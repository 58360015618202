import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import './LinkActionEditor.scss';

interface LinkActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
}

const LinkActionEditor = ({ action, modalStore }: LinkActionEditorProps) => {
  return (
    <div className="input-action-editor">
      <PageContainer>
        <PageHeadLine title="Collect customer data">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <TextInput
            name="survey-question"
            label={'URL to open'}
            value={action.href ?? ''}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                action.href = val;
              });
            }}
          />
          <div className="input-label mt-30 mb-10">Type</div>
          <div className="switch-container">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                action.newTab = !action.newTab;
              }}
              checked={action.newTab}
            />
            <span>Open in new tab</span>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore')(observer(LinkActionEditor));
