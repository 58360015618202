import React, { useState } from 'react';
import './SortableTextInput.scss';

interface SortableTextInputProps {
  onChange: any;
  onEnter: any;
  input: any;
}

const SortableTextInput = ({
  onChange,
  input,
  onEnter,
}: SortableTextInputProps) => {
  const [inputValue, setInputValue] = useState(input.value.title);

  return (
    <div className="draggable-input-container">
      <input
        className="draggable-input-item"
        value={inputValue}
        onBlur={() => {
          onChange(inputValue, input.value.index);
        }}
        onChange={(inputVal) => {
          setInputValue(inputVal.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

export default SortableTextInput;
