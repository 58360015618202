import ImageUpload from 'components/ImageUpload/ImageUpload';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellText,
  CellTextCopy
} from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ShareableUrl from 'components/ShareableUrl/ShareableUrl';
import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { isEqual } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import Switch from 'react-switch';
import { BugStore } from 'stores/private/BugStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectFeatureRequestSettings.scss';

interface ProjectFeatureRequestSettingsProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
}

const ProjectFeatureRequestSettings = ({
  projectStore,
}: ProjectFeatureRequestSettingsProps) => {
  const shareURL = `${process.env.REACT_APP_BASEURL}/sharedboard/${projectStore?.currentProject?.apiKey}`;
  var { flowConfig } = projectStore?.editingProject || {};
  const [customDomain, setCustomDomain] = useState(
    projectStore?.currentProject?.customDomain || '',
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Hostname',
        accessor: 'hostname',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '50px',
        Cell: (row) => <CellText text={row.value} />,
      },
      {
        Header: 'Add this value',
        accessor: 'value',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
    ],
    [],
  );

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const renderCustomDomainSettings = () => {
    if (projectStore?.currentProject?.customDomain) {
      return (
        <>
          <InfoBox className="mt-10 mb-20">
            In order to use your custom domain, you need to add the following
            CNAME record to your DNS settings.
          </InfoBox>
          <ListTable
            data={[
              {
                id: 'CNAME',
                label: 'CNAME',
                hostname: projectStore?.currentProject?.customDomain,
                type: 'CNAME',
                value: 'roadmap.gleap.io',
              },
            ]}
            columns={columns}
          />
          <LinkButton
            className="mt-20"
            label="Remove custom domain"
            onClick={() => {
              projectStore!.deleteCustomDomainSettings();
              setCustomDomain('');
            }}
          />
        </>
      );
    }

    return (
      <>
        <div className="fullwidth mt-30">
          <InfoBox className="mt-10 mb-20">
            Use your own custom domain for your Gleap roadmap portal.
          </InfoBox>
          <div className="custom-domain">
            <span>https://</span>
            <TextInput
              placeholder="roadmap.yourdomain.com"
              type="text"
              className="mb-10"
              error=""
              initalValue={customDomain}
              onChange={(text) => {
                setCustomDomain(text);
              }}
            />
            <PrimaryButton
              label="Add domain"
              icon="arrow-right"
              iconSideRight={true}
              className="ml-10"
              disabled={customDomain.length === 0}
              onClick={() => {
                projectStore?.updateCustomDomainSettings(customDomain);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderSettings = () => {
    if (flowConfig) {
      return (
        <>
          <div className="mb-20">
            <ImageUpload
              image={flowConfig.roadmapImage ?? ''}
              label="Header logo"
              editable
              uploadPath="feedback_widgets"
              afterImageUpload={(imageURL) => {
                flowConfig.roadmapImage = imageURL;
              }}
            />
          </div>
          <div className="fullwidth">
            <TextInput
              name="Link"
              placeholder="https://..."
              type="text"
              className="mb-20"
              error=""
              initalValue={flowConfig.roadmapLink}
              label="Header logo link URL"
              onChange={(text) => {
                runInAction(() => {
                  flowConfig.roadmapLink = text;
                });
              }}
            />
          </div>
        </>
      );
    }

    return null;
  };

  const renderCommentingOptions = () => {
    if (flowConfig) {
      return (
        <>
          <div className="switch-container mb-20">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                const isEnabled = flowConfig.enablePublicComments ?? true;
                flowConfig.enablePublicComments =
                  !isEnabled;
              }}
              checked={flowConfig.enablePublicComments ?? true}
            />
            <span>Enable public comments</span>
          </div>
          <InfoBox className='mb-20'>
            You will still be able to send out updates to all upvotes. Updates from the team are still publicly available.
          </InfoBox>
        </>
      );
    }

    return null;
  };

  return (
    <PageContainer className="relativ-full-width-page-container feedback-settings">
      <PageHeadLine title="Roadmap portal settings" />
      <PageContent hasTitle>
        <SizedContainer size={ContainerSizes.XXL}>
          <div className="options-group">
            <div className="options-group-header">Roadmap portal URL</div>
            <div className="share-url">
              <ShareableUrl url={shareURL} />
            </div>
          </div>
          <div className="options-group">
            <div className="options-group-header">
              Custom domain{' '}
              {projectStore?.currentProject?.customDomain && (<span>({projectStore?.currentProject?.customDomain})</span>)}
            </div>
            {renderCustomDomainSettings()}
          </div>
          <div className="options-group">
            <div className="options-group-header">Commenting options</div>
            {renderCommentingOptions()}
            <PrimaryButton
              label="Save"
              disabled={isEqual(projectStore?.flowConfig, flowConfig)}
              onClick={() => {
                projectStore?.saveEditingProject();
              }}
            />
          </div>
          <div className="options-group">
            <div className="options-group-header">Settings</div>
            {renderSettings()}
            <PrimaryButton
              label="Save"
              disabled={isEqual(projectStore?.flowConfig, flowConfig)}
              onClick={() => {
                projectStore?.saveEditingProject();
              }}
            />
          </div>
        </SizedContainer>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(ProjectFeatureRequestSettings));
