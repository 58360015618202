import * as React from 'react';
import classNames from 'classnames';
import './LogButton.scss';

interface LogButtonProps {
  label: string;
  onClick: any;
  icon?: any;
  className?: any;
  active?: boolean;
}

const LogButton = ({
  label,
  onClick,
  icon,
  className,
  active,
}: LogButtonProps) => {
  const secondaryButtonClassName = classNames(
    {
      'log-button': true,
    },
    {
      'log-button--active': active,
    },
    className,
  );

  return (
    <button
      type="button"
      className={secondaryButtonClassName}
      onClick={onClick}
    >
      {icon && <img src={icon} alt="icon" className="icon" />} {label}
    </button>
  );
};

export default LogButton;
