import React, { useState } from 'react';
import { Title } from 'components/Title/Title';
import TextInput from 'components/TextInput/TextInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { UsersStore } from 'stores/private/UsersStore';
import { inject, observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ModalStore } from 'stores/private/ModalStore';

interface ChangePasswordModalProps {
  usersStore?: UsersStore;
  modalStore?: ModalStore;
}

const ChangePasswordModal = ({
  usersStore,
  modalStore,
}: ChangePasswordModalProps) => {
  const { register, handleSubmit, formState } = useForm();
  const [passwordConfrim, setpasswordConfrim] = useState('');
  const [oldPassword, setoldPassword] = useState('');

  const user = usersStore!.currentUser;

  const onSubmit = async (data: any) => {
    if (data.newPassword !== data.repeatPassword) {
      toast.error('Passwords do not match');
      setpasswordConfrim('Passwords do not match');
    } else if (user) {
      try {
        await usersStore!.changeUserPassword(
          user?.id,
          data.oldPassword,
          data.repeatPassword,
        );
        toast.success('Password successfully updated.');
        modalStore!.closeModal();
      } catch (err: any) {
        toast.error('Wrong password');
      }
    } else {
      modalStore!.closeModal();
      usersStore!.logout();
    }
  };

  const generateErrorText = (type: string) => {
    if (type === 'minLength') {
      return 'The password must be at least 6 letters long';
    }
    if (type === 'required') {
      return 'Input is required';
    }
    return '';
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title label="Change password" />
        <TextInput
          inputRef={register('oldPassword', {
            required: true,
            minLength: 6,
          })}
          error={
            formState.errors.oldPassword
              ? generateErrorText(formState.errors.oldPassword.type)
              : oldPassword
          }
          onChange={() => {
            setoldPassword('');
          }}
          type="password"
          initalValue=""
          name="oldPassword"
          label="Old password"
          placeholder="Old password"
          className="mt-20"
        />
        <TextInput
          inputRef={register('newPassword', {
            required: true,
            minLength: 6,
          })}
          error={
            formState.errors.newPassword
              ? generateErrorText(formState.errors.newPassword.type)
              : ''
          }
          type="password"
          initalValue=""
          name="newPassword"
          label="New password"
          placeholder="New password"
          className="mt-20"
        />
        <TextInput
          inputRef={register('repeatPassword', {
            required: true,
            minLength: 6,
          })}
          error={
            formState.errors.repeatPassword
              ? generateErrorText(formState.errors.repeatPassword.type)
              : passwordConfrim
          }
          onChange={() => {
            setpasswordConfrim('');
          }}
          type="password"
          initalValue=""
          name="repeatPassword"
          label="Repeat password"
          placeholder="Repeat password"
          className="mb-20 mt-20"
        />
        <PrimaryButton
          submit
          className="change-password-modal bnt-container"
          label="Save"
          onClick={() => {}}
        />
      </form>
    </>
  );
};

export default inject(
  'usersStore',
  'modalStore',
)(observer(ChangePasswordModal));
