import ListTable, { CellLink, CellPaymentStatus, CellText } from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { StripeStore } from 'stores/private/StripeStore';
import './InvoiceTable.scss';

interface InvoiceTableProps {
  organisationStore?: OrganisationStore;
  stripeStore?: StripeStore;
}

const InvoiceTable = ({ organisationStore, stripeStore }: InvoiceTableProps) => {
  useEffect(() => {
    stripeStore!.getInvoices();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: (row) => ({
          invoice_pdf: row.invoice_pdf,
          number: row.number,
        }),
        Cell: (row) => (
          <CellLink className='cell-link--icon' link={`${row.value.invoice_pdf}`} text={row.value.number} icon="cloud-arrow-down" />
        ),
      },
      {
        Header: 'Created at',
        accessor: 'created',
        Cell: (row) => (
          <CellText text={moment.unix(row.value).format('LL')} />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (row) => (
          <CellPaymentStatus text={row.value} />
        ),
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: (row) => (
          <CellText text={`$${((row.value ? row.value : 0) / 100).toFixed(2)}`} />
        ),
      },
    ],
    [],
  );

  if (!organisationStore?.currentOrganisation) {
    return <Loading />;
  }

  return (
    <div className='list-table-container'>
      <ListTable data={stripeStore?.invoiceList} columns={columns} />
    </div>
  );
};

export default inject('organisationStore', 'stripeStore')(observer(InvoiceTable));
