import bbLogo from 'assets/icons/Gleap.svg';
import { HeadLine } from 'components/HeadLine/HeadLine';
import Loading from 'components/Loading/Loading';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { inject } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { UsersStore } from 'stores/private/UsersStore';
import './ResetPassword.scss';

interface ResetPasswordProps {
  usersStore?: UsersStore;
}

const ResetPassword = ({ usersStore }: ResetPasswordProps) => {
  // @ts-ignore
  const { token } = useParams();
  const { register, handleSubmit, formState } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pwReseted, setPwReseted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token === '' || !token) {
      window.localStorage.removeItem('@bbtoken');
      navigate('/login');
    }
  }, [token]);

  const onSubmit = async (data: any) => {
    try {
      if (token) {
        setIsLoading(true);
        if (await usersStore?.resetPasswordWithToken(token, data.password)) {
          setPwReseted(true);
        }
        setIsLoading(false);
      } else {
        toast.error('Could not reset password. Try it again later.');
      }
    } catch (err: any) {
      toast.error('Could not reset password. Try it again later.');
    }
  };

  const buildResetPasswordForm = () => {
    return (
      <>
        <HeadLine title="Password reset" subtitle="Enter new password" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            placeholder="Password*"
            type="password"
            name="password"
            initalValue=""
            inputRef={register('password', {
              required: true,
              minLength: 6,
            })}
            error={formState.errors.password ? 'Enter valid password' : ''}
            required
            className="mt-20"
          />
          <PrimaryButton
            className="mt-20"
            submit
            onClick={() => { }}
            label="Reset password"
          />
        </form>
      </>
    );
  };

  const buildSucessScreen = () => {
    return (
      <>
        <HeadLine
          title="Successfully reseted your password"
          subtitle="You can now log in with your new password"
        />
        <div className="centered">
          <PrimaryButton
            className="mt-10"
            onClick={() => {
              navigate('/login');
            }}
            label="Go to login"
          />
        </div>
      </>
    );
  };

  if (isLoading && !pwReseted) {
    return <Loading />;
  }

  return (
    <div className="reset-password-container">
      <div className="card-container">
        <div className="card">
          <img src={bbLogo} className="logo" alt="logo" />
          <SizedContainer size={ContainerSizes.M}>
            {!pwReseted && buildResetPasswordForm()}
            {pwReseted && buildSucessScreen()}
          </SizedContainer>
        </div>
      </div>
    </div>
  );
};

export default inject('usersStore')(ResetPassword);
