/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import './AppSumoRegisterForm.scss';
import { useForm } from 'react-hook-form';
import TextInput from 'components/TextInput/TextInput';
import { UsersStore } from 'stores/private/UsersStore';
import { inject } from 'mobx-react';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { toast } from 'react-toastify';
import { validateRecaptchaAction } from 'services/Recaptcha';
import { getAppSumoActivation } from 'services/UserHttpService';
import Loading from 'components/Loading/Loading';

interface AppSumoRegisterFormProps {
  usersStore?: UsersStore;
}

const AppSumoRegisterForm = ({ usersStore }: AppSumoRegisterFormProps) => {
  const [tos, setTos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activation, setActivation] = useState(undefined as any);
  const { register, handleSubmit, formState } = useForm();

  const loadAppSumoActivation = async (token: string) => {
    try {
      const activationData = await getAppSumoActivation(token);
      if (activationData && activationData.data) {
        setActivation(activationData.data);
      }
    } catch (exp) { }
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    if (token) {
      loadAppSumoActivation(token);
    }
  }, []);

  const onSubmit = async (data: any) => {
    if (!tos) {
      toast.error('Please accept our privacy policy and terms of use.');
      return;
    }

    let token = '';
    try {
      token = (await validateRecaptchaAction('AppSumoRegister')) as any;
    } catch (exp) {
      toast.error('Are you human?');
      return;
    }

    if (activation.email && data.password && data.name) {
      setLoading(true);
      await usersStore?.register(token, activation.email, data.password, data.name, activation.activationToken);
      setLoading(false);
    } else {
      toast.error('Please enter your name and a password.');
    }
  };

  const generateErrorText = (type: string) => {
    if (type === 'minLength') {
      return 'The password must contain at least 6 characters.';
    }
    if (type === 'required') {
      return 'Input is required';
    }
    if (type === 'pattern') {
      return 'Wrong input';
    }
    return '';
  };

  if (!activation || loading) {
    return (<>
      <Loading />
    </>);
  }

  return (
    <div className="appsumo-register-form-container" onClick={() => { }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="appsumo-register-form full-width"
        autoComplete="off"
      >
        <TextInput
          placeholder="Name*"
          type="text"
          name="name"
          className="mb-20"
          initalValue=""
          inputRef={register('name', {
            required: true,
            minLength: 2,
          })}
          error={formState.errors.name ? 'Please enter your name' : ''}
          required
        />
        <div className='prefilled-form-item'>
          {activation.email}
        </div>
        <TextInput
          placeholder="Password*"
          type="password"
          name="password"
          initalValue=""
          inputRef={register('password', {
            required: true,
            minLength: 6,
          })}
          error={
            formState.errors.password
              ? generateErrorText(formState.errors.password.type) || ''
              : ''
          }
          required
          className="mb-20"
        />
        <div
          className="checkbox-container mb-20"
          onClick={() => {
            setTos(!tos);
          }}
        >
          <input
            type="checkbox"
            className="checkbox"
            checked={tos}
            onChange={() => { }}
          />
          <span>
            I agree to the{' '}
            <a target="_blank" href="https://gleap.io/privacy-policy/">
              privacy policy & terms of use
            </a>
            .
          </span>
        </div>
        <div className="btn-container">
          <PrimaryButton
            fullWidth
            className="mt-10"
            submit
            onClick={() => { }}
            label="Sign up with email"
          />
        </div>
      </form>
    </div>
  );
};

export default inject('usersStore')(AppSumoRegisterForm);
