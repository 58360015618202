import FeatureRequestsHeadLine from 'components/FeatureRequestsHeadLine/FeatureRequestsHeadLine';
import SharedFeatureRequestDetails from 'components/SharedFeatureRequestDetails/SharedFeatureRequestDetails';
import { calculateContrast, calculateShadeColor } from 'helper/Color';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { BugStore } from 'stores/private/BugStore';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedSessionStore } from 'stores/public/SharedSessionStore';
import SharedFeatureRequestsListContainer from '../SharedFeatureRequestsListContainer/SharedFeatureRequestsListContainer';
import SharedFeatureRequestsRoadmap from '../SharedFeatureRequestsRoadmap/SharedFeatureRequestsRoadmap';
import SharedNewsList from '../SharedNewsList/SharedNewsList';
import './SharedFeatureRequests.scss';

interface SharedFeatureRequestsProps {
  bugStore?: BugStore;
  sharedBoardStorePublic?: SharedBoardStorePublic;
  sharedSessionStore?: SharedSessionStore;
}

const SharedFeatureRequests = ({
  bugStore,
  sharedBoardStorePublic,
  sharedSessionStore,
}: SharedFeatureRequestsProps) => {
  const [widgetMode, setWidgetMode] = useState(false);
  const [page, setPage] = useState('featurerequests');
  const { boardShareToken, featureRequestShareToken } = useParams();
  const [injectedStyling, setInjectedStyling] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  let gleapId = undefined as any;
  let gleapHash = undefined as any;

  try {
    gleapId = localStorage.getItem(`${boardShareToken}_gleapId`);
    gleapHash = localStorage.getItem(`${boardShareToken}_gleapHash`);
  } catch (exp) { }

  // Overwrite the gleapId and gleapHash if they are set in the url
  const params = new URLSearchParams(location.search);
  if (params.get('gleapId') && params.get('gleapHash')) {
    gleapId = params.get('gleapId');
    gleapHash = params.get('gleapHash');
  }

  useEffect(() => {
    if (sharedBoardStorePublic?.currentProject && !injectedStyling) {
      setInjectedStyling(true);

      const params = new URLSearchParams(location.search);
      const isWidgetApp = params.get('widgetApp');
      const lang = params.get('lang');
      if (lang && lang.length > 0) {
        runInAction(() => {
          sharedBoardStorePublic.overrideLanguage = lang;
        });
      }

      window.addEventListener('message', function (e) {
        try {
          var showRoadmapLoc = false;
          if (window.location.href.includes('roadmap')) {
            showRoadmapLoc = true;
          }
          const data = JSON.parse(e.data);
          if (data && data.name === 'goback') {
            navigate(
              `/sharedboard/${boardShareToken}${showRoadmapLoc ? '/roadmap' : ''
              }`,
            );
          }
        } catch (exp) { }
      });

      const color =
        sharedBoardStorePublic?.currentProject?.flowConfig?.color ?? '#00bcd4';
      const contrastColor = calculateContrast(color);
      const headerColor =
        sharedBoardStorePublic?.currentProject?.flowConfig?.headerColor ??
        '#00bcd4';
      const contrastHeaderColor = calculateContrast(headerColor);

      const backgroundColor =
        sharedBoardStorePublic?.currentProject?.flowConfig?.backgroundColor ||
        '#ffffff';
      const primaryTextColor = calculateContrast(backgroundColor);

      const isDarkMode = primaryTextColor === '#ffffff';
      const secondaryTextColor = isDarkMode
        ? calculateShadeColor(backgroundColor, 100)
        : calculateShadeColor(backgroundColor, -120);
      const borderColor = isDarkMode
        ? calculateShadeColor(backgroundColor, 70)
        : calculateShadeColor(backgroundColor, -70);

      const style = document.createElement('style');
      style.textContent = `
      .intercom-lightweight-app {
        display: none !important;
      }
      .shared-headline-fixed-preheader {
        background-color: ${headerColor} !important;
      }
      .shared-avatar i {
        color: ${color} !important;
      }
      .shared-feature-requests-board {
        background-color: ${backgroundColor} !important;
      }
      .shared-feature-requests-board::before {
        background: linear-gradient(180deg, ${backgroundColor} 0%, transparent 100%) !important;
      }
      .feature-requests-headline {
        background-color: ${backgroundColor} !important;
        border-bottom: 1px solid ${borderColor}55 !important;
      }
      .shared-column {
        border-right: 1px dashed ${borderColor}55 !important;
      }
      .shared-board .shared-column::before {
        background: linear-gradient(180deg, ${backgroundColor} 0%, transparent 100%);
      }
      .shared-board .shared-column::after {
        background: linear-gradient(0deg, ${backgroundColor} 0%, transparent 100%);
      }
      .shared-column-header-count {
        border: 1px solid ${borderColor} !important;
        color: ${primaryTextColor} !important;
      }
      .shared-column-header-title {
        color: ${primaryTextColor} !important;
      }
      .shared-feature-requests-board-filters {
        border-right: 1px solid ${borderColor}55 !important;
      }
      .shared-feature-requests-board-filters .dropdown-selection .dropdown-selection__control {
        background-color: ${backgroundColor} !important;
        border: 1px solid ${borderColor} !important;
      }
      .theme--default .dropdown-selection .dropdown-selection__control, .theme--default .select .dropdown-selection__control {
        color: ${primaryTextColor} !important;
      }
      .feature-request-details-inner,
      .shared-feature-requests-board .board-filter-header .dropdown-selection .dropdown-selection__control {
        background-color: ${backgroundColor} !important;
      }
      .order-prio-item span,
      .bug-prio-item span {
        color: ${primaryTextColor} !important;
      }
      .theme--default .shared-feature-requests-board-container--widget .board-filter-header .dropdown-selection__control {
        border: 1px solid ${borderColor}55 !important;
      }
      .feature-requests-order-filter .dropdown-selection .dropdown-selection__control:hover .dropdown-selection__indicator svg path {
        fill: ${color} !important;
      }
      .theme--default .dropdown-selection .dropdown-selection__control:hover .dropdown-selection__indicator path, .theme--default .language-selection .dropdown-selection__control:hover .dropdown-selection__indicator path, .theme--default .select .dropdown-selection__control:hover .dropdown-selection__indicator path {
        fill: ${color} !important;
      }
      .shared-feature-requests-board-filters .tag-count {
        border: 1px solid ${borderColor} !important;
        color: ${primaryTextColor} !important;
      }
      .theme--default .modal {
        background-color: ${backgroundColor} !important;
      }
      .theme--default .dropdown-selection__menu, .theme--default .select__menu {
        background-color: ${backgroundColor} !important;
        color: ${primaryTextColor} !important;
      }
      .theme--default .feature-request-card-upvotes:hover i,
      .theme--default .feature-request-card-upvotes:hover .number {
        color: ${primaryTextColor};
      }
      .theme--default .dropdown-selection__menu-list .option-list-item:hover,
      .theme--default .select__menu-list .option-list-item:hover {
        background-color: ${borderColor}22 !important;
        color: ${primaryTextColor} !important;
      }
      .feature-request-card-container {
        border-bottom: 1px solid ${borderColor}44 !important;
      }
      .theme--default .tags .tag:hover {
        background-color: ${borderColor}22 !important;
      }
      .theme--default .feature-request-card:hover {
        background-color: ${borderColor}22 !important;
      }
      .feature-request-card-upvotes {
        background-color: ${backgroundColor} !important;
        border: 1px solid ${secondaryTextColor} !important;
        color: ${borderColor} !important;
      }
      .theme--default .feature-request-container-comments .comment-composer .send-input-container,
      .theme--default .field-container .input-wrapper .textinput-gray {
        background-color: ${backgroundColor} !important;
        border: 1px solid ${borderColor}44 !important;
        color: ${primaryTextColor} !important;
      }
      .theme--default .feature-request-card-upvotes .number {
        color: ${secondaryTextColor};
      }
      .theme--default .feature-request-container-comments .comments-container .comment .comment-body .body {
        background-color: ${borderColor}22 !important;
      }
      .static-comments-eta {
        color: ${primaryTextColor} !important;
      }
      .theme--default .ProseMirror p,
      .theme--default .ProseMirror p a,
      .theme--default .ProseMirror a {
        color: ${primaryTextColor} !important;
      }
      .theme--default .ProseMirror .linktype-button {
        background-color: ${color} !important;
        color: ${contrastColor} !important;
      }
      .theme--default .shared-board .shared-column .shared-column-cards .feature-request-card {
        border: 1px solid ${borderColor}77 !important;
      }
      .theme--default .primary-button--disabled,
      .theme--default .feature-request-container-comments .comment-composer .send-input-container .send-button--disabled {
        background-color: ${borderColor}44 !important;
        color: ${borderColor} !important;
      }
      ::-webkit-scrollbar-track-piece {
        background-color: ${borderColor}44 !important;
      }
      ::-webkit-scrollbar-thumb:horizontal,
      ::-webkit-scrollbar-thumb:vertical {
        background-color: ${secondaryTextColor} !important;
      }
      .board-filter-header-title,
      .theme--default .feature-request-details .close-modal i,
      .feature-request-content-body-date,
      .theme--default .feature-requests-headline--inner .links div,
      .feature-request-card-body-description,
      .feature-request-card-body-statustags,
      .shared-feature-requests-board-filters .powered-by span,
      .shared-feature-requests-board-filters .tag-name span,
      .theme--default .comment .comment-body .body .comment-time time,
      .theme--default .comment .comment-body .body .comment-time .seen,
      .text {
        color: ${secondaryTextColor} !important;
      }
      .theme--default .feature-request-container-comments .comment-composer .send-input-container .send-input,
      .theme--default .feature-request-container-comments .comments-container .static-comments-description,
      .theme--default .feature-request-container-comments .comments-container .static-comments-description span,
      .feature-request-content-body-title,
      .feature-request-card-body-title,
      .shared-feature-requests-board-filters .tag-name,
      .title,
      .comments-title,
      .filter-title {
        color: ${primaryTextColor} !important;
      }
      .shared-feature-requests-board-container {
        background-color: ${backgroundColor} !important;
      }
      .feature-request-container-comments {
        background-color: ${backgroundColor} !important;
      }
      .shared-team-member {
        color: ${color} !important;
      }
      .primary-button, .send-button {
        background-color: ${color} !important;
        color: ${contrastColor} !important;
      }
      .shared-headline-fixed-preheader .head {
        color: ${contrastHeaderColor} !important;
      }
      .checkbox-list .checkmark:after {
        border: solid ${color} !important;
        border-width: 0 3px 3px 0 !important;
      }
      .textarea-gray:focus {
        border-color: ${color} !important;
      }
      .commenting-as a {
        color: ${color} !important;
      }
      .textinput-gray:active,
      .textinput-gray:focus-within {
        border: 1px solid ${color} !important;
      }
      .send-input::placeholder,
      .textinput-gray::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1; /* Firefox */
        color: ${primaryTextColor}33 !important;
      }

      .send-input:-ms-input-placeholder,
      .textinput-gray:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${primaryTextColor}33 !important;
      }

      .textinput-gray::-ms-input-placeholder,
      .send-input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${primaryTextColor}33 !important;
      }
      .send-input-container:focus-within {
        border: 1px solid ${color} !important;
      }
      .spring-spinner-rotator {
        border-right-color: ${color} !important;
        border-top-color: ${color} !important;
      }
      .feature-request-card-upvotes-selected i {
        color: ${color} !important;
      }
      .feature-requests-headline--inner .links div.active,
      .feature-requests-headline--inner .links div:hover {
        color: ${color} !important;
      }
      .feature-request-card-upvotes-selected {
        color: ${color} !important;
        border: 1px solid ${color} !important;
      }
      .shared-avatar--team {
        box-shadow: 0px 0px 0px 2px#fff, 0px 0px 0px 4px ${color} !important;
      }
      .feature-request-card-upvotes-selected .number {
        color: ${color} !important;
      }
      .shared-back-button:hover svg {
        fill: ${color} !important;
      }
      .main-fill-color {
        fill: ${color} !important;
      }
      .shared-logo--default path {
        fill: ${color} !important;
      }
      .dropdown-selection .dropdown-selection__control:hover,
      .dropdown-selection .dropdown-selection__control:hover i,
      .dropdown-selection .dropdown-selection__control:hover span {
        color: ${color} !important;
      }
      .feature-request-card-body-tag {
        color: ${color} !important;
      }
      .gleap-notification-container {
        display: none !important;
      }
      .text a {
        color: ${color} !important;
      }
      .theme--default #root {
        background-color: #fff !important;
      }`;
      document.head.append(style);

      if (!isWidgetApp && boardShareToken && boardShareToken.length > 0) {
        const script = document.createElement('script');
        script.src = 'https://sdk.gleap.io/latest/index.js';
        script.async = true;
        script.addEventListener('load', function () {
          (window as any).Gleap.showFeedbackButton(true);
          (window as any).Gleap.initialize(boardShareToken);
        });
        document.body.appendChild(script);
      }
    }
  }, [sharedBoardStorePublic?.currentProject, injectedStyling]);

  useEffect(() => {
    if (location.pathname.includes('roadmap')) {
      setPage('roadmap');
    } else if (location.pathname.includes('news')) {
      setPage('news');
    } else {
      setPage('featurerequests');
    }
  }, [location, location.pathname]);

  useEffect(() => {
    const widgetApp = params.get('widgetApp');
    if (widgetApp) {
      setWidgetMode(true);
    }

    if (boardShareToken) {
      sharedSessionStore!.setApiKey(boardShareToken);
      sharedBoardStorePublic?.getSharedProject(
        boardShareToken,
        gleapId,
        gleapHash,
      );
    }
  }, []);

  useEffect(() => {
    if (featureRequestShareToken) {
      bugStore!.loadSharedBug(
        featureRequestShareToken,
        true,
        gleapId,
        gleapHash,
      );
    } else {
      bugStore!.clearCurrentBug(false);
    }
  }, [bugStore, featureRequestShareToken]);

  if (!sharedBoardStorePublic?.currentProject) {
    return null;
  }

  const renderMainContent = () => {
    if (page === 'roadmap') {
      return <SharedFeatureRequestsRoadmap />;
    }
    if (page === 'news') {
      return <SharedNewsList />;
    } else {
      return <SharedFeatureRequestsListContainer widgetMode={widgetMode} />;
    }
  };

  return (
    <div
      className={`shared-feature-requests-board-container ${widgetMode && 'shared-feature-requests-board-container--widget'
        }`}
    >
      <FeatureRequestsHeadLine
        isAppWidget={widgetMode}
        page={page}
        headerConfig={{
          link: sharedBoardStorePublic?.currentProject?.flowConfig?.roadmapLink,
          logo: sharedBoardStorePublic?.currentProject?.flowConfig
            ?.roadmapImage,
          title: sharedBoardStorePublic?.currentProject?.name,
        }}
      />
      <div className="shared-feature-requests-container">
        {renderMainContent()}
        {(bugStore?.currentBug || bugStore?.loadingSharedBug) && (
          <SharedFeatureRequestDetails showRoadmap={page === 'roadmap'} />
        )}
      </div>
    </div>
  );
};
export default inject(
  'bugStore',
  'sharedBoardStorePublic',
  'sharedSessionStore',
)(observer(SharedFeatureRequests));
