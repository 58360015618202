/* eslint-disable jsx-a11y/media-has-caption */
import TabButton from 'components/TabButton/TabButton';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import './BugScreenContent.scss';
import BugScreenshot from './BugScreenshot';

interface BugScreenContentProps {
  bugStore?: BugStore;
  shared?: boolean;
}

const BugScreenContent = ({ bugStore, shared }: BugScreenContentProps) => {
  const bug = bugStore!.currentBug;
  const [tab, setTab] = useState('screenshot');

  if (!bug) {
    return <></>;
  }

  const renderVideo = () => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isWebM = bug.screenRecordingUrl && bug.screenRecordingUrl.includes(".webm");

    return (<>
      <video controls>
        <source src={bug.screenRecordingUrl} type={isWebM ? "video/webm" : "video/mp4"} />
      </video>
      {isWebM && !isChrome && <span className='video-playback-info'>⚠️ Video playback might only work on Chrome currently.</span>}
    </>);
  }

  return (
    <div className="screenrecording">
      {bug.screenRecordingUrl && bug.screenRecordingUrl.length > 0 && (
        <div className="tab-buttons">
          {bug.session && bug.session.email && (
            <TabButton
              active={tab === 'screenshot'}
              label="Screenshot"
              onClick={() => {
                setTab('screenshot');
              }}
            />
          )}
          <TabButton
            active={tab !== 'screenshot'}
            label="Screen recording"
            onClick={() => {
              setTab('screencapture');
            }}
          />
        </div>
      )}
      <div className="tab-content">
        {tab === 'screenshot' && <BugScreenshot />}
        {tab === 'screencapture' && renderVideo()}
      </div>
    </div>
  );
};

export default inject('bugStore')(observer(BugScreenContent));
