import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { Title } from 'components/Title/Title';
import './VoteModal.scss';
import { SharedSessionStore } from 'stores/public/SharedSessionStore';

interface VoteModalProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  sharedSessionStore?: SharedSessionStore;
}

const VoteModal = ({
  modalStore,
  bugStore,
  sharedSessionStore,
}: VoteModalProps) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const featureRequestListItem = document.getElementById(
      `feature-request-${modalStore?.customData.featureRequest.id}`,
    );

    if (!featureRequestListItem) {
      return;
    }

    const voteModal = document.getElementById('MODAL-VOTE');
    if (!voteModal) {
      return;
    }

    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = featureRequestListItem.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;
    offset = offset > 140 ? offset : 140;
    voteModal.style.top = offset + 'px';
  }, []);

  const sendVote = async () => {
    try {
      if (email && email.length > 0) {
        // Update session.
        await sharedSessionStore?.updateSession({
          email,
        });

        const upvoted = await bugStore!.voteBug(
          modalStore?.customData.featureRequest,
        );
        modalStore!.customData.callback(upvoted);
        modalStore!.closeModal();
      }
    } catch (exp) { }
  };

  return (
    <div className="vote-modal-container" id="vote-modal">
      <div className="vote-headline">
        <Title label="Vote for" />
        <Title
          label={modalStore?.customData.title}
          className="vote-headline-title"
        />
      </div>
      <TextInput
        placeholder="Email"
        type="text"
        error=""
        className="mt-20"
        initalValue={email}
        onChange={(value) => {
          setEmail(value);
        }}
      />
      <PrimaryButton
        disabled={email === ''}
        className="mt-20"
        label="Vote"
        onClick={sendVote}
      />
    </div>
  );
};

export default inject(
  'modalStore',
  'bugStore',
  'sharedSessionStore',
)(observer(VoteModal));
