/* eslint-disable @typescript-eslint/no-unused-expressions */

import React from 'react';
import { Invitation } from 'models/Invitations';
import './InvitationCard.scss';
import { inject } from 'mobx-react';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { UsersStore } from 'stores/private/UsersStore';
import LinkButton from 'components/LinkButton/LinkButton';
import { ampli } from 'ampli';

interface InvitationCardProps {
  invitation: Invitation;
  usersStore?: UsersStore;
}

const InvitationCard = ({ invitation, usersStore }: InvitationCardProps) => {
  return (
    <div className="card invitation-card">
      <div className="invitation-info">
        <div className="text subtitle">📬 Invitation to join</div>
        <div className="text organisation-name">{invitation.name}</div>
      </div>
      <div className="button-group">
        <LinkButton
          label="Reject"
          onClick={() => {
            ampli.invitationRejected();
            usersStore?.acceptInvitation(invitation.id, false);
          }}
        />
        <PrimaryButton
          label="Join"
          className="btn-join"
          onClick={() => {
            ampli.invitationAccepted();
            usersStore?.acceptInvitation(invitation.id, true);
          }}
        />
      </div>
    </div>
  );
};

export default inject('usersStore')(InvitationCard);
