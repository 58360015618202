import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import React from 'react';
import { useLocation } from 'react-router';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import CreateHelpcenterArticle from '../CreateHelpcenterArticle/CreateHelpcenterArticle';
import HelpcenterCollectionDetail from '../HelpcenterCollectionDetail/HelpcenterCollectionDetail';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg';
import { ReactComponent as VerifiedIcon } from '../../../../assets/icons/np_arroba_436046_000000.svg';
import ProjectHelpcenter from '../HelpcenterOverview/ProjectHelpcenter';
import HelpcenterSettings from '../HelpcenterSettings/HelpcenterSettings';
import HelpcenterCustomizations from '../HelpcenterCustomizations/HelpcenterCustomizations';
import SubNav from 'components/SubNav/SubNav';
import HelpcenterSeo from '../HelpcenterSeo/HelpcenterSeo';
import { inject, observer } from 'mobx-react';
import { SidenavStore } from 'stores/private/SidenavStore';

const Helpcenter = ({ sidenavStore }: {
  sidenavStore?: SidenavStore;
}) => {
  const { projectId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[4] === path) {
        return true;
      }

      if (path === '' && parts.length === 4 && parts[3] === 'helpcenter') {
        return true;
      }
    }

    return false;
  };

  const collectionsActive = (!isActive('settings') && !isActive('seo') && !isActive('customize'));

  return (
    <SidenavContainer sidenavHidden={sidenavStore?.sidenavHidden}>
      <SubSidenav title="Help center">
        <SimpleSidenavElement
          name="Collections"
          faIcon='book-open'
          onClick={() => {
            navigate(`/projects/${projectId}/helpcenter`);
          }}
          isActive={collectionsActive}
        />
        <SubNav title='Settings' faIcon='gear' onClick={() => {
          navigate(`/projects/${projectId}/helpcenter/settings`);
        }} isOpened={!collectionsActive}>
          <SimpleSidenavElement
            name="General"
            onClick={() => {
              navigate(`/projects/${projectId}/helpcenter/settings`);
            }}
            isActive={isActive('settings')}
          />
          <SimpleSidenavElement
            name="Customize"
            onClick={() => {
              navigate(`/projects/${projectId}/helpcenter/customize`);
            }}
            isActive={isActive('customize')}
          />
          <SimpleSidenavElement
            name="SEO & custom code"
            onClick={() => {
              navigate(`/projects/${projectId}/helpcenter/seo`);
            }}
            isActive={isActive('seo')}
          />
        </SubNav>
      </SubSidenav>
      <Routes>
        <Route path="/" element={<ProjectHelpcenter />} />
        <Route path="/settings" element={<HelpcenterSettings />} />
        <Route path="/seo" element={<HelpcenterSeo />} />
        <Route path="/customize" element={<HelpcenterCustomizations />} />
        <Route
          path="/collections/:collectionId"
          element={<HelpcenterCollectionDetail />}
        />
        <Route
          path="/collections/:collectionId/articles/:articleId"
          element={<CreateHelpcenterArticle />}
        />
      </Routes>
    </SidenavContainer>
  );
};

export default inject('sidenavStore')(observer(Helpcenter));