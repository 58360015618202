import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import UpsellOnboardingModal from 'components/UpsellOnboardingModal/UpsellOnboardingModal';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import CompleteOnboarding from './CompleteOnboarding/CompleteOnboarding';
import CompleteProfile from './CompleteProfile/CompleteProfile';
import CreateOrganisationOnboarding from './CreateOrganisationOnboarding/CreateOrganisationOnboarding';
import InvitationsOnboarding from './InvitationsOnboarding/InvitationsOnboarding';
import './OnBoarding.scss';
import QAInfo from './QAInfo/QAInfo';
import VerifyCode from './VerifyCode/VerifyCode';

const flowSteps = {
  verify: {
    completed: (usersStore, organisationStore, projectStore) => {
      if (usersStore?.currentUser?.confirmed) {
        return true;
      }
      return false;
    },
  },
  whoami: {
    completed: (usersStore, organisationStore, projectStore) => {
      if (usersStore?.currentUser?.userRole) {
        return true;
      }
      return false;
    },
  },
  qainfo: {
    completed: (usersStore, organisationStore, projectStore) => {
      if (usersStore?.currentUser?.knewGleapFrom) {
        return true;
      }
      return false;
    },
  },
  organization: {
    completed: (usersStore, organisationStore, projectStore) => {
      if (organisationStore?.organisations?.length > 0) {
        return true;
      }
      return false;
    },
  },
  upsell: {
    completed: (usersStore, organisationStore, projectStore) => {
      if (!organisationStore.canShowUpsell) {
        return true;
      }

      let canShowDialog = true;
      if (organisationStore.organisations) {
        for (let i = 0; i < organisationStore.organisations.length; i++) {
          if (
            organisationStore.organisations[i].subscription &&
            organisationStore.organisations[i].subscription.planID !== 'free'
          ) {
            canShowDialog = false;
          }
        }
      }

      return !canShowDialog;
    },
  },
  sdkinstallation: {
    completed: (usersStore, organisationStore, projectStore) => {
      if (usersStore?.currentUser?.completedOnboarding) {
        return true;
      }
      return false;
    },
  },
};

const mainFlow = [
  { type: 'verify' },
  { type: 'whoami' },
  { type: 'organization' },
  { type: 'upsell' },
  { type: 'sdkinstallation' },
];

interface OnboardingProps {
  usersStore?: UsersStore;
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
}

const Onboarding = ({
  usersStore,
  organisationStore,
  projectStore,
}: OnboardingProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    usersStore!.findInvitations();
  }, [usersStore]);

  useEffect(() => {
    if (
      usersStore?.currentUser &&
      usersStore?.currentUser.completedOnboarding &&
      usersStore?.currentUser.confirmed
    ) {
      navigate('/dashboard');
      window.location.reload();
    }
  }, [usersStore?.currentUser]);

  const getProgressSteps: () => any = () => {
    var progress: boolean[] = [];
    for (let i = 0; i < mainFlow.length; i++) {
      const step = mainFlow[i];
      const stepObj = flowSteps[step.type];
      const completed = stepObj.completed(
        usersStore,
        organisationStore,
        projectStore,
      );

      progress.push(completed);
    }

    return progress;
  };

  const getCurrentFlowStep: () => any = () => {
    for (let i = 0; i < mainFlow.length; i++) {
      const step = mainFlow[i];
      const stepObj = flowSteps[step.type];
      const completed = stepObj.completed(
        usersStore,
        organisationStore,
        projectStore,
      );

      if (!completed) {
        return step;
      }
    }

    return null;
  };

  const renderCurrentOnboardingStep = () => {
    if (
      usersStore?.isLoadingCurrentUser ||
      projectStore?.loadingProjects ||
      organisationStore?.isLoadingOrganisations
    ) {
      return (
        <div className="onboarding-loading">
          <LoadingAnimation />
        </div>
      );
    }

    if (
      usersStore?.invitations &&
      usersStore?.invitations.length > 0 &&
      !usersStore.skippedInvitations
    ) {
      return <InvitationsOnboarding />;
    }

    const currentFlowStep = getCurrentFlowStep();
    if (currentFlowStep?.type === 'verify') {
      return <VerifyCode />;
    }
    if (currentFlowStep?.type === 'whoami') {
      return <CompleteProfile />;
    }
    if (currentFlowStep?.type === 'organization') {
      return <CreateOrganisationOnboarding />;
    }
    if (currentFlowStep?.type === 'qainfo') {
      return <QAInfo />;
    }
    if (currentFlowStep?.type === 'upsell') {
      return <UpsellOnboardingModal />;
    }
    if (currentFlowStep?.type === 'sdkinstallation') {
      return <CompleteOnboarding />;
    }

    return <CompleteOnboarding />;
  };

  const progressSteps = getProgressSteps();

  return (
    <>
      <div className="onboarding">
        <div className="onboarding-container">
          {renderCurrentOnboardingStep()}
        </div>
      </div>
      <div className="onboarding-progress">
        {progressSteps.map((completed) => {
          return (
            <div
              className={`onboarding-progress-step ${
                completed && 'onboarding-progress-step--completed'
              }`}
            />
          );
        })}
      </div>
    </>
  );
};

export default inject(
  'usersStore',
  'organisationStore',
  'projectStore',
)(observer(Onboarding));
