import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellIconAction,
  CellInvitedUser
} from 'components/ListTable/ListTable';
import SubHeader from 'components/SubHeader/SubHeader';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import TimeAgo from 'react-timeago';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './InvitationsTable.scss';

interface InvitationsTableProps {
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
  isProjectLevel?: boolean;
}

const InvitationsTable = ({
  organisationStore,
  projectStore,
  isProjectLevel = false,
}: InvitationsTableProps) => {
  const invitations = isProjectLevel
    ? projectStore?.invitedTeamMembers
    : organisationStore?.invitedOrgaTeamMembers;
  const isAdmin = isProjectLevel
    ? projectStore?.isProjectAdmin
    : organisationStore?.isOrganisationAdmin;

  const initialize = async () => {
    if (isProjectLevel) {
      await projectStore!.getInvitedTeamMembers();
    } else {
      await organisationStore!.getInvitedOrgaTeamMembers();
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const resendInvitation = async (email, role) => {
    if (isProjectLevel) {
      await projectStore?.inviteTeamMember([email], true);
    } else {
      await organisationStore?.inviteTeamMember([email], true);
    }

    initialize();
  };

  const deleteInvitation = async (invitationID) => {
    await organisationStore?.deleteInvitation(invitationID);

    initialize();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (row) => <CellInvitedUser email={row.value} />,
      },
      {
        Header: 'Spacer',
        Cell: (row) => <></>,
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        Header: 'Action',
        accessor: (row) => ({
          role: row.role,
          email: row.email,
          id: row.id,
        }),
        Cell: (row) => (
          <div className="action-container">
            {isAdmin && (<>
              <LinkButton
                label="Resend"
                className="mr-15"
                onClick={() => {
                  resendInvitation(row.value.email, row.value.role);
                }}
              />
              <CellIconAction
                Icon={DeleteIcon}
                action={() => {
                  deleteInvitation(row.value.id);
                }}
              />
            </>)}
          </div>
        ),
      },
    ],
    [],
  );

  if (!invitations || invitations.length === 0) {
    return <> </>;
  }

  return (
    <>
      <SubHeader label="Pending invitations" className="header-align mt-30" />
      <ListTable data={invitations} columns={columns} showHeader={false} />
    </>
  );
};

export default inject(
  'organisationStore',
  'projectStore',
)(observer(InvitationsTable));
