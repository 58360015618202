import ReactDOM from 'react-dom';
import './BugBoard.scss';
import 'react-virtualized/styles.css';
import { List, AutoSizer } from 'react-virtualized';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DragDropContext,
  DraggableRubric,
  Droppable,
  DropResult,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import BugReportCard, {
  TicketCardSkeleton,
} from '../BugReportCard/BugReportCard';
import CustomLaneHeader from './LaneHeader/CustomLaneHeader';
import FeatureRequestCard, {
  BoardFeatureRequestCardSkeleton,
} from 'components/BugReportCard/FeatureRequestCard';

// Using a higher order function so that we can look up the items data to retrieve
// our item from within the rowRender function
const getRowRender =
  (bugs: Bug[], isFeatureRequest) =>
    ({ index, key, style, parent }: any) => {
      if (bugs.length === 0 || index >= bugs.length) {
        return null;
      }

      const bug: Bug = bugs[index];
      if (!bug) {
        return null;
      }

      const patchedStyle = {
        ...style,
        left: style.left + 4,
        top: style.top + 1,
        marginBottom: 10,
        height: style.height - 10,
        width: 'calc(100% - 8px)',
      };

      return (
        <Draggable draggableId={bug.id} index={index} key={bug.id}>
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
            if ((bug as any).isLoading) {
              if (isFeatureRequest) {
                return (
                  <BoardFeatureRequestCardSkeleton
                    provided={provided}
                    width="calc(100% - 8px)"
                    height={style.height - 10}
                    id={bug.id}
                    style={patchedStyle}
                  />
                );
              } else {
                return (
                  <TicketCardSkeleton
                    provided={provided}
                    width="calc(100% - 8px)"
                    height={style.height - 10}
                    id={bug.id}
                    style={patchedStyle}
                  />
                );
              }
            }
            if (isFeatureRequest) {
              return (
                <FeatureRequestCard
                  bug={bug}
                  provided={provided}
                  isDragging={snapshot.isDragging}
                  style={patchedStyle}
                />
              );
            } else {
              return (
                <BugReportCard
                  bug={bug}
                  provided={provided}
                  isDragging={snapshot.isDragging}
                  style={patchedStyle}
                />
              );
            }
          }}
        </Draggable>
      );
    };

interface BugBoardProps {
  projectStore?: ProjectStore;
}

const BugBoard = ({ projectStore }: BugBoardProps) => {
  const currentLanes = projectStore!.getLanesForFeedbackType();
  const isFeatureRequest =
    projectStore?.currentFeedbackType?.type === 'FEATURE_REQUEST';

  function removeAllIndicators() {
    const dropIndicators = document.querySelectorAll(`.drop-indicator`);
    for (let i = 0; i < dropIndicators.length; i++) {
      dropIndicators[i].remove();
    }
  }

  const handleScroll = (e, laneKey) => {
    const bottom = Math.abs(e.scrollHeight - e.clientHeight - e.scrollTop) < 1;
    if (bottom) {
      projectStore?.fetchAndSetTicketsDataForLane({ laneKey, loadMore: true });
    }
  };

  function createDragIndicator(item) {
    const dropIndicator = document.createElement('div');
    dropIndicator.classList.add('drop-indicator');
    dropIndicator.classList.add(`drop-indicator--${item.droppableId}`);
    dropIndicator.style.top = `${115 * item.index + 1}px`;
    dropIndicator.style.width = `${isFeatureRequest ? 327 : 257}px`;
    let scrollList = document.querySelector(
      `.react-trello-lane--${item.droppableId} .ReactVirtualized__Grid__innerScrollContainer`,
    ) as any;
    if (!scrollList) {
      scrollList = document.querySelector(
        `.react-trello-lane--${item.droppableId} .ReactVirtualized__List`,
      ) as any;
    }
    if (scrollList) {
      scrollList.appendChild(dropIndicator);
    }
  }

  function onDragStart(event: any) {
    removeAllIndicators();

    if (!event.source) {
      return;
    }

    createDragIndicator(event.source);
  }

  function onDragUpdate(event: any) {
    removeAllIndicators();

    if (!event.destination) {
      return;
    }

    createDragIndicator(event.destination);
  }

  function onDragEnd(result: DropResult) {
    removeAllIndicators();

    if (!result.destination) {
      return;
    }

    const { source, destination, draggableId } = result;

    // did not move anywhere
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const targetLane = currentLanes.find(
      (lane) => lane.key === destination.droppableId,
    );
    const targetPosition = destination.index;

    // Recalculate lexorank
    const newLexorank = projectStore!.calculateNewLexorank(
      draggableId,
      targetLane.key,
      targetPosition,
    );

    const didChangeLane = source.droppableId !== destination.droppableId;

    projectStore!.moveBugInProject(
      draggableId,
      destination.droppableId,
      newLexorank.toString(),
      didChangeLane,
    );
  }

  const getNotificationCountForLane = (laneKey) => {
    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (projectStore?.unreadItems[itemKey] &&
        projectStore?.unreadItems[itemKey].type === projectStore?.currentFeedbackType?.type &&
        projectStore?.unreadItems[itemKey].status === laneKey) {
        unreadCount++;
        if (unreadCount > 9) {
          return 10;
        }
      }
    }

    return unreadCount;
  };


  return (
    <div className="bugboard">
      <DragDropContext
        dragAndDropGroup="bugboard"
        onDragEnd={onDragEnd}
        onDragUpdate={onDragUpdate}
        onDragStart={onDragStart}
      >
        <div className="react-trello-board">
          {currentLanes.map((lane, index) => {
            const laneKey = lane.key;
            let cards =
              projectStore?.currentTicketsData &&
                projectStore?.currentTicketsData[laneKey]
                ? projectStore?.currentTicketsData[laneKey].data ?? []
                : [];
                
            let unread = getNotificationCountForLane(laneKey);
            let count =
              projectStore?.currentTicketsData &&
                projectStore?.currentTicketsData[laneKey]
                ? projectStore?.currentTicketsData[laneKey].count ?? 0
                : 0;

            return (
              <Droppable
                key={laneKey}
                droppableId={laneKey}
                mode="virtual"
                renderClone={(
                  provided: DraggableProvided,
                  snapshot: DraggableStateSnapshot,
                  rubric: DraggableRubric,
                ) => {
                  if (isFeatureRequest) {
                    return (
                      <FeatureRequestCard
                        bug={cards[rubric.source.index]}
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        style={{}}
                      />
                    );
                  } else {
                    return (
                      <BugReportCard
                        bug={cards[rubric.source.index]}
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        style={{}}
                      />
                    );
                  }
                }}
              >
                {(
                  droppableProvided: DroppableProvided,
                  snapshot: DroppableStateSnapshot,
                ) => {
                  let itemCount: number = snapshot.isUsingPlaceholder
                    ? cards.length + 1
                    : cards.length;

                  var laneTitle = lane?.title;
                  if (isFeatureRequest && laneKey === 'OPEN') {
                    laneTitle = 'Under review';
                  }

                  const isLoadingData = !projectStore?.currentTicketsData ||
                    !projectStore?.currentTicketsData[laneKey] ||
                    projectStore?.currentTicketsData[laneKey]
                      .isLoading;

                  return (
                    <div
                      className={`react-trello-lane react-trello-lane--${laneKey} ${snapshot.isDraggingOver && 'react-trello-lane--dragging'
                        }`}
                    >
                      <CustomLaneHeader
                        laneKey={laneKey}
                        title={laneTitle}
                        count={count}
                        feedbackTypeFilter={
                          projectStore?.currentFeedbackType?.type ?? ''
                        }
                        isLoading={isLoadingData}
                        unread={unread}
                      />
                      <AutoSizer>
                        {({ height, width }) => {
                          return (
                            <List
                              onScroll={(e) => {
                                handleScroll(e, laneKey);
                              }}
                              key={laneKey}
                              height={height - 32}
                              rowCount={itemCount}
                              rowHeight={115}
                              width={width}
                              ref={(ref) => {
                                if (ref) {
                                  // react-virtualized has no way to get the list's ref that I can so
                                  // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                                  if (ref) {
                                    // eslint-disable-next-line react/no-find-dom-node
                                    const whatHasMyLifeComeTo =
                                      ReactDOM.findDOMNode(ref);
                                    if (
                                      whatHasMyLifeComeTo instanceof HTMLElement
                                    ) {
                                      droppableProvided.innerRef(
                                        whatHasMyLifeComeTo,
                                      );
                                    }
                                  }
                                }
                              }}
                              rowRenderer={getRowRender(
                                cards,
                                isFeatureRequest,
                              )}
                            />
                          );
                        }}
                      </AutoSizer>
                    </div>
                  );
                }}
              </Droppable>
            );
          })}
        </div>
      </DragDropContext>
    </div>
  );
};

export default inject('projectStore')(observer(BugBoard));
