import axios from './Axios';

const createProject = (
  name: string,
  description: string,
  image: string,
  organisationId: string,
  templateId?: string,
): Promise<any> => {
  return axios.post(
    `/projects?organisationId=${organisationId}`,
    {
      name,
      description,
      picture: image,
    },
    {
      params: {
        templateId,
      },
    },
  );
};

const performAITextAction = (
  id: string,
  action: string,
  content?: string,
  style?: string,
) => {
  return axios.post(`/projects/${id}/aitextaction`, {
    action,
    input: content,
    style,
  });
};

const updateProject = (id: string, data: any): Promise<any> => {
  return axios.put(`/projects/${id}`, data);
};

const updateProjectPicture = (id: string, image: string): Promise<any> => {
  return axios.put(`/projects/${id}`, {
    picture: image,
  });
};

const getProjects = (): Promise<any> => {
  return axios.get('/projects');
};

const getProjectsUnreadCount = (): Promise<any> => {
  return axios.get('/projects/unread');
};

const getProjectSurveyUnreadStatus = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/unreadsurveys`);
};

const getProjectFeedbackUnreadStatus = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/unreaditems`);
};

const getProjectUsers = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/users`);
};

const getSharedProject = (
  apiKey: string,
  gleapId?: string,
  gleapHash?: string,
): Promise<any> => {
  return axios.get(`/projects/shared`, {
    headers: {
      'api-token': apiKey,
      'gleap-id': gleapId || '',
      'gleap-hash': gleapHash || '',
    },
  });
};

const getProject = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}`);
};

const loadTeamPreview = (apiKey: string): Promise<any> => {
  return axios.get(`/config/${apiKey}/team`);
};

const resendIntegrations = (id: string): Promise<any> => {
  return axios.put(`/projects/${id}/resendintegrations`, {});
};

const getTicketsForProject = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/tickets`, {
    params: args.query,
  });
};

const getSharedTicketsForProject = (args: {
  apiKey: string;
  gleapId?: string;
  gleapHash?: string;
  query: any;
}) => {
  return axios.get(`/projects/shared/tickets`, {
    params: args.query,
    headers: {
      'api-token': args.apiKey,
      'gleap-id': args.gleapId || '',
      'gleap-hash': args.gleapHash || '',
    },
  });
};

const getBugsOfProjects = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/bugs`);
};

const getProjectAnalytics = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/analytics`);
};

const getProjectStats = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/feedbackanalytics`);
};

const leaveProject = (id: string): Promise<any> => {
  return axios.delete(`/projects/${id}/leave`);
};

const deleteProject = (id: string): Promise<any> => {
  return axios.delete(`/projects/${id}`);
};

const summarizeFeedback = (projectId: string) => {
  return axios.post(`/projects/${projectId}/summarizefeedback`, {});
};

const deleteCompletedBugs = (id: string, status: string, type: string) => {
  let url = `/projects/${id}/bugs?status=${status}`;
  if (type !== 'ALL') {
    url += `&type=${type}`;
  }
  return axios.delete(url);
};

const archiveFeedbackItems = (id: string, status: string, type: string) => {
  return axios.put(`/projects/${id}/bugs/archive`, { status, type });
};

const getArchivedBugs = (id: string, isSpam: boolean, { skip, limit }) => {
  return axios.get(
    `/projects/${id}/bugs/archive?spam=${isSpam}&skip=${skip}&limit=${limit}`,
  );
};

const inviteTeam = (
  id: string,
  emails: string[],
  token: string,
): Promise<any> => {
  return axios.post(`/projects/${id}/invitations`, {
    emails,
    token,
  });
};

const getInvitedTeamMembers = (id: string) => {
  return axios.get(`/projects/${id}/invitations`);
};

const setUserRole = (id: string, userID: string, role: String) => {
  return axios.put(`/projects/${id}/users/${userID}`, {
    role,
  });
};

const deleteUser = (id: string, userID: string) => {
  return axios.delete(`/projects/${id}/users/${userID}`);
};

const migrateIntegrations = (id) => {
  return axios.post(`/projects/${id}/integrations/migrate`);
};

const createConversation = (id, sessionId) => {
  return axios.post(`/projects/${id}/conversation`, {
    session: sessionId,
  });
};

const searchForFeedbackItems = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/tickets/search`, {
    params: args.query,
  });
};

const searchForSessions = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/sessions/search`, {
    params: args.query,
  });
};

const searchForOutbounds = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/outbounds/search`, {
    params: args.query,
  });
};

const searchForHelpcenterArticles = (args: {
  projectId: string;
  query: any;
}) => {
  return axios.get(`/projects/${args.projectId}/helpcenter/search`, {
    params: args.query,
  });
};

const searchForComments = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/comments/search`, {
    params: args.query,
  });
};

const addDomain = (projectID, domainName: string): Promise<any> => {
  return axios.post(`/projects/${projectID}/domains`, {
    domainName,
  });
};

const updateDomainSettings = (
  projectID,
  senderType,
  senderName,
  senderEmail,
): Promise<any> => {
  return axios.put(`/projects/${projectID}/domains`, {
    senderType,
    senderName,
    senderEmail,
  });
};

const updateCustomDomainSettings = (projectID, customDomain): Promise<any> => {
  return axios.put(`/projects/${projectID}/customdomain`, {
    customDomain,
  });
};

const deleteCustomDomainSettings = (projectID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/customdomain`);
};

const updateCustomDomainHelpCenterSettings = (
  projectID,
  customDomain,
): Promise<any> => {
  return axios.put(`/projects/${projectID}/customdomainhelpcenter`, {
    customDomain,
  });
};

const deleteCustomDomainHelpCenterSettings = (projectID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/customdomainhelpcenter`);
};

const clearHelpCenterCache = (projectID): Promise<any> => {
  return axios.put(`/projects/${projectID}/clearhelpcentercache`);
};

const verifyDomain = (projectID): Promise<any> => {
  return axios.put(`/projects/${projectID}/domains/verify`);
};

const removeDomain = (projectID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/domains`);
};

const createBot = (projectID): Promise<any> => {
  return axios.post(`/projects/${projectID}/bots`, {});
};

const updateBot = (
  projectID,
  botId,
  data: {
    name?: string;
    actionFlows?: any;
  },
): Promise<any> => {
  return axios.put(`/projects/${projectID}/bots/${botId}`, data);
};

const getBots = (projectID): Promise<any> => {
  return axios.get(`/projects/${projectID}/bots`);
};

const getBot = (projectID, botID): Promise<any> => {
  return axios.get(`/projects/${projectID}/bots/${botID}`);
};

const deleteBot = (projectID, botID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/bots/${botID}`);
};

const getProjectAnswers = (
  projectID,
  unanswered = false,
  skip = 0,
  limit = 100,
): Promise<any> => {
  return axios.get(
    `/projects/${projectID}/qaanswers?unanswered=${unanswered}&skip=${skip}&limit=${limit}`,
  );
};

const getProjectAnswerStats = (projectID): Promise<any> => {
  return axios.get(`/projects/${projectID}/qaanswerstats`);
};

const updateProjectAnswer = (
  projectID,
  id: string,
  data: any,
): Promise<any> => {
  return axios.put(`/projects/${projectID}/qaanswers/${id}`, data);
};

const deleteProjectAnswer = (projectID, id: string): Promise<any> => {
  return axios.delete(`/projects/${projectID}/qaanswers/${id}`);
};

const createProjectAnswer = (projectID, data: any): Promise<any> => {
  return axios.post(`/projects/${projectID}/qaanswers`, data);
};

export {
  getBot,
  getBots,
  createBot,
  updateBot,
  deleteBot,
  getProjectAnswers,
  createProjectAnswer,
  updateProjectAnswer,
  getProjects,
  getSharedProject,
  getProjectUsers,
  createProject,
  getTicketsForProject,
  getSharedTicketsForProject,
  getBugsOfProjects,
  getProject,
  inviteTeam,
  getInvitedTeamMembers,
  updateProject,
  leaveProject,
  updateProjectPicture,
  deleteProject,
  deleteCompletedBugs,
  archiveFeedbackItems,
  getArchivedBugs,
  resendIntegrations,
  getProjectAnalytics,
  setUserRole,
  deleteUser,
  migrateIntegrations,
  searchForFeedbackItems,
  searchForOutbounds,
  searchForSessions,
  searchForHelpcenterArticles,
  searchForComments,
  getProjectsUnreadCount,
  createConversation,
  addDomain,
  verifyDomain,
  removeDomain,
  updateDomainSettings,
  updateCustomDomainSettings,
  deleteCustomDomainSettings,
  updateCustomDomainHelpCenterSettings,
  deleteCustomDomainHelpCenterSettings,
  getProjectSurveyUnreadStatus,
  getProjectFeedbackUnreadStatus,
  clearHelpCenterCache,
  getProjectStats,
  summarizeFeedback,
  performAITextAction,
  loadTeamPreview,
  getProjectAnswerStats,
  deleteProjectAnswer,
};
