import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import UserTable from 'components/Table/UserTable';
import { inject } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import InvitationsTable from 'components/InvitationsTable/InvitationsTable';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import './Team.scss';
import InfoBox from 'components/InfoBox/InfoBox';

interface TeanProps {
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
}

const Tean = ({ modalStore, organisationStore }: TeanProps) => {
  return (
    <PageContainer>
      <PageHeadLine title="Team">
        <div className="header-content-right">
          {organisationStore?.isOrganisationAdmin && (
            <PrimaryButton
              label="Invite people"
              icon="plus"
              iconSideRight={false}
              onClick={() => {
                modalStore!.openModal(MODALTYPE.INVITE_PEOPLE, {
                  type: 'organisation',
                });
              }}
            />
          )}
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <UserTable />
        <InvitationsTable />
        <InfoBox className='mt-30'>
        With Gleap, you can invite people at the <b>organization</b> or <b>project level</b>. You are currently editing organization level access. To manage project members, open project team settings.
        </InfoBox>
      </PageContent>
    </PageContainer>
  );
};

export default inject('modalStore', 'organisationStore')(Tean);
