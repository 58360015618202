import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import OutboundMessagePreview from 'components/OutboundMessagePreview/OutboundMessagePreview';
import { runInAction } from 'mobx';
import { Outbound } from 'models/Outbound';
import Switch from 'react-switch';
import React, { useState } from 'react';
import './MessageConfiguration.scss';

interface MessageConfigurationProps {
  outboundRule: Outbound;
}

const MessageConfiguration = ({ outboundRule }: MessageConfigurationProps) => {
  const [toggleState, setToggleState] = useState(false);

  return (
    <div className="message-container">
      <div className="options-group">
        <div className="options-group-header">Message</div>
        <div className="input-label">Message</div>
        <textarea
          className="default-textarea"
          placeholder="Your message..."
          value={outboundRule.message}
          onChange={(e) => {
            runInAction(() => {
              outboundRule.message = e.target.value;
            });
          }}
        />
        <div className="input-label mt-20">Sender</div>
        <FeedbackUserFilter
          hasBorder
          value={outboundRule.sender}
          truncatePreview={50}
          truncate={50}
          placeholder="Select user"
          onValueChanged={(value) => {
            runInAction(() => {
              if (value) {
                outboundRule.sender = value;
                setToggleState(!toggleState);
              }
            });
          }}
        />
        <div className="filter-form mt-30">
          <div className="input-label">Message type</div>
          <div className="mt-10">
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Messenger message</div>
              <input
                type="radio"
                name="continuously"
                checked={outboundRule.actionType === 'message'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.actionType = 'message';
                    setToggleState(!toggleState);
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Notification only (don't show message on click)</div>
              <input
                type="radio"
                name="once"
                checked={outboundRule.actionType === 'notification'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.actionType = 'notification';
                    setToggleState(!toggleState);
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
          </div>
        </div>
        <div className="input-label mt-30">Sound</div>
        <div className="switch-container mb-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                outboundRule.sound = checked;
                setToggleState(!toggleState);
              });
            }}
            checked={
              outboundRule.sound === undefined || outboundRule.sound === true
            }
          />
          <span>Notification sound</span>
        </div>
      </div>
      <div className="message-preview">
        <OutboundMessagePreview outbound={outboundRule} />
      </div>
    </div>
  );
};

export default MessageConfiguration;
