/* eslint-disable jsx-a11y/accessible-emoji */
import { ampli } from 'ampli';
import FeedbackButtonBottomLeft from 'assets/icons/FeedbackButtonBottomLeft.svg';
import FeedbackButtonBottomRight from 'assets/icons/FeedbackButtonBottomRight.svg';
import FeedbackButtonClassic from 'assets/icons/FeedbackButtonClassic.svg';
import FeedbackButtonClassicBottom from 'assets/icons/FeedbackButtonClassicBottom.svg';
import FeedbackButtonClassicLeft from 'assets/icons/FeedbackButtonClassicLeft.svg';
import FeedbackButtonCleared from 'assets/icons/FeedbackButtonCleared.svg';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import 'rc-slider/assets/index.css';
import { useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import { useParams } from 'react-router';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FeedbackButtonSettings.scss';

const feedbackButtonPositions = [
  {
    label: 'Left',
    value: 'BOTTOM_LEFT',
    img: FeedbackButtonBottomLeft,
  },
  {
    label: 'Right',
    value: 'BOTTOM_RIGHT',
    img: FeedbackButtonBottomRight,
  },
  {
    label: 'Hidden',
    value: 'BUTTON_NONE',
    img: FeedbackButtonCleared,
  },
  {
    label: 'Classic left',
    value: 'BUTTON_CLASSIC_LEFT',
    img: FeedbackButtonClassicLeft,
    isClassic: true,
  },
  {
    label: 'Classic bottom',
    value: 'BUTTON_CLASSIC_BOTTOM',
    img: FeedbackButtonClassicBottom,
    isClassic: true,
  },
  {
    label: 'Classic right',
    value: 'BUTTON_CLASSIC',
    img: FeedbackButtonClassic,
    isClassic: true,
  },
];

interface FeedbackButtonSettingsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function FeedbackButtonSettings({
  projectStore,
  modalStore,
}: FeedbackButtonSettingsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  if (!projectStore?.currentProject) {
    return <Loading />;
  }

  if (!flowConfig || Object.keys(flowConfig).length === 0) {
    return <Loading />;
  }

  const renderWebFeedbackButtonOptions = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">Color</div>
          <div className="widget-color-picker">
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={flowConfig.buttonColor}
                onChangeComplete={(color) => {
                  flowConfig.buttonColor = color.hex;

                  ampli.widgetCustomized({
                    projectId,
                    name: 'color',
                  });
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: flowConfig.buttonColor,
                }}
              />
            </div>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Position & type</div>
          <div className="positiontypeselection">
            {feedbackButtonPositions
              .filter((e) => !e.isClassic)
              .map((feedbackButtonPosition) => {
                return (
                  <div
                    key={feedbackButtonPosition.value}
                    className={
                      flowConfig.feedbackButtonPosition ===
                        feedbackButtonPosition.value
                        ? 'active'
                        : ''
                    }
                    onClick={() => {
                      flowConfig.feedbackButtonPosition =
                        feedbackButtonPosition.value;

                      ampli.widgetCustomized({
                        projectId,
                        name: 'buttonPosition',
                      });
                    }}
                  >
                    <img
                      src={feedbackButtonPosition.img}
                      alt={feedbackButtonPosition.label}
                    />
                    <div>{feedbackButtonPosition.label}</div>
                  </div>
                );
              })}
          </div>
          <div className="positiontypeselection mb-20">
            {feedbackButtonPositions
              .filter((e) => e.isClassic)
              .map((feedbackButtonPosition) => {
                return (
                  <div
                    key={feedbackButtonPosition.value}
                    className={
                      flowConfig.feedbackButtonPosition ===
                        feedbackButtonPosition.value
                        ? 'active'
                        : ''
                    }
                    onClick={() => {
                      flowConfig.feedbackButtonPosition =
                        feedbackButtonPosition.value;

                      ampli.widgetCustomized({
                        projectId,
                        name: 'buttonPosition',
                      });
                    }}
                  >
                    <img
                      src={feedbackButtonPosition.img}
                      alt={feedbackButtonPosition.label}
                    />
                    <div>{feedbackButtonPosition.label}</div>
                  </div>
                );
              })}
          </div>
          {(flowConfig.feedbackButtonPosition === 'BOTTOM_LEFT' ||
            flowConfig.feedbackButtonPosition === 'BOTTOM_RIGHT') && (
              <div className="customize-feedback-button-props mt-20">
                <div className="widget-logo-upload">
                  <div className="input-label mb-10">Button icon</div>
                  <ImageUpload
                    customBgColor={flowConfig.buttonColor}
                    image={flowConfig.buttonLogo}
                    label="Logo"
                    editable
                    accept='image/jpeg, image/png'
                    uploadPath="feedback_widgets"
                    afterImageUpload={(imageURL) => {
                      flowConfig.buttonLogo = imageURL;

                      ampli.widgetCustomized({
                        projectId,
                        name: 'buttonImage',
                      });
                    }}
                  />
                </div>
                <div className="button-offsets">
                  <TextInput
                    name="X offset"
                    placeholder=""
                    type="number"
                    error=""
                    value={flowConfig.buttonX ?? 20}
                    label="X offset"
                    onChange={(text) => {
                      flowConfig.buttonX = text;
                    }}
                  />
                  <TextInput
                    name="Y offset"
                    placeholder=""
                    type="number"
                    error=""
                    className="mt-10"
                    value={flowConfig.buttonY ?? 20}
                    label="Y offset"
                    onChange={(text) => {
                      flowConfig.buttonY = text;
                    }}
                  />
                </div>
              </div>
            )}
          {(flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC' ||
            flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_BOTTOM' ||
            flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_LEFT') && (
              <div className="widget-logo-upload">
                <TextInput
                  name="Button text"
                  placeholder="Feedback"
                  type="text"
                  error=""
                  value={flowConfig.widgetButtonText}
                  label="Button text"
                  onChange={(text) => {
                    flowConfig.widgetButtonText = text;
                  }}
                />
              </div>
            )}
          {/*
          <div className="switch-container mt-30">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.hideForGuests = !flowConfig.hideForGuests;

                ampli.widgetCustomized({
                  projectId,
                  name: 'buttonText',
                });
              }}
              checked={flowConfig.hideForGuests ?? false}
            />
            <span>Hide widget button for guests (not identified users)</span>
          </div>
           */}
        </div>
      </>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Feedback button">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent className="page-content" hasTitle>
        <div className="widget-general-settings">
          <div className="widget-general-settings-content">
            {renderWebFeedbackButtonOptions()}
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'modalStore',
)(observer(FeedbackButtonSettings));
