import { useState } from 'react';
import './CreateOrganisationOnboarding.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { observer, inject } from 'mobx-react';
import { HeadLine } from 'components/HeadLine/HeadLine';
import AvatarImage from 'components/AvatarImage/AvatarImage';
import camIcon from '../../../../assets/icons/cam.svg';

interface CreateOrganisationOnboardingProps {
  organisationStore?: OrganisationStore;
}

export const CreateOrganisationOnboarding = ({
  organisationStore,
}: CreateOrganisationOnboardingProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [orgaName, setOrgaName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const createOrganization = () => {
    setIsLoading(true);
    organisationStore!
      .createOrganisation(orgaName, imageUrl)
      .then((createdOrganisation: any) => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (!organisationStore) {
    return <></>;
  }

  return (
    <div className='organisation-onboarding'>
      <HeadLine
        title="Create your organization 🦸‍♀️"
        subtitle="Gleap is made for teams. Let's start by creating your organization."
        className="mb-20"
      />
      <AvatarImage
        image={imageUrl !== '' ? imageUrl : camIcon}
        className={`${!imageUrl && 'placeholder-img'}`}
        editable
        afterImageUpload={(finalImageUrl) => {
          setImageUrl(finalImageUrl);
        }}
      />
      <TextInput
        label="Name"
        required
        placeholder="Organization name"
        type="text"
        name="organame"
        className="mt-20 max-form-card"
        error=''
        onChange={(value) => {
          setOrgaName(value);
        }}
      />
      <PrimaryButton
        className="mt-20"
        icon='arrow-right'
        iconSideRight={true}
        label="Create"
        isLoading={isLoading}
        disabled={orgaName.length === 0}
        onClick={() => {
          createOrganization();
        }}
      />
    </div>
  );
};

export default inject('organisationStore')(observer(CreateOrganisationOnboarding));
