/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { BugPriorities } from '../../models/Bug';
import './BugPrioritySelection.scss';

interface BugPrioritySelectionProps {
  value: string;
  onValueChange?: (selectedValue: string) => void;
  className?: string;
}

const BugPrioritySelection = ({
  value,
  onValueChange,
  className,
}: BugPrioritySelectionProps) => {
  const bugPriorities = BugPriorities;

  const renderButtons = () => {
    return Object.keys(bugPriorities).map((bugPriorityKey) => {
      const bugPriority = bugPriorities[bugPriorityKey];
      const priorityButtonClassName = classNames('priority-button', {
        'priority-button--active': bugPriority.value === value,
      });
      return (
        <button
          key={bugPriorityKey}
          type="button"
          onClick={() => {
            if (onValueChange) {
              onValueChange(bugPriority.value);
            }
          }}
          className={priorityButtonClassName}
        >
          <div
            className="status-bubble"
            style={{
              backgroundColor: bugPriority.color,
            }}
          />
          {bugPriority.title}
        </button>
      );
    });
  };

  return (
    <div className={`priority-selection ${className}`}>{renderButtons()}</div>
  );
};

export default BugPrioritySelection;
