import { useMemo } from 'react';
import './FeedbackActionsEditor.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ListTable, {
  CellGestureDetector,
  CellText,
} from 'components/ListTable/ListTable';
import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import { runInAction } from 'mobx';
import { ampli } from 'ampli';
import { useNavigate, useParams } from 'react-router';

interface FeedbackActionsEditorProps {
  projectStore?: ProjectStore;
}

const FeedbackActionsEditor = ({
  projectStore,
}: FeedbackActionsEditorProps) => {
  const feedbackActions = projectStore?.currentProject?.projectActions;
  const navigate = useNavigate();
  const { projectId } = useParams();

  const showFeedbackFlowDetails = (feedbackFlowId) => {
    navigate(`/projects/${projectId}/widget/flows/${feedbackFlowId}`);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              showFeedbackFlowDetails(row.cell.row.values.actionKey);
            }}
            label={row.value}
          />
        ),
      },
      {
        Header: 'Feedback type',
        accessor: 'feedbackType',
        Cell: (row) => <CellText text={row.value} />,
      },
      {
        Header: 'Feedback action',
        accessor: 'actionKey',
        Cell: (row) => <CellText text={row.value} />,
      },
    ],
    [],
  );

  return (
    <PageContainer>
      <PageHeadLine
        title='Feedback flows'
      >
        <div className="header-content-right">
          {projectStore?.isProjectAdmin && (
            <PrimaryButton
              label="Add feedback flow"
              icon="plus"
              iconSideRight={false}
              onClick={() => {
                runInAction(() => {
                  if (feedbackActions) {
                    const actionKey = Math.random().toString(36).substring(7);
                    feedbackActions[actionKey] = {
                      title: 'Custom feedback flow',
                      description: 'Your feedback means a lot to us.',
                      thanksMessage:
                        'Thanks for your feedback. We will be in touch shortly',
                      feedbackType: 'BUG',
                      disableUserScreenshot: false,
                      enableUserScreenRecording: false,
                      form: [
                        {
                          title: 'Message',
                          placeholder: 'Your message',
                          type: 'textarea',
                          name: 'description',
                          required: true,
                        },
                      ],
                      excludeData: {
                        customData: false,
                        metaData: false,
                        consoleLog: false,
                        networkLogs: false,
                        customEventLog: false,
                        screenshot: false,
                        replays: false,
                      },
                      default: false,
                    };

                    showFeedbackFlowDetails(actionKey);

                    ampli.feedbackFlowAdded();
                  }
                });
              }}
            />
          )}
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        {feedbackActions &&
          Object.keys(feedbackActions) &&
          Object.keys(feedbackActions).length > 0 && (
            <>
              <InfoBox className="mb-20">
                <>
                  You can think of feedback flows as form steps that will be executed once triggered. <a
                    target="_blank"
                    href="https://docs.gleap.io/guides/Feedback%20flows/" rel="noreferrer"
                  >
                    <i className="ml-2 fa-regular fa-book-open" /> Learn more
                  </a>
                </>
              </InfoBox>
              <ListTable
                data={Object.keys(feedbackActions)
                  .map((key) => {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    feedbackActions[key]['actionKey'] = key;
                    return feedbackActions[key];
                  })
                  .filter((action) => (action.feedbackType !== 'SURVEY' && action.feedbackType !== 'surveys'))}
                columns={columns}
              />
            </>
          )}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(FeedbackActionsEditor));
