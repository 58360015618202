import { useEffect, useState } from 'react';
import './FeedbackActionSummaryItem.scss';
import Chart from 'react-apexcharts';
import { getEmojiForVal } from 'helper/ScoreFormater';
import ListTableVirtualized from 'components/ListTableVirtualized/ListTableVirtualized';
import FeedbackTextAnswer from 'components/FeedbackTextAnswer/FeedbackTextAnswer';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';

const npsOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface FeedbackActionSummaryItemProps {
  fullForm?: any;
  formItem: any;
  stats: any;
  projectStore?: ProjectStore;
}

const FeedbackActionSummaryItem = ({
  fullForm,
  formItem,
  stats,
  projectStore,
}: FeedbackActionSummaryItemProps) => {
  const [axisData, setAxisData] = useState([] as any);
  const [chartData, setChartData] = useState([] as any);
  const [listData, setListData] = useState([] as any);

  var npsKey = null;
  if (fullForm) {
    for (let i = 0; i < fullForm.length; i++) {
      if (fullForm[i].type === "netpromoterscore") {
        npsKey = fullForm[i].name;
      }
    }
  }

  useEffect(() => {
    generateStatisticsForType();
  }, [stats]);

  const generateStatisticsForType = () => {
    switch (formItem.type) {
      case 'onetofive':
        getOneToFiveScaleStatistics();
        break;
      case 'multiplechoice':
        getMultiChoiceStatistics();
        break;
      case 'rating':
        getRatingStatistcs();
        break;
      case 'text':
        getTextStatistics();
        break;
      case 'textarea':
        getTextStatistics();
        break;
      default:
        break;
    }
  };

  const getChartData = (currentAxisData) => {
    if (!currentAxisData) {
      return null;
    }

    const values: any[] = [];

    for (let i = 0; i < stats.length; i++) {
      const { formData } = stats[i];
      if (!formData || !formItem.name) {
        continue;
      }

      const currentValue = formData[formItem.name];
      if (formItem.type === 'rating') {
        values.push(getEmojiForVal(currentValue * 10));
      } else {
        values.push(currentValue);
      }
    }

    const currentChartData: any[] = [];
    for (let i = 0; i < currentAxisData.length; i++) {
      const filteredChartAxisData = values.filter(
        (element) => element === currentAxisData[i],
      );

      currentChartData.push(filteredChartAxisData.length);
    }

    setAxisData(currentAxisData);
    setChartData(currentChartData);
  };

  const getOneToFiveScaleStatistics = () => {
    const upToNumber = formItem.upToNumber ? formItem.upToNumber : 5;
    const fromNumber = !isNaN(formItem.fromNumber) ? formItem.fromNumber : 0;
    const currentAxisData = Array.from(
      { length: upToNumber - (fromNumber - 1) },
      (_, i) => String(fromNumber + i),
    );
    getChartData(currentAxisData);
  };

  const getMultiChoiceStatistics = () => {
    const currentAxisData = formItem.choices;
    getChartData(currentAxisData);
  };

  const getRatingStatistcs = () => {
    const currentAxisData = ['🤩', '🙂', '😐', '😢', '😡'];
    getChartData(currentAxisData);
  };

  const getTextStatistics = () => {
    const values: any[] = [];

    for (let i = 0; i < stats.length; i++) {
      const { formData } = stats[i];
      if (!formData || !formItem.name) {
        continue;
      }

      const currentValue = formData[formItem.name];
      if (currentValue) {
        var data = { content: currentValue, shareToken: stats[i].shareToken, createdAt: stats[i].createdAt };
        if (npsKey && formData[npsKey] !== undefined && formData[npsKey] !== null) {
          data['nps'] = formData[npsKey];
        }
        values.push(data);
      }
    }

    setListData(values);
  };

  const chartOptions: any = {
    chart: {
      id: 'Feedbackaction',
      toolbar: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    fill: {
      colors: ['#485bff'],
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: axisData,
    },
  };

  const chartSeries = [
    {
      name: '',
      data: chartData,
    },
  ];

  const buildChartContent = () => {
    return (
      <div className="chart-wrapper">
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={350}
          width="100%"
        />
      </div>
    );
  };

  const buildNPSContent = () => {
    var detractorsCount = 0;
    var passivesCount = 0;
    var promotersCount = 0;
    var values = {};
    const overallCount = stats.length;
    for (let i = 0; i < stats.length; i++) {
      const { formData } = stats[i];
      if (!formData || !formItem.name) {
        continue;
      }

      const currentValue = formData[formItem.name];
      if (currentValue >= 9) {
        promotersCount++;
      } else if (currentValue >= 7) {
        passivesCount++;
      } else {
        detractorsCount++;
      }
      values[currentValue] = values[currentValue] + 1 || 1;
    }
    var npsScore = Math.round(
      ((promotersCount - detractorsCount) / overallCount) * 100,
    );

    const npsType = (nps) => {
      if (nps >= 9) {
        return 'promoters';
      } else if (nps >= 7) {
        return 'passives';
      } else {
        return 'detractors';
      }
    };

    return (
      <div className="nps-wrapper">
        <div className="nps-overview">
          <div className="chart-wrapper">
            <Chart
              options={{
                labels: ['Promoters', 'Passives', 'Detractors'],
                plotOptions: {
                  pie: {
                    dataLabels: {
                      offset: 8,
                    },
                    donut: {
                      labels: {
                        show: true,
                      },
                      size: '95%',
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  formatter: function (val) {
                    return Math.round(parseInt(String(val))) + '%';
                  },
                  dropShadow: {
                    enabled: false,
                  },
                },
                colors: ['#19b097', '#f4ba56', '#ea555a'],
              }}
              series={[promotersCount, passivesCount, detractorsCount]}
              type="donut"
              height={360}
              width={360}
            />
          </div>
          <div className="nps-score">
            <div className="nps-score-value">
              {isNaN(npsScore)
                ? '--'
                : `${npsScore > 0 ? '+' : ''}${npsScore}`}
            </div>
            <div className="nps-score-label">NPS®</div>
          </div>
        </div>
        <div className="nps-info">NPS® = %PROMOTERS - %DETRACTORS</div>
        <div className="nps-bubble-wrapper">
          {npsOptions.map((nps) => {
            const countValue = values[nps] || 0;

            return (
              <div
                className={`nps-bubble nps-bubble--${npsType(nps)}`}
                key={`nps-${nps}-${countValue}`}
              >
                <div className="nps-bubble-circle">{nps}</div>
                <div className="nps-bubble-label">{countValue}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const buildAnswerListContent = () => {
    if (!listData || listData.length === 0) {
      return <></>;
    }

    return (
      <ListTableVirtualized
        data={listData}
        renderCell={(row, index) => {
          return (<FeedbackTextAnswer projectStore={projectStore} answer={row} key={`${index}`} />);
        }}
      />
    );
  };

  const buildActionContent = () => {
    switch (formItem.type) {
      case 'onetofive':
        return buildChartContent();
      case 'netpromoterscore':
        return buildNPSContent();
      case 'multiplechoice':
        return buildChartContent();
      case 'rating':
        return buildChartContent();
      case 'text':
        return buildAnswerListContent();
      case 'textarea':
        return buildAnswerListContent();
      default:
        return <></>;
    }
  };

  const getTypeLabel = () => {
    switch (formItem.type) {
      case 'netpromoterscore':
        return 'Net Promoter Score®';
      case 'onetofive':
        return 'Numeric scale';
      case 'multiplechoice':
        return 'Single choice';
      case 'rating':
        return 'Smiley rating';
      case 'text':
        return 'Short text';
      case 'upload':
        return 'File upload';
      case 'textarea':
        return 'Long text';
      default:
        return '';
    }
  };

  return (
    <div className="feedback-action-summary-item-container">
      <div className="header">
        <div className="title">{formItem.title}</div>
        <div className="type">{getTypeLabel()}</div>
      </div>
      {buildActionContent()}
    </div>
  );
};

export default inject(
  'projectStore',
)(observer(FeedbackActionSummaryItem));
