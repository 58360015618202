import { uploadFile } from 'services/FileHttpService';

export const uploadFileToServer = async (file: File, uploadPath?: string) => {
  try {
    const response = await uploadFile(file, uploadPath);
    if (response.status === 200) {
      const uploadURL = response.data;
      return uploadURL.fileUrl;
    }
    return null;
  } catch (exp) {
    return null;
  }
};
