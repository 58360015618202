import { useEffect, useState } from 'react';
import './ProjectInstallation.scss';
import { inject, observer } from 'mobx-react';
import InstructionCard from 'components/InstructionCard/InstructionCard';
import { Platforms } from 'models/Platform';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { Title } from 'components/Title/Title';
import { ProjectStore } from 'stores/private/ProjectStore';
import TabButton from 'components/TabButton/TabButton';
import ApiKey from 'components/ApiKey/ApiKey';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { ampli } from 'ampli';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import LinkButton from 'components/LinkButton/LinkButton';

interface ProjectInstallationProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const ProjectInstallation = ({
  modalStore,
  projectStore,
}: ProjectInstallationProps) => {
  const [filter, setFilter] = useState('all');
  const [showApiKey, setShowApiKey] = useState(false);
  const currentProject = projectStore?.currentProject;

  useEffect(() => {
    ampli.installationInstructionsViewed({
      projectId: currentProject?.id,
    });
  }, []);

  if (!currentProject) {
    return <></>;
  }

  const openInstruction = (platform: any) => {
    modalStore?.openModalSmart(MODALTYPE.SDK_INSTALLATION_TUTORIAL, platform);
  };

  const renderPlatforms = () => {
    const items = Platforms.filter((item) => {
      if (filter === 'all') {
        return true;
      }
      return item.type.includes(filter);
    });

    return items.map((item, key) => {
      return (
        <InstructionCard
          data-name={item.title}
          item={item}
          key={key}
          onClick={openInstruction}
        />
      );
    });
  };

  const renderHeaderSection = () => {
    if (!projectStore?.currentProject?.sdkInstalled) {
      return (
        <>
          <div className="sdk-setup-left mt-5 mb-10">
            <div className="title mb-5">Need help getting started?</div>
            <div className="text mb-15">
              Invite your dev team or schedule a support session with one of our experts.
            </div>
            <PrimaryButton
              label="Invite your team"
              icon="circle-user"
              className="mr-10"
              onClick={() => {
                ampli.peopleInvitedLinkClicked();
                modalStore!.openModal(MODALTYPE.INVITE_PEOPLE);
              }}
            />
            <LinkButton
              onClick={() => {
                window.open('https://calendly.com/gleap/session', '_blank');
              }}
              icon="message-question"
              iconSideRight={false}
              label="Book a demo"
            />
          </div>
          <>
            <div className="text bold mt-40">
              <b>Your API key:</b>
            </div>
            <div className="mt-10">
              <ApiKey apiKey={projectStore?.currentProject?.apiKey ?? '--'} />
            </div>
          </>
        </>
      );
    }

    return (
      <div className="sdk-setup-left mt-5 mb-10">
        {currentProject?.sdkInstalled && (
          <div className='sdk-installed-successfully'>You installed the Gleap SDK successfully. Great job 🎉</div>
        )}
        <div className="title mb-10">Install the Gleap SDK</div>
        <div className="text">
          Get started by following our installation guides or{' '}
          <a
            className="link"
            href="https://docs.gleap.io/"
            target="_blank"
            rel="noreferrer"
          >
            check out our docs
          </a>
          .
        </div>
        {!showApiKey ? (
          <LinkButton
            className="mt-20"
            label="Show API key"
            onClick={() => {
              setShowApiKey(true);
            }}
          />
        ) : (
          <>
            <div className="text mt-20">
              <b>Your API key:</b>
            </div>
            <div className="mt-15">
              <ApiKey apiKey={projectStore?.currentProject?.apiKey ?? '--'} />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <PageContainer className="project-installation">
      <PageHeadLine title="Install the Gleap SDK">
        <div className="header-content-right hide-on-mobile">
          {projectStore?.currentProject?.sdkInstalled && (
            <>
              <LinkButton
                onClick={() => {
                  window.open('https://calendly.com/gleap/session', '_blank');
                }}
                icon="circle-question"
                iconSideRight={false}
                className="mr-10"
                label="Schedule a support session"
              />
              <PrimaryButton
                label="Invite your team"
                icon="circle-user"
                onClick={() => {
                  ampli.peopleInvitedLinkClicked();
                  modalStore!.openModal(MODALTYPE.INVITE_PEOPLE);
                }}
              />
            </>
          )}
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <div className="project-installation-inner-container">
          <div className="project-installation-body mt-50">
            {renderHeaderSection()}
            <div className="text bold mt-40 mb-15">
              <b>Choose your platform to get started:</b>
            </div>
            <div className="tab-buttons">
              <TabButton
                active={filter === 'all'}
                label="All"
                onClick={() => {
                  setFilter('all');
                }}
              />
              <TabButton
                active={filter === 'websites'}
                label="Website"
                onClick={() => {
                  setFilter('websites');
                }}
              />
              <TabButton
                active={filter === 'webapps'}
                label="Web app"
                onClick={() => {
                  setFilter('webapps');
                }}
              />
              <TabButton
                active={filter === 'mobileapps'}
                label="App"
                onClick={() => {
                  setFilter('mobileapps');
                }}
              />
            </div>
            <div className="platforms mb-30 mt-15">{renderPlatforms()}</div>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(ProjectInstallation));
