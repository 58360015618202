/* eslint-disable @typescript-eslint/no-unused-expressions */
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import { useEffect, useState } from 'react';
import { unsubscribeSession } from 'services/SessionHttpService';
import './Unsubscribe.scss';

const Unsubscribe = () => {
  const [unsubscribed, setUnsubscribed] = useState(false);

  useEffect(() => {
    const parsedParams = new URLSearchParams(window.location.search);
    if (parsedParams) {
      const gleapId = parsedParams.get('u');
      const gleapHash = parsedParams.get('h');
      const apiToken = parsedParams.get('a');

      unsubscribeSession(gleapId!, gleapHash!, apiToken!, true).then(() => {
        setUnsubscribed(true);
      });
    }
  }, []);

  return (
    <div className="unsubscribe-page">
      {unsubscribed ? <>
        <div className='input-label'>
          You have been successfully unsubscribed!
        </div>
        <div className='text'>
          You will no longer receive any emails from us.
        </div>
      </> : <>
        <LoadingAnimationMedium />
      </>}
    </div>
  );
};
export default Unsubscribe;
