import { calculateContrast } from 'helper/Color';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './WidgetButtonPreview.scss';

interface WidgetButtonPreviewProps {
  projectStore?: ProjectStore;
}

const WidgetButtonPreview = ({ projectStore }: WidgetButtonPreviewProps) => {
  const flowConfig = projectStore?.flowConfig;
  if (!flowConfig) {
    return <></>;
  }

  return (
    <>
      {flowConfig.feedbackButtonPosition &&
        flowConfig.feedbackButtonPosition !== 'BUTTON_NONE' &&
        !flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
          <div
            className={`widget-bugbattle--feedback-new-button ${
              flowConfig.feedbackButtonPosition === 'BOTTOM_LEFT'
                ? 'widget-bugbattle--feedback-new-button--left'
                : ''
            }`}
          >
            <div
              className="widget-bugbattle--feedback-new-button-button"
              style={{
                backgroundColor: flowConfig.buttonColor ?? '#485bff',
                color: calculateContrast(flowConfig.buttonColor ?? '#485bff'),
              }}
              onClick={() => {}}
            >
              {flowConfig.buttonLogo && flowConfig.buttonLogo.length > 0 ? (
                <img src={flowConfig.buttonLogo} />
              ) : (
                <svg
                  className="widget-default-icon"
                  width="700px"
                  height="700px"
                  viewBox="0 0 700 700"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      transform="translate(77.000000, 119.000000)"
                      fill={calculateContrast(
                        flowConfig.buttonColor ?? '#485bff',
                      )}
                      fillRule="nonzero"
                    >
                      <path d="M476.856373,0 L68.122339,0 C30.601832,0 0,30.5935323 0,67.1461527 L0,372.655827 C0,410.166158 30.601832,439.80198 68.122339,439.80198 L170.305847,439.80198 L170.305847,528.23059 C170.305847,538.605788 182.280477,544.671288 190.657396,538.552581 L323.602398,438.844269 L476.877661,438.844269 C514.398168,438.844269 545,408.250737 545,371.698116 L545,67.1461527 C544.978712,30.5935323 515.28163,0 476.856373,0 Z"></path>
                    </g>
                  </g>
                </svg>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default inject('projectStore')(observer(WidgetButtonPreview));
