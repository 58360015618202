import { ampli } from 'ampli';
import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import Filter from 'components/Filter/Filter';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellLink,
  CellSession,
  CellTextIcon
} from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SessionStore } from 'stores/private/SessionStore';
import './ProjectContacts.scss';

const contactFilterStates = [
  { name: 'All', icon: "user-group", value: 'all' },
  { name: 'Users', icon: "user", value: 'users' },
  { name: 'Guests', icon: "user-secret", value: 'guests' },
];

interface ProjectContactsProps {
  projectStore?: ProjectStore;
  sessionStore?: SessionStore;
}

const ProjectContacts = ({
  projectStore,
  sessionStore,
}: ProjectContactsProps) => {
  const { projectId } = useParams();
  const sessions = sessionStore!.sessionsDataList.data;

  const [fullTextFilter, setFullTextFilter] = useState('');
  const [type, setType] = useState(null as any);
  const [onlyWithFeedback, setWithFeedback] = useState('false');

  useEffect(() => {
    ampli.contactsViewed({
      projectId,
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        width: '20%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellSession
            session={row.value}
            url={`/projects/${projectId}/sessions/${row.value.id}`}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '20%',
        Header: 'User id',
        accessor: 'userId',
        Cell: (row) => {
          if (row.value && row.value.length > 0) {
            return (
              <CellTextIcon
                icon="user"
                text={row.value}
              />);
          }

          return null;
        },
      },
      {
        className: 'hide-on-mobile',
        width: '25%',
        Header: 'Email',
        accessor: 'email',
        Cell: (row) => (
          <CellLink link={`mailto:${row.value}`} text={row.value} />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '15%',
        Header: 'Last activity',
        accessor: 'lastActivity',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
    ],
    [],
  );

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  const debounceSearch = useCallback(
    debounce(
      (_type, _withFeedback, _fullTextFilter) =>
        sessionStore!.searchForSessionItems({
          type: _type,
          withFeedback: _withFeedback,
          searchTerm: _fullTextFilter,
        }),
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (projectStore?.currentProject && type && onlyWithFeedback) {
      debounceSearch(type, onlyWithFeedback, fullTextFilter);
    }
  }, [fullTextFilter]);

  useEffect(() => {
    sessionStore!.sessionsDataList.pageIndex = 0;

    if (projectStore?.currentProject && type) {
      sessionStore!.getSessionItems({
        type,
        withFeedback: onlyWithFeedback === 'true',
      });
    }
  }, [projectStore?.currentProject, type, onlyWithFeedback]);

  useEffect(() => {
    let contactsType: any;
    let contactsWithFeedback: any;
    try {
      contactsType = localStorage.getItem('contactsType');
      contactsWithFeedback = localStorage.getItem('contactsWithFeedback');
      // eslint-disable-next-line no-empty
    } catch (exp) { }
    if (contactsType) {
      setType(contactsType);
    } else {
      setType('all');
    }
    if (contactsWithFeedback) {
      setWithFeedback(contactsWithFeedback);
    } else {
      setWithFeedback('false');
    }
  }, []);

  const renderMainContent = () => {
    return (
      <>
        <InfoBox className="mb-30">
          <>
            Contacts can either be identified users or un-identified guests.{' '}
            <a href="https://docs.gleap.io/javascript/user-identity">
              Learn how to identify contacts
            </a>
          </>
        </InfoBox>
        <ListTable data={sessions} columns={columns} />
        {sessionStore?.sessionsDataList.isLoading &&
          sessionStore?.sessionsDataList.data.length === 0 && (
            <div className="mt-20">
              <Loading />
            </div>
          )}
        {sessionStore?.sessionsDataList &&
          sessionStore?.sessionsDataList.data.length > 0 &&
          !sessionStore?.sessionsDataList.isLoading && (
            <div className="mt-20">
              <LinkButton
                isLoading={projectStore?.archivedBugsDataList?.isLoading}
                label="Load more"
                onClick={() => {
                  sessionStore!.getSessionItems({
                    type,
                    withFeedback: onlyWithFeedback === 'true',
                    loadMore: true,
                  });
                }}
              />
            </div>
          )}
      </>
    );
  };

  const render = () => {
    const activeType = contactFilterStates.find(
      (state) => state.value === type
    ) ?? contactFilterStates[0];

    if (
      sessionStore?.sessionsDataList.data.length === 0 &&
      sessionStore?.sessionsDataList.isLoading
    ) {
      return (
        <PageContainer>
          <PageHeadLine title={activeType.name} />
          <PageContent isCentered hasTitle>
            <Loading />
            {!projectStore?.currentProject?.sdkInstalled && (
              <div className="project-not-installed">
                <NotInstalledCard title="Get started with contacts 👥" description='Install the Gleap widget to get started with contacts.' />
              </div>
            )}
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <PageContainer>
        <PageHeadLine title={activeType.name}>
          <div className="header-content-right">
            <BoardSearchFilter />
            <Filter
              className="ml-10 hide-on-mobile"
              onValueChange={(value) => {
                setWithFeedback(value);
                try {
                  localStorage.setItem('contactsWithFeedback', value);
                  // eslint-disable-next-line no-empty
                } catch (exp) { }
              }}
              value={onlyWithFeedback}
              options={[
                { name: 'All', value: 'false' },
                { name: 'Only with feedback', value: 'true' },
              ]}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle isBoxed>
          {renderMainContent()}
          {!projectStore?.currentProject?.sdkInstalled && (
            <div className="project-not-installed">
              <NotInstalledCard title="Get started with contacts 👥" description='Install the Gleap widget to get started with contacts.' />
            </div>
          )}
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <SidenavContainer className="sidenav-container--small">
      <SubSidenav title="Contacts">
        {contactFilterStates.map((state) => {
          return (
            <SimpleSidenavElement
              key={state.value}
              name={state.name}
              onClick={() => {
                setType(state.value);
                try {
                  localStorage.setItem('contactsType', state.value);
                  // eslint-disable-next-line no-empty
                } catch (exp) { }
              }}
              faIcon={state.icon}
              isActive={type === state.value}
            />
          );
        })}
      </SubSidenav>
      <div className="project-contacts">{render()}</div>
    </SidenavContainer>);
};

export default inject(
  'projectStore',
  'sessionStore',
)(observer(ProjectContacts));
