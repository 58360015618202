import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import UserTable from 'components/Table/UserTable';
import { inject } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectTeam.scss';
import InvitationsTable from 'components/InvitationsTable/InvitationsTable';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import InfoBox from 'components/InfoBox/InfoBox';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { useNavigate } from 'react-router';

interface ProjectTeamProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const ProjectTeam = ({ modalStore, projectStore, organisationStore }: ProjectTeamProps) => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <PageHeadLine title="Project team">
        <div className="header-content-right">
          {projectStore?.isProjectAdmin && (
            <PrimaryButton
              label="Invite people"
              icon="plus"
              iconSideRight={false}
              onClick={() => {
                modalStore!.openModal(MODALTYPE.INVITE_PEOPLE, {
                  type: 'project',
                });
              }}
            />
          )}
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <UserTable isProjectLevel />
        <InvitationsTable isProjectLevel />
        <InfoBox className='mt-30'>
          Please note that all members of your organization can also access this project. If you want to manage your organization's members, please open your organization's settings.<br />
          <a href='#' onClick={() => {
            navigate(`/organization/${organisationStore?.currentOrganisation?.id}/team`);
          }}>
            <i className="fa-regular fa-arrow-up-right-from-square"></i> Open organization settings
          </a>
        </InfoBox>
      </PageContent>
    </PageContainer>
  );
};

export default inject('modalStore', 'projectStore', 'organisationStore')(ProjectTeam);
