export const getEmojiForVal = (val) => {
  if (val >= 80) {
    return '🤩';
  }
  if (val >= 60) {
    return '🙂';
  }
  if (val >= 40) {
    return '😐';
  }
  if (val >= 20) {
    return '😢';
  }
  return '😡';
};

export const formatCSATScore = (val) => {
  const finalVal = Math.round(val * 10);
  return `${finalVal}%`;
};
