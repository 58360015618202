import React from 'react';
import './ProfileDropUp.scss';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import classNames from 'classnames';
import { UsersStore } from 'stores/private/UsersStore';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { DarkModeToggle } from 'components/DarkModeToggle/DarkModeToggle';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/team.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as DocumentationIcon } from 'assets/icons/documentation.svg';
import UserAvatar from 'components/UserAvatar/UserAvatar';

interface ProfileDropUpProps {
  usersStore?: UsersStore;
  organisationStore?: OrganisationStore;
}

const ProfileDropUp = ({
  usersStore,
  organisationStore,
}: ProfileDropUpProps) => {
  const navigate = useNavigate();

  if (!usersStore?.currentUser) {
    return <></>;
  }

  const renderTrigger = (props) => {
    const containerClassName = classNames('trigger-container-main', {
      active: props['aria-expanded'],
    });

    return (
      <>
        <div className={containerClassName} onClick={props.onClick}>
          <div className="trigger">
            <UserAvatar
              small
              email={usersStore.currentUser?.email}
              imageUrl={usersStore.currentUser?.profileImageUrl}
            />
          </div>
        </div>
      </>
    );
  };

  const renderMenuItem = (props, path, open?) => {
    return (
      <div
        className="menu-item-container"
        onClick={() => {
          if (open) {
            open();
          } else if (path === 'logout') {
            usersStore.logout(true);
          } else {
            navigate(path);
          }
        }}
      >
        {props.children}
      </div>
    );
  };

  const renderDocument = (props, path) => {
    return (
      <div
        className="menu-item-container"
        onClick={() => {
          window.open('https://docs.gleap.io/');
        }}
      >
        {props.children}
      </div>
    );
  };

  const getOrganisationRoute = (route) => {
    if (
      organisationStore?.organisations &&
      organisationStore?.organisations.length >= 1
    ) {
      return `/organization/${organisationStore?.organisations[0].id}/${route}`;
    }
    return '/organization/create';
  };

  const renderMenu = (props) => <Dropdown.Menu {...props} className="menu" />;

  return (
    <div className="profile-dropup-menu" onClick={(e) => e.stopPropagation()}>
      <Dropdown style={{ width: '100%' }} dropup>
        <Dropdown.Toggle btnStyle="flat" componentClass={renderTrigger} />
        <Dropdown.Menu componentClass={renderMenu}>
          <DarkModeToggle />
          <MenuItem
            eventKey={1}
            componentClass={(props) => renderMenuItem(props, '/profile')}
          >
            <div className="menu-item">
              <ProfileIcon />
              Profile
            </div>
          </MenuItem>
          <MenuItem
            eventKey={3}
            componentClass={(props) =>
              renderMenuItem(props, getOrganisationRoute(''))
            }
          >
            <div className=" menu-item">
              <SettingsIcon />
              Organizations
            </div>
          </MenuItem>
          <MenuItem
            eventKey={2}
            componentClass={(props) =>
              renderMenuItem(props, getOrganisationRoute('team'))
            }
          >
            <div className=" menu-item">
              <TeamIcon />
              Invite team
            </div>
          </MenuItem>
          <MenuItem
            className="menu-item-container"
            eventKey={5}
            componentClass={(props) => renderDocument(props, '')}
          >
            <div className="menu-item">
              <DocumentationIcon />
              Documentation
            </div>
          </MenuItem>
          <MenuItem
            className="menu-item-container"
            eventKey={5}
            componentClass={(props) => renderMenuItem(props, 'logout')}
          >
            <div className="menu-item">
              <LogoutIcon />
              Logout
            </div>
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default inject(
  'usersStore',
  'organisationStore',
)(observer(ProfileDropUp));
