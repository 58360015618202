import { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import './DomainVerification.scss';
import ListTable, {
  CellText,
  CellTextCopy,
  CellVerified,
} from 'components/ListTable/ListTable';
import TextInput from 'components/TextInput/TextInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import LinkButton from 'components/LinkButton/LinkButton';
import Swal from 'sweetalert2';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import NoAdminCard from 'components/NoAdminCard/NoAdminCard';
import { ampli } from 'ampli';
import { ProjectStore } from 'stores/private/ProjectStore';
import InfoBox from 'components/InfoBox/InfoBox';

interface DomainVerificationProps {
  projectStore?: ProjectStore;
}
const DomainVerification = ({ projectStore }: DomainVerificationProps) => {
  const project = projectStore?.currentProject;
  const [domainName, setDomainName] = useState('');
  const [emailSenderType, setEmailSenderType] = useState('main');
  const [emailSenderEmail, setEmailSenderEmail] = useState('');
  const [emailSenderName, setEmailSenderName] = useState('');
  const [dnsEntries, setDnsEntries] = useState([] as any[]);

  useEffect(() => {
    if (!projectStore?.isProjectAdmin) {
      return;
    }

    if (project && project!.domainVerification) {
      setEmailSenderType(project?.domainVerification?.senderType ?? 'main');
      const email = project!.domainVerification?.senderEmail ?? '';
      setEmailSenderEmail(email.split('@')[0]);
      setEmailSenderName(project!.domainVerification?.senderName ?? '');
    }

    if (
      project &&
      project!.domainVerification &&
      project!.domainVerification.postmarkData
    ) {
      const { postmarkData } = project!.domainVerification;
      setDnsEntries([
        {
          id: 'DKIM',
          label: 'DKIM',
          hostname: postmarkData.DKIMHost
            ? postmarkData.DKIMHost
            : postmarkData.DKIMPendingHost,
          type: 'TXT',
          value: postmarkData.DKIMPendingTextValue
            ? postmarkData.DKIMPendingTextValue
            : postmarkData.DKIMTextValue,
          verified: postmarkData.DKIMVerified,
        },
        {
          id: 'Return-Path',
          label: 'Return-Path',
          hostname: postmarkData.ReturnPathDomain,
          type: 'CNAME',
          value: postmarkData.ReturnPathDomainCNAMEValue,
          verified: postmarkData.ReturnPathDomainVerified,
        },
      ]);
    } else {
      setDnsEntries([]);
    }
  }, [project, projectStore?.isProjectAdmin]);

  const columns = useMemo(
    () => [
      {
        id: 'status',
        Header: '',
        accessor: (row) => ({
          label: row.label,
          verified: row.verified,
        }),
        Cell: (row) => (
          <CellVerified label={row.value.label} verified={row.value.verified} />
        ),
      },
      {
        Header: 'Hostname',
        accessor: 'hostname',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '50px',
        Cell: (row) => <CellText text={row.value} />,
      },
      {
        Header: 'Add this value',
        accessor: 'value',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
    ],
    [],
  );

  const verifiedDomain =
    project?.domainVerification?.postmarkData?.DKIMVerified &&
    project?.domainVerification?.postmarkData?.ReturnPathDomainVerified;

  useEffect(() => {
    ampli.domainVerified({
      name: domainName,
    });
  }, []);

  if (!project) {
    return null;
  }

  const renderAdditionalSettings = () => {
    if (!verifiedDomain) {
      return <></>;
    }

    return (
      <div className="verify-domain-table-additional-info mt-40">
        <div className="section-title mb-10">Sender options</div>
        <SizedContainer size={ContainerSizes.XML} className="mt-10">
          <div className="email-sender-options">
            <div className="text mt-0">
              Specify your sender name and email address below.
            </div>
            <div className="input-label mb-5 mt-20">Sender name</div>
            <TextInput
              placeholder="Support"
              initalValue={emailSenderName}
              error=""
              onChange={(value) => {
                setEmailSenderName(value);
              }}
              required={false}
            />
            <div className="input-label mb-5 mt-20">Sender email</div>
            <div className="email-prefix">
              <TextInput
                placeholder="support"
                initalValue={emailSenderEmail}
                error=""
                onChange={(value) => {
                  const email = value.replace(/[^a-zA-Z\-\.\_0-9]/g, '');
                  setEmailSenderEmail(email);
                }}
                required={false}
              />
              <span>@{project?.domainVerification?.domainName}</span>
            </div>
            <PrimaryButton
              className="mt-20"
              label="Save"
              disabled={
                !emailSenderEmail ||
                !emailSenderName ||
                emailSenderEmail.length <= 0 ||
                emailSenderName.length <= 0
              }
              isLoading={projectStore?.isUpdatingSender}
              onClick={() => {
                projectStore!.updateDomainSettings(
                  emailSenderType,
                  emailSenderName,
                  `${emailSenderEmail}@${project?.domainVerification?.domainName}`,
                );
              }}
            />
          </div>
        </SizedContainer>
      </div>
    );
  };

  const renderDNSTable = () => {
    return (
      <div className="verify-domain-table">
        <div className="section-title mb-5">DNS settings</div>
        <div className="text mb-20">
          Head over to your DNS provider and add DKIM and Return-Path DNS
          records to verify your domain and ensure flawless delivery.
        </div>
        <ListTable data={dnsEntries} columns={columns} />
        {!verifiedDomain && (
          <InfoBox className='mt-20'>
            <b>CloudFlare users</b>: Please make sure to disable the proxy for the CNAME DNS record.
          </InfoBox>
        )}
        {!verifiedDomain && (
          <div className="text mt-20">
            It may take up to 48 hours for the DNS changes to be recognized.
          </div>
        )}
        <div className="domain-table-action-footer mt-20">
          {verifiedDomain ? (
            <span className="text text--success">Domain verified 🎉</span>
          ) : (
            <PrimaryButton
              icon="check"
              iconSideRight
              isLoading={projectStore?.isVerifyingDomain}
              label={verifiedDomain ? 'Re-verify domain' : 'Verify domain'}
              onClick={() => {
                projectStore!.verifyDomain();
              }}
            />
          )}
          <LinkButton
            label="Remove domain"
            className="ml-20"
            onClick={() => {
              Swal.fire({
                text: 'Do you really want to remove this domain?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  projectStore!.removeDomain();
                }
              });
            }}
          />
        </div>
        {renderAdditionalSettings()}
      </div>
    );
  };

  const renderGettingStarted = () => {
    return (
      <div className="verify-domain-form">
        <div className="text mb-20">
          Improve deliverability & brand awareness by using your own domain.
        </div>
        <TextInput
          placeholder="Domain name (i.e. example.com)"
          initalValue={domainName}
          error=""
          onChange={(value) => {
            setDomainName(value);
          }}
          required={false}
        />
        <PrimaryButton
          className="mt-20"
          label="Add domain"
          isLoading={projectStore?.isVerifyingDomain}
          icon="arrow-right"
          iconSideRight
          onClick={() => {
            const domain = domainName
              .replace('https://', '')
              .replace('http://', '');
            projectStore!.addDomain(domain);
            ampli.domainVerificationStarted({
              name: domain
            });
          }}
        />
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Domain verification" />
      <PageContent hasTitle>
        {!projectStore?.isProjectAdmin ? (
          <NoAdminCard title="Domain verification is" />
        ) : dnsEntries.length > 0 ? (
          renderDNSTable()
        ) : (
          renderGettingStarted()
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(DomainVerification));
