import axios from '../Axios';

const setEmailAuthToken = (projectID, code) => {
  return axios.post(`/projects/${projectID}/integrations/email`, {
    code,
  });
};

const setEmailActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/email/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectEmailIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/email/${integrationID}`,
  );
};

export { setEmailAuthToken, setEmailActions, disconnectEmailIntegration };
