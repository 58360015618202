import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getRepositories } from '../../services/integrations/GithubHttpService';

export class GithubIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  repositories: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setRepositories = (repositories) => {
    this.repositories = repositories;
  };

  getRepositories = async () => {
    try {
      const response = await getRepositories({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setRepositories(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
