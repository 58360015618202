import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Loading from 'components/Loading/Loading';
import './DeveloperOptionsWidgetSettings.scss';
import TagInput from 'components/TagInput/TagInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Swal from 'sweetalert2';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import InfoBox from 'components/InfoBox/InfoBox';
import { ampli } from 'ampli';
import { isEqual } from 'lodash';

interface DeveloperOptionsWidgetSettingsProps {
  projectStore?: ProjectStore;
}

function DeveloperOptionsWidgetSettings({
  projectStore,
}: DeveloperOptionsWidgetSettingsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  if (isLoading) {
    return <Loading />;
  }

  const renderGeneralSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">General</div>
        <div className="checkbox-settings-content">
          <div className="checkbox-settings-container">
            <div className="switch-container mb-20">
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  if (!flowConfig.enableReplays) {
                    Swal.fire({
                      text: "Our Replay feature is still in BETA. We don't recommend using it within production apps yet.",
                      showCancelButton: true,
                      confirmButtonText: `Activate anyway`,
                      denyButtonText: `Cancel`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        flowConfig.enableReplays = true;

                        ampli.developerOptionsCustomized({
                          projectId,
                          name: 'replays',
                        });
                      }
                    });
                  } else {
                    flowConfig.enableReplays = false;
                  }
                }}
                checked={flowConfig.enableReplays}
              />
              <span>
                Enable 🎥 Replays <i>(BETA)</i>
              </span>
            </div>
            {flowConfig.enableReplays && (
              <div className="switch-container mb-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => {
                    if (flowConfig.replaysInterval === 1) {
                      flowConfig.replaysInterval = 5;
                    } else {
                      flowConfig.replaysInterval = 1;
                    }

                    ampli.developerOptionsCustomized({
                      projectId,
                      name: 'replaysInterval',
                    });
                  }}
                  checked={flowConfig.replaysInterval === 1}
                />
                <span>High quality mode (impacts performance)</span>
              </div>
            )}
          </div>
          <div className="checkbox-settings-container">
            <div className="switch-container mb-20 mt-20">
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  flowConfig.enableNetworkLogs = !flowConfig.enableNetworkLogs;

                  ampli.developerOptionsCustomized({
                    projectId,
                    name: 'networkLogs',
                  });
                }}
                checked={flowConfig.enableNetworkLogs}
              />
              <span>Enable 🌎 Network Logs</span>
            </div>
            {flowConfig.enableNetworkLogs && (
              <>
                <div className="input-label">
                  Remove the following keys from all header fields and JSON
                  bodies.
                </div>
                <TagInput
                  tags={flowConfig.networkLogPropsToIgnore}
                  onChange={(newTags) => {
                    flowConfig.networkLogPropsToIgnore = newTags;

                    ampli.developerOptionsCustomized({
                      projectId,
                      name: 'networkLogPropsToIgnore',
                    });
                  }}
                />
                <InfoBox className="mt-20">
                  <>
                    Examples: token - username - password
                    <br />
                    Please note that only top-level keys are supported.
                  </>
                </InfoBox>
                <div className="input-label mt-20">
                  Exclude all requests that contain the following domains.
                </div>
                <TagInput
                  tags={flowConfig.networkLogBlacklist}
                  onChange={(newTags) => {
                    flowConfig.networkLogBlacklist = newTags;

                    ampli.developerOptionsCustomized({
                      projectId,
                      name: 'networkLogsBlacklist',
                    });
                  }}
                />
                <InfoBox className="mt-20">
                  <>Examples: gleap.io - api.gleap.io/bugs</>
                </InfoBox>
                <div className="switch-container mt-20">
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => {
                      flowConfig.sendNetworkResources =
                        !flowConfig.sendNetworkResources;

                      ampli.developerOptionsCustomized({
                        projectId,
                        name: 'networkLogSendResources',
                      });
                    }}
                    checked={flowConfig.sendNetworkResources ?? false}
                  />
                  <span>Include static resources (Web SDK only)</span>
                </div>
                <div className="additional-info-networklogs mt-20">
                  Some platforms require additional setup. Please see the
                  documentation for more information.
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderWebSDKOptions = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">Web SDK options</div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => {
                    flowConfig.enableShortcuts = !flowConfig.enableShortcuts;

                    ampli.developerOptionsCustomized({
                      projectId,
                      name: 'shortcuts',
                    });
                  }}
                  checked={flowConfig.enableShortcuts}
                />
                <span>
                  Enable 🏋️‍♀️ <i>CTRL + i</i> shortcut
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderAppSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">App SDK options</div>
        <div className="checkbox-settings-content">
          <div className="checkbox-settings-container">
            <div className="switch-container mb-20">
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  flowConfig.activationMethodShake =
                    !flowConfig.activationMethodShake;

                  ampli.developerOptionsCustomized({
                    projectId,
                    name: 'shakeGesture',
                  });
                }}
                checked={flowConfig.activationMethodShake}
              />
              <span>Enable 👋 Shake gesture</span>
            </div>
          </div>
          <div className="checkbox-settings-container">
            <div className="switch-container mt-20">
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => {
                  flowConfig.activationMethodScreenshotGesture =
                    !flowConfig.activationMethodScreenshotGesture;

                  ampli.developerOptionsCustomized({
                    projectId,
                    name: 'screenshotGesture',
                  });
                }}
                checked={flowConfig.activationMethodScreenshotGesture}
              />
              <span>Enable 📲 Screenshot gesture</span>
            </div>
            {(flowConfig.activationMethodShake || flowConfig.activationMethodScreenshotGesture) && (
              <InfoBox className='mt-20'>
                <b>Please note that Apple might reject your app</b> due to a
                recent policy change, when enabling the screenshot or shake gesture.
              </InfoBox>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Developer options">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <div className="developer-settings">
          {
            flowConfig && (<>
              {renderGeneralSettings()}
              {renderWebSDKOptions()}
              {renderAppSettings()}
            </>)
          }
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject('projectStore')(observer(DeveloperOptionsWidgetSettings));
