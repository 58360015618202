import { ampli } from 'ampli';
import { calculateContrast, hashToColor } from 'helper/Color';
import ConfettiHelper from 'helper/ConfettiHelper';
import { getRandomColor } from 'helper/RandomColorHelper';
import { LexoRank } from 'lexorank';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Bug } from 'models/Bug';
import { Feature } from 'models/Enums';
import { INTEGRATION } from 'models/Integration';
import { Invitation } from 'models/Invitations';
import {
  getSkipAndLimitFromPage,
  PaginationDataList,
} from 'models/PaginationDataList';
import {
  FCM_TOPIC,
  PostMessageAction,
  PostMessageData,
} from 'models/PostMessageData';
import { FeedbackType, Project } from 'models/Project';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getStreamedEventKeys } from 'services/OutboundHttpService';
import {
  addDomain,
  archiveFeedbackItems,
  clearHelpCenterCache,
  createConversation,
  createProject,
  deleteCompletedBugs,
  deleteCustomDomainHelpCenterSettings,
  deleteCustomDomainSettings,
  deleteProject,
  deleteUser,
  getArchivedBugs,
  getInvitedTeamMembers,
  getProject,
  getProjectAnalytics,
  getProjectFeedbackUnreadStatus,
  getProjects,
  getProjectStats,
  getProjectsUnreadCount,
  getProjectUsers,
  loadTeamPreview,
  getTicketsForProject,
  inviteTeam,
  leaveProject,
  migrateIntegrations,
  removeDomain,
  resendIntegrations,
  searchForComments,
  searchForFeedbackItems,
  searchForHelpcenterArticles,
  searchForOutbounds,
  searchForSessions,
  setUserRole,
  summarizeFeedback,
  updateCustomDomainHelpCenterSettings,
  updateCustomDomainSettings,
  updateDomainSettings,
  updateProject,
  updateProjectPicture,
  verifyDomain,
  getProjectAnswers,
  createProjectAnswer,
  getBots,
  getBot,
  createBot,
  updateBot,
  updateProjectAnswer,
  deleteBot,
  getProjectAnswerStats,
  deleteProjectAnswer,
} from 'services/ProjectHttpService';
import { validateRecaptchaAction } from 'services/Recaptcha';
import WebSocketMessage from 'services/WebSocketMessage';
import { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import Swal from 'sweetalert2';
import { defaultTranslations } from '../Translations';
import { MODALTYPE } from './ModalStore';

const itemsInPage = 50;

export const menuOptions = [
  {
    title: 'Report an issue',
    infoDescription: 'Starts the bug reporting flow.',
    description: 'Found a bug? Let us know.',
    icon: 'https://gleapcdn.com/res/iconbug.svg',
    actionType: 'bugreporting',
    color: '#F4CAC8',
  },
  {
    title: 'Request a feature',
    infoDescription: 'Starts the feature requests flow.',
    description: 'What would you like to see next?',
    icon: 'https://gleapcdn.com/res/iconidea.svg',
    actionType: 'featurerequests',
    color: '#FFEEC2',
  },
  {
    title: 'Send us a message',
    infoDescription: 'Starts the contact us form flow.',
    description: 'We are here to help.',
    icon: 'https://gleapcdn.com/res/iconcontact.svg',
    actionType: 'BOT',
    color: '#EFE2FF',
  },
  {
    title: 'Redirect to URL',
    infoDescription: 'Redirects the user to a custom URL.',
    description: 'Custom description',
    icon: 'https://gleapcdn.com/res/iconlink.svg',
    actionType: 'REDIRECT_URL',
    color: '#E8FEE1',
  },
  {
    title: 'Custom option',
    infoDescription: 'Create your own option from scratch.',
    description: 'Custom description',
    icon: 'https://gleapcdn.com/res/iconcustom.svg',
    actionType: 'CUSTOM_ACTION',
    color: '#E8FEE1',
  },
];

var debouncedMethods = {};
var debouncedSearchMethod: any = null;
var shouldDebounce = true;

export const defaultMenuOptions = [
  menuOptions[0],
  menuOptions[1],
  menuOptions[2],
];

export const flowConfigDefault = {
  color: '#485bff',
  backgroundColor: '#FFFFFF',
  headerColor: '#485bff',
  buttonColor: '#485bff',
  borderRadius: 20,
  buttonX: 20,
  buttonY: 20,
  operatorAvatarImage: '',
  logo: '',
  buttonLogo: '',
  customCSS: '',
  hideFeatureRequests: false,
  hideNews: false,
  hideAllConversations: false,
  hideKnowledgeBase: true,
  enableOpeningTimesBot: true,
  operatorAvatar: '',
  openingTimes: [
    {
      day: 'Weekdays',
      openingTime: '09:00',
      closingTime: '17:00',
    },
  ],
  replyTime: 'within a few minutes',
  timezone: 'Universal',
  showOnlineStatus: true,
  spamProtection: false,
  hideBranding: false,
  enableConsoleLogs: true,
  enableReplays: false,
  hideForGuests: false,
  replaysInterval: 5,
  thankYouDuration: 3000,
  enableNetworkLogs: true,
  sendNetworkResources: false,
  networkLogPropsToIgnore: [],
  networkLogBlacklist: [],
  enablePrivacyPolicy: false,
  enableShortcuts: true,
  privacyPolicyURL: '',
  enableMenu: true,
  menuItems: defaultMenuOptions,
  activationMethodFeedbackButton: true,
  activationMethodShake: false,
  activationMethodScreenshotGesture: false,
  enableCrashDetector: false,
  crashDetectorIsSilent: false,
  enableRageClickDetector: false,
  rageClickDetectorIsSilent: false,
  enableNetworkLogFilters: false,
  jsMajorVersion: 'v6',
  enableIntercomCompatibilityMode: true,
  hideWavingHandAfterName: false,
  hideUsersName: false,
  hideTeam: false,
  widgetButtonText: 'Feedback',
  widgetInfoTitle: 'Feedback',
  widgetInfoSubtitle: 'Let us know how we can do better.',
  welcomeText: 'How can we help?',
  showInfoPopup: false,
  feedbackButtonPosition: 'BOTTOM_RIGHT',
  customTranslations: defaultTranslations,
  helpcenterConfig: {
    localization: [
      {
        language: 'en',
        title: 'Help center',
        description: 'Find answers to your questions',
      },
    ],
    config: {
      logoUrl: '',
    },
  },
};

export const countAllOpenBugs = (currentBugs) => {
  let count = 0;

  if (currentBugs) {
    for (let i = 0; i < currentBugs.length; i++) {
      if (
        currentBugs[i].notificationsUnread &&
        currentBugs[i].type &&
        currentBugs[i].type.length > 0
      ) {
        count++;
      }
    }
  }

  return count;
};

// eslint-disable-next-line import/prefer-default-export
export class ProjectStore implements WebSocketMessage {
  projects: Project[] = [];
  projectsUnreadCount: any = {};
  unreadItems: {
    id: string;
    type: string;
    status: string;
    processingUser: string;
    mentions: string[];
  }[] = [];
  currentAnswerStats: any = {};
  teamPreview: any[] = [];
  currentProject: Project | undefined = undefined;
  currentProjectUsers: any[] = [];
  streamedEventKeys: string[] = [];
  currentFeedbackType?: FeedbackType = undefined;
  stores: any = {};
  externalSessionId: string | null = null;
  loadingCurrentProject = false;
  loadingStatistics = false;
  loadingStats = false;
  loadingArchivedBugs = true;
  currentProjectAnalytics: any = {};
  currentProjectStats: any = {};
  initialLoading = true;
  isProjectAdmin = false;
  loadingFailed = false;
  isProjectAdminLoaded = false;
  canLeaveProject = false;
  loadingProjects = false;
  hasCrashReports = false;
  flowConfig = flowConfigDefault;
  configuratorLang = 'en';
  inquiryFilter = 'all';
  invitedTeamMembers: Invitation[] = [];
  editingProject: Project | undefined = undefined;
  isAddingDomain = false;
  isUpdatingSender = false;
  isVerifyingDomain = false;
  loadingAiResponse = false;
  aiPlanFailed = false;
  aiResponse: any = {};
  currentQAAnswers: any[] = [];
  loadingQAAnswers = false;
  bots: any[] = [];
  loadingBots = false;
  bot: any = undefined;
  currentLanguage: string = 'en';

  archivedBugsDataList: PaginationDataList<Bug> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };

  // ! TICKET PAGINATION-LIST ! //
  currentTicketsData: any = {}; // { [laneKey: string]: PaginationDataList<Bug> } = {};
  currentTicketDataFilter: any = {};
  currentTicketDataSort: any = {};
  currentTicketSearchTerm: string = '';
  ticketSearchData: PaginationDataList<Bug> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };

  globalSearchData: any = {
    bug: {
      data: [],
      isLoading: true,
    },
    session: {
      data: [],
      isLoading: true,
    },
    comment: {
      data: [],
      isLoading: true,
    },
    outbound: {
      data: [],
      isLoading: true,
    },
    helpcenterArticle: {
      data: [],
      isLoading: true,
    },
  };

  constructor() {
    makeAutoObservable(this);
  }

  updateTicketFromUnreadList = (ticket) => {
    runInAction(() => {
      this.unreadItems = [
        ...this.unreadItems.filter((i) => i.id !== ticket.id),
        {
          id: ticket.id,
          type: ticket.type,
          status: ticket.status,
          mentions: ticket.mentions,
          processingUser: ticket.processingUser?.id
            ? ticket.processingUser?.id
            : ticket.processingUser,
        },
      ];
    });
  };

  removeTicketFromUnreadList = (ticket) => {
    runInAction(() => {
      this.unreadItems = [
        ...this.unreadItems.filter((i) => i.id !== ticket.id),
      ];
    });
  };

  ticketDoesPassCurrentFilter = (feedbackItem) => {
    if (
      this.currentTicketDataFilter.status &&
      this.currentTicketDataFilter.status !== feedbackItem?.status
    ) {
      return false;
    }

    if (
      this.currentTicketDataFilter.processingUser &&
      this.currentTicketDataFilter.processingUser !==
        feedbackItem?.processingUser?.id
    ) {
      return false;
    }

    if (
      this.currentTicketDataFilter.priority &&
      this.currentTicketDataFilter.priority !== feedbackItem?.priority
    ) {
      return false;
    }

    if (
      this.currentTicketDataFilter.tag &&
      !feedbackItem?.tags?.includes(this.currentTicketDataFilter.tag)
    ) {
      return false;
    }

    if (
      this.currentTicketDataFilter.country &&
      this.currentTicketDataFilter.country !==
        feedbackItem?.session?.location?.country
    ) {
      return false;
    }

    if (
      this.currentTicketSearchTerm &&
      this.currentTicketSearchTerm.length > 0
    ) {
      const feedbackItemCopy = JSON.parse(JSON.stringify(feedbackItem));
      delete feedbackItemCopy.form;

      const searchString = JSON.stringify(feedbackItemCopy);
      if (
        !searchString ||
        !searchString
          .toLowerCase()
          .includes(this.currentTicketSearchTerm.toLowerCase())
      ) {
        return false;
      }
    }

    return true;
  };

  prepareCurrentTicketsData = () => {
    shouldDebounce = true;
    this.currentTicketsData = {};

    runInAction(() => {
      if (
        this.currentFeedbackType &&
        this.currentFeedbackType.options &&
        this.currentFeedbackType.options.possibleLanes
      ) {
        for (const possibleLaneKey in this.currentFeedbackType.options
          .possibleLanes) {
          const possibleLane =
            this.currentFeedbackType.options.possibleLanes[possibleLaneKey];
          this.currentTicketsData[possibleLane.key] = {
            data: [],
            pageIndex: 0,
            itemsInPage: 50,
            isLoading: true,
            metaData: {
              currentFeedbackType: this.currentFeedbackType?.type,
              appliedQuery: null,
            },
          };
        }
      }

      this.setInitalSorting();

      for (const laneKey in this.currentTicketsData) {
        this.fetchAndSetTicketsDataForLane({
          laneKey,
          loadMore: false,
        });
      }
    });
  };

  getProjectSurveyUnreadStatus = async (id: string) => {
    try {
      this.unreadItems = [];

      const response = await getProjectFeedbackUnreadStatus(id);
      if (response.status === 200) {
        runInAction(() => {
          this.unreadItems = response.data.unread;

          if (response.data.hasCrashReports) {
            this.hasCrashReports = true;
          }
        });
      }
    } catch (exp) {}
  };

  setInitalSorting = () => {
    switch (this.currentFeedbackType?.type) {
      case 'INQUIRY':
        this.currentTicketDataSort.sortKey = 'lastNotification';
        this.currentTicketDataSort.sortDirection = 'desc';
        break;

      case 'FEATURE_REQUEST':
        this.currentTicketDataSort.sortKey = 'upvotesCount';
        this.currentTicketDataSort.sortDirection = 'desc';
        break;

      default:
        this.currentTicketDataSort.sortKey = 'lexorank';
        this.currentTicketDataSort.sortDirection = 'asc';
        break;
    }
  };

  locallySortTickets = () => {
    if (
      !this.currentTicketDataSort?.sortKey ||
      !this.currentTicketDataSort?.sortDirection
    ) {
      return;
    }

    for (const laneKey in this.currentTicketsData) {
      const currentLane = this.currentTicketsData[laneKey];

      if (currentLane) {
        switch (this.currentTicketDataSort?.sortKey) {
          case 'lexorank':
            currentLane.data.sort((a, b) =>
              a.lexorank.localeCompare(b.lexorank),
            );
            break;

          case 'lastNotification':
            currentLane.data.sort(function (a, b) {
              return (
                new Date(b.lastNotification).getTime() -
                new Date(a.lastNotification).getTime()
              );
            });
            break;

          default:
            break;
        }

        runInAction(() => {
          currentLane.data = [...currentLane.data];
        });
      }
    }
  };

  fetchAndSetTicketsDataForLane = async (args: {
    laneKey: string;
    loadMore: boolean;
    forceFetch?: boolean;
  }) => {
    if (shouldDebounce) {
      if (args.laneKey) {
        // Clear previous timer.
        if (debouncedMethods[args.laneKey]) {
          clearTimeout(debouncedMethods[args.laneKey]);
        }

        // Set new load request.
        debouncedMethods[args.laneKey] = setTimeout(() => {
          this.fetchAndSetTicketsDataForLaneDebounced(args);
        }, 650);
      }
    } else {
      this.fetchAndSetTicketsDataForLaneDebounced(args);
    }
  };

  fetchAndSetTicketsDataForLaneDebounced = async (args: {
    laneKey: string;
    loadMore: boolean;
    forceFetch?: boolean;
  }) => {
    debouncedMethods[args.laneKey] = null;
    const { laneKey, loadMore } = args;
    const currentTicketsData = this.currentTicketsData[laneKey];
    if (!currentTicketsData) {
      return;
    }

    var isLoading =
      currentTicketsData?.isLoading &&
      currentTicketsData.metaData.appliedQuery !== null;
    if (
      (!currentTicketsData || isLoading || !this.currentProject) &&
      !args.forceFetch
    ) {
      return;
    }

    var data = currentTicketsData?.data ?? [];
    var pageIndex = currentTicketsData?.pageIndex ?? 0;
    var itemsInPage = currentTicketsData?.itemsInPage ?? 0;

    if (loadMore) {
      // Check if already fetched all data
      if (data.length < itemsInPage * pageIndex) {
        return;
      }

      pageIndex++;
    } else {
      pageIndex = 0;
      data = [];
    }

    const loadDataForType = JSON.parse(
      JSON.stringify(this.currentFeedbackType?.type),
    );

    let query = {
      type: loadDataForType,
      status: laneKey,
      ...getSkipAndLimitFromPage({
        pageIndex,
        itemsInPage,
      }),
    };

    const filterQuery = this.getTicketFilterQuery();

    query = { ...query, ...filterQuery };

    if (
      JSON.stringify(currentTicketsData?.metaData?.appliedQuery) ===
      JSON.stringify(query)
    ) {
      return;
    }

    runInAction(() => {
      if (!loadMore) {
        this.currentTicketsData[laneKey].data = [];
      }
      this.currentTicketsData[laneKey].isLoading = true;
      this.currentTicketsData = { ...this.currentTicketsData };
    });

    const response = await getTicketsForProject({
      projectId: this.currentProject!.id,
      query,
    });

    if (loadDataForType !== this.currentFeedbackType?.type) {
      return;
    }

    if (
      JSON.stringify(currentTicketsData.metaData.appliedQuery) ===
      JSON.stringify(query)
    ) {
      // Assume that the query has been changed in the meantime and there is another request in progress.
      return;
    }

    runInAction(() => {
      if (
        this.currentTicketsData[laneKey] &&
        response.data &&
        response.data.tickets
      ) {
        this.currentTicketsData[laneKey].metaData.appliedQuery = query;
        this.currentTicketsData[laneKey].data = data.concat(
          response.data.tickets,
        );
        this.currentTicketsData[laneKey].pageIndex = pageIndex;
        this.currentTicketsData[laneKey].count = response.data.count;
        this.currentTicketsData[laneKey].isLoading = false;
      }
    });
  };

  filterTicketsData = () => {
    for (const laneKey in this.currentTicketsData) {
      this.fetchAndSetTicketsDataForLane({
        laneKey,
        loadMore: false,
        forceFetch: true,
      });
    }
  };

  getTicketFilterQuery = () => {
    let query: any = {};

    for (const key in this.currentTicketDataFilter) {
      switch (key) {
        case 'tags':
          query[key] = this.currentTicketDataFilter[key].label;
          break;

        case 'country':
          query['session.location.country'] =
            this.currentTicketDataFilter[key].code;
          break;

        default:
          query[key] = this.currentTicketDataFilter[key];
          break;
      }
    }

    // Sort
    if (
      this.currentTicketDataSort &&
      this.currentTicketDataSort.sortKey &&
      this.currentTicketDataSort.sortDirection
    ) {
      if (this.currentTicketDataSort.sortKey === 'lexorank') {
        query['sort'] = `lexorank`;
      } else {
        query['sort'] = `${
          this.currentTicketDataSort.sortDirection === 'asc' ? '' : '-'
        }${this.currentTicketDataSort.sortKey}`;
      }
    }

    return query;
  };

  getLanesForFeedbackType = () => {
    if (
      this.currentFeedbackType &&
      this.currentFeedbackType.options &&
      this.currentFeedbackType.options.possibleLanes
    ) {
      return this.currentFeedbackType.options.possibleLanes;
    }

    return [];
  };

  searchForTicketsForAllLanes = async (args: { searchTerm: string }) => {
    const { searchTerm } = args;
    if (!this.currentProject) {
      return;
    }

    // Set search term.
    this.currentTicketSearchTerm = searchTerm;

    if (debouncedSearchMethod) {
      clearTimeout(debouncedSearchMethod);
      debouncedSearchMethod = null;
    }

    debouncedSearchMethod = setTimeout(() => {
      for (const laneKey in this.currentTicketsData) {
        this.searchForTickets({
          searchTerm,
          laneKey,
        });
      }
    }, 800);
  };

  searchForTickets = async (args: { searchTerm: string; laneKey: string }) => {
    const { laneKey, searchTerm } = args;
    if (!this.currentProject) {
      return;
    }

    if (searchTerm === '') {
      for (const laneKey in this.currentTicketsData) {
        this.fetchAndSetTicketsDataForLane({
          laneKey,
          loadMore: false,
        });
      }
      return;
    }

    try {
      this.currentTicketsData[laneKey].isLoading = true;
      this.currentTicketsData[laneKey].pageIndex = 0;

      const response = await searchForFeedbackItems({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
          type: this.currentFeedbackType?.type,
          archived: false,
          status: laneKey,
        },
      });

      runInAction(() => {
        this.currentTicketsData[laneKey].data =
          response.data && response.data.hits ? response.data.hits : [];
        this.currentTicketsData[laneKey].isLoading = false;
      });
    } catch (_) {
      this.currentTicketsData[laneKey].isLoading = false;
    }
  };

  duplicateTicketSearch = async (args: { searchTerm: string }) => {
    const { searchTerm } = args;
    if (!this.currentProject) {
      return;
    }

    try {
      runInAction(() => {
        this.ticketSearchData.isLoading = true;
      });

      const response = await searchForFeedbackItems({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
          type: this.currentFeedbackType?.type,
          archived: false,
        },
      });

      runInAction(() => {
        this.ticketSearchData.data =
          response.data && response.data.hits ? response.data.hits : [];
        this.ticketSearchData.isLoading = false;
      });
    } catch (_) {
      runInAction(() => {
        this.ticketSearchData.data = [];
        this.ticketSearchData.isLoading = false;
      });
    }
  };

  setCurrentProject = (currentProject) => {
    this.currentProject = currentProject;

    // Set global styles
    const color = this.currentProject?.flowConfig?.color ?? '#00bcd4';
    const contrastColor = calculateContrast(color);
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty(
      '--contrast-color',
      contrastColor,
    );

    // Ensure that the correct organisation is loaded.
    if (
      this.currentProject?.organisation?.id &&
      this.stores.organisationStore?.currentOrganisation?.id &&
      this.currentProject?.organisation?.id !==
        this.stores.organisationStore?.currentOrganisation?.id
    ) {
      // Load new organisation due to orga change.
      this.stores.organisationStore.getOrganisation(
        this.currentProject?.organisation?.id,
      );
    }
  };

  setExternalSessionId(externalSessionId: string | null) {
    this.externalSessionId = externalSessionId;
  }

  setFeedbackTypeForPath(path) {
    if (!this.currentProject?.projectTypes) {
      return null;
    }

    for (let i = 0; i < this.currentProject?.projectTypes?.length; i++) {
      const currentProjectType = this.currentProject?.projectTypes[i];
      if (currentProjectType.path === path) {
        this.currentFeedbackType = currentProjectType;
        this.currentTicketDataFilter = {};
        this.currentTicketDataSort = {};

        // Fix for inqueries - add possible lanes manually
        if (currentProjectType.type === 'INQUIRY') {
          this.currentFeedbackType.options = {};
          this.currentFeedbackType.options.possibleLanes = [
            {
              title: 'Open',
              key: 'OPEN',
            },
            {
              title: 'Snoozed',
              key: 'SNOOZED',
            },
            {
              title: 'Done',
              key: 'DONE',
            },
          ];
        }
        this.prepareCurrentTicketsData();
        return currentProjectType;
      }
    }

    return null;
  }

  findFeedbackTypeForType(type) {
    if (!this.currentProject?.projectTypes) {
      return null;
    }

    for (let i = 0; i < this.currentProject?.projectTypes?.length; i++) {
      const currentProjectType = this.currentProject?.projectTypes[i];
      if (currentProjectType.type === type) {
        return currentProjectType;
      }
    }

    return null;
  }

  setStores(stores) {
    this.stores = stores;
  }

  locallyUpdateBug(bugId, data, force = false) {
    // Find and update bug in currentTicketsData and if status changed, move it to the correct lane
    for (const laneKey in this.currentTicketsData) {
      if (this.currentTicketsData[laneKey].data.length > 0) {
        const ticket = this.currentTicketsData[laneKey].data.find(
          (x) => x.id === bugId || x.shareToken === bugId,
        );

        if (!ticket) {
          continue;
        }

        // Don't change a ticket that is currently open.
        if (
          !force &&
          ticket.id === this.stores.bugStore?.currentBug?.id &&
          data.notificationsUnread
        ) {
          data.notificationsUnread = false;
        }

        const newTicket = { ...ticket, ...data };

        if (
          data.notificationsUnread === false &&
          ticket.notificationsUnread === true
        ) {
          // Ticket got read.
          this.removeTicketFromUnreadList(newTicket);
        } else if (
          data.notificationsUnread === true &&
          ticket.notificationsUnread === false
        ) {
          // Ticket got unread.
          this.updateTicketFromUnreadList(newTicket);
        } else {
          if (newTicket.notificationsUnread) {
            this.updateTicketFromUnreadList(newTicket);
          } else {
            this.removeTicketFromUnreadList(newTicket);
          }
        }

        // Check if type changed
        if (data.type && ticket.type !== data.type) {
          // Remove from current lane
          const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
            (x) => x.id === bugId || x.shareToken === bugId,
          );

          if (bugIndex >= 0) {
            runInAction(() => {
              this.currentTicketsData[laneKey].data.splice(bugIndex, 1);
              this.currentTicketsData[laneKey].count--;
            });
          }
        }

        // Check if status changed
        else if (data.status && ticket.status !== data.status) {
          // Remove from current lane
          const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
            (x) => x.id === bugId || x.shareToken === bugId,
          );

          if (bugIndex >= 0) {
            runInAction(() => {
              this.currentTicketsData[laneKey].data.splice(bugIndex, 1);
              this.currentTicketsData[laneKey].count--;

              this.currentTicketsData[laneKey] = {
                ...this.currentTicketsData[laneKey],
              };
            });
          }

          // Add to new lane
          const newLaneKey = data.status;
          if (this.currentTicketsData[newLaneKey]) {
            runInAction(() => {
              this.currentTicketsData[newLaneKey].count++;

              this.currentTicketsData[newLaneKey].data = [
                ...this.currentTicketsData[newLaneKey].data,
                { ...ticket, ...data },
              ];
            });
          }
        } else {
          // Update ticket in current lane
          const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
            (x) => x.id === bugId || x.shareToken === bugId,
          );
          if (bugIndex >= 0) {
            runInAction(() => {
              this.currentTicketsData[laneKey].data[bugIndex] = {
                ...ticket,
                ...data,
              };
              this.currentTicketsData[laneKey].data = [
                ...this.currentTicketsData[laneKey].data,
              ];
            });
          }
        }

        break;
      }
    }

    // Sort tickets
    this.locallySortTickets();
  }

  locallyDeleteBug(data) {
    // Find and delete bug in currentTicketsData
    for (const laneKey in this.currentTicketsData) {
      if (this.currentTicketsData[laneKey].data.length > 0) {
        const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
          (x) => x.id === data.id,
        );
        if (bugIndex >= 0) {
          runInAction(() => {
            this.currentTicketsData[laneKey].data.splice(bugIndex, 1);
            this.currentTicketsData[laneKey].data = [
              ...this.currentTicketsData[laneKey].data,
            ];
          });
        }
      }
    }
  }

  onEvent = (event: string, data: any) => {
    if (!data) {
      return;
    }

    if (event === WEBSOCKET_EVENTS.BUG_UPDATED) {
      if (
        data.latestComment &&
        (typeof data.latestComment === 'string' ||
          data.latestComment instanceof String)
      ) {
        delete data.latestComment;
      }
      const ticketPassesFilter = this.ticketDoesPassCurrentFilter(data);

      if (!ticketPassesFilter && Object.keys(data).length > 2) {
        this.locallyDeleteBug(data);
      } else {
        this.locallyUpdateBug(data.id, data);
      }
    }

    if (event === WEBSOCKET_EVENTS.BUG_DELETED) {
      this.locallyDeleteBug(data);
    }

    if (event === WEBSOCKET_EVENTS.BUG_CREATED) {
      const ticketPassesFilter = this.ticketDoesPassCurrentFilter(data);

      // Update notifications if needed.
      if (data && data.notificationsUnread) {
        this.updateTicketFromUnreadList(data);
      }

      if (
        data &&
        data.project &&
        this.currentProject?.id &&
        data.project === this.currentProject?.id &&
        data.archived !== true &&
        data.type === this.currentFeedbackType?.type
      ) {
        if (
          this.currentTicketsData &&
          this.currentTicketsData[data.status] &&
          ticketPassesFilter
        ) {
          // Insert bug
          runInAction(() => {
            if (!this.currentTicketsData[data.status].data) {
              this.currentTicketsData[data.status].data = [];
            }

            this.currentTicketsData[data.status].data = [
              ...this.currentTicketsData[data.status].data,
              data,
            ];
          });
        }

        // Sort tickets
        this.locallySortTickets();
      }
    }

    if (event === WEBSOCKET_EVENTS.COLUMN_DELETED) {
      if (data && data.project && data.project === this.currentProject?.id) {
        if (
          this.currentTicketsData &&
          this.currentTicketsData[data.status] &&
          this.currentTicketsData[data.status].data.length > 0
        ) {
          this.currentTicketsData[data.status].data = [];
        }
      }
    }
  };

  isPlanExpired = () => {
    if (
      this.currentProject?.organisation?.subscription?.planID === 'free' &&
      !this.isInTimeSpan()
    ) {
      return true;
    }
    return false;
  };

  isInTimeSpan = () => {
    const expiresAt = moment(
      this.currentProject?.organisation?.subscription?.trialExpiresAt,
    );
    const today = moment(Date.now());
    return expiresAt >= today;
  };

  isIntegrationInPlan = (integration: INTEGRATION) => {
    try {
      const metadata =
        this.currentProject?.organisation?.subscription?.metadata;
      if (metadata && metadata.integrations) {
        if (
          metadata.integrations === 'some' &&
          (integration === INTEGRATION.JIRA ||
            integration === INTEGRATION.TRELLO ||
            integration === INTEGRATION.SLACK)
        ) {
          return true;
        }
        if (metadata.integrations === 'all') {
          return true;
        }
      }

      return false;
    } catch {
      return false;
    }
  };

  isFeatureInPlan = (feature: Feature, values?: string[]) => {
    try {
      const metadata =
        this.currentProject?.organisation?.subscription?.metadata;
      if (metadata && metadata[feature]) {
        if (metadata[feature] === 'true') {
          return true;
        }

        if (values && values.length > 0) {
          for (let i = 0; i < values.length; i++) {
            if (metadata[feature] === values[i]) {
              return true;
            }
            if (metadata[feature] !== 'false' && values[i] === 'number') {
              return true;
            }
          }
        }
      }
      return false;
    } catch {
      return false;
    }
  };

  inviteTeamMember = async (emails: any[], resend = false) => {
    let token = '';
    try {
      token = (await validateRecaptchaAction('inviteTeam')) as any;
    } catch (exp) {
      toast.error('Are you human?');
      return null;
    }

    try {
      const response = await inviteTeam(
        this.currentProject?.id!,
        emails,
        token,
      );

      if (resend) {
        toast.success('Successfully sent invitation.');
      }
      return response.data;
    } catch (err: any) {
      toast.error('Could not send all invitation(s).');
      return null;
    }
  };

  createConversation = async (sessionId) => {
    try {
      const response = await createConversation(
        this.currentProject?.id!,
        sessionId,
      );
      if (response.status === 200) {
        return response.data;
      }
      // eslint-disable-next-line no-empty
    } catch (err: any) {}
    return null;
  };

  getInvitedTeamMembers = async () => {
    try {
      const response = await getInvitedTeamMembers(this.currentProject?.id!);
      runInAction(() => {
        if (response.status === 200) {
          this.invitedTeamMembers = response.data;
        }
      });
      // eslint-disable-next-line no-empty
    } catch (err: any) {}
  };

  setUserRole = async (userID, role) => {
    try {
      const response = await setUserRole(this.currentProject!.id, userID, role);
      if (response.status === 200) {
        toast.success('Successfully updated user role.');
        this.getProjectUsers();
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      } else if (err && err.response.status === 409) {
        toast.error('You are the last admin in the project!');
      } else if (err && err.response.status === 404) {
        toast.error('Updating user role failed!');
      }
    }
  };

  removeUser = async (userID) => {
    try {
      const response = await deleteUser(this.currentProject!.id, userID);
      if (response.status === 200) {
        toast.success('Successfully removed user from project.');
        this.getProjectUsers();
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      } else if (err && err.response.status === 409) {
        toast.error('You are the last admin in the project!');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (err && err.response.status === 404) {
        toast.error('The user was not found in the project!');
      }
    }
  };

  getItemAtPosition = (cards: any[], index: number) => {
    if (cards && index >= 0 && index < cards.length) {
      if (typeof cards[index] !== 'undefined') {
        return cards[index];
      }
    }

    return null;
  };

  setCurrentProjectAnalytics = (currentProjectAnalytics) => {
    this.currentProjectAnalytics = currentProjectAnalytics;
  };

  setCurrentProjectStats = (currentProjectStats) => {
    this.currentProjectStats = currentProjectStats;
  };

  setLoadingStats = (loadingStats) => {
    this.loadingStats = loadingStats;
  };

  getBots = async (projectId: string) => {
    this.loadingBots = true;

    try {
      const response = await getBots(projectId);
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.bots = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.loadingBots = false;
    });
  };

  getBot = async (projectId: string, botId: string) => {
    try {
      this.bot = undefined;

      const response = await getBot(projectId, botId);
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.bot = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  getProjectAnswerStats = async (projectId: string) => {
    try {
      this.currentAnswerStats = {};

      const response = await getProjectAnswerStats(projectId);
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.currentAnswerStats = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  getQAAnswers = async (projectId: string, unanswered, skip, limit) => {
    try {
      this.currentQAAnswers = [];
      this.loadingQAAnswers = true;

      const response = await getProjectAnswers(
        projectId,
        unanswered,
        skip,
        limit,
      );
      if (response.status === 200) {
        runInAction(() => {
          if (response.data && response.data.answers) {
            this.currentQAAnswers = response.data.answers;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.loadingQAAnswers = false;
    });
  };

  deleteQAAnswer = async (projectId: string, answerId: string) => {
    try {
      runInAction(() => {
        this.currentQAAnswers = [
          ...this.currentQAAnswers.filter((a) => a.id !== answerId),
        ];
      });

      await deleteProjectAnswer(projectId, answerId);
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  updateQAAnswer = async (projectId: string, answerId: string, data: any) => {
    try {
      runInAction(() => {
        for (var i in this.currentQAAnswers) {
          if (this.currentQAAnswers[i].id === answerId) {
            this.currentQAAnswers[i] = {
              ...this.currentQAAnswers[i],
              ...data,
            };
            this.currentQAAnswers[i].acknowledged = true;
            break;
          }
        }

        this.currentQAAnswers = [...this.currentQAAnswers];
      });

      await updateProjectAnswer(projectId, answerId, data);
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  createQAAnswer = async (
    projectId: string,
    question: string,
    answer: string,
  ) => {
    try {
      const response = await createProjectAnswer(projectId, {
        question,
        answer,
      });
      if (response.status === 200) {
        runInAction(() => {
          this.currentQAAnswers = [...this.currentQAAnswers, response.data];
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  updateBot = async (
    projectId: string,
    botId: string,
    data: {
      status: 'draft' | 'live';
      name?: string;
      actionFlows?: any;
      triggerPriority?: number;
      trigger?: string;
      targetAudience?: string;
      conditions?: any[];
    },
  ) => {
    try {
      const response = await updateBot(projectId, botId, data);
      if (response.status === 200) {
        runInAction(() => {
          this.bot = response.data;

          // Replace bot in list.
          const index = this.bots.findIndex((b) => b.id === this.bot.id);
          if (index !== -1) {
            this.bots[index] = this.bot;
          }
        });

        toast.success('Successfully updated bot.');
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {
      toast.error('Updating bot failed.');
    }
  };

  createBot = async (projectId: string) => {
    try {
      const response = await createBot(projectId);
      if (response.status === 200) {
        runInAction(() => {
          this.bots = [...this.bots, response.data];
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  deleteBot = async (botId: string) => {
    try {
      const response = await deleteBot(this.currentProject?.id, botId);
      if (response.status === 200) {
        runInAction(() => {
          this.bots = this.bots.filter((b) => b.id !== botId);
        });
      }

      toast.success('Successfully deleted bot.');
    } catch (exp) {
      toast.error('Deleting bot failed.');
    }
  };

  getCurrentProjecStats = async (projectId) => {
    this.setLoadingStats(true);
    try {
      const response = await getProjectStats(projectId);
      if (response.status === 200) {
        this.setCurrentProjectStats(response.data);
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
    this.setLoadingStats(false);
  };

  async summarizeFeedback() {
    if (!this.currentProject) {
      return;
    }

    this.loadingAiResponse = true;

    try {
      const response = await summarizeFeedback(this.currentProject.id);
      runInAction(() => {
        this.aiResponse = response.data;
      });
    } catch (error) {
      if (
        (error as any) &&
        (error as any)?.response &&
        (error as any)?.response.status === 408
      ) {
        runInAction(() => {
          this.aiPlanFailed = true;
        });
      } else {
        if ((error as any)?.response?.data?.errors?.length > 0) {
          Swal.fire({
            text: (error as any)?.response.data.errors[0].message,
            showCancelButton: false,
            confirmButtonText: `Ok`,
          });
        } else {
          toast.error(
            'Could not summarize survey results. Please try again later.',
          );
        }
      }
    }

    runInAction(() => {
      this.loadingAiResponse = false;
    });
  }

  setLoadingStatistics = (loadingStatistics) => {
    this.loadingStatistics = loadingStatistics;
  };

  getCurrentProjectAnalytics = async (projectId) => {
    this.setLoadingStatistics(true);
    try {
      const response = await getProjectAnalytics(projectId);
      if (response.status === 200) {
        this.setCurrentProjectAnalytics(response.data);
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
    this.setLoadingStatistics(false);
  };

  getStreamedEventKeys = async () => {
    if (!this.currentProject?.id) {
      return;
    }

    try {
      const response = await getStreamedEventKeys(this.currentProject?.id);
      if (response.status === 200) {
        this.streamedEventKeys = response.data;
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  calculateNewLexorank = (ticketId, currentLane, position) => {
    var laneData = JSON.parse(
      JSON.stringify(this.currentTicketsData[currentLane].data),
    );
    laneData = laneData.filter((item) => item.id !== ticketId);
    laneData.splice(position, 0, { name: 'FAKE', lexorank: null });

    let lexorankPrev = LexoRank.min();
    const itemPrev = this.getItemAtPosition(laneData, position - 1);

    if (itemPrev && itemPrev.lexorank) {
      try {
        lexorankPrev = LexoRank.parse(itemPrev.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    let lexorankNext = lexorankPrev.genNext().genNext();
    const itemNext = this.getItemAtPosition(laneData, position + 1);

    if (itemNext && itemNext.lexorank) {
      try {
        lexorankNext = LexoRank.parse(itemNext.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    try {
      return lexorankPrev.between(lexorankNext);
    } catch {
      return LexoRank.min();
    }
  };

  moveBugInProject = (bugId, status, lexorank, didChangeLane) => {
    var dataToUpdate: any = { status };
    if (this.currentTicketDataSort.sortKey === 'lexorank' || didChangeLane) {
      dataToUpdate = { status, lexorank };
    }

    this.locallyUpdateBug(bugId, dataToUpdate);
    this.stores.bugStore.updateBug(bugId, dataToUpdate);
  };

  downloadBugsAsJSON = async () => {
    // TODO: Download from server
    const json = JSON.stringify(this.currentTicketsData);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${this.currentProject?.id}_bugs.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    ampli.feedbackItemsExported();
  };

  getProjectUsers = async () => {
    if (!this.currentProject?.id) {
      return;
    }

    try {
      const response = await getProjectUsers(this.currentProject!.id);

      runInAction(() => {
        if (response.status === 200) {
          this.currentProjectUsers = response.data;

          // Update admin state.
          let isAdmin = false;
          let canLeaveProject = false;
          for (let i = 0; i < this.currentProjectUsers.length; i++) {
            if (
              this.currentProjectUsers[i].id ===
                this.stores.usersStore.currentUser.id &&
              this.currentProjectUsers[i].role === 'ADMIN'
            ) {
              isAdmin = true;
            }

            if (
              this.currentProjectUsers[i].id ===
                this.stores.usersStore.currentUser.id &&
              this.currentProjectUsers[i].type === 'project'
            ) {
              canLeaveProject = true;
            }
          }

          this.isProjectAdmin = isAdmin;
          this.canLeaveProject = canLeaveProject;
        }
      });
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.isProjectAdminLoaded = true;
    });
  };

  getProjectsUnreadCount = async () => {
    try {
      const response = await getProjectsUnreadCount();
      if (response.status === 200) {
        runInAction(() => {
          this.projectsUnreadCount = response.data;
          this.loadingFailed = false;
        });
      } else {
        runInAction(() => {
          this.loadingFailed = true;
        });
      }
    } catch (exp) {
      runInAction(() => {
        this.loadingFailed = true;
      });
    }
  };

  getProjects = async () => {
    this.loadingProjects = true;
    try {
      const response = await getProjects();

      if (response.status === 200) {
        runInAction(() => {
          this.projects = response.data;

          // Subscribe to project updates on app.
          for (let i = 0; i < this.projects.length; i++) {
            const project = this.projects[i];
            if (project) {
              (window as any).messageHandler?.postMessage(
                JSON.stringify({
                  action: PostMessageAction.SUBSCRIBE_TO_TOPIC,
                  data: {
                    topic: `${FCM_TOPIC.PROJECT}-${project.id}`,
                  },
                } as PostMessageData),
              );
            }
          }

          this.initialLoading = false;
        });
      }
    } catch (exp) {
      runInAction(() => {
        this.initialLoading = false;
      });
    }

    runInAction(() => {
      this.loadingProjects = false;
    });
  };

  loadFlowConfig = () => {
    if (this.currentProject && this.currentProject.flowConfig) {
      this.flowConfig = {
        ...flowConfigDefault,
        ...this.currentProject.flowConfig,
      };

      const currentFlowConfigKeys = Object.keys(this.currentProject.flowConfig);
      const flowConfigDefaultKeys = Object.keys(flowConfigDefault);
      if (currentFlowConfigKeys.length < flowConfigDefaultKeys.length) {
        this.updateFlowConfig();
      }
    } else {
      this.flowConfig = flowConfigDefault;
      this.updateFlowConfig();
    }
  };

  setLoadingCurrentProject = (loadingCurrentProject) => {
    this.loadingCurrentProject = loadingCurrentProject;
  };

  getProject = async (id: string) => {
    this.setCurrentProject(undefined);
    this.setLoadingCurrentProject(true);
    const response = await getProject(id);
    if (response && response.status === 200) {
      const project = response.data as Project;
      this.setLoadingCurrentProject(false);
      this.setCurrentProject(project);
      this.getProjectUsers();
      this.getProjectSurveyUnreadStatus(project.id);
      this.loadFlowConfig();

      this.stores.outboundStore.getProjectSurveyUnreadStatus(id);
    }
  };

  resendIntegrations = async () => {
    const response = await resendIntegrations(this.currentProject!.id);
    if (response && response.status === 200) {
      toast.success('Bugs are being sent to your connected integrations. 🎉');
    } else {
      toast.error(
        '🙀 Something went wrong while sending your bugs. Please contact us!',
      );
    }
  };

  loadProjectById = async (
    projectId: string,
    refresh: boolean = false,
    loadTeamAvatars = false,
  ) => {
    if (this.currentProject?.id !== projectId || refresh) {
      await this.getProject(projectId);
      await this.refreshBugsForCurrentProject();
    }

    if (loadTeamAvatars && this.currentProject?.apiKey) {
      loadTeamPreview(this.currentProject.apiKey)
        .then((res) => {
          runInAction(() => {
            this.teamPreview = res.data;
          });
        })
        .catch(() => {});
    }
  };

  openFeedbackItem = async ({ shareToken, openModal = true }) => {
    if (!this.currentProject || !shareToken) {
      return;
    }

    const currentPath = window.location.pathname;
    this.stores.bugStore.openFeedbackItem(
      this.currentProject.id,
      shareToken,
      currentPath,
      openModal,
    );
  };

  determineTitle = (dbTitle: string) => {
    if (dbTitle === 'OPEN') {
      return 'Open';
    }
    if (dbTitle === 'INPROGRESS') {
      return 'In Progress';
    }
    if (dbTitle === 'DONE') {
      return 'Done';
    }
    if (dbTitle === 'TOTEST') {
      return 'ToTest';
    }
    return '';
  };

  refreshBugsForCurrentProject = async () => {
    if (this.currentProject) {
      this.archivedBugsDataList.data = [];
      this.prepareCurrentTicketsData();
    }
  };

  clearCurrentProject = () => {
    this.setCurrentProject(undefined);
    this.setLoadingCurrentProject(false);
    this.currentTicketsData = {};
  };

  createProject = async (
    name: string,
    description: string,
    picture: string,
    templateId?: string,
    hideToast?: boolean,
  ) => {
    if (!this.stores.organisationStore.currentOrganisation) {
      return false;
    }

    this.setCurrentProject(undefined);
    this.currentTicketsData = {};
    this.setLoadingCurrentProject(true);

    try {
      const response = await createProject(
        name,
        description,
        picture,
        this.stores.organisationStore.currentOrganisation.id,
        templateId,
      );
      if (response.status === 201) {
        this.setCurrentProject(response.data as Project);
        this.setLoadingCurrentProject(false);
        this.getProjectUsers();
        this.loadFlowConfig();
        this.getProjects();
        this.refreshBugsForCurrentProject();
        if (!hideToast) {
          ConfettiHelper.start();
        }
        return true;
      }
    } catch (error: any) {
      if (error.response.status === 408) {
        this.stores.modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
          type: 'projectlimit',
        });
      } else {
        toast.error('Could not create project.');
      }
    }
    return false;
  };

  updateProject = async (
    id: string,
    data: any,
    showToast = true,
    reloadAfterUpdate = true,
  ) => {
    const response = await updateProject(id, data);
    if (response.status === 200) {
      if (showToast) {
        toast.success('Project updated 🎉');
      }
      if (reloadAfterUpdate) {
        this.setCurrentProject(response.data as Project);
        this.getProjectUsers();
        this.loadFlowConfig();
        this.getProjects();
      }
    }
  };

  updateProjectImage = async (id: string, picture: string) => {
    const response = await updateProjectPicture(id, picture);
    if (response.status === 200) {
      this.setCurrentProject(response.data as Project);
      this.getProjectUsers();
      this.loadFlowConfig();
      this.getProjects();
    }
  };

  leaveProject = async (projectID: string) => {
    try {
      await leaveProject(projectID);

      runInAction(() => {
        // Reload projects
        this.projects = [];
        this.setCurrentProject(undefined);
        this.getProjects();

        // Redirect to dashboard
        this.stores.navigate('/dashboard');
      });
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error('You are not authorizied');
      } else {
        toast.error('Could not leave project. Please try it again later!');
      }
    }
  };

  deleteProject = async (projectID: string) => {
    try {
      await deleteProject(projectID);

      // Reload projects
      this.getProjects();

      // Redirect to dashboard
      this.stores.navigate('/dashboard');

      // Cleanup current project.
      this.setCurrentProject(undefined);

      ampli.projectDeleted({
        projectId: projectID,
      });
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error('You are not authorizied');
      } else {
        toast.error('Could not delete project. Please try it again later!');
      }
    }
  };

  deleteCompletedBugs = async (
    projectID: string,
    status: string,
    type: string,
  ) => {
    try {
      await deleteCompletedBugs(projectID, status, type);
      toast.success('Successfully deleted items in column');
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error('You are not authorizied');
      } else {
        toast.error(
          'Could not delete completed bugs. Please try it again later!',
        );
      }
    }
  };

  archiveFeedbackItems = async (status, type) => {
    if (!this.currentProject) {
      return;
    }

    try {
      runInAction(() => {
        this.currentTicketsData[status].data = [];
        this.currentTicketsData[status].count = 0;
      });

      await archiveFeedbackItems(this.currentProject.id, status, type);

      toast.success('Successfully archived items in column');
    } catch (err: any) {
      toast.error('Could not archive items in column');
    }
  };

  setLoadingArchivedBugs = (loadingArchivedBugs: boolean) => {
    this.loadingArchivedBugs = loadingArchivedBugs;
  };

  updateFlowConfig = async (updatedData = {}) => {
    const newFlowConfig = {
      ...toJS(this.flowConfig),
      ...updatedData,
    };
    this.flowConfig = newFlowConfig;

    await this.updateProject(
      this.currentProject!.id,
      {
        flowConfig: newFlowConfig,
      },
      false,
      false,
    );
  };

  getArchivedFeedbackItems = async (args: {
    isSpam?: boolean;
    loadMore?: boolean;
  }) => {
    if (this.archivedBugsDataList.isLoading || !this.currentProject) {
      return;
    }

    try {
      this.archivedBugsDataList.isLoading = true;

      if (args.loadMore) {
        this.archivedBugsDataList.pageIndex += 1;
      } else {
        this.archivedBugsDataList.pageIndex = 0;
        this.archivedBugsDataList.data = [];
      }

      const response = await getArchivedBugs(
        this.currentProject.id,
        args.isSpam ?? false,
        getSkipAndLimitFromPage({
          pageIndex: this.archivedBugsDataList.pageIndex,
          itemsInPage,
        }),
      );

      if (response.status === 200) {
        runInAction(() => {
          this.archivedBugsDataList.data = [
            ...this.archivedBugsDataList.data,
            ...response.data.bugs,
          ];
        });
      }

      this.archivedBugsDataList.isLoading = false;
    } catch (_) {
      this.archivedBugsDataList.isLoading = false;
    }
  };

  localyRemoveArchivedBug = async (id: string) => {
    if (!this.currentProject) {
      return;
    }

    this.archivedBugsDataList.data = this.archivedBugsDataList.data.filter(
      (archivedBug) => archivedBug.id !== id,
    );
  };

  searchForArchivedFeedbackItems = async (args: { searchTerm: string }) => {
    if (!this.currentProject) {
      return;
    }

    if (args.searchTerm === '') {
      this.getArchivedFeedbackItems({ loadMore: false });
      return;
    }

    try {
      runInAction(() => {
        this.archivedBugsDataList.isLoading = true;
      });

      const response = await searchForFeedbackItems({
        projectId: this.currentProject.id,
        query: {
          searchTerm: args.searchTerm,
          archived: true,
        },
      });
      if (response.status === 200) {
        runInAction(() => {
          this.archivedBugsDataList.data =
            response.data && response.data.hits ? response.data.hits : [];
          this.archivedBugsDataList.isLoading = false;
        });
      }
    } catch (_) {
      runInAction(() => {
        this.archivedBugsDataList.isLoading = false;
      });
    }
  };

  handleTags = (tags: string[]) => {
    if (!this.currentProject) {
      return;
    }

    const feedbackTags = this.currentProject?.feedbackTags ?? [];
    for (let i = 0; i < tags.length; i++) {
      const currentTag = tags[i];
      if (
        feedbackTags.filter((tagItem) => tagItem.label === currentTag)
          .length === 0
      ) {
        feedbackTags.push({ label: currentTag, color: getRandomColor() });
        this.currentProject!.feedbackTags = feedbackTags;
        this.updateProject(this.currentProject!.id, this.currentProject, false);
      }
    }
  };

  getFeatureRequestStates = (excludeOpenDone = true) => {
    const featureRequestStates = this.currentProject?.projectTypes
      .find((value) => value.type === 'FEATURE_REQUEST')
      ?.options.possibleLanes.filter((featureRequestState) => {
        if (
          excludeOpenDone &&
          (featureRequestState.key === 'OPEN' ||
            featureRequestState.key === 'DONE')
        ) {
          return false;
        }
        return true;
      })
      .map((item) => {
        let color;
        switch (item.key) {
          case 'OPEN':
            color = '#85B5B3';
            break;

          case 'PLANNED':
            color = '#1FA0FF';
            break;

          case 'INPROGRESS':
            color = '#9B59B6';
            break;

          case 'DONE':
            color = '#30CB83';
            break;

          default:
            color = hashToColor(item.key);
            break;
        }

        return { ...item, color: color };
      });

    return featureRequestStates;
  };

  setEditingProject = () => {
    if (!this.currentProject) {
      return;
    }

    this.editingProject = JSON.parse(JSON.stringify(this.currentProject));

    this.editingProject!.flowConfig = JSON.parse(
      JSON.stringify(this.flowConfig),
    );
  };

  saveEditingProject = async (showToast: boolean = true) => {
    if (!this.editingProject || !this.currentProject) {
      return;
    }

    this.flowConfig = JSON.parse(
      JSON.stringify(this.editingProject.flowConfig),
    );

    await this.updateFlowConfig();

    runInAction(() => {
      this.setEditingProject();
    });

    if (showToast) {
      toast.success('Saved 🎉');
    }
  };

  /**
   * Sets the verify domain flag
   * @param isVerifyingDomain
   */
  setIsVerifyingDomain = (isVerifyingDomain) => {
    this.isVerifyingDomain = isVerifyingDomain;
  };

  /**
   * Add a domain to an organisation
   * @param domainName
   * @returns
   */
  addDomain = async (domainName: string) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    this.setIsVerifyingDomain(true);
    try {
      const resp = await addDomain(this.currentProject?.id, domainName);
      if (resp) {
        this.setCurrentProject(resp.data as Project);
        this.setIsVerifyingDomain(false);
        toast.success('Domain added 🎉');
        return;
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    toast.error('Could not add domain. Please contact our support team!');
    this.setIsVerifyingDomain(false);
  };

  verifyDomain = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    this.setIsVerifyingDomain(true);
    const resp = await verifyDomain(this.currentProject?.id);
    if (resp) {
      this.setCurrentProject(resp.data as Project);
    } else {
      toast.error('Verification failed.');
    }

    this.setIsVerifyingDomain(false);
  };

  removeDomain = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
    }

    const resp = await removeDomain(this.currentProject?.id);
    if (resp) {
      this.setCurrentProject(resp.data as Project);
      return resp.data as Project;
    } else {
      toast.error('Domain removal failed.');
    }

    return null;
  };

  setIsUpdatingSender = (isUpdatingSender) => {
    this.isUpdatingSender = isUpdatingSender;
  };

  updateDomainSettings = async (senderType, senderName, senderEmail) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    this.setIsUpdatingSender(true);
    const resp = await updateDomainSettings(
      this.currentProject?.id,
      senderType,
      senderName,
      senderEmail,
    );
    if (resp) {
      this.setCurrentProject(resp.data as Project);
      toast.success('Sender settings updated 🎉');
    } else {
      toast.error('Settings update failed.');
    }

    this.setIsUpdatingSender(false);
  };

  updateCustomDomainSettings = async (customDomain) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await updateCustomDomainSettings(
      this.currentProject?.id,
      customDomain,
    );
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomain = customDomain;
      });
    } else {
      toast.error('Domain is already in use by a Gleap customer.');
    }
  };

  deleteCustomDomainSettings = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await deleteCustomDomainSettings(this.currentProject?.id);
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomain = undefined;
      });
    }
  };

  updateCustomDomainHelpCenterSettings = async (customDomain) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await updateCustomDomainHelpCenterSettings(
      this.currentProject?.id,
      customDomain,
    );
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomainHelpCenter = customDomain;
      });
    } else {
      toast.error('Domain is already in use by a Gleap customer.');
    }
  };

  clearHelpCenterCache = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await clearHelpCenterCache(this.currentProject?.id);
    if (resp && resp.data && resp.data.success) {
      toast.success('Cache cleared ✅');
    } else {
      toast.error('An error occurred.');
    }
  };

  deleteCustomDomainHelpCenterSettings = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await deleteCustomDomainHelpCenterSettings(
      this.currentProject?.id,
    );
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomainHelpCenter = undefined;
      });
    }
  };

  migrateIntegrations = async () => {
    migrateIntegrations(this.currentProject?.id);
  };

  globalSearch = async (searchTerm: string) => {
    try {
      this.searchGlobalForTickets(searchTerm);
      this.searchGlobalForSessions(searchTerm);
      this.searchGlobalForComments(searchTerm);
      this.searchGlobalForOutbounds(searchTerm);
      this.searchGlobalForHelpcenterArticles(searchTerm);
    } catch (err) {}
  };

  searchGlobalForTickets = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.bug = {
        label: 'Tickets',
        type: 'TICKET',
        sort: 1,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForFeedbackItems({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.bug = {
          label: 'Tickets',
          type: 'TICKET',
          sort: 1,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchGlobalForSessions = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.session = {
        label: 'Contacts',
        type: 'SESSION',
        sort: 2,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForSessions({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.session = {
          label: 'Contacts',
          type: 'SESSION',
          sort: 2,
          isLoading: false,
          data: response.data,
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchGlobalForComments = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.comment = {
        label: 'Comments',
        type: 'COMMENT',
        sort: 3,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForComments({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.comment = {
          label: 'Comments',
          type: 'COMMENT',
          sort: 3,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchGlobalForOutbounds = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.outbound = {
        label: 'Outbounds',
        type: 'OUTBOUND',
        sort: 4,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForOutbounds({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.outbound = {
          label: 'Outbounds',
          type: 'OUTBOUND',
          sort: 4,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchGlobalForHelpcenterArticles = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.helpcenterArticle = {
        label: 'Articles',
        type: 'HELPCENTER_ARTICLE',
        sort: 5,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForHelpcenterArticles({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });
      runInAction(() => {
        this.globalSearchData.helpcenterArticle = {
          label: 'Articles',
          type: 'HELPCENTER_ARTICLE',
          sort: 5,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  refreshData = () => {
    // this.getProjects();

    if (this.currentProject) {
      this.getProjectSurveyUnreadStatus(this.currentProject.id);
      //this.loadProjectById(this.currentProject.id, true);
      this.refreshBugsForCurrentProject();
    }
  };

  addActionFlowNode(node) {
    if (!this.bot || !this.bot.actionFlows) {
      return;
    }

    this.bot.actionFlows.push(node);
    this.bot.actionFlows = [...this.bot.actionFlows];
  }
}
