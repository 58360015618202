import './SharedFeatureRequestsList.scss';
import { inject, observer } from 'mobx-react';
import FeatureRequestCard, {
  FeatureRequestCardSkeleton,
} from 'components/FeatureRequestCard/FeatureRequestCard';
import { HeadLine } from 'components/HeadLine/HeadLine';
import { Title } from 'components/Title/Title';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { Bug } from 'models/Bug';

interface SharedFeatureRequestsListProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  isShared?: boolean;
  boardShareToken?: string;
  showUnreadOnly?: boolean;
  filter?: string[];
  widgetMode?: boolean;
}

const SharedFeatureRequestsList = ({
  sharedBoardStorePublic,
  isShared = false,
  boardShareToken,
  showUnreadOnly = false,
  filter = [],
  widgetMode = false,
}: SharedFeatureRequestsListProps) => {
  let featureRequests: Bug[] =
    sharedBoardStorePublic?.featureRequestList.data ?? [];

  featureRequests = featureRequests.filter((card: Bug) => {
    if (card.type !== sharedBoardStorePublic?.currentFeedbackType?.type) {
      return false;
    }

    if (filter.length !== 0 && !filter.includes(card.status)) {
      return false;
    }

    if (showUnreadOnly && !card.notificationsUnread) {
      return false;
    }

    return true;
  });

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
      ) < 1;
    if (bottom) {
      sharedBoardStorePublic?.fetchAndSetFeatureRequestList({
        loadMore: true,
      });
    }
  };

  const renderEmptyState = () => {
    if (!featureRequests || featureRequests?.length > 0) {
      return null;
    }

    var maxFilters = sharedBoardStorePublic?.getFeatureRequestStates().length;
    if (isShared) {
      maxFilters--;
    }
    var title = 'No feature requests yet';
    var subTitle =
      'New feature requests will show up, as soon as they get reported.';
    if (filter.length > 0 && filter.length !== maxFilters) {
      title = 'Nothing found';
      subTitle = 'Try to reset the filters to see more feature requests.';

      if (!widgetMode) {
        return (
          <div className="feature-request-list-empty">
            <Title className="mb-3" label={title} />
            <div className="text subtitle">
              Try to reset the filters or{' '}
              <a
                href="#"
                onClick={() => {
                  (window as any).Gleap.startFeedbackFlow('featurerequests');
                }}
              >
                submit a new feature request
              </a>
              .
            </div>
          </div>
        );
      }
    }
    if (showUnreadOnly) {
      title = 'Nothing unseen, good job 💪';
      subTitle = 'There are no unseen feature requests waiting for you.';
    }

    return (
      <div className="feature-request-list-empty">
        <HeadLine title={title} subtitle={subTitle} />
      </div>
    );
  };

  const isLoading =
    (!sharedBoardStorePublic?.featureRequestList ||
      sharedBoardStorePublic?.featureRequestList.isLoading) &&
    sharedBoardStorePublic?.featureRequestList?.data?.length === 0;

  const loadMoreIsOngoing =
    (!sharedBoardStorePublic?.featureRequestList ||
      !sharedBoardStorePublic?.featureRequestList?.data ||
      sharedBoardStorePublic?.featureRequestList.isLoading) &&
    sharedBoardStorePublic!.featureRequestList?.data?.length > 0;

  if (loadMoreIsOngoing) {
    featureRequests = [
      ...featureRequests,
      { isLoading: true, id: Math.random().toString(36).substring(7) } as any,
    ];
  }

  return (
    <div
      className="feature-request-list-wrapper"
      onScroll={(e) => {
        handleScroll(e);
      }}
    >
      <div className="feature-request-list">
        {isLoading && (
          <>
            {Array.from(Array(10).keys()).map((val, index) => (
              <FeatureRequestCardSkeleton
                height={130}
                key={index}
              />
            ))}
          </>
        )}
        {!isLoading && renderEmptyState()}
        {featureRequests.map((featureRequest) => {
          if ((featureRequest as any).isLoading) {
            return (
              <FeatureRequestCardSkeleton
                height={130}
                key={featureRequest.id}
              />
            );
          }

          return (
            <div
              className="feature-request-card-container"
              key={featureRequest.id}
            >
              <FeatureRequestCard
                key={featureRequest.id}
                isShared={isShared}
                boardShareToken={boardShareToken}
                featureRequest={featureRequest}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default inject('sharedBoardStorePublic')(
  observer(SharedFeatureRequestsList),
);
