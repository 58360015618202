import React from 'react';
import './LoadingAnimation.scss';

const LoadingAnimation = () => {
  return (
    <div className="spring-spinner">
      <div className="spring-spinner-part top">
        <div className="spring-spinner-rotator" />
      </div>
      <div className="spring-spinner-part bottom">
        <div className="spring-spinner-rotator" />
      </div>
    </div>
  );
};

export default LoadingAnimation;
