import { Plantypes } from 'components/PriceTable/PriceTable';
import ConfettiHelper from 'helper/ConfettiHelper';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Bug } from 'models/Bug';
import { Comment, CommentTypes } from 'models/Comment';
import { Feature } from 'models/Enums';
import { toast } from 'react-toastify';
import WebSocketMessage from 'services/WebSocketMessage';
import { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import { getFormFieldValue } from 'helper/FormDataHelper';
import {
  updateBug,
  getBug,
  addCommentToBug,
  getComments,
  getLogs,
  createBug,
  deleteBug,
  getNetworkLogs,
  deleteCommentFromBug,
  getActivityLog,
  voteBug,
  subscribeToBug,
  getSharedComments,
  addSharedCommentWithSession,
  markBugAsDuplicate,
  archiveBug,
  unarchiveBug,
  removeBugAsDuplicate,
  sendToIntegration,
  userIsTypingInTicket,
  snoozeBug,
} from '../../services/BugHttpService';
import { MODALTYPE } from './ModalStore';
import { sendPostMessage } from 'services/Helper';

export enum FilterType {
  ASC,
  DESC,
}

export class BugStore implements WebSocketMessage {
  listView = false;
  menuOpenForBug: Bug | null = null;
  currentBug?: Bug;
  currentBugShareToken?: string;
  currentComments?: Comment[] = [];
  loadingComments?: boolean = false;
  activityLog?: String[];
  currentLogs?: String[];
  currentNetworkLogs?: any[];
  replayData?: any;
  stores: any = {};
  loadingBug = false;
  reportedBug = false;
  loadingSharedBug = false;
  loadedingSharedBugFailed = false;
  currentlyTyping: any = null;
  currentlyTypingTimeout: any = null;
  sharedGleapId?: string;
  sharedGleapHash?: string;
  sharedGleapIdSecret?: string;

  constructor() {
    makeAutoObservable(this);
  }

  setReportedBug(reportedBug: boolean) {
    this.reportedBug = reportedBug;
  }

  setStores(stores) {
    this.stores = stores;
  }

  clearCurrentlyTypingTimeout() {
    if (this.currentlyTypingTimeout) {
      clearTimeout(this.currentlyTypingTimeout);
      this.currentlyTypingTimeout = null;
    }
  }

  clearCurrentlyTyping() {
    this.currentlyTyping = null;
    this.clearCurrentlyTypingTimeout();
  }

  handleCurrentlyTyping = (data) => {
    runInAction(() => {
      if (
        this.currentBug &&
        this.currentBug.id === data.id &&
        data.user?.id !== this.stores?.usersStore?.currentUser?.id
      ) {
        this.clearCurrentlyTypingTimeout();

        if (data.typing) {
          this.currentlyTyping = data;
          this.currentlyTypingTimeout = setTimeout(() => {
            if (
              this.currentlyTyping &&
              this.currentlyTyping.id === this.currentBug?.id
            ) {
              this.currentlyTyping = null;
            }
          }, 10000);
        } else {
          this.clearCurrentlyTyping();
        }
      }
    });
  };

  onEvent = (event: string, data: any) => {
    if (!data) {
      return;
    }

    if (event === WEBSOCKET_EVENTS.BUG_USER_READ_ALL_COMMENTS) {
      setTimeout(() => {
        if (this.currentComments) {
          for (let i = 0; i < this.currentComments?.length; i++) {
            var comment = this.currentComments[i];
            comment.sessionNotificationsUnread = false;
          }
          this.setComments([...this.currentComments]);
        }
      }, 1500);
    }

    if (event === WEBSOCKET_EVENTS.BUG_CREATED) {
      this.reportedBug = true;
    }

    if (event === WEBSOCKET_EVENTS.BUG_UPDATED) {
      if (this.currentBug && this.currentBug.id === data.id) {
        this.loadSharedBug(this.currentBug.shareToken, false);
      }
    }

    if (event === WEBSOCKET_EVENTS.BUG_TYPING) {
      this.handleCurrentlyTyping(data);
    }

    if (event === WEBSOCKET_EVENTS.BUG_DELETED) {
      if (this.currentBug && this.currentBug.id === data.id) {
        this.stores.modalStore!.closeModal();
      }
    }

    if (
      event === WEBSOCKET_EVENTS.COMMENT_CREATED &&
      this.currentBug &&
      this.currentBug.id === data.bug &&
      this.currentComments &&
      data &&
      this.stores.usersStore.currentUser.id !== data.user?.id
    ) {
      this.handleCurrentBugNotify();
      this.currentComments!.push(data);
    }

    if (
      event === WEBSOCKET_EVENTS.COMMENT_UPDATED &&
      this.currentBug &&
      this.currentBug.id === data.bug &&
      this.currentComments &&
      data
    ) {
      const foundCommentIndex = this.currentComments.findIndex(
        (comment) => comment.id === data.id,
      );
      if (foundCommentIndex > -1) {
        this.currentComments[foundCommentIndex] = data;
      }
    }

    if (
      event === WEBSOCKET_EVENTS.COMMENT_DELETED &&
      this.currentComments &&
      data.id
    ) {
      this.currentComments = this.currentComments.filter(
        (comment) => comment.id !== data.id,
      );
    }
  };

  handleCurrentBugNotify() {
    setTimeout(() => {
      if (this.currentBug && this.currentBug.notificationsUnread) {
        this.updateBug(this.currentBug.id, { notificationsUnread: false });
      }
    }, 500);
  }

  pushSharedBug = (title?: string, replaceTitle: boolean = false) => {
    sendPostMessage(
      JSON.stringify({
        type: 'app',
        name: replaceTitle ? 'pagetitle' : 'page',
        data: {
          title: title || '',
          path: window.location.pathname,
        },
      }),
    );
  };

  loadSharedBug = async (
    shareId: string,
    loadComments = true,
    gleapId?: string,
    gleapHash?: string,
    token?: string,
  ) => {
    if (loadComments) {
      this.currentComments = [];
      this.loadingComments = true;
    }
    this.loadingSharedBug = true;

    if (gleapId) {
      this.sharedGleapId = gleapId;
    }
    if (gleapHash) {
      this.sharedGleapHash = gleapHash;
    }
    if (token) {
      this.sharedGleapIdSecret = token;
    }

    this.pushSharedBug('');

    try {
      const response = await getBug(shareId, gleapId, gleapHash, token);
      runInAction(() => {
        if (response.status === 200) {
          this.currentBug = response.data;

          if (this.currentBug) {
            this.pushSharedBug(
              getFormFieldValue(this.currentBug, 'title'),
              true,
            );
          }

          if (loadComments) {
            this.getSharedComments(shareId, gleapId, gleapHash, token ?? '');
          }
          this.loadedingSharedBugFailed = false;
        } else {
          this.loadedingSharedBugFailed = true;
        }
        this.loadingSharedBug = false;
      });
    } catch (err: any) {
      runInAction(() => {
        this.loadedingSharedBugFailed = true;
        this.loadingSharedBug = false;
      });
    }
  };

  openFeedbackItem = async (projectId, shareToken, currentPath, openModal) => {
    if (this.currentBugShareToken === shareToken) {
      // Already opened bug.
      return;
    }

    this.currentBugShareToken = shareToken;
    this.currentComments = [];
    this.currentBug = undefined;
    this.loadingBug = true;

    // Update unread state locally.
    this.stores.projectStore!.locallyUpdateBug(shareToken, {
      notificationsUnread: false,
    });

    if (openModal) {
      this.stores.modalStore!.openModal(MODALTYPE.BUG_DETAIL);
    }

    try {
      const response = await getBug(shareToken);
      runInAction(() => {
        if (response.status === 200) {
          if (this.currentBugShareToken !== shareToken) {
            return;
          }

          this.currentBug = response.data;

          if (
            !(
              this.currentBug &&
              this.currentlyTyping &&
              this.currentlyTyping.id === this.currentBug.id
            )
          ) {
            this.currentlyTyping = null;
          }

          if (!currentPath.includes(shareToken)) {
            // Prevent double id push.
            if (currentPath) {
              const pathItems = currentPath.split('/');
              const lastItem = pathItems[pathItems.length - 1];
              if (lastItem.length > 50) {
                currentPath = currentPath.replace(`/${lastItem}`, '');
              }
            }
            this.stores.navigate(`${currentPath}/${shareToken}`);
          }

          this.getComments(shareToken);
        }
      });
    } catch (err: any) {
      var existingBug: any = null;
      const dataKeys = Object.keys(
        this.stores?.projectStore?.currentTicketsData ?? {},
      );
      for (let i = 0; i < dataKeys.length; i++) {
        const key = dataKeys[i];
        const tickets =
          this.stores?.projectStore?.currentTicketsData?.[key].data;
        const ticketFound = tickets?.find(
          (ticket) => ticket.shareToken === shareToken,
        );
        if (ticketFound) {
          existingBug = ticketFound;
        }
      }

      runInAction(() => {
        this.stores.modalStore!.closeModal();

        if ((err as any)?.response?.status === 408) {
          this.stores.modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
            projectId,
            bugId: existingBug?.id,
            type: 'dataretention',
            plan: Plantypes.GROWTH,
          });
        } else {
          toast.error('Feedback does not exist anymore.');
        }
      });
    }

    runInAction(() => {
      this.loadingBug = false;
    });
  };

  clearCurrentBug = (redirect = true) => {
    this.currentBug = undefined;
    this.currentBugShareToken = undefined;
    if (redirect) {
      let url = window.location.pathname;
      const splittedUrl = window.location.pathname.split('/');

      if (splittedUrl.length > 4) {
        url = url.slice(0, url.lastIndexOf('/'));
      }

      this.stores.navigate(url);
    }
  };

  setMenuOpenForBug(bug: Bug | null): void {
    this.menuOpenForBug = bug;
  }

  locallyUpdateCurrentBug(data) {
    if (this.currentBug) {
      this.currentBug = {
        ...toJS(this.currentBug),
        ...data,
      };
    }
  }

  subscribeUser = async (bug: Bug, email: string, subscribe = true) => {
    if (!bug) {
      return false;
    }

    try {
      const response = await subscribeToBug(bug.id, email);
      if ((response as any).data) {
        var upvotes: any[] = toJS(bug.upvotes ?? []);
        if (subscribe) {
          upvotes = [...upvotes, { email }];
        } else {
          upvotes = upvotes.filter((e) => e.email !== email);
        }

        const data = {
          upvotesCount: (response as any).data.upvotesCount ?? 1,
          upvotes: upvotes,
        };

        this.stores.projectStore.locallyUpdateBug(bug.id, data);
        this.locallyUpdateCurrentBug(data);
        return (response as any).data.upvoted;
      }
      return false;
    } catch (err: any) {
      toast.error('An error occurred 😵‍💫');
      return false;
    }
  };

  voteBug = async (bug: Bug) => {
    const session = this.stores.sharedSessionStore.session;
    if (!bug || !session) {
      return false;
    }

    try {
      const response = await voteBug(
        bug.shareToken,
        session.gleapId,
        session.gleapHash,
      );
      if ((response as any).data) {
        const data = {
          upvotesCount: (response as any).data.upvotesCount ?? 1,
        };
        this.stores.projectStore.locallyUpdateBug(bug.id, data);
        this.locallyUpdateCurrentBug(data);
        return (response as any).data.upvoted;
      }
      return false;
    } catch (err: any) {
      toast.error('Failed to vote for feature request 😵‍💫');
      return false;
    }
  };

  sendToIntegration = async (
    id: string,
    integrationId: string,
    integrationType: string,
  ) => {
    try {
      await sendToIntegration(id, integrationId, integrationType);
      toast.success('Successfully sent to integration 📬');
      this.stores.modalStore!.closeModal();
    } catch (err: any) {
      toast.error('Could not send issue to integration 😱');
    }
  };

  snoozeBug = async (id: string, duration: any) => {
    if (this.currentBug && this.currentBug.id === id) {
      this.currentBug = {
        ...this.currentBug,
        status: 'SNOOZED',
      };
    }

    // Locally update bug data.
    this.stores.projectStore.locallyUpdateBug(id, {
      status: 'SNOOZED',
    });

    try {
      await snoozeBug(id, {
        duration,
      });
    } catch (err: any) {
      toast.error('Could not update bug');
    }
  };

  updateBug = async (id: string, data: any) => {
    if (this.currentBug && this.currentBug.id === id) {
      this.currentBug = {
        ...this.currentBug,
        ...data,
      };
    }

    if (data.status === 'DONE') {
      ConfettiHelper.start(
        ['✅', '👏', '🎉'],
        80,
        Math.floor(Math.random() * (10 - 3 + 1) + 8),
      );
    }

    // Locally update bug data.
    this.stores.projectStore.locallyUpdateBug(id, data, true);

    try {
      await updateBug(id, data);
    } catch (err: any) {
      toast.error('Could not update bug');
    }
  };

  markBugAsDuplicate = async (id: string, sourceBugId: string) => {
    try {
      await markBugAsDuplicate(id, sourceBugId);
    } catch (err: any) {
      toast.error('Could not mark bug as duplicate');
    }
  };

  removeBugAsDuplicate = async (id: string) => {
    try {
      await removeBugAsDuplicate(id);
    } catch (err: any) {
      toast.error('Could not remove bug from duplicate');
    }
  };

  createBug = async (data: any) => {
    const projectId = this.stores.projectStore.currentProject.id;

    try {
      let reportedBy = '';
      if (data.session && data.session.email) {
        reportedBy = data.session.email;
        data.session = data.session.id;
      } else if (this.stores.usersStore && this.stores.usersStore.currentUser) {
        reportedBy = this.stores.usersStore.currentUser.email;
      }

      const response = await createBug(
        {
          ...data,
          screenshotUrl: data.screenshotUrl,
          priority: data.priority,
          manuallyAdded: true,
          reportedBy,
          type: this.stores.projectStore?.currentFeedbackType?.type,
        },
        projectId,
      );

      return response;
    } catch (err: any) {
      return err;
    }
  };

  addSharedCommentWithSession = async (
    shareToken: string,
    gleapId: string,
    gleapHash: string,
    comment: string,
  ) => {
    const placeholderComment = {
      id: Math.random().toString(36).substring(7),
      data: {
        content: comment,
      },
      session: this.currentBug?.session,
      type: CommentTypes.SHARED_COMMENT,
      sending: true,
    } as Comment;

    this.currentComments = [...this.currentComments!, placeholderComment];

    const response = await addSharedCommentWithSession(
      shareToken,
      gleapId,
      gleapHash,
      comment,
    );
    if (response.status !== 201) {
      return toast.error('Failed sending comment.');
    }

    const indexToUpdate = this.currentComments.findIndex(
      (c) => c.id === placeholderComment.id,
    );
    this.currentComments[indexToUpdate] = response.data;

    this.setComments(this.currentComments);
  };

  userIsTypingInTicket = async (id: string, typing: boolean) => {
    try {
      await userIsTypingInTicket(id, typing);
    } catch (exp) { }
  };

  addCommentToBug = async (
    id: string,
    comment: string,
    isNote: Boolean,
    attachments?: any[],
  ) => {
    const placeholderComment = {
      type: isNote ? CommentTypes.NOTE : CommentTypes.TEXT,
      id: Math.random().toString(36).substring(7),
      data: {
        content: comment,
      },
      user: {
        ...this.stores?.usersStore?.currentUser,
      },
      sending: true,
    } as Comment;

    var internalComments = [...this.currentComments!, placeholderComment];
    this.setComments(internalComments);

    const response = await addCommentToBug(id, comment, isNote, attachments);
    if (response.status !== 201) {
      return toast.error('Failed sending comment.');
    }

    const indexToUpdate = internalComments.findIndex(
      (c) => c.id === placeholderComment.id,
    );

    if (indexToUpdate >= 0) {
      internalComments[indexToUpdate] = response.data;
    }

    this.setComments(internalComments);
  };

  setComments = (comments: Comment[]) => {
    this.currentComments = comments;
  };

  deleteCommentFromBug = async (id: string, commentId: string) => {
    const response = await deleteCommentFromBug(id, commentId);
    if (response.status !== 201) {
      toast.error('Failed sending comment.');
    }
  };

  getSharedComments = async (
    id: string,
    gleapId?: string,
    gleapHash?: string,
    sharedGleapIdSecret?: string,
    showLoadingIndicator = true,
  ) => {
    if (showLoadingIndicator) {
      this.loadingComments = true;
    }
    const response = await getSharedComments(
      id,
      gleapId,
      gleapHash,
      sharedGleapIdSecret,
    );

    runInAction(() => {
      if (response.status === 200) {
        this.currentComments = response.data;
      }
      this.loadingComments = false;
    });
  };

  getComments = async (shareToken: string) => {
    this.loadingComments = true;
    const response = await getComments(shareToken);

    runInAction(() => {
      if (this.currentBugShareToken !== shareToken) {
        return;
      }

      if (response.status === 200) {
        this.currentComments = response.data;
      }
      this.loadingComments = false;
    });
  };

  getNetworkLogs = async (id: string) => {
    try {
      const response = await getNetworkLogs(
        id,
        this.sharedGleapId ?? '',
        this.sharedGleapHash ?? '',
        this.sharedGleapIdSecret ?? '',
      );
      runInAction(() => {
        if (response.status === 200) {
          if (response.data && response.data.requests) {
            this.currentNetworkLogs = response.data.requests.reverse();
          }
        }
      });
    } catch (exp) {
      runInAction(() => {
        this.currentNetworkLogs = [];
      });
    }
  };

  getActivityLog = async (shareToken: string) => {
    try {
      const response = await getActivityLog(
        shareToken,
        this.sharedGleapId ?? '',
        this.sharedGleapHash ?? '',
        this.sharedGleapIdSecret ?? '',
      );
      runInAction(() => {
        if (response.status === 200) {
          const activities: any[] = [];
          for (let i = 0; i < response.data.length; i++) {
            activities.push(response.data[i]);
          }

          this.activityLog = activities;
        }
      });
    } catch {
      toast.error('Could not load activity log.');
    }
  };

  getLogs = async (shareToken: string) => {
    try {
      const response = await getLogs(
        shareToken,
        this.sharedGleapId ?? '',
        this.sharedGleapHash ?? '',
        this.sharedGleapIdSecret ?? '',
      );
      runInAction(() => {
        if (response.status === 200) {
          const logs: any[] = [];
          for (let i = 0; i < response.data.logs.length; i++) {
            const log = response.data.logs[i];
            if (log[0]) {
              logs.push(log[0]);
            } else {
              logs.push(log);
            }
          }

          this.currentLogs = logs.reverse();
        }
      });
    } catch {
      toast.error('Could not get logs.');
    }
  };

  deleteBug = async (id: string) => {
    try {
      await deleteBug(id);

      runInAction(() => {
        toast.success('Successfully deleted ticket.');
        this.currentBug = undefined;
        this.currentBugShareToken = undefined;
        this.stores.modalStore!.closeModal();
      });
    } catch (err: any) {
      toast.error('Could not delete ticket.');
    }
  };

  archiveBug = async (id: string) => {
    try {
      await archiveBug(id);

      runInAction(() => {
        toast.success('Successfully archived ticket.');
        this.currentBug = undefined;
        this.currentBugShareToken = undefined;
        this.stores.modalStore!.closeModal();
      });
    } catch (_) {
      toast.error('Could not archive ticket.');
    }
  };

  unarchiveBug = async (id: string) => {
    try {
      await unarchiveBug(id);
      this.locallyUpdateCurrentBug({ archived: false, isSpam: false });
      this.stores.projectStore.localyRemoveArchivedBug(id);
      toast.success('Successfully unarchived ticket.');
    } catch (_) {
      toast.error('Could not unarchive ticket.');
    }
  };

  isFeatureInPlan = (feature: Feature, values?: string[]) => {
    try {
      const metadata = this.currentBug?.plan;

      if (metadata && metadata[feature]) {
        if (metadata[feature] === 'true') {
          return true;
        }

        if (values && values.length > 0) {
          for (let i = 0; i < values.length; i++) {
            if (metadata[feature] === values[i]) {
              return true;
            }
            if (metadata[feature] !== 'false' && values[i] === 'number') {
              return true;
            }
          }
        }
      }
      return false;
    } catch {
      return false;
    }
  };

  handleTags = (tags: string[]) => {
    if (!this.currentBug) {
      return;
    }

    this.stores.projectStore.handleTags(tags);
    this.updateBug(this.currentBug!.id, {
      tags,
    });
  };

  refreshData = () => {
    if (this.currentBug) {
      this.stores.projectStore.openFeedbackItem({
        shareToken: this.currentBug.shareToken,
        openModal: false,
      });
    }
  };
}
