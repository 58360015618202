import axios from './Axios';

const startCheckoutFlow = (planId: string, organisationId: string) => {
  return axios.get(
    `/stripe/create-checkout-session?planId=${planId}&organisationId=${organisationId}`,
  );
};

const startCustomerPortal = (organisationId: string) => {
  return axios.get(
    `/stripe/create-customer-portal-session?organisationId=${organisationId}`,
  );
};

const getCustomer = (customerID: String) => {
  return axios.get(`/stripe/customers/${customerID}`);
};

const updateSubscription = (
  organisationId: string,
  cancel: boolean,
  reason?: string,
  reasonExtended?: string,
) => {
  return axios.put(`/stripe/subscription?organisationId=${organisationId}`, {
    cancel,
    reason,
    reasonExtended,
  });
};

const upgradeSubscription = (organisationId: string) => {
  return axios.put(`/stripe/upgrade-plan?organisationId=${organisationId}`, {});
};

const upgradeToYearlySubscription = (organisationId: string) => {
  return axios.put(`/stripe/yearly-plan?organisationId=${organisationId}`, {});
};

const getSubscription = (organisationId: string) => {
  return axios.get(`/stripe/subscription?organisationId=${organisationId}`);
};

const getInvoices = (organisationId: string) => {
  return axios.get(`/stripe/invoices?organisationId=${organisationId}`);
};

const getCustomerDetails = (organisationId: string) => {
  return axios.get(`/stripe/customerdetails?organisationId=${organisationId}`);
};

export {
  getCustomer,
  startCheckoutFlow,
  startCustomerPortal,
  getInvoices,
  getCustomerDetails,
  getSubscription,
  updateSubscription,
  upgradeToYearlySubscription,
  upgradeSubscription,
};
