import axios from '../Axios';

const getAuthCodeFromSlack = (projectID) => {
  const scopes =
    'channels:read,chat:write,chat:write.public,commands,groups:read,im:read,mpim:read,users:read,users:read.email';

  return window.open(
    `https://slack.com/oauth/v2/authorize?scope=${encodeURIComponent(
      scopes,
    )}&redirect_uri=${
      process.env.REACT_APP_BASEURL
    }/projects/${projectID}/integrations/slack&client_id=${
      process.env.REACT_APP_SLACK_CLIENT_ID
    }`,
    '_self',
  );
};

const setSlackAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/slack`, {
    code,
  });
};

const setSlackActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/slack/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectSlackIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/slack/${integrationID}`,
  );
};

const getSlackChannels = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/slack/${integrationID}/channels`,
  );
};

export {
  getAuthCodeFromSlack,
  setSlackAuthToken,
  setSlackActions,
  disconnectSlackIntegration,
  getSlackChannels,
};
