/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import TimeAgo from 'react-timeago';
import './ProjectStatistics.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Loading from 'components/Loading/Loading';
import { BugStore } from 'stores/private/BugStore';
import { formatCSATScore, getEmojiForVal } from 'helper/ScoreFormater';
import classNames from 'classnames';
import BoardWrapperComponent from 'components/BoardWrapperComponent/BoardWrapperComponent';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import StatCard from 'components/StatCard/StatCard';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import Chart from 'react-apexcharts';
import { ampli } from 'ampli';

interface ProjectAnalyticsProps {
  projectStore?: ProjectStore;
  bugStore?: BugStore;
}

function ProjectAnalytics({ projectStore, bugStore }: ProjectAnalyticsProps) {
  // @ts-ignore
  const { shareToken } = useParams();
  const currentProjectAnalytics = projectStore?.currentProjectAnalytics;
  const [chartCategories, setChartCategories] = useState([] as any);
  const [monthlyRatings, setMonthlyRatings] = useState([] as any);

  useEffect(() => {
    if (shareToken) {
      projectStore!.openFeedbackItem({ shareToken });
    }

    ampli.projectDashboardViewed({
      projectId: projectStore?.currentProject?.id,
    });
  }, []);

  useEffect(() => {
    projectStore!.getCurrentProjectAnalytics(projectStore?.currentProject?.id);
  }, [projectStore?.currentProject?.id]);

  useEffect(() => {
    // Prepare analytics for chart
    if (currentProjectAnalytics.monthlyRatings) {
      const categories: any[] = [];
      const ratings: any[] = [];

      var hasValues = false;
      for (let i = 0; i < currentProjectAnalytics.monthlyRatings.length; i++) {
        categories.push(currentProjectAnalytics.monthlyRatings[i].month);
        ratings.push(
          `${currentProjectAnalytics.monthlyRatings[i].average * 10} %`,
        );
        if (currentProjectAnalytics.monthlyRatings[i].average > 0) {
          hasValues = true;
        }
      }

      if (hasValues) {
        setChartCategories(categories);
        setMonthlyRatings(ratings);
      }
    }
  }, [currentProjectAnalytics]);

  const containerClassName = classNames({
    'statistics-container-inner': true,
  });

  const buildCSATTrends = () => {
    if (!monthlyRatings || monthlyRatings.length === 0) {
      return null;
    }

    const chartOptions: any = {
      chart: {
        id: 'Trends',
        toolbar: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
        colors: ['#485bff'],
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: chartCategories,
      },
    };

    const chartSeries = [
      {
        name: 'CSAT-Score',
        data: monthlyRatings,
      },
    ];

    return (
      <BoardWrapperComponent label="CSAT trends" className="w-60">
        <>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="line"
            width="100%"
            height={235}
          />
        </>
      </BoardWrapperComponent>
    );
  };

  const getClassForCATScore = (className, score) => {
    return classNames({
      [className]: true,
      'score-green': score >= 70,
      'score-organge': score > 45 && score < 70,
      'score-red': score <= 45,
    });
  };

  const calcAverageTimeToComplete = () => {
    if (
      !currentProjectAnalytics?.itemAnalytics?.timeToCompletion ||
      !currentProjectAnalytics?.itemAnalytics?.itemsCompleted
    ) {
      return 0;
    }

    return (
      currentProjectAnalytics?.itemAnalytics?.timeToCompletion /
      currentProjectAnalytics?.itemAnalytics?.itemsCompleted
    );
  };

  const calcPrevAverageTimeToComplete = () => {
    if (
      !currentProjectAnalytics?.prevItemAnalytics?.timeToCompletion ||
      !currentProjectAnalytics?.prevItemAnalytics?.itemsCompleted
    ) {
      return 0;
    }

    return (
      currentProjectAnalytics?.prevItemAnalytics?.timeToCompletion /
      currentProjectAnalytics?.prevItemAnalytics?.itemsCompleted
    );
  };

  const buildActivites = () => {
    if (
      !(
        currentProjectAnalytics.lastRatings &&
        currentProjectAnalytics.lastRatings.length > 0
      )
    ) {
      return null;
    }

    return (
      <BoardWrapperComponent label="Latest ratings" className="w-60">
        <div className="statistics-card-rows">
          {currentProjectAnalytics.lastRatings &&
            currentProjectAnalytics.lastRatings.map((lastRating, index) => {
              return (
                <div className="statistics-card-value-row" key={index}>
                  <div className="statistics-card-value-row-key">
                    <a
                      className="cursor-focus"
                      onClick={() => {
                        projectStore!.openFeedbackItem({
                          shareToken: lastRating.shareToken,
                        });
                      }}
                    >
                      #{lastRating.bugId}
                    </a>{' '}
                    <TimeAgo date={lastRating.createdAt} />
                  </div>
                  <div
                    className={getClassForCATScore(
                      'statistics-card-value-row-value',
                      lastRating.pagerating * 10,
                    )}
                  >
                    {formatCSATScore(lastRating.pagerating)}{' '}
                    {getEmojiForVal(lastRating.pagerating * 10)}
                  </div>
                </div>
              );
            })}
        </div>
      </BoardWrapperComponent>
    );
  };

  const renderStatistics = () => {
    if (!currentProjectAnalytics) {
      return <></>;
    }

    return (
      <div className={containerClassName}>
        <StatCard
          className="w-30"
          icon="square-plus"
          label="Active users"
          subLabel="Last 30 days"
          valueSubLabel="active users"
          value={currentProjectAnalytics?.sessionsAnalytics?.count ?? 0}
          prevValue={currentProjectAnalytics?.prevSessionsAnalytics?.count ?? 0}
          showNoData={
            !currentProjectAnalytics?.sessionsAnalytics?.count &&
            !currentProjectAnalytics?.prevSessionsAnalytics?.count
          }
        />
        <StatCard
          className="w-30"
          icon="square-plus"
          label="Active users"
          subLabel="Last 3 month"
          valueSubLabel="active users"
          hideChange
          value={
            currentProjectAnalytics?.sessionsAnalyticsLastThreeMonth?.count ?? 0
          }
          showNoData={
            !currentProjectAnalytics?.sessionsAnalyticsLastThreeMonth?.count
          }
        />
        <StatCard
          className="w-30"
          icon="square-plus"
          label="Items created"
          subLabel="Last 30 days"
          valueSubLabel="created items"
          value={currentProjectAnalytics?.itemAnalytics?.itemsCreated ?? 0}
          prevValue={
            currentProjectAnalytics?.prevItemAnalytics?.itemsCreated ?? 0
          }
          showNoData={
            !currentProjectAnalytics?.itemAnalytics?.itemsCreated &&
            !currentProjectAnalytics?.prevItemAnalytics?.itemsCreated
          }
        />
        <StatCard
          className="w-30"
          icon="square-check"
          label="Items completed"
          subLabel="Last 30 days"
          valueSubLabel="completed items"
          value={currentProjectAnalytics?.itemAnalytics?.itemsCompleted ?? 0}
          prevValue={
            currentProjectAnalytics?.prevItemAnalytics?.itemsCompleted ?? 0
          }
          showNoData={
            !currentProjectAnalytics?.itemAnalytics?.itemsCompleted &&
            !currentProjectAnalytics?.prevItemAnalytics?.itemsCompleted
          }
        />
        <StatCard
          className="w-30"
          icon="globe"
          label="Widget page loads"
          subLabel="Last 30 days"
          valueSubLabel="page loads"
          value={currentProjectAnalytics?.widgetAnalytics?.count ?? 0}
          prevValue={currentProjectAnalytics?.prevWidgetAnalytics?.count ?? 0}
          showNoData={
            !currentProjectAnalytics?.widgetAnalytics?.count &&
            !currentProjectAnalytics?.prevWidgetAnalytics?.count
          }
        />
        <StatCard
          className="w-30"
          icon="gauge-simple-max"
          label="Time to completion"
          subLabel="Last 30 days"
          valueSubLabel="Average time to completion"
          type="timespan"
          value={calcAverageTimeToComplete()}
          prevValue={calcPrevAverageTimeToComplete()}
          hideChange
          showNoData={!currentProjectAnalytics?.itemAnalytics?.itemsCompleted}
        />
        {currentProjectAnalytics?.rating?.averageRating && (
          <StatCard
            className="w-30"
            icon="face-laugh-beam"
            label="CSAT-Score"
            subLabel="Last 30 days"
            valueSubLabel="Customer Satisfaction Score"
            type="csat"
            hideChange
            value={currentProjectAnalytics?.rating?.averageRating ?? 0}
            showNoData={
              typeof currentProjectAnalytics?.rating?.averageRating ===
              'undefined'
            }
          />
        )}
        {buildCSATTrends()}
        {buildActivites()}
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Dashboard" tag="BETA" />
      <PageContent hasTitle>
        {projectStore?.loadingStatistics ? <Loading /> : renderStatistics()}
        {!projectStore?.currentProject?.sdkInstalled && (
          <div className="project-not-installed">
            <NotInstalledCard />
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
}

export default inject('projectStore', 'bugStore')(observer(ProjectAnalytics));
