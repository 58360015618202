export const getSessionName = (session) => {
    if (session?.name) {
        return session?.name;
    }

    if (session && session.id) {
        var id = session.id;
        var ctr = 0;
        var counter = parseInt(id.slice(ctr, (ctr += 6)), 16);
        return `Guest ${counter}`;
    }

    return 'Guest';
}