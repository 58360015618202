import React from 'react';
import classNames from 'classnames';
import './Container.scss';

const Container = ({ className, children }: any) => {
  const containerClassNmae = classNames(
    {
      container: true,
    },
    className,
  );

  return <div className={containerClassNmae}>{children}</div>;
};

export default Container;
