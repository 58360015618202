import * as React from 'react';
import { Navigate } from 'react-router-dom';

function PublicRoute({ children }: any) {
  return localStorage.getItem('@bbtoken') === null ? (
    children
  ) : (
    <Navigate to="/dashboard" replace />
  );
}

export default PublicRoute;
