import { ampli } from 'ampli';
import ApiKey from 'components/ApiKey/ApiKey';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectSecurity.scss';

interface ProjectSecurityProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const ProjectSecurity = ({
  modalStore,
  projectStore,
}: ProjectSecurityProps) => {
  const currentProject = projectStore?.currentProject;
  const [showSecuritySecret, setShowSecuritySecret] = useState(false);
  const [secureSessionIdentify, setSecureSessionIdentify] = useState(false);

  useEffect(() => {
    if (currentProject && currentProject.secureSessionIdentify) {
      setSecureSessionIdentify(true);
    }
  }, [currentProject]);

  if (!currentProject) {
    return <></>;
  }

  return (
    <PageContainer className="project-security">
      <PageHeadLine title="User identity" />
      <PageContent hasTitle isMediumBoxed>
        <div className="project-security-inner-container">
          <div className="options-group">
            <div className="options-group-header">Enforce identity verification</div>
            <div className='text mt-10'>
              Enforce identity verification on all platforms to prevent third parties from impersonating logged-in users.<br />
              Once enabled, Gleap will require you to pass the correct user hash in order to identify users.
            </div>
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={async (checked) => {
                    setSecureSessionIdentify(checked);

                    try {
                      await projectStore!.updateProject(currentProject!.id, {
                        secureSessionIdentify: checked,
                      });
                    } catch (exp) { }

                    if (checked) {
                      ampli.securedIdentifyEnabled({
                        projectId: currentProject.id,
                      });
                    }
                  }}
                  checked={secureSessionIdentify}
                />
                <span>
                  Enforce identity verification
                </span>
              </div>
            </div>
            <InfoBox className="mt-20">
              <>
                Once enforced, you must pass a user hash to identify a user. <a
                  target="_blank"
                  href="https://docs.gleap.io/guides/enforce-identity-verification" rel="noreferrer"
                >
                  <i className="ml-2 fa-regular fa-book-open" /> Learn more
                </a>
              </>
            </InfoBox>
          </div>
          <div className="options-group">
            <div className="options-group-header">User hash generation</div>
            <div className='text'>
              Please read the following documentation to learn more on how to generate the required user hash.<br /><a
                target="_blank"
                href="https://docs.gleap.io/guides/enforce-identity-verification" rel="noreferrer"
              >
                <i className="ml-2 fa-regular fa-book-open" /> User hash calculation
              </a>
            </div>
          </div>
          <div className="options-group">
            <div className="options-group-header">Identity verification secret</div>
            {!showSecuritySecret && <LinkButton icon="eye-slash" iconSideRight={false} label='Show secret key' onClick={() => {
              setShowSecuritySecret(true);
            }} />}
            {showSecuritySecret && <>
              <ApiKey apiKey={projectStore?.currentProject?.secretApiKey ?? '--'} />
              <InfoBox className="mt-20">
                <>
                  Please note that this is <b>NOT</b> your API key.
                </>
              </InfoBox>
            </>}
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(ProjectSecurity));
