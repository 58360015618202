import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import HelpCenterArticle from './HelpcenterConversationArticle';

export const HelpCenterArticleExtension = Node.create({
  name: 'helpCenterArticle',
  group: 'block',
  atom: true,

  addNodeView() {
    return ReactNodeViewRenderer(HelpCenterArticle);
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 0];
  },

  addAttributes() {
    return {
      articleId: {
        default: null,
      },
      articleTitle: {
        default: 'No title',
      },
      articleDescription: {
        default: 'No description',
      },
      articleUrl: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'helpcenter-article',
        getAttrs: (node) => {
          if (typeof node === 'string') {
            return {};
          }

          if (node.nodeType !== 1 /* Node.ELEMENT_NODE */) {
            return {};
          }

          const element = node as HTMLElement;
          return {
            articleId: element.getAttribute('articleId') || null,
            articleTitle: element.getAttribute('articleTitle') || 'No title',
            articleDescription:
              element.getAttribute('articleDescription') || 'No description',
            articleUrl: element.getAttribute('articleUrl') || null,
          };
        },
      },
    ];
  },

  renderText() {
    return '';
  },
});
