import axios from '../Axios';

const setZapierAuth = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/zapier`, {
    code,
  });
};

const setZapierActions = ({
  projectID,
  integrationID,
  actions,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/zapier/${integrationID}`,
    {
      actions,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectZapierIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/zapier/${integrationID}`,
  );
};

export { setZapierAuth, setZapierActions, disconnectZapierIntegration };
