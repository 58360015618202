import './ProjectApiToken.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';
import ApiKey from 'components/ApiKey/ApiKey';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import { useEffect, useState } from 'react';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import { ampli } from 'ampli';
import { Title } from 'components/Title/Title';
import { ApiPlatforms } from 'models/Platform';
import InstructionCard from 'components/InstructionCard/InstructionCard';

interface ProjectApiTokenProps {
  projectStore?: ProjectStore;
}

const ProjectApiToken = ({
  projectStore,
}: ProjectApiTokenProps) => {
  const currentProject = projectStore?.currentProject;
  const [showSecuritySecret, setShowSecuritySecret] = useState(false);

  if (!currentProject) {
    return <></>;
  }

  const renderPlatforms = () => {
    return ApiPlatforms.map((item, key) => {
      return (
        <InstructionCard
          data-name={item.title}
          item={item}
          key={key}
          buttonTitle="Show documentation"
          onClick={() => {
            window.location.href = item.url;
          }}
        />
      );
    });
  };

  return (
    <PageContainer className="project-installation">
      <PageHeadLine title="Secret API token" />
      <PageContent hasTitle isMediumBoxed>
        <div className="project-installation-inner-container">
          <div className="options-group mt-50">
            <div className="options-group-header">Secret API token</div>
            <div className='text mb-20'>
              Please read the following documentation to learn more on how to use our API.<br /><a
                target="_blank"
                href="https://docs.gleap.io/guides/enforce-identity-verification" rel="noreferrer"
              >
                <i className="ml-2 fa-regular fa-book-open" /> User hash calculation
              </a>
            </div>
            {!showSecuritySecret && <LinkButton icon="eye-slash" iconSideRight={false} label='Show secret API token' onClick={() => {
              setShowSecuritySecret(true);
            }} />}
            {showSecuritySecret && <>
              <ApiKey apiKey={projectStore?.currentProject?.secretApiKey ?? '--'} />
              <InfoBox className="mt-20">
                <>
                  Please note that this is <b>NOT</b> your frontend API token. Do not share this token.
                </>
              </InfoBox>
            </>}
          </div>
          <Title className="mt-20" label="Server-side event tracking" small />
          <div className="sdk-setup-left mt-5 mb-10">
            <div className="text">
              Get started with server-side event tracking by using our SDK or REST api.
            </div>
          </div>
          <div className="platforms mb-30 mt-15">{renderPlatforms()}</div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
)(observer(ProjectApiToken));
