import './WidgetAppCard.scss';
import { Card } from 'components/Card/Card';
import LinkButton from 'components/LinkButton/LinkButton';
import { inject, observer } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';

interface WidgetAppCardProps {
  modalStore?: ModalStore;
  item: any;
}

const WidgetAppCard = ({ item, modalStore }: WidgetAppCardProps) => {
  return (
    <Card className="widget-app-card">
      <div className="link-header">
        <item.image className="image" />
      </div>
      <div className="link-body">
        <span className="title">{item.title}</span>
        <div className="description">{item.description}</div>
      </div>
      <div className="link-footer">
        <LinkButton
          icon="gear"
          iconSideRight={false}
          label="Settings"
          onClick={() => {
            modalStore?.openModal(MODALTYPE.WIDGET_APP_SETTINGS, item);
          }}
        />
      </div>
    </Card>
  );
};

export default inject('modalStore')(observer(WidgetAppCard));
