import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { TrelloIntegrationStore } from 'stores/integrations/TrelloIntegrationsStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface TrelloConfigurationProps {
  integrationID: string;
  index: number;
  trelloIntegrationStore?: TrelloIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const TrelloConfiguration = ({
  integrationID,
  index,
  trelloIntegrationStore,
  projectStore,
  onChange,
}: TrelloConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [boardID, setBoardID] = useState('');
  const [listID, setListID] = useState('');

  const boards = trelloIntegrationStore?.boards;
  const boardLists = trelloIntegrationStore?.boardLists;

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [boardID, listID]);

  const initialize = async () => {
    trelloIntegrationStore!.setIntegrationID(integrationID);
    await trelloIntegrationStore!.getBoards();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.TRELLO,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.trello[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseBoard = async (boardId) => {
    setBoardID(boardId);
    await trelloIntegrationStore?.getBoardLists(boardId);
  };

  const chooseList = async (listId) => {
    setListID(listId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      boardID,
      listID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Project"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={boards.find(
          (element) => element.id === currentIntegrationInfo?.boardID,
        )}
        isLoading={boards.length === 0}
        items={boards}
        onChange={(option) => {
          chooseBoard(option.id);
        }}
      />
      {boardID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="List"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={boardLists.find(
            (element) => element.id === currentIntegrationInfo?.listID,
          )}
          isLoading={boardLists.length === 0}
          items={boardLists}
          onChange={(option) => {
            chooseList(option.id);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'trelloIntegrationStore',
  'projectStore',
)(observer(TrelloConfiguration));
