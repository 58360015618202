import { hashToColor } from "helper/Color";
import { useEffect, useRef } from "react";

function capitalizeFirstLetter(string) {
  if (string && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return '';
}

function useClickedOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const useHotkey = (key: number, onKeyPress, useShift = true) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.keyCode === key) {
        if (event.shiftKey || !useShift) {
          onKeyPress();
          event.preventDefault();
        }
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
}

const useEscape = (onEscape) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27)
        onEscape();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
}

const useBackRouter = (navigate, boardShareToken?, collectionId?, articleId?) => {
  useEffect(() => {
    const initialData = {
      boardShareToken: boardShareToken,
      collectionId: collectionId,
      articleId: articleId
    };

    const handleBackRoute = (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data && data.name === 'goback') {
          if (initialData.boardShareToken && initialData.collectionId && initialData.articleId) {
            return navigate(`/sharedboard/${boardShareToken}/helpcenter/${collectionId}`);
          }
          if (initialData.boardShareToken && initialData.collectionId) {
            return navigate(`/sharedboard/${boardShareToken}/helpcenter`);
          }
          if (initialData.boardShareToken) {
            return navigate(`/sharedboard/${boardShareToken}/helpcenter`);
          }
        }
      } catch (exp) { }
    };
    window.addEventListener('message', handleBackRoute);

    return () => {
      window.removeEventListener('message', handleBackRoute);
    };
  }, []);
}

function isMacintosh() {
  return navigator.platform.indexOf('Mac') > -1
}

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseInt(obj[el]);
    }
  }
  return sum;
}

function sendPostMessage(data) {
  var parentWindow = (window as any).top;
  if ((window as any).parent != (window as any).top) {
    parentWindow = (window as any).parent;
  }

  if (parentWindow) {
    parentWindow.postMessage(data, '*');
  }
};

function ticketStatusToColor(status) {
  var color = '';
  switch (status) {
    case 'OPEN':
      color = '#85B5B3';
      break;

    case 'PLANNED':
      color = '#1FA0FF';
      break;

    case 'INPROGRESS':
      color = '#9B59B6';
      break;

    case 'DONE':
      color = '#30CB83';
      break;

    default:
      color = hashToColor(status);
      break;
  }

  return color;
}

export { capitalizeFirstLetter, useClickedOutside, useEscape, useHotkey, isMacintosh, sum, sendPostMessage, useBackRouter, ticketStatusToColor };
