import React, { ReactChild, ReactChildren } from 'react';
import './BoardWrapperComponent.scss';
import classNames from 'classnames';

interface BoardWrapperComponentProps {
  label: string;
  children: ReactChild | ReactChildren;
  className?: any;
}

const BoardWrapperComponent = ({
  label,
  children,
  className,
}: BoardWrapperComponentProps) => {
  const boardWrapperClassName = classNames(
    {
      'board-wrapper-component': true,
    },
    className,
  );

  return (
    <div className={boardWrapperClassName}>
      <div className="board-wrapper-component-title">{label}</div>
      {children}
    </div>
  );
};

export default BoardWrapperComponent;
