import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';
import './SurveyLimitSendingPicker.scss';

export const SurveyLimitSendingPicker = ({ className, value, onChange, }: {
    className?: string;
    value?: number;
    onChange: (value?: number) => void;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef(null);
    useClickedOutside(wrapperRef, () => {
        setIsOpen(false);
    });
    useEscape(() => {
        setIsOpen(false);
    });

    const getConditionDescription = () => {
        return '';
    }

    const renderConditionName = () => {
        return (
            <div className="condition-name">
                <i className={`fa-solid fa-hourglass-clock`} />
                {value ? `Limit sending to once every ${value} day${value === 1 ? '' : 's'}` : 'Don\'t limit sending'}
            </div>
        );
    }

    const renderRelativeTriggerDateContent = () => {
        const options = [{
            label: "Don't limit sending",
            value: 'nolimit',
        }, {
            label: "Limit sending to once every",
            value: 'limit',
        }];

        return (
            <div className='count-editor-container'>
                <div className='count-editor-container-options'>
                    {options.map((option, index) => {
                        var checked = false;
                        if (option.value === "nolimit" && !value) {
                            checked = true;
                        }
                        if (option.value === "limit" && value) {
                            checked = true;
                        }
                        return (
                            <>
                                <label className="bb-feedback-multiplechoice-container" key={`${option.value}-${index}`}>
                                    <div className="text">
                                        {option.label}
                                    </div>
                                    <input
                                        type="radio"
                                        name={`condition-${option.value}`}
                                        checked={checked}
                                        onChange={() => {
                                            if (option.value === "limit") {
                                                onChange(14);
                                            } else {
                                                onChange(undefined);
                                            }
                                        }}
                                    />
                                    <span className="bb-feedback-multiplechoice-checkmark" />
                                </label>
                                {option.value !== "nolimit" && value ? (
                                    <>
                                        <div className="field-container">
                                            <div className="input-wrapper">
                                                <input
                                                    className="textinput-gray"
                                                    value={value}
                                                    type="number"
                                                    onChange={(inputVal) => {
                                                        const number = parseInt(inputVal.target.value);
                                                        if (!isNaN(number) && number >= 0) {
                                                            onChange(number);
                                                        } else {
                                                            onChange(undefined);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <span>day{value === 1 ? '' : 's'}</span>
                                        </div>
                                    </>
                                ) : null}
                            </>
                        )
                    })}
                </div>
            </div>
        );
    }

    const renderEditorModalContent = () => {
        return (
            <div className="condition-editor">
                {renderRelativeTriggerDateContent()}
            </div>
        )
    }

    const cardClassName = classNames(
        {
            'limit-sending-tag': true,
        },
        className,
    );

    return (
        <div
            className={cardClassName}
            ref={wrapperRef}>
            <div
                className='limit-sending-tag--inner'
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                {renderConditionName()}
                <div className='condition-description'>{getConditionDescription()}</div>
            </div>
            <div className={`limit-sending-tag-overlay ${isOpen && 'limit-sending-tag-overlay--open'}`}>
                {renderEditorModalContent()}
            </div>
        </div>
    );
};
