import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
  getFolders,
  getLists,
  getSpaces,
  getTeams,
  getFolderlessLists,
} from 'services/integrations/ClickupHttpService';

export class ClickupIntegrationStore {
  stores: any = {};

  integrationID: string = '';

  teams: any = [];
  spaces: any = [];
  folders: any = [];
  lists: any = [];
  folderlessLists: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setTeams = (teams) => {
    this.teams = teams;
  };

  getTeams = async () => {
    try {
      const response = await getTeams({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setTeams(response.data.teams);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setSpaces = (spaces) => {
    this.spaces = spaces;
  };

  getSpaces = async (teamID: string) => {
    if (!teamID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getSpaces({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        teamID,
      });
      if (response.status === 200) {
        this.setSpaces(response.data.spaces);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setFolders = (folders) => {
    this.folders = folders;
  };

  getFolders = async (spaceID: string) => {
    if (!spaceID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getFolders({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        spaceID,
      });
      if (response.status === 200) {
        this.setFolders(response.data.folders);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setFolderlessLists = (folderlessLists) => {
    this.folderlessLists = folderlessLists;
  };

  getFolderlessLists = async (spaceID: string) => {
    if (!spaceID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getFolderlessLists({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        spaceID,
      });
      if (response.status === 200) {
        this.setFolderlessLists(response.data.lists);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setLists = (lists) => {
    this.lists = lists;
  };

  getLists = async (folderID: string) => {
    if (!folderID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getLists({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        folderID,
      });
      if (response.status === 200) {
        this.setLists(response.data.lists);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
