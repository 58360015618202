import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { Outbound } from 'models/Outbound';
import { FeedbackAction } from 'models/Project';
import Switch from 'react-switch';
import { useState } from 'react';
import Survey from 'assets/icons/survey.svg';
import SurveyFull from 'assets/icons/survey_full.svg';
import './OptionsConfiguration.scss';

const surveyFormats = [
  {
    label: 'Small format',
    value: 'survey',
    img: Survey,
  },
  {
    label: 'Large format',
    value: 'survey_full',
    img: SurveyFull,
  },
];

interface OptionsConfigurationProps {
  outboundRule: Outbound;
  feedbackAction: FeedbackAction;
}

const OptionsConfiguration = ({
  outboundRule,
  feedbackAction,
}: OptionsConfigurationProps) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="options-group">
        <div className="options-group-header">Look & feel</div>
        <div className="content-page">
          <div className="input-label mb-10 mt-20">Format</div>
          <div className="positiontypeselection">
            {surveyFormats.map((surveyFormat) => {
              return (
                <div
                  key={surveyFormat.value}
                  className={
                    outboundRule.format === surveyFormat.value ? 'active' : ''
                  }
                  onClick={() => {
                    runInAction(() => {
                      outboundRule.format = surveyFormat.value;
                      setToggle(!toggle);
                    });
                  }}
                >
                  <img src={surveyFormat.img} alt={surveyFormat.label} />
                  <div>{surveyFormat.label}</div>
                </div>
              );
            })}
          </div>
        </div>
        {outboundRule.format === 'survey_full' && (
          <>
            <TextInput
              name="greeting"
              placeholder="Optional"
              language="English"
              type="text"
              className="mb-20 linespacerwithborder"
              error=""
              initalValue={feedbackAction.introTitle ?? ''}
              label="Greeting"
              onChange={(text) => {
                feedbackAction.introTitle = text;
                setToggle(!toggle);
              }}
            />
            <div className="input-label">Intro message</div>
            <textarea
              className="default-textarea"
              placeholder="Optional"
              value={feedbackAction.introMessage}
              onChange={(e) => {
                runInAction(() => {
                  feedbackAction.introMessage = e.target.value;
                  setToggle(!toggle);
                });
              }}
            />
          </>
        )}
        <div className="input-label mt-30">Progress bar</div>
        <div className="switch-container mb-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                feedbackAction.showProgressBar = checked;
                setToggle(!toggle);
              });
            }}
            checked={
              feedbackAction.showProgressBar === true
            }
          />
          <span>Show a progress bar on multiple questions</span>
        </div>
      </div>
      <div className="options-group">
        <div className="options-group-header">Options</div>
        <div className="input-label">Collect email</div>
        <div className="switch-container mb-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                feedbackAction.collectEmail = checked;
                setToggle(!toggle);
              });
            }}
            checked={
              feedbackAction.collectEmail === undefined ||
              feedbackAction.collectEmail === true
            }
          />
          <span>Collect email addresses of users</span>
        </div>
        <div className="mt-20">
          <div className="input-label linespacerwithborder">
            Single page form
          </div>
          <div className="switch-container mb-20 mt-10">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  feedbackAction.singlePageForm = checked;
                  setToggle(!toggle);
                });
              }}
              checked={feedbackAction.singlePageForm ?? false}
            />
            <span>Show all questions on one page</span>
          </div>
        </div>
        <div className="mt-20">
          <div className="input-label linespacerwithborder">Privacy policy</div>
          <div className="switch-container mb-20 mt-10">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  feedbackAction.privacyPolicyEnabled = checked;
                  setToggle(!toggle);
                });
              }}
              checked={feedbackAction.privacyPolicyEnabled ?? false}
            />
            <span>Enable privacy policy check</span>
          </div>
          {feedbackAction.privacyPolicyEnabled && (
            <div className="fullwidth">
              <TextInput
                name="Privacy policy URL"
                placeholder="https://..."
                type="text"
                className="mb-20"
                error=""
                initalValue={feedbackAction.privacyPolicyUrl}
                label="Privacy policy URL"
                onChange={(text) => {
                  runInAction(() => {
                    feedbackAction.privacyPolicyUrl = text;
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OptionsConfiguration;
