import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import Editor from 'react-simple-code-editor';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { isEqual } from 'lodash';
import './HelpcenterSeo.scss';
import { useParams } from 'react-router-dom';
import LinkButton from 'components/LinkButton/LinkButton';
import { highlight, languages } from 'prismjs/components/prism-core';

interface HelpcenterSeoProps {
  projectStore?: ProjectStore;
}

const HelpcenterSeo = ({ projectStore }: HelpcenterSeoProps) => {
  const { flowConfig } = projectStore?.editingProject || {};
  const { projectId } = useParams();
  const helpcenterConfig = flowConfig?.helpcenterConfig;
  const [footerLinks, setFooterLinks] = useState([] as any[]);
  const [initializedFooterLinks, setInitializedFooterLinks] = useState(false);

  useEffect(() => {
    if (footerLinks && projectStore!.editingProject && projectStore!.editingProject!.flowConfig) {
      if (
        !projectStore?.editingProject?.flowConfig?.helpcenterConfig
          ?.config
      ) {
        projectStore!.editingProject!.flowConfig.helpcenterConfig[
          'config'
        ] = {
          footerLinks: footerLinks,
        };
      } else {
        projectStore!.editingProject!.flowConfig.helpcenterConfig.config.footerLinks =
          footerLinks;
      }
    }
  }, [footerLinks]);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    projectStore?.setEditingProject();

    if (projectStore?.currentProject && !initializedFooterLinks) {
      setInitializedFooterLinks(true);
      setFooterLinks(projectStore?.currentProject?.flowConfig?.helpcenterConfig?.config?.footerLinks?.map((item) => {
        return { ...item, key: Math.random().toString(36).substring(7) };
      }) ?? []);
    }
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const _buildHelpcenterSitemapForm = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Sitemap</div>
        <div className='text'>Gleap generates SEO optimized static pages for your help center. You can access the sitemap of your help center below:</div>
        <LinkButton className="mt-10" label='Show sitemap' onClick={() => {
          window.open(`https://${projectStore?.currentProject?.customDomainHelpCenter ? projectStore?.currentProject?.customDomainHelpCenter : `${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`}/sitemap.xml`, '_blank')
        }} />
      </div>
    );
  };

  const _buildHelpcenterCustomCodeForm = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Custom code</div>
        <div className='text mb-10'>Add custom code like Google Analytics or other tools to your help center by pasting the code in the field below.</div>
        <div className="fullwidth">
          <Editor
            className="code-editor"
            value={(helpcenterConfig?.config?.customFooter && helpcenterConfig?.config?.customFooter.length > 0) ? helpcenterConfig?.config?.customFooter : '\n\n\n'}
            onValueChange={(code) => {
              runInAction(() => {
                try {
                  if (
                    !projectStore?.editingProject?.flowConfig?.helpcenterConfig
                      ?.config
                  ) {
                    projectStore!.editingProject!.flowConfig.helpcenterConfig[
                      'config'
                    ] = {
                      customFooter: code,
                    };
                  } else {
                    projectStore!.editingProject!.flowConfig.helpcenterConfig.config.customFooter =
                      code;
                  }
                } catch (exp) { }
              });
            }}
            highlight={(code) => highlight(code, languages.text)}
            padding={10}
          />
        </div>
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="SEO">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <SizedContainer size={ContainerSizes.XXL}>
          {_buildHelpcenterSitemapForm()}
          {_buildHelpcenterCustomCodeForm()}
        </SizedContainer>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(HelpcenterSeo));
