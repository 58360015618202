import { ReactComponent as FeedbackFlowIcon } from 'assets/icons/feedbackflow.svg';
import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as CustomActionIcon } from 'assets/icons/customaction.svg';
import BotDropdown from 'components/BotDropdown/BotDropdown';
import FeedbackFlowDropdown from 'components/FeedbackFlowDropdown/FeedbackFlowDropdown';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { isEqual } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FormWidgetItem.scss';
import './FormWidgetSettings.scss';

export const formWidgetActionType = [
  {
    label: 'Start conversation (optionally with bot)',
    value: 'BOT',
    icon: BotIcon,
  },
  {
    label: 'Start feedback flow',
    value: 'FEEDBACK_FLOW',
    icon: FeedbackFlowIcon,
  },
  {
    label: 'Start custom action',
    value: 'CUSTOM_ACTION',
    icon: CustomActionIcon,
  },
  {
    label: 'Open link',
    value: 'REDIRECT_URL',
    icon: LinkIcon,
  },
];

interface FormWidgetItemProps {
  projectStore?: ProjectStore;
  menuItem: any;
  flowConfig: any;
  possibleActions: any;
  bots: any;
  projectId: any;
  onSave(menuItem): any;
}

const FormWidgetItem = ({
  projectStore,
  menuItem,
  flowConfig,
  possibleActions,
  bots,
  projectId,
  onSave,
}: FormWidgetItemProps) => {
  const navigate = useNavigate();

  const [initalMenuItem, setInitalMenuItem] = useState(null as any);

  useEffect(() => {
    setInitalMenuItem(JSON.parse(JSON.stringify(menuItem)));
  }, []);

  if (!initalMenuItem) {
    return <></>;
  }

  const selectedType = initalMenuItem.actionType || 'BOT';
  const isFeedbackFlow =
    selectedType !== 'BOT' &&
    selectedType !== 'CUSTOM_ACTION' &&
    selectedType !== 'REDIRECT_URL';

  return (
    <>
      <div>
        <TextInput
          language="English"
          name="Title"
          placeholder=""
          type="text"
          className="mb-20"
          error=""
          initalValue={initalMenuItem.title}
          label="Title"
          onChange={(text) => {
            initalMenuItem.title = text;
            setInitalMenuItem({ ...initalMenuItem });
          }}
        />
      </div>
      <div className="mt-30">
        <TextInput
          language="English"
          name="Description"
          placeholder=""
          type="text"
          className="mb-20"
          error=""
          initalValue={initalMenuItem.description}
          label="Description"
          onChange={(text) => {
            initalMenuItem.description = text;
            setInitalMenuItem({ ...initalMenuItem });
          }}
        />
      </div>
      <div className="input-label mb-5 mt-30">Action</div>
      <div className="form-action-type mb-10">
        <IconDropdown
          value={formWidgetActionType.find((element) => {
            if (isFeedbackFlow && element.value === 'FEEDBACK_FLOW') {
              return true;
            }

            return element.value === selectedType;
          })}
          options={formWidgetActionType}
          onValueChanged={(selectedItem) => {
            runInAction(() => {
              if (selectedItem.value === 'FEEDBACK_FLOW') {
                initalMenuItem.actionType = possibleActions[3].value;
                setInitalMenuItem({ ...initalMenuItem });
              } else {
                initalMenuItem.actionType = selectedItem.value;
                setInitalMenuItem({ ...initalMenuItem });
              }
            });
          }}
        />
      </div>
      {isFeedbackFlow && (
        <>
          <FeedbackFlowDropdown
            projectId={projectId}
            value={
              possibleActions.find((action) => {
                return action.value === initalMenuItem.actionType;
              }) ?? possibleActions[3]
            }
            options={possibleActions}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                initalMenuItem.actionType = selectedItem.value;
                setInitalMenuItem({ ...initalMenuItem });
              });
            }}
          />
          <div className="hint mt-5">
            {`A feedback flow is the action that runs when a user clicks on a menu item. Manage your project's feedback flows `}
            <a
              href="#"
              onClick={() => {
                navigate(`/projects/${projectId}/widget/flows`);
              }}
            >
              here
            </a>
            .
          </div>
        </>
      )}
      {initalMenuItem.actionType === 'BOT' && (
        <>
          <BotDropdown
            projectId={projectId}
            value={bots.find((action) => {
              return action.id === initalMenuItem.botId;
            })}
            options={bots.filter((bot) => bot.status === 'live')}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                initalMenuItem.botId = selectedItem?.id;
                setInitalMenuItem({ ...initalMenuItem });
              });
            }}
          />
        </>
      )}
      {initalMenuItem.actionType === 'REDIRECT_URL' && (
        <>
          <div className="mt-30">
            <TextInput
              name="URL"
              placeholder="https://..."
              type="text"
              className="mb-20"
              error=""
              initalValue={initalMenuItem.actionBody}
              label="URL to open"
              onChange={(text) => {
                initalMenuItem.actionBody = text;
                setInitalMenuItem({ ...initalMenuItem });
              }}
            />
          </div>
          <div className="switch-container mb-20 mt-0">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  initalMenuItem.actionOpenInNewTab = checked;
                  setInitalMenuItem({ ...initalMenuItem });
                });
              }}
              checked={initalMenuItem?.actionOpenInNewTab ?? false}
            />
            <span>Open in new tab</span>
          </div>
        </>
      )}
      {(initalMenuItem.actionType === 'CUSTOM' ||
        initalMenuItem.actionType === 'CUSTOM_ACTION') && (
          <>
            <div className="mt-30">
              <TextInput
                name="Custom action name"
                placeholder="Name the custom action"
                type="text"
                error=""
                value={initalMenuItem.actionBody}
                label="Custom action name"
                onChange={(text) => {
                  initalMenuItem.actionBody = text;
                  setInitalMenuItem({ ...initalMenuItem });
                }}
              />
            </div>
            <div className="hint mb-20">
              Learn more about custom actions{' '}
              <a
                target="_blank"
                href="https://docs.gleap.io/javascript/custom-actions"
                rel="noreferrer"
              >
                here
              </a>
              .
            </div>
          </>
        )}
      <div className="widget-logo-upload mt-30">
        <div className="input-label mb-10">Icon</div>
        <ImageUpload
          image={initalMenuItem.icon}
          label="Upload an icon"
          editable
          uploadPath="feedback_widget_icons"
          afterImageUpload={(imageURL) => {
            initalMenuItem.icon = imageURL;
            setInitalMenuItem({ ...initalMenuItem });
          }}
        />
      </div>
      <div className="form-widget-buttons mt-30">
        <LinkButton
          icon="trash"
          className="danger"
          iconSideRight={false}
          label="Delete"
          onClick={() => {
            if (flowConfig.menuItems.length <= 1) {
              toast.error('You need to have at least one menu item');
              return;
            }

            flowConfig.menuItems = flowConfig.menuItems.filter((item) => {
              return item !== menuItem;
            });
          }}
        />
        <PrimaryButton
          label="Save"
          disabled={isEqual(initalMenuItem, menuItem)}
          onClick={() => {
            if (initalMenuItem.actionType === 'REDIRECT_URL') {
              runInAction(() => {
                initalMenuItem.actionBody =
                  initalMenuItem.actionBody.indexOf('://') === -1 &&
                    initalMenuItem.actionBody.indexOf('mailto:') === -1
                    ? 'http://' + initalMenuItem.actionBody
                    : initalMenuItem.actionBody;
                setInitalMenuItem({ ...initalMenuItem });
              });
            }

            onSave(initalMenuItem);
          }}
        />
      </div>
    </>
  );
};

export default inject('projectStore')(observer(FormWidgetItem));
