import classNames from 'classnames';
import { runInAction } from 'mobx';
import { PredicateChild } from 'models/Bot';
import { useEffect, useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';
import './BotConditionTag.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';

const onlyLettersAndNumbersRegex = /^[a-zA-Z0-9]+$/; // regular expression to only allow letters and numbers

const userAttributeOptions = [
  {
    label: 'Name',
    value: 'name',
    icon: 'user',
    type: 'text',
  },
  {
    label: 'Email',
    value: 'email',
    icon: 'envelope',
    type: 'text',
  },
  {
    label: 'Value',
    value: 'value',
    icon: 'envelope',
    type: 'text',
  },
  {
    label: 'First activity',
    value: 'firstActivity',
    icon: 'calendar',
    type: 'date',
  },
  {
    label: 'Last activity',
    value: 'lastActivity',
    icon: 'calendar',
    type: 'date',
  },
  {
    label: 'Feedback report count',
    value: 'countFeedbackReports',
    icon: 'bullseye-arrow',
    type: 'text',
  },
];

const userEventAttributeOptions = [
  {
    label: 'Session started',
    value: 'sessionStarted',
    icon: 'calendar',
    type: 'event',
  },
];

const ticketAttributeOptions = [];

const officeHoursOptions = [
  {
    label: 'During office hours',
    value: 'during-office-hours',
  },
  {
    label: 'Outside office hours',
    value: 'outside-office-hours',
  },
];

export const operatorOptions = [
  {
    label: 'is',
    value: 'equals',
    inputType: 'text',
  },
  {
    label: 'is not',
    value: 'not_equals',
    inputType: 'text',
  },
  {
    label: 'contains',
    value: 'contains',
    inputType: 'text',
  },
  {
    label: 'does not contain',
    value: 'not_contains',
    inputType: 'text',
  },
  {
    label: 'starts with',
    value: 'starts_with',
    inputType: 'text',
  },
  {
    label: 'ends with',
    value: 'ends_with',
    inputType: 'text',
  },
  {
    label: 'more than',
    value: 'greater_than',
    inputType: 'text',
  },
  {
    label: 'less than',
    value: 'less_than',
    inputType: 'text',
  },
  {
    label: 'is empty',
    value: 'is_empty',
    hideInput: true,
  },
  {
    label: 'is not empty',
    value: 'not_empty',
    hideInput: true,
  },
  {
    label: 'on date',
    value: 'on_date',
    inputType: 'date',
  },
  {
    label: 'before date',
    value: 'before_date',
    inputType: 'date',
  },
  {
    label: 'after date',
    value: 'after_date',
    inputType: 'date',
  },
  {
    label: 'after the event occurs',
    value: 'date_after_event',
    unitLabel: 'days ago',
  },
];

interface BotConditionTagProps {
  predicate: PredicateChild;
  onAddCondition: () => void;
  onRemove: () => void;
  showAddCondition?: boolean;
  projectStore?: ProjectStore;
}

const BotConditionTag = ({
  predicate,
  onAddCondition,
  onRemove,
  showAddCondition = true,
  projectStore,
}: BotConditionTagProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [attributeInputHasError, setAttributeInputHasError] = useState(false);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  useEscape(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (!predicate.attribute) {
      setIsOpen(true);
    }
  }, [predicate]);

  const isValid = () => {
    if (!predicate.dataType || !predicate.operator || !predicate.value) {
      return false;
    }

    return true;
  };

  const findAttributesUsedInBot = () => {
    var attributes: {
      session: string[];
      ticket: string[];
    } = {
      session: [],
      ticket: [],
    };
    const actionFlows = projectStore?.bot?.actionFlows;

    for (let i = 0; i < actionFlows.length; i++) {
      const actionFlow = actionFlows[i];
      const actions = actionFlow.actions;

      for (let j = 0; j < actions.length; j++) {
        const action = actions[j];
        if (action.type === 'input' && action.attribute) {
          if (action.attributeType === 'session') {
            attributes.session.push(action.attribute);
          } else {
            attributes.ticket.push(action.attribute);
          }
        }
      }
    }

    return attributes;
  };

  const getAttributeOptionsForDataType = () => {
    const attributesUsedInBot = findAttributesUsedInBot();

    switch (predicate.dataType) {
      case 'user':
        return [
          ...userAttributeOptions,
          ...attributesUsedInBot.session.map((attribute) => {
            return {
              label: attribute,
              value: attribute,
              icon: 'user',
              type: 'text',
            };
          }),
        ];
      case 'user-event':
        return userEventAttributeOptions;
      case 'ticket':
        return [
          ...ticketAttributeOptions,
          ...attributesUsedInBot.ticket.map((attribute) => {
            return {
              label: attribute,
              value: attribute,
              icon: 'ticket',
              type: 'text',
            };
          }),
        ];
      default:
        return [];
    }
  };

  const renderConditionName = () => {
    let label: any[] = [];

    // Data type
    if (predicate.dataType != null) {
      label.push(predicate.dataType);
    }

    // Attribute
    if (predicate.attribute != null) {
      label.push(` ${predicate.attribute}`);
    }

    // Operator
    if (predicate.operator != null) {
      const operatorLabel = operatorOptions.find(
        (option) => option.value === predicate.operator,
      )?.label;

      if (operatorLabel != null) {
        label.push(
          <span key="operatorLabel" className="operator-label">
            {' '}
            {operatorLabel}{' '}
          </span>,
        );
      }
    }

    // Value
    if (predicate.value != null) {
      label.push(` ${predicate.value}`);
    }

    label = label.map((item, index) => {
      if (item === 'office-hours') {
        return '';
      }
      if (item === ' during-office-hours') {
        return 'during office hours';
      }
      if (item === ' outside-office-hours') {
        return 'outside office hours';
      }
      return item;
    });

    // Wrap the array of elements in a parent span element
    return <span>{label}</span>;
  };

  const renderEditorModalContent = () => {
    // Special data type office hours
    if (predicate.dataType === 'office-hours') {
      return (
        <div className="operator-select-options">
          {officeHoursOptions.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={predicate.value === option.value}
                    onChange={() => {
                      runInAction(() => {
                        predicate.operator = 'is';
                        predicate.value = option.value as any;

                        setToggleState(!toggleState);
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
              </>
            );
          })}
        </div>
      );
    }

    // Attribute
    if (predicate.attribute == null) {
      const customAttributeInputClassName = classNames({
        'textinput-gray': true,
        'custom-attribute-input': true,
        'custom-attribute-input--invalid': attributeInputHasError,
      });

      return (
        <div className="default-select-options">
          {getAttributeOptionsForDataType().map((option) => {
            return (
              <div
                className="default-select-option"
                key={`operator-${option.value}`}
                onClick={() => {
                  runInAction(() => {
                    predicate.attribute = option.value;

                    setToggleState(!toggleState);
                  });
                }}
              >
                {option.label}
              </div>
            );
          })}
          <div className="field-container">
            <div className="input-wrapper">
              <input
                className={customAttributeInputClassName}
                value={predicate.attribute}
                placeholder="Enter custom attribute"
                onChange={(inputVal) => {
                  const { value } = inputVal.target;
                  if (!onlyLettersAndNumbersRegex.test(value)) {
                    setAttributeInputHasError(true);
                    return;
                  } else {
                    setAttributeInputHasError(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (attributeInputHasError) {
                      return;
                    }

                    const { value } = e.currentTarget;
                    predicate.attribute = `customData.${value}`;

                    setToggleState(!toggleState);
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    // Operator & value
    return (
      <div className="operator-select-options">
        {operatorOptions
          .filter((operator) => {
            let attribute = [
              ...userAttributeOptions,
              ...userEventAttributeOptions,
              ...ticketAttributeOptions,
            ].find((attr) => attr.value === predicate.attribute);

            if (!operator.inputType || !attribute?.type) {
              return true;
            }

            if (operator.inputType === attribute.type) {
              return true;
            }

            return false;
          })
          .map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={predicate.operator === option.value}
                    onChange={() => {
                      runInAction(() => {
                        predicate.operator = option.value as any;

                        setToggleState(!toggleState);
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {predicate.operator === option.value && !option.hideInput && (
                  <div className="field-container value-input-container">
                    <div className="input-wrapper value-input-wrapper">
                      <input
                        className="textinput-gray value-input"
                        type={option.inputType}
                        value={predicate.value}
                        onChange={(inputVal) => {
                          runInAction(() => {
                            predicate.value = inputVal.target.value;

                            setToggleState(!toggleState);
                          });
                        }}
                      />
                      {option.unitLabel && (
                        <div className="unit-label ml-5">
                          {option.unitLabel}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            );
          })}
      </div>
    );
  };

  const buildAddCondition = () => {
    return (
      <div className="bot-condition-tag--inner" onClick={onAddCondition}>
        +
      </div>
    );
  };

  const cardClassName = classNames({
    'bot-condition-tag': true,
    'bot-condition-tag--invalid': !isOpen && !isValid(),
  });

  const overlayClassname = classNames({
    'bot-condition-tag-overlay': true,
    'bot-condition-tag-overlay--open': isOpen,
  });

  return (
    <>
      <div className={cardClassName} ref={wrapperRef}>
        <div
          className="bot-condition-tag--inner"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {renderConditionName()}
          <div className="condition-remove">
            <i
              className="fa-sharp fa-solid fa-xmark"
              onClick={() => {
                onRemove();
              }}
            ></i>
          </div>
        </div>
        <div className={overlayClassname}>{renderEditorModalContent()}</div>
      </div>
      {showAddCondition && buildAddCondition()}
    </>
  );
};

export default inject('projectStore')(observer(BotConditionTag));
