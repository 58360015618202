import React from 'react';
import classNames from 'classnames';
import './PageContent.scss';

const PageContent = ({
  className,
  children,
  hasTitle,
  hasPadding = true,
  isBoxed = false,
  isSmallBoxed = false,
  isMediumBoxed = false,
  isLargeBoxed = false,
  isCentered = false,
  onScroll,
}: {
  className?: any;
  children: any;
  hasTitle?: boolean;
  hasPadding?: boolean;
  isBoxed?: boolean;
  isSmallBoxed?: boolean;
  isMediumBoxed?: boolean;
  isLargeBoxed?: boolean;
  isCentered?: boolean;
  onScroll?: (e: any) => void;
}) => {
  const PageContentClassNmae = classNames(
    {
      'page-content': true,
      'page-content--title': hasTitle,
      'page-content--padding': hasPadding,
      'page-content--boxed': isBoxed,
      'page-content--smallboxed': isSmallBoxed,
      'page-content--mediumboxed': isMediumBoxed,
      'page-content--largeboxed': isLargeBoxed,
      'page-content--centered': isCentered,
    },
    className,
  );

  return (
    <div className={PageContentClassNmae} onScroll={onScroll}>
      <div className="page-content--inner">{children}</div>
    </div>
  );
};

export default PageContent;
