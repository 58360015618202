import axios from '../Axios';

const getAuthCodeFromNotion = (projectID) => {
  return window.open(
    `${process.env.REACT_APP_NOTION_CONNECT_URL}?client_id=${process.env.REACT_APP_NOTION_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASEURL}/integrations/notion&state=${projectID}&response_type=code&owner=user`,
    '_self',
  );
};

const setNotionAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/notion`, {
    code,
  });
};

const getAccessibleDatabases = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/notion/${integrationID}/databases`,
  );
};

const setNotionActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/notion/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectNotionIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/notion/${integrationID}`,
  );
};

export {
  getAuthCodeFromNotion,
  setNotionAuthToken,
  getAccessibleDatabases,
  setNotionActions,
  disconnectNotionIntegration,
};
