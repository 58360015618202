import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './PublicSkeleton.scss';

interface PublicSkeletonProps {
  height?: number | string;
  width?: number | string;
  count?: number;
  style?: React.CSSProperties;
}

const PublicSkeleton = ({
  height,
  width,
  count,
  style,
}: PublicSkeletonProps) => {
  return <Skeleton height={height} width={width} style={style} count={count} />;
};

export default PublicSkeleton;
