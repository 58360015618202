/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './MdxCodeSnippet.scss';

import * as CodeBlock from 'react-code-blocks';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import copyIcon from '../../../../../assets/icons/copy.svg';

interface MdxCodeSnippetProps {
  children: any;
  projectStore?: ProjectStore;
}

const MdxCodeSnippet = ({ children, projectStore }: MdxCodeSnippetProps) => {
  const code = children.props.children;

  let language = 'js';
  if (children.props.props.className) {
    language = children.props.props.className.replace('language-', '');
  }
  let apiKeyText = code.replace(
    'API_KEY_REPLACE',
    projectStore?.currentProject?.apiKey || 'YOUR-API-KEY',
  );
  apiKeyText = apiKeyText.replace(
    'WIDGET_BASE_URL',
    process.env.REACT_APP_WIDGET_PREVIEW,
  );

  const handleCopyClick = (e) => {
    copy(apiKeyText.replace('$ ', ''), {
      debug: true,
    });
    toast.success('Successfully copied ✓');
  };

  return (
    <div className="code-snippet">
      <img
        src={copyIcon}
        alt="copy icon"
        className="copy-icon"
        onClick={handleCopyClick}
      />
      <CodeBlock.CodeBlock
        text={apiKeyText}
        language={language}
        showLineNumbers={false}
        theme={CodeBlock.androidstudio}
      />
    </div>
  );
};

export default inject('projectStore')(observer(MdxCodeSnippet));
