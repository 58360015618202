import axios from './Axios';

const getPublicNews = (
  apiKey: string,
  offset: number = 0,
  gleapId?: string,
  gleapHash?: string,
) => {
  return axios.get(`/outbound/news/shared?offset=${offset}`, {
    headers: {
      'api-token': apiKey,
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
    },
  });
};

const getPublicNewsDetails = (
  apiKey: string,
  newsId: string,
  gleapId?: string,
  gleapHash?: string,
) => {
  return axios.get(`/outbound/news/shared/${newsId}`, {
    headers: {
      'api-token': apiKey,
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
    },
  });
};

export { getPublicNews, getPublicNewsDetails };
