import './FeatureRequestCard.scss';
import { Bug } from 'models/Bug';
import { useNavigate, useLocation } from 'react-router';
import { BugStore } from 'stores/private/BugStore';
import { inject, observer } from 'mobx-react';
import FeatureRequestUpvoteItem from 'components/FeatureRequestUpvoteItem/FeatureRequestUpvoteItem';
import FeatureRequestSubscribeItem from 'components/FeatureRequestSubscribeItem/FeatureRequestSubscribeItem';
import { getFormFieldValue } from 'helper/FormDataHelper';
import moment from 'moment';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';

interface FeatureRequestCardProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  sharedBoardStorePublic?: SharedBoardStorePublic;
  featureRequest: Bug;
  isShared?: boolean;
  showTag?: boolean;
  boardShareToken?: string;
  isRoadmap?: boolean;
}

const FeatureRequestCard = ({
  bugStore,
  projectStore,
  sharedBoardStorePublic,
  featureRequest,
  isShared,
  showTag = true,
  boardShareToken,
  isRoadmap = false,
}: FeatureRequestCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  let featureRequestState;

  if (isShared) {
    featureRequestState = sharedBoardStorePublic
      ?.getFeatureRequestStates(false)
      .find((x) => {
        return x.key === featureRequest.status;
      });
  } else {
    featureRequestState = projectStore
      ?.getFeatureRequestStates(false)
      .find((x) => {
        return x.key === featureRequest.status;
      });
  }

  const featureRequestDescription = getFormFieldValue(
    featureRequest,
    featureRequest.plainContent ? 'plainContent' : 'description',
  );

  return (
    <div
      className="feature-request-card"
      onClick={() => {
        if (isShared) {
          navigate(
            `/sharedboard/${boardShareToken}/${
              isRoadmap ? 'roadmap' : 'featurerequests'
            }/${featureRequest!.shareToken}`,
          );
        } else {
          const splittedUrl = location.pathname.split('/');
          bugStore!.openFeedbackItem(
            splittedUrl[2],
            featureRequest!.shareToken,
            window.location.pathname,
            true,
          );
        }
      }}
    >
      <div className="feature-request-card-inner">
        {isShared ? (
          <FeatureRequestUpvoteItem featureRequest={featureRequest} />
        ) : (
          <FeatureRequestSubscribeItem featureRequest={featureRequest} />
        )}
        <div className="feature-request-card-body">
          <div className="feature-request-card-body-title">
            {getFormFieldValue(featureRequest, 'title')}
          </div>
          {featureRequestDescription !== '--' && (
            <div className="feature-request-card-body-description">
              {featureRequestDescription}
            </div>
          )}
          {!isShared && (
            <div className="feature-request-card-body-date">
              {moment(featureRequest.createdAt).format('MMMM Do, YYYY')}
            </div>
          )}
          <div className="feature-request-card-body-statustags">
            {showTag && (
              <div
                className="feature-request-card-body-status"
                style={{
                  borderColor: featureRequestState?.color,
                  backgroundColor: featureRequestState?.color + '11',
                  color: featureRequestState?.color,
                }}
              >
                {featureRequestState?.title}
              </div>
            )}
            {featureRequest.tags?.map((tag, index) => {
              return (
                <div className="feature-request-card-body-tag" key={index}>
                  #{tag}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {featureRequest!.notificationsUnread && (
        <div className="feature-request-card-dot">
          <div className="feature-request-card-dot-inner" />
        </div>
      )}
    </div>
  );
};

export default inject(
  'bugStore',
  'sharedBoardStorePublic',
  'projectStore',
)(observer(FeatureRequestCard));

interface FeatureRequestCardSkeletonProps {
  height?: number;
  width?: number;
}

export const FeatureRequestCardSkeleton = ({
  height,
  width,
}: FeatureRequestCardSkeletonProps) => {
  return (
    <div className="feature-request-card" style={{ height, width }}>
      <div className="feature-request-card-inner">
        <PublicSkeleton
          height="48px"
          width="48px"
          style={{
            borderRadius: '8px',
          }}
        />
        <div className="feature-request-card-body">
          <div className="feature-request-card-body-title">
            <PublicSkeleton height="16px" width="200px" />
            <PublicSkeleton height="16px" width="160px" />
          </div>

          <div className="feature-request-card-body-description">
            <PublicSkeleton height="14px" width="200px" />
          </div>
        </div>
      </div>
    </div>
  );
};
