import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
  getPipelines,
  getPiplelineStages,
} from 'services/integrations/HubSpotHttpService';

export class HubspotIntegrationStore {
  stores: any = {};

  integrationID: string = '';

  pipelines: any = [];
  piplelineStages: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setPipelines = (pipelines) => {
    this.pipelines = pipelines;
  };

  setPipelineStages = (piplelineStages) => {
    this.piplelineStages = piplelineStages;
  };

  getPipelines = async () => {
    try {
      const response = await getPipelines({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });

      if (response.status === 200) {
        this.setPipelines(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  getPiplineStages = async (pipelineID) => {
    try {
      const response = await getPiplelineStages({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        pipelineID,
      });

      if (response.status === 200) {
        this.setPipelineStages(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
