/* eslint-disable prefer-destructuring */
import OutboundBlankImage from 'assets/OutboundBlank.png';
import OutboundMessageBlankImage from 'assets/OutboundMessageBlank.png';
import RatingsImage from 'assets/SurveysIntro.png';
import NewsImage from 'assets/icons/News.png';
import OutboundEmail from 'assets/icons/OutboundEmail.png';
import { HeadLine } from 'components/HeadLine/HeadLine';
import ListTable, {
  CellLinkInternal,
  CellTag,
  CellTextIcon
} from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { sum } from 'services/Helper';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectOutboundOverview.scss';

interface ProjectOutboundOverviewProps {
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  modalStore?: ModalStore;
}

const ProjectOutboundOverview = ({
  projectStore,
  outboundStore,
  modalStore,
}: ProjectOutboundOverviewProps) => {
  const [typeFilter, setTypeFilter] = useState('all');
  // @ts-ignore
  const { projectId } = useParams();
  const planIsEntitled = projectStore?.isFeatureInPlan(Feature.SURVEYS);

  const getIcon = (type: string) => {
    if (type === 'SURVEY') {
      return 'square-poll-vertical';
    }
    if (type === 'EMAIL') {
      return 'envelope';
    }

    if (type === 'NEWS') {
      return 'bullhorn';
    }

    return 'comment-lines';
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Rule',
        accessor: (row) => ({
          name: row.name,
          id: row.id,
          status: row.status,
          type: row.type,
          unread: outboundStore?.currentProjectSurveyUnreadCount[row.id] ?? 0,
        }),
        Cell: (row) => (
          <CellLinkInternal
            unread={row.value.unread}
            link={
              row.value.type === 'NEWS'
                ? `/projects/${projectId}/outbound/${row.value.id}/edit`
                : row.value.status === 'live'
                  ? `/projects/${projectId}/outbound/${row.value.id}`
                  : `/projects/${projectId}/${row.value.id}/loading`
            }
            text={row.value.name}
          />
        ),
        width: '45%',
      },
      {
        className: 'hide-on-mobile',
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        Header: 'Status',
        accessor: (row) => ({
          status: row.status,
          frequencyType: row.frequencyType,
        }),
        Cell: (row) => {
          if (
            row.value.status === 'live' &&
            row.value.frequencyType === 'fixed'
          ) {
            return <CellTag className="cell-tag--primary" text="Sent" />;
          }

          if (row.value.status === 'live') {
            return <CellTag className="cell-tag--green" text="Live" />;
          }

          return <CellTag className="cell-tag--gray" text="Draft" />;
        },
      },
      {
        className: 'hide-on-mobile',
        Header: 'Type',
        accessor: 'type',
        Cell: (row) => (
          <CellTextIcon
            className="cell-text-icon--capitalized"
            icon={getIcon(row.value)}
            text={row.value.toLocaleLowerCase()}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        Header: 'Type',
        accessor: (row) => ({
          frequency: row.frequency,
          frequencyType: row.frequencyType,
        }),
        Cell: (row) => (
          <CellTextIcon
            className="cell-text-icon--capitalized"
            icon={row.value.frequencyType === 'fixed' ? 'lock' : `bolt`}
            text={row.value.frequencyType === 'fixed' ? 'Fixed' : `Dynamic`}
          />
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject) {
      outboundStore!.loadOutboundRules();
    }
  }, [projectStore?.currentProject]);

  const buttonText = () => {
    if (typeFilter === 'surveys') {
      return 'New survey';
    }
    if (typeFilter === 'messages') {
      return 'New chat message';
    }
    if (typeFilter === 'emails') {
      return 'New email';
    }
    if (typeFilter === 'news') {
      return 'New article';
    }
    return 'New message';
  };

  const renderCreateSurveyButton = (buttonText) => {
    return (
      <PrimaryButton
        icon="plus"
        iconSideRight={false}
        onClick={async () => {
          modalStore!.openModal(MODALTYPE.CREATE_SURVEY, {
            type: typeFilter,
          });
        }}
        label={buttonText}
      />
    );
  };

  const outboundRules = (outboundStore?.outboundRules ?? []).filter(
    (outboundRule) => {
      if (typeFilter === 'all') {
        return true;
      }
      return typeFilter.includes(outboundRule.type.toLocaleLowerCase());
    },
  );

  const hasSurveys =
    planIsEntitled && outboundRules && outboundRules.length > 0;

  const renderMainContent = () => {
    if (outboundStore?.loadingOutboundRules || !projectStore?.currentProject) {
      return <Loading />;
    }

    const getEmptyState = () => {
      if (typeFilter === 'messages') {
        return {
          image: OutboundMessageBlankImage,
          title: 'No chat messages yet',
          description: 'Compose a new chat message to get started.',
        };
      }

      if (typeFilter === 'surveys') {
        return {
          image: RatingsImage,
          title: 'No surveys yet',
          description: 'Gather valuable feedback with surveys.',
        };
      }

      if (typeFilter === 'emails') {
        return {
          image: OutboundEmail,
          title: 'No outbound emails yet',
          description: 'Engage with your customers with automated emails.',
        };
      }

      if (typeFilter === 'news') {
        return {
          image: NewsImage,
          title: 'No news articles yet',
          description:
            'Keep your customers up to date with news & release notes.',
        };
      }

      return {
        image: OutboundBlankImage,
        title: 'No outbound messages yet',
        description: 'Engage with your customers through surveys or messages.',
      };
    };
    const blankState = getEmptyState();

    const getUpgradeState = () => {
      if (typeFilter === 'messages') {
        return {
          title: 'Chat messages',
          description: 'Send chat messages based on events in your app.',
        };
      }

      if (typeFilter === 'surveys') {
        return {
          title: 'Surveys',
          description: 'Gather valuable user feedback with micro surveys.',
        };
      }

      if (typeFilter === 'emails') {
        return {
          title: 'Outbound emails',
          description: 'Engage with your customers with automated emails.',
        };
      }

      if (typeFilter === 'news') {
        return {
          title: 'News / release notes',
          description:
            'Keep your customers up to date with news & release notes.',
        };
      }

      return {
        title: 'Outbound messages',
        description:
          'Run surveys, send emails based on events & create release notes to build better customer relationships.',
      };
    };
    const upgradeState = getUpgradeState();

    return (
      <>
        {hasSurveys ? (
          <ListTable data={outboundRules} columns={columns} />
        ) : (
          <div className="no-surveys-container">
            <img
              src={blankState.image}
              alt="No surveys created yet"
              className="no-surveys-image"
            />
            {planIsEntitled ? (
              <>
                <HeadLine
                  title={blankState.title}
                  subtitle={blankState.description}
                />
                {renderCreateSurveyButton(buttonText())}
              </>
            ) : (
              <SuggestSubscription
                plan={Plantypes.GROWTH}
                title={upgradeState.title}
                description={`${upgradeState.description}`}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const getSurveysNotificationCount = () => {
    return sum(outboundStore?.currentProjectSurveyUnreadCount ?? {});
  };

  const render = () => {
    return (
      <SidenavContainer className="sidenav-container--small capitalized-title">
        <SubSidenav title="Outbound">
          <SimpleSidenavElement
            name="All"
            onClick={() => {
              setTypeFilter('all');
            }}
            isActive={typeFilter === 'all'}
          />
          <SimpleSidenavElement
            name="Surveys"
            onClick={() => {
              setTypeFilter('surveys');
            }}
            isActive={typeFilter === 'surveys'}
            notificationCount={getSurveysNotificationCount()}
          />
          <SimpleSidenavElement
            name="Chat messages"
            onClick={() => {
              setTypeFilter('messages');
            }}
            isActive={typeFilter === 'messages'}
          />
          <SimpleSidenavElement
            name="News / Release notes"
            onClick={() => {
              setTypeFilter('news');
            }}
            isActive={typeFilter === 'news'}
          />
          <SimpleSidenavElement
            name="Emails"
            onClick={() => {
              setTypeFilter('emails');
            }}
            isActive={typeFilter === 'emails'}
          />
        </SubSidenav>
        <PageContainer>
          <PageHeadLine title={typeFilter}>
            <div className="header-content-right hide-on-mobile">
              {planIsEntitled &&
                outboundStore?.outboundRules &&
                outboundStore?.outboundRules.length > 0 &&
                renderCreateSurveyButton(buttonText())}
            </div>
          </PageHeadLine>
          <PageContent hasTitle hasPadding isBoxed isCentered={!hasSurveys}>
            {renderMainContent()}
          </PageContent>
        </PageContainer>
      </SidenavContainer>
    );
  };

  return (
    <div className="project-dashboard">
      {render()}
      {!projectStore?.currentProject?.sdkInstalled && (
        <div className="project-not-installed">
          <NotInstalledCard title="Get started with outbound messaging 👋" description='Install the Gleap SDK to start sending outbound messages.' />
        </div>
      )}
    </div>
  );
};

export default inject(
  'projectStore',
  'outboundStore',
  'modalStore',
)(observer(ProjectOutboundOverview));
