import React from 'react';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import './TagInput.scss';

interface TagInputProps {
  tags: string[];
  onChange: any;
}

const TagInput = ({ tags, onChange }: TagInputProps) => {
  return (
    <ReactTagInput
      placeholder="Type a key and press enter"
      tags={tags}
      onChange={onChange}
    />
  );
};

export default TagInput;
