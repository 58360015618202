import React from 'react';
import './IconDropdown.scss';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';

interface IconType {
  icon: any;
  label: string;
  value: string;
}

interface IconDropdownProps {
  options: IconType[];
  value?: IconType;
  onValueChanged: (data: IconType) => void;
}

const IconDropdown = ({
  options,
  value,
  onValueChanged,
}: IconDropdownProps) => {
  return (
    <>
      <Select
        className="icon-dropdown-selection"
        classNamePrefix="icon-dropdown-selection"
        value={value}
        placeholder="Type"
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            const Icon = option.data.icon;
            return (
              <div className="icon-type-item">
                <Icon className="status-icon" />
                {option.data.label}
              </div>
            );
          },
          Option: (option: any) => {
            const Icon = option.data.icon;
            return (
              <div
                className="option option-list-item icon-type-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <Icon className="status-icon" />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected) {
            onValueChanged(selected);
          }
        }}
        options={options}
      />
    </>
  );
};

export default inject('projectStore')(observer(IconDropdown));
