import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getHelpscoutMailboxes } from 'services/integrations/HelpscoutHttpService';

export class HelpscoutIntegrationStore {
  stores: any = {};

  integrationID: string = '';

  mailboxes: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setMailboxes = (mailboxes) => {
    this.mailboxes = mailboxes;
  };

  getMailboxes = async () => {
    try {
      const response = await getHelpscoutMailboxes({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });

      if (response.status === 200) {
        this.setMailboxes(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
