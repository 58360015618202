import React, { useState } from 'react';
import classNames from 'classnames';
import './TextInput.scss';
import { useNavigate } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import { ActionMeta } from 'react-select';

interface TextInputProps {
  placeholder: string;
  inputRef?: any;
  initalValue?: any;
  value?: string;
  type?: any;
  className?: any;
  error?: string;
  onEnter?: any;
  name?: string;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  small?: boolean;
  icon?: String;
  description?: any;
  validator?: any;
  preText?: string;
  onChange?: (text) => void;
  onKeyPress?: any;
  language?: any;
  languageSelectorOptions?: any[];
  languageSelectorValue?: any;
  autoWidth?: boolean;
  onLanguageChange?: (
    value: any,
    actionMeta?: ActionMeta<any> | undefined,
  ) => void;
  projectStore?: ProjectStore;
}

const TextInput = ({
  placeholder,
  initalValue = undefined,
  value,
  autoWidth = false,
  type,
  className,
  onEnter,
  error,
  preText,
  inputRef,
  name,
  label,
  required,
  readOnly,
  small,
  icon,
  description,
  onChange,
  onKeyPress,
  language,
  languageSelectorOptions,
  languageSelectorValue,
  onLanguageChange,
  projectStore,
}: TextInputProps) => {
  const navigate = useNavigate();

  const onEnterPressed = (event: any) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  const fieldType = () => {
    if (type && type === 'password') {
      return 'password';
    }
    if (type && type === 'number') {
      return 'number';
    }
    return 'text';
  };

  const renderEditableInput = () => {
    const editableClassName = classNames({
      'textinput-gray': true,
      'textinput-error': error !== '',
    });

    return (
      <div className="input-wrapper">
        <input
          {...inputRef}
          style={{
            width: autoWidth ? `${(value?.length ?? 0) * 10.5}px` : 'auto',
          }}
          readOnly={readOnly}
          className={editableClassName}
          type={fieldType()}
          name={name}
          autoComplete="off"
          placeholder={placeholder}
          defaultValue={initalValue}
          value={value}
          onKeyPress={onKeyPress}
          onChange={(text) => {
            if (onChange) {
              onChange(text.target.value);
            }
            if (inputRef && typeof inputRef.onChange !== 'undefined') {
              inputRef.onChange(text);
            }
          }}
        />
        {languageSelectorOptions && (
          <LanguageDropdown
            items={languageSelectorOptions}
            selectedItem={languageSelectorValue}
            onChange={onLanguageChange!}
          />
        )}
      </div>
    );
  };

  const renderErrorMessage = () => {
    if (!error) {
      return null;
    }
    return <div className="error-message">{error}</div>;
  };

  const textInputClassName = classNames(
    {
      'field-container': true,
      'field-container--icon': icon && icon.length > 0,
      'field-container--pretext': preText && preText.length > 0,
      'field-container--small': small,
      'field-container--translateable': language && language.length > 0,
    },
    className,
  );

  return (
    <div
      className={textInputClassName}
      onClick={() => { }}
      onKeyPress={(event) => {
        onEnterPressed(event);
      }}
    >
      <div className="label-container">
        {label && (
          <div className="input-label">
            {label} {required && <span className="input-required">*</span>}
          </div>
        )}
        {language && (
          <div
            className="language-tag"
            onClick={() => {
              navigate(
                `/projects/${projectStore?.currentProject?.id}/widget/translations`,
              );
            }}
          >
            {language}
          </div>
        )}
      </div>
      {icon && <i className={`input-icon fa-solid fa-${icon}`} />}
      {preText && <div className='input-pre-text'>{preText}</div>}
      {renderEditableInput()}
      {description && <div className="input-description">{description}</div>}
      {renderErrorMessage()}
    </div>
  );
};

export default inject('projectStore')(observer(TextInput));
