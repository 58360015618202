import { BubbleMenu, Editor } from '@tiptap/react';
import { ReactComponent as AIIcon } from 'assets/icons/ai.svg';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { uploadFileToServer } from 'services/FileUpload';
import { performAITextAction } from 'services/ProjectHttpService';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import { useFilePicker } from 'use-file-picker';
import { ReactComponent as ButtonIcon } from '../../../assets/icons/button_icon.svg';
import './Toolbar.scss';

export enum ToolbarItems {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Embedded = 'Embedded',
  AI = 'AI',
}

interface ToolbarProps {
  editor: Editor | null;
  floating?: boolean;
  items?: ToolbarItems[];
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
  aiStyle?: string;
}

const Toolbar = ({ editor, projectStore, organisationStore, modalStore, floating = false, aiStyle = 'agent', items = [ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.AI] }: ToolbarProps) => {
  const [linkUrlInputVisible, setLinkUrlInputVisible] = useState(false);
  const [buttonLinkUrlInputVisible, setButtonLinkUrlInputVisible] =
    useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [showAITools, setShowAITools] = useState(false);
  const [videoUrlInputVisible, setVideoUrlInputVisible] = useState(false);
  const [iframeUrlInputVisible, setIframeUrlInputVisible] = useState(false);
  const [linkUrlContent, setLinkUrlContent] = useState('');
  const [buttonLinkUrlContent, setButtonLinkUrlContent] = useState('');
  const [videoUrlContent, setVideoUrlContent] = useState('');
  const [iframeUrlContent, setIframeUrlContent] = useState('');
  const linkInputRef = useRef<HTMLInputElement>(null);
  const buttonLinkInputRef = useRef<HTMLInputElement>(null);
  const videoLinkInputRef = useRef<HTMLInputElement>(null);
  const iframeLinkInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 10,
  });

  const insertImageFromFile = async (file) => {
    const uploadedAttachment = await uploadFileToServer(file, `helparticle`);
    if (uploadedAttachment) {
      editor?.chain().focus().setImage({ src: uploadedAttachment }).run();
    }
    clear();
  };

  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      insertImageFromFile(plainFiles[0]);
    }
  }, [plainFiles]);

  const openLinkInput = useCallback(() => {
    setLinkUrlInputVisible(true);
    const previousUrl = editor!.getAttributes('link').href;
    if (previousUrl) {
      setLinkUrlContent(previousUrl);
    }

    setTimeout(() => {
      linkInputRef.current!.focus();
    }, 150);
  }, [editor]);

  const openButtonInput = useCallback(() => {
    setButtonLinkUrlInputVisible(true);
    const previousUrl = editor!.getAttributes('link').href;
    if (previousUrl) {
      setButtonLinkUrlContent(previousUrl);
    }

    setTimeout(() => {
      if (buttonLinkInputRef.current) {
        buttonLinkInputRef.current!.focus();
      }
    }, 150);
  }, [editor]);

  const openVideoInput = useCallback(() => {
    setVideoUrlInputVisible(true);
    setTimeout(() => {
      if (videoLinkInputRef.current) {
        videoLinkInputRef.current!.focus();
      }
    }, 150);
  }, [editor]);

  const openiFrameInput = useCallback(() => {
    setIframeUrlInputVisible(true);
    setTimeout(() => {
      if (iframeLinkInputRef.current) {
        iframeLinkInputRef.current!.focus();
      }
    }, 150);
  }, [editor]);

  const setLink = (event) => {
    if (event.key === 'Enter') {
      // Empty
      if (linkUrlContent === '') {
        editor!.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
      }

      // Update link
      editor!
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: linkUrlContent })
        .updateAttributes('link', { linktype: 'link' })
        .run();

      setLinkUrlInputVisible(false);
      setLinkUrlContent('');
    }
  };

  const setButton = (event) => {
    if (event.key === 'Enter') {
      // empty
      if (buttonLinkUrlContent === '') {
        editor!.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      // update link
      editor!
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: buttonLinkUrlContent })
        .updateAttributes('link', { linktype: 'button' })
        .run();

      setButtonLinkUrlInputVisible(false);
      setButtonLinkUrlContent('');
    }
  };

  const setVideo = (event) => {
    if (event.key === 'Enter') {
      // empty
      if (videoUrlContent === '') {
        editor!.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      // update link
      editor!.commands.setYoutubeVideo({
        src: videoUrlContent,
      });

      setVideoUrlInputVisible(false);
      setVideoUrlContent('');
    }
  };

  const setiFrame = (event) => {
    if (event.key === 'Enter') {
      // empty
      if (iframeUrlContent === '') {
        return;
      }

      // update link
      editor!.chain().focus().setIframe({ src: iframeUrlContent }).run()

      setIframeUrlInputVisible(false);
      setIframeUrlContent('');
    }
  };

  if (!editor) {
    return <></>;
  }

  const doAIAction = async (action, plainText, selection) => {
    if (!projectStore?.currentProject?.id) {
      setShowAITools(false);
      return;
    }

    setAiLoading(true);

    return performAITextAction(projectStore?.currentProject?.id ?? "", action, plainText, selection ? "plain" : aiStyle).then((response) => {
      if (response.status === 200 && response.data && response.data.text) {
        // Repace text.
        setAiLoading(false);
        setShowAITools(false);

        return response.data.text;
      }
      return null;
    }).catch(() => {
      setAiLoading(false);

      Swal.fire({
        text: 'AI assist is not supported in your legacy plan. Move to our new plans to unlock AI assist.',
        showCancelButton: true,
        confirmButtonText: `Update plan`,
        denyButtonText: `Cancel`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(
            `/organization/${organisationStore?.currentOrganisation?.id}/billing`,
          );
          modalStore?.closeModal();
        }
      });
      return null;
    });
  }

  const workAIAction = async (action) => {
    var text = "";

    const { from, to, empty } = editor.state.selection
    if (empty) {
      text = editor.getText();
    } else {
      text = editor.state.doc.textBetween(from, to, ' ');
    }

    if (text && text.length > 5) {
      const result = await doAIAction(action, text, !empty);
      if (result) {
        var textToPaste = result.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if (empty) {
          editor.commands.clearContent();
          editor.commands.insertContent(textToPaste);
        } else {
          editor.commands.setTextSelection({ from: from, to: to });
          editor.commands.deleteSelection();
          editor.commands.insertContentAt(from, textToPaste, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: 'full',
            }
          });
        }
      }
    }
  }

  const renderAITools = () => {
    if (aiLoading) {
      return (
        <div className='ai-toolbar-loading'>
          <LoadingAnimationSmall />
        </div>
      );
    }

    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        <div
          data-for="toolbarTooltip"
          data-tip="Expand text"
          onClick={() => {
            workAIAction("expand");
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}>
          <i className="fa-regular fa-pen-nib" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Rephrase text"
          onClick={() => {
            workAIAction("rephrase");
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}>
          <i className="fa-regular fa-pen" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Rewrite text to sound more fun and energizing"
          onClick={() => {
            workAIAction("energize");
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}>
          <i className="fa-regular fa-face-smile-relaxed" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Rewrite text to sound more professional"
          onClick={() => {
            workAIAction("formal");
          }}
          className={'bubble-menu-item bubble-menu-item-ai'}>
          <i className="fa-regular fa-face-meh" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Close AI tools"
          onClick={() => {
            setShowAITools(false);
          }}
          className={'bubble-menu-item'}>
          <i className="fa-sharp fa-solid fa-circle-xmark" />
        </div>
      </>
    );
  };

  const renderLinkTools = () => {
    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        <span
          className="bubble-menu-link-url-input bubble-menu-item"
        >
          <input
            ref={linkInputRef}
            className="link-input"
            placeholder="Enter a link"
            value={linkUrlContent}
            onChange={(event) => {
              setLinkUrlContent(event.target.value);
            }}
            onKeyPress={setLink}
            onBlur={() => {
              setLinkUrlInputVisible(false);
            }}
          />
          <i
            className="fa-regular fa-circle-xmark ml-15"
            onClick={() => {
              setLinkUrlInputVisible(false);
            }}
          />
        </span>
      </>
    );
  }

  const renderButtonLinkTools = () => {
    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        <span
          className="bubble-menu-link-url-input bubble-menu-item"
        >
          <input
            ref={buttonLinkInputRef}
            className="link-input"
            placeholder="Enter a button link"
            value={buttonLinkUrlContent}
            onChange={(event) => {
              setButtonLinkUrlContent(event.target.value);
            }}
            onKeyPress={setButton}
            onBlur={() => {
              setButtonLinkUrlInputVisible(false);
            }}
          />
          <i
            className="fa-regular fa-circle-xmark ml-15"
            onClick={() => {
              setButtonLinkUrlInputVisible(false);
            }}
          />
        </span>
      </>
    );
  }

  const renderVideoUrlTools = () => {
    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        <span
          className="bubble-menu-link-url-input bubble-menu-item"
        >
          <input
            ref={videoLinkInputRef}
            className="link-input"
            placeholder="Enter a video link"
            value={videoUrlContent}
            onChange={(event) => {
              setVideoUrlContent(event.target.value);
            }}
            onKeyPress={setVideo}
            onBlur={() => {
              setVideoUrlInputVisible(false);
            }}
          />
          <i
            className="fa-regular fa-circle-xmark ml-15"
            onClick={() => {
              setVideoUrlContent('');
              setVideoUrlInputVisible(false);
            }}
          />
        </span>
        <span
          className="bubble-menu-link-url-input bubble-menu-item"
          style={{
            visibility: iframeUrlInputVisible ? 'visible' : 'hidden',
          }}
        >
          <input
            ref={iframeLinkInputRef}
            className="link-input"
            placeholder="Enter an iframe URL"
            value={iframeUrlContent}
            onChange={(event) => {
              setIframeUrlContent(event.target.value);
            }}
            onKeyPress={setiFrame}
            onBlur={() => {
              setIframeUrlContent('');
              setIframeUrlInputVisible(false);
            }}
          />
          <i
            className="fa-regular fa-circle-xmark ml-15"
            onClick={() => {
              setIframeUrlInputVisible(false);
            }}
          />
        </span>
      </>
    );
  }

  const renderiFrameUrlTools = () => {
    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        <span
          className="bubble-menu-link-url-input bubble-menu-item"
        >
          <input
            ref={iframeLinkInputRef}
            className="link-input"
            placeholder="Enter an iframe URL"
            value={iframeUrlContent}
            onChange={(event) => {
              setIframeUrlContent(event.target.value);
            }}
            onKeyPress={setiFrame}
            onBlur={() => {
              setIframeUrlContent('');
              setIframeUrlInputVisible(false);
            }}
          />
          <i
            className="fa-regular fa-circle-xmark ml-15"
            onClick={() => {
              setIframeUrlInputVisible(false);
            }}
          />
        </span>
      </>
    );
  }

  const renderBaseTools = () => {
    return (
      <>
        <ReactTooltip
          id="toolbarTooltip"
          className="infoTooltipBold"
          delayHide={0}
          type="light"
          offset={{
            top: 14,
          }}
          place={'top'}
          effect="solid"
        />
        <div
          data-for="toolbarTooltip"
          data-tip="Heading 2"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={`bubble-menu-item ${editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
            }`}
        >
          <i className="fa-solid fa-h2" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Heading 3"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={`bubble-menu-item ${editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
            }`}
        >
          <i className="fa-solid fa-h3" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Bold"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`bubble-menu-item ${editor.isActive('bold') ? 'is-active' : ''
            }`}
        >
          <i className="fa-solid fa-bold" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Italic"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`bubble-menu-item ${editor.isActive('italic') ? 'is-active' : ''
            }`}
        >
          <i className="fa-solid fa-italic" />
        </div>
        <div
          data-for="toolbarTooltip"
          data-tip="Strikethrough"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`bubble-menu-item ${editor.isActive('strike') ? 'is-active' : ''
            }`}
        >
          <i className="fa-solid fa-strikethrough" />
        </div>
        {!editor.isActive('link', { linktype: 'link' }) && (
          <div
            data-for="toolbarTooltip"
            data-tip="Insert link"
            onClick={openLinkInput} className={'bubble-menu-item '}>
            <i className="fa-solid fa-link" />
          </div>
        )}
        {editor.isActive('link', { linktype: 'link' }) && (
          <div
            data-for="toolbarTooltip"
            data-tip="Remove link"
            onClick={() => editor.chain().focus().unsetLink().run()}
            className={'bubble-menu-item is-active'}
          >
            <i className="fa-solid fa-link-slash" />
          </div>
        )}
        <div
          data-for="toolbarTooltip"
          data-tip="List"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`bubble-menu-item ${editor.isActive('bulletList') ? 'is-active' : ''
            }`}
        >
          <i className="fa-solid fa-list" />
        </div>
        {items.includes(ToolbarItems.Advanced) && (<>
          <div
            data-for="toolbarTooltip"
            data-tip="Code block"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={`bubble-menu-item ${editor.isActive('codeBlock') ? 'is-active' : ''
              }`}
          >
            <i className="fa-solid fa-square-code" />
          </div>
        </>)}
        {!floating && items.includes(ToolbarItems.Advanced) && (
          <>
            <div
              data-for="toolbarTooltip"
              data-tip="Insert image"
              onClick={() => {
                openFileSelector();
              }}
              className={`bubble-menu-item ${editor.isActive('image') ? 'is-active' : ''
                }`}
            >
              <i className="fa-solid fa-image" />
            </div>
            {items.includes(ToolbarItems.Embedded) && (<>
              <div
                data-for="toolbarTooltip"
                data-tip="Insert YouTube video"
                onClick={openVideoInput} className={'bubble-menu-item'}>
                <i className="fa-brands fa-youtube" />
              </div>
              <div
                data-for="toolbarTooltip"
                data-tip="Embed iFrame"
                onClick={openiFrameInput} className={'bubble-menu-item'}>
                <i className="fa-regular fa-window-restore" />
              </div>
            </>)}
          </>
        )}
        {items.includes(ToolbarItems.Advanced) &&
          (
            !editor.isActive('link', { linktype: 'button' }) ? (
              <div
                data-for="toolbarTooltip"
                data-tip="Insert button"
                onClick={openButtonInput} className={'bubble-menu-item'}>
                <ButtonIcon className="bubble-menu-item-icon" />
              </div>
            ) : (
              <div
                onClick={() => editor.chain().focus().unsetLink().run()}
                className={'bubble-menu-item is-active'}
              >
                <ButtonIcon className="bubble-menu-item-icon isActive" />
              </div>
            )
          )}
        {items.includes(ToolbarItems.AI) && (
          <div
            data-for="toolbarTooltip"
            data-tip="Open AI assist tools"
            onClick={() => {
              setShowAITools(true);
            }}
            className={'bubble-menu-item'}>
            <AIIcon className="bubble-menu-item-aiicon" />
          </div>
        )}
      </>
    );
  }

  const buildToolbar = () => {
    if (showAITools) {
      return renderAITools();
    }

    if (videoUrlInputVisible) {
      return renderVideoUrlTools();
    }

    if (linkUrlInputVisible) {
      return renderLinkTools();
    }

    if (buttonLinkUrlInputVisible) {
      return renderButtonLinkTools();
    }

    if (iframeUrlInputVisible) {
      return renderiFrameUrlTools();
    }

    return renderBaseTools();
  }

  if (floating) {
    return (
      <BubbleMenu
        className="bubble-menu bubble-menu-container"
        tippyOptions={{ duration: 100 }}
        editor={editor}
      >
        {buildToolbar()}
      </BubbleMenu>
    );
  }

  return <div className="bubble-menu">{buildToolbar()}</div>;
};

export default inject('projectStore', 'organisationStore', 'modalStore')(observer(Toolbar));
