import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ShortcutIntegrationStore } from 'stores/integrations/ShortcutIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import ARROW from 'assets/icons/np_arrow_3542562_000000.svg';
import GLEAP_LOGO from 'assets/icons/Gleap.svg';
import SHORTCUT_LOGO from 'assets/icons/shortcut_logo.svg';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';

interface ShortcutConnectionConfigurationProps {
  shortcutIntegrationStore?: ShortcutIntegrationStore;
  projectStore?: ProjectStore;
}

const ShortcutConnectionConfiguration = ({
  shortcutIntegrationStore,
  projectStore,
}: ShortcutConnectionConfigurationProps) => {
  const navigate = useNavigate();

  // @ts-ignore
  const { projectId } = useParams();

  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, []);

  useEffect(() => {
    navigate(
      `/projects/${projectId}/integrations/shortcut/${shortcutIntegrationStore?.integrationID}`,
      { replace: true },
    );
  }, [shortcutIntegrationStore?.integrationID]);

  const connectShortcut = async () => {
    shortcutIntegrationStore!.setAuth(accessToken);
  };

  return (
    <PageContainer>
      <PageHeadLine
        title="Shortcut Integration"
        onActionBack={() => {
          navigate(`/projects/${projectId}/integrations`);
        }}
      />
      <PageContent hasTitle isMediumBoxed>
        <div className="choose-integation-action-container">
          <div className="integration-info-content-header">
            <img
              className="integration-preview-image"
              src={GLEAP_LOGO}
              alt="Gleap logo"
            />
            <img className="arrow-icon" src={ARROW} alt="Integrations" />
            <img
              className="integration-preview-image"
              src={SHORTCUT_LOGO}
              alt="awork logo"
            />
          </div>
          <TextInput
            className="mb-30"
            error=""
            placeholder=""
            label="API token"
            initalValue={accessToken}
            onChange={(val) => {
              setAccessToken(val);
            }}
          />
          <a
            className="mb-15"
            rel="noreferrer"
            target="_blank"
            href="https://app.shortcut.com/settings/account/api-tokens"
          >
            Where do I find the Shortcut api token?
          </a>
          <SizedContainer size={ContainerSizes.M}>
            <PrimaryButton
              label="Connect"
              onClick={connectShortcut}
              disabled={accessToken === ''}
            />
          </SizedContainer>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'shortcutIntegrationStore',
  'projectStore',
)(observer(ShortcutConnectionConfiguration));
