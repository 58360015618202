import React from 'react';
import './CreateProjectPage.scss';
import CreateProject from 'components/CreateProject/CreateProject';
import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';

interface CreateProjectPageProps {
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
}
const CreateProjectPage = ({ projectStore }: CreateProjectPageProps) => {
  return (
    <PageContainer className="update-project">
      <PageHeadLine title="Create a project" />
      <PageContent hasTitle isCentered isMediumBoxed>
        {projectStore!.initialLoading ? (
          <Loading />
        ) : (
          <CreateProject redirectAfter="settings/installation" />
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'organisationStore',
  'projectStore',
)(observer(CreateProjectPage));
