import AuthenticationForm from 'components/AuthenticationForm/AuthenticationForm';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { UsersStore } from '../../../stores/private/UsersStore';
import './Login.scss';
import LoginForm from './LoginForm/LoginForm';

interface LoginProps {
  usersStore?: UsersStore;
}

const Login = ({ usersStore }: LoginProps) => {
  useEffect(() => {
    const authResult = new URLSearchParams(window.location.search);
    if (authResult) {
      if (authResult.get('code')) {
        usersStore?.loginGithub(authResult.get('code')!);
      } else {
        usersStore?.setLoading(false);
      }
    } else {
      usersStore!.setLoading(false);
    }
    return () => {};
  }, [usersStore]);
  if (usersStore!.loading) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <div className="login-page">
      <AuthenticationForm
        headline="Login to Gleap"
        subtitle="Happy customers, more efficient team."
        form={<LoginForm />}
      />
    </div>
  );
};
export default inject('usersStore')(observer(Login));
