import axios from '../Axios';

const getAuthCodeFromJira = (projectID) => {
  const redirectUri = `${process.env.REACT_APP_BASEURL}/integrations/jira`;
  return window.open(
    `${
      process.env.REACT_APP_JIRA_CONNECT_URL
    }&state=${projectID}&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&client_id=${process.env.REACT_APP_JIRA_CLIENT_ID}`,
    '_self',
  );
};

const setJiraAuthToken = (code, projectID) => {
  return axios.post(`projects/${projectID}/integrations/jira`, {
    code,
  });
};

const getAccessibleOrganisations = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/jira/${integrationID}/organisations`,
  );
};

const getBoards = ({ projectID, integrationID, organisationID }) => {
  return axios.get(
    `projects/${projectID}/integrations/jira/${integrationID}/boards?organisationID=${organisationID}`,
  );
};

const setJiraActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/jira/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectJiraIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/jira/${integrationID}`,
  );
};

export {
  getAuthCodeFromJira,
  setJiraAuthToken,
  getAccessibleOrganisations,
  getBoards,
  setJiraActions,
  disconnectJiraIntegration,
};
