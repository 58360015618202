import './SessionDetailsShort.scss';
import { Session } from 'models/Session';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import currency from 'currency.js';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';
import { getCountryByCode } from 'services/CountryHelper';
import moment from 'moment';

interface SessionDetailsShortProps {
  session?: Session;
  shared?: boolean;
  onHeaderClicked?: () => void;
}

const SessionDetailsShort = ({
  session,
  shared,
  onHeaderClicked,
}: SessionDetailsShortProps) => {
  if (!session) {
    return <></>;
  }

  let location = 'Unknown';
  if (session.location) {
    location =
      session.location.region && session.location.region.length > 0
        ? `${session.location.region}, ${getCountryByCode(session.location.country)}`
        : getCountryByCode(session.location.country);
  }

  const renderCustomData = () => {
    if (shared) {
      return null;
    }

    if (!session.customData) {
      return null;
    }

    return Object.keys(session.customData).map((key) => {
      const data = session.customData[key] ?? 'Unknown';
      return (
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">{key}</div>
          <div className="contact-details-card-value-row-value">
            <span data-for="sessionDetailsTooltip" data-tip={`${data}`}>
              {`${data}`}
            </span>
          </div>
        </div>
      );
    });
  };

  const name = cleanupName(getSessionName(session), 20);

  return (
    <>
      <div className="contact-details-card card session-details-short">
        <div className="contact-details-card-rows">
          {name && (
            <div className="contact-details-card-value-row">
              <div className="contact-details-card-value-row-key">Name</div>
              <div className="contact-details-card-value-row-value">
                <div className='contact-short-name'>
                  {name ?? 'Not set'}
                  {session.userId ? (
                    <div className="type-tag">
                      <i className="fa-solid fa-shield-check"></i>
                    </div>
                  ) : (
                    <div className="type-tag type-tag--guest">
                      <i className="fa-regular fa-shield"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {session.userId && (
            <div className="contact-details-card-value-row">
              <div className="contact-details-card-value-row-key">User id</div>
              <div className="contact-details-card-value-row-value">
                <span data-for="sessionTooltip" data-tip={session.userId}>
                  {session.userId ?? 'Not set'}
                </span>
              </div>
            </div>
          )}
          {session.unsubscribed && session.unsubscribedAt && (
            <div className="contact-details-card-value-row">
              <div className="contact-details-card-value-row-key">Unsubscribed</div>
              <div className="contact-details-card-value-row-value">
                {moment(session.unsubscribedAt).format('LLL')}
              </div>
            </div>
          )}
          <div className="contact-details-card-value-row">
            <div className="contact-details-card-value-row-key">Email</div>
            <div className="contact-details-card-value-row-value">
              <span data-for="sessionDetailsTooltip" data-tip={session.email}>
                {session.email ?? 'Unknown'}
              </span>
            </div>
          </div>
          {!shared && (
            <>
              {session.location && (
                <div className="contact-details-card-value-row">
                  <div className="contact-details-card-value-row-key">
                    Location
                  </div>
                  <div className="contact-details-card-value-row-value capitalize">
                    <span
                      data-for="sessionDetailsTooltip"
                      data-tip={session.name}
                    >
                      {location}
                    </span>
                  </div>
                </div>
              )}
              {session.phone && (
                <div className="contact-details-card-value-row">
                  <div className="contact-details-card-value-row-key">
                    Phone
                  </div>
                  <div className="contact-details-card-value-row-value">
                    <span data-for="sessionTooltip" data-tip={session.phone}>
                      {session.phone ?? 'Unknown'}
                    </span>
                  </div>
                </div>
              )}
              {session.value && session.value > 0 ? (
                <div className="contact-details-card-value-row">
                  <div className="contact-details-card-value-row-key">
                    Value
                  </div>
                  <div className="contact-details-card-value-row-value">
                    <span data-for="sessionDetailsTooltip">
                      {session.value
                        ? currency(session.value).format()
                        : 'Not set'}
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          )}
          {renderCustomData()}
          {!shared && (
            <div className='sidebar-more-button' onClick={() => {
              if (onHeaderClicked) {
                onHeaderClicked();
              }
            }}>Show contact details</div>
          )}
        </div>
      </div>
      <ReactTooltip
        id="sessionDetailsTooltip"
        className="infoTooltip"
        delayHide={300}
        type="light"
        effect="solid"
        getContent={(content) => {
          return (
            <div className="copy-tooltip">
              <span>{content}</span>
              <CopyToClipboard
                text={content}
                onCopy={() => {
                  toast.success('Successfully copied ✓');
                }}
              >
                <i className="fa-solid fa-clone" />
              </CopyToClipboard>
            </div>
          );
        }}
      />
    </>
  );
};

export default SessionDetailsShort;
