import { Editor } from '@tiptap/react';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { languages } from 'constants/Languages';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import * as CodeBlock from 'react-code-blocks';
import Collapsible from 'react-collapsible';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import makeUrlFriendly from 'services/UrlHelper';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import './CreateHelpcenterArticle.scss';

interface CreateHelpcenterProps {
  projectStore?: ProjectStore;
  helpcenterStore?: HelpcenterStore;
  sidenavStore?: SidenavStore;
}

const CreateHelpcenterArticle = ({
  projectStore,
  helpcenterStore,
  sidenavStore,
}: CreateHelpcenterProps) => {
  const navigate = useNavigate();
  const { projectId, collectionId, articleId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [initIsLoading, setInitIsLoading] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const editorRef: MutableRefObject<Editor | null> = useRef(null);

  const {
    register,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  const helpcenterConfig = projectStore?.flowConfig.helpcenterConfig;

  const availableLanguages = languages.filter((language) =>
    helpcenterConfig?.localization.find(
      (item) => item.language === language.code,
    ),
  );

  const [selectedLanguage, setSelectedLanguage] = useState(
    availableLanguages[0],
  );

  const project = projectStore?.currentProject;
  const article = helpcenterStore?.currentArticle;

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    initialize();
  }, [collectionId, articleId, project]);

  const initialize = async () => {
    if (collectionId && articleId && project) {
      setInitIsLoading(true);
      reset();
      await helpcenterStore?.getArticle(collectionId, articleId);
      setInitIsLoading(false);
    }
  };

  useEffect(() => {
    editorRef?.current?.commands.setContent(
      article?.content[selectedLanguage.code] ?? {},
    );
  }, [editorRef, selectedLanguage]);

  const _createHelpercenterArticle = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    if (!article?.author || article.author === '') {
      toast.error('Author is required');
      return;
    }

    setIsLoading(true);

    let updatedArticle;

    if (articleId === 'new') {
      updatedArticle = await helpcenterStore?.createArticle(
        article!,
        collectionId,
      );
    } else {
      updatedArticle = await helpcenterStore?.updateArticle(
        article!,
        collectionId,
      );
    }

    setIsLoading(false);

    if (updatedArticle) {
      navigate(-1);
    }
  };

  const _deleteHelpercenterArticle = async () => {
    setDeleteIsLoading(true);

    await helpcenterStore?.deleteHelpcenterArticle(article!.id);

    setDeleteIsLoading(false);

    navigate(-1);
  };

  if (!article) {
    return <></>;
  }

  const _buildHelpcenterArticleEditor = () => {
    return (
      <div className="create-helpcenter">
        <form id="create-helpcenter-article-form">
          <div className="create-helpcenter-editor">
            <TextInput
              inputRef={register('title', {
                validate: (value) =>
                  value !== null &&
                    Object.keys(article?.title).length > 0 &&
                    !Object.keys(article?.title).find(
                      (item) =>
                        article!.title[item] == null ||
                        article!.title[item] === '',
                    )
                    ? true
                    : 'Article title is required',
              })}
              className="mb-0 mt-10 no-border-input title-input"
              placeholder="Article title"
              value={getProperty(article, `title.${selectedLanguage.code}`)}
              languageSelectorOptions={availableLanguages}
              languageSelectorValue={selectedLanguage}
              error={errors.title?.message}
              onLanguageChange={(value) => {
                setSelectedLanguage(value);
              }}
              onChange={(val) => {
                runInAction(() => {
                  setProperty(article, `title.${selectedLanguage.code}`, val);
                });
              }}
            />
            <TextInput
              inputRef={register('description', {
                validate: (value) =>
                  value !== null &&
                    Object.keys(article?.description).length > 0 &&
                    !Object.keys(article?.description).find(
                      (item) =>
                        article!.description[item] == null ||
                        article!.description[item] === '',
                    )
                    ? true
                    : 'Article description is required',
              })}
              className="mb-20 mt-0 no-border-input subtitle-input"
              placeholder="Describe your article to help users find it"
              error={errors.description?.message}
              value={getProperty(
                article,
                `description.${selectedLanguage.code}`,
              )}
              languageSelectorOptions={availableLanguages}
              languageSelectorValue={selectedLanguage}
              onLanguageChange={(value) => {
                setSelectedLanguage(value);
              }}
              onChange={(val) => {
                runInAction(() => {
                  setProperty(
                    article,
                    `description.${selectedLanguage.code}`,
                    val,
                  );
                });
              }}
            />
            <div className="fields-aside ml-10">
              <div>
                <FeedbackUserFilter
                  hasBorder
                  value={article.author}
                  truncatePreview={50}
                  truncate={50}
                  placeholder="Select author"
                  onValueChanged={(value) => {
                    if (value) {
                      runInAction(() => {
                        article.author = value;
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="helpcenter-input-container">
              <div className="helpcenter-input-container-language">
                <LanguageDropdown
                  items={availableLanguages}
                  selectedItem={selectedLanguage}
                  onChange={(value) => {
                    setSelectedLanguage(value);
                  }}
                />
              </div>
              <div className="editor-wrapper">
                <Toolbar editor={editorRef?.current} aiStyle="helpcenter" items={[ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.Embedded, ToolbarItems.AI]} floating />
                <EmailEditor
                  editorRef={editorRef}
                  onEditorReady={() => {
                    setToggleState(!toggleState);
                  }}
                  placeholder="Start writing your article..."
                  content={getProperty(
                    article,
                    `content.${selectedLanguage.code}`,
                  )}
                  inputContentChanged={(content) => {
                    runInAction(() => {
                      setProperty(
                        article,
                        `content.${selectedLanguage.code}`,
                        content,
                      );
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  if (initIsLoading) {
    return (
      <PageContainer>
        <PageHeadLine />
        <PageContent isCentered hasTitle>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const articleTitle = getProperty(article, `title.${selectedLanguage.code}`) ?? "no-title";
  const shareBaseURL = projectStore?.currentProject
    ?.customDomainHelpCenter
    ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
    : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;
  const articleURL = `${shareBaseURL}/${selectedLanguage.code}/articles/${article?.docId
    }-${makeUrlFriendly(articleTitle)}`;

  return (
    <PageContainer>
      <PageHeadLine onActionBack={() => navigate(-1)} title="Edit article">
        <div className="header-content-right">
          {articleId !== 'new' && (
            <LinkButton
              className="danger mr-15"
              label="Delete"
              isLoading={deleteIsLoading}
              onClick={() => {
                _deleteHelpercenterArticle();
              }}
            />
          )}
          <PrimaryButton
            label="Save"
            icon="save"
            isLoading={isLoading}
            iconSideRight={false}
            form="create-helpcenter-article-form"
            onClick={_createHelpercenterArticle}
          />
        </div>
      </PageHeadLine>
      <PageContent isMediumBoxed hasTitle>
        <>
          {_buildHelpcenterArticleEditor()}
          {article?.docId && (
            <div className='share-article-container mb-60'>
              <Collapsible
                className="Collapsible-big"
                trigger={<div className="collapsible-header">
                  <div className="collapsible-header-title">Share this article</div>
                  <div className="collapsible-header-sub">
                    <div>
                      <i className="fa-regular fa-code"></i> Share by code<br />
                      <i className="fa-regular fa-link-horizontal"></i> Share by link
                    </div>
                  </div>
                </div>}
                transitionTime={200}
                overflowWhenOpen="initial"
                openedClassName="Collapsible-big Collapsible--opened"
                onClose={() => { }}
              >
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">Share the article by code</div>
                  <div>
                    Offer <b>contextual help</b> to your customers by opening this
                    article directly within the Gleap widget. Usually you would simply
                    add a help icon button to your app and then call the following
                    method, to directly show this help article.
                    <div className="mt-10 mb-10 code-container">
                      <CodeBlock.CodeBlock
                        text={`Gleap.openHelpCenterArticle("${article?.docId}", false);`}
                        language={'js'}
                        showLineNumbers={false}
                      />
                    </div>
                    <a
                      href="https://docs.gleap.io/javascript/helpcenter"
                      target={'_blank'}
                      className="mt-10"
                      rel="noreferrer"
                    >
                      Open documentation
                    </a>
                  </div>
                </div>
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">Share this article by link</div>
                  <div>
                    <CodeBlock.CodeBlock
                      text={articleURL}
                      language={'js'}
                      showLineNumbers={false}
                    />
                  </div>
                </div>
              </Collapsible>
            </div>
          )}
          {editorRef?.current && (
            <div className="fixed-editor-toolbar">
              <Toolbar editor={editorRef?.current} aiStyle="helpcenter" items={[ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.Embedded, ToolbarItems.AI]} />
            </div>
          )}
        </>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'helpcenterStore',
  'sidenavStore',
)(observer(CreateHelpcenterArticle));
