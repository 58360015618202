import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { setProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { UsersStore } from 'stores/private/UsersStore';

const intervalValues = [
  {
    id: 'hourly',
    name: 'Hourly',
  },
  {
    id: 'daily',
    name: 'Daily',
  },
  {
    id: 'never',
    name: 'Never',
  },
];

interface ProfileNotificationSettingsProps {
  usersStore?: UsersStore;
}

const ProfileNotificationSettings = ({
  usersStore,
}: ProfileNotificationSettingsProps) => {
  const user = usersStore?.currentUser;

  const [isLoading, setisLoading] = useState(false);

  const updateUser = async () => {
    setisLoading(true);

    const toUpdateUser: any = {
      notificationSettings: user?.notificationSettings,
    };

    try {
      await usersStore!.updateUser(user!.id, toUpdateUser);
    } catch (err) {
      toast.error('Profile update failed');
    }

    setisLoading(false);
  };

  if (!user) {
    return <></>;
  }

  return (
    <PageContainer>
      <PageHeadLine title="My notifications" />
      <PageContent hasTitle isSmallBoxed>
        <SelectDropDown
          className="mb-15"
          label="Notification digest frequency"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={intervalValues.find(
            (item) => item.id === user.notificationSettings.interval ?? 'daily',
          )}
          items={intervalValues}
          onChange={(option) => {
            setProperty(user, 'notificationSettings.interval', option.id);
          }}
        />
        <PrimaryButton
          label="Save"
          isLoading={isLoading}
          onClick={updateUser}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('usersStore')(observer(ProfileNotificationSettings));
