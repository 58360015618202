import bbLogo from 'assets/icons/Gleap.svg';
import { HeadLine } from 'components/HeadLine/HeadLine';
import Loading from 'components/Loading/Loading';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import { TextHref } from 'components/TextHref/TextHref';
import TextInput from 'components/TextInput/TextInput';
import { EMAIL_REGEX } from 'constants/Regexpatterns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { validateRecaptchaAction } from 'services/Recaptcha';
import { sendResetPasswordLink } from 'services/ResetPasswordHttpService';
import './ForgetPassword.scss';

const ForgetPassword = () => {
  const { register, handleSubmit, formState } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [linkSended, setLinkSended] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    try {
      if (data.email) {
        setIsLoading(true);

        let token = '';
        try {
          token = (await validateRecaptchaAction('resetpassword')) as any;
        } catch (exp) {
          toast.error('Are you human?');
          return;
        }

        await sendResetPasswordLink(token, data.email);
        setLinkSended(true);
        setIsLoading(false);
        toast.success('Successfully send reset mail');
      }
    } catch (err: any) {
      setIsLoading(false);
      toast.error('User with this E-Mail does not exist');
    }
  };

  const buildResetPasswordForm = () => {
    return (
      <>
        <HeadLine
          className="mb-20"
          title="Password reset"
          subtitle="Enter your e-mail address, and we will send you a reset link."
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            placeholder="E-Mail*"
            type="text"
            name="email"
            className="mb-20"
            initalValue=""
            inputRef={register('email', {
              required: true,
              pattern: EMAIL_REGEX,
            })}
            error={
              formState.errors.email
                ? 'Please enter a valid e-mail address.'
                : ''
            }
            required
          />
          <PrimaryButton submit onClick={() => {}} label="Send reset link" />
        </form>
      </>
    );
  };

  const buildSucessScreen = () => {
    return (
      <>
        <HeadLine
          title="Successfully sent reset link"
          subtitle="Please check your E-Mail inbox"
        />
        <div className="centered">
          <PrimaryButton
            className="mt-10"
            onClick={() => {
              navigate('/login');
            }}
            label="Go to login"
          />
        </div>
      </>
    );
  };

  if (isLoading && !linkSended) {
    return <Loading />;
  }

  return (
    <div
      className="forget-password-container"
    >
      <div className="card-container">
        <div className="card">
          <img src={bbLogo} className="logo" alt="logo" />
          <SizedContainer size={ContainerSizes.M}>
            {!linkSended && buildResetPasswordForm()}
            {linkSended && buildSucessScreen()}
            <TextHref
              className="href mt-30 mb-20 text centered"
              label="Sign in?"
              onClick={() => {
                navigate('/login');
              }}
            />
          </SizedContainer>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
