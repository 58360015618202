import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import './APIActionEditor.scss';
import Gleap from 'gleap';

interface APIActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
}

const APIActionEditor = ({ action, modalStore }: APIActionEditorProps) => {
  return (
    <div className="api-action-editor">
      <PageContainer>
        <PageHeadLine title="Custom API action">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <InfoBox className="mb-20">
            The custom API action allows you to to seamlessly connect your live
            external data with Gleap. This real-time data can be used to empower
            bot flows, enabling your customers to self-serve their inquiries
            automatically within the Gleap widget.
            <br />
            <br />
            To use custom API actions, start by identifying common inquiries
            that your team spends a lot of time answering, particularly those
            without information available in Gleap. These may include questions
            about external systems, such as "What's my order status?" or actions
            like "Reschedule delivery."
          </InfoBox>
          <TextInput
            name="survey-question"
            label="API url"
            value={action.url ?? ''}
            placeholder="https://"
            type="text"
            error=""
            preText="POST"
            onChange={(val) => {
              runInAction(() => {
                action.url = val;
              });
            }}
          />
          <TextInput
            name="survey-question"
            className="mt-20"
            label="Authorization header (optional)"
            value={action.authHeader ?? ''}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                action.authHeader = val;
              });
            }}
          />
          <InfoBox className="mt-20">
            Learn more about the POST body and how to respond to the API call in
            our{' '}
            <a
              onClick={() => {
                Gleap.openHelpCenterArticle('53', false);
              }}
              href="#"
            >
              help center
            </a>
          </InfoBox>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore')(observer(APIActionEditor));
