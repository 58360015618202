import axios from '../Axios';

const setAzureAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/azure`, {
    code,
  });
};

const setAzureActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/azure/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectAzureIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/azure/${integrationID}`,
  );
};

export { setAzureAuthToken, setAzureActions, disconnectAzureIntegration };
