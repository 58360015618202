import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { ZendeskIntegrationStore } from 'stores/integrations/ZendeskIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import Switch from 'react-switch';
import './ZendeskConfiguration.scss';

interface ZendeskConfigurationProps {
  integrationID: string;
  index: number;
  zendeskIntegrationStore?: ZendeskIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const ZendeskConfiguration = ({
  integrationID,
  index,
  zendeskIntegrationStore,
  projectStore,
  onChange,
}: ZendeskConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const organizations = zendeskIntegrationStore?.organizations;
  const brands = zendeskIntegrationStore?.brands;

  const [organizationID, setOrganizationID] = useState('');
  const [brandID, setBrandID] = useState('');
  const [sendNotToOrgaUsers, setSendNotToOrgaUsers] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [organizationID, sendNotToOrgaUsers, brandID]);

  const initialize = async () => {
    zendeskIntegrationStore!.setIntegrationID(integrationID);
    await zendeskIntegrationStore!.getOrganizations();
    await zendeskIntegrationStore!.getBrands();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.ZENDESK,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.zendesk[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);

    setSendNotToOrgaUsers(integrationInfo.sendNotToOrgaUsers ?? false);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      organizationID,
      sendNotToOrgaUsers,
      brandID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Brand (optional)"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={brands.find(
          (element) => element.id === currentIntegrationInfo?.brandID,
        )}
        items={brands}
        onChange={(option) => {
          setBrandID(option.id);
        }}
      />
      <SelectDropDown
        className="mb-15"
        label="Organization (optional)"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={organizations.find(
          (element) => element.id === currentIntegrationInfo?.organizationID,
        )}
        items={organizations}
        onChange={(option) => {
          setOrganizationID(option.id);
        }}
      />
      {organizationID && organizationID !== '' && (
        <div className="switch-container">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              setSendNotToOrgaUsers(checked);
            }}
            checked={sendNotToOrgaUsers ? true : false}
          />
          <span>Don't send tickets from Zendesk organization users.</span>
        </div>
      )}
    </div>
  );
};

export default inject(
  'zendeskIntegrationStore',
  'projectStore',
)(observer(ZendeskConfiguration));
