import React, { useEffect, useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import './LanguageDropdown.scss';

interface LanguageDropdownProps {
  items: any[];
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void;
  selectedItem?: any;
  placeholder?: string;
  disabled?: boolean;
  labelPropertyName?: string;
  valuePropertyName?: string;
  className?: any;
  isLoading?: boolean;
  getOptionlabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
}

const LanguageDropdown = ({
  items,
  onChange,
  selectedItem,
  placeholder,
  disabled,
  labelPropertyName = 'name',
  valuePropertyName = 'code',
  className,
  isLoading,
  getOptionlabel = (option) => option[labelPropertyName],
  getOptionValue = (option) => option[valuePropertyName],
}: LanguageDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    if (!items) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if ('options' in item) {
        delete item.options;
      }
    }
  }, [items]);

  useEffect(() => {
    if (selectedItem && selectedItem !== null && selectedItem !== '') {
      onChange(selectedItem);
    }
  }, [selectedItem]);

  return (
    <Select
      isLoading={isLoading}
      getOptionLabel={getOptionlabel}
      getOptionValue={getOptionValue}
      className="language-select"
      classNamePrefix="language-selection"
      value={selectedValue ?? selectedItem}
      onChange={(val) => {
        setSelectedValue(val);
        onChange(val);
      }}
      isSearchable={false}
      options={items}
      placeholder={placeholder}
      isDisabled={disabled}
    />
  );
};

export default LanguageDropdown;
