import Loading from 'components/Loading/Loading';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AIFeedbackSummary.scss';

interface AIFeedbackSummaryProps {
    projectStore?: ProjectStore;
    organisationStore?: OrganisationStore;
}

const AIFeedbackSummary = ({ projectStore, organisationStore }: AIFeedbackSummaryProps) => {
    const navigate = useNavigate();

    const renderAIResponse = () => {
        if (projectStore?.loadingAiResponse) {
            return <div className="ai-loading-container ai-container">
                <div className='ai-tag ai-tag-topleft'>AI assist</div>
                <Loading />
            </div>;
        }


        if (projectStore?.aiPlanFailed) {
            return (<div className="ai-container">
                <div className='ai-tag ai-tag-topleft'>AI assist</div>
                <div className='ai-list-planerror'>
                    <div className='planerror-title'>New plan needed 🪄</div>
                    <div className='planerror-description'>AI assist is not supported in your legacy plan. Move to our new plans to unlock AI assist.</div>
                    <PrimaryButton onClick={() => {
                        navigate(
                            `/organization/${organisationStore?.currentOrganisation?.id}/billing`,
                        );
                    }} label='Update plan' />
                </div>
            </div>);
        }

        if (projectStore?.currentProject?.aiSummary || projectStore?.aiResponse?.summary) {
            const summary = projectStore?.aiResponse?.summary || projectStore?.currentProject?.aiSummary;
            return (
                <div className='ai-container'>
                    <div className='ai-tag ai-tag-topleft'>AI assist</div>
                    <div className='question-answer-title'>TL;DR</div>
                    <div className='question-answer'>
                        {summary}
                    </div>
                    <div className='regenerate-info'>
                        {(projectStore?.currentProject?.aiSummaryOutdated && !projectStore?.aiResponse?.summary) && <span className='regenerate-info-outdated'>This summary is outdated.</span>}
                        <span className='simple-link' onClick={() => {
                            projectStore!.summarizeFeedback();
                        }}>
                            <i className="fa-regular fa-rotate-right"></i>
                            Re-summarize?
                        </span>
                    </div>
                </div>
            );
        }

        return <div className='generate-summary ai-container'>
            <div className='ai-tag ai-tag-topleft'>AI assist</div>
            <div className='generate-summary-label'>Ready for some AI magic?</div>
            <div className='generate-summary-description'>Our AI assistant summarizes your feedback and outlines the most important bullet points for you.</div>
            <PrimaryButton label='Summarize feedback ✍️' onClick={() => {
                projectStore!.summarizeFeedback();
            }} />
        </div>;
    };

    return (
        <div className='ai-feedback-summary'>
            <div className='ai-feedback-summary-headline'>
                <div className='ai-feedback-summary-title'>Feedback analysis 🪄</div>
                <div className='ai-feedback-summary-subtitle'>Gain valuable insights from your customer feedback through our AI.</div>
            </div>
            {renderAIResponse()}
        </div>
    );
};

export default inject('projectStore', 'organisationStore')(observer(AIFeedbackSummary));
