import { NodeViewWrapper } from '@tiptap/react';
import classNames from 'classnames';
import React from 'react';
import './HelpcenterConversationArticle.scss';

interface HelpCenterArticleAttributes {
  articleId: string;
  articleTitle: string;
  articleDescription: string;
  articleUrl: string;
}

const HelpCenterArticle = ({
  node,
  updateAttributes,
  selected,
  editor,
}: any) => {
  const { articleId, articleTitle, articleDescription, articleUrl } =
    node.attrs as HelpCenterArticleAttributes;

  const helpcenterConversationClassName = classNames(
    {
      'helpcenter-conversation-article--selected': selected,
    },
    'helpcenter-conversation-article',
  );

  return (
    <NodeViewWrapper>
      <div
        className={helpcenterConversationClassName}
        onClick={() => {
          window.open(articleUrl, '_blank');
        }}
      >
        <div className='article-header-container'>
          <div className="article-header">{articleTitle}</div>
          <i className="fa-solid fa-arrow-up-right-from-square" />
        </div>
        <div className="article-description">{articleDescription}</div>
      </div>
    </NodeViewWrapper>
  );
};

export default HelpCenterArticle;
