import './ProjectCard.scss';
import { useNavigate } from 'react-router';
import { Project } from 'models/Project';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { Organisation } from 'models/Organisation';

interface ProjectCardProps {
  organization: Organisation;
  project: Project;
  projectStore?: ProjectStore;
}

const ProjectCard = ({ project, organization, projectStore }: ProjectCardProps) => {
  const navigate = useNavigate();
  const projectsUnreadCount = projectStore?.projectsUnreadCount;
  const unreadCount = projectsUnreadCount && projectsUnreadCount[project.id];

  return (
    <div
      className="project-card"
      onClick={() => {
        navigate(`/projects/${project.id}/`);
      }}
    >
      <div className="project-card-content">
        {unreadCount && <div className="notifications-badge">{unreadCount}</div>}
        <div className="project-card-content-picture">
          {!project.picture && (
            <div className="image-placeholder">
              {project.name && project.name.charAt(0).toLocaleUpperCase()}
            </div>
          )}
          {project.picture && (
            <img src={project.picture} alt="projpicture" />
          )}
        </div>
        <div className="project-card-content-content">
          <div className="title">{project.name}</div>
          <div className='memberscount'>{(project?.users?.length ?? 0) + (organization?.users?.length ?? 0)} Members</div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
)(observer(ProjectCard));
