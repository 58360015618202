import React, { useState, useEffect } from 'react';
import './ResendTimer.scss';
import moment from 'moment';
import { inject } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';

interface ResendTimerProps {
  usersStore?: UsersStore;
  timeGone: (isGone: boolean) => void;
}

const DURATION = 300;

const ResendTimer = ({ timeGone, usersStore }: ResendTimerProps) => {
  const [timer, setTimer]: any = useState();
  const [counter, setCounter] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const savedDate = localStorage.getItem('@verifyTime');
      let diff = 0;
      if (savedDate) {
        diff = moment(savedDate).diff(moment());
        setCounter(moment(diff).format('mm:ss'));
        if (diff <= 0) {
          timeGone(true);
          localStorage.removeItem('@verifyTime');
          clearInterval(timer);
        }
      } else {
        const countUntil = moment();
        countUntil.add(DURATION, 'seconds');
        localStorage.setItem('@verifyTime', countUntil.toLocaleString());
        diff = countUntil.diff(moment());
        setCounter(moment(diff).format('mm:ss'));
      }
    }, 1000);

    if (!timer) {
      setTimer(interval);
    }
    return () => clearInterval(timer);
  }, [timer, timeGone]);

  if (counter === '') {
    return <div></div>;
  }

  return (
    <div className="counter">
      <div>Retry in</div>
      {counter}
    </div>
  );
};

export default inject('usersStore')(ResendTimer);
