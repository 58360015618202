/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import './Filter.scss';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

interface FilterProps {
  value: string;
  onValueChange?: (selectedValue: string) => void;
  className?: string;
  options: { name: string; value: string; description?: string, isLoading?: boolean, unreadCount?: number }[];
  disabled?: boolean;
}

const Filter = ({
  value,
  onValueChange,
  className,
  options,
  disabled,
}: FilterProps) => {
  const renderButtons = () => {
    return options.map((option) => {
      const priorityButtonClassName = classNames('priority-button', {
        'priority-button--active': option.value === value,
      });
      return (
        <button
          key={option.value}
          type="button"
          onClick={() => {
            if (onValueChange && !disabled) {
              onValueChange(option.value);
            }
          }}
          className={priorityButtonClassName}
        >
          <span>
            {option.isLoading ? '--' : option.name}
            {option.unreadCount && option.unreadCount > 0 ? (
              <div className="item-unread-count"><div className='item-unread-count-dot' />{option.unreadCount} unread response{option.unreadCount === 1 ? '' : 's'}</div>
            ) : null}
          </span>
          {option.description && (
            <div className="item-description">{option.description}</div>
          )}
        </button>
      );
    });
  };

  return (
    <div
      className={`filter-selection ${className} ${disabled && 'filter-selection--disabled'
        }`}
    >
      {renderButtons()}
    </div>
  );
};

export default Filter;
