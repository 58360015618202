import Pusher from 'pusher-js/with-encryption';

const SHARED_WEBSOCKET_EVENTS = {
  BUG_TYPING: 'bug.typing',
  COMMENT_CREATED: 'comment.created',
};

Pusher.logToConsole = false;

class SharedWebSocketHelper {
  private static instance: SharedWebSocketHelper;
  private pusher?: Pusher;
  private listeners: any[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static addEventListener(onEvent: (event: string, data: any) => void) {
    this.getInstance().listeners.push(onEvent);
  }

  public static getInstance(): SharedWebSocketHelper {
    if (!SharedWebSocketHelper.instance) {
      SharedWebSocketHelper.instance = new SharedWebSocketHelper();
    }

    return SharedWebSocketHelper.instance;
  }

  public isConnected() {
    return this.pusher !== undefined;
  }

  public disconnect() {
    if (this.pusher) {
      this.pusher.disconnect();
      this.pusher = undefined;
    }
  }

  public connect = async (shareToken: string) => {
    if (this.pusher) {
      this.pusher.disconnect();
      this.pusher = undefined;
    }

    this.pusher = new Pusher('29b0a09928856b262405', {
      cluster: 'eu',
      userAuthentication: {
        endpoint: `${process.env.REACT_APP_BACKEND_URL}/projects/sharedpusher-auth`,
        transport: 'ajax',
        headers: {
          'share-token': shareToken,
        },
      },
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_BACKEND_URL}/projects/sharedpusher-channel`,
        transport: 'ajax',
        headers: {
          'share-token': shareToken,
        },
      },
    });

    const channel = this.pusher!.subscribe(
      `private-sharedticket-${shareToken}`,
    );
    const eventKeys = Object.keys(SHARED_WEBSOCKET_EVENTS);
    for (let i = 0; i < eventKeys.length; i++) {
      const eventValue = SHARED_WEBSOCKET_EVENTS[eventKeys[i]];
      channel.bind(eventValue, (data) => {
        for (let j = 0; j < this.listeners.length; j++) {
          if (this.listeners[j]) {
            this.listeners[j](eventValue, data);
          }
        }
      });
    }
  };
}

export { SHARED_WEBSOCKET_EVENTS };

export default SharedWebSocketHelper;
