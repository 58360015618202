import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { TextArea } from 'components/TextArea/TextArea';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './CreateFeatureRequestModal.scss';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import TextInput from 'components/TextInput/TextInput';
import { ProjectStore } from 'stores/private/ProjectStore';
import SessionRecipientPicker from 'components/SessionRecipientPicker/SessionRecipientPicker';

interface CreateFeatureRequestModalProps {
  bugStore?: BugStore;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const CreateFeatureRequestModal = ({
  bugStore,
  modalStore,
  projectStore,
}: CreateFeatureRequestModalProps) => {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });

  const [session, setSession] = useState(undefined as any);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any) => {
    const { description, title } = data;
    if (
      !description ||
      !title ||
      description.length === 0 ||
      title.length === 0
    ) {
      return;
    }

    setIsLoading(true);
    await bugStore?.createBug({
      formData: {
        title,
        description,
      },
      status: modalStore?.customData.status ?? 'OPEN',
      type: projectStore?.currentFeedbackType?.type,
      session: session,
    });
    modalStore!.closeModal();
    setIsLoading(false);
  };

  return (
    <SizedContainer size={ContainerSizes.L}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="manually-create-bug centered"
      >
        <HeadLine title="Add a feature request" />
        <TextInput
          inputRef={register('title', {
            required: true,
          })}
          name="title"
          placeholder="Describe the feature request"
          className="mb-15 mt-20"
          required
          error={formState.errors.title ? 'The title is required.' : ''}
          initalValue=""
          label="Title"
        />
        <TextArea
          inputRef={register('description', {
            required: true,
          })}
          name="description"
          placeholder="Add more details"
          className="mb-15"
          rows={5}
          error={
            formState.errors.description ? 'The description is required.' : ''
          }
          value=""
          required
          label="Description"
          readonly={false}
        />
        <SessionRecipientPicker
          placeholder="Assign reporter (optional)"
          session={session}
          onChange={(selectedSession) => {
            setSession(selectedSession);
          }}
        />
        <PrimaryButton
          disabled={!formState.isValid}
          submit
          label="Create"
          isLoading={isLoading}
          onClick={() => {}}
        />
      </form>
    </SizedContainer>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
)(observer(CreateFeatureRequestModal));
