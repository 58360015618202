import NoBotYet from 'assets/NoBotYet.png';
import { HeadLine } from 'components/HeadLine/HeadLine';
import ListTable, { CellLinkInternal, CellTag, CellTextIcon } from 'components/ListTable/ListTable';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { iconList } from 'components/UserTypeDropDown/UserTypeDropDown';
import { inject, observer } from 'mobx-react';
import { botTriggerOptions } from 'pages/private/BotConfigurationPage/FlowChart';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CustomBots.scss';

interface CustomBotsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const CustomBots = ({ projectStore, modalStore }: CustomBotsProps) => {
  const { projectId } = useParams();
  const [creatingBot, setCreatingBot] = useState(false);
  const currentProject = projectStore?.currentProject;

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getBots(currentProject.id);
    }
  }, [currentProject]);

  const columns = useMemo(
    () => [
      {
        width: '50%',
        Header: 'Name',
        accessor: (row) => row,
        Cell: (row) => (
          <CellLinkInternal link={`/projects/${projectStore?.currentProject?.id}/bots/custombots/${row.value.id}/`} text={row.value.name} />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '25%',
        Header: 'Trigger',
        accessor: 'trigger',
        Cell: (row) => {
          const trigger = botTriggerOptions.find((option) => option.value === row.value) ?? botTriggerOptions[0];
          return (
            <CellTextIcon
              icon={`fa-solid ${iconList[trigger.value]}`}
              text={trigger.name}
            />);
        },
      },
      {
        width: '25%',
        Header: 'Status',
        accessor: 'status',
        Cell: (row) => {
          if (row.value === 'live') {
            return <CellTag className="cell-tag--green" text="Live" />;
          }

          return <CellTag className="cell-tag--gray" text="Draft" />;
        },
      },
    ],
    [],
  );

  if (!currentProject) {
    return <></>;
  }

  const renderBotsList = () => {
    if (projectStore.loadingBots) {
      return <div className='loading mt-100'>
        <LoadingAnimationMedium />
      </div>;
    }

    if (projectStore?.bots?.length === 0) {
      return (
        <div className="no-surveys-container">
          <img
            src={NoBotYet}
            alt="Custom bots"
            className="no-surveys-image"
          />
          <>
            <HeadLine
              className="mt-20"
              title="Custom bots"
              subtitle="Custom bots are a powerful tool to automate your customer support. Get started by creating your first custom bot now."
            />
            <PrimaryButton
              onClick={async () => {
                setCreatingBot(true);
                if (projectId) {
                  await projectStore.createBot(projectId);
                }
                setCreatingBot(false);
              }}
              isLoading={creatingBot}
              icon='plus'
              label="Create bot"
            />
          </>
        </div>
      );
    }

    return (
      <ListTable data={projectStore?.bots ?? []} columns={columns} />
    );
  }

  return (
    <PageContainer className="custom-bots">
      <PageHeadLine title="Custom bots" tag='BETA'>
        <PrimaryButton
          onClick={async () => {
            setCreatingBot(true);
            if (projectId) {
              await projectStore.createBot(projectId);
            }
            setCreatingBot(false);
          }}
          isLoading={creatingBot}
          className="save-button"
          icon='plus'
          label="Create bot"
        />
      </PageHeadLine>
      <PageContent hasTitle isLargeBoxed>
        {renderBotsList()}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore', 'modalStore')(observer(CustomBots));
