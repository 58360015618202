import { makeAutoObservable } from 'mobx';

// eslint-disable-next-line import/prefer-default-export
export class SidenavStore {
  sidenavHidden = false;
  mainSidenavHidden = false;

  constructor() {
    makeAutoObservable(this);
  }
}
