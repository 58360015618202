import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './EditInboxView.scss';
import TextInput from 'components/TextInput/TextInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

interface EditInboxViewProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const EditInboxView = ({
  modalStore,
  projectStore,
}: EditInboxViewProps) => {
  const answerObj = modalStore?.customData?.answer ?? {};
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [selectedEmoji, setSelectedEmoji] = useState<string>("🚀");
  const [title, setTitle] = useState<string>("");

  return (
    <SizedContainer size={ContainerSizes.L} className="edit-inbox-view-modal">
      <div className="input-label mb-20">
        Create new inbox view
      </div>
      <div className='emoji-title'>
        <div className='emoji-picker-container' onClick={() => {
          setShowEmojiPicker(!showEmojiPicker);
        }}>
          <div className='emoji-picker-inner'>
            {selectedEmoji}
          </div>
          {showEmojiPicker && (<div className='emoji-picker-cont'>
            <EmojiPicker lazyLoadEmojis skinTonesDisabled emojiStyle={EmojiStyle.NATIVE} onEmojiClick={(emoji) => {
              setSelectedEmoji(emoji.emoji);
              setShowEmojiPicker(false);
            }} />
          </div>)}
        </div>
        <TextInput
          name="question"
          placeholder="View name"
          type="text"
          className="mb-20"
          error=""
          value={title ?? ''}
          onChange={(text) => {
            setTitle(text);
          }}
        />
      </div>
      <div className="input-label mb-20 mt-20">
        Filters
      </div>
      <div>

      </div>
      <div>
        <PrimaryButton label='Save' onClick={() => {
          
        }} />
      </div>
    </SizedContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(EditInboxView));
