import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getGitlabProjects } from 'services/integrations/GitlabHttpService';
import { getRepositories } from '../../services/integrations/GithubHttpService';

export class GitlabIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  projects: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setProjects = (projects) => {
    this.projects = projects;
  };

  getProjects = async () => {
    try {
      const response = await getGitlabProjects({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setProjects(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
