import AISummary from 'components/AISummary/AISummary';
import FeedbackActionSummaryItem from 'components/FeedbackActionSummaryItem/FeedbackActionSummaryItem';
import Filter from 'components/Filter/Filter';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellGestureDetector,
  CellLink,
  CellSession,
  CellText,
} from 'components/ListTable/ListTable';
import ListTableVirtualized from 'components/ListTableVirtualized/ListTableVirtualized';
import Loading from 'components/Loading/Loading';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import OutboundMessagePreview from 'components/OutboundMessagePreview/OutboundMessagePreview';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import StatCard from 'components/StatCard/StatCard';
import { Title } from 'components/Title/Title';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import TimeAgo from 'react-timeago';
import './ProjectOutboundDetails.scss';
import Swal from 'sweetalert2';

interface ProjectOutboundDetailsProps {
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  modalStore?: ModalStore;
  sidenavStore?: SidenavStore;
}

function ProjectOutboundDetails({
  projectStore,
  outboundStore,
  modalStore,
  sidenavStore,
}: ProjectOutboundDetailsProps) {
  const navigate = useNavigate();
  const { projectId, outboundId, shareToken } = useParams();
  const outboundRule = outboundStore?.outboundRule;
  const outboundRecipients = outboundStore?.outboundRecipientsDataList.data;
  const project = projectStore?.currentProject;
  const [currentPage, setCurrentPage] = useState('summary');
  const [exportInProgress, setExportInProgress] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        width: '20%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellSession
            session={row.value.session}
            url={`/projects/${projectId}/sessions/${row.value.session?.id}`}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '25%',
        Header: 'Email',
        accessor: (row) => row,
        Cell: (row) => (
          <CellLink
            link={`mailto:${row.value.session?.email}`}
            text={row.value.session?.email}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '20%',
        Header: 'Sent at',
        accessor: (row) => row,
        Cell: (row) => <TimeAgo date={row.value.sendAt} />,
      },
    ],
    [],
  );

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject && outboundId) {
      outboundStore!.loadOutboundRule(outboundId);
      outboundStore!.loadOutboundRuleResponses(outboundId);
      outboundStore!.loadOutboundRuleStats(outboundId);
      outboundStore!.getOutboundRecipients({ outboundId });
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    if (shareToken) {
      setCurrentPage('responses');
      projectStore!.openFeedbackItem({ shareToken });
    }
  }, [project]);

  const handleScroll = (e) => {
    if (currentPage !== 'recipients') {
      return;
    }

    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
      ) < 1;
    if (bottom) {
      outboundStore?.getOutboundRecipients({
        outboundId: outboundId!,
        loadMore: true,
      });
    }
  };

  if (!outboundRule || outboundStore?.loadingOutboundRule) {
    return (
      <PageContainer>
        <PageHeadLine
          title="..."
          onActionBack={() => {
            navigate(`/projects/${projectId}/outbound`);
          }}
        />
        <PageContent hasTitle isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const buildDetailsSummary = () => {
    return (
      <>
        <div className="survey-stats">
          {outboundStore.outboundRuleStats &&
            Object.keys(outboundStore.outboundRuleStats).map((key) => {
              return (
                <StatCard
                  key={key}
                  className="mb-20"
                  icon="square-plus"
                  label="Sent"
                  hideChange
                  valueSubLabel="surveys sent"
                  value={outboundStore.outboundRuleStats[key] ?? 0}
                />
              );
            })}
          <StatCard
            key={'responsesCount'}
            className="mb-20"
            icon="square-plus"
            label="Responses"
            hideChange
            isLoading={outboundStore.loadingOutboundRuleResponses}
            loadingLabel="Loading responses..."
            valueSubLabel="responses"
            value={outboundStore?.outboundRuleResponses.length ?? 0}
          />
        </div>
        <AISummary outboundId={outboundId!} />
        {project?.projectActions[outboundRule.actionType]?.form.map(
          (element, index) => (
            <FeedbackActionSummaryItem
              fullForm={project?.projectActions[outboundRule.actionType]?.form}
              key={element.name}
              formItem={element}
              stats={outboundStore?.outboundRuleResponses}
            />
          ),
        )}
      </>
    );
  };

  const buildDetailsResponses = () => {
    return (
      <div className="responses-tab">
        <div className="responses-header">
          <Title label="Responses" />
          <LinkButton
            isLoading={outboundStore?.loadingOutboundRuleResponsesUnreadUpdate}
            onClick={() => {
              outboundStore?.markOutboundRuleResponsesAsRead(outboundId!);
            }}
            label="Mark all as read"
          />
        </div>
        {outboundStore?.loadingOutboundRuleResponses ? (
          <div className="responses-loading">
            <LoadingAnimation />
          </div>
        ) : (
          <div className="responses-list">
            <ListTableVirtualized
              renderCell={(data, index) => {
                return (
                  <div className="outbound-response">
                    <div className="outbound-response-main">
                      <CellGestureDetector
                        label="View response"
                        unread={data.notificationsUnread}
                        onClick={() => {
                          projectStore!.openFeedbackItem({
                            shareToken: data.shareToken,
                          });
                        }}
                      />
                      {data.session && (
                        <CellSession
                          session={data.session}
                          url={`/projects/${projectId}/sessions/${data.session?.id}`}
                        />
                      )}
                    </div>
                    <CellText
                      className="outbound-response-date"
                      text={moment(data.createdAt).format('LLL')}
                    />
                  </div>
                );
              }}
              data={outboundStore?.outboundRuleResponses}
            />
          </div>
        )}
      </div>
    );
  };

  const _buildOutboundRecipients = () => {
    return <ListTable data={outboundRecipients} columns={columns} />;
  };

  const getSummeryForType = () => {
    if (outboundRule.type === 'SURVEY') {
      return buildDetailsSummary();
    }
    if (outboundRule.type === 'MESSAGE') {
      return buildMessageSummary();
    }
    if (outboundRule.type === 'EMAIL') {
      return buildEmailSummary();
    }

    return <></>;
  };

  const buildOverview = () => {
    const options: any = [
      { name: 'Summary', description: 'Overview', value: 'summary' },
      {
        name: 'Recipients',
        description: 'People',
        value: 'recipients',
      },
    ];

    if (outboundRule.type === 'SURVEY') {
      options.push({
        name: `${outboundStore?.outboundRuleResponses?.length ?? 0}`,
        description: 'Responses',
        value: 'responses',
        unreadCount: outboundStore?.unreadResponses,
        isLoading: outboundStore?.loadingOutboundRuleResponses,
      });
    }

    return (
      <>
        <div className="main-tabs">
          <Filter
            onValueChange={(value) => {
              setCurrentPage(value);
            }}
            value={currentPage}
            options={options}
          />
        </div>
        {currentPage === 'summary' && getSummeryForType()}
        {currentPage === 'recipients' && _buildOutboundRecipients()}
        {currentPage === 'responses' && buildDetailsResponses()}
      </>
    );
  };

  const buildMessageSummary = () => {
    return (
      <div className="outbound-message-overview">
        <div className="message-preview">
          <OutboundMessagePreview outbound={outboundRule} />
        </div>
        <div className="message-stats">
          {outboundStore.outboundRuleStats &&
            Object.keys(outboundStore.outboundRuleStats).map((key) => {
              return (
                <StatCard
                  key={key}
                  icon="square-plus"
                  label="Sent messages"
                  hideChange
                  valueSubLabel="messages sent"
                  value={outboundStore.outboundRuleStats[key] ?? 0}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const buildEmailSummary = () => {
    return (
      <div className="outbound-email-overview">
        <div className="message-preview">
          <OutboundMessagePreview outbound={outboundRule} />
        </div>
        <div className="message-stats">
          {outboundStore.outboundRuleStats &&
            Object.keys(outboundStore.outboundRuleStats).map((key) => {
              return (
                <StatCard
                  key={key}
                  icon="square-plus"
                  label="Sent emails"
                  hideChange
                  valueSubLabel="emails sent"
                  value={outboundStore.outboundRuleStats[key] ?? 0}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const renderStatusBadge = () => {
    if (outboundRule.status === 'live' && outboundRule.frequencyType === "fixed") {
      return (<div className="outbound-status-tag outbound-status-tag--primary">
        Sent
      </div>);
    }

    if (outboundRule.status === 'live' && outboundRule.frequencyType === "dynamic") {
      return (<div className="outbound-status-tag outbound-status-tag--green">
        Live
      </div>);
    }

    return (
      <div className="outbound-status-tag">Draft</div>
    );
  }

  const canEdit = () => {
    if (outboundRule.status === 'live' && outboundRule.frequencyType === "fixed") {
      return false;
    }
    return true;
  }

  return (
    <PageContainer>
      <PageHeadLine
        title={outboundRule.name}
        onActionBack={() => {
          navigate(`/projects/${projectId}/outbound`);
        }}
      >
        <div>
          {renderStatusBadge()}
        </div>
        <div className="header-content-right">
          {outboundRule.type === 'SURVEY' && (
            <>
              <LinkButton
                className="hide-on-mobile mr-10"
                isLoading={exportInProgress}
                onClick={async () => {
                  setExportInProgress(true);
                  await outboundStore!.exportOutboundResponses(outboundId);
                  setExportInProgress(false);
                }}
                icon="cloud-arrow-down"
                iconSideRight={false}
                label="Export responses"
              />
              <LinkButton
                icon="circle-play"
                iconSideRight={false}
                className="hide-on-mobile"
                onClick={() => {
                  modalStore?.openModal(MODALTYPE.SURVEY_PREVIEW, {
                    actionType: outboundRule.actionType,
                    outboundId: outboundRule.id,
                    format: outboundRule.format,
                  });
                }}
                label="Preview survey"
              />
            </>
          )}
          <PrimaryButton
            className="save-button ml-10 hide-on-mobile"
            onClick={() => {
              if (!canEdit()) {
                Swal.fire({
                  text: 'You can not edit an outbound message with a fixed audience that has been sent already.',
                  showCancelButton: false,
                  confirmButtonText: `Ok`,
                });
                return;
              }
              navigate(`/projects/${projectId}/outbound/${outboundId}/edit`);
            }}
            label="Edit"
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle isBoxed onScroll={handleScroll}>
        <div
          className={`outbounddetails-container ${currentPage === 'responses' &&
            'outbounddetails-container--responses'
            }`}
        >
          {buildOverview()}
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'outboundStore',
  'modalStore',
  'sidenavStore',
)(observer(ProjectOutboundDetails));
