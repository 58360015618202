import './FeedbackItem.scss';
import TimeAgo from 'react-timeago';
import { Bug } from 'models/Bug';
import { generateHTML } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import TextStyle from '@tiptap/extension-text-style';
import { inject, observer } from 'mobx-react';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getDataDescription } from 'helper/FormDataHelper';
import { stripHtml } from 'components/InboxItem/InboxItem';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { CustomLink } from 'components/Editors/RichTextEditor/extensions/CustomLink';
import { getSessionName } from 'services/GuestNameHelper';
import Youtube from '@tiptap/extension-youtube';
import Iframe from 'services/Iframe';

interface FeedbackItemProps {
  feedbackItem: Bug;
  projectStore?: ProjectStore;
}

const FeedbackItem = ({ feedbackItem, projectStore }: FeedbackItemProps) => {
  const getDescription = () => {
    var prefix = '';

    if (
      feedbackItem.latestComment?.type === 'NOTE' ||
      feedbackItem.latestComment?.type === 'TEXT'
    ) {
      prefix = 'Team: ';
    }

    if (feedbackItem.latestComment?.data && feedbackItem.latestComment?.data.text) {
      return prefix + feedbackItem.latestComment?.data.text;
    }

    if (feedbackItem.latestComment?.data?.content) {
      if (typeof feedbackItem.latestComment?.data?.content === 'string') {
        return prefix + stripHtml(feedbackItem.latestComment?.data?.content);
      } else {
        try {
          const html = generateHTML(feedbackItem.latestComment?.data?.content, [
            StarterKit.configure({ codeBlock: {}, heading: false }),
            Heading.configure({
              levels: [1, 2, 3],
            }),
            CustomLink,
            Mention,
            Placeholder,
            TextStyle,
            Youtube.configure({ controls: true }),
            Iframe,
          ]);
          return prefix + stripHtml(html);
        } catch (exp) { }
      }
    }

    if (feedbackItem.form?.description?.value) {
      return feedbackItem.form?.description?.value;
    }

    return getDataDescription({ data: feedbackItem, maxLength: 100 });
  };

  return (
    <div
      className="feedback-item card"
      onClick={() => {
        projectStore!.openFeedbackItem({ shareToken: feedbackItem.shareToken });
      }}
    >
      <div className="feedback-item-header">
        <div className="feedback-item-header-user">
          <UserAvatar
            email={
              feedbackItem.session?.email
                ? feedbackItem.session?.email
                : feedbackItem.session?.gleapId
            }
          />{' '}
          <span className="capitalize">
            {cleanupName(getSessionName(feedbackItem.session), 20)}
          </span>
        </div>
        <TimeAgo date={feedbackItem.createdAt} />
      </div>
      <div className="feedback-item-body">
        {getDescription()}
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(FeedbackItem));
