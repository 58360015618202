import React from 'react';
import './UpdateProjectDataExport.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import LinkButton from 'components/LinkButton/LinkButton';

interface UpdateProjectDataExportProps {
  projectStore?: ProjectStore;
}

const UpdateProjectDataExport = ({
  projectStore,
}: UpdateProjectDataExportProps) => {
  const project = projectStore!.currentProject;

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Data export" />
      <PageContent hasTitle isMediumBoxed>
        <div className="options-group">
          <div className="options-group-header">Data export</div>
          <div className="danger-zone">
            <div className="danger-zone-container">
              <div className="danger-zone-item">
                <div className="danger-zone-label">Export all feedback items</div>
                <LinkButton
                  label="Export"
                  onClick={() => {
                    projectStore!.downloadBugsAsJSON();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(UpdateProjectDataExport));
