import ProjectInstallation from 'components/ProjectInstallation/ProjectInstallation';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import UpdateProject from 'components/UpdateProject/UpdateProject';
import UpdateProjectDangerZone from 'components/UpdateProjectDangerZone/UpdateProjectDangerZone';
import UpdateProjectDataExport from 'components/UpdateProjectDataExport/UpdateProjectDataExport';
import UpdateProjectNotifications from 'components/UpdateProjectNotifications/UpdateProjectNotifications';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import ProjectTeam from '../ProjectTeam/ProjectTeam';
import './ProjectSettings.scss';

import AIAssistProjectSettings from 'components/AIAssistProjectSettings/AIAssistProjectSettings';
import ProjectApiToken from 'components/ProjectApiToken/ProjectApiToken';
import ProjectMessageTemplates from 'components/ProjectMessageTemplates/ProjectMessageTemplates';
import ProjectSecurity from 'components/ProjectSecurity/ProjectSecurity';
import ProjectSpamProtection from 'components/ProjectSpamProtection/ProjectSpamProtection';
import PushNotificationSettings from 'components/PushNotificationSettings/PushNotificationSettings';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import UpdateProjectSharing from 'components/UpdateProjectSharing/UpdateProjectSharing';
import { SidenavStore } from 'stores/private/SidenavStore';
import { ReactComponent as KeyIcon } from '../../../assets/icons/np_library_3018670_FFFFFF.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import DomainVerification from '../Organisation/DomainVerification/DomainVerification';
import DeveloperOptionsWidgetSettings from '../ProjectWidget/DeveloperOptionsWidgetSettings/DeveloperOptionsWidgetSettings';

interface ProjectSettingsProps {
  projectStore?: ProjectStore;
  sidenavStore?: SidenavStore;
}

function ProjectSettings({ projectStore, sidenavStore }: ProjectSettingsProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  if (!currentProject) {
    return <></>;
  }

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[4] === path) {
        return true;
      }

      if (path === '' && parts.length === 4 && parts[3] === 'settings') {
        return true;
      }
    }

    return false;
  };

  return (
    <SidenavContainer sidenavHidden={sidenavStore?.sidenavHidden}>
      <SubSidenav title="Settings">
        <SimpleSidenavElement
          name="General"
          onClick={() => {
            navigate(`/projects/${projectId}/settings`);
          }}
          Icon={SettingsIcon}
          isActive={isActive('')}
        />
        <SimpleSidenavElement
          name="Installation"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/installation`);
          }}
          Icon={KeyIcon}
          isActive={isActive('installation')}
        />
        <SimpleSidenavElement
          name="AI assist 🪄"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/aiassist`);
          }}
          faIcon="sparkles"
          isActive={isActive('aiassist')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="User identity"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/security`);
          }}
          faIcon='id-badge'
          isActive={isActive('security')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Spam protection"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/spamprotection`);
          }}
          faIcon='spaghetti-monster-flying'
          isActive={isActive('spamprotection')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Push notifications"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/pushnotifications`);
          }}
          faIcon='bell'
          isActive={isActive('pushnotifications')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Message templates"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/messagetemplates`);
          }}
          faIcon='copy'
          isActive={isActive('messagetemplates')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Project team"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/team`);
          }}
          faIcon='users'
          isActive={isActive('team')}
        />
        <SimpleSidenavElement
          name="Developer options"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/developer`);
          }}
          faIcon='code'
          isActive={isActive('developer')}
        />
        <SimpleSidenavElement
          name="Domain verification"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/domainverification`);
          }}
          faIcon='at'
          isActive={isActive('domainverification')}
        />
        <SimpleSidenavElement
          name="Email settings"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/emailsettings`);
          }}
          faIcon='envelope'
          isActive={isActive('emailsettings')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Sharing settings"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/sharing`);
          }}
          faIcon='share-all'
          isActive={isActive('sharing')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Secret API token"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/apitoken`);
          }}
          faIcon='key'
          isActive={isActive('apitoken')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Data export"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/dataexport`);
          }}
          faIcon='file-export'
          isActive={isActive('dataexport')}
        />
        <SimpleSidenavElement
          name="Danger zone"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/dangerzone`);
          }}
          faIcon='triangle-exclamation'
          isActive={isActive('dangerzone')}
          visible={projectStore?.isProjectAdmin}
        />
      </SubSidenav>
      <Routes>
        <Route path="/" element={<UpdateProject />} />
        <Route path="/sharing" element={<UpdateProjectSharing />} />
        <Route path="/aiassist" element={<AIAssistProjectSettings />} />
        <Route path="/emailsettings" element={<UpdateProjectNotifications />} />
        <Route
          path="/pushnotifications"
          element={<PushNotificationSettings />}
        />
        <Route
          path="/developer"
          element={<DeveloperOptionsWidgetSettings />}
        />
        <Route path="/dataexport" element={<UpdateProjectDataExport />} />
        <Route path="/dangerzone" element={<UpdateProjectDangerZone />} />
        <Route path="/installation" element={<ProjectInstallation />} />
        <Route path="/security" element={<ProjectSecurity />} />
        <Route path="/apitoken" element={<ProjectApiToken />} />
        <Route path="/messagetemplates" element={<ProjectMessageTemplates />} />
        <Route path="/spamprotection" element={<ProjectSpamProtection />} />
        <Route path="/team" element={<ProjectTeam />} />
        <Route path="/domainverification" element={<DomainVerification />} />
      </Routes>
    </SidenavContainer>
  );
}

export default inject(
  'projectStore',
  'sidenavStore',
)(observer(ProjectSettings));
