import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getSlackChannels } from 'services/integrations/SlackHttpService';

export class SlackIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  channels: any = [];
  loadingChannels = false;

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setChannels = (channels) => {
    this.channels = channels;
  };

  setChannelsLoading = (loading) => {
    this.loadingChannels = loading;
  };

  getChannels = async () => {
    try {
      this.setChannelsLoading(true);
      const response = await getSlackChannels({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setChannels(response.data);
      }
      this.setChannelsLoading(false);
    } catch (err: any) {
      this.setChannelsLoading(false);
      toast.error('Could not get channels!');
    }
  };
}
