import { Editor } from '@tiptap/react';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useRef, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import './MessageActionEditor.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';

interface MessageActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const MessageActionEditor = ({
  action,
  modalStore,
  projectStore,
}: MessageActionEditorProps) => {
  const editorRef: MutableRefObject<Editor | null> = useRef(null);
  const [toggleState, setToggleState] = useState(false);

  const currentLang = projectStore?.currentLanguage ?? 'en';

  return (
    <div className="edit-feature-request-modal">
      <PageContainer>
        <PageHeadLine title="Edit chat message">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="bot-message-editor">
            <div className="toolbar-wrapper">
              <Toolbar
                editor={editorRef?.current}
                aiStyle="agent"
                items={[
                  ToolbarItems.Basic,
                  ToolbarItems.Advanced,
                  ToolbarItems.Embedded,
                  ToolbarItems.AI,
                ]}
              />
            </div>
            <div className="editor-wrapper">
              <EmailEditor
                editorRef={editorRef}
                onEditorReady={() => {
                  setToggleState(!toggleState);
                }}
                placeholder="Write your chat message..."
                content={getProperty(
                  action,
                  `data.content.localized.${currentLang}`,
                )}
                inputContentChanged={(content) => {
                  runInAction(() => {
                    setProperty(
                      action,
                      `data.content.localized.${currentLang}`,
                      content,
                    );
                  });
                }}
              />
            </div>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(MessageActionEditor));
