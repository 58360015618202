import classNames from 'classnames';
import Loading from 'components/Loading/Loading';
import LogButton from 'components/LogButton/LogButton';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  AutoSizer, CellMeasurer,
  CellMeasurerCache, List
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import { BugStore } from 'stores/private/BugStore';
import clickIcon from '../../../assets/icons/click.svg';
import closeIcon from '../../../assets/icons/close.svg';
import errorIcon from '../../../assets/icons/error.svg';
import logsIcon from '../../../assets/icons/logs.svg';
import networkFilter from '../../../assets/icons/networkFilter.svg';
import eventIcon from '../../../assets/icons/np_actions_2010719_000000.svg';
import downloadIcon from '../../../assets/icons/np_download_3884758_000000.svg';
import goalIcon from '../../../assets/icons/np_goal_3655947_5AC49A.svg';
import suggestSubscriptionIcon from '../../../assets/icons/rectangle-terminal-solid.svg';
import warningIcon from '../../../assets/icons/warning.svg';
import EventLogItem from '../EventLog/EventLogItem';
import NetworkLogItem, {
  getNetworkLogIconForType,
  getNetworkLogType
} from '../NetworkLog/NetworkLogItem';
import './ActivityTimeline.scss';

interface ActivityTimelineProps {
  bugStore?: BugStore;
  showAfterDate?: Date;
}

export const getNetworkLogStatusText = (log) => {
  if (log.initiatorType) {
    return log.initiatorType.toUpperCase();
  }

  return `${log.response?.status ?? 'ERR'} ${log.type ?? '--'}`;
}

const ActivityTimeline = ({
  bugStore,
  showAfterDate,
}: ActivityTimelineProps) => {
  const bug = bugStore?.currentBug;
  const [loading, setLoading] = useState(true);
  const [openedLog, setOpenedLog] = useState(null as any);
  const [filterLogs, setFilterLogs] = useState([
    'WARNING',
    'ERROR',
    'INFO',
    'CLICK',
    'NETWORK',
    'EVENTS',
  ] as string[]);
  const [filter, setFilter] = useState('');
  const [logs, setLogs]: any = useState([]);
  const planIsEntitled = bugStore?.isFeatureInPlan(Feature.CONSOLE_LOG);

  const darkMode = localStorage.getItem('theme') === 'dark';

  useEffect(() => {
    const bugLogs = bugStore?.activityLog;
    setLogs(bugLogs);
    setLoading(false);
  }, [bugStore?.activityLog]);

  useEffect(() => {
    setLoading(true);

    if (bug?.shareToken && planIsEntitled) {
      bugStore!.getActivityLog(bug?.shareToken);
    }
  }, [bug]);

  if (!planIsEntitled) {
    return (
      <div
        className={classNames(
          {
            'activity-log-container--log-bg--light': !darkMode,
            'activity-log-container--log-bg--dark': darkMode,
          },
          'activity-log-container activity-log-container--log-bg',
        )}
      >
        <SuggestSubscription
          icon={suggestSubscriptionIcon}
          title="Console & network logs"
          description="Unlock console & network logs with Gleap Team to fix bugs even faster for $109/month."
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="activity-log-container activity-log-container--no-contents text">
        <Loading />
      </div>
    );
  }

  if (!logs || !bug || !Array.isArray(logs) || logs.length === 0) {
    return (
      <div className="activity-log-container activity-log-container--no-contents text">
        There are no activity logs available for this ticket.
      </div>
    );
  }

  const formatDate = (date: string) => {
    const formatedDate = moment(date).format('HH:mm:ss');
    return formatedDate;
  };

  const infoLogs: any[] = [];

  const filteredLogs = [
    ...infoLogs,
    ...logs.filter((log) => {
      if (showAfterDate) {
        if (moment(log.date).isAfter(showAfterDate)) {
          return false;
        }
      }

      if (log.t === 'l' && log.priority && !filterLogs.includes(log.priority)) {
        return false;
      }

      if (log.t === 'n' && !filterLogs.includes('NETWORK')) {
        return false;
      }

      if (log.t === 'e' && !filterLogs.includes('EVENTS')) {
        return false;
      }

      if (filter === '') {
        return true;
      }

      return (
        log && JSON.stringify(log).toLowerCase().includes(filter.toLowerCase())
      );
    }),
  ];

  const cache = new CellMeasurerCache({
    defaultHeight: 36,
    fixedWidth: true,
  });

  const renderSystemEventLogItem = (event, key, style) => {
    const logClassName = classNames('timeline-item');

    return (
      <div className={logClassName} key={key} style={style}>
        <div className="timeline-count">
          <img src={goalIcon} alt="system event log" />
          {formatDate(event.date)}
        </div>
        <div className="timeline-data">
          <div className="event-data">
            <span className="event-data-name">{event.name}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderEventLogItem = (event, key, style) => {
    const logClassName = classNames('timeline-item');

    return (
      <div className={logClassName} key={key} style={style}>
        <div className="timeline-count">
          <img src={eventIcon} className="reversed-img" alt="event log" />
          {formatDate(event.date)}
        </div>
        <div className="timeline-data">
          <div className="event-data">
            <span className="event-data-name">{event.name} {event.name === "pageView" && event.data?.page && <a href={event.data.page} target="_blank" rel="noreferrer">{event.data.page}</a>}</span>
            <span
              className="show-details"
              onClick={() => {
                setOpenedLog(event);
              }}
            >
              Show data
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderNetworkLogItem = (log, key, style) => {
    const type = getNetworkLogType(log);

    const logClassName = classNames('timeline-item', {
      'timeline-item--warning': type === 'WARNING',
      'timeline-item--error': type === 'ERROR',
    });

    return (
      <div className={logClassName} key={key} style={style}>
        <div className="timeline-count">
          <img src={getNetworkLogIconForType(type)} alt="network log" />
          {formatDate(log.date)}
        </div>
        <div className="timeline-data">
          {getNetworkLogStatusText(log)}{' '}
          <a href={log.url} target="_blank" rel="noreferrer">
            {log.url}
          </a>
          <span
            className="show-details"
            onClick={() => {
              setOpenedLog(log);
            }}
          >
            Show details
          </span>
        </div>
      </div>
    );
  };

  const renderLogItem = (log, key, style) => {
    const logClassName = classNames('timeline-item', {
      'timeline-item--warning': log.priority && log.priority === 'WARNING',
      'timeline-item--error': log.priority && log.priority === 'ERROR',
    });

    let icon = logsIcon;
    if (log.priority && log.priority === 'WARNING') {
      icon = warningIcon;
    }
    if (log.priority && log.priority === 'ERROR') {
      icon = errorIcon;
    }
    if (log.priority && log.priority === 'CLICK') {
      icon = clickIcon;
    }

    return (
      <div className={logClassName} key={key} style={style}>
        <div className="timeline-count">
          <img src={icon} alt="console log" />
          {formatDate(log.date)}
        </div>
        <div className="timeline-data">
          {log.priority === 'CLICK' && 'Click: '}
          {log.log &&
            log.log.split('\n').map((item, idx) => {
              item = item.replace(
                /\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d.\d\d\d.+?(?=\])] /gm,
                '',
              );

              if (!item || item.length === 0) {
                return <></>;
              }

              return (
                <span key={idx}>
                  {item}
                  <br />
                </span>
              );
            })}
        </div>
      </div>
    );
  };

  const rowRenderer = ({ index, isScrolling, key, parent, style }) => {
    const item = filteredLogs[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }) => {
          if (item.t === 's') {
            return renderSystemEventLogItem(item, key, style);
          }
          if (item.t === 'n') {
            return renderNetworkLogItem(item, key, style);
          }
          if (item.t === 'e') {
            return renderEventLogItem(item, key, style);
          }
          return renderLogItem(item, key, style);
        }}
      </CellMeasurer>
    );
  };

  const toggleFilterForItem = (item: string) => {
    const filterLogsNew: string[] = JSON.parse(JSON.stringify(filterLogs));
    const index = filterLogsNew.indexOf(item);
    if (index > -1) {
      filterLogsNew.splice(index, 1);
    } else {
      filterLogsNew.push(item);
    }
    setFilterLogs(filterLogsNew);
  };

  const renderLogDetailsEvent = () => {
    return <EventLogItem log={openedLog} />;
  };

  const renderLogDetailsNetwork = () => {
    return <NetworkLogItem log={openedLog} />;
  };

  const renderLogDetails = () => {
    if (openedLog && openedLog.t === 'e') {
      return renderLogDetailsEvent();
    }
    if (openedLog && openedLog.t === 'n') {
      return renderLogDetailsNetwork();
    }
    return <></>;
  };

  return (
    <div className="activity-log-container">
      <div className="log-tools">
        <div className="log-tools-filter">
          <LogButton
            icon={errorIcon}
            label="Error"
            active={filterLogs.includes('ERROR')}
            onClick={() => {
              toggleFilterForItem('ERROR');
            }}
          />
          <LogButton
            icon={warningIcon}
            label="Warning"
            active={filterLogs.includes('WARNING')}
            onClick={() => {
              toggleFilterForItem('WARNING');
            }}
          />
          <LogButton
            icon={logsIcon}
            label="Logs"
            active={filterLogs.includes('INFO')}
            onClick={() => {
              toggleFilterForItem('INFO');
            }}
          />
          <LogButton
            icon={clickIcon}
            label="Clicks"
            active={filterLogs.includes('CLICK')}
            onClick={() => {
              toggleFilterForItem('CLICK');
            }}
          />
          <LogButton
            icon={networkFilter}
            label="Network"
            active={filterLogs.includes('NETWORK')}
            onClick={() => {
              toggleFilterForItem('NETWORK');
            }}
            className="log-button-icon-rev"
          />
          <LogButton
            icon={eventIcon}
            label="Events"
            active={filterLogs.includes('EVENTS')}
            onClick={() => {
              toggleFilterForItem('EVENTS');
            }}
            className="log-button-icon-rev"
          />
        </div>
        <div className="log-tools-search">
          {!showAfterDate && (
            <LogButton
              active
              label=""
              onClick={async () => {
                const json = JSON.stringify(filteredLogs);
                const blob = new Blob([json], { type: 'application/json' });
                const href = await URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.download = `${bug.bugId}_consolelogs.json`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              icon={downloadIcon}
            />
          )}
          <TextInput
            name="log_search"
            placeholder="Search"
            icon="magnifying-glass"
            type="text"
            error=""
            small
            initalValue={filter}
            onChange={(text) => {
              setFilter(text);
            }}
            className=""
          />
        </div>
      </div>
      <div className="log-list">
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              rowCount={filteredLogs.length}
              style={{
                transition: 'background-color 0.2s ease',
              }}
              rowRenderer={rowRenderer}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
            />
          )}
        </AutoSizer>
      </div>
      {openedLog && (
        <div className="log-details">
          <div className="log-details-content-container">
            <div className="log-details-close-button" onClick={() => setOpenedLog(null)}>
              <img src={closeIcon} alt="close" />
            </div>
            {renderLogDetails()}
          </div>
        </div>
      )}
    </div>
  );
};

export default inject('bugStore')(observer(ActivityTimeline));
