import React, { useEffect, useState } from 'react';
import './ProjectDropdown.scss';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { ProjectStore } from 'stores/private/ProjectStore';

interface ProjectDropdownProps {
  projectStore?: ProjectStore;
  onChooseProject: (project: any) => void;
  placeholder?: string;
}

const ProjectDropdown = ({
  projectStore,
  onChooseProject,
  placeholder,
}: ProjectDropdownProps) => {
  const projects = projectStore?.projects;
  const [data, setData] = useState(null as any);

  return (
    <div className="project-selection-container">
      <Select
        className="project-selection"
        classNamePrefix="project-selection"
        defaultValue={data}
        value={data}
        placeholder={placeholder || 'Select a project'}
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            const project = option.data;
            return (
              <div className="project-option">
                {!project.picture && (
                  <div className="image-placeholder mr-15">
                    {project.name && project.name.charAt(0).toLocaleUpperCase()}
                  </div>
                )}
                {project.picture && (
                  <img
                    src={project.picture}
                    className="mr-15"
                    alt="projpicture"
                  />
                )}
                <div className="project-name">{project.name}</div>
              </div>
            );
          },
          Option: (option: any) => {
            const project = option.data;
            return (
              <div
                className="project-option project-option--item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                {project.showOverview && (
                  <div className="image-placeholder mr-10">
                    <i className="fa-solid fa-grid-horizontal" />
                  </div>
                )}
                {!project.showOverview && !project.picture && (
                  <div className="image-placeholder mr-10">
                    {project.name && project.name.charAt(0).toLocaleUpperCase()}
                  </div>
                )}
                {!project.showOverview && project.picture && (
                  <img
                    src={project.picture}
                    className="mr-10"
                    alt="projpicture"
                  />
                )}
                <div className="project-name">{project.name}</div>
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          onChooseProject(selected);
          setData(selected);
        }}
        options={projects ?? []}
      />
    </div>
  );
};

export default inject('projectStore')(observer(ProjectDropdown));
