import { useEffect, useState } from 'react';
import './FormWidgetSettings.scss';
import { inject, observer } from 'mobx-react';
import { defaultMenuOptions, ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Collapsible from 'react-collapsible';
import Loading from 'components/Loading/Loading';
import LinkButton from 'components/LinkButton/LinkButton';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import {
  SortableElement,
  SortableContainer,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { ReactComponent as DragIcon } from 'assets/icons/dragicon.svg';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import FormWidgetItem from './FormWidgetItem';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import Swal from 'sweetalert2';
import { ampli } from 'ampli';
import { isEqual } from 'lodash';

interface FormWidgetSettingsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function FormWidgetSettings({
  projectStore,
  modalStore,
}: FormWidgetSettingsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [possibleActions, setPossibleActions] = useState([] as any[]);
  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      projectStore?.getBots(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (
      projectStore?.editingProject &&
      projectStore?.editingProject?.projectActions
    ) {
      const possibleProjectActions: any[] = [];
      const actionKeys = Object.keys(
        projectStore?.editingProject?.projectActions,
      );

      for (let i = 0; i < actionKeys.length; i++) {
        const action =
          projectStore?.editingProject?.projectActions[actionKeys[i]];
        if (action.feedbackType !== 'SURVEY' && action.feedbackType !== 'surveys') {
          possibleProjectActions.push({
            label: action.title,
            icon: 'circle-play',
            value: actionKeys[i],
          });
        }
      }

      setPossibleActions([
        ...possibleProjectActions,
      ]);
    }
  }, [projectStore?.editingProject]);

  if (isLoading || !flowConfig || !flowConfig.menuItems) {
    return <Loading />;
  }

  const onSortEnd = (event) => {
    flowConfig.menuItems = arrayMove(
      flowConfig.menuItems,
      event.oldIndex,
      event.newIndex,
    );

    setToggle(!toggle);
  };

  const DragHandle = SortableHandle(() => (
    <DragIcon className="menu-item-form-item-drag-icon" />
  ));

  const SortableItem = SortableElement(({ formItem, currentIndex }) =>
    renderMenuItem(formItem, currentIndex),
  );

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem
            key={`item-${value.title}-${Math.random()
              .toString(36)
              .substring(7)}`}
            index={index}
            currentIndex={index}
            formItem={value}
          />
        ))}
      </div>
    );
  });

  const renderMenuItem = (menuItem, key) => {
    return (
      <Collapsible
        key={key}
        trigger={
          <div className="action-item-header">
            <DragHandle />
            <div
              className="icon"
            >
              <img alt="Type icon" src={menuItem.icon} />
            </div>{' '}
            {menuItem.title}
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible--opened"
        onClose={() => {
          ampli.menuOptionsCustomized({
            projectId,
            options: flowConfig.menuItems,
          });
        }}
      >
        <FormWidgetItem
          menuItem={menuItem}
          bots={projectStore?.bots}
          flowConfig={flowConfig}
          possibleActions={possibleActions}
          projectId={projectId}
          onSave={(menuItem) => {
            flowConfig.menuItems[key] = menuItem;
          }}
        />
      </Collapsible>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Home menu">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject();

              ampli.menuOptionsCustomized({
                projectId,
                options: flowConfig.menuItems,
              });
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <div className="widget-general-settings widget-form-settings">
          <div className="widget-general-settings-content">
            <div>
              <SortableList
                itemsList={flowConfig.menuItems}
                onSortEnd={onSortEnd}
                useDragHandle
                helperClass="sortable-helper"
              />
            </div>
            <>
              <PrimaryButton
                icon="plus"
                label="Add menu option"
                className="mr-10 mt-20"
                onClick={() => {
                  modalStore!.openModal(MODALTYPE.ADDMENUOPTION);
                }}
              />
              <LinkButton
                icon=""
                label="Restore default options"
                className="mt-20"
                onClick={() => {
                  Swal.fire({
                    text: 'Do you really want to restore the default menu options?',
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                    denyButtonText: `No`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      flowConfig.menuItems = defaultMenuOptions;

                      ampli.menuOptionsCustomized({
                        projectId,
                        options: flowConfig.menuItems,
                      });
                    }
                  });
                }}
              />
            </>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'modalStore',
)(observer(FormWidgetSettings));
