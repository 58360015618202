export const validateRecaptchaAction = (action) => {
  const { grecaptcha } = window as any;
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute('6LeMpiwcAAAAAAuGag4PWJvwSSgH0mCVX7EDQIjT', { action })
        .then((token) => {
          if (token) {
            resolve(token);
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  });
};
