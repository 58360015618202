import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import Select from 'react-select';
import './FeedbackTagFilter.scss';

interface FeedbackTagFilterProps {
  projectStore?: ProjectStore;
  value?: any;
  onValueChanged?: (value?: string) => void;
}

const FeedbackTagFilter = ({
  projectStore,
  value,
  onValueChanged,
}: FeedbackTagFilterProps) => {
  const [data, setData] = useState(undefined as any);

  useEffect(() => {
    if (value) {
      setData(value);
    } else {
      setData(undefined);
    }
  }, [value]);

  if (
    !projectStore?.currentProject?.feedbackTags ||
    projectStore?.currentProject?.feedbackTags.length <= 0
  ) {
    return <></>;
  }

  const filterOptions = (candidate, input) => {
    if (input) {
      if (candidate.label.toLowerCase().includes(input.toLowerCase()))
        return true;
      if (
        Array.isArray(data) &&
        data.some((opt) => {
          if (opt.value === candidate.value) return true;
          return false;
        })
      )
        return true;
      return false;
    }
    return true;
  };

  return (
    <Select
      className="dropdown-selection feedback-type-filter"
      classNamePrefix="dropdown-selection"
      value={data}
      getOptionLabel={(option) => option.label}
      // eslint-disable-next-line no-underscore-dangle
      getOptionValue={(option) => option._id}
      placeholder="Filter by tag"
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      isClearable
      filterOption={filterOptions}
      components={{
        SingleValue: (option: any) => {
          return (
            <div className="bug-prio-item">
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.label}</span>
            </div>
          );
        },
        Option: (option: any) => {
          return (
            <div
              className="option option-list-item bug-prio-item"
              onClick={() => {
                if (option.selectOption) {
                  option.selectOption(option.data);
                }
              }}
            >
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.label}</span>
            </div>
          );
        },
      }}
      onChange={(selectedTag: any) => {
        let valToUpdate;
        if (selectedTag && selectedTag.label) {
          valToUpdate = selectedTag;
        }

        if (onValueChanged) {
          onValueChanged(valToUpdate);
        }
      }}
      options={projectStore?.currentProject?.feedbackTags ?? []}
    />
  );
};

export default inject('projectStore')(observer(FeedbackTagFilter));
