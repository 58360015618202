import './ProjectSpamProtection.scss';
import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import InfoBox from 'components/InfoBox/InfoBox';
import { runInAction } from 'mobx';
import { ampli } from 'ampli';

interface ProjectSpamProtectionProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const ProjectSpamProtection = ({
  modalStore,
  projectStore,
}: ProjectSpamProtectionProps) => {
  const currentProject = projectStore?.currentProject;

  if (!currentProject) {
    return <></>;
  }

  return (
    <PageContainer className="project-spamprotection">
      <PageHeadLine title="Spam protection" />
      <PageContent hasTitle isMediumBoxed>
        <div className="project-spamprotection-inner-container">
          <div className="options-group">
            <div className="options-group-header">Spam protection</div>
            <div className='text mt-10'>
              We use a mix of internal spam detection as well as reCAPTCHA to protect you from spam & automated feedback inquries. This feature is disabled by default.
            </div>
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={async (checked) => {
                    runInAction(() => {
                      projectStore!.flowConfig.spamProtection =
                        !projectStore!.flowConfig.spamProtection;
                      projectStore!.updateFlowConfig();

                      if (projectStore!.flowConfig.spamProtection) {
                        ampli.spamProtectionEnabled({
                          projectId: currentProject.id,
                        });
                      }
                    });
                  }}
                  checked={projectStore!.flowConfig.spamProtection}
                />
                <span>
                  Enable spam protection
                </span>
              </div>
            </div>
            <InfoBox className="mt-20">
              <>
                Please note that you might need to add custom rules to your CSP headers when using CSP.
              </>
            </InfoBox>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(ProjectSpamProtection));
