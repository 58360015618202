/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Replay.scss';
import { BugStore } from 'stores/private/BugStore';
import useInterval from 'react-useinterval';
import moment from 'moment';
import { inject } from 'mobx-react';
import playIcon from '../../../assets/icons/play.svg';
import pauseIcon from '../../../assets/icons/pause.svg';
import ActivityTimeline from '../ActivityTimeline/ActivityTimeline';

export const formatSeconds = (step) => {
  return new Date(step * 1000).toISOString().substr(11, 8);
};

interface AppReplayProps {
  bugStore?: BugStore;
}

const AppReplay = ({ bugStore }: AppReplayProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [playing, setPlaying] = useState(false);
  const bug = bugStore!.currentBug;

  // Initial vars.
  const framesCount = bug?.replay?.frames.length ?? 10;
  const replayInterval = bug?.replay?.interval ?? 1000;
  const stepInterval = 10;
  const timeForEachFrame = replayInterval / stepInterval;

  useInterval(() => {
    if (!playing) {
      return;
    }

    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);

    const nextFrame = Math.round(nextStep / timeForEachFrame);

    if (bug && bug.replay && bug.replay.frames) {
      if (nextFrame < framesCount) {
        setCurrentFrame(nextFrame);
      } else if (nextStep === timeForEachFrame * framesCount) {
        setPlaying(false);
      }
    }
  }, stepInterval);

  if (!bug || !bug.replay || bug.replay.frames.length === 0) {
    return <></>;
  }

  const currentFrameObject = bug.replay.frames[currentFrame];

  return (
    <div className="replay-container">
      <div className="replay-container-inner">
        <div className="replay-upper-frame-container">
          <div className="replay-frame-container">
            {currentFrameObject && (
              <img
                src={
                  currentFrame === framesCount - 1
                    ? bug.screenshotUrl
                    : currentFrameObject.url
                }
                alt="replay frame"
                className="replay-frame"
              />
            )}
          </div>
        </div>
        <div className="replay-container-bar">
          <img
            onClick={() => {
              if (currentFrame >= framesCount - 1) {
                setCurrentFrame(0);
                setCurrentStep(0);
              }
              setPlaying(!playing);
            }}
            src={playing ? pauseIcon : playIcon}
            alt="play"
            className="play-icon"
          />
          <div className="replay-container-slider">
            <Slider
              min={0}
              max={timeForEachFrame * framesCount}
              value={currentStep}
              onChange={(value) => {
                const frame = Math.round(value / timeForEachFrame);

                if (frame < framesCount) {
                  setCurrentFrame(frame);
                  setCurrentStep(value);
                  setPlaying(false);
                }
              }}
            />
            <div className="replay-container-slider-timer">
              <div>
                {formatSeconds(currentStep / (1000 / stepInterval))} /{' '}
                {formatSeconds(
                  (timeForEachFrame * framesCount) / (1000 / stepInterval),
                )}
              </div>
              <div>Current screen: {currentFrameObject.screenname}</div>
              <div>
                {moment(currentFrameObject.date).format('DD.MM.yyyy HH:mm:ss')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="replay-console-logs">
        <div className="replays-header">Replay details</div>
        {currentFrameObject && (
          <ActivityTimeline showAfterDate={currentFrameObject.date} />
        )}
      </div>
    </div>
  );
};

export default inject('bugStore')(AppReplay);
