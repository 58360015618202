import LinkButton from 'components/LinkButton/LinkButton';
import NewLineText from 'components/NewLineText/NewLineText';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { formatCSATScore, getEmojiForVal } from 'helper/ScoreFormater';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import Swal from 'sweetalert2';
import './Details.scss';
import './FormData.scss';

interface FormDataProps {
  bugStore?: BugStore;
  shared?: boolean;
}

const FormData = ({ bugStore, shared }: FormDataProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const bug = bugStore!.currentBug;

  if (!bug) {
    return <></>;
  }

  const formDataFooter = () => {
    return (
      <div className="form-data-container-footer">
        <time>
          {moment(bug.createdAt).format('MMMM Do YYYY, h:mm a')}
        </time>
      </div>
    );
  }

  const renderEditingForm = () => {
    const formKeys = Object.keys(bug.form);

    return (
      <div className="form-data-container">
        <div className="form-data-container-forms">
          {formKeys.map((key) => {
            const item = bug.form[key];
            const renderFormItem = () => {
              if (!item || !item.type) {
                return null;
              }
              if (item.type === 'textarea') {
                return (
                  <div className="textarea-container">
                    <textarea
                      placeholder={item.placeholder}
                      className="textarea-gray"
                      value={item.value}
                      onChange={(e) => {
                        item.value = e.target.value;
                        bug.formData[key] = e.target.value;
                      }}
                      rows={5}
                    />
                  </div>
                );
              }
              if (item.type === 'text') {
                return (
                  <TextInput
                    placeholder={item.placeholder}
                    type="text"
                    error=""
                    initalValue={item.value}
                    onChange={(text) => {
                      item.value = text;
                      bug.formData[key] = text;
                    }}
                  />
                );
              }
              return null;
            };

            const editItem = renderFormItem();
            if (!editItem) {
              return null;
            }

            return (
              <div className="form-data-edit-item mb-10" key={item.key}>
                <div className="input-label mb-5">
                  {item.title ? item.title : item.name}
                </div>
                {editItem}
              </div>
            );
          })}
          <PrimaryButton
            label="Save"
            onClick={() => {
              // Cleanup keys.
              const innerFormKeys = Object.keys(bug.form);
              for (let i = 0; i < innerFormKeys.length; i++) {
                if (
                  !bug.formData[innerFormKeys[i]] ||
                  bug.formData[innerFormKeys[i]] === ''
                ) {
                  delete bug.formData[innerFormKeys[i]];
                  delete bug.form[innerFormKeys[i]];
                }
              }

              bugStore!.updateBug(bug.id, {
                formData: bug.formData,
                form: bug.form,
              });

              setIsEditing(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderFormData = () => {
    if (!bug.form || Object.keys(bug.form).length === 0) {
      return renderFormDataOld();
    }

    const renderContent = (data) => {
      if (data.type === 'rating') {
        return (
          <span>
            {formatCSATScore(data.value)} {getEmojiForVal(data.value * 10)}
          </span>
        );
      }

      if (data.type === 'upload') {
        return (
          <a href={data.value} target="_blank" rel="noreferrer">
            Download file
          </a>
        );
      }

      return (
        <NewLineText
          text={
            typeof data.value === 'string'
              ? data.value
              : JSON.stringify(data.value)
          }
        />
      );
    };

    return (
      <div className="form-data-container">
        <div className="form-data-container-items">
          {Object.keys(bug.form).map((key) => {
            const data = bug.form[key];
            return (
              <div className="detail-item" key={key}>
                <div className="desc-item-header">
                  {data.title ? data.title : data.name}
                </div>
                <div className="desc-item">{renderContent(data)}</div>
              </div>
            );
          })}
          {!shared && (
            <div className="form-data-container-edit">
              <LinkButton
                label="Edit"
                noBorder
                small
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            </div>
          )}
        </div>
        {formDataFooter()}
      </div>
    );
  };

  const renderFormDataOld = () => {
    if (bug.formData) {
      const formData = JSON.parse(JSON.stringify(bug.formData));
      delete formData.reportedBy;
      const formDataKeys = Object.keys(formData);
      if (formDataKeys && formDataKeys.length > 0) {
        return (
          <div className="form-data-container">
            <div className="form-data-container-items">
              {formDataKeys.map((key) => {
                const data = formData[key];
                return (
                  <div className="detail-item" key={key}>
                    <div className="desc-item-header">{key}</div>
                    <div className="desc-item">
                      <NewLineText
                        text={
                          typeof data === 'string' ? data : JSON.stringify(data)
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {!shared && (
              <div className="form-data-container-edit">
                <LinkButton
                  label="Edit"
                  noBorder
                  small
                  onClick={() => {
                    Swal.fire({
                      text: 'Editing is only available on feedback created with the latest Gleap SDKs.',
                      showCancelButton: false,
                      confirmButtonText: `Ok`,
                    });
                  }}
                />
              </div>
            )}
            {formDataFooter()}
          </div>
        );
      }
    }

    return (
      <div className="form-data-container">
        <div className="form-data-container-items">
          <div className="detail-item">
            <div className="desc-item-header">Description</div>
            <div className="desc-item">
              <NewLineText text={bug.description ? bug.description : '--'} />
            </div>
          </div>
        </div>
        {formDataFooter()}
      </div>
    );
  };

  if (isEditing) {
    return renderEditingForm();
  }

  return renderFormData();
};

export default inject('bugStore')(observer(FormData));
