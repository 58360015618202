import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import { generateHTML } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import { CustomLink } from 'components/Editors/RichTextEditor/extensions/CustomLink';
import { stripHtml } from 'components/InboxItem/InboxItem';
import { formatCSATScore, getEmojiForVal } from './ScoreFormater';
import Youtube from '@tiptap/extension-youtube';
import Iframe from 'services/Iframe';
import { get } from 'lodash';

export const getDataDescription = ({ data, maxLength = 50 }) => {
  if (!data) {
    return '--';
  }

  // AI generated title.
  if (data.title) {
    return truncate(data.title, maxLength);
  }

  let descriptionData = '';
  if (data.form) {
    const formFields = Object.values(data.form);

    let description = '';

    const textFormFields: any = formFields.filter(
      (item: any) => item.type === 'textarea' || item.type === 'text',
    );
    for (let i = 0; i < textFormFields.length; i++) {
      description += textFormFields[i].value;
      description += ' • ';
    }

    const otherFields: any = formFields.filter(
      (item: any) => item.type !== 'textarea' && item.type !== 'text',
    );
    for (let i = 0; i < otherFields.length; i++) {
      if (otherFields[i].type === 'rating') {
        description += `${getEmojiForVal(
          Math.round(otherFields[i].value * 10),
        )} Rating: ${formatCSATScore(otherFields[i].value)}`;
      } else {
        description += otherFields[i].value;
      }

      description += ' • ';
    }

    if (description !== '') {
      descriptionData = description.slice(0, -3);
    } else {
      descriptionData = description;
    }
  } else if (data.formData && data.formData.title) {
    descriptionData = data.formData.title;
  } else if (data.description) {
    descriptionData = data.description;
  } else if (data.formData && data.formData.description) {
    descriptionData = data.formData.description;
  } else if (data.data && data.data.text) {
    descriptionData = data.data.text;
  } else if (data.latestComment && data.latestComment?.data?.content) {
    if (typeof data.latestComment?.data?.content === 'string') {
      descriptionData = stripHtml(data.latestComment?.data?.content);
    } else {
      try {
        descriptionData = stripHtml(
          generateHTML(data.latestComment?.data?.content, [
            StarterKit.configure({ codeBlock: {}, heading: false }),
            Heading.configure({
              levels: [1, 2, 3],
            }),
            CustomLink,
            Mention,
            Placeholder,
            TextStyle,
            Youtube.configure({ controls: true }),
            Iframe,
          ]),
        );
      } catch (exp) {}
    }
  }

  // Remove line breaks
  descriptionData = descriptionData.replace(/(\r\n|\n|\r)/gm, ' ');

  if (descriptionData === '') {
    return '--';
  }

  return truncate(descriptionData, maxLength);
};

export const truncate = (str, n) => {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const getFormFieldValue = (bug, key, fallback?: string) => {
  if (key === 'title' && bug && bug[key]) {
    return bug[key];
  }

  if (key === 'plainContent' && bug && bug[key]) {
    return bug[key];
  }

  if (bug && bug.form && bug.form[key] && bug.form[key].value) {
    return bug.form?.[key]?.value;
  }

  if (bug && bug.formData && bug.formData[key]) {
    return bug.formData[key];
  }

  if (fallback) {
    const val = get(bug, fallback, undefined);
    if (val) {
      return val;
    }

    if (fallback === 'formText') {
      return getDataDescription({ data: bug, maxLength: 200 });
    }
  }

  if (key === 'title') {
    return 'Untitled';
  }

  return '--';
};
