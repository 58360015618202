import axios from './Axios';

const removeOutboundRule = (projectId: string, id: string) => {
  return axios.delete(`/projects/${projectId}/outbound/${id}`);
};

const getStreamedEventKeys = (projectId: string) => {
  return axios.get(`/projects/${projectId}/streamedeventkeys`);
};

const getOutboundRules = (projectId: string) => {
  return axios.get(`/projects/${projectId}/outbound`);
};

const getOutboundRule = (projectId: string, id: string) => {
  return axios.get(`/projects/${projectId}/outbound/${id}`);
};

const getOutboundRuleStats = (projectId: string, id: string) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/stats`);
};

const getOutboundRuleResponses = (projectId: string, id: string) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/responses`);
};

const getOutboundRuleRecipients = (
  projectId: string,
  id: string,
  query: any,
) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/recipients`, {
    params: query,
  });
};

const getFixedAudience = (projectId: string, id: string, conditions: any[], targetAudience: string) => {
  return axios.post(`/projects/${projectId}/outbound/${id}/samplefixedaudience`,
  {
    conditions,
    targetAudience,
  });
};

const askOutboundAIQuestion = (
  projectId: string,
  outboundId: string,
  question: string,
) => {
  return axios.post(
    `/projects/${projectId}/outbound/${outboundId}/aiquestion`,
    {
      question,
    },
  );
};

const markOutboundRuleResponsesAsRead = (projectId: string, id: string) => {
  return axios.put(`/projects/${projectId}/outbound/${id}/markallasread`);
};

const checkIfOutboundRuleResponsesAvailable = (
  projectId: string,
  id: string,
) => {
  return axios.get(
    `/projects/${projectId}/outbound/${id}/responses/checkavailability`,
  );
};

const createOutboundRule = (projectId: string, data: any) => {
  return axios.post(`/projects/${projectId}/outbound`, data);
};

const updateOutboundRule = (projectId: string, id: string, data: any) => {
  return axios.put(`/projects/${projectId}/outbound/${id}`, data);
};

const exportOutboundResponses = (projectId: string, id: string) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/export`);
};

export {
  getStreamedEventKeys,
  getOutboundRules,
  getOutboundRule,
  getOutboundRuleResponses,
  getOutboundRuleRecipients,
  removeOutboundRule,
  createOutboundRule,
  updateOutboundRule,
  exportOutboundResponses,
  checkIfOutboundRuleResponsesAvailable,
  markOutboundRuleResponsesAsRead,
  getOutboundRuleStats,
  askOutboundAIQuestion,
  getFixedAudience,
};
