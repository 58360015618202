import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { isMacintosh, useClickedOutside } from 'services/Helper';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './SendInputMacros.scss';

const SendInputMacros = ({
  onTextPaste,
  projectStore,
  modalStore,
}: {
  onTextPaste: (text: string) => void;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}) => {
  const scrollContainerRef = useRef(null as any);
  const navigate = useNavigate();
  const [currentlyFocused, setCurrentlyFocused] = useState(0);
  const currentlyFocusedRef = useRef(currentlyFocused);
  currentlyFocusedRef.current = currentlyFocused;
  const [showMacrosList, setShowMacrosList] = useState(false);
  const showMacrosListRef = useRef(showMacrosList);
  showMacrosListRef.current = showMacrosList;
  const macroList: any[] = [...(projectStore?.currentProject?.messageTemplates || []), { create: true, title: "", content: "" }];
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowMacrosList(false);
  });

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      const children = scrollContainerRef.current.children;
      if (children && children[currentlyFocused]) {
        children[currentlyFocused].scrollIntoView({ block: 'nearest' });
      }
    }
  }, [currentlyFocused]);

  const pasteMacro = (content: any) => {
    onTextPaste(content);
    setShowMacrosList(false);
  };

  const performActionForIndex = (index) => {
    const macro = macroList[index];
    if (!macro) {
      return;
    }
    
    if (macro.create) {
      modalStore?.closeModal();
      navigate(
        `/projects/${projectStore?.currentProject?.id}/settings/messagetemplates`,
      );
      return;
    }

    pasteMacro(macro.content);
  }

  const handleUserKeyPress = useCallback((event: any) => {
    const { key } = event;

    if ((event.metaKey || event.ctrlKey) && key === 'e') {
      setShowMacrosList(!showMacrosListRef.current);
      event.preventDefault();
      return;
    }

    // Don't perform other key bindings if it's not open.
    if (!showMacrosListRef.current) {
      return;
    }

    if (event.key === "Escape") {
      setShowMacrosList(false);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 13) {
      if (showMacrosListRef.current) {
        performActionForIndex(currentlyFocusedRef.current);
        event.preventDefault();
        return;
      }
    }

    if (event.keyCode === 40) {
      var newIndex = (currentlyFocusedRef.current + 1);
      if ((newIndex > macroList.length - 1)) {
        newIndex = 0;
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 38) {
      var newIndex = (currentlyFocusedRef.current - 1);
      if (newIndex < 0) {
        newIndex = macroList.length - 1;
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }

    if (key && showMacrosListRef.current) {
      const index = getIndex(key);
      performActionForIndex(index);

      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const getIndex = (character: string) => {
    return character.charCodeAt(0) - 97;
  };

  const getCharacter = (index: number) => {
    return String.fromCharCode(97 + index);
  };

  return (
    <div className="macro-container" ref={wrapperRef}>
      {showMacrosList && (
        <div className="macro-list">
          <div className='macro-list-title'>
            Message from template
          </div>
          <div className='macro-list-list' ref={scrollContainerRef}>
            {macroList.map((macro, index) => {
              return (
                <div
                  className={`macro-list-item ${(index === currentlyFocused) && 'focused'}`}
                  key={index}
                  onMouseEnter={() => {
                    setCurrentlyFocused(index);
                  }}
                  onClick={() => {
                    performActionForIndex(index);
                  }}
                >
                  <div className="macro-list-item-shortcut">
                    {macro.create ? <i className="fa-regular fa-plus"></i> : getCharacter(index)}
                  </div>
                  <div className="macro-list-item-shortcut-content">
                    {macro.create ? 'Add templates' : macro.title}
                  </div>
                </div>
              );
            })}
          </div>
          <div className='macro-list-footer'>
            <div className="hotkeys-list">
              <div className='hotkey-key'>
                <i className="fa-regular fa-arrow-up"></i>
              </div>
              <div className='hotkey-key'>
                <i className="fa-regular fa-arrow-down"></i>
              </div>
              <div className='hotkeys-list-label'>to navigate</div>
            </div>
            <div className="hotkeys-list">
              <div className='hotkey-key'>
                <i className="fa-regular fa-turn-down-left"></i>
              </div>
              <div className='hotkeys-list-label'>to select</div>
            </div>
            <div className="hotkeys-list">
              <div className='hotkey-key'>
                ESC
              </div>
              <div className='hotkeys-list-label'>to close</div>
            </div>
          </div>
        </div>
      )}
      {!showMacrosList && (
        <ReactTooltip
          id="aiButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={0}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
                <div className="hotkey-help">
                  {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                  <div>E</div>
                </div>
              </div>
            );
          }}
        />
      )}
      <div
        data-for="aiButtonTooltip"
        data-tip="Message from template"
        className={`macro-container-item ${showMacrosList && 'macro-container-item--active'}`}
        onClick={() => {
          setShowMacrosList(!showMacrosList);
        }}
      >
        <i className="fa-regular fa-clipboard-list" />
      </div>
    </div>
  );
};

export default inject('projectStore', 'modalStore')(observer(SendInputMacros));
