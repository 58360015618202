import axios from '../Axios';

const getAuthCodeFromClickup = (projectID) => {
  return window.open(
    `${process.env.REACT_APP_CLICKUP_CONNECT_URL}?client_id=${process.env.REACT_APP_CLICKUP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASEURL}/projects/${projectID}/integrations/clickup`,
    '_self',
  );
};

const setClickupAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/clickup`, {
    code,
  });
};

const getTeams = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/clickup/${integrationID}/teams`,
  );
};

const getSpaces = ({ projectID, integrationID, teamID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/clickup/${integrationID}/spaces?teamID=${teamID}`,
  );
};

const getFolders = ({ projectID, integrationID, spaceID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/clickup/${integrationID}/folders?spaceID=${spaceID}`,
  );
};

const getFolderlessLists = ({ projectID, integrationID, spaceID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/clickup/${integrationID}/folderless-lists?spaceID=${spaceID}`,
  );
};

const getLists = ({ projectID, integrationID, folderID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/clickup/${integrationID}/lists?folderID=${folderID}`,
  );
};

const setClickupActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/clickup/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectClickupIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/clickup/${integrationID}`,
  );
};

export {
  getAuthCodeFromClickup,
  setClickupAuthToken,
  disconnectClickupIntegration,
  getTeams,
  getSpaces,
  getLists,
  getFolders,
  getFolderlessLists,
  setClickupActions,
};
