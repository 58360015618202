import React from 'react';
import './InstructionCard.scss';
import { Card } from 'components/Card/Card';
import LinkButton from 'components/LinkButton/LinkButton';

interface InstructionCardProps {
  item: any;
  onClick?: any;
  buttonTitle?: string;
}

const InstructionCard = ({ item, onClick, buttonTitle = 'Start setup' }: InstructionCardProps) => {
  return (
    <Card className="instruction-card">
      <div className="link-header">
        <img className="image" src={item.image} alt="integration_image" />
      </div>
      <div className="link-body">
        <span className='title'>{item.title}</span>
        <div className="description">{item.description}</div>
      </div>
      <div className="link-footer">
        <LinkButton
          label={buttonTitle}
          icon="arrow-right"
          iconSideRight={true}
          onClick={() => {
            onClick(item);
          }}
        />
      </div>
    </Card>
  );
};

export default InstructionCard;
