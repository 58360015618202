/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 62
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/gleap/Gleap/implementation/frontend)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'development' | 'production';

export const ApiKey: Record<Environment, string> = {
  development: '',
  production: '2289d3d500007be6a2a9e4db57d59dc6'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '62',
    branch: 'main',
    source: 'frontend',
    versionId: '164f6196-5d01-470c-b0c6-19691d0c3516'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptions {
  environment?: Environment;
  disabled?: boolean;
  client?: {
    apiKey?: string;
    configuration?: BrowserOptions;
    instance?: BrowserClient;
  }
}

export interface IdentifyProperties {
  createdAt?: {
    [k: string]: any;
  };
  email?: string;
  id?: {
    [k: string]: any;
  };
  name?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export interface ActivityLogOpenedProperties {
  bugId?: string;
}

export interface BoardViewedProperties {
  boardType: string;
}

export interface BrokenScreenshotReportedProperties {
  bugId?: string;
}

export interface BugDetailsOpenedProperties {
  bugId?: string;
}

export interface ContactDeletedProperties {
  projectId?: string;
}

export interface ContactDetailsViewedProperties {
  projectId?: string;
}

export interface ContactEventsViewedProperties {
  projectId?: string;
}

export interface ContactExportedProperties {
  projectId?: string;
}

export interface ContactsViewedProperties {
  projectId?: string;
}

export interface CustomDataOpenedProperties {
  bugId?: string;
}

export interface DeveloperOptionsCustomizedProperties {
  name?: string;
  projectId?: string;
}

export interface DomainVerificationStartedProperties {
  name?: string;
  organizationId?: string;
}

export interface DomainVerifiedProperties {
  name?: string;
  organizationId?: string;
}

export interface DuplicateUnlinkedProperties {
  bugId?: string;
}

export interface EnvironmentalDataOpenedProperties {
  bugId?: string;
}

export interface FeedbackFlowAddedProperties {
  name?: string;
  projectId?: string;
}

export interface FeedbackFlowEditedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  feedbackFlows: string[];
  projectId?: string;
}

export interface FeedbackTypeEditorOpenedProperties {
  name?: string;
  projectId?: string;
}

export interface InstallationInstructionsViewedProperties {
  projectId?: string;
}

export interface InstallationStartedProperties {
  platform?: string;
  projectId?: string;
}

export interface IntegrationSetupCompletedProperties {
  name?: string;
  projectId?: string;
}

export interface IntegrationSetupStartedProperties {
  name?: string;
  projectId?: string;
}

export interface ManuallyForwardedToIntegrationProperties {
  bugId?: string;
  name?: string;
}

export interface ManuallyForwardToIntegrationModalOpenedProperties {
  bugId?: string;
}

export interface MarkedAsDuplicateProperties {
  bugId?: string;
}

export interface MenuOptionAddedProperties {
  name?: string;
  projectId?: string;
}

export interface MenuOptionsCustomizedProperties {
  options: any[];
  projectId?: string;
}

export interface MessageCreatedProperties {
  name?: string;
  projectId?: string;
}

export interface MessageLaunchedProperties {
  name?: string;
  projectId?: string;
}

export interface OnboardingCompletedProperties {
  name?: string;
  organizationId?: string;
}

export interface OpeningHoursCustomizedProperties {
  projectId?: string;
}

export interface PeopleInvitedProperties {
  isOnboarding?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | project, organization |
   */
  level: "project" | "organization";
}

export interface PeopleRemovedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | project, organization |
   */
  level: "project" | "organization";
}

export interface PlanSelectedProperties {
  name?: string;
  organizationId?: string;
}

export interface ProjectDashboardViewedProperties {
  projectId?: string;
}

export interface ProjectDeletedProperties {
  projectId?: string;
}

export interface ReplaysOpenedProperties {
  bugId?: string;
}

export interface SecuredIdentifyEnabledProperties {
  projectId?: string;
}

export interface SendEmailOnFeedbackEnabledProperties {
  projectId?: string;
}

export interface SendEmailToAssignedUsersEnabledProperties {
  projectId?: string;
}

export interface SendEmailUpdateToTeamEnabledProperties {
  projectId?: string;
}

export interface SendMessageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | note, message |
   */
  messageType: "note" | "message";
}

export interface ShareLinkCopiedProperties {
  bugId?: string;
}

export interface SpamProtectionEnabledProperties {
  projectId?: string;
}

export interface StripePortalOpenedProperties {
  organizationId?: string;
}

export interface SubscriberListOpenedProperties {
  bugId?: string;
}

export interface SuggestedUpsellClickedProperties {
  name?: string;
  planId?: string;
}

export interface SuggestedUpsellModalBugsArchivedProperties {
  organizationId?: string;
  planId?: string;
}

export interface SuggestedUpsellModalClickedUpgradeProperties {
  organizationId?: string;
  planId?: string;
}

export interface SuggestedUpsellModalOpenedProperties {
  organizationId?: string;
  planId?: string;
}

export interface SurveyCreatedProperties {
  name?: string;
  projectId?: string;
}

export interface SurveyLaunchedProperties {
  name?: string;
  projectId?: string;
}

export interface TagChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags: string[];
}

export interface TicketArchivedProperties {
  bugId?: string;
}

export interface TicketClosedProperties {
  bugId?: string;
}

export interface TicketDeletedProperties {
  bugId?: string;
}

export interface TicketDueDateSetProperties {
  bugId?: string;
}

export interface TicketPriorityChangedProperties {
  bugId?: string;
  priority: string;
}

export interface TicketRestoredProperties {
  bugId?: string;
}

export interface TicketStatusChangedProperties {
  bugId?: string;
  name?: string;
}

export interface TicketTypeChangedProperties {
  bugId?: string;
  name?: string;
}

export interface TranslationLanguageAddedProperties {
  language: string;
  projectId?: string;
}

export interface TranslationsCustomizedProperties {
  projectId?: string;
}

export interface UpsellModalDismissedProperties {
  isOnboarding?: boolean;
}

export interface UpsellModalPlanIntervalSelectedProperties {
  interval?: string;
}

export interface UpsellModalPlanSelectedProperties {
  isOnboarding?: boolean;
  planId?: string;
}

export interface UpsellModalShownProperties {
  isOnboarding?: boolean;
  planId?: string;
}

export interface UserAssignedProperties {
  bugId?: string;
}

export interface UserRegisteredProperties {
  type?: string;
}

export interface UserRoleSelectedProperties {
  role?: string;
}

export interface WidgetCustomizedProperties {
  name?: string;
  projectId?: string;
}

export class Identify implements BaseEvent {
  event_type = 'Identify';

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Group implements BaseEvent {
  event_type = 'Group';
}

export class ActivityLogOpened implements BaseEvent {
  event_type = 'activityLogOpened';

  constructor(
    public event_properties?: ActivityLogOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BoardFiltered implements BaseEvent {
  event_type = 'boardFiltered';
}

export class BoardSearched implements BaseEvent {
  event_type = 'boardSearched';
}

export class BoardSorted implements BaseEvent {
  event_type = 'boardSorted';
}

export class BoardViewed implements BaseEvent {
  event_type = 'boardViewed';

  constructor(
    public event_properties: BoardViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BrokenScreenshotReported implements BaseEvent {
  event_type = 'brokenScreenshotReported';

  constructor(
    public event_properties?: BrokenScreenshotReportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BugDetailsOpened implements BaseEvent {
  event_type = 'bugDetailsOpened';

  constructor(
    public event_properties?: BugDetailsOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ComposeNewMessage implements BaseEvent {
  event_type = 'composeNewMessage';
}

export class ContactDeleted implements BaseEvent {
  event_type = 'contactDeleted';

  constructor(
    public event_properties?: ContactDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactDetailsViewed implements BaseEvent {
  event_type = 'contactDetailsViewed';

  constructor(
    public event_properties?: ContactDetailsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactEventsViewed implements BaseEvent {
  event_type = 'contactEventsViewed';

  constructor(
    public event_properties?: ContactEventsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactExported implements BaseEvent {
  event_type = 'contactExported';

  constructor(
    public event_properties?: ContactExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactsViewed implements BaseEvent {
  event_type = 'contactsViewed';

  constructor(
    public event_properties?: ContactsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CustomDataOpened implements BaseEvent {
  event_type = 'customDataOpened';

  constructor(
    public event_properties?: CustomDataOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeveloperOptionsCustomized implements BaseEvent {
  event_type = 'developerOptionsCustomized';

  constructor(
    public event_properties?: DeveloperOptionsCustomizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DomainVerificationStarted implements BaseEvent {
  event_type = 'domainVerificationStarted';

  constructor(
    public event_properties?: DomainVerificationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DomainVerified implements BaseEvent {
  event_type = 'domainVerified';

  constructor(
    public event_properties?: DomainVerifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DpaRequested implements BaseEvent {
  event_type = 'dpaRequested';
}

export class DuplicateModalOpened implements BaseEvent {
  event_type = 'duplicateModalOpened';
}

export class DuplicateOpened implements BaseEvent {
  event_type = 'duplicateOpened';
}

export class DuplicateUnlinked implements BaseEvent {
  event_type = 'duplicateUnlinked';

  constructor(
    public event_properties?: DuplicateUnlinkedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EnvironmentalDataOpened implements BaseEvent {
  event_type = 'environmentalDataOpened';

  constructor(
    public event_properties?: EnvironmentalDataOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FeatureRequestShareModalOpened implements BaseEvent {
  event_type = 'featureRequestShareModalOpened';
}

export class FeedbackFlowAdded implements BaseEvent {
  event_type = 'feedbackFlowAdded';

  constructor(
    public event_properties?: FeedbackFlowAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FeedbackFlowEdited implements BaseEvent {
  event_type = 'feedbackFlowEdited';

  constructor(
    public event_properties: FeedbackFlowEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FeedbackItemsExported implements BaseEvent {
  event_type = 'feedbackItemsExported';
}

export class FeedbackTypeEditorOpened implements BaseEvent {
  event_type = 'feedbackTypeEditorOpened';

  constructor(
    public event_properties?: FeedbackTypeEditorOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeScreenViewed implements BaseEvent {
  event_type = 'homeScreenViewed';
}

export class InstallationInstructionsViewed implements BaseEvent {
  event_type = 'installationInstructionsViewed';

  constructor(
    public event_properties?: InstallationInstructionsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstallationSkipped implements BaseEvent {
  event_type = 'installationSkipped';
}

export class InstallationStarted implements BaseEvent {
  event_type = 'installationStarted';

  constructor(
    public event_properties?: InstallationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationSetupCompleted implements BaseEvent {
  event_type = 'integrationSetupCompleted';

  constructor(
    public event_properties?: IntegrationSetupCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationSetupStarted implements BaseEvent {
  event_type = 'integrationSetupStarted';

  constructor(
    public event_properties?: IntegrationSetupStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InvitationAccepted implements BaseEvent {
  event_type = 'invitationAccepted';
}

export class InvitationRejected implements BaseEvent {
  event_type = 'invitationRejected';
}

export class ManuallyForwardedToIntegration implements BaseEvent {
  event_type = 'manuallyForwardedToIntegration';

  constructor(
    public event_properties?: ManuallyForwardedToIntegrationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ManuallyForwardToIntegrationModalOpened implements BaseEvent {
  event_type = 'manuallyForwardToIntegrationModalOpened';

  constructor(
    public event_properties?: ManuallyForwardToIntegrationModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarkedAsDuplicate implements BaseEvent {
  event_type = 'markedAsDuplicate';

  constructor(
    public event_properties?: MarkedAsDuplicateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MenuOptionAdded implements BaseEvent {
  event_type = 'menuOptionAdded';

  constructor(
    public event_properties?: MenuOptionAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MenuOptionsCustomized implements BaseEvent {
  event_type = 'menuOptionsCustomized';

  constructor(
    public event_properties: MenuOptionsCustomizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageCreated implements BaseEvent {
  event_type = 'messageCreated';

  constructor(
    public event_properties?: MessageCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageLaunched implements BaseEvent {
  event_type = 'messageLaunched';

  constructor(
    public event_properties?: MessageLaunchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingCompleted implements BaseEvent {
  event_type = 'onboardingCompleted';

  constructor(
    public event_properties?: OnboardingCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingStarted implements BaseEvent {
  event_type = 'onboardingStarted';
}

export class OpeningHoursCustomized implements BaseEvent {
  event_type = 'openingHoursCustomized';

  constructor(
    public event_properties?: OpeningHoursCustomizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrganizationDeleted implements BaseEvent {
  event_type = 'organizationDeleted';
}

export class PasswordVerified implements BaseEvent {
  event_type = 'passwordVerified';
}

export class PeopleInvited implements BaseEvent {
  event_type = 'peopleInvited';

  constructor(
    public event_properties: PeopleInvitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PeopleInvitedLinkClicked implements BaseEvent {
  event_type = 'peopleInvitedLinkClicked';
}

export class PeopleRemoved implements BaseEvent {
  event_type = 'peopleRemoved';

  constructor(
    public event_properties: PeopleRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlanSelected implements BaseEvent {
  event_type = 'planSelected';

  constructor(
    public event_properties?: PlanSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PrivacyPolicyViewed implements BaseEvent {
  event_type = 'privacyPolicyViewed';
}

export class ProjectDashboardViewed implements BaseEvent {
  event_type = 'projectDashboardViewed';

  constructor(
    public event_properties?: ProjectDashboardViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProjectDeleted implements BaseEvent {
  event_type = 'projectDeleted';

  constructor(
    public event_properties?: ProjectDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReplaysOpened implements BaseEvent {
  event_type = 'replaysOpened';

  constructor(
    public event_properties?: ReplaysOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecuredIdentifyEnabled implements BaseEvent {
  event_type = 'securedIdentifyEnabled';

  constructor(
    public event_properties?: SecuredIdentifyEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendEmailOnFeedbackEnabled implements BaseEvent {
  event_type = 'sendEmailOnFeedbackEnabled';

  constructor(
    public event_properties?: SendEmailOnFeedbackEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendEmailToAssignedUsersEnabled implements BaseEvent {
  event_type = 'sendEmailToAssignedUsersEnabled';

  constructor(
    public event_properties?: SendEmailToAssignedUsersEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendEmailUpdateToTeamEnabled implements BaseEvent {
  event_type = 'sendEmailUpdateToTeamEnabled';

  constructor(
    public event_properties?: SendEmailUpdateToTeamEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendMessage implements BaseEvent {
  event_type = 'sendMessage';

  constructor(
    public event_properties: SendMessageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShareLinkCopied implements BaseEvent {
  event_type = 'shareLinkCopied';

  constructor(
    public event_properties?: ShareLinkCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpamProtectionEnabled implements BaseEvent {
  event_type = 'spamProtectionEnabled';

  constructor(
    public event_properties?: SpamProtectionEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StripePortalOpened implements BaseEvent {
  event_type = 'stripePortalOpened';

  constructor(
    public event_properties?: StripePortalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriberListOpened implements BaseEvent {
  event_type = 'subscriberListOpened';

  constructor(
    public event_properties?: SubscriberListOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuggestedUpsellClicked implements BaseEvent {
  event_type = 'suggestedUpsellClicked';

  constructor(
    public event_properties?: SuggestedUpsellClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuggestedUpsellModalBugsArchived implements BaseEvent {
  event_type = 'suggestedUpsellModalBugsArchived';

  constructor(
    public event_properties?: SuggestedUpsellModalBugsArchivedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuggestedUpsellModalClickedUpgrade implements BaseEvent {
  event_type = 'suggestedUpsellModalClickedUpgrade';

  constructor(
    public event_properties?: SuggestedUpsellModalClickedUpgradeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuggestedUpsellModalOpened implements BaseEvent {
  event_type = 'suggestedUpsellModalOpened';

  constructor(
    public event_properties?: SuggestedUpsellModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SurveyCreated implements BaseEvent {
  event_type = 'surveyCreated';

  constructor(
    public event_properties?: SurveyCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SurveyLaunched implements BaseEvent {
  event_type = 'surveyLaunched';

  constructor(
    public event_properties?: SurveyLaunchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TagChanged implements BaseEvent {
  event_type = 'tagChanged';

  constructor(
    public event_properties: TagChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketArchived implements BaseEvent {
  event_type = 'ticketArchived';

  constructor(
    public event_properties?: TicketArchivedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketClosed implements BaseEvent {
  event_type = 'ticketClosed';

  constructor(
    public event_properties?: TicketClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketDeleted implements BaseEvent {
  event_type = 'ticketDeleted';

  constructor(
    public event_properties?: TicketDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketDueDateSet implements BaseEvent {
  event_type = 'ticketDueDateSet';

  constructor(
    public event_properties?: TicketDueDateSetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketPriorityChanged implements BaseEvent {
  event_type = 'ticketPriorityChanged';

  constructor(
    public event_properties: TicketPriorityChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketRestored implements BaseEvent {
  event_type = 'ticketRestored';

  constructor(
    public event_properties?: TicketRestoredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketStatusChanged implements BaseEvent {
  event_type = 'ticketStatusChanged';

  constructor(
    public event_properties?: TicketStatusChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TicketTypeChanged implements BaseEvent {
  event_type = 'ticketTypeChanged';

  constructor(
    public event_properties?: TicketTypeChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TranslationLanguageAdded implements BaseEvent {
  event_type = 'translationLanguageAdded';

  constructor(
    public event_properties: TranslationLanguageAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TranslationsCustomized implements BaseEvent {
  event_type = 'translationsCustomized';

  constructor(
    public event_properties?: TranslationsCustomizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpsellModalDismissed implements BaseEvent {
  event_type = 'upsellModalDismissed';

  constructor(
    public event_properties?: UpsellModalDismissedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpsellModalPlanIntervalSelected implements BaseEvent {
  event_type = 'upsellModalPlanIntervalSelected';

  constructor(
    public event_properties?: UpsellModalPlanIntervalSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpsellModalPlanSelected implements BaseEvent {
  event_type = 'upsellModalPlanSelected';

  constructor(
    public event_properties?: UpsellModalPlanSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpsellModalShown implements BaseEvent {
  event_type = 'upsellModalShown';

  constructor(
    public event_properties?: UpsellModalShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserAssigned implements BaseEvent {
  event_type = 'userAssigned';

  constructor(
    public event_properties?: UserAssignedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserRegistered implements BaseEvent {
  event_type = 'userRegistered';

  constructor(
    public event_properties?: UserRegisteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserRoleSelected implements BaseEvent {
  event_type = 'userRoleSelected';

  constructor(
    public event_properties?: UserRoleSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WidgetCustomized implements BaseEvent {
  event_type = 'widgetCustomized';

  constructor(
    public event_properties?: WidgetCustomizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options?: LoadOptions): PromiseResult<void> {
    this.disabled = options?.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    const env = options?.environment ?? 'development';
    const apiKey = options?.client?.apiKey ?? ApiKey[env];

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Set Group for the current user
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  setGroup(groupType: string, groupName: string | string[], options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.setGroup(groupType, groupName, options);
  }

  /**
   * Identify a group and set group properties.
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  groupIdentify(
    groupType: string,
    groupName: string | string[],
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * activityLogOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/activityLogOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  activityLogOpened(
    properties?: ActivityLogOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActivityLogOpened(properties), options);
  }

  /**
   * boardFiltered
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/boardFiltered)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  boardFiltered(
    options?: EventOptions,
  ) {
    return this.track(new BoardFiltered(), options);
  }

  /**
   * boardSearched
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/boardSearched)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  boardSearched(
    options?: EventOptions,
  ) {
    return this.track(new BoardSearched(), options);
  }

  /**
   * boardSorted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/boardSorted)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  boardSorted(
    options?: EventOptions,
  ) {
    return this.track(new BoardSorted(), options);
  }

  /**
   * boardViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/boardViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. boardType)
   * @param options Amplitude event options.
   */
  boardViewed(
    properties: BoardViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BoardViewed(properties), options);
  }

  /**
   * brokenScreenshotReported
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/brokenScreenshotReported)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  brokenScreenshotReported(
    properties?: BrokenScreenshotReportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrokenScreenshotReported(properties), options);
  }

  /**
   * bugDetailsOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/bugDetailsOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  bugDetailsOpened(
    properties?: BugDetailsOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BugDetailsOpened(properties), options);
  }

  /**
   * composeNewMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/composeNewMessage)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  composeNewMessage(
    options?: EventOptions,
  ) {
    return this.track(new ComposeNewMessage(), options);
  }

  /**
   * contactDeleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/contactDeleted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  contactDeleted(
    properties?: ContactDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactDeleted(properties), options);
  }

  /**
   * contactDetailsViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/contactDetailsViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  contactDetailsViewed(
    properties?: ContactDetailsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactDetailsViewed(properties), options);
  }

  /**
   * contactEventsViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/contactEventsViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  contactEventsViewed(
    properties?: ContactEventsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactEventsViewed(properties), options);
  }

  /**
   * contactExported
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/contactExported)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  contactExported(
    properties?: ContactExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactExported(properties), options);
  }

  /**
   * contactsViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/contactsViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  contactsViewed(
    properties?: ContactsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactsViewed(properties), options);
  }

  /**
   * customDataOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/customDataOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  customDataOpened(
    properties?: CustomDataOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CustomDataOpened(properties), options);
  }

  /**
   * developerOptionsCustomized
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/developerOptionsCustomized)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  developerOptionsCustomized(
    properties?: DeveloperOptionsCustomizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeveloperOptionsCustomized(properties), options);
  }

  /**
   * domainVerificationStarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/domainVerificationStarted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  domainVerificationStarted(
    properties?: DomainVerificationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DomainVerificationStarted(properties), options);
  }

  /**
   * domainVerified
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/domainVerified)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  domainVerified(
    properties?: DomainVerifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DomainVerified(properties), options);
  }

  /**
   * dpaRequested
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/dpaRequested)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  dpaRequested(
    options?: EventOptions,
  ) {
    return this.track(new DpaRequested(), options);
  }

  /**
   * duplicateModalOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/duplicateModalOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  duplicateModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new DuplicateModalOpened(), options);
  }

  /**
   * duplicateOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/duplicateOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  duplicateOpened(
    options?: EventOptions,
  ) {
    return this.track(new DuplicateOpened(), options);
  }

  /**
   * duplicateUnlinked
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/duplicateUnlinked)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  duplicateUnlinked(
    properties?: DuplicateUnlinkedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DuplicateUnlinked(properties), options);
  }

  /**
   * environmentalDataOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/environmentalDataOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  environmentalDataOpened(
    properties?: EnvironmentalDataOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EnvironmentalDataOpened(properties), options);
  }

  /**
   * featureRequestShareModalOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/featureRequestShareModalOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  featureRequestShareModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new FeatureRequestShareModalOpened(), options);
  }

  /**
   * feedbackFlowAdded
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/feedbackFlowAdded)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  feedbackFlowAdded(
    properties?: FeedbackFlowAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackFlowAdded(properties), options);
  }

  /**
   * feedbackFlowEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/feedbackFlowEdited)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. feedbackFlows)
   * @param options Amplitude event options.
   */
  feedbackFlowEdited(
    properties: FeedbackFlowEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackFlowEdited(properties), options);
  }

  /**
   * feedbackItemsExported
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/feedbackItemsExported)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  feedbackItemsExported(
    options?: EventOptions,
  ) {
    return this.track(new FeedbackItemsExported(), options);
  }

  /**
   * feedbackTypeEditorOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/feedbackTypeEditorOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  feedbackTypeEditorOpened(
    properties?: FeedbackTypeEditorOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackTypeEditorOpened(properties), options);
  }

  /**
   * homeScreenViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/homeScreenViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  homeScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new HomeScreenViewed(), options);
  }

  /**
   * installationInstructionsViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/installationInstructionsViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  installationInstructionsViewed(
    properties?: InstallationInstructionsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstallationInstructionsViewed(properties), options);
  }

  /**
   * installationSkipped
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/installationSkipped)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  installationSkipped(
    options?: EventOptions,
  ) {
    return this.track(new InstallationSkipped(), options);
  }

  /**
   * installationStarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/installationStarted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. platform)
   * @param options Amplitude event options.
   */
  installationStarted(
    properties?: InstallationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstallationStarted(properties), options);
  }

  /**
   * integrationSetupCompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/integrationSetupCompleted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  integrationSetupCompleted(
    properties?: IntegrationSetupCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationSetupCompleted(properties), options);
  }

  /**
   * integrationSetupStarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/integrationSetupStarted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  integrationSetupStarted(
    properties?: IntegrationSetupStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationSetupStarted(properties), options);
  }

  /**
   * invitationAccepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/invitationAccepted)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  invitationAccepted(
    options?: EventOptions,
  ) {
    return this.track(new InvitationAccepted(), options);
  }

  /**
   * invitationRejected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/invitationRejected)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  invitationRejected(
    options?: EventOptions,
  ) {
    return this.track(new InvitationRejected(), options);
  }

  /**
   * manuallyForwardedToIntegration
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/manuallyForwardedToIntegration)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  manuallyForwardedToIntegration(
    properties?: ManuallyForwardedToIntegrationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ManuallyForwardedToIntegration(properties), options);
  }

  /**
   * manuallyForwardToIntegrationModalOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/manuallyForwardToIntegrationModalOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  manuallyForwardToIntegrationModalOpened(
    properties?: ManuallyForwardToIntegrationModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ManuallyForwardToIntegrationModalOpened(properties), options);
  }

  /**
   * markedAsDuplicate
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/markedAsDuplicate)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  markedAsDuplicate(
    properties?: MarkedAsDuplicateProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarkedAsDuplicate(properties), options);
  }

  /**
   * menuOptionAdded
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/menuOptionAdded)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  menuOptionAdded(
    properties?: MenuOptionAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MenuOptionAdded(properties), options);
  }

  /**
   * menuOptionsCustomized
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/menuOptionsCustomized)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. options)
   * @param options Amplitude event options.
   */
  menuOptionsCustomized(
    properties: MenuOptionsCustomizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MenuOptionsCustomized(properties), options);
  }

  /**
   * messageCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/messageCreated)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  messageCreated(
    properties?: MessageCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageCreated(properties), options);
  }

  /**
   * messageLaunched
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/messageLaunched)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  messageLaunched(
    properties?: MessageLaunchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageLaunched(properties), options);
  }

  /**
   * onboardingCompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/onboardingCompleted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  onboardingCompleted(
    properties?: OnboardingCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingCompleted(properties), options);
  }

  /**
   * onboardingStarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/onboardingStarted)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  onboardingStarted(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStarted(), options);
  }

  /**
   * openingHoursCustomized
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/openingHoursCustomized)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  openingHoursCustomized(
    properties?: OpeningHoursCustomizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpeningHoursCustomized(properties), options);
  }

  /**
   * organizationDeleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/organizationDeleted)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  organizationDeleted(
    options?: EventOptions,
  ) {
    return this.track(new OrganizationDeleted(), options);
  }

  /**
   * passwordVerified
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/passwordVerified)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  passwordVerified(
    options?: EventOptions,
  ) {
    return this.track(new PasswordVerified(), options);
  }

  /**
   * peopleInvited
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/peopleInvited)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. isOnboarding)
   * @param options Amplitude event options.
   */
  peopleInvited(
    properties: PeopleInvitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PeopleInvited(properties), options);
  }

  /**
   * peopleInvitedLinkClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/peopleInvitedLinkClicked)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  peopleInvitedLinkClicked(
    options?: EventOptions,
  ) {
    return this.track(new PeopleInvitedLinkClicked(), options);
  }

  /**
   * peopleRemoved
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/peopleRemoved)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. level)
   * @param options Amplitude event options.
   */
  peopleRemoved(
    properties: PeopleRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PeopleRemoved(properties), options);
  }

  /**
   * planSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/planSelected)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  planSelected(
    properties?: PlanSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlanSelected(properties), options);
  }

  /**
   * privacyPolicyViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/privacyPolicyViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param options Amplitude event options.
   */
  privacyPolicyViewed(
    options?: EventOptions,
  ) {
    return this.track(new PrivacyPolicyViewed(), options);
  }

  /**
   * projectDashboardViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/projectDashboardViewed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  projectDashboardViewed(
    properties?: ProjectDashboardViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectDashboardViewed(properties), options);
  }

  /**
   * projectDeleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/projectDeleted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  projectDeleted(
    properties?: ProjectDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProjectDeleted(properties), options);
  }

  /**
   * replaysOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/replaysOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  replaysOpened(
    properties?: ReplaysOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReplaysOpened(properties), options);
  }

  /**
   * securedIdentifyEnabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/securedIdentifyEnabled)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  securedIdentifyEnabled(
    properties?: SecuredIdentifyEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecuredIdentifyEnabled(properties), options);
  }

  /**
   * sendEmailOnFeedbackEnabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/sendEmailOnFeedbackEnabled)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  sendEmailOnFeedbackEnabled(
    properties?: SendEmailOnFeedbackEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendEmailOnFeedbackEnabled(properties), options);
  }

  /**
   * sendEmailToAssignedUsersEnabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/sendEmailToAssignedUsersEnabled)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  sendEmailToAssignedUsersEnabled(
    properties?: SendEmailToAssignedUsersEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendEmailToAssignedUsersEnabled(properties), options);
  }

  /**
   * sendEmailUpdateToTeamEnabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/sendEmailUpdateToTeamEnabled)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  sendEmailUpdateToTeamEnabled(
    properties?: SendEmailUpdateToTeamEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendEmailUpdateToTeamEnabled(properties), options);
  }

  /**
   * sendMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/sendMessage)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. messageType)
   * @param options Amplitude event options.
   */
  sendMessage(
    properties: SendMessageProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendMessage(properties), options);
  }

  /**
   * shareLinkCopied
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/shareLinkCopied)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  shareLinkCopied(
    properties?: ShareLinkCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareLinkCopied(properties), options);
  }

  /**
   * spamProtectionEnabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/spamProtectionEnabled)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  spamProtectionEnabled(
    properties?: SpamProtectionEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpamProtectionEnabled(properties), options);
  }

  /**
   * stripePortalOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/stripePortalOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  stripePortalOpened(
    properties?: StripePortalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StripePortalOpened(properties), options);
  }

  /**
   * subscriberListOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/subscriberListOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  subscriberListOpened(
    properties?: SubscriberListOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriberListOpened(properties), options);
  }

  /**
   * suggestedUpsellClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/suggestedUpsellClicked)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  suggestedUpsellClicked(
    properties?: SuggestedUpsellClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuggestedUpsellClicked(properties), options);
  }

  /**
   * suggestedUpsellModalBugsArchived
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/suggestedUpsellModalBugsArchived)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  suggestedUpsellModalBugsArchived(
    properties?: SuggestedUpsellModalBugsArchivedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuggestedUpsellModalBugsArchived(properties), options);
  }

  /**
   * suggestedUpsellModalClickedUpgrade
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/suggestedUpsellModalClickedUpgrade)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  suggestedUpsellModalClickedUpgrade(
    properties?: SuggestedUpsellModalClickedUpgradeProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuggestedUpsellModalClickedUpgrade(properties), options);
  }

  /**
   * suggestedUpsellModalOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/suggestedUpsellModalOpened)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  suggestedUpsellModalOpened(
    properties?: SuggestedUpsellModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuggestedUpsellModalOpened(properties), options);
  }

  /**
   * surveyCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/surveyCreated)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  surveyCreated(
    properties?: SurveyCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SurveyCreated(properties), options);
  }

  /**
   * surveyLaunched
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/surveyLaunched)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  surveyLaunched(
    properties?: SurveyLaunchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SurveyLaunched(properties), options);
  }

  /**
   * tagChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/tagChanged)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. tags)
   * @param options Amplitude event options.
   */
  tagChanged(
    properties: TagChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagChanged(properties), options);
  }

  /**
   * ticketArchived
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketArchived)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketArchived(
    properties?: TicketArchivedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketArchived(properties), options);
  }

  /**
   * ticketClosed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketClosed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketClosed(
    properties?: TicketClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketClosed(properties), options);
  }

  /**
   * ticketDeleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketDeleted)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketDeleted(
    properties?: TicketDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketDeleted(properties), options);
  }

  /**
   * ticketDueDateSet
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketDueDateSet)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketDueDateSet(
    properties?: TicketDueDateSetProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketDueDateSet(properties), options);
  }

  /**
   * ticketPriorityChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketPriorityChanged)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketPriorityChanged(
    properties: TicketPriorityChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketPriorityChanged(properties), options);
  }

  /**
   * ticketRestored
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketRestored)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketRestored(
    properties?: TicketRestoredProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketRestored(properties), options);
  }

  /**
   * ticketStatusChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketStatusChanged)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketStatusChanged(
    properties?: TicketStatusChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketStatusChanged(properties), options);
  }

  /**
   * ticketTypeChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/ticketTypeChanged)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  ticketTypeChanged(
    properties?: TicketTypeChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TicketTypeChanged(properties), options);
  }

  /**
   * translationLanguageAdded
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/translationLanguageAdded)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. language)
   * @param options Amplitude event options.
   */
  translationLanguageAdded(
    properties: TranslationLanguageAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TranslationLanguageAdded(properties), options);
  }

  /**
   * translationsCustomized
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/translationsCustomized)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  translationsCustomized(
    properties?: TranslationsCustomizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TranslationsCustomized(properties), options);
  }

  /**
   * upsellModalDismissed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/upsellModalDismissed)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. isOnboarding)
   * @param options Amplitude event options.
   */
  upsellModalDismissed(
    properties?: UpsellModalDismissedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpsellModalDismissed(properties), options);
  }

  /**
   * upsellModalPlanIntervalSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/upsellModalPlanIntervalSelected)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. interval)
   * @param options Amplitude event options.
   */
  upsellModalPlanIntervalSelected(
    properties?: UpsellModalPlanIntervalSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpsellModalPlanIntervalSelected(properties), options);
  }

  /**
   * upsellModalPlanSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/upsellModalPlanSelected)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. isOnboarding)
   * @param options Amplitude event options.
   */
  upsellModalPlanSelected(
    properties?: UpsellModalPlanSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpsellModalPlanSelected(properties), options);
  }

  /**
   * upsellModalShown
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/upsellModalShown)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. isOnboarding)
   * @param options Amplitude event options.
   */
  upsellModalShown(
    properties?: UpsellModalShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpsellModalShown(properties), options);
  }

  /**
   * userAssigned
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/userAssigned)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. bugId)
   * @param options Amplitude event options.
   */
  userAssigned(
    properties?: UserAssignedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserAssigned(properties), options);
  }

  /**
   * userRegistered
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/userRegistered)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  userRegistered(
    properties?: UserRegisteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserRegistered(properties), options);
  }

  /**
   * userRoleSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/userRoleSelected)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. role)
   * @param options Amplitude event options.
   */
  userRoleSelected(
    properties?: UserRoleSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserRoleSelected(properties), options);
  }

  /**
   * widgetCustomized
   *
   * [View in Tracking Plan](https://data.amplitude.com/gleap/Gleap/events/main/latest/widgetCustomized)
   *
   * Owner: Lukas Böhler
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  widgetCustomized(
    properties?: WidgetCustomizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WidgetCustomized(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
