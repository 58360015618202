import './Modal.scss';
import Modal from 'react-modal';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import ManuallyAddBugModal from 'components/Modals/ManuallyAddBugModal/ManuallyAddBugModal';
import BugDetail from 'components/BugDetail/BugDetail';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import UpsellModal from 'components/UpsellModal/UpsellModal';
import OnBoarding from 'pages/private/tutorial/OnBoarding';
import Replay from 'components/BugDetail/Replay/Replay';
import ChangePasswordModal from '../../../pages/private/Profile/ChangePasswordModal/ChangePasswordModal';
import InvitePeopleModal from '../InvitePeopleModal/InvitePeopleModal';
import SuggestChangeSubscriptionModal from '../SuggestChangeSubscriptionModal/SuggestChangeSubscriptionModal';
import AddMenuOptionModal from '../AddMenuOptionModal/AddMenuOptionModal';
import VoteModal from '../VoteModal/VoteModal';
import CreateFeatureRequestModal from '../CreateFeatureRequestModal/CreateFeatureRequestModal';
import FeatureBoardShareModal from '../FeatureBoardShareModal/FeatureBoardShareModal';
import ShowEventDataModal from '../ShowEventDataModal/ShowEventDataModal';
import DuplicateSearchModal from '../DuplicateSearchModal/DuplicateSearchModal';
import InvitePeopleOnboardingModal from '../InvitePeopleOnboardingModal/InvitePeopleOnboardingModal';
import FeatureRequestSubscribeUserModal from '../FeatureRequestSubscribeUserModal/FeatureRequestSubscribeUserModal';
import ManuallySendIntegrationModal from '../ManuallySendIntegrationModal/ManuallySendIntegrationModal';
import FeedbackTypeEditor from 'components/FeedbackTypeEditor/FeedbackTypeEditor';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ProjectOutboundTemplates from 'pages/private/ProjectOutbounds/ProjectOutboundTemplates/ProjectOutboundTemplates';
import ComposeMessageModal from '../ComposeMessageModal/ComposeMessageModal';
import WidgetAppSettingsModal from '../WidgetAppSettingsModal/WidgetAppSettingsModal';
import CancelPlanModal from '../CancelPlanModal/CancelPlanModal';
import CreateHelpcenterCollectionModal from '../CreateHelpcenterCollectionModal/CreateHelpcenterCollectionModal';
import SurveyPreviewModal from '../SurveyPreviewModal/SurveyPreviewModal';
import SDKInstallationModal from '../SDKInstallationModal/SDKInstallationModal';
import GlobalSearchModal from '../GlobalSearchModal/GlobalSearchModal';
import EditFeatureRequestModal from '../EditFeatureRequestModal/EditFeatureRequestModal';
import CreateQAModal from '../CreateQAModal/CreateQAModal';
import ActionEditorModal from '../ActionEditorModal/ActionEditorModal';
import EditQAModal from '../EditQAModal/EditQAModal';
import EditInboxView from '../EditInboxView/EditInboxView';

interface ModalScreenProps {
  modalStore?: ModalStore;
}

const getComponentForPath = (path) => {
  var children;
  switch (path) {
    case MODALTYPE.INVITE_PEOPLE:
      children = <InvitePeopleModal />;
      break;
    case MODALTYPE.INVITE_PEOPLE_ONBOARDING:
      children = <InvitePeopleOnboardingModal />;
      break;
    case MODALTYPE.CHANGE_PASSWORD:
      children = <ChangePasswordModal />;
      break;
    case MODALTYPE.SUGGESTSUBSCRIPTION:
      children = <SuggestChangeSubscriptionModal />;
      break;
    case MODALTYPE.SHOW_EVENT_DATA:
      children = <ShowEventDataModal />;
      break;
    case MODALTYPE.SDK_INSTALLATION_TUTORIAL:
      children = <SDKInstallationModal />;
      break;
    case MODALTYPE.CREATE_QA:
      children = <CreateQAModal />;
      break;
    case MODALTYPE.EDIT_QA:
      children = <EditQAModal />;
      break;
    case MODALTYPE.EDIT_INBOX_VIEW:
      children = <EditInboxView />;
      break;
    case MODALTYPE.ACTIONEDITOR:
      children = <ActionEditorModal />;
      break;
    case MODALTYPE.MANUALLY_ADD_BUG:
      children = <ManuallyAddBugModal />;
      break;
    case MODALTYPE.BUG_DETAIL:
      children = <BugDetail shared={false} />;
      break;
    case MODALTYPE.UPSELL_MODAL:
      children = <UpsellModal />;
      break;
    case MODALTYPE.ONBOARDING:
      children = <OnBoarding />;
      break;
    case MODALTYPE.FEEDBACK_TYPE:
      children = <FeedbackTypeEditor />;
      break;
    case MODALTYPE.ADDMENUOPTION:
      children = <AddMenuOptionModal />;
      break;
    case MODALTYPE.VOTE:
      children = <VoteModal />;
      break;
    case MODALTYPE.SUBSCRIBE_USER:
      children = <FeatureRequestSubscribeUserModal />;
      break;
    case MODALTYPE.CREATE_FEATURE_REQUEST:
      children = <CreateFeatureRequestModal />;
      break;
    case MODALTYPE.CANCEL_PLAN:
      children = <CancelPlanModal />;
      break;
    case MODALTYPE.FEATURE_BOARD_SHARE:
      children = <FeatureBoardShareModal />;
      break;
    case MODALTYPE.DUPLICATE_SEARCH:
      children = <DuplicateSearchModal />;
      break;
    case MODALTYPE.MANUALLY_SEND_INTEGRATION:
      children = <ManuallySendIntegrationModal />;
      break;
    case MODALTYPE.REPLAYS:
      children = <Replay shared />;
      break;
    case MODALTYPE.CREATE_SURVEY:
      children = <ProjectOutboundTemplates />;
      break;
    case MODALTYPE.COMPOSE_MESSAGE:
      children = <ComposeMessageModal />;
      break;
    case MODALTYPE.WIDGET_APP_SETTINGS:
      children = <WidgetAppSettingsModal />;
      break;
    case MODALTYPE.CREATE_HELPCENTER_COLLECTION:
      children = <CreateHelpcenterCollectionModal />;
      break;
    case MODALTYPE.SURVEY_PREVIEW:
      children = <SurveyPreviewModal />;
      break;
    case MODALTYPE.EDIT_FEATURE_REQUEST:
      children = <EditFeatureRequestModal />;
      break;
    default:
      children = <div />;
      break;
  }

  return children;
};

const ModalScreen = ({ modalStore }: ModalScreenProps) => {
  let children: any = null;
  let nestedChildren: any = null;

  const location = useLocation();

  children = getComponentForPath(modalStore!.currentPath);
  nestedChildren = getComponentForPath(modalStore!.currentNestedPath);

  const [lastStateWasInvisible, setLastStateWasInvisible] = useState(true);
  const [lastKnownPath, setLastKnownPath] = useState('');

  useEffect(() => {
    if (modalStore?.currentPath === MODALTYPE.INVISIBLE) {
      setLastStateWasInvisible(true);
      setLastKnownPath(location.pathname);
      return;
    }

    if (
      !lastStateWasInvisible &&
      location.pathname.length !== lastKnownPath.length
    ) {
      modalStore?.closeModal();

      return;
    }

    setLastKnownPath(location.pathname);
    setLastStateWasInvisible(false);
  }, [location]);

  const canCloseModal = () => {
    return (
      modalStore!.currentPath !== MODALTYPE.ONBOARDING &&
      modalStore!.currentPath !== MODALTYPE.INVITE_PEOPLE_ONBOARDING &&
      modalStore!.currentPath !== MODALTYPE.FEEDBACK_TYPE
    );
  };

  const modalClassName = classNames('modal', {
    'modal--full-width-right': modalStore!.currentPath === MODALTYPE.BUG_DETAIL,
    'modal--mid-width-right':
      modalStore!.currentPath === MODALTYPE.ACTIONEDITOR,
    'modal--full-width': modalStore!.currentPath === MODALTYPE.CREATE_SURVEY,
    'modal--message-compose':
      modalStore!.currentPath === MODALTYPE.COMPOSE_MESSAGE,
    'modal--vote': modalStore!.currentPath === MODALTYPE.VOTE,
    'modal--full-width-autoheight':
      !canCloseModal() ||
      modalStore!.currentPath === MODALTYPE.SURVEY_PREVIEW ||
      modalStore!.currentPath === MODALTYPE.INVITE_PEOPLE ||
      modalStore!.currentPath === MODALTYPE.FEATURE_BOARD_SHARE,
    'modal--xl-width':
      modalStore!.currentPath === MODALTYPE.SHOW_EVENT_DATA ||
      modalStore!.currentPath === MODALTYPE.ADDMENUOPTION ||
      modalStore!.currentPath === MODALTYPE.SDK_INSTALLATION_TUTORIAL,
    'modal--feedbacktype': modalStore!.currentPath === MODALTYPE.FEEDBACK_TYPE,
    'modal--no-wrapper':
      modalStore!.currentPath === MODALTYPE.CREATE_HELPCENTER_COLLECTION,
    'modal--onboarding': modalStore!.currentPath === MODALTYPE.ONBOARDING,
    'modal--upsellmodal': modalStore!.currentPath === MODALTYPE.UPSELL_MODAL,
  });

  const nestedModalClassName = classNames('modal', {
    'modal--xl-width':
      modalStore!.currentNestedPath === MODALTYPE.DUPLICATE_SEARCH ||
      modalStore!.currentNestedPath === MODALTYPE.SHOW_EVENT_DATA ||
      modalStore!.currentNestedPath === MODALTYPE.MANUALLY_SEND_INTEGRATION,
    'modal--full': modalStore!.currentNestedPath === MODALTYPE.REPLAYS,
    'modal--side-edit':
      modalStore!.currentNestedPath === MODALTYPE.EDIT_FEATURE_REQUEST,
  });

  const closeModal = () => {
    modalStore!.closeModal();
  };

  if (modalStore?.currentPath === MODALTYPE.GLOBAL_SEARCH) {
    return <GlobalSearchModal />;
  }

  return (
    <>
      <Modal
        id={`MODAL-${MODALTYPE.VOTE}`}
        className={modalClassName}
        isOpen={modalStore?.currentPath !== MODALTYPE.INVISIBLE}
        ariaHideApp={false}
        onRequestClose={() => {
          if (canCloseModal()) {
            closeModal();
          }
        }}
        closeTimeoutMS={500}
      >
        {children}
        {canCloseModal() &&
          modalStore!.currentPath !== MODALTYPE.ACTIONEDITOR && (
            <div className="close-button" onClick={() => closeModal()}>
              <i className="fa-solid fa-xmark" />
            </div>
          )}
      </Modal>
      <Modal
        className={nestedModalClassName}
        isOpen={modalStore?.currentNestedPath !== MODALTYPE.INVISIBLE}
        ariaHideApp={false}
        onRequestClose={() => closeModal()}
        closeTimeoutMS={500}
      >
        {nestedChildren}
        <div className="close-button" onClick={() => closeModal()}>
          <i className="fa-solid fa-xmark" />
        </div>
      </Modal>
    </>
  );
};

export default inject('modalStore')(observer(ModalScreen));
