import classNames from 'classnames';
import './Title.scss';

interface TitleProps {
  label: string;
  style?: any;
  className?: any;
  small?: boolean;
}

export const Title = ({
  label,
  style,
  className,
  small = false,
}: TitleProps) => {
  const titleClassName = classNames(
    {
      title: true,
      'title--small': small,
    },
    className,
  );

  return (
    <div className={titleClassName} style={style}>
      {label}
    </div>
  );
};
