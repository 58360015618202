import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  Route, Routes, useLocation, useNavigate, useParams
} from 'react-router';
import { toast } from 'react-toastify';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import ProjectFeatureRequestSettings from '../ProjectFeatureRequests/ProjectFeatureRequestSettings';
import ProjectFeedback from '../ProjectFeedback/ProjectFeedback';
import './ProjectInboundFeatureRequests.scss';

interface ProjectInboundFeatureRequestsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function ProjectInboundFeatureRequests({ projectStore, modalStore }: ProjectInboundFeatureRequestsProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      if (projectId) {
        await projectStore!.loadProjectById(projectId);
      }
    } catch (err) {
      toast.error('Could not load project');
      navigate('/dashboard');
    }
  };

  if (!currentProject) {
    return <></>;
  }

  const sidebarCount = (projectType) => {
    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (projectStore?.unreadItems[itemKey] && projectStore?.unreadItems[itemKey].type === projectType) {
        unreadCount++;
        if (unreadCount > 9) {
          return 10;
        }
      }
    }

    return unreadCount;
  };

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 3 && parts[3] === path) {
        return true;
      }
    }

    return false;
  };

  const isActiveSubPath = (path, subpath) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[3] === path && parts[4] === subpath) {
        return true;
      }
    }

    return false;
  };

  const pathIsActive = isActive("featurerequests");
  const settingsIsActive = isActiveSubPath("featurerequests", "settings");

  return (
    <SidenavContainer className="sidenav-container--small">
      <SubSidenav title="Roadmap">
        <SimpleSidenavElement
          onClick={() => {
            navigate(`/projects/${projectId}/featurerequests`);
          }}
          isActive={pathIsActive && !settingsIsActive}
          key="featurerequests"
          name="Board"
          faIcon='lightbulb'
          notificationCount={sidebarCount("FEATURE_REQUEST")}
        />
        <SimpleSidenavElement
          onClick={() => {
            navigate(`/projects/${projectId}/featurerequests/settings`);
          }}
          isActive={pathIsActive && settingsIsActive}
          key="settings"
          faIcon='share'
          name="Share settings"
        />
      </SubSidenav>
      <Routes>
        <Route path="/settings" element={<ProjectFeatureRequestSettings />} />
        <Route path="/" element={<ProjectFeedback forceTypePath="featurerequests" />} />
        <Route
          path="/:shareToken"
          element={<ProjectFeedback forceTypePath="featurerequests" />}
        />
      </Routes>
    </SidenavContainer>
  );
}

export default inject('projectStore', 'modalStore')(observer(ProjectInboundFeatureRequests));
