import { ampli } from 'ampli';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { BugStore } from 'stores/private/BugStore';
import Swal from 'sweetalert2';
import reportIcon from '../../../assets/icons/np_flag_888694_FFFFFF.svg';
import { ReactComponent as ScreenshotGenerating } from '../../../assets/icons/screenshotGenerating.svg';
import './BugScreenshot.scss';

interface BugScreenshotProps {
  bugStore?: BugStore;
  shared?: boolean;
}

const BugScreenshot = ({ bugStore, shared }: BugScreenshotProps) => {
  const [showScreenshotLightbox, setShowScreenshotLightbox] = useState(false);
  const bug = bugStore!.currentBug;
  const [bugImage, setBugImage] = useState('');

  if (!bug) {
    return <></>;
  }

  const renderScreenshot = () => {
    if (bug.generatingScreenshot) {
      return (
        <>
          <ScreenshotGenerating className="screenshot-image-generator" />
        </>
      );
    }

    if (bug.screenshotUrl) {
      return (
        <div
          className="screenshot-image-container"
          onClick={() => {
            setShowScreenshotLightbox(!showScreenshotLightbox);
          }}
        >
          <img
            alt="bug preview"
            className="bug-preview-image"
            src={bug.screenshotUrl}
          />
          {renderScreenshotIssueReporter()}
        </div>
      );
    }

    return null;
  };

  const renderScreenshotIssueReporter = () => {
    if (shared || bug.screenshotBroke) {
      return <></>;
    }

    const tooltipData = `<div class="reportscreenshot-tooltip">Broken screenshot 😵? Report it to help improve the quality 💪.</div>`;

    return (
      <div
        className="report-screenshot"
        data-for="infoTooltip"
        data-tip={tooltipData}
        onClick={(e) => {
          e.stopPropagation();
          bugStore!.updateBug(bug.id, {
            screenshotBroke: new Date(),
          });
          ampli.brokenScreenshotReported({
            bugId: bug.id
          });
          Swal.fire({
            text: 'There are a few things you could try to fix common screenshot rendering issues. Do you want to open the documentation?',
            showCancelButton: true,
            confirmButtonText: `Open docs`,
            denyButtonText: `Cancel`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.location.href = 'https://docs.gleap.io/javascript/rendering-issues';
            }
          });
        }}
      >
        <img src={reportIcon} alt="Report screenshot" />
      </div>
    );
  };

  return (
    <div className="screenshot-container">
      {renderScreenshot()}
      {showScreenshotLightbox && (
        <Lightbox
          mainSrc={bug.screenshotUrl}
          onCloseRequest={() => setShowScreenshotLightbox(false)}
        />
      )}
    </div>
  );
};

export default inject('bugStore')(observer(BugScreenshot));
