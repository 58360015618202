import React, { useState } from 'react';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import './DropDown.scss';
import classNames from 'classnames';
import arrowDownIcon from '../../assets/icons/arrow-down.svg';

interface DropDownProps {
  items: any;
  onClick: any;
  disabled?: boolean;
  label: string;
  className?: any;
}

const DropDown = ({
  items,
  onClick,
  disabled = false,
  label,
  className,
}: DropDownProps) => {
  const [header, setHeader] = useState(label);

  const triggerContainer = classNames(
    {
      'trigger-container-mainmenu': true,
    },
    className,
  );

  const onSelect = (index) => {
    const selectedItem = items[index];
    setHeader(selectedItem.name);
    if (selectedItem.id) {
      onClick(selectedItem.id);
    } else if (selectedItem.gid) {
      onClick(selectedItem.gid);
    }
  };

  const renderTrigger = (props) => {
    return (
      <>
        <div className={triggerContainer} onClick={props.onClick}>
          <div className="trigger">
            <div>{props!.children}</div>
          </div>
          <img className="arrow-down" alt="arrow" src={arrowDownIcon} />
        </div>
      </>
    );
  };

  const renderMenu = (props) => <Dropdown.Menu {...props} className="menu" />;

  const renderMenuItem = (props) => {
    return (
      <div className="menu-item-container" onClick={props.onClick}>
        {props!.children}
      </div>
    );
  };

  return (
    <div className="dropdown-component">
      <Dropdown
        onSelect={onSelect}
        disabled={disabled}
        style={{ width: '100%' }}
      >
        <Dropdown.Toggle title={header} componentClass={renderTrigger} />
        <Dropdown.Menu componentClass={renderMenu}>
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                eventKey={index}
                componentClass={renderMenuItem}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropDown;
