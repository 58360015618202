import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Youtube from '@tiptap/extension-youtube';
import { generateHTML } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import { CustomLink } from 'components/Editors/RichTextEditor/extensions/CustomLink';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Iframe from 'services/Iframe';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedNewsStorePublic } from 'stores/public/SharedNewsStorePublic';
import { SharedPoweredBy } from '../SharedPoweredBy/SharedPoweredBy';
import './SharedNewsDetail.scss';

interface SharedNewsDetailProps {
  sharedNewsStorePublic?: SharedNewsStorePublic;
  sharedBoardStorePublic?: SharedBoardStorePublic;
}

const SharedNewsDetail = ({
  sharedNewsStorePublic,
  sharedBoardStorePublic,
}: SharedNewsDetailProps) => {
  const { newsId, boardShareToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (sharedBoardStorePublic?.projectSharedToken && newsId) {
      sharedNewsStorePublic?.loadNewsDetails(
        sharedBoardStorePublic.projectSharedToken,
        newsId,
        sharedBoardStorePublic.gleapId ?? '',
        sharedBoardStorePublic.gleapHash ?? '',
      );
    }
  }, [sharedBoardStorePublic?.projectSharedToken, newsId]);

  const news = sharedNewsStorePublic?.newsDetails;

  const renderNewsDetails = () => {
    if (sharedNewsStorePublic?.loadingNewsDetails || !news) {
      return (
        <div className="news-item">
          <div className="news-fake-image-loading">
            <PublicSkeleton
              width="100%"
              height="360px"
              style={{
                borderRadius: '12px',
              }}
            />
          </div>
          <div className="news-item--content">
            <div className="news-item--title">
              <PublicSkeleton width="220px" height="24px" />
            </div>
            <div className="news-item--meta">
              <PublicSkeleton
                width="24px"
                height="24px"
                style={{
                  borderRadius: '100%',
                  marginRight: '8px',
                }}
              />
              <PublicSkeleton
                width="76px"
                height="16px"
                style={{
                  marginRight: '16px',
                }}
              />
              <PublicSkeleton
                width="46px"
                height="16px"
                style={{
                  marginRight: '8px',
                }}
              />
            </div>
            <div className="news-item--content--body">
              <PublicSkeleton width="276px" height="16px" count={4} />
            </div>
          </div>
        </div>
      );
    }

    var content = '';
    try {
      if (news.message) {
        content = generateHTML(news.message, [
          StarterKit.configure({ codeBlock: {}, heading: false }),
          Heading.configure({
            levels: [1, 2, 3],
          }),
          Mention,
          Placeholder,
          CustomLink,
          Image,
          TextStyle,
          Youtube.configure({ controls: true }),
          Iframe,
        ]);
        content = content.replaceAll('\n', '<br>');
      }
    } catch (exp) {}

    return (
      <div className="news-item" key={news.id}>
        <img
          className="news-item--image"
          src={news.coverImageUrl}
          alt={news.subject}
        />
        <div className="news-item--content">
          <div className="news-item--title">{news.subject}</div>
          <div className="news-item--meta">
            {news.sender && (
              <>
                <img src={news.sender.profileImageUrl} />
                <span>
                  {news.sender.firstName} •{' '}
                  {news.sentAt
                    ? moment(news.sentAt).fromNow()
                    : moment(news.createdAt).fromNow()}
                </span>
              </>
            )}
          </div>
          <div
            className="news-item--content--body"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="shared-news-details-container">
      <div className="shared-news-details">
        <div className="news-detail-header">
          <div
            className="news-detail-header-breadcrumbs news-detail-header-breadcrumbs--active"
            onClick={() => {
              navigate(`/sharedboard/${boardShareToken}/news`);
            }}
          >
            {sharedBoardStorePublic?.translateText('News')}
          </div>
          <i className="fa-sharp fa-solid fa-chevron-right"></i>
          <div className="news-detail-header-breadcrumbs">
            {news?.subject ?? ''}
          </div>
        </div>
        {renderNewsDetails()}
      </div>
      <div className="news-footer">
        {!sharedBoardStorePublic?.currentProject?.flowConfig?.hideBranding && (
          <SharedPoweredBy />
        )}
      </div>
    </div>
  );
};

export default inject(
  'sharedNewsStorePublic',
  'sharedBoardStorePublic',
)(observer(SharedNewsDetail));
