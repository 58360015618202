import * as React from 'react';
import './RecentEvents.scss';
import { SessionStore } from 'stores/private/SessionStore';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import RecentEventsItem from './RecentEventsItem';

interface RecentEventsProps {
  sessionStore?: SessionStore;
  projectStore?: ProjectStore;
  contactId: string;
}

const RecentEvents = ({
  sessionStore,
  projectStore,
  contactId,
}: RecentEventsProps) => {
  const limit = React.useRef(5);

  const loadEvents = (silent = true) => {
    if (projectStore?.currentProject && contactId) {
      sessionStore!.loadEventsOfSession(contactId, limit.current, silent);
    }
  };

  React.useEffect(() => {
    loadEvents(false);

    const intervalRef = setInterval(() => {
      loadEvents();
    }, 7500);
    return () => clearInterval(intervalRef);
  }, []);

  const sessionEvents = sessionStore?.sessionEvents;

  return (
    <div className="recent-events card">
      <div className="recent-events-body">
        {sessionEvents?.map((event) => {
          return <RecentEventsItem key={event.id} event={event} />;
        })}
        {!sessionEvents ||
          (sessionEvents?.length <= 0 && !sessionStore?.loadingSessionEvents && (
            <div key="norecords" className="text centered p-20">
              No events recorded yet.
            </div>
          ))}
        {sessionStore?.loadingSessionEvents && (
          <div className="recent-events-loading">
            <LoadingAnimation />
          </div>
        )}
      </div>
      <div className="recent-events-footer">
        {sessionEvents && sessionEvents?.length > 0 && (
          <div
            className="sidebar-more-button"
            onClick={() => {
              limit.current += 5;
              loadEvents(false);
            }}
          >
            Load more
          </div>
        )}
      </div>
    </div>
  );
};

export default inject('sessionStore', 'projectStore')(observer(RecentEvents));
