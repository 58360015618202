import React, { useEffect, useState } from 'react';
import './WidgetButtonPreview.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Loading from 'components/Loading/Loading';
import { UsersStore } from 'stores/private/UsersStore';
import { toast } from 'react-toastify';
import Gleap from 'gleap';
import { calculateContrast, calculateShadeColor } from 'helper/Color';

interface WidgetButtonPreviewProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

function WidgetButtonPreview({ projectStore, usersStore }: WidgetButtonPreviewProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    }
  }, []);

  if (isLoading || !flowConfig) {
    return <Loading />;
  }

  const showDemoNotice = () => {
    toast.success('You clicked your demo widget 🥳.');
  };

  const cornerRadius = flowConfig.borderRadius ?? 20;
  const backgroundColor = flowConfig.backgroundColor ?? '#ffffff';
  const contrastBackgroundColor = calculateContrast(backgroundColor);
  const subTextColor =
    contrastBackgroundColor === '#ffffff'
      ? calculateShadeColor(backgroundColor, 100)
      : calculateShadeColor(backgroundColor, -100);

  return (
    <div className="widget-button-preview">
      {flowConfig.feedbackButtonPosition &&
        flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
          <div
            className="widget-bugbattle--feedback-button"
            onClick={() => {
              showDemoNotice();
            }}
          >
            <div
              className={`widget-bugbattle--feedback-button-text ${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_LEFT'
                ? 'widget-bugbattle--feedback-button-text--left'
                : ''
                }${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_BOTTOM'
                  ? 'widget-bugbattle--feedback-button-text--bottom'
                  : ''
                }`}
              style={{
                backgroundColor: flowConfig.buttonColor ?? '#485bff',
                color: calculateContrast(flowConfig.buttonColor ?? '#485bff'),
                borderTopLeftRadius: cornerRadius * 0.4,
                borderTopRightRadius: cornerRadius * 0.4,
              }}
            >
              {flowConfig.widgetButtonText}
            </div>
          </div>
        )}
      <div className="widget-button-preview-browser">
        <div className="widget-button-preview-browser-head">
          <div className="widget-button-preview-browser-head-close" />
          <div className="widget-button-preview-browser-head-minimize" />
          <div className="widget-button-preview-browser-head-maximize" />
        </div>
        <div className="widget-button-preview-browser-body">
          {flowConfig.feedbackButtonPosition &&
            flowConfig.feedbackButtonPosition !== 'BUTTON_NONE' &&
            !flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
              <div
                className={`widget-bugbattle--feedback-new-button ${flowConfig.feedbackButtonPosition === 'BOTTOM_LEFT'
                  ? 'widget-bugbattle--feedback-new-button--left'
                  : ''
                  }`}
                  style={{
                    left: `${flowConfig.buttonX ?? 20}px`,
                    right: `${flowConfig.buttonX ?? 20}px`,
                    bottom: `${flowConfig.buttonY ?? 20}px`,
                  }}
              >
                <div
                  className="widget-bugbattle--feedback-new-button-button"
                  style={{
                    backgroundColor: flowConfig.buttonColor ?? '#485bff',
                    color: calculateContrast(
                      flowConfig.buttonColor ?? '#485bff',
                    ),
                  }}
                  onClick={() => {
                    showDemoNotice();
                  }}
                >
                  {flowConfig.buttonLogo && flowConfig.buttonLogo.length > 0 ? (
                    <img src={flowConfig.buttonLogo} />
                  ) : (
                    <svg
                      className="widget-default-icon"
                      width="700px"
                      height="700px"
                      viewBox="0 0 700 700"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          transform="translate(77.000000, 119.000000)"
                          fill={calculateContrast(
                            flowConfig.buttonColor ?? '#485bff',
                          )}
                          fillRule="nonzero"
                        >
                          <path d="M476.856373,0 L68.122339,0 C30.601832,0 0,30.5935323 0,67.1461527 L0,372.655827 C0,410.166158 30.601832,439.80198 68.122339,439.80198 L170.305847,439.80198 L170.305847,528.23059 C170.305847,538.605788 182.280477,544.671288 190.657396,538.552581 L323.602398,438.844269 L476.877661,438.844269 C514.398168,438.844269 545,408.250737 545,371.698116 L545,67.1461527 C544.978712,30.5935323 515.28163,0 476.856373,0 Z"></path>
                        </g>
                      </g>
                    </svg>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default inject('projectStore', 'usersStore')(observer(WidgetButtonPreview));
