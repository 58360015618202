import React from 'react';
import './PrimaryButton.scss';
import classNames from 'classnames';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

interface PrimaryButtonProps {
  label?: string;
  onClick?: any;
  submit?: any;
  form?: string;
  icon?: string;
  small?: boolean;
  className?: any;
  fullWidth?: boolean;
  iconSideRight?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const PrimaryButton = ({
  label,
  onClick,
  submit,
  form,
  className,
  icon,
  small = false,
  fullWidth = false,
  disabled = false,
  isLoading = false,
  iconSideRight = false,
}: PrimaryButtonProps) => {
  const primaryButtonClassName = classNames(
    {
      'primary-button': true,
      'primary-button--full-width': fullWidth,
      'primary-button--disabled': disabled,
      'primary-button--loading': isLoading,
      'primary-button--icon-right': iconSideRight,
      'primary-button--small': small,
    },
    className,
  );

  if (isLoading) {
    return (
      <div className={primaryButtonClassName}>
        <LoadingAnimationSmall />
      </div>
    );
  }

  if (submit) {
    return (
      <button type="submit" form={form} className={primaryButtonClassName}>
        {!iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
        {label}
        {iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
      </button>
    );
  }

  return (
    <button
      type="button"
      className={primaryButtonClassName}
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
        return true;
      }}
    >
      {!iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
      {label}
      {iconSideRight && icon && <i className={`icon fa-solid fa-${icon}`} />}
    </button>
  );
};

export default PrimaryButton;
