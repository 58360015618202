import { Editor } from '@tiptap/react';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectMessageTemplates.scss';

const ProjectMessageTemplatesItem = ({ menuItem, onSave, onDelete }: any) => {
  const [title, setTitle] = useState(menuItem.title);
  const [content, setContent] = useState(menuItem.content);
  const [toggleState, setToggleState] = useState(false);
  const editorRef: MutableRefObject<Editor | null> = useRef(null);

  return (
    <Collapsible
      trigger={<div className="action-item-header">{menuItem.title}</div>}
      transitionTime={200}
      overflowWhenOpen="initial"
      openedClassName="Collapsible--opened"
      onClose={() => {}}
    >
      <TextInput
        placeholder="Template name"
        type="text"
        error=""
        value={title}
        onChange={(text) => {
          setTitle(text);
        }}
      />
      <div className="template-editor">
        <div className="toolbar-wrapper">
          <Toolbar editor={editorRef?.current} aiStyle="agent" items={[ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.AI]} />
        </div>
        <EmailEditor
          editorRef={editorRef}
          onEditorReady={() => {
            setToggleState(!toggleState);
          }}
          content={content}
          inputContentChanged={(content) => {
            setContent(content);
          }}
        />
      </div>
      <div className="form-buttons mt-20">
        <PrimaryButton
          label="Save template"
          onClick={() => {
            onSave({
              title,
              content,
            });
          }}
        />
        <LinkButton
          iconSideRight={false}
          className="danger-button"
          label="Delete"
          icon={'trash'}
          onClick={() => {
            onDelete();
          }}
        />
      </div>
    </Collapsible>
  );
};

interface ProjectMessageTemplatesProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const ProjectMessageTemplates = ({
  modalStore,
  projectStore,
}: ProjectMessageTemplatesProps) => {
  const currentProject = projectStore?.currentProject;
  const [messageTemplates, setMessageTemplates] = useState([] as any[]);

  useEffect(() => {
    if (currentProject?.messageTemplates) {
      setMessageTemplates(currentProject?.messageTemplates ?? []);
    }
  }, [currentProject?.messageTemplates]);

  if (!currentProject) {
    return <></>;
  }

  const saveChanges = (newMessageTemplates: any[]) => {
    projectStore!.updateProject(projectStore!.currentProject!.id, {
      messageTemplates: newMessageTemplates,
    });
  };

  return (
    <PageContainer className="project-message-templates">
      <PageHeadLine title="Message templates" />
      <PageContent hasTitle>
        <div className="widget-general-settings widget-form-settings">
          <div className="widget-general-settings-content">
            <div>
              {messageTemplates.length === 0 && (
                <div className="no-templates">
                  <InfoBox>
                    Message templates are building blocks that you can use to
                    write messages at ⚡️-speed.
                  </InfoBox>
                </div>
              )}
              {messageTemplates.map((menuItem, key) => {
                return (
                  <ProjectMessageTemplatesItem
                    menuItem={menuItem}
                    onSave={(updatedItem) => {
                      const newMessageTemplates = messageTemplates.map(
                        (item, index) => {
                          if (index === key) {
                            return updatedItem;
                          }
                          return item;
                        },
                      );
                      setMessageTemplates(newMessageTemplates);
                      saveChanges(newMessageTemplates);
                    }}
                    onDelete={() => {
                      const newMessageTemplates = messageTemplates.filter(
                        (_, index) => index !== key,
                      );
                      setMessageTemplates(newMessageTemplates);
                      saveChanges(newMessageTemplates);
                    }}
                    key={key}
                  />
                );
              })}
            </div>
            <>
              <PrimaryButton
                icon="plus"
                label="Add new template"
                className="mr-10 mt-20"
                onClick={() => {
                  setMessageTemplates([
                    ...messageTemplates,
                    {
                      title: 'New template',
                      content: {},
                    },
                  ]);
                }}
              />
            </>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(ProjectMessageTemplates));
