import classNames from 'classnames';
import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import BugDetail from 'components/BugDetail/BugDetail';
import IconButton from 'components/IconButton/IconButton';
import InboxItem, { InboxItemSkeleton } from 'components/InboxItem/InboxItem';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { Title } from 'components/Title/Title';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './ProjectInbox.scss';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import InboxTicketStatusSelection from 'components/InboxTicketStatusSelection/InboxTicketStatusSelection';
import LinkButton from 'components/LinkButton/LinkButton';
import Swal from 'sweetalert2';

const inboxFilterStates = [
  { name: 'My inbox', icon: 'user', value: 'personalinbox' },
  { name: 'All', icon: 'user-group', value: 'all' },
  { name: 'Unassigned', icon: 'user-slash', value: 'unassigned' },
  { name: 'Mentions', icon: 'at', value: 'mentions' },
];

const filterStates = [
  { name: 'Open', icon: 'inbox', value: 'OPEN' },
  { name: 'Snoozed', icon: 'moon', value: 'SNOOZED' },
  { name: 'Closed', icon: 'check', value: 'DONE' },
];

interface ProjectInboxProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
  filterBugState?: string;
  filterBugAssignedTo?: string;
}

const ProjectInbox = ({
  projectStore,
  usersStore,
  bugStore,
  modalStore,
}: ProjectInboxProps) => {
  const navigate = useNavigate();

  const [currentInbox, setCurrentInbox] = useState('all');
  const [filterBugState, setFilterBugState] = useState('OPEN');
  const { shareToken, projectId } = useParams();
  const location = useLocation();

  const initialize = async () => {
    try {
      if (projectId) {
        await projectStore!.loadProjectById(projectId);
        projectStore!.setFeedbackTypeForPath('inquiries');
      }
    } catch (err) {
      toast.error('Could not load project');
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    if (location.pathname.includes('inquiries')) {
      bugStore!.clearCurrentBug(false);
      initialize();
    }
  }, []);

  useEffect(() => {
    runInAction(() => {
      if (currentInbox === 'personalinbox') {
        projectStore!.currentTicketDataFilter = {
          processingUser: usersStore?.currentUser?.id,
        };
      } else if (currentInbox === 'unassigned') {
        projectStore!.currentTicketDataFilter = {
          filter: JSON.stringify({
            $or: [
              {
                processingUser: {
                  $exists: false,
                },
              },
              {
                processingUser: null,
              },
            ],
          }),
        };
      } else if (currentInbox === 'mentions') {
        projectStore!.currentTicketDataFilter = {
          mentions: usersStore?.currentUser?.id,
        };
      } else {
        projectStore!.currentTicketDataFilter = {};
      }

      projectStore?.filterTicketsData();
    });
  }, [filterBugState, currentInbox]);

  const getCountForInbox = (inbox) => {
    var checkFunc = function (item) {
      if (item.type === 'INQUIRY') {
        return true;
      }
      return false;
    };

    if (inbox === 'personalinbox') {
      checkFunc = function (item) {
        if (
          item.type === 'INQUIRY' &&
          item.processingUser === usersStore?.currentUser?.id
        ) {
          return true;
        }
        return false;
      };
    } else if (inbox === 'unassigned') {
      checkFunc = function (item) {
        if (
          item.type === 'INQUIRY' &&
          (!item.processingUser || item.processingUser === null)
        ) {
          return true;
        }
        return false;
      };
    } else if (inbox === 'mentions') {
      checkFunc = function (item) {
        if (
          item.type === 'INQUIRY' &&
          item.mentions &&
          item.mentions.includes(usersStore?.currentUser?.id)
        ) {
          return true;
        }
        return false;
      };
    }

    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (
        projectStore?.unreadItems[itemKey] &&
        checkFunc(projectStore?.unreadItems[itemKey])
      ) {
        unreadCount++;
        if (unreadCount > 9) {
          return 10;
        }
      }
    }

    return unreadCount;
  };

  useEffect(() => {
    if (shareToken && projectStore?.currentProject) {
      projectStore!.openFeedbackItem({
        shareToken,
        openModal: false,
      });
    }
  }, [shareToken, projectStore?.currentProject]);

  const handleScroll = (e, laneKey) => {
    const bottom = Math.abs(e.scrollHeight - e.clientHeight - e.scrollTop) < 1;
    if (bottom) {
      projectStore?.fetchAndSetTicketsDataForLane({ laneKey, loadMore: true });
    }
  };

  const inboxClassName = classNames({
    'inbox-sidebox': true,
  });

  const inboxBodyClassName = classNames({
    'inbox-body-item': true,
  });

  const isLoading =
    (!projectStore?.currentTicketsData ||
      !projectStore?.currentTicketsData[filterBugState] ||
      projectStore?.currentTicketsData[filterBugState].isLoading) &&
    projectStore?.currentTicketsData[filterBugState]?.data?.length === 0;

  let filteredTickets =
    projectStore?.currentTicketsData &&
    projectStore?.currentTicketsData[filterBugState]
      ? projectStore?.currentTicketsData[filterBugState].data ?? []
      : [];

  // Check if loadMore is ongoing
  var showLoadingIndicator = false;
  if (
    (!projectStore?.currentTicketsData ||
      !projectStore?.currentTicketsData[filterBugState] ||
      projectStore?.currentTicketsData[filterBugState].isLoading) &&
    projectStore?.currentTicketsData[filterBugState]?.data?.length > 0
  ) {
    showLoadingIndicator = true;
  }

  const rowRenderer = ({ index, key, style }) => {
    const bug = filteredTickets[index];

    if (bug.isLoading) {
      return (
        <div key={key} style={style}>
          <InboxItemSkeleton />;
        </div>
      );
    }
    return (
      <div key={key} style={style}>
        <InboxItem
          active={bug.shareToken === shareToken}
          key={bug.id}
          onClick={() => {
            if (window.innerWidth <= 1100) {
              projectStore!.openFeedbackItem({ shareToken: bug.shareToken });
            } else {
              navigate(`/projects/${projectId}/inquiries/${bug.shareToken}`);
            }
          }}
          item={bug}
        />
      </div>
    );
  };

  const renderInboxList = () => {
    if (filteredTickets.length === 0 && !isLoading) {
      return (
        <div className="sidebar-placeholder">
          {filterBugState === 'OPEN'
            ? 'All done 🎉. Great job.'
            : 'Nothing here yet.'}
        </div>
      );
    }

    if (isLoading) {
      return (
        <>
          {Array.from(Array(5).keys()).map((val, index) => (
            <InboxItemSkeleton key={index} />
          ))}
        </>
      );
    }

    return (
      <>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                onScroll={(e) => {
                  handleScroll(e, filterBugState);
                }}
                height={height}
                width={width}
                rowCount={filteredTickets.length}
                style={{
                  transition: 'background-color 0.2s ease',
                }}
                rowRenderer={rowRenderer}
                rowHeight={80}
              />
            );
          }}
        </AutoSizer>
        {showLoadingIndicator && (
          <div className="loading-data">
            <i className="fa-duotone fa-spinner-third fa-spin" />
          </div>
        )}
      </>
    );
  };

  const renderInboxBody = () => {
    if (
      (bugStore?.currentBug || bugStore?.loadingBug) &&
      window.innerWidth > 1100
    ) {
      return <BugDetail shared={false} />;
    }

    return <div className="inbox-no-item-selected">📬 No inquiry selected</div>;
  };

  const clearCurrentBug = () => {
    projectStore!.refreshBugsForCurrentProject();
    bugStore!.clearCurrentBug(false);
    navigate(`/projects/${projectId}/inquiries`);
  };

  const render = () => {
    const activeInbox =
      inboxFilterStates.find((state) => state.value === currentInbox) ??
      inboxFilterStates[0];

    return (
      <PageContainer>
        <PageContent hasPadding={false}>
          <div className={inboxClassName}>
            <div className="sidebar-header">
              <div className="sidebar-header-title">
                <Title label={activeInbox.name} />
              </div>
              <div className="filter">
                <InboxTicketStatusSelection
                  items={filterStates}
                  selected={filterStates.find(
                    (state) => state.value === filterBugState,
                  )}
                  onChange={(value) => {
                    setFilterBugState(value);
                    clearCurrentBug();
                  }}
                />
              </div>
            </div>
            <div className="sidebar-items">{renderInboxList()}</div>
          </div>
          <div key={shareToken ?? 'notselected'} className={inboxBodyClassName}>
            {renderInboxBody()}
          </div>
          {projectStore?.isProjectAdmin && (
            <LinkButton
              className="inbox-settings-icon hide-on-mobile"
              iconSideRight={false}
              icon="gear"
              small
              onClick={() => {
                modalStore!.openModal(MODALTYPE.FEEDBACK_TYPE, {
                  feedbackType: projectStore?.currentFeedbackType?.type,
                });
              }}
            />
          )}
          {!projectStore?.currentProject?.sdkInstalled && (
            <div className="project-not-installed">
              <NotInstalledCard />
            </div>
          )}
        </PageContent>
      </PageContainer>
    );
  };

  return (
    <SidenavContainer className="sidenav-container--small">
      <SubSidenav
        title="Inbox"
        buttons={
          <>
            <BoardSearchFilter iconOnly />
            <IconButton
              className="primary"
              icon="pen-to-square"
              onClick={() => {
                modalStore!.openModal(MODALTYPE.COMPOSE_MESSAGE);
              }}
            />
          </>
        }
      >
        {inboxFilterStates.map((state) => {
          return (
            <SimpleSidenavElement
              key={state.value}
              name={state.name}
              onClick={() => {
                clearCurrentBug();
                setCurrentInbox(state.value);
              }}
              faIcon={state.value === 'personalinbox' ? undefined : state.icon}
              IconComponent={
                state.value === 'personalinbox' ? (
                  <UserAvatar
                    small
                    email={usersStore?.currentUser?.email}
                    imageUrl={usersStore?.currentUser?.profileImageUrl}
                  />
                ) : undefined
              }
              isActive={currentInbox === state.value}
              notificationCount={getCountForInbox(state.value)}
            />
          );
        })}
        <div className="sidenavtitle">
          <span className="sidenavtitle-text">Views</span>
          <div
            className="sidenavtitle-icon"
            onClick={() => {
              Swal.fire({
                text: 'Custom inbox views are currently in private beta and launching soon!',
                showCancelButton: false,
                confirmButtonText: `Ok`,
              });
            }}
          >
            <i className="fa-solid fa-pen" />
          </div>
        </div>
        {/*<div className='sidenavtitle'>
          <span className='sidenavtitle-text'>Views</span>
          <div className='sidenavtitle-icon' onClick={() => {
            modalStore!.openModal(MODALTYPE.EDIT_INBOX_VIEW, {

            });
          }}>
            <i className="fa-solid fa-pen" />
          </div>
        </div>
        {inboxFilterStates.map((state) => {
          return (
            <SimpleSidenavElement
              key={state.value}
              name={state.name}
              onClick={() => {
                clearCurrentBug();
                setCurrentInbox(state.value);
              }}
              faIcon={state.icon}
              isActive={currentInbox === state.value}
              notificationCount={getCountForInbox(state.value)}
            />
          );
        })}*/}
      </SubSidenav>
      <div className="project-inbox">{render()}</div>
    </SidenavContainer>
  );
};

export default inject(
  'projectStore',
  'usersStore',
  'bugStore',
  'modalStore',
)(observer(ProjectInbox));
