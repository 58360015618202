import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import React from 'react';
import './Loading.scss';

const Loading = ({ className }: any) => {
  return (
    <div className={`loading ${className}`}>
      <LoadingAnimation />
    </div>
  );
};

export default Loading;
