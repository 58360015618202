import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
  getAuthToken,
  getBrands,
  getOrganizations,
} from 'services/integrations/ZendeskHttpService';

export class ZendeskIntegrationStore {
  stores: any = {};

  integrationID: string = '';

  organizations: any = [];
  brands: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setOrganizsations = (zendeskOrganizations) => {
    this.organizations = zendeskOrganizations;
  };

  setBrands = (zendeskBrands) => {
    this.brands = zendeskBrands;
  };

  getOrganizations = async () => {
    try {
      const response = await getOrganizations({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });

      if (response.status === 200) {
        this.setOrganizsations(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  getBrands = async () => {
    try {
      const response = await getBrands({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });

      if (response.status === 200) {
        this.setBrands(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  getAuthToken = async ({ baseUrl }) => {
    try {
      getAuthToken({
        projectID: this.stores.projectStore.currentProject.id,
        baseUrl,
      });
    } catch {
      toast.error('Something went wrong!');
    }
  };
}
