import axios from './Axios';

const sendResetPasswordLink = (token, email) => {
  return axios.post('/password-resets', { token, email });
};

const resetPassword = (token, password) => {
  return axios.put(`/password-resets/${token}`, { password });
};

export { sendResetPasswordLink, resetPassword };
