import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { ReactComponent as DangerIcon } from '../../../assets/icons/np_warning_462862_FFFFFF.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import AnswerBot from './AnswerBot/AnswerBot';
import CustomBots from './CustomBots/CustomBots';
import './Bots.scss';
import FlowChart from '../BotConfigurationPage/FlowChart';
import { inject, observer } from 'mobx-react';
import { SidenavStore } from 'stores/private/SidenavStore';
import SubNav from 'components/SubNav/SubNav';
import UnansweredQuestions from './UnansweredQuestions/UnansweredQuestions';

const Bots = ({ sidenavStore }: { sidenavStore?: SidenavStore }) => {
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[4] === path) {
        return true;
      }

      if (path === '' && parts.length === 4 && parts[3] === 'bots') {
        return true;
      }
    }

    return false;
  };

  return (
    <SidenavContainer sidenavHidden={sidenavStore?.sidenavHidden}>
      <SubSidenav title="Bots">
        <SimpleSidenavElement
          name="Custom bots"
          faIcon='message-bot'
          onClick={() => {
            navigate(`/projects/${projectId}/bots`);
          }}
          isActive={isActive('')}
        />
        <SubNav
          title="Answer bot"
          faIcon='messages-question'
          onClick={() => {
            navigate(`/projects/${projectId}/bots/answerbot`);
          }}
          isOpened={
            isActive('answerbot') ||
            isActive('unansweredquestions')
          }
        >
          <SimpleSidenavElement
            name="Answers"
            onClick={() => {
              navigate(`/projects/${projectId}/bots/answerbot`);
            }}
            isActive={isActive('answerbot')}
          />
          <SimpleSidenavElement
            name="Unanswered questions"
            onClick={() => {
              navigate(`/projects/${projectId}/bots/unansweredquestions`);
            }}
            isActive={isActive('unansweredquestions')}
          />
        </SubNav>
      </SubSidenav>
      <Routes>
        <Route path="/" element={<CustomBots />} />
        <Route path="/answerbot" element={<AnswerBot />} />
        <Route path="/unansweredquestions" element={<UnansweredQuestions />} />
        <Route path="/custombots/:botId" element={<FlowChart />} />
      </Routes>
    </SidenavContainer>
  );
};

export default inject('sidenavStore')(observer(Bots));