import DashboardButton from 'components/DashboardButton/DashboardButton';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import './PageHeadLine.scss';

interface PageHeadLineProps {
  title?: string;
  isEditable?: boolean;
  onChangeTitle?: any;
  tag?: string;
  children?: any;
  prependChildren?: boolean;
  showBack?: boolean;
  transparent?: boolean;
  onActionBack?: any;
}

export const PageHeadLine = ({
  title,
  isEditable = false,
  onChangeTitle,
  tag,
  children,
  prependChildren = false,
  showBack = false,
  transparent = false,
  onActionBack,
}: PageHeadLineProps) => {
  return (
    <>
      <div
        className={`page-headline ${transparent ? 'page-headline--transparent' : ''
          }`}
      >
        <div className="left-align-wrapper">
          {showBack && <DashboardButton showLabel isHeader />}
          {onActionBack && (
            <div className="action-back-icon" onClick={onActionBack}>
              <i className="fa-solid fa-chevron-left" />
            </div>
          )}
          {prependChildren && children}
          {title === "..." && (<i className="fa-duotone fa-spinner-third fa-spin" />)}
          {!isEditable && title && title !== "..." && <Title label={title} />}
          {isEditable && (
            <div className='headline-input'>
              <TextInput
                autoWidth
                className="headline-input-field"
                value={title}
                placeholder="Enter a title"
                error=""
                onChange={(val) => {
                  if (onChangeTitle) {
                    onChangeTitle(val);
                  }
                }}
              />
            </div>
          )}
          {tag && <div className="page-headline-tag">{tag}</div>}
        </div>
        {!prependChildren && children}
      </div>
    </>
  );
};
