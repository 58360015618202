import { ReactComponent as TextFieldIcon } from 'assets/icons/inputfieldItem.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icons/multipleChoiceItem.svg';
import { ReactComponent as NumberIcon } from 'assets/icons/onetofiveItem.svg';
import { ReactComponent as TextAreaFieldIcon } from 'assets/icons/textareaItem.svg';
import { ReactComponent as TicketIcon } from 'assets/icons/ticket.svg';
import { ReactComponent as SessionIcon } from 'assets/icons/session.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as CustomDataIcon } from 'assets/icons/customData.svg';
import { ReactComponent as NameIcon } from 'assets/icons/name.svg';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import './InputActionEditor.scss';
import InfoBox from 'components/InfoBox/InfoBox';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';

export const attributeTypes = [
  {
    label: 'Contact',
    value: 'session',
    icon: SessionIcon,
  },
  {
    label: 'Ticket',
    value: 'ticket',
    icon: TicketIcon,
  },
];

export const dataActionTypes = [
  {
    label: 'Customer email',
    value: 'email',
    icon: EmailIcon,
  },
  {
    label: 'Customer name',
    value: 'name',
    icon: NameIcon,
  },
  {
    label: 'Custom data',
    value: 'custom',
    icon: CustomDataIcon,
  },
];

export const inputActionTypes = [
  {
    label: 'Text',
    value: 'text',
    icon: TextFieldIcon,
  },
  {
    label: 'Number',
    value: 'number',
    icon: NumberIcon,
  },
  {
    label: 'Dropwdown',
    value: 'dropdown',
    icon: MultipleChoiceIcon,
  },
];

interface InputActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const InputActionEditor = ({
  action,
  modalStore,
  projectStore,
}: InputActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  const renderSpecialFields = () => {
    if (action.inputType === 'dropdown') {
      return (
        <>
          <div className="input-label mt-20">Options</div>
          {action.options &&
            action.options.map((element, i) => (
              <div className="feedback-content-row-choices mb-5" key={i}>
                <TextInput
                  name="choice"
                  initalValue={getProperty(
                    element,
                    `label.localized.${currentLang}`,
                  )}
                  onChange={(val) => {
                    runInAction(() => {
                      const option = {
                        ...action.options[i],
                        value: val,
                      };

                      setProperty(
                        option,
                        `label.localized.${currentLang}`,
                        val,
                      );

                      action.options[i] = option;
                    });
                  }}
                  placeholder="Choice text"
                  type="text"
                  error=""
                />
                <i
                  className="delete-choice-item fa-solid fa-trash"
                  onClick={() => {
                    runInAction(() => {
                      action.options.splice(i, 1);
                    });
                  }}
                />
              </div>
            ))}
          <div className="add-choice-item-container">
            <LinkButton
              label="Add option"
              onClick={() => {
                runInAction(() => {
                  if (action.options) {
                    action.options.push({
                      label: 'New option',
                      value: 'New option',
                    });
                  } else {
                    action.options = [
                      {
                        label: 'New option',
                        value: 'New option',
                      },
                    ];
                  }
                });
              }}
            />
          </div>
        </>
      );
    }
    if (action.inputType === 'number') {
      return (
        <>
          <TextInput
            name="minvalue"
            label={'Min value'}
            value={action.minValue ?? 0}
            placeholder=""
            type="number"
            className="mt-20"
            error=""
            onChange={(val) => {
              const num = parseInt(val, 10);
              if (isNaN(num)) {
                return;
              }

              runInAction(() => {
                action.minValue = num;
              });
            }}
          />
          <TextInput
            name="maxvalue"
            label={'Max value'}
            value={action.maxValue ?? 100}
            placeholder=""
            type="number"
            className="mt-20"
            error=""
            onChange={(val) => {
              const num = parseInt(val, 10);
              if (isNaN(num)) {
                return;
              }

              runInAction(() => {
                action.maxValue = num;
              });
            }}
          />
        </>
      );
    }

    return null;
  };

  return (
    <div className="input-action-editor">
      <PageContainer>
        <PageHeadLine title="Collect customer data">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="input-label">Data to collect</div>
          <div className="icon-dropdown-container">
            <IconDropdown
              value={dataActionTypes.find(
                (element) => element.value === (action.actionType || 'email'),
              )}
              options={dataActionTypes}
              onValueChanged={(selectedItem) => {
                runInAction(() => {
                  action.actionType = selectedItem.value;
                  if (selectedItem.value === 'email') {
                    action.inputType = 'email';
                    setProperty(action, `title.localized.${currentLang}`, 'What is your email address?');
                  } else if (selectedItem.value === 'name') {
                    action.inputType = 'text';
                    setProperty(action, `title.localized.${currentLang}`, 'What is your name?');
                  } else {
                    action.attributeType = 'ticket';
                    action.inputType = 'text';
                    setProperty(action, `title.localized.${currentLang}`, '');
                  }
                });
              }}
            />
          </div>
          {action.actionType === 'custom' && (
            <>
              <div className="input-label mt-20">Input type</div>
              <div className="icon-dropdown-container">
                <IconDropdown
                  value={inputActionTypes.find(
                    (element) => element.value === (action.inputType || 'text'),
                  )}
                  options={inputActionTypes}
                  onValueChanged={(selectedItem) => {
                    runInAction(() => {
                      action.inputType = selectedItem.value;

                      if (action.inputType === 'number') {
                        action.minValue = 0;
                        action.maxValue = 100;
                      }
                    });
                  }}
                />
              </div>
            </>
          )}
          <TextInput
            name="survey-question"
            className="mt-20"
            label="Question"
            value={getProperty(action, `title.localized.${currentLang}`)}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                setProperty(action, `title.localized.${currentLang}`, val);
              });
            }}
          />
          {action.actionType === 'custom' && (
            <>
              <div className="input-label mt-20">Attribute type</div>
              <div className="icon-dropdown-container">
                <IconDropdown
                  value={attributeTypes.find(
                    (element) =>
                      element.value === (action.attributeType || 'ticket'),
                  )}
                  options={attributeTypes}
                  onValueChanged={(selectedItem) => {
                    runInAction(() => {
                      action.attributeType = selectedItem.value;
                    });
                  }}
                />
              </div>
              <InfoBox className="mt-10">
                <b>Attribute type</b>: You can store the user input within the
                ticket or the contact data.
              </InfoBox>
              <TextInput
                name="attribute"
                label={'Attribute name'}
                value={action.attribute ?? ''}
                placeholder=""
                type="text"
                className="mt-20"
                error=""
                onChange={(val) => {
                  runInAction(() => {
                    action.attribute = val;
                  });
                }}
              />
              <InfoBox className="mt-10">
                <b>Attribute name</b>: The name of the attribute you want to
                store the user input in.
              </InfoBox>
            </>
          )}
          {action.actionType === 'email' && (
            <InfoBox className="mt-20">
              This action type will collect the email of the user. If the user already has an email assigned, this action
              will be skipped automatically.
            </InfoBox>
          )}
          {action.actionType === 'name' && (
            <InfoBox className="mt-20">
              This action type will collect the name of the user. If the user already has a name assigned, this action
              will be skipped automatically.
            </InfoBox>
          )}
          {renderSpecialFields()}
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(InputActionEditor));
