import BotConditionTag from 'components/BotConditionTag/BotConditionTag';
import { runInAction } from 'mobx';
import { PredicateChild } from 'models/Bot';
import React, { useState } from 'react';
import Select from 'react-select';
import './BotConditionPicker.scss';

const dataTypeOptions = [
  {
    label: 'User',
    value: 'user',
    icon: 'user',
  },
  // {
  //   label: 'User event',
  //   value: 'user-event',
  //   icon: 'bullseye-arrow',
  // },
  {
    label: 'Ticket',
    value: 'ticket',
    icon: 'ticket',
  },
  {
    label: 'Office hours',
    value: 'office-hours',
    icon: 'calendar',
  },
];

interface BotConditionPickerProps {
  predicate: PredicateChild;
}

const BotConditionPicker = ({ predicate }: BotConditionPickerProps) => {
  const [toggle, setToggle] = useState(false);

  const renderCreateCondition = (currentPredicate, pushInArray = false) => {
    return (
      <Select
        className="dropdown-selection bot-condition-picker"
        classNamePrefix="dropdown-selection"
        getOptionLabel={(option: any) => option?.label}
        getOptionValue={(option: any) => option?.value}
        placeholder="+ Add condition"
        components={{
          SingleValue: (option: any) => {
            if (!option.data.value) {
              return (
                <div className="outbound-select-event">+ Add condition</div>
              );
            }
            return <>{option.data.label}</>;
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.value);
                  }
                }}
              >
                <i className={`fa-solid fa-${option.data.icon}`} />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(value: any) => {
          runInAction(() => {
            if (pushInArray) {
              predicate.predicates?.push({
                type: 'and',
                predicates: [{ dataType: value }],
              } as any);
            } else {
              currentPredicate.dataType = value;
            }

            setToggle(!toggle);
          });
        }}
        options={dataTypeOptions}
      />
    );
  };

  const _buildAndOrSelector = (currentPredicate) => {
    return (
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        getOptionLabel={(option: any) => option?.label}
        getOptionValue={(option: any) => option?.value}
        value={{
          label: currentPredicate.type.toUpperCase(),
          value: currentPredicate.type,
        }}
        components={{
          SingleValue: (option: any) => {
            return <>{option.data.label}</>;
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.value);
                  }
                }}
              >
                <i className={`fa-solid fa-${option.data.icon}`} />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(value: any) => {
          runInAction(() => {
            currentPredicate.type = value;

            setToggle(!toggle);
          });
        }}
        options={[
          { label: 'AND', value: 'and' },
          { label: 'OR', value: 'or' },
        ]}
      />
    );
  };

  const buildChildConditions = (childPredicate, parentIndex) => {
    return childPredicate.predicates?.map((predicateChild, index) => {
      if (predicateChild?.dataType == null) {
        return renderCreateCondition(predicateChild);
      }

      return (
        <div
          className="child-condition-item"
          key={`bot-condition-tag-${index}`}
        >
          <BotConditionTag
            predicate={predicateChild}
            showAddCondition={index + 1 === childPredicate.predicates?.length}
            onAddCondition={() => {
              runInAction(() => {
                childPredicate.predicates?.push({} as any);

                setToggle(!toggle);
              });
            }}
            onRemove={() => {
              runInAction(() => {
                if (predicate.predicates!.length > 1) {
                  childPredicate.predicates?.splice(index, 1);
                } else {
                  childPredicate.predicates[index] = {}; // Reset the predicate
                }

                // If predicate array is empty, remove it
                if (
                  childPredicate.predicates?.length === 0 &&
                  predicate.predicates!.length > 1
                ) {
                  predicate.predicates?.splice(parentIndex, 1);
                }

                setToggle(!toggle);
              });
            }}
          />
          {index + 1 !== childPredicate.predicates?.length &&
            _buildAndOrSelector(childPredicate)}
        </div>
      );
    });
  };

  const buildRootCondition = () => {
    return predicate.predicates?.map((predicateChild, index) => {
      return (
        <div className="bot-root-conditions mr-10" key={`root-child-${index}`}>
          <div className="bot-root-conditions-wrapper">
            <div className="bot-child-predicates">
              {buildChildConditions(predicateChild, index)}
            </div>
            {index + 1 !== predicate.predicates?.length && (
              <div className="ml-15"> {_buildAndOrSelector(predicate)}</div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bot-condition-picker">
      <div className="if-preview">if</div>
      <div className="condition-picker-conditions">
        {buildRootCondition()}
        {predicate.predicates != null &&
          predicate.predicates!.length > 0 &&
          predicate.predicates![0].predicates != null &&
          predicate.predicates![0].predicates!.length > 0 &&
          predicate.predicates![0].predicates![0].dataType != null &&
          renderCreateCondition(predicate, true)}
      </div>
    </div>
  );
};

export default BotConditionPicker;
