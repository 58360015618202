import React, { useState } from 'react';
import './AvatarImage.scss';
import { inject, observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import { uploadFileToServer } from 'services/FileUpload';
import classNames from 'classnames';
import { UsersStore } from 'stores/private/UsersStore';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import crossIcon from '../../assets/icons/add_icon.svg';

interface AvatarImageProps {
  image?: string;
  editable?: boolean;
  afterImageUpload?: any;
  className?: any;
  usersStore?: UsersStore;
}

const AvatarImage = ({
  usersStore,
  image,
  editable,
  afterImageUpload,
  className,
}: AvatarImageProps) => {
  const [loading, setLoading] = useState(false);
  const renderAvatar = () => {
    return (
      <div className="image">
        <img src={image} alt="avatar" />
      </div>
    );
  };

  const renderFilePicker = (getInputProps: any) => {
    return (
      <div className="file-picker">
        <img src={crossIcon} alt="crossicon" />
        <input className="avatar-file-picker" {...getInputProps()} />
      </div>
    );
  };

  const renderContent = (getInputProps: any) => {
    return (
      <>
        {image !== '' && renderAvatar()}
        {editable ? renderFilePicker(getInputProps) : <></>}
      </>
    );
  };

  const avatarImageClassName = classNames(
    {
      'avatar-image': true,
    },
    className,
  );

  const userId = usersStore?.currentUser ? usersStore.currentUser.id : '';

  return (
    <Dropzone
      accept="image/jpeg,image/png,image/gif,image/jpg,image/jpg,image/svg+xml"
      onDrop={async (element: File[]) => {
        if (afterImageUpload) {
          setLoading(true);
          try {
            afterImageUpload(await uploadFileToServer(element[0], userId));
            // eslint-disable-next-line no-empty
          } catch (exp) {}
          setLoading(false);
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={avatarImageClassName} {...getRootProps()}>
          {loading ? (
            <div className="loading-container">
              <LoadingAnimation />
            </div>
          ) : (
            renderContent(getInputProps)
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default inject('usersStore')(observer(AvatarImage));
