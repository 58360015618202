/* eslint-disable jsx-a11y/label-has-associated-control */
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { StripeStore } from 'stores/private/StripeStore';
import './PriceTable.scss';

interface PriceTableProps {
  stripeStore?: StripeStore;
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
  isModal?: boolean;
}

export enum Plantypes {
  FREE = 'free',
  STARTER = 'starter',
  GROWTH = 'growth',
  PRO = 'pro',
}

export const plans = {
  free: {
    name: 'Gleap Free',
    nameSolo: 'Free',
    mainFeature1: 'Full access to detailed bug reports',
    mainFeature2: 'Access to all integrations',
    prices: ['free', 'trial'],
    monthly: {
      plan: 'free',
      price: 0,
    },
    annually: {
      plan: 'free',
      price: 0,
    },
  },
  starter: {
    name: 'Gleap Hobby',
    nameSolo: 'Hobby',
    mainFeature1: 'Access to environment data',
    mainFeature2: '31 days data retention',
    prices: [
      'price_1HldaKFIu8tJdh4ndvlfeP8T',
      'price_1IqLlGFIu8tJdh4nhxvN7PUR',
      'price_1JMu4pFIu8tJdh4nGcNyuISj',
      'price_1MVztuFIu8tJdh4nP1sEVZJX',
      'price_1MWgllFIu8tJdh4nEQwX5BJb',
      'price_1MY8meFIu8tJdh4nlFcBJvpX',
      'price_1MZwcMFIu8tJdh4niugCvrl4',
      'price_1MXMF7FIu8tJdh4n9G7VL2Ay',
    ],
    monthly: {
      plan: 'price_1MY8meFIu8tJdh4nlFcBJvpX',
      price: 30,
    },
    annually: {
      plan: 'price_1MZwcMFIu8tJdh4niugCvrl4',
      price: 24,
    },
  },
  growth: {
    name: 'Gleap Team',
    nameSolo: 'Team',
    mainFeature1: 'Full access to detailed bug reports',
    mainFeature2: 'Access to all integrations',
    prices: [
      process.env.REACT_APP_GROWTH_PLAN,
      'price_1KP2xOFIu8tJdh4nrLlOv1W4',
      'price_1KP2xOFIu8tJdh4nMlFSwszP',
      'price_1HldbkFIu8tJdh4nZbwb9oA2',
      'price_1IW2ZjFIu8tJdh4ny99NS9Zq',
      'price_1IqLjeFIu8tJdh4nXhyWN0m8',
      'price_1JMwjRFIu8tJdh4njduiDLo1',
      'price_1JfjfQFIu8tJdh4n7R30m3kl',
      'price_1Jfjg2FIu8tJdh4nYeCawixv',
      'price_1MVxQFFIu8tJdh4nIedlO8Pd',
      'price_1MWJ7iFIu8tJdh4nFUVFHqdK',
      'price_1MWgdSFIu8tJdh4nfGpWj1fS',
      'price_1MXMFbFIu8tJdh4nBDPtJTcH',
      'price_1MXPuoFIu8tJdh4n9B4GdX1w',
      'price_1MXm1FFIu8tJdh4npoK2mgoi',
      'price_1MY8oQFIu8tJdh4nmWSYrzQr',
      'price_1Mg59JFIu8tJdh4nONfclUnT',
      'price_1MXm1FFIu8tJdh4npoK2mgoi',
    ],
    monthly: {
      plan: 'price_1MY8oQFIu8tJdh4nmWSYrzQr',
      price: 150,
    },
    annually: {
      plan: 'price_1Mg59JFIu8tJdh4nONfclUnT',
      price: 120,
    },
  },
  pro: {
    name: 'Gleap Pro',
    nameSolo: 'Pro',
    mainFeature1: 'Full access to detailed bug reports',
    mainFeature2: 'Access to all integrations',
    prices: [
      'price_1KbYOnFIu8tJdh4nLqWygBAY',
      'price_1KbYP2FIu8tJdh4nBGhtAm2a',
      'price_1JMu6WFIu8tJdh4n5byvxDwP',
      'price_1KPMceFIu8tJdh4nzHqNJQJ0',
      'price_1K55kyFIu8tJdh4nuMQeiXPM',
      'price_1JcmKKFIu8tJdh4nEcJOGGTM',
      'price_1IW2ZJFIu8tJdh4n80PJNY10',
      'price_1HlddCFIu8tJdh4nDwQVxDIc',
    ],
    monthly: {
      plan: 'price_1KbYP2FIu8tJdh4nBGhtAm2a',
      price: 399,
    },
    annually: {
      plan: 'price_1KbYOnFIu8tJdh4nLqWygBAY',
      price: 3588,
    },
  },
};

const PriceTable = ({
  stripeStore,
  organisationStore,
  modalStore,
  isModal = false,
}: PriceTableProps) => {
  const currentPlan =
    organisationStore!.currentOrganisation?.subscription?.planID;

  const planButton = (planID) => {
    const plan = plans[planID];
    const isCurrentPlan = plan.prices.includes(currentPlan);

    if (isCurrentPlan) {
      return <div className="currentplanbutton">Current plan</div>;
    }

    return (
      <PrimaryButton
        fullWidth
        className="mt-20"
        onClick={() => {
          modalStore!.openModal(MODALTYPE.UPSELL_MODAL, {
            plan: planID,
            skipIntro: true,
          });
        }}
        label={
          organisationStore?.currentOrganisation?.trialUsed
            ? 'Subscribe now ✨'
            : 'Start 14-day free trial'
        }
      />
    );
  };

  const getPrice = (planID) => {
    const plan = plans[planID];
    const desiredPlan = plan.annually;
    return desiredPlan.price;
  };

  return (
    <div className='pb-30'>
      <div className="startupinfo">
        👋🚀 You are an early-stage startup?{' '}
        <a
          href="https://www.gleap.io/gleap-startup-discount-program"
          target="_blank"
          rel="noreferrer"
        >
          Apply for our startup discount.
        </a>
      </div>
      <div className="pricetable-container">
        <div className="pricing-card">
          <div>
            <h2 className="pricingheadlinetop">Hobby</h2>
            <h2 className="pricingheadline"><span className='planpre'>from</span> ${getPrice('starter')} <span className='planpost'>per month</span></h2>
          </div>
          {planButton('starter')}
          <div className="pricinginfocard pricinginfocardtop">
            <div>Team members</div>
            <div>1</div>
          </div>
          <div className="pricinginfocard ">
            <div>Data retention</div>
            <div>1 month</div>
          </div>
          <div className="pricinginfocard pricinginfocardbottom">
            <div>People reached incl. <i className="fa-solid fa-circle-question" data-for="mauLimitTooltip" data-tip={JSON.stringify({
              included: "50",
              price: "5",
            })} /></div>
            <div>50</div>
          </div>
          <div className="pricingfeatures">
            In-app bug reporting
            <br />
            Feature requests
            <br />
            Live-chat
          </div>
        </div>
        <div className="pricing-card">
          <div>
            <h2 className="pricingheadlinetop">Team</h2>
            <h2 className="pricingheadline"><span className='planpre'>from</span> ${getPrice('growth')} <span className='planpost'>per month</span></h2>
          </div>
          {planButton('growth')}
          <div className="pricinginfocard pricinginfocardtop">
            <div>Team members</div>
            <div>Unlimited</div>
          </div>
          <div className="pricinginfocard">
            <div>Data retention</div>
            <div>12 months</div>
          </div>
          <div className="pricinginfocard pricinginfocardbottom">
            <div>People reached incl. <i className="fa-solid fa-circle-question" data-for="mauLimitTooltip" data-tip={JSON.stringify({
              included: "1,000",
              price: "5",
            })} /></div>
            <div>1,000</div>
          </div>
          <div className="pricingfeatures">
            <h5>Everything in Hobby +</h5>
            All integrations
            <br />
            Console & network logs
            <br />
            In-app surveys
            <br />
            Custom domain
            <br />
            Release notes
            <br />
            Outbound messaging
            <br />
            Help center
            <br />
            Remove Gleap branding
          </div>
        </div>
        <div className="pricing-card">
          <div>
            <h2 className="pricingheadlinetop">Enterprise</h2>
            <h2 className="pricingheadline">Let's talk</h2>
          </div>
          <PrimaryButton
            fullWidth
            className="mt-20"
            onClick={() => {
              Gleap.open();
            }}
            label={"Contact us"}
          />
          <div className="pricinginfocard pricinginfocardtop">
            <div>Team members</div>
            <div>Unlimited</div>
          </div>
          <div className="pricinginfocard">
            <div>Data retention</div>
            <div>Custom</div>
          </div>
          <div className="pricinginfocard pricinginfocardbottom">
            <div>People reached incl.</div>
            <div>Custom</div>
          </div>
          <div className="pricingfeatures">
            <h5>Everything in Team +</h5>
            High priority support
            <br />
            Custom people reached plan
            <br />
            API access
            <br />
            99.9 % SLA
          </div>
        </div>
      </div>
      <ReactTooltip
        id="mauLimitTooltip"
        className="infoTooltip"
        delayHide={300}
        type="light"
        effect="solid"
        getContent={(content) => {
          try {
            const data = JSON.parse(content);
            return (
              <div className='maulimit-info-tooltip'><b>People reached</b><br />This plan includes {data.included} people reached.<br />{data.price}$/month for 1,000 additional people reached.</div>
            );
          } catch (exp) { }
          return null;
        }}
      />
    </div>
  );
};

export default inject('stripeStore', 'organisationStore', 'modalStore')(observer(PriceTable));
