import React from 'react';
import './ToolTip.scss';

interface ToolTipProps {
  label: string;
}

export const ToolTip = ({ label }: ToolTipProps) => {
  return <div className="tooltip">{label}</div>;
};
