import './FeedbackActionQuestionListItem.scss';
import { SortableHandle } from 'react-sortable-hoc';
import { ReactComponent as MultipleChoiceIcon } from '../../assets/icons/multipleChoiceItem.svg';
import { ReactComponent as TextFieldIcon } from '../../assets/icons/inputfieldItem.svg';
import { ReactComponent as TextAreaIcon } from '../../assets/icons/textareaItem.svg';
import { ReactComponent as FileuploadItemIcon } from '../../assets/icons/fileuploaditem.svg';
import { ReactComponent as NPSIcon } from '../../assets/icons/npsicon.svg';
import { ReactComponent as SmileyIcon } from '../../assets/icons/smileyRating.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/onetofiveItem.svg';
import { ReactComponent as DragIcon } from '../../assets/icons/dragicon.svg';

export const actionTypes = [
  {
    label: 'Net Promoter Score',
    value: 'netpromoterscore',
    icon: NPSIcon,
  },
  {
    label: 'Numeric scale',
    value: 'onetofive',
    icon: QuestionIcon,
  },
  {
    label: 'Single choice',
    value: 'multiplechoice',
    icon: MultipleChoiceIcon,
  },
  {
    label: 'Smiley rating',
    value: 'rating',
    icon: SmileyIcon,
  },
  {
    label: 'Short text',
    value: 'text',
    icon: TextFieldIcon,
  },
  {
    label: 'Long text',
    value: 'textarea',
    icon: TextAreaIcon,
  },
  {
    label: 'File upload',
    value: 'upload',
    icon: FileuploadItemIcon,
  },
];

interface FeedbackActionQuestionListItemProps {
  formItem: any;
  onRemove: any;
  onClick: any;
  active?: boolean;
  index: number;
}

const FeedbackActionQuestionListItem = ({
  formItem,
  onRemove,
  onClick,
  active = true,
  index,
}: FeedbackActionQuestionListItemProps) => {
  const DragHandle = SortableHandle(() => (
    <DragIcon className="feedback-action-question-item-drag-icon" />
  ));

  const currentIcon = actionTypes.find(
    (element) => element.value === formItem.type,
  );

  return (
    <div className="feedback-action-question-item-container">
      <div className={`feedback-action-question-item ${active && 'feedback-action-question-item--active'}`} onClick={() => {
        if (onClick) {
          onClick(index);
        }
      }}>
        <DragHandle />
        {currentIcon && <currentIcon.icon className="feedback-action-question-item-icon" />}
        <div className="feedback-action-question-item-title">
          <div className="feedback-action-question-item-title-inner">{formItem.title}</div>
        </div>
        <div className="trash-icon" onClick={onRemove}>
          <i className="fa-solid fa-trash" />
        </div>
      </div>
    </div>
  );
};

export default FeedbackActionQuestionListItem;
