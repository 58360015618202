import { ConditionTagStatic } from 'components/ConditionTagStatic/ConditionTagStatic';
import { OutboundCondition } from 'models/OutboundCondition';
import './ConditionDisplay.scss';

interface ConditionDisplayProps {
    conditions: OutboundCondition[];
}

const ConditionDisplay = ({ conditions }: ConditionDisplayProps) => {
    return <>{conditions.map((condition, index) => {
        const hasNext = conditions.length > index + 1;
        return (
            <>
                <ConditionTagStatic
                    key={`condition-${condition.event}-${index}`}
                    className='mb-10'
                    value={condition} />
                {hasNext && <div className="condition-picker-conditions-operator">and</div>}
            </>
        );
    })}</>;
};

export default ConditionDisplay;
