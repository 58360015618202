import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getBoards, getSections } from 'services/integrations/AsanaHttpService';

export class AsanaIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  boards: any = [];
  sections: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setBoards = (boards) => {
    this.boards = boards;
  };

  getBoards = async () => {
    try {
      const response = await getBoards({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setBoards(response.data);
      }
    } catch (err: any) {
      toast.error('Could not find boards');
    }
  };

  setSections = (sections) => {
    this.sections = sections;
  };

  getSections = async (boardID: string) => {
    if (!boardID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getSections({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        boardID,
      });
      if (response.status === 200) {
        this.setSections(response.data);
      } else {
        toast.error('Could not find sections');
      }
    } catch (err: any) {
      toast.error('Could not find sections');
    }
  };
}
