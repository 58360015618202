import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { AsanaIntegrationStore } from 'stores/integrations/AsanaIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface AsanaConfigurationProps {
  integrationID: string;
  index: number;
  asanaIntegrationStore?: AsanaIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const AsanaConfiguration = ({
  integrationID,
  index,
  asanaIntegrationStore,
  projectStore,
  onChange,
}: AsanaConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const boards = asanaIntegrationStore?.boards;
  const sections = asanaIntegrationStore?.sections;

  const [boardID, setBoardID] = useState('');
  const [sectionID, setSectionID] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [boardID, sectionID]);

  const initialize = async () => {
    asanaIntegrationStore!.setIntegrationID(integrationID);
    await asanaIntegrationStore!.getBoards();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.ASANA,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.asana[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseBoard = async (boardId) => {
    setBoardID(boardId);
    await asanaIntegrationStore?.getSections(boardId);
  };

  const chooseSection = async (listId) => {
    setSectionID(listId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      boardID,
      sectionID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Projects"
        labelPropertyName="name"
        valuePropertyName="gid"
        selectedItem={boards.find(
          (element) => element.gid === currentIntegrationInfo?.boardID,
        )}
        isLoading={boards.length === 0}
        items={boards}
        onChange={(option) => {
          chooseBoard(option.gid);
        }}
      />

      {boardID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="List"
          labelPropertyName="name"
          valuePropertyName="gid"
          selectedItem={sections.find(
            (element) => element.gid === currentIntegrationInfo?.sectionID,
          )}
          isLoading={sections.length === 0}
          items={sections}
          onChange={(option) => {
            chooseSection(option.gid);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'asanaIntegrationStore',
  'projectStore',
)(observer(AsanaConfiguration));
