import FeatureRequestComments from 'components/BugDetail/Details/FeatureRequestComments';
import './SharedFeatureRequestDetails.scss';
import { useNavigate, useParams } from 'react-router';
import { sendPostMessage, useEscape } from 'services/Helper';

const SharedFeatureRequestDetails = ({ showRoadmap = false }: { showRoadmap?: boolean }) => {
  const navigate = useNavigate();
  const { boardShareToken } = useParams();

  const handleClose = (e?) => {
    if (e) {
      e.stopPropagation();
    }
    if (showRoadmap) {
      navigate(`/sharedboard/${boardShareToken}/roadmap`);
    } else {
      navigate(`/sharedboard/${boardShareToken}`);
    }

    sendPostMessage(JSON.stringify({
      type: "app",
      name: "data",
      data: {
        tab: showRoadmap ? "roadmap" : "featurerequests",
      }
    }));
  };
  
  useEscape(() => {
    handleClose();
  });

  return (
    <div className="feature-request-details" onClick={(e) => {
      handleClose(e);
    }}>
      <div className="close-modal"
        onClick={(e) => {
          handleClose(e);
        }}>
        <i className="fa-sharp fa-solid fa-xmark"></i>
      </div>
      <div className="feature-request-details-inner" onClick={e => e.stopPropagation()}>
        <FeatureRequestComments />
      </div>
    </div >
  );
};

export default SharedFeatureRequestDetails;
