import { ampli } from 'ampli';
import { EditableDropdown } from 'components/EditableDropdown/EditableDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import { defaultTranslations } from 'stores/Translations';
import 'styles/swal-theme.scss';
import Swal from 'sweetalert2';
import './TranslationsWidgetSettings.scss';

interface TranslationsWidgetSettingsProps {
  projectStore?: ProjectStore;
}

function TranslationsWidgetSettings({
  projectStore,
}: TranslationsWidgetSettingsProps) {
  const { projectId } = useParams();

  const [translation, setTranslations] = useState(undefined as any);
  const [rtlSupportEnabled, setRtlSupportEnabled] = useState(false);

  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    if (!projectStore?.editingProject) {
      return;
    }

    const processedTranslations = processTranslations(
      projectStore?.editingProject,
      flowConfig,
    );

    if (processedTranslations) {
      setTranslations(processedTranslations);
    }
  }, [projectStore?.editingProject, flowConfig]);

  useEffect(() => {
    if (translation && projectStore!.configuratorLang) {
      const translationForLang = translation[projectStore!.configuratorLang];
      if (
        translationForLang &&
        translationForLang['rtlLang'] &&
        translationForLang['rtlLang'] === 'true'
      ) {
        setRtlSupportEnabled(true);
      } else {
        setRtlSupportEnabled(false);
      }
    }
  }, [translation, projectStore!.configuratorLang]);

  if (!translation) {
    return <></>;
  }

  const translationEditorForLang = (lang) => {
    const translationForLang = translation[lang];
    return (
      <div key={lang}>
        <div className="checkbox-settings-container">
          <div className="switch-container mb-20">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                setRtlSupportEnabled(checked);
                translationForLang['rtlLang'] = checked ? 'true' : 'false';
              }}
              checked={rtlSupportEnabled}
            />
            <span>Enable RTL (right-to-left) layout for this language.</span>
          </div>
        </div>
        <div className="translation-table">
          <div className="translation-editor-row">
            <div className="translation-editor-cell">Default value</div>
            <div className="translation-editor-cell">
              Translation for <b>{lang && lang.toUpperCase()}</b>
            </div>
          </div>
          {translationForLang &&
            Object.keys(translationForLang).map((key) => {
              if (key === 'rtlLang') {
                return null;
              }

              const translationForKey = translationForLang[key];
              return (
                <div key={key} className="translation-editor-row">
                  <div className="translation-editor-cell">{key}</div>
                  <div className="translation-editor-cell">
                    <TextInput
                      name={key}
                      placeholder="..."
                      type="text"
                      error=""
                      initalValue={translationForKey}
                      label={key}
                      onChange={(text) => {
                        translationForLang[key] = text;
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const getTranslationsList = () => {
    const items = [] as any[];
    if (!translation) {
      return items;
    }

    const langKeys = Object.keys(translation);

    for (let i = 0; i < langKeys.length; i++) {
      items.push({
        label: langKeys[i].toLocaleUpperCase(),
        value: langKeys[i],
      });
    }

    return items;
  };

  return (
    <PageContainer>
      <PageHeadLine title="Translations">
        {' '}
        <div className="translation-header">
          <div className="language-translation-selector">
            <div className="language-translation-selector-label">
              Show translations for:
            </div>
            <EditableDropdown
              value={{
                label: projectStore!.configuratorLang.toLocaleUpperCase(),
                value: projectStore!.configuratorLang,
              }}
              onChange={(value) => {
                runInAction(() => {
                  projectStore!.configuratorLang = value.value;
                });
              }}
              options={getTranslationsList()}
            />
          </div>
          <LinkButton
            label="Add language"
            className="mr-10"
            iconSideRight={false}
            icon="plus"
            onClick={() => {
              Swal.fire({
                title: 'Add a new language key',
                text: 'Please enter the language key (2-digit ISO code) for the language you want to add.',
                input: 'text',
                showCancelButton: true,
              }).then((result) => {
                if (result.value) {
                  const langKey = result.value.toLowerCase();

                  // Check if langKey already exists
                  if (translation[langKey]) {
                    Swal.fire({
                      title: 'Error',
                      text: 'This language key already exists.',
                      icon: 'error',
                    });

                    return;
                  }

                  // Add default translation
                  translation[langKey] = JSON.parse(
                    JSON.stringify(defaultTranslations.en),
                  );
                  const updatedTranslations = JSON.parse(
                    JSON.stringify(translation),
                  );
                  setTranslations(updatedTranslations);

                  flowConfig.customTranslations = updatedTranslations;

                  projectStore!.configuratorLang = langKey;

                  ampli.translationLanguageAdded({
                    language: langKey,
                    projectId,
                  });
                }
              });
            }}
          />
          <PrimaryButton
            label="Save"
            onClick={() => {
              // Update translations
              projectStore!.editingProject!.flowConfig.customTranslations =
                translation;

              // Save project
              projectStore?.saveEditingProject();

              ampli.translationsCustomized({
                projectId,
              });
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        {translationEditorForLang(projectStore!.configuratorLang)}
      </PageContent>
    </PageContainer>
  );
}

export default inject('projectStore')(observer(TranslationsWidgetSettings));

export const processTranslations = function (project, flowConfig) {
  if (!project || !flowConfig || !project.projectActions) {
    return null;
  }

  const additionalKeys = ['rtlLang'] as any[];
  const { projectActions } = project;

  let currentTranslations = flowConfig.customTranslations;
  if (!currentTranslations) {
    currentTranslations = {};
  }

  if (flowConfig.widgetInfoTitle) {
    additionalKeys.push(flowConfig.widgetInfoTitle);
  }
  if (flowConfig.widgetInfoSubtitle) {
    additionalKeys.push(flowConfig.widgetInfoSubtitle);
  }
  if (flowConfig.welcomeText) {
    additionalKeys.push(flowConfig.welcomeText);
  }

  if (flowConfig.menuItems) {
    for (let i = 0; i < flowConfig.menuItems.length; i++) {
      const menuItem = flowConfig.menuItems[i];
      additionalKeys.push(menuItem.title);
      additionalKeys.push(menuItem.description);
    }
  }

  if (projectActions) {
    const projectActionKeys = Object.keys(projectActions);
    for (let i = 0; i < projectActionKeys.length; i++) {
      const projectAction = projectActions[projectActionKeys[i]];
      additionalKeys.push(projectAction.title);
      additionalKeys.push(projectAction.thanksMessage);

      if (projectAction.captureTitle) {
        additionalKeys.push(projectAction.captureTitle);
      }

      if (projectAction.screenshotTitle) {
        additionalKeys.push(projectAction.screenshotTitle);
      }

      for (let j = 0; j < projectAction.form.length; j++) {
        const formItem = projectAction.form[j];
        additionalKeys.push(formItem.title);
        additionalKeys.push(formItem.description);
        additionalKeys.push(formItem.placeholder);
        additionalKeys.push(formItem.lowestValueLabel);
        additionalKeys.push(formItem.highestValueLabel);

        if (formItem.choices && formItem.choices.length > 0) {
          for (let k = 0; k < formItem.choices.length; k++) {
            const choice = formItem.choices[k];
            additionalKeys.push(choice);
          }
        }
      }
    }
  }

  const newTranslations = JSON.parse(JSON.stringify(defaultTranslations));
  if (!newTranslations || !currentTranslations) {
    return null;
  }

  const newTranslationKeys = Object.keys(newTranslations);
  const currentTranslationKeys = Object.keys(currentTranslations);
  const translationKeys = [...newTranslationKeys, ...currentTranslationKeys];

  for (let i = 0; i < translationKeys.length; i++) {
    const translationLang = translationKeys[i];
    if (!currentTranslations[translationLang]) {
      currentTranslations[translationLang] = {};
    }
    if (!newTranslations[translationLang]) {
      newTranslations[translationLang] = {};
    }

    // Copy old translations
    if (newTranslations.en) {
      const baseTranslationKeys = Object.keys(newTranslations.en);
      for (let j = 0; j < baseTranslationKeys.length; j++) {
        const oldTranslationData =
          currentTranslations[translationLang][baseTranslationKeys[j]];
        // Old translation.
        if (oldTranslationData && oldTranslationData.length > 0) {
          newTranslations[translationLang][baseTranslationKeys[j]] =
            oldTranslationData;
        }

        // Try to load default translation.
        if (!newTranslations[translationLang][baseTranslationKeys[j]]) {
          newTranslations[translationLang][baseTranslationKeys[j]] =
            newTranslations[translationLang][baseTranslationKeys[j]];
        }

        // Try to load default english translation.
        if (!newTranslations[translationLang][baseTranslationKeys[j]]) {
          newTranslations[translationLang][baseTranslationKeys[j]] =
            newTranslations.en[baseTranslationKeys[j]];
        }
      }
    }

    // Copy custom translations
    for (let j = 0; j < additionalKeys.length; j++) {
      const customTranslationKey = additionalKeys[j];
      if (customTranslationKey && customTranslationKey.length > 0) {
        // Copy from old translations
        const oldTranslationData =
          currentTranslations[translationLang][customTranslationKey];
        if (oldTranslationData && oldTranslationData.length > 0) {
          newTranslations[translationLang][customTranslationKey] =
            oldTranslationData;
        }

        if (
          !newTranslations[translationLang][customTranslationKey] ||
          newTranslations[translationLang][customTranslationKey].length === 0
        ) {
          newTranslations[translationLang][customTranslationKey] =
            customTranslationKey;
        }
      }
    }
  }

  return newTranslations;
};
