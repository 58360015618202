import { useCallback, useState } from 'react';
import './ImageUpload.scss';
import { useDropzone } from 'react-dropzone';
import { uploadFileToServer } from 'services/FileUpload';
import ReactLoading from 'react-loading';
import classNames from 'classnames';

interface ImageUploadProps {
  image?: string;
  editable?: boolean;
  afterImageUpload?: any;
  className?: any;
  label: string;
  formatInfo?: string;
  uploadPath?: string;
  customBgColor?: string;
  accept?: string;
  canDelete?: boolean;
}

const ImageUpload = ({
  image,
  editable,
  afterImageUpload,
  className,
  formatInfo,
  label,
  uploadPath,
  customBgColor,
  canDelete = true,
  accept = 'image/jpeg, image/png, image/svg+xml',
}: ImageUploadProps) => {
  const [loading, setLoading] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (afterImageUpload && acceptedFiles.length > 0) {
        setLoading(true);
        try {
          afterImageUpload(
            await uploadFileToServer(acceptedFiles[0], uploadPath),
          );
          // eslint-disable-next-line no-empty
        } catch (exp) { }
        setLoading(false);
      }
    },
    [afterImageUpload, uploadPath],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
  });

  const renderPlaceholder = () => {
    const customStyle: any = {};
    if (customBgColor) {
      customStyle.backgroundColor = customBgColor;
    }

    return (
      <div className="image" style={customStyle}>
        <img src={image} alt="uploaded file" />
      </div>
    );
  };

  const renderFilePicker = () => {
    return (
      <div className="file-picker">
        <div className="draginfo-label">
          {(!image || image === '') && (
            <>
              {label}
              <br />
              <span className="accepts-label">
                {formatInfo ? formatInfo : `(JPG, PNG, SVG)`}
              </span>
            </>
          )}
        </div>
        <input className="image-file-picker" {...getInputProps()} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        {image && image !== '' && renderPlaceholder()}
        {editable ? renderFilePicker() : <></>}
      </>
    );
  };

  const imageClassName = classNames('image-upload-component', className, {
    'image-upload-component--gragging': isDragActive,
  });

  return (
    <div className="image-upload-wrapper">
      <div className={imageClassName} {...getRootProps()}>
        {loading ? (
          <div className="loading-container">
            <ReactLoading
              className="react-loading-spinner"
              type="spinningBubbles"
              height={50}
              width={50}
            />
          </div>
        ) : (
          renderContent()
        )}
        {canDelete && image && image !== '' && (
          <div className='image-upload-remove' onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            afterImageUpload('');
          }}>
            <i className="fa-sharp fa-solid fa-circle-xmark"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
