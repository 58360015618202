import ApiKey from 'components/ApiKey/ApiKey';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ZapierConfiguration.scss';

interface ZapierConfigurationProps {
  integrationID: string;
  index: number;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const ZapierConfiguration = ({
  integrationID,
  index,
  projectStore,
  onChange,
}: ZapierConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentAuthInfo, setAuthIntegrationInfo] = useState({} as any);

  useEffect(() => {
    if (project) {
      initialize();
    }
  }, [project]);

  const initialize = async () => {
    if (!project?.integrations?.zapier) {
      return;
    }

    const integrationInfo =
      project?.integrations?.zapier[integrationID]?.authInfo;

    setAuthIntegrationInfo(integrationInfo);
  };

  return (
    <div className="zapier-configuration-container mt-30">
      <div className="beta-link mb-20">
        Get started with our{' '}
        <a
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          href="https://zapier.com/apps/gleap/integrations"
        >
          integration here
        </a>
        .
      </div>
      <ApiKey
        apiKey={
          currentAuthInfo?.secretToken ? currentAuthInfo?.secretToken : '--'
        }
      />
      {/* <div className="mb-30" />
      <div style={{ width: '100%' }}>
        <zapier-app-directory
          app="gleap"
          link-target="same-tab"
          theme="light"
          applimit={6}
          zapstyle="row"
          introcopy="hide"
        />
      </div> */}
    </div>
  );
};

export default inject('projectStore')(observer(ZapierConfiguration));
