import React from 'react';
import classNames from 'classnames';
import './HeadLine.scss';
import { Title } from 'components/Title/Title';

interface HeadLineProps {
  title: string;
  subtitle?: string;
  component?: any;
  className?: any;
}

export const HeadLine = ({
  title,
  subtitle,
  component,
  className,
}: HeadLineProps) => {
  const headLineClassName = classNames({
    text: true,
    subtitle: true,
    'mb-15': true,
  });

  return (
    <div className={className}>
      <Title className="mb-3" label={title} />
      {subtitle ? <div className={headLineClassName}>{subtitle}</div> : <></>}
      {component}
    </div>
  );
};
