import ConditionPicker from 'components/ConditionPicker/ConditionPicker';
import Filter from 'components/Filter/Filter';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import * as CodeBlock from 'react-code-blocks';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import Gleap from 'gleap';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ActionFlowNode } from 'models/Bot';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactFlow, {
  applyEdgeChanges,
  Background,
  Controls,
  MiniMap,
  Node,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import { audienceOptions } from '../ProjectOutbounds/ProjectOutboundConfiguration/components/OutboundTriggerConfiguration/OutboundTriggerConfiguration';
import ActionEditor from './components/ActionEditor/ActionEditor';
import './FlowChart.scss';
import Loading from 'components/Loading/Loading';
import Swal from 'sweetalert2';
import LinkButton from 'components/LinkButton/LinkButton';
import ProjectLanguageDropdown from 'components/ProjectLanguageDropdown/ProjectLanguageDropdown';
import Collapsible from 'react-collapsible';

export const botTriggerOptions = [
  { name: 'No automatic trigger', value: 'none' },
  { name: 'On conversation started', value: 'on-conversation-started' },
  /*{ name: 'On conversation closed', value: 'on-conversation-closed' },*/
];

const nodeTypes: any = {
  defaultAction: ActionEditor,
};

interface FlowChartProps {
  projectStore?: ProjectStore;
  sidenavStore?: SidenavStore;
}

const FlowChart = ({ projectStore, sidenavStore }: FlowChartProps) => {
  const navigate = useNavigate();
  const currentFlows = projectStore?.bot?.actionFlows ?? [];
  const [currentPage, setCurrentPage] = useState('bot');
  const { projectId, botId } = useParams();

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (botId && projectId) {
      projectStore!.getBot(projectId, botId);
    }
  }, [botId, projectId]);

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore.getStreamedEventKeys();
    }
  }, [projectStore?.currentProject]);

  const [nodes, setNodes] = useState([] as any);
  const [edges, setEdges] = useState([] as any);

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    };
  }, []);

  useEffect(() => {
    if (currentFlows && currentFlows.length > 0) {
      setNodes(prepareNodes(currentFlows));
      setEdges(prepareEdges(currentFlows));
    }
  }, [currentFlows]);

  const prepareNodes = (actionFlows: ActionFlowNode[]) => {
    return actionFlows.map((actionFlow) => {
      const { id, position } = actionFlow;
      return {
        id,
        type: 'defaultAction',
        position: position
          ? {
            x: position.x,
            y: position.y,
          }
          : {
            x: 0,
            y: 0,
          },
        deletable: true,
        data: actionFlow,
      };
    });
  };

  const prepareEdges = (
    obj,
    preparedEdges: any = [],
    currentActionFlowId?: string,
    currentIndex?: number,
  ) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item, index) => {
            prepareEdges(item, preparedEdges, currentActionFlowId, index);
          });
        } else {
          prepareEdges(
            obj[key],
            preparedEdges,
            currentActionFlowId,
            currentIndex,
          );
        }
      } else if (key === 'id') {
        // Set the currentActionFlowId when an actionFlow object is encountered
        currentActionFlowId = obj[key];
      } else if (key === 'type' && obj[key] === 'actionflow') {
        const { actionFlow: targetActionFlow } = obj;
        const source = currentActionFlowId;
        const target = targetActionFlow;

        // Special case for source handle
        let sourceTarget =
          currentIndex !== undefined ? `${source}-${currentIndex}` : undefined;
        if (targetActionFlow.includes('else')) {
          sourceTarget = `${source}-else`;
        }

        preparedEdges.push({
          id: `e-${source}-${target}`,
          source,
          target,
          sourceHandle: sourceTarget,
          animated: false,
          type: 'smoothstep',
          markerEnd: { type: 'arrow' },
          deletable: false,
          focusable: false,
        });
      }
    }

    return preparedEdges;
  };

  const onNodesDelete = useCallback(
    (nodesToDelete: Node[]) => {
      runInAction(() => {
        for (const node of nodesToDelete) {
          projectStore!.bot.actionFlows = projectStore!.bot.actionFlows.filter(
            (actionFlow) => actionFlow.id !== node.id,
          );
        }
      });
    },
    [projectStore?.bot],
  );

  const onNodesChange = useCallback(
    (changes) => {
      runInAction(() => {
        projectStore!.bot.actionFlows = changes.reduce((prevNodes, change) => {
          return prevNodes.map((node) => {
            if (node.id === change.id) {
              // if the node ID matches the ID in the changes array, check if position is defined before updating the x and y properties
              if (change.position) {
                const { x = node.position.x, y = node.position.y } =
                  change.position;
                return {
                  ...node,
                  position: { x, y },
                };
              } else {
                // if position is undefined, return the original node object
                return node;
              }
            } else {
              // otherwise, return the original node object
              return node;
            }
          });
        }, projectStore!.bot.actionFlows);
      });
    },
    [projectStore?.bot],
  );

  const onEdgesChange = useCallback(
    (changes) => {
      /*for (const change of changes) {
        const edgeParts = change.id.split('-');
        const source = edgeParts[1];
        const target = edgeParts[2];
      }*/

      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [projectStore?.bot],
  );

  const renderBotDesigner = () => {
    return (
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodesDelete={onNodesDelete}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        maxZoom={1}
      >
        <Background />
        <Controls />
        <MiniMap nodeStrokeWidth={3} zoomable pannable />
      </ReactFlow>
    );
  };

  const renderAudience = () => {
    return (
      <>
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">Audience</div>
          <div className="audience-selection">
            <div className="audience-dropdown">
              <UserTypeDropDown
                onValueChanged={(value) => {
                  runInAction(() => {
                    projectStore!.bot!.targetAudience = value.value;
                  });
                }}
                value={audienceOptions.find(
                  ({ value }) => value === projectStore?.bot?.targetAudience,
                )}
                options={audienceOptions}
              />
            </div>
            <ConditionPicker
              type="audience"
              conditions={projectStore?.bot?.conditions ?? []}
              onChange={(conditions) => {
                runInAction(() => {
                  if (!conditions || conditions.length <= 0) {
                    projectStore!.bot!.conditions = [];
                  }
                  projectStore!.bot!.conditions = conditions;
                });
              }}
              streamedEventKeys={projectStore?.streamedEventKeys ?? []}
            />
          </div>
        </div>
      </>
    );
  };

  const trigger = botTriggerOptions.find(
    ({ value }) => value === projectStore?.bot?.trigger,
  );

  const renderTrigger = () => {
    return (
      <div className='outbound-configuration-container'>
        <Collapsible
          className="Collapsible-big"
          trigger={<div className="collapsible-header">
            <div className="collapsible-header-title">Trigger this bot automatically <i className="fa-sharp fa-solid fa-chevron-right"></i></div>
            <div className="collapsible-header-sub">
              {trigger?.value && trigger?.value !== 'none' ? <div><i className="fa-regular fa-bolt" /> {trigger?.name}</div> : <div><i className="fa-regular fa-bolt" /> Expand to set up your rules</div>}
            </div>
          </div>}
          transitionTime={200}
          overflowWhenOpen="initial"
          openedClassName="Collapsible-big Collapsible--opened"
          onClose={() => { }}
        >
          <div className="collapsible-options-group">
            <div>
              <div className="mb-10 text">
                You can trigger this bot automatically by specifying a trigger and
                audience. If multiple bots match at the same time, Gleap will pick
                the bot with the highest priority.
              </div>
            </div>
          </div>
          <div className="collapsible-options-group">
            <div className="collapsible-options-group-header">Trigger</div>
            <div className="max-width-300">
              <div className="audience-dropdown">
                <UserTypeDropDown
                  onValueChanged={(value) => {
                    runInAction(() => {
                      projectStore!.bot!.trigger = value.value;
                    });
                  }}
                  value={trigger}
                  options={botTriggerOptions}
                />
              </div>
            </div>
          </div>
          {renderAudience()}
          <div className="collapsible-options-group">
            <div className="collapsible-options-group-header">Priority</div>
            <div className="max-width-300">
              <div className="audience-dropdown">
                <TextInput
                  value={projectStore!.bot!.triggerPriority}
                  placeholder=""
                  type="number"
                  error=""
                  onChange={(val) => {
                    runInAction(() => {
                      var num = parseInt(val);
                      if (isNaN(num)) {
                        return;
                      }

                      projectStore!.bot!.triggerPriority = num;
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Collapsible>
        <Collapsible
          className="Collapsible-big"
          trigger={<div className="collapsible-header">
            <div className="collapsible-header-title">Manually start this bot</div>
            <div className="collapsible-header-sub">
              <div>
                <i className="fa-regular fa-code"></i> Start by code<br />
              </div>
            </div>
          </div>}
          transitionTime={200}
          overflowWhenOpen="initial"
          openedClassName="Collapsible-big Collapsible--opened"
          onClose={() => { }}
        >
          <div className="collapsible-options-group">
            <div>
              <div className='mb-10 text'>
                The following code snippet shows how to start this bot by code. This is useful if you want to have full control over it.
              </div>
              <CodeBlock.CodeBlock
                text={`Gleap.startBot("${botId}");`}
                language={'js'}
                showLineNumbers={false}
              />
              <div className='mt-10 text'>
                Check out our <a href="https://docs.gleap.io/javascript/surveys/#manually-sending-surveys" target="_blank" rel="noreferrer">full documentation</a> on how to get started with bots.
              </div>
            </div>
          </div>
        </Collapsible>
      </div>
    );
  };

  return (
    <PageContainer className="flow-chart-bot page-centered-main-tabs">
      <PageHeadLine
        isEditable
        onChangeTitle={(newTitle) => {
          projectStore!.bot.name = newTitle;
        }}
        onActionBack={() => {
          navigate(`/projects/${projectId}/bots`);
        }}
        title={projectStore?.bot?.name}
      >
        <div className="header-content-left">
          <ProjectLanguageDropdown />
        </div>
        <div className="centered-main-tabs">
          <div className="main-tabs">
            <Filter
              onValueChange={(value) => {
                setCurrentPage(value);
              }}
              value={currentPage}
              options={[
                {
                  name: 'Bot designer',
                  value: 'bot',
                },
                { name: 'Trigger', value: 'trigger' },
              ]}
            />
          </div>
        </div>
        <div className="header-content-right">
          {projectStore?.bot?.status === 'draft' && (
            <LinkButton
              className="danger"
              label="Delete"
              onClick={() => {
                Swal.fire({
                  text: 'Are you sure you want to delete this bot?',
                  showCancelButton: true,
                  confirmButtonText: `Yes`,
                  denyButtonText: `No`,
                }).then(async (result) => {
                  if (result.isConfirmed && botId) {
                    await projectStore?.deleteBot(botId);
                    navigate(`/projects/${projectId}/bots`);
                  }
                });
              }}
            />
          )}
          <LinkButton
            onClick={() => {
              if (projectId && botId) {
                projectStore!.updateBot(projectId, botId, {
                  status: projectStore?.bot?.status,
                  name: projectStore?.bot?.name,
                  actionFlows: projectStore?.bot?.actionFlows,
                  triggerPriority: projectStore?.bot?.triggerPriority,
                  trigger: projectStore?.bot?.trigger,
                  targetAudience: projectStore?.bot?.targetAudience,
                  conditions: projectStore?.bot?.conditions,
                });
              }
            }}
            className="save-button ml-10"
            label="Save"
          />
          {projectStore?.bot?.status === 'draft' && (
            <PrimaryButton
              onClick={async () => {
                if (projectId && botId) {
                  projectStore!.updateBot(projectId, botId, {
                    status: 'live',
                    name: projectStore?.bot?.name,
                    actionFlows: projectStore?.bot?.actionFlows,
                    triggerPriority: projectStore?.bot?.triggerPriority,
                    trigger: projectStore?.bot?.trigger,
                    targetAudience: projectStore?.bot?.targetAudience,
                    conditions: projectStore?.bot?.conditions,
                  });
                }
              }}
              className="save-button live ml-10"
              icon='play'
              label="Publish"
            />
          )}
          {projectStore?.bot?.status === 'live' && (
            <PrimaryButton
              className="save-button danger ml-10"
              icon='pause'
              label="Unpublish"
              onClick={async () => {
                if (projectId && botId) {
                  projectStore!.updateBot(projectId, botId, {
                    status: 'draft',
                    name: projectStore?.bot?.name,
                    actionFlows: projectStore?.bot?.actionFlows,
                    triggerPriority: projectStore?.bot?.triggerPriority,
                    trigger: projectStore?.bot?.trigger,
                    targetAudience: projectStore?.bot?.targetAudience,
                    conditions: projectStore?.bot?.conditions,
                  });
                }
              }}
            />
          )}
        </div>
      </PageHeadLine>
      {!projectStore?.bot && (
        <PageContent hasTitle>
          <Loading />
        </PageContent>
      )}
      {projectStore?.bot && (
        <PageContent hasTitle>
          {currentPage === 'bot' && renderBotDesigner()}
          {currentPage === 'trigger' && renderTrigger()}
        </PageContent>
      )}
    </PageContainer>
  );
};

export default inject('projectStore', 'sidenavStore')(observer(FlowChart));
