import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import ReactJson from 'react-json-view';
import './NetworkLogItem.scss';
import networkLogIconOK from '../../../assets/icons/np_arrow_3542562_000000.svg';
import networkLogIconWarn from '../../../assets/icons/np_arrow_3542562_000000_WARN.svg';
import networkLogIconFailed from '../../../assets/icons/np_arrow_3542562_000000_FAILED.svg';
import arrow from '../../../assets/icons/np_arrow_3134184_FFFFFF.svg';
import { getNetworkLogStatusText } from '../ActivityTimeline/ActivityTimeline';

export const getNetworkLogIconForType = (type) => {
  if (type === 'ERROR') {
    return networkLogIconFailed;
  }
  if (type === 'WARNING') {
    return networkLogIconWarn;
  }
  return networkLogIconOK;
};

export const getNetworkLogType = (log) => {
  if (log.type === "RESOURCE") {
    return "OK";
  }

  let type = 'ERROR';
  if (log.response?.status) {
    const status = parseInt(log.response?.status ?? 0, 10);
    if (status >= 400) {
      type = 'ERROR';
    } else if (status >= 300) {
      type = 'WARNING';
    } else {
      type = 'OK';
    }
  }

  return type;
};

const NetworkLogItem = ({ log, index }: any) => {
  const [showResponse, setShowResponse] = useState(true);
  const [showRequest, setShowRequest] = useState(false);

  const formatDate = (date: string) => {
    const formatedDate = moment(date).format('HH:mm:ss');
    return formatedDate;
  };

  const logType = getNetworkLogType(log);

  const logClassName = classNames('networklog-item', {
    'networklog-item--warning': logType === 'WARNING',
    'networklog-item--error': logType === 'ERROR',
  });

  const jsonParseElement = (data, key) => {
    if (data[key]) {
      try {
        data[key] = JSON.parse(data[key]);
        // eslint-disable-next-line no-empty
      } catch (exp) { }
    }
  };

  const cleanupRequest = (data) => {
    if (!data) {
      return {};
    }

    jsonParseElement(data, 'headers');
    jsonParseElement(data, 'responseText');
    jsonParseElement(data, 'payload');

    return data;
  };

  const dataRequest = cleanupRequest(log.request);
  const dataResponse = cleanupRequest(log.response);

  return (
    <div className={logClassName} key={index}>
      <div className="log-count">
        <img src={getNetworkLogIconForType(logType)} alt="network log" />
        <div>{formatDate(log.date)}</div>
      </div>
      <div className="log-data">
        <div className="log-url">
          {getNetworkLogStatusText(log)}{' '}
          <div>
            <a href={log.url} target="_blank">
              {log.url}
            </a>
          </div>
        </div>
        <div className="log-extended">
          <b>Duration</b>: {log.duration}ms
          <br />
          {log.request && <><span
            onClick={() => {
              setShowRequest(!showRequest);
            }}
          >
            <img
              alt="arrow"
              src={arrow}
              className={`arrow ${showRequest ? 'arrow--down' : ''}`}
            />{' '}
            Request
          </span>
            {showRequest && (
              <div className="log-data-details">
                <ReactJson
                  theme={
                    localStorage.getItem('theme') === 'dark'
                      ? 'brewer'
                      : 'shapeshifter:inverted'
                  }
                  collapsed={1}
                  src={dataRequest}
                  style={{
                    background: 'transparent',
                  }}
                />
              </div>
            )}</>}
          <br />
          {log.response ? <>
            <span
              onClick={() => {
                setShowResponse(!showResponse);
              }}
            >
              <img
                alt="arrow"
                src={arrow}
                className={`arrow ${showResponse ? 'arrow--down' : ''}`}
              />{' '}
              Response
            </span>
            {showResponse && (
              <div className="log-data-details">
                <ReactJson
                  theme={
                    localStorage.getItem('theme') === 'dark'
                      ? 'brewer'
                      : 'shapeshifter:inverted'
                  }
                  collapsed={1}
                  src={dataResponse}
                  style={{
                    background: 'transparent',
                  }}
                />
              </div>
            )}</> : <>Could not collect the request payload. This usually means:<br /> - The request was sent before the Gleap SDK got initialized<br /> - It's a static resource<br />- You exceeded the <a href="https://docs.gleap.io/javascript/network-logs#limitations" target="_blank">request limit</a> (default: last 30 requests)</>}
        </div>
      </div>
    </div>
  );
};

export default NetworkLogItem;
