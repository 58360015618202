import { ReactComponent as WidgetIconActive } from 'assets/icons/np_widget_411203_FFFFFF.svg';
import FeedbackActionEditorItem from 'components/FeedbackActionsEditor/FeedbackActionEditorItem';
import FeedbackActionsEditor from 'components/FeedbackActionsEditor/FeedbackActionsEditor';
import { HeadLine } from 'components/HeadLine/HeadLine';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubNav from 'components/SubNav/SubNav';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  Route,
  Routes, useLocation, useNavigate, useParams
} from 'react-router';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import AdminNeeded from '../../../assets/AdminNeeded.svg';
import DeveloperOptionsWidgetSettings from './DeveloperOptionsWidgetSettings/DeveloperOptionsWidgetSettings';
import FeedbackButtonSettings from './FeedbackButtonSettings/FeedbackButtonSettings';
import FormWidgetSettings from './FormWidgetSettings/FormWidgetSettings';
import GeneralWidgetSettings from './GeneralWidgetSettings/GeneralWidgetSettings';
import OperatingHoursSettings from './OperatingHoursSettings/OperatingHoursSettings';
import './ProjectWidget.scss';
import TranslationsWidgetSettings from './TranslationsWidgetSettings/TranslationsWidgetSettings';
import WdigetApps from './WdigetApps/WdigetApps';
import WidgetButtonPreview from './WidgetButtonPreview/WidgetButtonPreview';
import WidgetPreview from './WidgetPreview/WidgetPreview';

interface ProjectWidgetProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
  sidenavStore?: SidenavStore;
}

function ProjectWidget({
  projectStore,
  organisationStore,
  sidenavStore,
}: ProjectWidgetProps) {
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path, extraPaths: string[] = []) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4) {
        if (parts[4] === path) {
          return true;
        }

        for (let i = 0; i < extraPaths.length; i++) {
          if (parts[4] === extraPaths[i]) {
            return true;
          }
        }
      }

      if (path === '' && parts.length === 4 && parts[3] === 'widget') {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId, false, true);
    }


  }, [projectId]);

  const isAdmin =
    projectStore?.isProjectAdmin || organisationStore?.isOrganisationAdmin;
  const isAdminLoaded =
    projectStore?.isProjectAdminLoaded ||
    organisationStore?.isOrganisationAdminLoaded;

  if (projectStore?.currentProject === null) {
    return <Loading />;
  }

  const renderMainContent = () => {
    if (isAdmin) {
      return (
        <Routes>
          <Route path="/feedbackbutton" element={<FeedbackButtonSettings />} />
          <Route path="/form" element={<FormWidgetSettings />} />
          <Route path="/flows/:feedbackFlowId" element={<FeedbackActionEditorItem />} />
          <Route path="/flows" element={<FeedbackActionsEditor />} />
          <Route path="/apps" element={<WdigetApps />} />
          <Route path="/operating-hours" element={<OperatingHoursSettings />} />
          <Route
            path="/translations"
            element={<TranslationsWidgetSettings />}
          />
          <Route path="/*" element={<GeneralWidgetSettings />} />
        </Routes>
      );
    }

    if (!isAdminLoaded) {
      return <Loading />;
    }

    return (
      <PageContainer className="relativ-full-width-page-container">
        <PageHeadLine title="Permission required" />
        <PageContent hasTitle>
          <div className="no-permissions-container">
            <img
              src={AdminNeeded}
              alt="No permissions"
              className="no-permissions-image"
            />
            <HeadLine
              title="Permission required"
              subtitle="All widget settings require admin permissions. Please contact your organization manager to upgrade your permissions."
            />
          </div>
        </PageContent>
      </PageContainer>
    );
  };

  const widgetCustomizationIsOpen = isActive('', ['general']);

  return (
    <SidenavContainer className="widget-sidenav-container" sidenavHidden={sidenavStore?.sidenavHidden}>
      <SubSidenav title="Widget">
        <SubNav
          title="Customize"
          faIcon='paintbrush-pencil'
          onClick={() => {
            navigate(`/projects/${projectId}/widget`);
          }}
          isOpened={
            widgetCustomizationIsOpen || isActive('feedbackbutton')
          }
        >
          <SimpleSidenavElement
            name="Widget"
            onClick={() => {
              navigate(`/projects/${projectId}/widget`);
            }}
            isActive={widgetCustomizationIsOpen}
          />
          <SimpleSidenavElement
            name="Feedback button"
            onClick={() => {
              navigate(`/projects/${projectId}/widget/feedbackbutton`);
            }}
            isActive={isActive('feedbackbutton')}
          />
        </SubNav>
        <SimpleSidenavElement
          name="Widget apps"
          onClick={() => {
            navigate(`/projects/${projectId}/widget/apps`);
          }}
          faIcon='grid-round-2-plus'
          isActive={isActive('apps')}
        />
        <SimpleSidenavElement
          name="Home menu"
          onClick={() => {
            navigate(`/projects/${projectId}/widget/form`);
          }}
          faIcon='bars'
          isActive={isActive('form')}
        />
        <SimpleSidenavElement
          name="Feedback flows"
          onClick={() => {
            navigate(`/projects/${projectId}/widget/flows`);
          }}
          faIcon='bolt'
          isActive={isActive('flows')}
        />
        <SimpleSidenavElement
          name="Operating hours"
          onClick={() => {
            navigate(`/projects/${projectId}/widget/operating-hours`);
          }}
          faIcon='clock'
          isActive={isActive('operating-hours')}
        />
        <SimpleSidenavElement
          name="Translations"
          onClick={() => {
            navigate(`/projects/${projectId}/widget/translations`);
          }}
          faIcon='language'
          isActive={isActive('translations')}
        />
      </SubSidenav>
      <div className="widget-configurator-content-wrapper">
        <div className="widget-content-page">{renderMainContent()}</div>
        {!(
          location.pathname.includes('translations') ||
          location.pathname.includes('flows') ||
          location.pathname.includes('feedbackbutton')
        ) && (
            <div className="widget-preview-wrapper">
              <WidgetPreview />
            </div>
          )}
        {location.pathname.includes('feedbackbutton') && (
          <div className="widget-preview-wrapper">
            <WidgetButtonPreview />
          </div>
        )}
      </div>
    </SidenavContainer>
  );
}

export default inject(
  'projectStore',
  'organisationStore',
  'sidenavStore',
)(observer(ProjectWidget));
