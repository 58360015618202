import { ampli } from 'ampli';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import { Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Subscription from 'components/Subscription/Subscription';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './UpsellOnboardingModal.scss';

interface UpsellOnboardingModalProps {
  usersStore?: UsersStore;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const UpsellOnboardingModal = ({
  usersStore,
  modalStore,
  projectStore,
  organisationStore,
}: UpsellOnboardingModalProps) => {
  const [chooseSubscription, setChooseSubscription] = useState(false);
  const [trialType, setTrialType] = useState(Plantypes.GROWTH as any);
  const [loadingSkip, setLoadingSkip] = useState(false);

  useEffect(() => {
    try {
      const trialTypeVar = localStorage.getItem('trial-type');
      if (trialTypeVar && trialTypeVar === 'starter') {
        setTrialType(Plantypes.STARTER);
      }

      ampli.upsellModalShown({
        planId: trialTypeVar ?? 'growth',
        isOnboarding: true,
      });
    } catch (exp) { }
  }, []);

  if (loadingSkip) {
    <div className="upsell-onboarding-modal">
      <div className="upsell-onboarding-modal-centered">
        <LoadingAnimation />
      </div>
    </div>
  }

  return (
    <div className="upsell-onboarding-modal">
      <div className="upsell-onboarding-modal-centered">
        {chooseSubscription && <Subscription plan={trialType} />}
        {!chooseSubscription && (
          <>
            <div className="onboarding-trial-header">
              <HeadLine title={`Start your free trial ✨`} />
            </div>
            <div className="text mt-30 mb-20">
              You are only one step away from starting your 14-day free trial,
              including access to: <b>all integrations</b>, <b>help center</b>,{' '}
              <b>micro surveys</b> and so <b>much more</b>.
            </div>
            <div className="text mb-30">
              <b>Cancel any time.</b> We will remind you 7 days before your
              trial ends.
            </div>
            <div>
              <PrimaryButton
                label={`Start 14-day free trial`}
                iconSideRight={true}
                fullWidth={true}
                icon="arrow-right"
                onClick={() => {
                  ampli.upsellModalPlanSelected({
                    planId: trialType,
                    isOnboarding: true,
                  });

                  setChooseSubscription(true);
                }}
              />
              <a
                className="skip-button"
                href="#"
                onClick={async () => {
                  setLoadingSkip(true);
                  await organisationStore!.startFreeOrgaTrial();
                  organisationStore!.getMyOrganisations();
                  projectStore?.getProjects();
                  projectStore?.getProjectsUnreadCount();
                }}
              >
                Skip for now
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default inject(
  'usersStore',
  'modalStore',
  'projectStore',
  'organisationStore',
)(observer(UpsellOnboardingModal));
