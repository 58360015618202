import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './MAULimitProgress.scss';

interface MAULimitProgressProps {
  organisationStore?: OrganisationStore;
  isHobbyPlan?: boolean;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MAULimitProgress = ({ organisationStore, isHobbyPlan }: MAULimitProgressProps) => {
  const mauUsage = organisationStore?.currentMAU ?? 0;
  const includedMAU = organisationStore?.currentOrganisation?.subscription?.metadata?.sessionlimit ?? 1000;
  const maxScale = Math.max(mauUsage, includedMAU) * 1.3;
  const mauUsagePercentage = Math.ceil((mauUsage / maxScale) * 100);
  const mauUsageIncludedPercentage = Math.ceil((includedMAU / maxScale) * 100);

  if (organisationStore?.loadingMAU) {
    return <div className='centered'>
      <LoadingAnimationSmall />
    </div>
  }

  return (
    <>
      <div className='mau-limit-cont'>
        <div className='mau-limit-progress-bar'>
          <div className='mau-limit-progress-bar-inner' style={{
            minWidth: `${mauUsagePercentage}%`,
          }}></div>
          <div className='mau-limit-included' style={{
            left: `${mauUsageIncludedPercentage}%`,
          }}>
            <div className='mau-limit-included-label'>{numberWithCommas(includedMAU)} included</div>
          </div>
        </div>
        <div className='mau-limit-info'>{mauUsage} people reached</div>
      </div>
      <div className='text mt-30'>We charge a monthly fee of $5 for every additional 1,000 people reached utilized. {isHobbyPlan && mauUsage > includedMAU && <>Upgrade your subscription to our <b>Pro</b> plan to <b>save on additional people reached</b>.</>}</div>
    </>
  );
};

export default inject('organisationStore')(observer(MAULimitProgress));
