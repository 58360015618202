import ListTable, { CellGestureDetector, CellSession } from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import TimeAgo from 'react-timeago';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './UnansweredQuestions.scss';
import LinkButton from 'components/LinkButton/LinkButton';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import GleapBotAI from 'assets/GleapBotAi.png';
import { HeadLine } from 'components/HeadLine/HeadLine';
import InfoBox from 'components/InfoBox/InfoBox';

interface UnansweredQuestionsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const UnansweredQuestions = ({ projectStore, modalStore }: UnansweredQuestionsProps) => {
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;

  const sendAnswer = async (id, data) => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    await projectStore?.updateQAAnswer(projectStore.currentProject?.id, id, data);
    modalStore!.closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        width: 'calc(100% - 520px)',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value,
              });
            }}
            label={row.value.question}
          />
        ),
      },
      {
        Header: 'Asked by',
        width: 200,
        accessor: (row) => row,
        Cell: (row) => {
          if (!row.value.session) {
            return null;
          }

          return (<CellSession
            session={row.value.session}
            url={`/projects/${projectId}/sessions/${row.value.session?.id}`}
          />);
        }
      },
      {
        width: 150,
        Header: 'Asked',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        width: 170,
        Header: 'Action',
        accessor: (row) => row,
        Cell: (row) => (<div>
          <LinkButton
            small
            onClick={() => {
              sendAnswer(row.value.id, { skipped: true });
            }}
            className="mr-10"
            label="Skip"
          />
          <PrimaryButton
            small
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value,
              });
            }}
            label="Answer"
          />
        </div>)
      }
    ],
    [],
  );

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getQAAnswers(currentProject.id, true, 0, 100);
    }
  }, [currentProject]);

  if (!currentProject) {
    return <></>;
  }

  const answers = projectStore?.currentQAAnswers.filter((answer) => {
    if (answer.skipped || answer.acknowledged) {
      return false;
    }

    return true;
  }) || [];

  const renderAnswers = () => {
    if (projectStore.loadingQAAnswers) {
      return <div className='loading mt-100'>
        <LoadingAnimationMedium />
      </div>;
    }

    if (answers.length === 0) {
      return (
        <div className="no-surveys-container">
          <img
            src={GleapBotAI}
            alt="No surveys created yet"
            className="no-surveys-image"
          />
          <>
            <HeadLine
              className="mt-20"
              title="No unanswered questions"
              subtitle="Unanswered questions will appear here once your customers interact with your bot and ask questions that are not yet answered."
            />
          </>
        </div>
      );
    }

    return (<>
      <InfoBox className='mb-30'>
        The answer bot only answers questions based on the content of your help center or already answered answers. If an answer can't be found, the question will be shown in the list below. Simply answer the question once, to help the bot better serve your customers.
      </InfoBox>
      <ListTable
        data={answers}
        columns={columns}
      />
    </>);
  }

  return (
    <PageContainer className="answer-bot">
      <PageHeadLine title="Unanswered questions" tag='BETA' />
      <PageContent hasTitle isLargeBoxed>
        {renderAnswers()}
      </PageContent>
    </PageContainer >
  );
};

export default inject('projectStore', 'modalStore')(observer(UnansweredQuestions));
