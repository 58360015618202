import { Card } from 'components/Card/Card';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { isEqual } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import Switch from 'react-switch';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './WidgetAppSettingsModal.scss';

interface WidgetAppSettingsModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const WidgetAppSettingsModal = ({
  modalStore,
  projectStore,
}: WidgetAppSettingsModalProps) => {
  const navigate = useNavigate();
  var { flowConfig } = projectStore?.editingProject || {};
  const data = modalStore?.customData;
  const selector = () => {
    if (data?.type === 'news') {
      return 'hideNews';
    }
    if (data?.type === 'featureRequests') {
      return 'hideFeatureRequests';
    }
    if (data?.type === 'knowledgeBase') {
      return 'hideKnowledgeBase';
    }
    return 'hideAllConversations';
  };

  const mainSwitchSelector = selector();

  const renderCustomConfig = (type: string) => {
    if (type === 'featureRequests') {
      return (<>
        <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                flowConfig.showNoFeatureSuggestions = !checked;
              });
            }}
            checked={flowConfig.showNoFeatureSuggestions ? false : true}
          />
          <span>Suggest feature requests before submitting a new feature request</span>
        </div>
      </>);
    }

    if (type === 'news') {
      return (<>
        <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                flowConfig.showNoHomeNews = !checked;
              });
            }}
            checked={flowConfig.showNoHomeNews ? false : true}
          />
          <span>Show news card on home page</span>
        </div>
      </>);
    }

    return null;
  }

  return (
    <div className="widget-app-settings-modal">
      <Card className="widget-app-card">
        <div className="link-header">
          {data.image && <data.image className="image" />}
        </div>
        <div className="link-body">
          <span className="title">{data.title}</span>
          <div className="description">{data.description}</div>
        </div>
      </Card>
      <div className="switch-container feedback-content-row-cell mr-10">
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            runInAction(() => {
              flowConfig[mainSwitchSelector] = !checked;
            });
          }}
          checked={flowConfig[mainSwitchSelector] ? false : true}
        />
        <span>Show in tab bar</span>
      </div>
      {renderCustomConfig(data.type)}
      <PrimaryButton
        label="Save"
        className="mt-20"
        disabled={isEqual(projectStore?.flowConfig, flowConfig)}
        onClick={() => {
          projectStore?.saveEditingProject();
          modalStore?.closeModal();
        }}
      />
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(WidgetAppSettingsModal));
