/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import './DarkModeToggle.scss';

interface DarkModeToggleProps {
  className?: string;
  onModeChanged?: (darkModeOn: boolean) => void;
  timedTurnDark?: boolean;
}

// eslint-disable-next-line no-empty-pattern
export const DarkModeToggle = ({
  className,
  onModeChanged,
  timedTurnDark,
}: DarkModeToggleProps) => {
  const [htmlElementId] = useState(`prefix-${Math.random() * 100}`);
  const [darkModeOn, setDarkModeOn] = useState(
    localStorage.getItem('theme') === 'dark',
  );

  useEffect(() => {
    if (timedTurnDark) {
      setTimeout(() => {
        setDarkModeOn(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (darkModeOn) {
      document.body.classList.add('theme--dark');
      document.body.classList.remove('theme--default');
      localStorage.setItem('theme', 'dark');
    } else {
      document.body.classList.remove('theme--dark');
      document.body.classList.add('theme--default');
      localStorage.setItem('theme', 'default');
    }
    if (onModeChanged) {
      onModeChanged(darkModeOn);
    }
    const toggles = document.body.getElementsByClassName('darkside-checkbox');
    for (let i = 0; i < toggles.length; i++) {
      (toggles[i] as any).checked = darkModeOn;
    }
  }, [darkModeOn]);

  return (
    <div className="dark-mode-container">
      <div className="dark-mode-title">Dark mode</div>
      <div className={`dark-mode-toggle ${className}`}>
        <input
          type="checkbox"
          className="checkbox darkside-checkbox"
          id={htmlElementId}
          onChange={(e) => {
            setDarkModeOn(e.target.checked);
          }}
          checked={darkModeOn}
        />
        <label className="label" htmlFor={htmlElementId}>
          <i className="fas fa-moon" />
          <i className="fas fa-sun" />
          <div className="ball" />
        </label>
      </div>
    </div>
  );
};
