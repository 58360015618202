import React from 'react';
import './SessionData.scss';
import classNames from 'classnames';
import { BugStore } from 'stores/private/BugStore';
import Linkify from 'linkify-react';
import { inject } from 'mobx-react';
import { enrichMetaData } from 'helper/MetaDataEnricher';
import { Feature } from 'models/Enums';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import suggestSubscriptionIcon from 'assets/icons/crystal-ball-solid.svg';
import { Plantypes } from 'components/PriceTable/PriceTable';

interface CustomDataProps {
  bugStore?: BugStore;
}

const CustomData = ({ bugStore }: CustomDataProps) => {
  const bug = bugStore!.currentBug;
  const planIsEntitled = bugStore?.isFeatureInPlan(Feature.SESSION_DATA);
  const darkMode = localStorage.getItem('theme') === 'dark';

  if (!bug) {
    return null;
  }

  if (!planIsEntitled) {
    return (
      <div
        className={classNames(
          {
            'session-data-container--bg--light': !darkMode,
            'session-data-container--bg--dark': darkMode,
          },
          'session-data-container session-data-container--bg',
        )}
      >
        <SuggestSubscription
          plan={Plantypes.GROWTH}
          icon={suggestSubscriptionIcon}
          title="Session & environment data"
          description="With Session & environment data you get all the contextual information to reproduce a bug. Included with Gleap Team for $109/month."
        />
      </div>
    );
  }

  const renderBugMeta = () => {
    if (!bug.metaData) {
      return (
        <>
          <div className="session-data-container session-data-container--no-contents text">
            <div>No environment data available.</div>
          </div>
        </>
      );
    }

    const metaDataItems = enrichMetaData(bug.metaData);

    return (
      <>
        <div className="meta-data-container">
          <div className="meta-container">
            {metaDataItems.map((item, index) => {
              if (!item.value) {
                return <div key={index} />;
              }
              return (
                <div className="detail-item" key={index}>
                  <div className="desc-item-icon">
                    <i className={`fa-regular fa-${item.icon}`} />
                  </div>
                  <div className="desc-item-content">
                    <div className="desc-item-header">{item.title}</div>
                    <div className="desc-item">
                      <Linkify
                        tagName="span"
                        options={{
                          target: {
                            url: '_blank',
                          },
                        }}
                      >
                        {typeof item.value === 'string'
                          ? item.value
                          : JSON.stringify(item.value)}
                      </Linkify>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return <div className="session-data-container">{renderBugMeta()}</div>;
};

export default inject('bugStore')(CustomData);
