import React from 'react';
import './SidenavElement.scss';

interface SimpleSidenavElementProps {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  IconComponent?: any;
  faIcon?: string;
  name: string;
  onClick: () => void;
  isActive?: boolean;
  visible?: boolean;
  notificationCount?: number;
  tag?: string;
}
const SimpleSidenavElement = ({
  Icon,
  IconComponent,
  faIcon,
  name,
  onClick,
  isActive,
  visible = true,
  notificationCount,
  tag,
}: SimpleSidenavElementProps) => {
  if (!visible) {
    return <></>;
  }

  let notificationBadge;
  if (notificationCount && notificationCount > 0) {
    notificationBadge = (
      <div className="notifications-badge">{notificationCount > 9 ? '9+' : notificationCount}</div>
    );
  }

  return (
    <div
      className={`simple-sidenav-element ${isActive ? 'active' : ''}`}
      onClick={() => {
        onClick();
      }}
    >
      {Icon && <Icon />}
      {IconComponent && IconComponent}
      {faIcon && <i className={`fa-solid fa-${faIcon}`} />}
      {name}
      {notificationBadge}
      {tag && <div className="simple-sidenav-element-tag-outer">
        <div className="simple-sidenav-element-tag">{tag}</div>
      </div>}
    </div>
  );
};

export default SimpleSidenavElement;
