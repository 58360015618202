import React, { useState } from 'react';
import './InvitePeopleOnboardingModal.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { inject } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { TextHref } from 'components/TextHref/TextHref';
import { HeadLine } from 'components/HeadLine/HeadLine';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';

import crossIcon from 'assets/icons/closeerror.svg';
import checkIcon from 'assets/icons/checkGreen.png';
import GleapTeamImage from 'assets/icons/GleapTeam.png';
import LinkButton from 'components/LinkButton/LinkButton';
import { ampli } from 'ampli';

interface InvitePeopleOnboardingModalProps {
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

const InvitePeopleOnboardingModal = ({
  organisationStore,
  modalStore,
  projectStore,
  usersStore,
}: InvitePeopleOnboardingModalProps) => {
  const MAX_INVITATIONS = 4;
  const [inputFormValues, setInputFormValues] = useState(['']);
  const [invitations, setInvitations] = useState([]);
  const [invalidInvitations, setInvalidInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invitationsSent, setInvitationsSent] = useState(false);
  const { register, handleSubmit, formState } = useForm();
  const type = modalStore?.customData?.type ?? 'organisation';

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    const emails: any = [];
    for (const key in data) {
      if (data[key] !== '') {
        emails.push(data[key]);
      }
    }

    await setInvitations(emails);

    ampli.peopleInvited({
      isOnboarding: true,
      level: type ? 'organization' : 'project',
    });

    let invalidInvitationsArray = [];
    if (type === 'organisation') {
      invalidInvitationsArray = await organisationStore!.inviteTeamMember(
        emails,
      );
    } else {
      invalidInvitationsArray = await projectStore!.inviteTeamMember(emails);
    }
    if (invalidInvitationsArray) {
      setInvalidInvitations(invalidInvitationsArray);
    }

    setIsLoading(false);
    setInvitationsSent(true);
  };

  const checkIfEmailIsValid = (input: String) => {
    const index = invalidInvitations?.findIndex((email) => email === input);
    return index === -1;
  };

  const closeSuccessScreen = () => {
    usersStore!.updateUser(
      usersStore?.currentUser?.id!,
      {
        completedOnboarding: true,
      },
      true,
    );
    organisationStore!.getInvitedTeamMembers();
    organisationStore!.getInvitedOrgaTeamMembers();
    projectStore!.getInvitedTeamMembers();
    modalStore!.closeModal();
  };

  const renderInviteForm = () => {
    if (invitationsSent) {
      return (
        <>
          {invalidInvitations && invalidInvitations.length > 0 ? (
            <HeadLine
              className="headline"
              title="Some invitations couldn't be sent"
            />
          ) : (
            <HeadLine
              className="headline"
              title="Successfully sent invitations"
            />
          )}
          {invitations.map((email: any, key) => {
            return (
              <div className="invitatin-row" key={`success${key}`}>
                {checkIfEmailIsValid(email) ? (
                  <img src={checkIcon} alt="icon" className="icon" />
                ) : (
                  <img src={crossIcon} alt="icon" className="icon" />
                )}
                <TextInput
                  className="text-input"
                  readOnly
                  placeholder={email}
                  initalValue={email}
                  error={
                    checkIfEmailIsValid(email)
                      ? ''
                      : 'User is already part of an organisation.'
                  }
                />
              </div>
            );
          })}
          <div>
            <PrimaryButton
              label="Done"
              onClick={closeSuccessScreen}
              className="button-success"
            />
          </div>
        </>
      );
    }

    return (
      <div>
        <HeadLine
          title="Invite your team"
          subtitle="Gleap is a perfect fit for teams."
        />
        <LinkButton
          icon="arrow-left-long"
          iconSideRight={false}
          className="invite-people-back"
          onClick={() => {
            modalStore!.openModal(MODALTYPE.ONBOARDING);
          }}
          label="Back"
        />
        <form onSubmit={handleSubmit(onSubmit)} className="invite-people">
          {inputFormValues.map((item, key) => {
            return (
              <div className="textinput content-width" key={key}>
                <TextInput
                  inputRef={register(`input${key}`, {
                    pattern: /^\S+@\S+\.\S+$/,
                  })}
                  placeholder="name@company.com"
                  type="text"
                  error={
                    formState.errors[`input${key}`] ? 'E-Mail address is not valid' : ''
                  }
                  name={`input${key}`}
                  initalValue={item}
                  className="mb-10"
                />
              </div>
            );
          })}
          {inputFormValues.length < MAX_INVITATIONS && (
            <TextHref
              className="mb-20"
              onClick={() => {
                const arr = inputFormValues;
                arr.push('');
                setInputFormValues([...arr]);
              }}
              label="+ Add more"
            />
          )}
          <PrimaryButton
            label="Invite"
            icon="paper-plane-top"
            isLoading={isLoading}
            iconSideRight
            onClick={() => { }}
            submit
          />
        </form>
      </div>
    );
  };

  return (
    <div className="invite-modal">
      <div className="invite-modal-left">{renderInviteForm()}</div>
      <div className="invite-modal-right">
        <img src={GleapTeamImage} alt="invite team members" />
      </div>
    </div>
  );
};

export default inject(
  'organisationStore',
  'projectStore',
  'modalStore',
  'usersStore',
)(InvitePeopleOnboardingModal);
