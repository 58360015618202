import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import TextInput from 'components/TextInput/TextInput';
import { Outbound } from 'models/Outbound';
import { useEffect, useRef, useState } from 'react';
import './NewsConfiguration.scss';
import { Editor, useEditor } from '@tiptap/react';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import { runInAction } from 'mobx';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import Filter from 'components/Filter/Filter';

interface NewsConfigurationProps {
  outboundRule: Outbound;
  content?: any;
  inputContentChanged?: (content: any) => void;
  projectStore?: ProjectStore;
}

const NewsConfiguration = ({
  outboundRule,
  content,
  inputContentChanged,
  projectStore,
}: NewsConfigurationProps) => {
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const [toggleState, setToggleState] = useState(false);

  useEffect(() => {
    editorRef?.current?.commands.setContent(content ?? {});
  }, [editorRef]);

  const _buildSidebar = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Article options</div>
        <div className="input-label">Image</div>
        <ImageUpload
          className="news-cover-image-upload mt-10"
          label="Upload header image"
          formatInfo='Recommended: 1380x670px'
          image={outboundRule.coverImageUrl ?? ''}
          editable
          uploadPath="manuallyuploaded"
          afterImageUpload={(imageURL) => {
            runInAction(() => {
              outboundRule.coverImageUrl = imageURL;
              setToggleState(!toggleState);
            });
          }}
        />
        <div className="input-label mt-20">Author</div>
        <FeedbackUserFilter
          value={outboundRule.sender}
          truncatePreview={50}
          truncate={50}
          placeholder="Select user"
          onValueChanged={(value) => {
            runInAction(() => {
              if (value) {
                outboundRule.sender = value;
              }
            });
          }}
        />
        <div className="filter-form mt-30">
          <div className="input-label">Notification type</div>
          <div className="mt-10">
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Don't notify customer</div>
              <input
                type="radio"
                name="continuously"
                checked={outboundRule.actionType === 'nonotify_news'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.actionType = 'nonotify_news';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Notify customer</div>
              <input
                type="radio"
                name="once"
                checked={outboundRule.actionType === 'notification'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.actionType = 'notification';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
          </div>
        </div>
        {outboundRule.actionType === 'notification' && (
          <>
            <div className="input-label mt-30">Audience</div>
            <div className="filter-form">
              <Filter
                onValueChange={(value) => {
                  runInAction(() => {
                    outboundRule.targetAudience = value;
                  });
                }}
                value={outboundRule.targetAudience}
                options={[
                  { name: 'All people', value: 'all' },
                  { name: 'Users only', value: 'users' },
                  { name: 'Guests only', value: 'guests' },
                ]}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const _buildNewsEditor = () => {
    return (
      <div className="news-input-body">
        <TextInput
          className="mb-20"
          placeholder="Title"
          error=""
          initalValue={outboundRule.subject}
          onChange={(val) => {
            runInAction(() => {
              outboundRule.subject = val;
              outboundRule.name = val;
              setToggleState(!toggleState);
            });
          }}
        />
        <div className="news-input-container">
          <div className="toolbar-wrapper">
            <Toolbar editor={editorRef?.current} aiStyle="news" items={[ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.AI]} />
          </div>
          <div className="editor-wrapper">
            <EmailEditor
              editorRef={editorRef}
              onEditorReady={() => {
                setToggleState(!toggleState);
              }}
              content={content}
              inputContentChanged={inputContentChanged}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="news-editor-container">
      <div className="news-editor-input-container">{_buildNewsEditor()}</div>
      <div className="news-preview-wrapper">{_buildSidebar()}</div>
    </div>
  );
};

export default inject('projectStore')(observer(NewsConfiguration));
