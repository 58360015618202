/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import { Extension } from '@tiptap/core';
import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, ReactRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Iframe from 'services/Iframe';
import tippy from 'tippy.js';
import MentionList from '../../Mentions/MentionsList';
import Toolbar from '../ToolBar/Toolbar';
import { CustomLink } from './extensions/CustomLink';
import { HelpCenterArticleExtension } from './extensions/HelpcenterExtension';
import './RichTextEditor.scss';

const overrideEventDefaults = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

interface RichTextEditorProps {
  mentions?: {
    id: string;
    label: string;
    email?: string;
    profileImageUrl?: string;
  }[];
  showToolbar?: boolean;
  content?: any;
  inputContentChanged?: any;
  emptyStateChanged?: any;
  placeholder?: string;
  editable?: boolean;
  sendMessage?: () => void;
  onFilesSelected?: (any) => void;
}

export interface RichTextEditorRefProps {
  getJSON(): any;
  clearContent(): any;
  insertText(text: string): any;
  insertContent(content: any): any;
}

export const KeyboardSendExtension = (callback) => {
  return Extension.create({
    name: 'keyboardExtension',

    addKeyboardShortcuts: () => {
      return {
        'Mod-Enter': callback as any,
      };
    },
  });
};

const RichTextEditor = forwardRef<RichTextEditorRefProps, RichTextEditorProps>(
  (
    {
      mentions = [],
      content,
      inputContentChanged,
      emptyStateChanged,
      placeholder,
      editable = true,
      showToolbar = true,
      sendMessage,
      onFilesSelected,
    }: RichTextEditorProps,
    ref,
  ) => {
    const [savedMentions, setSavedMentions] = useState(mentions);

    const editor = useEditor(
      {
        editable,
        extensions: [
          StarterKit.configure({ codeBlock: {}, heading: false }),
          Heading.configure({
            levels: [1, 2, 3],
          }),
          KeyboardSendExtension(() => {
            if (sendMessage) {
              sendMessage();
            }
          }),
          CustomLink.configure({
            openOnClick: false,
          }),
          Placeholder.configure({
            placeholder,
          }),
          HelpCenterArticleExtension,
          Iframe,
          TextStyle,
          Image,
          Mention.configure({
            HTMLAttributes: {
              class: 'mention',
            },
            renderLabel({ options, node }) {
              return `${options.suggestion.char}${
                node.attrs.label ?? node.attrs.id
              }`;
            },
            suggestion: {
              items: ({ query }) => {
                return savedMentions
                  .filter((item) =>
                    item.label.toLowerCase().startsWith(query.toLowerCase()),
                  )
                  .slice(0, 5);
              },

              render: () => {
                let component;
                let popup;

                return {
                  onStart: (props) => {
                    component = new ReactRenderer(MentionList, {
                      props,
                      editor: props.editor,
                    });

                    popup = tippy(
                      (window as any).document.querySelector('body'),
                      {
                        getReferenceClientRect: props.clientRect as any,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                      },
                    );
                  },

                  onUpdate(props) {
                    component.updateProps(props);

                    if (popup && popup.length > 0) {
                      popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                      });
                    }
                  },

                  onKeyDown(props) {
                    if (props.event.key === 'Escape') {
                      if (popup && popup.length > 0) {
                        popup[0].hide();
                      }

                      return true;
                    }

                    return component.ref?.onKeyDown(props);
                  },

                  onExit() {
                    if (popup && popup.length > 0) {
                      popup[0].destroy();
                    }
                    if (component) {
                      component.destroy();
                    }
                  },
                };
              },
            },
          }),
        ],
        content,
        onUpdate({ editor }) {
          if (emptyStateChanged) {
            const isEmpty = editor?.getText().replace(/\s/g, '').length === 0;
            emptyStateChanged(editor?.isEmpty || isEmpty);
          }
          inputContentChanged(editor?.getJSON());
        },
      },
      [savedMentions, placeholder],
    );

    useEffect(() => {
      // Otherwise it ends up in endless loop
      if (mentions.length !== savedMentions.length) {
        setSavedMentions(mentions);
      }
    }, [mentions]);

    useImperativeHandle(ref, () => ({
      getJSON: () => {
        return editor!.getJSON();
      },
      clearContent: () => {
        editor!.commands.clearContent();
      },
      insertText: (text) => {
        editor!.commands.insertContent(text);
      },
      insertContent: (content) => {
        editor!.commands.insertContent(content);
      },
      updateContent: (content) => {
        // Preserv the cursor position.
        if (editor) {
          const oldCursorPosition = editor.state.selection.$anchor.pos;
          editor.commands.setContent(content);
          editor.chain().focus().setTextSelection(oldCursorPosition).run();
          editor.commands.scrollIntoView();
        }
      },
    }));

    return (
      <>
        {editor && showToolbar && editable && (
          <Toolbar floating editor={editor} aiStyle="agent" />
        )}
        <EditorContent
          editor={editor}
          onDrop={(e) => {
            overrideEventDefaults(e);
            if (!onFilesSelected) return;
            if (!e.dataTransfer) return;

            let files = Array.from(e.dataTransfer.files);

            onFilesSelected(files);
          }}
          onPasteCapture={(e) => {
            if (!onFilesSelected) return;
            if (!e.clipboardData.files) return;

            const files = e.clipboardData.files;

            onFilesSelected(files);
          }}
        />
      </>
    );
  },
);

export default RichTextEditor;
