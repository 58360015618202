import './MainRouting.scss';
import { Routes, Route } from 'react-router-dom';
import Profile from 'pages/private/Profile/Profile';
import Organisation from 'pages/private/Organisation/Organisation';
import CreateProjectPage from 'pages/private/CreateProjectPage/CreateProjectPage';
import ProjectSettings from 'pages/private/ProjectSettings/ProjectSettings';
import ProjectOverview from 'pages/private/ProjectIntegrations/IntegationsOverview/IntegrationsOverview';
import Dashboard from 'pages/private/Dashboard/Dashboard';
import VerifyCode from 'pages/private/tutorial/VerifyCode/VerifyCode';
import ProjectWidget from 'pages/private/ProjectWidget/ProjectWidget';
import ConfigureIntegration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigureIntegration';
import ChooseIntegration from 'pages/private/ProjectIntegrations/ChooseIntegration/ChooseIntegration';
import IntegrationCallback from 'pages/private/ProjectIntegrations/IntegrationCallback/IntegrationCallback';
import ZendeskConnectConfiguration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigurationComponents/ZendeskConfiguration/ZendeskConnectConfiguration';
import ProjectContacts from 'pages/private/ProjectContacts/ProjectContacts';
import ProjectContactDetails from 'pages/private/ProjectContactDetails/ProjectContactDetails';
import AworkConnectConfiguration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigurationComponents/AworkConfiguration/AworkConnectConfiguration';
import ProjectOutboundOverview from 'pages/private/ProjectOutbounds/ProjectOutboundsOverview/ProjectOutboundOverview';
import ProjectOutboundConfiguration from 'pages/private/ProjectOutbounds/ProjectOutboundConfiguration/ProjectOutboundConfiguration';
import ProjectOutboundDetails from 'pages/private/ProjectOutbounds/ProjectOutboundDetails/ProjectOutboundDetails';
import ShortcutConnectionConfiguration from 'pages/private/ProjectIntegrations/ConfigureIntegration/ConfigurationComponents/ShortcutConfiguration/ShortcutConnectionConfiguration';
import ProjectOutboundLoading from 'pages/private/ProjectOutbounds/ProjectOutboundLoading/ProjectOutboundLoading';
import ForgetPassword from 'pages/public/ForgetPasword/ForgetPassword';
import ResetPassword from 'pages/public/ResetPassword/ResetPassword';
import SharedBugReport from 'pages/public/SharedBugReport/SharedBugReport';
import Register from '../pages/public/register/Register';
import Login from '../pages/public/login/Login';
import PublicRoute from './PublicRoute';
import MainProjectRedirect from './MainProjectRedirect';
import SharedFeatureRequests from 'pages/public/SharedFeatureRequests/SharedFeatureRequests';
import AppSumoRegister from 'pages/public/AppSumoRegister/AppSumoRegister';
import ProjectInbound from 'pages/private/ProjectInbound/ProjectInbound';
import Helpcenter from 'pages/private/ProjectHelpcenter/Helpcenter/Helpcenter';
import CreateOrganisation from 'pages/private/CreateOrganisation/CreateOrganisation';
import Unsubscribe from 'pages/public/Unsubscribe/Unsubscribe';
import ProjectInboundFeatureRequests from 'pages/private/ProjectInboundFeatureRequests/ProjectInboundFeatureRequests';
import Bots from 'pages/private/Bots/Bots';
import ProjectInbox from 'pages/private/ProjectInbox/ProjectInbox';

const ProjectRoute = () => {
  return (
    <Routes>
      <Route path="/:projectId/bots" element={<Bots />} />
      <Route path="/:projectId/bots/*" element={<Bots />} />
      <Route
        path="/:projectId/outbound/:outboundId/edit"
        element={<ProjectOutboundConfiguration />}
      />
      <Route
        path="/:projectId/outbound"
        element={<ProjectOutboundOverview />}
      />
      <Route
        path="/:projectId/:outboundId/loading"
        element={<ProjectOutboundLoading />}
      />
      <Route
        path="/:projectId/outbound/:outboundId"
        element={<ProjectOutboundDetails />}
      />
      <Route
        path="/:projectId/outbound/:outboundId/:shareToken"
        element={<ProjectOutboundDetails />}
      />
      <Route path="/:projectId/widget/*" element={<ProjectWidget />} />
      <Route path="/:projectId/sessions" element={<ProjectContacts />} />
      <Route
        path="/:projectId/sessions/:contactId"
        element={<ProjectContactDetails />}
      />
      <Route
        path="/:projectId/sessions/:contactId/:shareToken"
        element={<ProjectContactDetails />}
      />
      <Route path="/:projectId/integrations" element={<ProjectOverview />} />
      <Route
        path="/:projectId/integrations/awork"
        element={<AworkConnectConfiguration />}
      />
      <Route
        path="/:projectId/integrations/shortcut"
        element={<ShortcutConnectionConfiguration />}
      />
      <Route
        path="/:projectId/integrations/connect"
        element={<ChooseIntegration />}
      />
      <Route
        path="/:projectId/integrations/trello"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/webhook"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/email"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/slack"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/clickup"
        element={<IntegrationCallback />}
      />
      <Route
        path="/:projectId/integrations/zendesk"
        element={<ZendeskConnectConfiguration />}
      />
      <Route
        path="/:projectId/integrations/:integration/:integrationID"
        element={<ConfigureIntegration />}
      />
      <Route path="/:projectId/settings/*" element={<ProjectSettings />} />
      <Route path="/:projectId/helpcenter/*" element={<Helpcenter />} />
      <Route
        path="/:projectId/featurerequests/*"
        element={<ProjectInboundFeatureRequests />}
      />
      <Route path="/:projectId/inquiries" element={<ProjectInbox />} />
      <Route
        path="/:projectId/inquiries/:shareToken"
        element={<ProjectInbox />}
      />
      <Route path="/:projectId/*" element={<ProjectInbound />} />
      <Route path="/:projectId" element={<MainProjectRedirect />} />
    </Routes>
  );
};

export const PrivateRouting = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route path="/register" element={<Register />} />
      <Route path="/registerappsumo" element={<AppSumoRegister />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/resetpassword/:token" element={<ResetPassword />} />
      <Route path="/share/:shareToken" element={<SharedBugReport />} />
      <Route path="/unsubscribe" element={<Unsubscribe />} />
      <Route
        path="/sharedboard/:boardShareToken"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/news"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/news/:newsId"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/roadmap"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/roadmap/:featureRequestShareToken"
        element={<SharedFeatureRequests />}
      />
      <Route
        path="/sharedboard/:boardShareToken/featurerequests/:featureRequestShareToken"
        element={<SharedFeatureRequests />}
      />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/organization/create" element={<CreateOrganisation />} />
      <Route
        path="/organization/:organizationId/*"
        element={<Organisation />}
      />
      <Route path="/organization/*" element={<Organisation />} />
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/integrations/webhook" element={<IntegrationCallback />} />
      <Route path="/integrations/email" element={<IntegrationCallback />} />
      <Route path="/integrations/azure" element={<IntegrationCallback />} />
      <Route
        path="/integrations/azureDevops"
        element={<IntegrationCallback />}
      />
      <Route path="/integrations/awork" element={<IntegrationCallback />} />
      <Route path="/integrations/jira" element={<IntegrationCallback />} />
      <Route path="/integrations/github" element={<IntegrationCallback />} />
      <Route path="/integrations/gitlab" element={<IntegrationCallback />} />
      <Route path="/integrations/asana" element={<IntegrationCallback />} />
      <Route path="/integrations/azure" element={<IntegrationCallback />} />
      <Route path="/integrations/hubspot" element={<IntegrationCallback />} />
      <Route path="/integrations/helpscout" element={<IntegrationCallback />} />
      <Route path="/integrations/notion" element={<IntegrationCallback />} />
      <Route path="/integrations/zapier" element={<IntegrationCallback />} />
      <Route path="/integrations/zendesk" element={<IntegrationCallback />} />
      <Route path="/integrations/plutio" element={<IntegrationCallback />} />
      <Route path="/integrations/linear" element={<IntegrationCallback />} />
      <Route path="/projects/create" element={<CreateProjectPage />} />
      <Route path="/projects/*" element={<ProjectRoute />} />
    </Routes>
  );
};

export default PrivateRouting;
