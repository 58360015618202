import Heading from '@tiptap/extension-heading';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Youtube from '@tiptap/extension-youtube';
import { generateText } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { CustomLink } from 'components/Editors/RichTextEditor/extensions/CustomLink';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { copyToClipboard } from 'helper/CopyToClipboard';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { performAIAction } from 'services/BugHttpService';
import { isMacintosh, useClickedOutside } from 'services/Helper';
import Iframe from 'services/Iframe';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ReactComponent as AIIcon } from 'assets/icons/ai.svg';
import './PerformAIAction.scss';

const generateTextFromContent = (content) => {
  try {
    const text = generateText(content, [
      StarterKit.configure({ codeBlock: {}, heading: false }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      CustomLink,
      Mention,
      Placeholder,
      TextStyle,
      Youtube.configure({ controls: true }),
      Iframe,
    ]);
    return text;
  } catch (exp) {}
  return "";
}

const PerformAIAction = ({
  onTextPaste,
  onTextReplace,
  bugStore,
  organisationStore,
  modalStore,
  currentText,
}: {
  onTextPaste: (text: string) => void;
  onTextReplace: (text: string) => void;
  bugStore?: BugStore;
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
  currentText?: string;
}) => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null as any);
  const [plainText, setPlainText] = useState("");
  const plainTextRef = useRef("");
  plainTextRef.current = plainText;
  const [currentlyFocused, setCurrentlyFocused] = useState(0);
  const currentlyFocusedRef = useRef(0);
  currentlyFocusedRef.current = currentlyFocused;
  const [aiActionsList, setShowAIActionsList] = useState(false);
  const [showPlanIssue, setShowPlanIssue] = useState(false);
  const [processingAction, setProcessingAction] = useState(null as any);
  const showAIListRef = useRef(aiActionsList);
  showAIListRef.current = aiActionsList;
  const aiActionList = [{
    title: "Summarize conversation",
    type: "summarizeconversation",
    description: "Summarize the conversation to the most important bullet points.",
    needsText: false,
  }, {
    title: "Generate commit message",
    type: "commitmessage",
    description: "Generate a git-commit message based on the current ticket.",
    needsText: false,
  }, {
    title: "Rephrase text",
    type: "rephrase",
    description: "Rephrase the text to make it more concise.",
    needsText: true,
  }, {
    title: "Expand text",
    type: "expand",
    description: "Expand the text to make it more detailed. Simply outline your main points and let the AI fill in the details.",
    needsText: true,
  }, {
    title: "Make it sound more professional",
    type: "formal",
    description: "Make the text sound more professional.",
    needsText: true,
  }, {
    title: "Make it sound more fun",
    type: "energize",
    description: "Make the text sound more fun and energizing.",
    needsText: true,
  }];
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    if (processingAction) {
      return;
    }
    setShowAIActionsList(false);
  });

  useEffect(() => {
    if (aiActionList) {
      setShowPlanIssue(false);
    }
  }, [aiActionsList])

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      const children = scrollContainerRef.current.children;
      if (children && children[currentlyFocused]) {
        children[currentlyFocused].scrollIntoView({ block: 'nearest' });
      }
    }
  }, [currentlyFocused]);

  useEffect(() => {
    if (aiActionsList) {
      try {
        if (document.activeElement && document.activeElement !== document.body) {
          (document.activeElement as HTMLElement).blur();
        };
      } catch (exp) { }
    }
  }, [aiActionsList]);

  useEffect(() => {
    if (aiActionsList) {
      const plainText = generateTextFromContent(currentText);
      setPlainText(plainText);
    }
  }, [currentText, aiActionsList]);

  const canFocusAtIndex = (index) => {
    const macro = aiActionList[index];
    return (!macro.needsText || (plainTextRef.current && plainTextRef.current.length > 0));
  }

  const performAction = (action: any) => {
    if (processingAction) {
      return;
    }

    const bugId = bugStore?.currentBug?.id;
    if (!bugId) {
      setShowAIActionsList(false);
      return;
    }

    setProcessingAction(action);

    performAIAction(bugId, action, plainTextRef.current).then((response) => {
      if (response.status === 200 && response.data && response.data.text) {
        const text = response.data.text.replace(/(?:\r\n|\r|\n)/g, '<br>');

        if (action === "summarizeconversation" || action === "commitmessage") {
          onTextPaste(text);
        } else {
          onTextReplace(text);
        }

        // Copy to clipboard.
        if (action === "commitmessage") {
          copyToClipboard(response.data.text);
          toast.success('Copied commit message to clipboard ✅');
        }

        setShowAIActionsList(false);
        setProcessingAction(null);
      }
    }).catch(() => {
      setShowPlanIssue(true);
      setProcessingAction(null);
    });
  };

  const handleUserKeyPress = useCallback((event: any) => {
    const { key } = event;

    if ((event.metaKey || event.ctrlKey) && key === 'd') {
      setShowAIActionsList(!showAIListRef.current);
      event.preventDefault();
      return;
    }

    // Don't perform other key bindings if it's not open.
    if (!showAIListRef.current) {
      return;
    }

    if (event.key === "Escape") {
      setShowAIActionsList(false);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 13) {
      if (showAIListRef.current) {
        performAction(aiActionList[currentlyFocusedRef.current].type);
        event.preventDefault();
        return;
      }
    }

    if (event.keyCode === 40) {
      var newIndex = (currentlyFocusedRef.current + 1);
      if ((newIndex > aiActionList.length - 1) || !canFocusAtIndex(newIndex)) {
        newIndex = 0;
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }

    if (event.keyCode === 38) {
      var newIndex = (currentlyFocusedRef.current - 1);
      if (newIndex < 0) {
        newIndex = aiActionList.length - 1;
        while (!canFocusAtIndex(newIndex)) {
          newIndex--;
        }
      }

      setCurrentlyFocused(newIndex);
      event.preventDefault();
      return;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const renderList = () => {
    if (processingAction) {
      return (<div className='ai-list-loading'>
        <LoadingAnimation />
      </div>);
    }

    if (showPlanIssue) {
      return (<div className='ai-list-planerror'>
        <div className='planerror-title'>New plan needed 🪄</div>
        <div className='planerror-description'>AI assist is not supported in your legacy plan. Move to our new plans to unlock AI assist.</div>
        <PrimaryButton onClick={() => {
          navigate(
            `/organization/${organisationStore?.currentOrganisation?.id}/billing`,
          );
          modalStore?.closeModal();
        }} label='Update plan' />
      </div>);
    }

    return (<>
      <div className='ai-list-list' ref={scrollContainerRef}>
        {aiActionList.map((macro, index) => {
          const canBeFocused = canFocusAtIndex(index);
          return (
            <div
              className={`ai-list-item ${(canBeFocused && index === currentlyFocused) && 'focused'} ${!canBeFocused && 'ai-list-item--disabled'}`}
              key={index}
              onMouseEnter={() => {
                if (canBeFocused) {
                  setCurrentlyFocused(index);
                }
              }}
              onClick={() => {
                performAction(macro.type);
              }}
            >
              <i className="fa-solid fa-circle-question" data-for="mauLimitTooltip" data-tip={JSON.stringify({
                title: macro.title,
                description: macro.description,
              })} />
              <div className='ai-list-item-title'>{macro.title}</div>
              {!canBeFocused && <div className='ai-list-item-textneeded'>Write text in editor first</div>}
            </div>
          );
        })}
      </div>
      <div className='ai-list-footer'>
        <div className="hotkeys-list">
          <div className='hotkey-key'>
            <i className="fa-regular fa-arrow-up"></i>
          </div>
          <div className='hotkey-key'>
            <i className="fa-regular fa-arrow-down"></i>
          </div>
          <div className='hotkeys-list-label'>to navigate</div>
        </div>
        <div className="hotkeys-list">
          <div className='hotkey-key'>
            <i className="fa-regular fa-turn-down-left"></i>
          </div>
          <div className='hotkeys-list-label'>to select</div>
        </div>
        <div className="hotkeys-list">
          <div className='hotkey-key'>
            ESC
          </div>
          <div className='hotkeys-list-label'>to close</div>
        </div>
      </div>
    </>);
  }

  return (
    <div className="ai-container" ref={wrapperRef}>
      {aiActionsList && (
        <div className="ai-list">
          <ReactTooltip
            id="mauLimitTooltip"
            className="infoTooltip"
            delayHide={300}
            type="light"
            effect="solid"
            getContent={(content) => {
              try {
                const data = JSON.parse(content);
                return (
                  <div className='ai-info-tooltip'><b>{data.title}</b><br />{data.description}</div>
                );
              } catch (exp) { }
              return null;
            }}
          />
          <div className='ai-list-title'>
            What do you need help with?
            <div className='ai-list-title-tag'>AI assist</div>
          </div>
          {renderList()}
        </div>
      )}
      {!aiActionsList && <ReactTooltip
        id="templatesButtonTooltip"
        className="infoTooltip infoTooltipButton"
        delayHide={0}
        type="light"
        effect="solid"
        getContent={(content) => {
          return (
            <div className="send-key-tooltip">
              <span>{content}</span>
              <div className="hotkey-help">
                {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                <div>D</div>
              </div>
            </div>
          );
        }}
      />}
      <div
        data-for="templatesButtonTooltip"
        data-tip="AI assist"
        className={`ai-container-item ${aiActionsList && 'ai-container-item--active'}`}
        onClick={() => {
          if (processingAction) {
            return;
          }
          setShowAIActionsList(!aiActionsList);
        }}
      >
        <AIIcon />
      </div>
    </div>
  );
};

export default inject('bugStore', 'organisationStore')(observer(PerformAIAction));
