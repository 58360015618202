import classNames from 'classnames';
import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import TextInput from 'components/TextInput/TextInput';
import { useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';
import './SelectPageUrl.scss';

export const SelectPageUrl = ({ className, value, onChange }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  useEscape(() => {
    setIsOpen(false);
  });
  const cardClassName = classNames(
    {
      'select-page-url': true,
    },
    className,
  );

  const renderDelayText = () => {
    if (!value || value.length === 0) {
      return (<>+ Add page rule</>);
    }

    return (<>Current page contains <b>{truncateString(value, 10)}</b></>);
  }

  return (
    <div
      className={cardClassName}
      ref={wrapperRef}>
      <div
        className={`select-page-url--inner ${value && value.length > 0 && 'select-page-url--inner--valueadded'}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {renderDelayText()}
      </div>
      <div className={`select-page-url-overlay ${isOpen && 'select-page-url-overlay--open'}`}>
        <div className="input-label input-label--small">
          Trigger only on a specific page
        </div>
        <div className="text mt-10">
          Send this survey only when the page name or URL contains the
          following text.
        </div>
        <div className="audience-page-filter mt-10">
          <TextInput
            name="highest-value"
            initalValue={value}
            placeholder="i.e. /onboarding"
            type="text"
            error=""
            onChange={(val) => {
              if (onChange) {
                onChange(val);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
