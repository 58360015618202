import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
  getAccessibleOrganisations,
  getBoards,
} from 'services/integrations/JiraHttpService';

export class JiraIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  boards: any = [];
  accessibleOrganisations: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setAccessibleOrganisations = (accessibleOrganisations) => {
    this.accessibleOrganisations = accessibleOrganisations;
  };

  getAccessibleOrganisations = async () => {
    try {
      const response = await getAccessibleOrganisations({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });

      if (response.status === 200) {
        this.setAccessibleOrganisations(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setBoards = (boards) => {
    this.boards = boards;
  };

  getBoards = async ({ organisationID }) => {
    if (!organisationID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getBoards({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        organisationID,
      });
      if (response.status === 200) {
        this.setBoards(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
