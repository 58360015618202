import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AssignUserActionEditor.scss';

interface AssignUserActionEditorProps {
  action: any;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const AssignUserActionEditor = ({
  action,
  modalStore,
  projectStore,
}: AssignUserActionEditorProps) => {
  return (
    <div className="edit-feature-request-modal">
      <PageContainer>
        <PageHeadLine title="Assign user">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="tagcontainer">
            <div className="input-label">
              Choose a user to assign the conversation to
            </div>
            <FeedbackUserFilter
              value={action.processingUser ?? null}
              truncatePreview={50}
              truncate={50}
              hasBorder
              placeholder="Select user"
              onValueChanged={(value) => {
                runInAction(() => {
                  action.processingUser = value;
                });
              }}
            />
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(AssignUserActionEditor));
