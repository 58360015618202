/* eslint-disable prefer-destructuring */
import BoardFilter from 'components/BoardFilter/BoardFilter';
import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import BoardSortFilter from 'components/BoardSortFilter/BoardSortFilter';
import BugBoard from 'components/BugBoard/BugBoard';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectFeedbackBoard.scss';
import { BugStore } from 'stores/private/BugStore';

interface ProjectFeedbackBoardProps {
  projectStore?: ProjectStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const ProjectFeedbackBoard = ({
  projectStore,
  modalStore,
  bugStore,
}: ProjectFeedbackBoardProps) => {
  const { shareToken } = useParams();

  useEffect(() => {
    if (shareToken) {
      projectStore!.openFeedbackItem({ shareToken });
    } else {
      bugStore!.clearCurrentBug();
    }
  }, []);

  const render = () => {
    return (
      <>
        <PageContainer>
          <PageHeadLine
            title={projectStore?.currentFeedbackType?.menuName ?? 'Feedback'}
          >
            <div className="filter-bar">
              <BoardSortFilter
                filterOptions={[
                  {
                    title: 'Sort manually',
                    key: 'lexorank',
                  },
                  {
                    title: 'By date',
                    key: 'createdAt',
                  },
                  {
                    title: 'By due date',
                    key: 'dueDate',
                  },
                  {
                    title: 'By read status',
                    key: 'notificationsUnread',
                  },
                  {
                    title: 'By priority',
                    key: 'priority',
                  },
                ]}
              />
              <BoardFilter />
              <BoardSearchFilter />
              {projectStore?.isProjectAdmin && (
                <>
                  <div className="button-seperator hide-on-mobile"></div>
                  <LinkButton
                    className="hide-on-mobile"
                    iconSideRight={false}
                    icon="gear"
                    small
                    onClick={() => {
                      modalStore!.openModal(MODALTYPE.FEEDBACK_TYPE, {
                        feedbackType: projectStore?.currentFeedbackType?.type,
                      });
                    }}
                  />
                </>
              )}
            </div>
          </PageHeadLine>
          <PageContent hasTitle hasPadding={false}>
            <div className="board-container">
              <BugBoard />
            </div>
          </PageContent>
        </PageContainer>
        {!projectStore?.currentProject?.sdkInstalled && (
          <div className="project-not-installed">
            <NotInstalledCard title="Get started with customer feedback 💬" description='Install the Gleap SDK to start receiving customer feedback.' />
          </div>
        )}
      </>
    );
  };

  if (!projectStore!.currentProject) {
    return (
      <div className="full-loader">
        <Loading />
      </div>
    );
  }

  return <div className="project-dashboard">{render()}</div>;
};

export default inject(
  'projectStore',
  'modalStore',
  'bugStore',
)(observer(ProjectFeedbackBoard));
