import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { ProjectStore } from 'stores/private/ProjectStore';
import './InboxTicketStatusSelection.scss';

interface BugAssignUserProps {
  items?: any[];
  selected?: any;
  onChange?: (value: any) => void;
  projectStore?: ProjectStore;
}

const InboxTicketStatusSelection = ({ items, selected, onChange, projectStore }: BugAssignUserProps) => {
  const currentTicketsData = projectStore?.currentTicketsData;

  return (
    <>
      <Select
        className="inbox-ticket-status-dropdown dropdown-selection dropdown-selection--noborder"
        classNamePrefix="dropdown-selection"
        value={selected}
        placeholder="Status"
        isClearable={false}
        isSearchable={false}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.value}
        components={{
          SingleValue: (option: any) => {
            return (
              <div className="inbox-ticket-status-item">
                <span className='status-title'>{option.data.name}</span>
                <span className='status-count'>{currentTicketsData[option.data.value]?.count ?? 0}</span>
              </div>
            );
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item inbox-ticket-status-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.value);
                  }
                }}
              >
                <span className='status-title'>{option.data.name}</span>
                <span className='status-count'>{currentTicketsData[option.data.value]?.count ?? 0}</span>
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected && onChange) {
            onChange(selected);
          }
        }}
        options={items}
      />
    </>
  );
};

export default inject(
  'projectStore',
)(observer(InboxTicketStatusSelection));
