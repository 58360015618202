import { Title } from 'components/Title/Title';
import React from 'react';

const MdxHeadLine = (props: any) => {
  return (
    <Title
      // eslint-disable-next-line react/destructuring-assignment
      label={props.children}
    />
  );
};

export default MdxHeadLine;
