import axios from '../Axios';

const setShortcutAuthToken = (projectID, code) => {
  return axios.post(`/projects/${projectID}/integrations/shortcut`, {
    code,
  });
};

const setShortcutActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/shortcut/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectShortcutIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/shortcut/${integrationID}`,
  );
};

const getProjects = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/shortcut/${integrationID}/projects`,
  );
};

const getEpics = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/shortcut/${integrationID}/epics`,
  );
};

const getWorkflows = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/shortcut/${integrationID}/workflows`,
  );
};

export {
  setShortcutAuthToken,
  setShortcutActions,
  disconnectShortcutIntegration,
  getProjects,
  getEpics,
  getWorkflows,
};
