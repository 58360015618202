/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useMemo } from 'react';
import './UserTable.scss';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { inject, observer } from 'mobx-react';
import { ROLES } from 'models/Organisation';
import { ProjectStore } from 'stores/private/ProjectStore';
import ListTable, {
  CellDropdown,
  CellIconAction,
  CellLink,
  CellText,
  CellTextIcon,
  CellUser,
} from 'components/ListTable/ListTable';
import { capitalizeFirstLetter } from 'services/Helper';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import adminIcon from '../../assets/icons/admin_icon.svg';
import userIcon from '../../assets/icons/profile.svg';
import { ampli } from 'ampli';

interface TableProps {
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
  isProjectLevel?: boolean;
}

const UserTable = ({
  organisationStore,
  projectStore,
  isProjectLevel = false,
}: TableProps) => {
  const currentOrganisationUsers: any = !isProjectLevel
    ? organisationStore?.currentOrganisationUsers
    : projectStore?.currentProjectUsers;
  const isAdmin = isProjectLevel
    ? projectStore?.isProjectAdmin
    : organisationStore?.isOrganisationAdmin;

  const menuItems = [
    {
      id: 1,
      icon: adminIcon,
      role: ROLES.ADMIN,
      name: 'Admin',
    },
    {
      id: 2,
      icon: userIcon,
      role: ROLES.USER,
      name: 'User',
    },
  ];

  const updateRole = async (itemID, userId) => {
    const choosenItem = menuItems.find((item) => item.id === itemID);
    if (choosenItem?.id) {
      if (isProjectLevel) {
        await projectStore!.setUserRole(userId, choosenItem.role);
      } else {
        await organisationStore!.setUserRole(userId, choosenItem.role);
      }
    }
  };

  const removeUser = async (userId) => {
    if (isProjectLevel) {
      await projectStore!.removeUser(userId);
      ampli.peopleRemoved({
        level: 'project',
      });
    } else {
      await organisationStore!.removeUser(userId);
      ampli.peopleRemoved({
        level: 'organization',
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => row,
        Cell: (row) => <CellUser user={row.value} />,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (row) => (
          <CellLink link={`mailto:${row.value}`} text={row.value} />
        ),
      },
      {
        Header: 'Role',
        accessor: (row) => ({
          role: row.role,
          type: row.type,
          id: row.id,
        }),
        Cell: (row) => {
          if (!isAdmin) {
            return <CellTextIcon icon={row.value.role === 'ADMIN' ? 'user-shield' : 'user'} text={capitalizeFirstLetter(row.value.role)} />;
          }

          if (isProjectLevel && row.value.type === 'organisation') {
            return (
              <CellTextIcon
                className="organization-role" icon={row.value.role === 'ADMIN' ? 'user-shield' : 'user'} text={capitalizeFirstLetter(row.value.role)} />
            );
          }

          return (
            <div onClick={(e) => e.stopPropagation()}>
              <CellDropdown
                menuItems={menuItems}
                label={capitalizeFirstLetter(row.value.role)}
                onUpdate={(item) => {
                  updateRole(item, row.value.id);
                }}
              />
            </div>
          );
        },
      },
      {
        Header: 'Level',
        className: !isProjectLevel ? 'hide-column' : '',
        width: '100px',
        accessor: (row) => ({
          role: row.role,
          type: row.type,
          id: row.id,
        }),
        Cell: (row) => {
          if (!isProjectLevel) {
            return <></>;
          }

          return (
            <CellTextIcon
              className="organization-role" icon={row.value.type === 'organisation' ? 'sitemap' : 'square-kanban'} text={capitalizeFirstLetter(row.value.type)} />
          );
        },
      },
      {
        id: 'remove',
        width: '50px',
        Header: 'Action',
        accessor: (row) => ({
          role: row.role,
          type: row.type,
          id: row.id,
        }),
        Cell: (row) => {
          if (!isAdmin) {
            return <CellText text="" />;
          }

          if (isProjectLevel && row.value.type === 'organisation') {
            return (
              <CellLink
                text="Manage in organisation"
                link={`/organization/${organisationStore!.currentOrganisation?.id
                  }/team`}
              />
            );
          }

          return (
            <CellIconAction
              Icon={DeleteIcon}
              action={() => {
                removeUser(row.value.id);
              }}
            />
          );
        },
      },
    ],
    [isAdmin],
  );

  useEffect(() => {
    if (!isProjectLevel) {
      organisationStore!.getOrgaUsers();
    }
  }, [isProjectLevel]);

  if (!currentOrganisationUsers) {
    return <></>;
  }

  if (!currentOrganisationUsers || currentOrganisationUsers.length === 0) {
    return <></>;
  }

  return (
    <>
      <ListTable data={currentOrganisationUsers} columns={columns} />
    </>
  );
};

export default inject('organisationStore', 'projectStore')(observer(UserTable));
