import TextInput from 'components/TextInput/TextInput';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';

interface EmailConfigurationProps {
  integrationID: string;
  index: number;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const EmailConfiguration = ({
  integrationID,
  index,
  projectStore,
  onChange,
}: EmailConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [email, setEmail] = useState('');

  useEffect(() => {
    if (project) {
      initialize();
    }
  }, [project]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [email]);

  const initialize = async () => {
    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.EMAIL,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.email[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      email,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <TextInput
        error=""
        className="mb-15"
        placeholder="hello@gleap.io"
        label="Email"
        value={currentIntegrationInfo.callbackUrl}
        onChange={(val) => {
          setEmail(val);
        }}
      />
    </div>
  );
};

export default inject('projectStore')(observer(EmailConfiguration));
