import './OutboundMessagePreview.scss';
import { Outbound } from 'models/Outbound';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import EmailTemplate from 'components/EmailTemplate/EmailTemplate';
import RichTextEditor from 'components/Editors/RichTextEditor/RichTextEditor';
import WidgetButtonPreview from 'components/WidgetButtonPreview/WidgetButtonPreview';

interface OutboundMessagePreviewProps {
  outbound?: Outbound;
  projectStore?: ProjectStore;
}

export const OutboundMessagePreview = ({
  outbound,
  projectStore,
}: OutboundMessagePreviewProps) => {
  const senderId = outbound?.sender;
  const sender = projectStore?.currentProjectUsers.find(
    (o) => o.id === senderId,
  );
  const flowConfig = projectStore?.flowConfig;
  if (!flowConfig) {
    return <></>;
  }

  if (outbound?.type === 'EMAIL') {
    return (
      <div className="outbound-email-composer">
        <div className="widget-preview">
          <div className="widget-preview-browser">
            <div className="widget-preview-browser-head">
              <div className="widget-preview-browser-head-close" />
              <div className="widget-preview-browser-head-minimize" />
              <div className="widget-preview-browser-head-maximize" />
            </div>
            <div className="widget-preview-browser-body">
              <div className="widget-preview-browser-body-header">
                <span>RE:</span> {outbound.subject}
              </div>
              <EmailTemplate
                template={outbound.format ?? 'plain'}
                data={{
                  sender: outbound.sender,
                  projectName: projectStore.currentProject?.name,
                }}
              >
                <RichTextEditor editable={false} content={outbound.message} />
              </EmailTemplate>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="outbound-message-container">
      <div className="outbound-message-item">
        <UserAvatar
          small
          email={sender?.email}
          imageUrl={sender?.profileImageUrl}
        />
        <div className="outbound-message-item-container">
          <div className="outbound-message-item-sender">
            {sender?.firstName} {sender?.lastName}
          </div>
          <div className="outbound-message-item-content">
            {outbound?.message && outbound?.message.length > 0
              ? truncateString(outbound?.message, 70)
              : '--'}
          </div>
        </div>
      </div>
      <WidgetButtonPreview />
    </div>
  );
};

export default inject('projectStore')(observer(OutboundMessagePreview));
