import * as React from 'react';
import classNames from 'classnames';
import './TabButton.scss';

interface TabButtonProps {
  label: string;
  onClick: any;
  active?: boolean;
}

const TabButton = ({ label, onClick, active }: TabButtonProps) => {
  const TabButtonClassName = classNames({
    'tab-button': true,
    'tab-button--active': active,
  });

  return (
    <button type="button" className={TabButtonClassName} onClick={onClick}>
      {label}
    </button>
  );
};

export default TabButton;
