import RichTextEditor from 'components/Editors/RichTextEditor/RichTextEditor';
import FeatureRequestSubscribeItem from 'components/FeatureRequestSubscribeItem/FeatureRequestSubscribeItem';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { getFormFieldValue } from 'helper/FormDataHelper';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import './Details.scss';
import './FeatureRequestFormData.scss';

interface FeatureRequestFormDataProps {
  bugStore?: BugStore;
  modalStore?: ModalStore;
  shared?: boolean;
}

const FeatureRequestFormData = ({ bugStore, modalStore, shared }: FeatureRequestFormDataProps) => {
  const bug = bugStore!.currentBug;
  const [version, setVersion] = useState(0);

  useEffect(() => {
    setVersion(version + 1);
  }, [bug?.content]);

  if (!bug) {
    return <></>;
  }

  const title = getFormFieldValue(bug, 'title');

  const renderFeatureRequestStaticCommentContent = () => {
    if (!bug) {
      return null;
    }

    if (bug.content) {
      return (
        <div className="static-feature-request-body-content" key={`version-tag-${version}`}>
          <RichTextEditor editable={false} content={bug.content} />
        </div>
      );
    }

    return (
      <div className="static-feature-request-body-text">
        {getFormFieldValue(bug, 'description', 'formText')}
      </div>
    );
  }

  const renderFeatureRequestStaticComment = () => {
    if (!bug) {
      return null;
    }

    return (<div className='static-feature-request-container'>
      <div className="static-feature-request-head" onClick={() => { }}>
        <FeatureRequestSubscribeItem featureRequest={bug} />
        <div className="static-feature-request-header">
          <div className="static-feature-request-header-title">
            {title}
          </div>
          <div className="static-feature-request-header-date">
            {moment(bug.createdAt).format('MMMM Do, YYYY')}
          </div>
        </div>
      </div>
      <div className='static-feature-request-body'>
        {renderFeatureRequestStaticCommentContent()}
      </div>
      {!shared &&
        <div className="static-feature-request-edit">
          <PrimaryButton
            icon='edit'
            label="Edit"
            small
            onClick={() => {
              modalStore?.openModalSmart(MODALTYPE.EDIT_FEATURE_REQUEST);
            }}
          />
        </div>}
    </div>)
  };

  return renderFeatureRequestStaticComment();
};

export default inject('bugStore', 'modalStore')(observer(FeatureRequestFormData));
