import './MainNavBar.scss';
import { inject, observer } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';
import ProfileDropUp from 'components/ProfileDropUp/ProfileDropUp';
import { ReactComponent as GleapLogo } from 'assets/icons/GleapLogoBlack.svg';
import { useNavigate, useLocation } from 'react-router';
import ProjectDropdown from 'components/Sidenav/ProjectDropdown/ProjectDropdown';
import SingleSidenavElement from 'components/Sidenav/SidenavElement/SingleSidenavElement';
import { ProjectStore } from 'stores/private/ProjectStore';
import { ReactComponent as OutboundIcon } from 'assets/icons/paper-plane-regular.svg';
import { ReactComponent as OutboundIconActive } from 'assets/icons/paper-plane-solid.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/gear-regular.svg';
import { ReactComponent as SettingsIconActive } from 'assets/icons/settings.svg';
import { ReactComponent as InboxIcon } from 'assets/icons/inbox-regular_new.svg';
import { ReactComponent as InboxIconActive } from 'assets/icons/inbox-solid.svg';
import { ReactComponent as IntegrationsIconActive } from 'assets/icons/integrations-active.svg';
import { ReactComponent as IntegrationsIcon } from 'assets/icons/integrations.svg';
import { ReactComponent as KanbanIcon } from 'assets/icons/square-kanban-solid.svg';
import { ReactComponent as WidgetIconActive } from 'assets/icons/np_widget_411203_FFFFFF.svg';
import { ReactComponent as WidgetIcon } from 'assets/icons/widget-config.svg';
import { ReactComponent as ContactsIcon } from 'assets/icons/user-group-regular_new.svg';
import { ReactComponent as ContactsIconActive } from 'assets/icons/user-group-solid.svg';
import { ReactComponent as HelpCenterIcon } from 'assets/icons/circle-question-solid.svg';
import { ReactComponent as HelpCenterIconActive } from 'assets/icons/book-open-solid.svg';
import { ReactComponent as RoadmapIcon } from 'assets/icons/lightbulb-regular.svg';
import { ReactComponent as RoadmapIconActive } from 'assets/icons/lightbulb-solid.svg';
import { ReactComponent as BotIcon } from 'assets/icons/message-bot-light.svg';
import { ReactComponent as BotIconActive } from 'assets/icons/message-bot-solid.svg';
import { sum } from 'services/Helper';
import { OutboundStore } from 'stores/private/OutboundStore';

interface MainNavBarProps {
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
}

const MainNavBar = ({
  usersStore,
  projectStore,
  outboundStore,
}: MainNavBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userIsComplete =
    usersStore?.currentUser && usersStore.currentUser.confirmed;
  const project = projectStore?.currentProject;

  if (!userIsComplete) {
    return <></>;
  }

  const renderProjectLinks = () => {
    if (!project || !location.pathname.includes('/project')) {
      return <div className="growing" />;
    }

    const extraTypes =
      project.projectTypes
        .filter(function (projectType) {
          if (
            projectType.path === 'featurerequests' ||
            projectType.path === 'inquiries'
          ) {
            return false;
          }
          return true;
        })
        .map((projectType) => {
          return projectType.type;
        }) ?? [];

    const extraPaths =
      project.projectTypes
        .filter(function (projectType) {
          if (
            projectType.path === 'featurerequests' ||
            projectType.path === 'inquiries'
          ) {
            return false;
          }
          return true;
        })
        .map((projectType) => {
          return projectType.path;
        }) ?? [];

    const getCustomNotificationsCount = (key = 'FEATURE_REQUEST') => {
      var unreadCount = 0;

      for (const itemKey in projectStore.unreadItems) {
        if (
          projectStore.unreadItems[itemKey] &&
          projectStore.unreadItems[itemKey].type === key
        ) {
          unreadCount++;
          if (unreadCount > 9) {
            return 10;
          }
        }
      }

      return unreadCount;
    };

    const getNotificationCount = () => {
      var types: string[] = [];
      for (let i = 0; i < extraTypes.length; i++) {
        types.push(extraTypes[i]);
      }

      var unreadCount = 0;

      for (const itemKey in projectStore.unreadItems) {
        if (
          projectStore.unreadItems[itemKey] &&
          types.includes(projectStore.unreadItems[itemKey].type)
        ) {
          unreadCount++;
          if (unreadCount > 9) {
            return 10;
          }
        }
      }

      return unreadCount;
    };

    const getSurveysNotificationCount = () => {
      return sum(outboundStore?.currentProjectSurveyUnreadCount);
    };

    return (
      <>
        <div className="nav-links">
          <SingleSidenavElement
            name="Inbox"
            Icon={InboxIcon}
            IconActive={InboxIconActive}
            projectId={project.id}
            notificationCount={getCustomNotificationsCount('INQUIRY')}
            projectPath="inquiries"
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Feedback"
            Icon={KanbanIcon}
            IconActive={KanbanIcon}
            projectId={project.id}
            projectPath="bugs"
            extraProjectPaths={[
              ...extraPaths,
              'archive',
              'dashboard',
              'insights',
            ]}
            notificationCount={getNotificationCount()}
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Roadmap"
            Icon={RoadmapIcon}
            IconActive={RoadmapIconActive}
            projectId={project.id}
            notificationCount={getCustomNotificationsCount('FEATURE_REQUEST')}
            projectPath="featurerequests"
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Outbound"
            projectId={project.id}
            Icon={OutboundIcon}
            IconActive={OutboundIconActive}
            projectPath="outbound"
            notificationCount={getSurveysNotificationCount()}
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Help center"
            Icon={HelpCenterIcon}
            IconActive={HelpCenterIconActive}
            projectId={project.id}
            projectPath="helpcenter"
            className="sidenav-element--main"
          />
          <SingleSidenavElement
            name="Bots"
            Icon={BotIcon}
            IconActive={BotIconActive}
            projectId={project.id}
            projectPath="bots"
            className="sidenav-element--main sidenav-element--main--bot"
          />
          <SingleSidenavElement
            name="Contacts"
            Icon={ContactsIcon}
            IconActive={ContactsIconActive}
            projectId={project.id}
            projectPath="sessions"
            className="sidenav-element--main"
          />
          <div className="growing" />
          <SingleSidenavElement
            name="Widget configuration"
            Icon={WidgetIcon}
            IconActive={WidgetIconActive}
            projectId={project.id}
            projectPath="widget"
          />
          <SingleSidenavElement
            name="Integrations"
            Icon={IntegrationsIcon}
            IconActive={IntegrationsIconActive}
            projectId={project.id}
            projectPath="integrations"
          />
          <SingleSidenavElement
            name="Settings"
            Icon={SettingsIcon}
            IconActive={SettingsIconActive}
            projectId={project.id}
            projectPath="settings"
          />
        </div>
      </>
    );
  };

  const renderHeader = () => {
    if (true || !project || !location.pathname.includes('/project')) {
      if (project) {
        return (
          <div
            className="project-option"
            onClick={() => {
              navigate('/');
            }}
          >
            {!project.picture && (
              <div className="image-placeholder">
                {project.name && project.name.charAt(0).toLocaleUpperCase()}
              </div>
            )}
            {project.picture && <img src={project.picture} alt="projpicture" />}
          </div>
        );
      }

      return (
        <div className="logo">
          <GleapLogo
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="mainnavbar">
      <div className="mainnavbar--header">{renderHeader()}</div>
      {renderProjectLinks()}
      <ProfileDropUp />
    </div>
  );
};

export default inject(
  'usersStore',
  'projectStore',
  'outboundStore',
)(observer(MainNavBar));
