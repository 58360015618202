import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
  getBoardLists,
  getBoards,
} from 'services/integrations/TrelloHttpService';

export class TrelloIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  boards: any = [];
  boardLists: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setBoards = (boards) => {
    this.boards = boards;
  };

  getBoards = async () => {
    try {
      const response = await getBoards({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setBoards(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setBoardLists = (boardLists) => {
    this.boardLists = boardLists;
  };

  getBoardLists = async (boardID) => {
    if (!boardID || !this.stores?.projectStore?.currentProject?.id) {
      return;
    }

    try {
      const response = await getBoardLists({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        boardID,
      });
      if (response.status === 200) {
        this.setBoardLists(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
