import React from 'react';
import classNames from 'classnames';
import './ExtendedStatCard.scss';
import { formatCSATScore, getEmojiForVal } from 'helper/ScoreFormater';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { FeedbackType } from 'models/Project';

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const dDisplay = d > 0 ? `${d}d ` : '';
  const hDisplay = h > 0 ? `${h}h ` : '';
  const mDisplay = m > 0 ? `${m}m` : '';
  const dhms = dDisplay + hDisplay + mDisplay;

  if (dhms.length === 0) {
    return "--";
  }

  return dhms;
}

function percIncrease(a, b) {
  let percent;
  if (b !== 0) {
    if (a !== 0) {
      percent = (b - a) / a * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = - a * 100;
  }
  return Math.floor(percent);
}

interface ExtendedStatCardProps {
  projectTypes?: FeedbackType[];
  icon: string;
  label: string;
  subLabel?: string;
  typeKey: string;
  averageTypeKey?: string;
  values: any;
  prevValues?: any;
  valueSubLabel: string;
  className?: any;
  type?: string;
  showNoData?: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
}

const ExtendedStatCard = ({
  projectTypes,
  icon,
  label,
  subLabel,
  typeKey,
  averageTypeKey,
  values,
  prevValues,
  valueSubLabel,
  className,
  type = 'numbers',
  showNoData = false,
  isLoading = false,
  loadingLabel = '',
}: ExtendedStatCardProps) => {
  const ExtendedStatCardClassName = classNames('extended-stat-card', className);
  
  const averageValueCount = (averageTypeKey && values) ? values[averageTypeKey] : undefined;
  const value = values ? values[typeKey] : undefined;
  const prevValue = prevValues ? prevValues[typeKey] : undefined;
  const dataKeys = Object.keys(values ?? {}).filter((dk) => {
    if (dk && dk.includes(typeKey) && dk !== typeKey) {
      return true;
    }
    return false;
  });

  let change: any = undefined;
  if (typeof prevValue !== 'undefined') {
    const prevVal = prevValue !== 0 ? prevValue : 0;
    change = percIncrease(prevVal, value);
  }

  const changeClass = (valChange) => {
    if (valChange === 0) {
      return '';
    }

    return valChange > 0 ? 'extended-stat-card-change-plus' : 'extended-stat-card-change-minus';
  };

  const formatValue = (val) => {
    if (type === 'numbers') {
      return Math.round(val);
    }

    if (type === 'csat') {
      const csatVal = formatCSATScore(val);
      return `${csatVal} ${getEmojiForVal(Math.round(val * 10))}`;
    }

    if (type === 'timespan') {
      return secondsToDhms(Math.round(val));
    }

    return '';
  };

  const projectTypeForKey = (key) => {
    if (projectTypes) {
      const projectType = projectTypes.find((pt) => pt.type === key);
      if (projectType) {
        return projectType;
      }
    }
    return null;
  };

  const getContent = () => {
    if (isLoading) {
      return <>
        <div className="extended-stat-card-body-title">
          <LoadingAnimationSmall />
        </div>
        <div className="extended-stat-card-body-subtitle mt-20">{loadingLabel}</div>
      </>;
    }

    return showNoData ? (
      <div className="extended-stat-card-body-subtitle">No data yet</div>
    ) : (
      <>
        <div className="extended-stat-card-body-title">{formatValue(averageValueCount ? Math.round(value / averageValueCount) : value)}</div>
        <div className="extended-stat-card-body-subtitle">{valueSubLabel}</div>
        {change !== undefined && <div className={`extended-stat-card-change ${changeClass(change)}`}>
          {change > 0 ? '+' : ''}
          {Math.round(change)}%
        </div>}
      </>
    );
  }

  const renderFooterStats = () => {
    if (showNoData) {
      return null;
    }

    return (
      <div className='extended-stat-card-footer'>
        {dataKeys.map((dk) => {
          const value = values[dk];
          const projectTypeKey = dk.split("_")[1];
          const projectType = projectTypeForKey(projectTypeKey);
          if (!projectType?.menuName) {
            return null;
          }

          var innerChange: any = undefined;
          if (prevValues) {
            const prevVal = prevValues[dk];
            if (typeof prevVal !== 'undefined') {
              innerChange = percIncrease(prevVal, value);
            }
          }

          const formattedValue = formatValue(averageValueCount ? Math.round(value / averageValueCount) : value);

          return (
            <div className='stat-row'>
              <div className='stat-row-name'>{projectType?.menuName}</div>
              <div className='stat-row-value'>
                {innerChange !== undefined && formattedValue !== "--" && <div className={`extended-stat-card-change stat-row-change ${changeClass(innerChange)}`}>
                  ({innerChange > 0 ? '+' : ''}
                  {Math.round(innerChange)}%)
                </div>}
                {formattedValue}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={ExtendedStatCardClassName}>
      <div className="extended-stat-card-header">
        <div className="extended-stat-card-header-title">
          <i className={`fa-solid fa-${icon}`} /> {label}
        </div>
        {subLabel && <div className="extended-stat-card-header-subtitle">{subLabel}</div>}
      </div>
      <div className="extended-stat-card-body">
        {getContent()}
      </div>
      {renderFooterStats()}
    </div>
  );
};

export default ExtendedStatCard;
