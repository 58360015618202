export const checkIfUserIsOrgaAdmin = (user, orga) => {
  if (!orga || !user || !orga.users) {
    return false;
  }

  const orgaUser = orga.users.find((userItem) => {
    if (!userItem || !user) {
      return false;
    }
    return userItem.userId === user.id;
  });

  const isAdmin = orgaUser && orgaUser.role === 'ADMIN';

  return isAdmin;
};

export const checkIfIsOrgaUser = (user, orga) => {
  if (!orga || !user || !orga.users) {
    return false;
  }

  const orgaUser = orga.users.find((userItem) => {
    if (!userItem || !user) {
      return false;
    }
    return userItem.userId === user.id;
  });

  return orgaUser !== undefined;
};
