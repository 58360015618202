import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { updateSession } from 'services/BugHttpService';

// eslint-disable-next-line import/prefer-default-export
export class SharedSessionStore {
  stores: any = {};
  session: any;
  apiKey?: string = undefined;
  upvotes: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  updateUpvotes(insert, id) {
    runInAction(() => {
      if (insert) {
        this.upvotes = [...this.upvotes, id];
      } else {
        this.upvotes = this.upvotes.filter((upvote) => upvote !== id);
      }
    });
  }

  setUpvotes(upvotes) {
    this.upvotes = upvotes;
  }

  setApiKey(apiKey) {
    this.apiKey = apiKey;
  }

  setStores(stores) {
    this.stores = stores;
  }

  setSession(session: any) {
    this.session = session;
  }

  updateSession = async (data: { email?: string; name?: string }) => {
    if (!this.session) {
      return;
    }

    try {
      await updateSession(
        data,
        this.session.gleapId,
        this.session.gleapHash,
        this.apiKey,
      );

      // Locally update data.
      this.session.name = data.name;
      this.session.email = data.email;

      return false;
    } catch (err: any) {
      toast.error('Failed to vote for feature request 😵‍💫');
      return false;
    }
  };
}
