import { makeAutoObservable, runInAction } from 'mobx';
import { Bug } from 'models/Bug';
import {
  getSkipAndLimitFromPage,
  PaginationDataList,
} from 'models/PaginationDataList';
import { Session } from 'models/Session';
import { toast } from 'react-toastify';
import {
  getSessions,
  getSession,
  getSessionActivities,
  removeSession,
  getEventsOfSession,
  searchForSessions,
} from 'services/SessionHttpService';
import WebSocketMessage from 'services/WebSocketMessage';

// eslint-disable-next-line import/prefer-default-export
export class SessionStore implements WebSocketMessage {
  sessionFeedbackActivities: Bug[] = [];
  session?: Session = undefined;
  sessionEvents: any[] = [];
  loadingSession = true;
  loadingSessionEvents = false;
  stores: any = {};

  sessionsDataList: PaginationDataList<Session> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  downloadBugsAsJSON = async () => {
    const json = JSON.stringify({
      session: this.session,
      feedbackItems: this.sessionFeedbackActivities,
    });
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${this.session?.id}_session.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  setLoadingSession(loadingSession) {
    this.loadingSession = loadingSession;
  }

  setSessionFeedbackActivities(sessionFeedbackActivities) {
    this.sessionFeedbackActivities = sessionFeedbackActivities;
  }

  setSession(session) {
    this.session = session;
  }

  async loadSession(id: string = 'USER') {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setSessionFeedbackActivities([]);
    this.setSession(undefined);

    this.setLoadingSession(true);
    try {
      const response = await getSession(
        this.stores.projectStore.currentProject.id,
        id,
      );
      this.setSession(response.data);

      const responseActivities = await getSessionActivities(
        this.stores.projectStore.currentProject.id,
        id,
      );
      this.setSessionFeedbackActivities(responseActivities.data);
    } catch (exp) {
      toast.error('Could not load contact.');
    }
    this.setLoadingSession(false);
  }

  async loadEventsOfSession(
    sessionId: string,
    limit: number,
    silent: boolean = false,
  ) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    if (!silent) {
      this.sessionEvents = [];
      this.loadingSessionEvents = true;
    }
    try {
      const response = await getEventsOfSession(
        this.stores.projectStore.currentProject.id,
        sessionId,
        limit,
      );

      runInAction(() => {
        if (!silent) {
          this.loadingSessionEvents = false;
        }
        this.sessionEvents = response.data;
      });
    } catch (err: any) {}
  }

  searchForSessionItems = async (args: {
    type: string;
    withFeedback: boolean;
    searchTerm: string;
  }) => {
    const currentProject = this.stores.projectStore.currentProject;

    if (!this.stores.projectStore || !currentProject) {
      return;
    }

    if (args.searchTerm === '') {
      this.getSessionItems({
        type: args.type,
        withFeedback: args.withFeedback,
        loadMore: false,
      });
      return;
    }

    try {
      this.sessionsDataList.isLoading = true;

      const response = await searchForSessions(
        currentProject.id,
        args.type,
        args.withFeedback,
        args.searchTerm,
      );
      if (response.status === 200) {
        this.sessionsDataList.data = response.data;
      }
      this.sessionsDataList.isLoading = false;
    } catch (_) {
      this.sessionsDataList.isLoading = false;
    }
  };

  getSessionItems = async (args: {
    type: string;
    withFeedback: boolean;
    loadMore?: boolean;
  }) => {
    const currentProject = this.stores.projectStore.currentProject;

    if (
      this.sessionsDataList.isLoading ||
      !this.stores.projectStore ||
      !currentProject
    ) {
      return;
    }

    try {
      runInAction(() => {
        this.sessionsDataList.isLoading = true;
      });

      if (args.loadMore) {
        this.sessionsDataList.pageIndex += 1;
      } else {
        this.sessionsDataList.pageIndex = 0;
        this.sessionsDataList.data = [];
      }

      const response = await getSessions(
        currentProject.id,
        args.type,
        args.withFeedback,
        getSkipAndLimitFromPage({
          pageIndex: this.sessionsDataList.pageIndex,
          itemsInPage: this.sessionsDataList.itemsInPage,
        }),
      );

      if (response.status === 200) {
        runInAction(() => {
          this.sessionsDataList.data = [
            ...this.sessionsDataList.data,
            ...response.data,
          ];
        });
      }

      this.sessionsDataList.isLoading = false;
    } catch (_) {
      this.sessionsDataList.isLoading = false;
    }
  };

  onEvent = (event: string, data: any) => {};

  removeSession = async (sessionId) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    await removeSession(this.stores.projectStore.currentProject.id, sessionId);

    this.sessionsDataList = {
      data: [],
      pageIndex: 0,
      itemsInPage: 50,
      isLoading: false,
    };
    this.session = undefined;
    this.stores.projectStore.refreshBugsForCurrentProject();

    toast.success('Session removed ✅');
  };

  refreshData = () => {};
}
