import { arrayMoveImmutable as arrayMove } from 'array-move';
import { ReactComponent as IntroIcon } from 'assets/icons/intro.svg';
import { ReactComponent as OutroIcon } from 'assets/icons/outro.svg';
import Container from 'components/Container/Container';
import FeedbackActionQuestionEditorAddOptions from 'components/FeedbackActionQuestionEditorAddOptions/FeedbackActionQuestionEditorAddOptions';
import FeedbackActionQuestionEditorProps from 'components/FeedbackActionQuestionEditorProps/FeedbackActionQuestionEditorProps';
import FeedbackActionQuestionListItem from 'components/FeedbackActionQuestionListItem/FeedbackActionQuestionListItem';
import FeedbackActionQuestionPreview from 'components/FeedbackActionQuestionPreview/FeedbackActionQuestionPreview';
import Filter from 'components/Filter/Filter';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SpecialFeedbackActionQuestionItem from 'components/SpecialFeedbackActionQuestionItem/SpecialFeedbackActionQuestionItem';
import { runInAction } from 'mobx';
import { FeedbackAction } from 'models/Project';
import { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import './FeedbackActionQuestionEditor.scss';

interface FormEditorProps {
  feedbackAction: FeedbackAction;
  isSurvey?: boolean;
}

const FeedbackActionQuestionEditor = ({
  feedbackAction,
  isSurvey,
}: FormEditorProps) => {
  const [toggle, setToggle] = useState(false);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [activeFormItem, setActiveFormItem] = useState(null as any);
  const [editingType, setEditingType] = useState(
    'intro' as 'question' | 'intro' | 'outro',
  );

  const selectNewElement = () => {
    if (!feedbackAction) {
      return;
    }

    if (
      !isSurvey &&
      feedbackAction.introMessage &&
      feedbackAction.introMessage.length > 0
    ) {
      setEditingType('intro');
      setToggle(!toggle);
      return;
    }

    if (feedbackAction.form.length > 0) {
      setEditingType('question');
      setActiveFormItem(feedbackAction.form[0]);
      setToggle(!toggle);
      return;
    }

    setEditingType('outro');
    setToggle(!toggle);
  };

  useEffect(() => {
    if (feedbackAction) {
      selectNewElement();
    }
  }, [feedbackAction]);

  if (!feedbackAction) {
    return (
      <Container>
        <div className="session-loading">
          <LoadingAnimation />
        </div>
      </Container>
    );
  }

  const onSortEnd = (event) => {
    runInAction(() => {
      feedbackAction.form = arrayMove(
        feedbackAction.form,
        event.oldIndex,
        event.newIndex,
      );
      setToggle(!toggle);
    });
  };

  const SortableItem = SortableElement(({ formItem, currentIndex }) => (
    <FeedbackActionQuestionListItem
      key={currentIndex}
      index={currentIndex}
      formItem={formItem}
      onClick={() => {
        setActiveFormItem(formItem);
        setEditingType('question');
      }}
      active={
        editingType === 'question' &&
        activeFormItem &&
        activeFormItem.name === formItem.name
      }
      onRemove={() => {
        runInAction(() => {
          feedbackAction.form.splice(currentIndex, 1);
          setToggle(!toggle);
          setTimeout(() => {
            selectNewElement();
          }, 0);
        });
      }}
    />
  ));

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem
            key={`item-${value.title}-${Math.random()
              .toString(36)
              .substring(7)}`}
            index={index}
            currentIndex={index}
            formItem={value}
          />
        ))}
      </div>
    );
  });

  const renderEditor = () => {
    if (editingType === 'intro') {
      return (
        <div className="special-sidenav-editor">
          <div className="input-label">Intro message</div>
          <textarea
            className="default-textarea"
            placeholder=""
            value={feedbackAction.introMessage ?? ''}
            onChange={(e) => {
              feedbackAction.introMessage = e.target.value;
              setToggle(!toggle);
            }}
          />
          <div className="hint mt-5">
            The intro message will be shown once a feedback flow starts.
          </div>
        </div>
      );
    }

    if (editingType === 'outro') {
      return (
        <div className="special-sidenav-editor">
          <div className="input-label">Thank you message</div>
          <textarea
            className="default-textarea"
            placeholder=""
            value={feedbackAction.thanksMessage ?? ''}
            onChange={(e) => {
              feedbackAction.thanksMessage = e.target.value;
              setToggle(!toggle);
            }}
          />
          <div className="hint mt-5">
            The thank you message will be shown after submitting a submission.
          </div>
        </div>
      );
    }

    return (
      <FeedbackActionQuestionEditorProps
        chatStyle={feedbackAction.chatStyle}
        key={activeFormItem}
        formItemUpdated={(formItem) => {
          setToggle(!toggle);
        }}
        isSurvey={isSurvey}
        formItem={activeFormItem}
      />
    );
  };

  const renderIntroMessageListItem = () => {
    if (isSurvey) {
      return null;
    }

    if (
      !feedbackAction.introMessage ||
      feedbackAction.introMessage.length === 0
    ) {
      return null;
    }

    return (
      <SpecialFeedbackActionQuestionItem
        label={feedbackAction.introMessage}
        fallbackLabel="No intro message set"
        active={editingType === 'intro'}
        Icon={IntroIcon}
        canDelete
        onDelete={() => {
          feedbackAction.introMessage = '';
          setToggle(!toggle);

          setTimeout(() => {
            selectNewElement();
          }, 0);
        }}
        onClick={() => {
          setActiveFormItem(null);
          setEditingType('intro');
        }}
      />
    );
  };

  return (
    <div className="feedback-action-questions-container">
      <div className="questions-list">
        <div className="questions-list-header">
          <div className="questions-list-header-title">Questions</div>
          <PrimaryButton
            className="questions-list-header-button"
            icon={'plus'}
            onClick={() => {
              setShowAddQuestion(true);
            }}
          />
          {showAddQuestion && (
            <FeedbackActionQuestionEditorAddOptions
              feedbackAction={feedbackAction}
              isSurvey={isSurvey}
              onQuestionAdded={(type) => {
                setToggle(!toggle);

                setTimeout(() => {
                  if (type === 'intro') {
                    setEditingType('intro');
                  } else {
                    setEditingType('question');
                    setActiveFormItem(
                      feedbackAction.form[feedbackAction.form.length - 1],
                    );
                  }
                }, 0);
              }}
              closeAddOptions={() => {
                setShowAddQuestion(false);
              }}
            />
          )}
        </div>
        <div className="questions-list-body">
          {renderIntroMessageListItem()}
          <SortableList
            itemsList={feedbackAction.form}
            onSortEnd={onSortEnd}
            useDragHandle
            helperClass="sortable-helper"
          />
          <SpecialFeedbackActionQuestionItem
            label={feedbackAction.thanksMessage}
            fallbackLabel="No thank you message set"
            active={editingType === 'outro'}
            Icon={OutroIcon}
            onClick={() => {
              setActiveFormItem(null);
              setEditingType('outro');
            }}
          />
        </div>
      </div>
      <div className="question-preview">
        {!isSurvey && (
          <div className="question-preview-styleselector">
            <Filter
              onValueChange={(value) => {
                if (value === 'CHAT') {
                  feedbackAction.chatStyle = true;
                  feedbackAction.singlePageForm = false;
                } else {
                  feedbackAction.chatStyle = false;
                }
                setToggle(!toggle);
              }}
              value={feedbackAction.chatStyle ? 'CHAT' : 'FORM'}
              options={[
                { name: 'Chat style', value: 'CHAT' },
                { name: 'Form style', value: 'FORM' },
              ]}
            />
          </div>
        )}
        <FeedbackActionQuestionPreview
          editingType={editingType}
          outroMessage={feedbackAction.thanksMessage}
          introMessage={feedbackAction.introMessage}
          chatStyle={feedbackAction.chatStyle}
          updateToggle={toggle}
          formItem={activeFormItem}
        />
      </div>
      <div className="question-editor">{renderEditor()}</div>
    </div>
  );
};

export default FeedbackActionQuestionEditor;
