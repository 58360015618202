import LinkButton from 'components/LinkButton/LinkButton';
import { inject, observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useClickedOutside } from 'services/Helper';
import moment from 'moment';
import { BugStore } from 'stores/private/BugStore';
import './InboxSnoozeButton.scss';
import ReactTooltip from 'react-tooltip';

interface InboxSnoozeButtonProps {
  bugStore?: BugStore;
  isInbox?: boolean;
}

const InboxSnoozeButton = ({
  bugStore,
  isInbox = false,
}: InboxSnoozeButtonProps) => {
  const bug = bugStore?.currentBug;
  const [showSnoozeMenu, setShowSnoozeMenu] = useState(false);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowSnoozeMenu(false);
  });

  const now = new Date();

  const getMinutesUntil = (days: number, atHour: number, atMinute: number) => {
    var newDate = new Date();
    newDate.setDate(newDate.getDate() + days)
    newDate.setHours(atHour);
    newDate.setMinutes(atMinute);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return moment(newDate).diff(moment(), 'seconds');
  }

  let defaultSnoozeOptions = [{
    label: 'Tomorrow',
    get: () => {
      return getMinutesUntil(1, 8, 0);
    },
  }, {
    label: 'Next week',
    get: () => {
      var a = moment().endOf('week');
      var b = moment();
      return getMinutesUntil(a.diff(b, 'days') + 2, 8, 0);
    },
  }, {
    label: 'One week',
    get: () => {
      return getMinutesUntil(7, 8, 0);
    },
  }, {
    label: 'Next month',
    get: () => {
      var a = moment().endOf('month');
      var b = moment();
      return getMinutesUntil(a.diff(b, 'days') + 1, 8, 0);
    },
  }];
  let snoozeOptions = now.getHours() < 18 ? [{
    label: 'Later today',
    get: () => {
      if (now.getHours() < 11) {
        return getMinutesUntil(0, 12, 0);
      }
      return getMinutesUntil(0, 18, 0);
    },
  }, ...defaultSnoozeOptions] : defaultSnoozeOptions;

  if (!isInbox || !bug) {
    return null;
  }

  if (!bug) {
    return <></>;
  }

  const openSnoozeMenu = () => {
    setShowSnoozeMenu(!showSnoozeMenu);
  };

  return (
    <div className="snooze-button-container">
      {bug.status === 'SNOOZED' && bug.snoozedUntil ? (
        <>
          <div className='snoozed-badge'>
            <div className='unread-snoozed-icon-container'>
              <i className="unread-snoozed-icon fa-solid fa-moon" />
              <span className="unread-snoozed-icon-label">{moment(bug.snoozedUntil).format(moment(bug.snoozedUntil).isSame(moment(), 'day') ? "[Today, ]h:mm A" : "MMM DD")}</span>
            </div>
            <LinkButton
              label={`Unsnooze`}
              className="bfw snoozed-button ml-10 mr-10"
              noBorder
              iconSideRight={false}
              icon="circle-xmark"
              onClick={() => {
                bugStore!.updateBug(bug.id, {
                  status: 'OPEN',
                  snoozedUntil: null,
                });
                bugStore!.clearCurrentBug();
              }}
            />
          </div>
        </>
      ) : (
        <>
          <ReactTooltip
            id="snoozeButtonTooltip"
            className="infoTooltip infoTooltipButton"
            delayHide={0}
            type="light"
            effect="solid"
            getContent={(content) => {
              return (
                <div className="send-key-tooltip">
                  <span>{content}</span>
                </div>
              );
            }}
          />
          <div data-for="snoozeButtonTooltip" data-tip={`Snooze ${isInbox ? 'conversation' : 'ticket'}`}>
            <LinkButton
              label="Snooze"
              className="bfw ml-10 mr-10"
              noBorder
              iconSideRight={false}
              icon="moon"
              onClick={() => {
                openSnoozeMenu();
              }}
            />
          </div>
        </>
      )}
      {showSnoozeMenu && (
        <div className="snooze-options-list" ref={wrapperRef}>
          {snoozeOptions.map((snoozeOption, index) => {
            return (
              <div
                className="snooze-options-list-item"
                key={index}
                onClick={() => {
                  const delay = snoozeOption.get();
                  bugStore!.snoozeBug(bug.id, delay);
                  bugStore!.clearCurrentBug();
                }}
              >
                <div className="snooze-options-list-item-shortcut-content">
                  {snoozeOption.label}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default inject('bugStore')(observer(InboxSnoozeButton));
