import classNames from 'classnames';
import Slider from 'rc-slider';
import { useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';
import './SelectTimeOnPageDelay.scss';

export const SelectTimeOnPageDelay = ({ className, value, onChange }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  useEscape(() => {
    setIsOpen(false);
  });
  const cardClassName = classNames(
    {
      'select-time-on-page-delay': true,
    },
    className,
  );

  const getDelay = () => {
    const number = parseInt(value);
    if (!isNaN(number) && number >= 0) {
      return number;
    }
    return 0;
  }
  const delay = getDelay();

  const renderDelayText = () => {
    if (delay === 0) {
      return (<>+ Add time on page rule</>);
    }

    return (<>Time on page is <b>{delay} second{delay === 1 ? '' : 's'}</b></>);
  }

  return (
    <div
      className={cardClassName}
      ref={wrapperRef}>
      <div
        className={`select-time-on-page-delay--inner ${delay && delay > 0 && 'select-time-on-page-delay--inner--valueadded'}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {renderDelayText()}
      </div>
      <div className={`select-time-on-page-delay-overlay ${isOpen && 'select-time-on-page-delay-overlay--open'}`}>
        <div className="corner-radius">
          <Slider
            marks={{
              0: '0s',
              10: '10s',
              20: '20s',
              30: '30s',
              40: '40s',
              50: '50s',
              60: '60s',
            }}
            step={null}
            min={0}
            max={60}
            value={delay ?? 0}
            onChange={(value) => {
              if (!isNaN(value) && value >= 0) {
                onChange(value);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
