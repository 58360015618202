import React, { useEffect, useState } from 'react';
import './BugTypeSelection.scss';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getIconForFeedbackType } from 'components/Sidenav/SidenavElement/SidenavElement';
import { FeedbackType } from 'models/Project';

interface BugAssignUserProps {
  projectStore?: ProjectStore;
  value: string;
  onValueChanged: (data: any) => void;
  all?: boolean;
}

const getOptionFromFeedbackType = (feedbackType: FeedbackType) => {
  let newStatus = 'OPEN';
  if (feedbackType.options && feedbackType.options.possibleLanes) {
    newStatus = feedbackType.options.possibleLanes[0].key;
  }

  return {
    title: feedbackType.name,
    value: feedbackType.type,
    icon: feedbackType.icon,
    newStatus,
  };
};

const BugTypeSelection = ({
  projectStore,
  value,
  onValueChanged,
  all = false,
}: BugAssignUserProps) => {
  const [data, setData] = useState(null as any);

  useEffect(() => {
    if (value && projectStore?.currentProject) {
      const bugType = projectStore?.currentProject.projectTypes.find((x) => {
        // @ts-ignore
        return x.type === value;
      });
      if (bugType) {
        setData(getOptionFromFeedbackType(bugType));
      }
    }
  }, [value, projectStore?.currentProject]);

  if (!value || data === '' || !projectStore?.currentProject?.projectTypes) {
    return <></>;
  }

  const options: any[] = [];
  for (let i = 0; i < projectStore?.currentProject?.projectTypes.length; i++) {
    const option = projectStore?.currentProject?.projectTypes[i];
    if (option.selectable || all) {
      options.push(getOptionFromFeedbackType(option));
    }
  }

  return (
    <>
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        defaultValue={data}
        value={data}
        placeholder="Type"
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            const Icon = getIconForFeedbackType(option.data.icon);
            return (
              <div className="bug-type-item">
                <Icon className="status-icon" />
                {option.data.title}
              </div>
            );
          },
          Option: (option: any) => {
            const Icon = getIconForFeedbackType(option.data.icon);
            return (
              <div
                className="option option-list-item bug-type-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <Icon className="status-icon" />
                {option.data.title}
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected) {
            onValueChanged(selected);
            setData(selected);
          }
        }}
        options={options}
      />
    </>
  );
};

export default inject('projectStore')(observer(BugTypeSelection));
