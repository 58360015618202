import JSConfetti from 'js-confetti';

export default class ConfettiHelper {
  // @ts-ignore
  static jsConfetti = new JSConfetti({
    canvas: document.querySelector('.gleap-fun') as any,
  });

  static start(
    emojis: string[] = ['🦄', '🚀', '🎉'],
    emojiSize = 80,
    confettiNumber = 30,
  ) {
    this.jsConfetti.addConfetti({
      emojis,
      // @ts-ignore
      emojiSize,
      confettiNumber,
    });
  }
}
