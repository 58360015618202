import ListTable, { CellGestureDetector } from 'components/ListTable/ListTable';
import { getDataDescription } from 'helper/FormDataHelper';
import { inject, observer } from 'mobx-react';
import TimeAgo from 'react-timeago';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { ReactComponent as SearchIcon } from 'assets/icons/noun-search-1157129-FFFFFF.svg';
import './ProjectArchive.scss';
import TextInput from 'components/TextInput/TextInput';
import Loading from 'components/Loading/Loading';
import moment from 'moment';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import InfoBox from 'components/InfoBox/InfoBox';
import Filter from 'components/Filter/Filter';
import { getIconForFeedbackType } from 'components/Sidenav/SidenavElement/SidenavElement';
import { debounce } from 'lodash';
import LinkButton from 'components/LinkButton/LinkButton';
import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';

interface ProjectArchiveProps {
  projectStore?: ProjectStore;
}

const ProjectArchive = ({ projectStore }: ProjectArchiveProps) => {
  const archivedBugs = projectStore!.archivedBugsDataList.data;
  const [filter, setFilter] = useState('ALL');
  const [fullTextFilter, setFullTextFilter] = useState('');

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore!.getArchivedFeedbackItems({});
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    projectStore!.archivedBugsDataList.pageIndex = 0;
    projectStore!.getArchivedFeedbackItems({
      isSpam: filter === 'SPAM',
    });
  }, [filter]);

  const debounceSearch = useCallback(
    debounce(
      (nextValue) =>
        projectStore?.searchForArchivedFeedbackItems({
          searchTerm: nextValue,
        }),
      350,
    ),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              projectStore!.openFeedbackItem({
                shareToken: row.value.bug.shareToken,
              });
            }}
            label={`#${row.value.bug.bugId} ${getDataDescription({
              data: row.value.bug,
              maxLength: 45,
            })}`}
          />
        ),
      },
      {
        Header: 'Type',
        width: '50px',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => {
          const Icon = getIconForFeedbackType(row.value.bug.type);
          return <Icon className="cell-icon" />;
        },
      },
      {
        className: 'hide-on-mobile',
        width: '20%',
        Header: 'Created at',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => moment(row.value.bug.createdAt).format('MMM Do, h:mma'),
      },
      {
        className: 'hide-on-mobile',
        width: '20%',
        Header: 'Deletion in',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) => (
          <TimeAgo date={moment(row.value.bug.updatedAt).add(365, 'days')} />
        ),
      },
      {
        className: 'hide-on-mobile',
        Header: 'Spam',
        width: '10%',
        accessor: (row) => ({
          bug: row,
        }),
        Cell: (row) =>
          row.value.bug.isSpam ? (
            <i className="fa-solid fa-user-robot-xmarks"></i>
          ) : null,
      },
    ],
    [],
  );
  return (
    <PageContainer>
      <PageHeadLine title="Project archive">
        <div className="filter-bar">
          <Filter
            className="mr-20"
            onValueChange={(value) => {
              setFilter(value);
            }}
            value={filter}
            options={[
              { name: 'All', value: 'ALL' },
              { name: 'Spam', value: 'SPAM' },
            ]}
          />
          <BoardSearchFilter />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <InfoBox className="mb-30">
          <>All archived items will be deleted automatically after 1 year.</>
        </InfoBox>
        <ListTable data={archivedBugs} columns={columns} />
        {projectStore?.archivedBugsDataList.isLoading &&
          projectStore!.archivedBugsDataList.data.length === 0 && (
            <div className="mt-20">
              <Loading />
            </div>
          )}
        {projectStore!.archivedBugsDataList.data.length > 0 &&
          projectStore!.archivedBugsDataList.itemsInPage *
            (projectStore!.archivedBugsDataList.pageIndex + 1) ===
            projectStore!.archivedBugsDataList.data.length && (
            <div className="mt-20">
              <LinkButton
                isLoading={projectStore?.archivedBugsDataList?.isLoading}
                label="Load more"
                onClick={() => {
                  projectStore!.getArchivedFeedbackItems({
                    loadMore: true,
                    isSpam: filter === 'SPAM',
                  });
                }}
              />
            </div>
          )}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(ProjectArchive));
