import moment from "moment";
import './FeedbackTextAnswer.scss';

interface FeedbackTextAnswerProps {
    answer: any;
    projectStore?: any;
}

const FeedbackTextAnswer = ({ answer, projectStore }: FeedbackTextAnswerProps) => {
    const hasNPS = !(answer.nps === undefined || answer.nps === null || isNaN(answer.nps));

    const renderNPS = () => {
        if (!hasNPS) {
            return null;
        }

        const nps = parseInt(answer.nps);

        var npsClass = 'promoters';
        if (nps < 9) {
            npsClass = 'passives';
        }
        if (nps < 7) {
            npsClass = 'detractors';
        }

        return (<div className={`feedback-text-answer-nps feedback-text-answer-nps--${npsClass}`}>{nps}</div>);
    }

    return (
        <div
            className={`feedback-text-answer ${hasNPS ? 'feedback-text-answer--has-nps' : ''}`}
        >
            {renderNPS()}
            <div className="feedback-text-answer-maincontent">
                <div className="feedback-text-answer-content">{answer.content}</div>
                <div className="feedback-text-answer-date">
                    {moment(answer.createdAt).format('LLL')}
                    {answer.shareToken && <>· <span className="feedback-text-answer-open-details" onClick={() => {
                        projectStore!.openFeedbackItem({
                            shareToken: answer.shareToken,
                        });
                    }}>Open feedback</span></>}
                </div>
            </div>
        </div>
    );
};

export default FeedbackTextAnswer;
