import { makeAutoObservable, runInAction } from 'mobx';
import { sendPostMessage } from 'services/Helper';
import {
  getPublicNews,
  getPublicNewsDetails,
} from 'services/PublicNewsHttpService';

// eslint-disable-next-line import/prefer-default-export
export class SharedNewsStorePublic {
  news: any[] = [];
  newsDetails: any = {};
  loadingNews = false;
  loadingNewsDetails = false;
  newsOffset = 0;
  noMoreNews = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadNews = async (
    apiKey: string,
    gleapId: string,
    gleapHash: string,
    initialLoad = false,
  ) => {
    if (this.loadingNews || this.noMoreNews) {
      return;
    }

    this.loadingNews = true;
    if (initialLoad) {
      this.newsOffset = 0;
      this.news = [];
      this.noMoreNews = false;
    }

    try {
      const response = await getPublicNews(
        apiKey,
        this.newsOffset,
        gleapId,
        gleapHash,
      );
      if (response.data) {
        runInAction(() => {
          if (response.data.length === 0) {
            this.noMoreNews = true;
            this.loadingNews = false;
          } else {
            this.newsOffset += response.data.length;
            this.news = [...this.news, ...response.data];
            this.loadingNews = false;
          }
        });
      }
    } catch (error) { }
  };

  loadNewsDetails = async (
    apiKey: string,
    newsId: string,
    gleapId: string,
    gleapHash: string,
  ) => {
    this.loadingNewsDetails = true;
    this.newsDetails = {};

    try {
      const response = await getPublicNewsDetails(
        apiKey,
        newsId,
        gleapId,
        gleapHash,
      );
      if (response.data) {
        runInAction(() => {
          this.newsDetails = response.data;
          this.loadingNewsDetails = false;

          sendPostMessage(
            JSON.stringify({
              type: 'app',
              name: 'page',
              data: {
                title: this.newsDetails.subject || '',
                path: window.location.pathname,
              },
            }),
          );
        });
      }
    } catch (error) { }
  };
}
