import axios from '../Axios';

const getAuthCodeFromLinear = (projectID) => {
  const redirectUri = `${process.env.REACT_APP_BASEURL}/integrations/linear`;

  return window.open(
    `${process.env.REACT_APP_LINEAR_CONNECT_URL}?state=${projectID}&redirect_uri=${redirectUri}&client_id=${process.env.REACT_APP_LINEAR_CLIENT_ID}&scope=write&actor=application&response_type=code`,
    '_self',
  );
};

const setLinearAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/linear`, {
    code,
  });
};

const setLinearActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/linear/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
    },
  );
};

const disconnectLinearIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/linear/${integrationID}`,
  );
};

const getTeams = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/linear/${integrationID}/teams`,
  );
};

export {
  getAuthCodeFromLinear,
  setLinearAuthToken,
  setLinearActions,
  disconnectLinearIntegration,
  getTeams,
};
