export enum PostMessageAction {
  INITIALIZE = 'INITIALIZE',
  SUBSCRIBE_TO_TOPIC = 'SUBSCRIBE_TO_TOPIC',
  UNSUBSCRIBE_FROM_TOPIC = 'UNSUBSCRIBE_FROM_TOPIC',
  FCM_OPENED = 'FCM_OPENED',
}

export type PostMessageData = {
  action: PostMessageAction;
  data: any;
};

export enum FCM_TOPIC {
  USER = 'user',
  PROJECT = 'project',
  ORGANIZATION = 'organization',
}
