import React, { useEffect } from 'react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { inject, observer } from 'mobx-react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import './Referral.scss';
import ShareableUrl from 'components/ShareableUrl/ShareableUrl';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { HeadLine } from 'components/HeadLine/HeadLine';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import NoAdminCard from 'components/NoAdminCard/NoAdminCard';

interface ReferralProps {
  organisationStore?: OrganisationStore;
}
const Referral = ({ organisationStore }: ReferralProps) => {
  useEffect(() => {
    if (organisationStore?.currentOrganisation) {
      organisationStore!.getVoucherOfCurrentOrganisation();
    }
  }, [organisationStore?.currentOrganisation]);

  const code = organisationStore?.voucherCode?.promotionCode ?? '--';
  const shareText = `We love working with @Gleap. The tool is all about bug reporting and customer feedback with glee 🔥 Get a 20% discount on your first year using our coupon code: ${code}`;
  const shareURL = 'https://gleap.io';

  const renderContent = () => {
    return (
      <>
        <HeadLine
          className="mb-30"
          title="Gift 20 % off any plan, earn 20 % off your own plan"
        />
        <div className="mb-20 text">
          Invite friends, business partners or companies in your area to join
          Gleap and we’ll give you 20 % off your plan or any plan you choose for
          the next year. Even better, the friend you refer also gets 20 % off
          their new plan for their first year at Gleap.
          <br />
          <br />
          Your coupon code for three of your closest friends or partners:
        </div>
        <SizedContainer size={ContainerSizes.S}>
          <ShareableUrl url={code} />
        </SizedContainer>
        <div className="text text--small mt-30">Share on social media</div>
        <div className="share-buttons mt-10">
          <FacebookShareButton url={shareURL} title={shareText}>
            <FacebookIcon size={43} borderRadius={43} />
          </FacebookShareButton>
          <TwitterShareButton url={shareURL} title={shareText}>
            <TwitterIcon size={43} borderRadius={43} />
          </TwitterShareButton>
          <RedditShareButton url={shareURL} title={shareText}>
            <RedditIcon size={43} borderRadius={43} />
          </RedditShareButton>
          <LinkedinShareButton url={shareURL} title={shareText}>
            <LinkedinIcon size={43} borderRadius={43} />
          </LinkedinShareButton>
          <EmailShareButton
            url={shareURL}
            subject="Join me at Gleap"
            body={`Hey there,\nI’d love to invite you to join the Gleap family. The tool is all about bug reporting and customer feedback with glee and has made my life so much easier. With my coupon code you can now get a 20% discount on your first year: ${code}\nWould love to hear what you think about Gleap.\nCheers`}
          >
            <EmailIcon size={43} borderRadius={43} />
          </EmailShareButton>
        </div>
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Help us grow" />
      <PageContent hasTitle className="referral-container" isSmallBoxed>
        {!organisationStore?.isOrganisationAdmin ? (
          <NoAdminCard title='Referral are' />
        ) : (
          renderContent()
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject('organisationStore')(observer(Referral));
