import Filter from 'components/Filter/Filter';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import './ProjectOutboundConfiguration.scss';
import { ampli } from 'ampli';
import { conditionIsValid } from 'components/ConditionTag/ConditionTag';
import Gleap from 'gleap';
import MessageConfiguration from './components/MessageConfiguration/MessageConfiguration';
import OptionsConfiguration from './components/OptionsConfiguration/OptionsConfiguration';
import OutboundTriggerConfiguration from './components/OutboundTriggerConfiguration/OutboundTriggerConfiguration';
import EmailConfiguration from './components/EmailConfiguration/EmailConfiguration';
import NewsConfiguration from './components/NewsConfiguration/NewsConfiguration';
import FeedbackActionQuestionEditor from 'components/FeedbackActionQuestionEditor/FeedbackActionQuestionEditor';
import { SidenavStore } from 'stores/private/SidenavStore';

interface ProjectOutboundConfigurationProps {
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  sidenavStore?: SidenavStore;
}

const ProjectOutboundConfiguration = ({
  projectStore,
  outboundStore,
  sidenavStore,
}: ProjectOutboundConfigurationProps) => {
  // @ts-ignore
  const { projectId, outboundId } = useParams();
  const navigate = useNavigate();

  const project = projectStore?.currentProject;
  const outboundRule = outboundStore?.outboundRule;
  const [currentPage, setCurrentPage] = useState('questions');
  const [toggle, setToggle] = useState(false);
  const [content, setContent] = useState(null as any);

  const debouncedQueryFixedRecievers = useCallback(
    debounce(
      (_outboundId, _conditions, _targetAudience) =>
        outboundStore!.queryFixedRecievers(_outboundId, _conditions, _targetAudience),
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (outboundRule?.message && outboundRule?.message.content) {
      setContent(outboundRule?.message);
    }
  }, [outboundRule?.message]);

  useEffect(() => {
    if (outboundId && outboundRule?.frequencyType === "fixed") {
      outboundStore!.loadingOutboundRecipients(true);
      debouncedQueryFixedRecievers(outboundId, outboundRule?.conditions, outboundRule?.targetAudience);
    }
  }, [outboundRule?.frequencyType, outboundRule?.conditions, outboundRule?.targetAudience]);

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject && outboundId) {
      projectStore.getStreamedEventKeys();
      outboundStore!.loadOutboundRule(outboundId);
      outboundStore!.loadOutboundRuleResponses(outboundId);
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    const isHomePage =
      currentPage === 'questions' ||
      currentPage === 'message' ||
      currentPage === 'email' ||
      currentPage === 'news';
    if (isHomePage) {
      if (outboundRule && outboundRule.type === 'MESSAGE') {
        setCurrentPage('message');
      }
      if (outboundRule && outboundRule.type === 'EMAIL') {
        setCurrentPage('email');
      }
      if (outboundRule && outboundRule.type === 'SURVEY') {
        setCurrentPage('questions');
      }
      if (outboundRule && outboundRule.type === 'NEWS') {
        setCurrentPage('news');
      }
    }
  }, [outboundRule]);

  if (
    !outboundStore?.loadingOutboundRule &&
    project &&
    outboundRule &&
    project?.projectActions &&
    outboundRule.type === 'SURVEY' &&
    !(
      outboundRule.actionType &&
      project?.projectActions[outboundRule.actionType]
    )
  ) {
    return (
      <PageContainer>
        <PageHeadLine title="Outbound" />
        <PageContent hasTitle isCentered>
          <div className="input-label">Misconfigured survey</div>
          <div className="text">
            This survey is misconfigured and must be deleted. If this happened
            by accident,{' '}
            <a
              href="#"
              onClick={() => {
                Gleap.startFeedbackFlow('contact', true);
              }}
            >
              please contact
            </a>{' '}
            us.
          </div>
          <LinkButton
            className="danger mt-20"
            label="Delete"
            onClick={async () => {
              Swal.fire({
                text: 'Do you really want to delete this outbound message and all associated replies?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await outboundStore!.removeOutboundRule(outboundId);
                  navigate(
                    `/projects/${projectStore?.currentProject!.id}/outbound`,
                  );
                }
              });
            }}
          />
        </PageContent>
      </PageContainer>
    );
  }

  if (
    outboundStore?.loadingOutboundRule ||
    !project ||
    !outboundRule ||
    !project?.projectActions ||
    (outboundRule.type === 'SURVEY' &&
      !(
        outboundRule.actionType &&
        project?.projectActions[outboundRule.actionType]
      ))
  ) {
    return (
      <PageContainer>
        <PageHeadLine
          title="..."
          onActionBack={() => {
            navigate(`/projects/${projectId}/outbound`);
          }}
        />
        <PageContent hasTitle isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const getConditions = () => {
    if (!outboundRule.conditions || outboundRule.conditions.length === 0) {
      if (outboundRule?.eventTrigger && outboundRule?.eventTrigger.length > 0) {
        return [
          {
            event: outboundRule.eventTrigger,
            type: 'firstoccured',
            dateOperator: 'relative',
            matchOperator: 'greaterthan',
            data: outboundRule.eventTriggerDelay || 0,
          },
        ] as any[];
      } else {
        return [];
      }
    }
    return outboundRule.conditions;
  };

  const preSaveCheck = () => {
    // Update old conditions.
    outboundRule.conditions = getConditions();

    // Update message.
    if (
      outboundRule &&
      (outboundRule.type === 'EMAIL' || outboundRule.type === 'NEWS')
    ) {
      runInAction(() => {
        outboundRule.message = content;
      });
    }

    if (outboundStore?.loadingFixedRecievers) {
      Swal.fire(
        'Preview not loaded yet',
        'Please wait for the preview to be loaded.',
        'info',
      );

      return false;
    }

    if (
      outboundRule.conditions &&
      outboundRule.conditions.length > 0 &&
      !outboundRule.conditions
        .map((condition) => conditionIsValid(condition))
        .every((condition) => condition === true)
    ) {
      Swal.fire(
        'Invalid audience rules specified',
        'Please fix your audience rules.',
        'info',
      );

      return false;
    }

    var triggerNeeded = true;
    if (outboundRule.type === 'NEWS') {
      triggerNeeded = false;
    }
    if (outboundRule.frequencyType === "fixed") {
      triggerNeeded = false;
    }
    if (
      outboundRule.type !== 'SURVEY' &&
      triggerNeeded &&
      (!outboundRule.trigger ||
        !conditionIsValid(outboundRule.trigger))
    ) {
      Swal.fire(
        'Invalid trigger event specified',
        'Please fix your trigger event.',
        'info',
      );

      return false;
    }

    if (outboundRule.type === 'MESSAGE') {
      if (!outboundRule.sender) {
        Swal.fire(
          'No sender specified',
          'You must specify a sender for your message.',
          'info',
        );
        return false;
      }
      if (!(outboundRule.message && outboundRule.message.length > 0)) {
        Swal.fire(
          'No message specified',
          'You must specify a message.',
          'info',
        );
        return false;
      }
    }
    if (outboundRule.type === 'EMAIL') {
      if (!outboundRule.sender) {
        Swal.fire(
          'No sender specified',
          'You must specify a sender for your message.',
          'info',
        );
        return false;
      }
      if (
        !(
          outboundRule.message &&
          outboundRule.message.content &&
          outboundRule.message.content.length > 0
        )
      ) {
        Swal.fire(
          'No message specified',
          'You must specify a message.',
          'info',
        );
        return false;
      }
      if (!(outboundRule.subject && outboundRule.subject.length > 0)) {
        Swal.fire(
          'No subject specified',
          'You must specify a subject.',
          'info',
        );
        return false;
      }
    }
    if (outboundRule.type === 'NEWS') {
      if (
        !(
          outboundRule.message &&
          outboundRule.message.content &&
          outboundRule.message.content.length > 0
        )
      ) {
        Swal.fire(
          'No content specified',
          'You must specify a content.',
          'info',
        );
        return false;
      }
      if (!(outboundRule.subject && outboundRule.subject.length > 0)) {
        Swal.fire('No title specified', 'You must specify a title.', 'info');
        return false;
      }
      if (
        !(outboundRule.coverImageUrl && outboundRule.coverImageUrl.length > 0)
      ) {
        Swal.fire(
          'No article image specified',
          'You must specify a article image.',
          'info',
        );
        return false;
      }
    }

    return true;
  };

  const getMenuOptions = () => {
    if (outboundRule && outboundRule.type === 'MESSAGE') {
      return [
        { name: 'Message', value: 'message' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }
    if (outboundRule && outboundRule.type === 'SURVEY') {
      return [
        { name: 'Questions', value: 'questions' },
        { name: 'Options', value: 'options' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }
    if (outboundRule && outboundRule.type === 'EMAIL') {
      return [
        { name: 'Email', value: 'email' },
        { name: 'Trigger', value: 'trigger' },
      ];
    }
    return [];
  };

  const setOutboundLive = async (updateProjectActions = false) => {
    if (!preSaveCheck()) {
      return;
    }

    if (outboundRule.frequencyType === 'fixed') {
      const shouldContinue = await Swal.fire({
        text: `Are you sure you want to send this outbound message to ${outboundStore?.fixedLimitReached ? outboundStore?.fixedRecieversLimitCount : outboundStore?.fixedRecieversCount} people now?`,
        showCancelButton: true,
        confirmButtonText: `Send now ⚡️`,
        denyButtonText: `Cancel`,
      });
      if (!shouldContinue.isConfirmed) {
        return;
      }
    }

    if (updateProjectActions) {
      await projectStore!.updateProject(project!.id, {
        projectActions: project?.projectActions,
      }, false);
    }

    outboundRule.newTrigger = true;
    outboundRule.status = 'live';
    await outboundStore!.updateOutboundRule(
      outboundId,
      outboundRule,
    );
    navigate(
      `/projects/${projectStore?.currentProject!.id}/outbound`,
    );

    ampli.surveyLaunched({
      projectId: projectStore?.currentProject!.id,
      name: outboundRule.name,
    });
  }

  const canSetLive = currentPage === 'trigger' || currentPage === 'news';

  const buildHeadline = () => {
    return (
      <div className="header-content-right">
        {!(outboundRule.status === 'live' &&
          outboundRule.frequencyType === 'dynamic') && (<LinkButton
            className="danger ml-10"
            label="Delete"
            onClick={async () => {
              Swal.fire({
                text: 'Do you really want to delete this outbound message and all associated replies?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await outboundStore!.removeOutboundRule(outboundId);
                  navigate(
                    `/projects/${projectStore?.currentProject!.id}/outbound`,
                  );
                }
              });
            }}
          />)}
        <LinkButton
          className="save-button ml-10"
          onClick={async () => {
            if (!preSaveCheck()) {
              return;
            }

            outboundRule.newTrigger = true;
            if (outboundRule && outboundRule.type === 'SURVEY') {
              // Update the outbound actions.
              if (project?.projectActions) {
                await projectStore!.updateProject(project!.id, {
                  projectActions: project?.projectActions,
                }, false);
              }
            }
            await outboundStore!.updateOutboundRule(outboundId, outboundRule);
          }}
          label="Save"
        />
        {outboundRule.status === 'live' &&
          outboundRule.frequencyType === 'dynamic' && (
            <PrimaryButton
              className="save-button danger ml-10"
              icon='pause'
              label="Unpublish"
              onClick={async () => {
                outboundRule.status = 'draft';

                await projectStore!.updateProject(project!.id, {
                  projectActions: project?.projectActions,
                }, false);

                await outboundStore!.updateOutboundRule(
                  outboundId,
                  outboundRule,
                );
                navigate(
                  `/projects/${projectStore?.currentProject!.id}/outbound`,
                );
              }}
            />
          )}
        {outboundRule.status === 'draft' && (
          <PrimaryButton
            className={`save-button ml-10 ${canSetLive ? 'live' : ''}`}
            onClick={async () => {
              if (outboundRule.type === 'SURVEY') {
                if (currentPage === 'questions') {
                  setCurrentPage('options');
                } else if (currentPage === 'options') {
                  setCurrentPage('trigger');
                } else {
                  setOutboundLive(true);
                }
              }

              if (
                outboundRule.type === 'MESSAGE' ||
                outboundRule.type === 'EMAIL'
              ) {
                if (currentPage === 'message' || currentPage === 'email') {
                  setCurrentPage('trigger');
                } else {
                  setOutboundLive();
                }
              }

              if (outboundRule.type === 'NEWS') {
                setOutboundLive();
              }
            }}
            icon={canSetLive ? 'play' : undefined}
            label={
              canSetLive
                ? 'Launch'
                : 'Continue'
            }
          />
        )}
      </div>
    );
  };

  const canEditTitle = () => {
    if (outboundRule.type === 'EMAIL' || outboundRule.type === 'NEWS') {
      return false;
    }

    return true;
  };

  return (
    <PageContainer className="page-centered-main-tabs">
      <PageHeadLine
        title={canEditTitle() ? outboundRule.name : `Edit ${currentPage}`}
        isEditable={canEditTitle()}
        onChangeTitle={(val) => {
          if (
            project!.projectActions &&
            project!.projectActions[outboundRule.actionType]
          ) {
            project!.projectActions[outboundRule.actionType].title = val;
          }
          outboundRule.name = val;
          setToggle(!toggle);
        }}
        onActionBack={() => {
          if (outboundRule.type === 'NEWS') {
            navigate(`/projects/${projectId}/outbound`);
          } else if (outboundRule.status === 'live') {
            navigate(`/projects/${projectId}/outbound/${outboundId}`);
          } else {
            navigate(`/projects/${projectId}/outbound`);
          }
        }}
      >
        {currentPage !== 'news' && (
          <div className="centered-main-tabs">
            <div className="main-tabs">
              <Filter
                onValueChange={(value) => {
                  setCurrentPage(value);
                }}
                value={currentPage}
                options={getMenuOptions()}
              />
            </div>
          </div>
        )}
        {buildHeadline()}
      </PageHeadLine>
      <PageContent hasTitle className="page-content--fulltabs">
        {currentPage === 'email' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <EmailConfiguration
              outboundRule={outboundRule}
              content={content}
              inputContentChanged={(newContent) => {
                setContent(newContent);
              }}
            />
          </div>
        )}
        {currentPage === 'message' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <MessageConfiguration outboundRule={outboundRule} />
          </div>
        )}
        {currentPage === 'news' && (
          <div className="outbound-configuration-container outbound-configuration-container--large">
            <NewsConfiguration
              outboundRule={outboundRule}
              content={content}
              inputContentChanged={(newContent) => {
                setContent(newContent);
              }}
            />
          </div>
        )}
        {currentPage === 'questions' && (
          <FeedbackActionQuestionEditor
            feedbackAction={project?.projectActions[outboundRule.actionType]}
            isSurvey
          />
        )}
        {currentPage === 'options' && (
          <div className="outbound-configuration-container">
            <OptionsConfiguration
              outboundRule={outboundRule}
              feedbackAction={project?.projectActions[outboundRule.actionType]}
            />
          </div>
        )}
        {currentPage === 'trigger' && (
          <div className="outbound-configuration-container">
            <OutboundTriggerConfiguration outboundRule={outboundRule} />
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'outboundStore',
  'sidenavStore',
)(observer(ProjectOutboundConfiguration));
