/* eslint-disable @typescript-eslint/no-unused-expressions */
import AuthenticationForm from 'components/AuthenticationForm/AuthenticationForm';
import { inject, observer } from 'mobx-react';
import { UsersStore } from '../../../stores/private/UsersStore';
import './AppSumoRegister.scss';
import AppSumoRegisterForm from './AppSumoRegisterForm/AppSumoRegisterForm';

interface AppSumoRegisterProps {
  usersStore?: UsersStore;
}

const AppSumoRegister = ({ usersStore }: AppSumoRegisterProps) => {
  return (
    <div
      className="login-page"
    >
      <AuthenticationForm
        headline="Hey Sumo-ling 👋"
        subtitle="Let's get started by activating your account."
        form={<AppSumoRegisterForm />}
      />
    </div>
  );
};
export default inject('usersStore')(observer(AppSumoRegister));
