import './LoadingAnimationMedium.scss';

const LoadingAnimationMedium = () => {
  return (
    <div className="spring-spinner-medium">
      <div className="spring-spinner-medium-part top">
        <div className="spring-spinner-medium-rotator" />
      </div>
      <div className="spring-spinner-medium-part bottom">
        <div className="spring-spinner-medium-rotator" />
      </div>
    </div>
  );
};

export default LoadingAnimationMedium;
