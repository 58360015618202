/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import './RegisterForm.scss';
import { useForm } from 'react-hook-form';
import TextInput from 'components/TextInput/TextInput';
import { UsersStore } from 'stores/private/UsersStore';
import { inject } from 'mobx-react';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { EMAIL_REGEX } from 'constants/Regexpatterns';
import { toast } from 'react-toastify';
import { TextHref } from 'components/TextHref/TextHref';
import { useNavigate } from 'react-router';
import SocialLoginButton from 'components/SocialLoginButton/SocialLoginButton';
import { SocialButtonType } from 'models/Enums';
import { validateRecaptchaAction } from 'services/Recaptcha';

interface RegisterFormProps {
  usersStore?: UsersStore;
}

const RegisterForm = ({ usersStore }: RegisterFormProps) => {
  const [tos, setTos] = useState(false);
  const [showGoogleSignup, setShowGoogleSignup] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [presetEmail, setPresetEmail] = useState('');
  const [showFullForm, setShowFullForm] = useState(false);
  const { register, handleSubmit, formState, setValue } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const invitee = query.get('invitee');
    if (invitee) {
      setPresetEmail(invitee);
      setShowFullForm(true);
      setValue('email', invitee);
    }

    const googleSignup = query.get('g');
    if (googleSignup && googleSignup === 'y') {
      setShowGoogleSignup(true);
    } else {
      setShowGoogleSignup(false);
    }

    const signIn = query.get('s');
    if (signIn && signIn === 'y') {
      setShowSignIn(true);
    } else {
      setShowSignIn(false);
    }

    const trialTypeVar = query.get('tt');
    if (trialTypeVar) {
      try {
        localStorage.setItem('trial-type', trialTypeVar);
      } catch (exp) { }
    }
  }, []);

  const onSubmit = async (data: any) => {
    // Show the full form if the user has entered an email address and it's not shown yet.
    if (!showFullForm) {
      setShowFullForm(true);
      return;
    }

    if (!tos) {
      toast.error('Please accept our privacy policy and terms of use.');
      return;
    }

    let token = '';
    try {
      token = (await validateRecaptchaAction('register')) as any;
    } catch (exp) {
      toast.error('Are you human?');
      return;
    }

    if (data.email && data.password && data.name) {
      usersStore?.register(token, data.email, data.password, data.name);
    } else {
      toast.error('Please enter your name, email and a password.');
    }
  };

  const generateErrorText = (type: string) => {
    if (type === 'minLength') {
      return 'The password must contain at least 6 characters.';
    }
    if (type === 'required') {
      return 'Input is required';
    }
    if (type === 'pattern') {
      return 'Wrong input';
    }
    return '';
  };

  return (
    <div className="register-form-container" onClick={() => { }}>
      {showGoogleSignup && !showFullForm && (
        <div className="social-container-button">
          <SocialLoginButton
            title="Sign up with Google"
            type={SocialButtonType.GOOGLE}
          />
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="register-form full-width"
        autoComplete="off"
      >
        <TextInput
          readOnly={presetEmail.length > 0}
          placeholder="you@yourcompany.com"
          type="text"
          label='Work email'
          name="email"
          className={'mb-20'}
          initalValue={presetEmail}
          inputRef={register('email', {
            required: true,
            pattern: EMAIL_REGEX,
          })}
          error={
            formState.errors.email
              ? 'Please enter a valid work email address.'
              : ''
          }
          required
        />
        {!showFullForm && (
          <div className="btn-container">
            <PrimaryButton
              fullWidth
              icon='arrow-right'
              iconSideRight
              onClick={() => {
                setShowFullForm(true);
              }}
              label="Continue"
            />
          </div>
        )}
        {showFullForm && (
          <>
            <TextInput
              placeholder="First name and last name"
              type="text"
              name="name"
              label='Name'
              className="mb-20"
              initalValue=""
              inputRef={register('name', {
                required: true,
                minLength: 2,
              })}
              error={formState.errors.name ? 'Please enter your name' : ''}
              required
            />
            <TextInput
              label='Password'
              placeholder="Password (at least 8 characters)"
              type="password"
              name="password"
              initalValue=""
              inputRef={register('password', {
                required: true,
                minLength: 8,
              })}
              error={
                formState.errors.password
                  ? generateErrorText(formState.errors.password.type) || ''
                  : ''
              }
              required
              className="mb-30"
            />
            <div
              className="checkbox-container mb-20"
              onClick={() => {
                setTos(!tos);
              }}
            >
              <input
                type="checkbox"
                className="checkbox"
                checked={tos}
                onChange={() => { }}
              />
              <span>
                I agree to the{' '}
                <a target="_blank" href="https://www.gleap.io/terms-of-service" rel="noreferrer">
                  terms of service
                </a>, <a target="_blank" href="https://gleap.io/privacy-policy" rel="noreferrer">
                  privacy policy
                </a> and <a target="_blank" href="https://www.gleap.io/data-processing-addendum" rel="noreferrer">
                  data processing addendum
                </a>
                .
              </span>
            </div>
            <div className="btn-container">
              <PrimaryButton
                fullWidth
                className="mt-10"
                submit
                icon='arrow-right'
                iconSideRight
                onClick={() => { }}
                label="Sign up with email"
              />
            </div>
          </>
        )}
      </form>
      {showSignIn && !showFullForm && (
        <TextHref
          className="href mt-30 text"
          label="Already have an account? Sign in"
          onClick={() => {
            navigate('/login');
          }}
        />
      )}
    </div>
  );
};

export default inject('usersStore')(RegisterForm);
