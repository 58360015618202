import { ampli } from 'ampli';
import { HeadLine } from 'components/HeadLine/HeadLine';
import { plans, Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Subscription from 'components/Subscription/Subscription';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './UpsellModal.scss';

interface UpsellModalProps {
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
}

const UpsellModal = ({ modalStore, organisationStore }: UpsellModalProps) => {
  const [chooseSubscription, setChooseSubscription] = useState(modalStore?.customData?.skipIntro ? true : false);
  const planToUpsell = modalStore?.customData?.plan ?? Plantypes.GROWTH;
  const planObjToUpsell = plans[planToUpsell];
  const currentOrganization = organisationStore?.currentOrganisation;
  const trialAlreadyUsed = currentOrganization?.trialUsed;

  useEffect(() => {
    ampli.upsellModalShown({
      planId: planToUpsell,
      isOnboarding: false,
    });
  }, []);

  const renderPlanFeatures = () => {
    if (planToUpsell === Plantypes.GROWTH) {
      return (
        <>
          <div className="upsell-item">
            <div className="icon">
              <i className="fa-regular fa-unlock" />
            </div>
            <div className="text">
              <b>12 months</b> access to your bug reports & customer feedback.
            </div>
          </div>
          <div className="upsell-item">
            <div className="icon">
              <i className="fa-regular fa-paper-plane" />
            </div>
            <div className="text">
              Access to all features including <b>surveys</b>,{' '}
              <b>release-notes</b> & the <b>knowledge base</b>.
            </div>
          </div>
          <div className="upsell-item">
            <div className="icon">
              <i className="fa-regular fa-plug" />
            </div>
            <div className="text">
              <b>Integrate Gleap</b> with your favourite project management
              tools.
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="upsell-item">
          <div className="icon">
            <i className="fa-regular fa-unlock" />
          </div>
          <div className="text">
            <b>31 days</b> access to your bug reports & customer feedback.
          </div>
        </div>
        <div className="upsell-item">
          <div className="icon">
            <i className="fa-regular fa-rectangle-terminal" />
          </div>
          <div className="text">
            <b>Session & environment data.</b> Never miss the important
            information when a bug happens.
          </div>
        </div>
        <div className="upsell-item">
          <div className="icon">
            <i className="fa-regular fa-square-poll-horizontal" />
          </div>
          <div className="text">
            <b>Micro surveys</b> help you better understand your customer's
            needs.
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="upsell-modal">
      <div className="upsell-modal-left">
        {chooseSubscription && <Subscription plan={planToUpsell} />}
        {!chooseSubscription && (
          <>
            <HeadLine
              title={
                currentOrganization?.trialUsed
                  ? 'Upgrade to a paid plan'
                  : `Start your free ${planObjToUpsell.nameSolo} trial`
              }
              subtitle="Fix bugs in no time by getting all the important information your developers need."
            />
            <div className="text mb-20">
              <b>Here's what you get with {planObjToUpsell.name}.</b>
            </div>
            {renderPlanFeatures()}
            {!trialAlreadyUsed && (
              <div className="text mb-20">
                <b>Cancel any time.</b> We will remind you 7 days before your
                trial ends.
              </div>
            )}
            <PrimaryButton
              fullWidth
              label={
                trialAlreadyUsed
                  ? 'Next'
                  : `Try ${planObjToUpsell.name} 14 days for free`
              }
              onClick={() => {
                ampli.upsellModalPlanSelected({
                  planId: planObjToUpsell.name,
                  isOnboarding: false,
                });

                setChooseSubscription(true);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default inject('modalStore', 'organisationStore')(observer(UpsellModal));
