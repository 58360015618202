import './SharedFeatureRequestsRoadmap.scss';
import { inject, observer } from 'mobx-react';
import SharedBoard from 'components/SharedBoard/SharedBoard';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';

interface SharedFeatureRequestsRoadmapProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
}

const SharedFeatureRequestsRoadmap = ({
  sharedBoardStorePublic,
}: SharedFeatureRequestsRoadmapProps) => {
  if (!sharedBoardStorePublic?.currentProject) {
    return null;
  }

  return (
    <div className="shared-feature-requests-roadmap">
      <SharedBoard />
    </div>
  );
};
export default inject('sharedBoardStorePublic')(
  observer(SharedFeatureRequestsRoadmap),
);
