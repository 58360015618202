export const contentWithVariables = (content: string, variables: any) => {
    if (!content) {
        return "";
    }

    var innerContent = content;
    let keys = Object.keys(variables);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        innerContent = innerContent.replaceAll(`{{${key}}}`, variables[key]);
    }

    return innerContent;
};