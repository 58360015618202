import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

interface CheckOutFormProps {
  onSuccess: any;
  onError: any;
  onReady: any;
  submit: any;
}

const CheckoutForm = ({
  onSuccess,
  onError,
  onReady,
  submit,
}: CheckOutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
  }, [submit]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      onError();
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    } as any);

    if (error) {
      onError();
      toast.error('Could not verify billing details');
    } else {
      onSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        onReady={() => {
          onReady();
        }}
      />
    </form>
  );
};

export default CheckoutForm;
