import React, { useEffect, useState } from 'react';
import './FeedbackPriorityFilter.scss';
import Select from 'react-select';

import { BugPriorities } from '../../models/Bug';

interface BugAssignUserProps {
  value?: any;
  onValueChanged?: (value?: string) => void;
}

const FeedbackPriorityFilter = ({
  value,
  onValueChanged,
}: BugAssignUserProps) => {
  const [data, setData] = useState(undefined as any);

  useEffect(() => {
    if (value) {
      setData(BugPriorities[value]);
    } else {
      setData(undefined);
    }
  }, [value]);

  const filterOptions = (candidate, input) => {
    if (input) {
      if (candidate.label && input && candidate.label.toLowerCase().includes(input.toLowerCase()))
        return true;
      if (
        Array.isArray(data) &&
        data.some((opt) => {
          if (opt.value === candidate.value) return true;
          return false;
        })
      )
      return true;
    }
    return true;
  };

  return (
    <Select
      className="dropdown-selection feedback-priority-filter"
      classNamePrefix="dropdown-selection"
      defaultValue={data}
      value={data}
      placeholder="Filter by priority"
      isSearchable={false}
      isClearable
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      filterOption={filterOptions}
      components={{
        SingleValue: (option: any) => {
          return (
            <div className="bug-prio-item">
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.title}</span>
            </div>
          );
        },
        Option: (option: any) => {
          return (
            <div
              className="option option-list-item bug-prio-item"
              onClick={() => {
                if (option.selectOption) {
                  option.selectOption(option.data);
                }
              }}
            >
              <div
                className="bug-prio-item-dot"
                style={{
                  backgroundColor: option.data.color,
                }}
              />
              <span>{option.data.title}</span>
            </div>
          );
        },
      }}
      onChange={(selected: any) => {
        setData(selected);
        if (onValueChanged) {
          onValueChanged(selected ? selected.value : undefined);
        }
      }}
      options={Object.values(BugPriorities)}
    />
  );
};

export default FeedbackPriorityFilter;
