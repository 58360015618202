import classNames from 'classnames';
import React, { useState } from 'react';
import { BlockPicker } from 'react-color';
import './ColorPicker.scss';

interface ColorPickerProps {
  color: string;
  onChange: any;
  className?: any;
}

const ColorPicker = ({ color, onChange, className }: ColorPickerProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleOnChange = (event) => {
    setDisplayColorPicker(false);
    onChange(event);
  };

  const colorClassName = classNames(
    {
      'color-preview-container': true,
    },
    className,
  );

  return (
    <div className={colorClassName}>
      <div
        className="color-preview"
        style={{ background: color }}
        onClick={handleClick}
      />
      {displayColorPicker ? (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <BlockPicker color={color} onChange={handleOnChange} triangle="hide" />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
