import LinkButton from 'components/LinkButton/LinkButton';
import ProjectArchive from 'components/ProjectArchive/ProjectArchive';
import { getIconForFeedbackType } from 'components/Sidenav/SidenavElement/SidenavElement';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { useEffect } from 'react';
import {
  Route, Routes, useLocation, useNavigate, useParams
} from 'react-router';
import { toast } from 'react-toastify';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import { ReactComponent as KeyIcon } from '../../../assets/icons/np_library_3018670_FFFFFF.svg';
import ProjectFeatureRequestSettings from '../ProjectFeatureRequests/ProjectFeatureRequestSettings';
import ProjectFeedback from '../ProjectFeedback/ProjectFeedback';
import ProjectStatistics from '../ProjectStatistics/ProjectStatistics';
import ProjectStats from '../ProjectStats/ProjectStats';
import './ProjectInbound.scss';

interface ProjectInboundProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function ProjectInbound({ projectStore, modalStore }: ProjectInboundProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      if (projectId) {
        await projectStore!.loadProjectById(projectId);
      }
    } catch (err) {
      toast.error('Could not load project');
      navigate('/dashboard');
    }
  };

  if (!currentProject) {
    return <></>;
  }

  const sidebarCount = (projectType) => {
    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (projectStore?.unreadItems[itemKey] && projectStore?.unreadItems[itemKey].type === projectType) {
        unreadCount++;
        if (unreadCount > 9) {
          return 10;
        }
      }
    }

    return unreadCount;
  };

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 3 && parts[3] === path) {
        return true;
      }
    }

    return false;
  };

  const updateProjectTypes = () => {
    runInAction(() => {
      projectStore!.updateProject(
        projectStore!.currentProject!.id,
        {
          projectTypes: projectStore!.currentProject!.projectTypes,
        },
        false,
      );
    });
  };

  const addBoard = (boardName) => {
    const baseName = boardName.toLowerCase().replace(/[^a-z]/g, '');

    if (!baseName || baseName === '') {
      toast.error('Board name must be at least contain one character.');
      return;
    }

    if (
      projectStore!.currentProject!.projectTypes.find(
        (board) => board.name.toLowerCase() === baseName,
      )
    ) {
      toast.error('Board name characters must be unique.');
      return;
    }

    runInAction(() => {
      projectStore!.currentProject!.projectTypes.push({
        icon: 'GOAL',
        name: boardName,
        menuName: boardName,
        path: baseName,
        type: baseName.toUpperCase(),
        renderType: 'BOARD',
        receievedEmailTitle: `Feedback #{{feedbackId}} received`,
        receievedEmailDescription: {
          blocks: [
            {
              type: 'paragraph',
              data: {
                text: `Hey {{receiverName}},`,
              },
            },
            {
              type: 'paragraph',
              data: {
                text: `Thank you for writing to us. We have received your request and our support team will be in touch with you shortly. If something pops up until then, simply reply to this email.`,
              },
            },
            {
              type: 'paragraph',
              data: {
                text: `Thanks again,<br>Your {{companyName}} team`,
              },
            },
          ],
        },
        options: {
          possibleLanes: [
            {
              title: 'Open',
              key: 'OPEN',
            },
            {
              title: 'In progress',
              key: 'INPROGRESS',
            },
            {
              title: 'To test',
              key: 'TOTEST',
            },
            {
              title: 'Done',
              key: 'DONE',
            },
          ],
        },
        selectable: true,
        default: false,
      });
      updateProjectTypes();
    });
  };

  return (
    <SidenavContainer className="sidenav-container--small">
      <SubSidenav title="Feedback">
        {currentProject.projectTypes.map((projectType) => {
          if (projectType.type === 'RATING') {
            return null;
          }

          if (projectType.type === 'CRASH' && !projectStore.hasCrashReports) {
            return null;
          }

          if (projectType.type === 'INQUIRY') {
            return null;
          }

          if (projectType.type === 'FEATURE_REQUEST') {
            return null;
          }

          return (
            <SimpleSidenavElement
              onClick={() => {
                navigate(`/projects/${projectId}/${projectType.path}`);
              }}
              Icon={getIconForFeedbackType(projectType.icon)}
              isActive={isActive(projectType.path)}
              key={projectType.path}
              name={projectType.menuName}
              notificationCount={sidebarCount(projectType?.type)}
            />
          );
        })}
        {projectStore?.isProjectAdmin && (
          <LinkButton
            className="add-new-board-button"
            icon="plus"
            noBorder
            iconSideRight={false}
            label="Add board"
            onClick={() => {
              if (projectStore!.isFeatureInPlan(Feature.CUSTOM_FEEDBACK_TYPE)) {
                Swal.fire({
                  title: 'Create a new board',
                  text: 'Please enter a name for the board.',
                  input: 'text',
                  showCancelButton: true,
                }).then((result) => {
                  if (result.value && result.value.length > 0) {
                    addBoard(result.value);
                  }
                });
              } else {
                modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
                  projectId,
                  type: 'custom_feedback_type',
                });
              }
            }}
          />
        )}
        <div className="subnav-spacer-top mt-20" />
        <SimpleSidenavElement
          name="Archive & spam"
          onClick={() => {
            navigate(`/projects/${projectId}/archive`);
          }}
          faIcon='box-archive'
          isActive={isActive('archive')}
        />
        <SimpleSidenavElement
          name="Insights"
          onClick={() => {
            navigate(`/projects/${projectId}/insights`);
          }}
          faIcon='gauge'
          isActive={isActive('insights')}
        />
      </SubSidenav>
      <Routes>
        <Route path="/insights" element={<ProjectStats />} />
        <Route path="/dashboard" element={<ProjectStatistics />} />
        <Route path="/dashboard/:shareToken" element={<ProjectStatistics />} />
        <Route path="/archive" element={<ProjectArchive />} />
        <Route path="/archive/:shareToken" element={<ProjectArchive />} />
        <Route path="/featurerequests/settings" element={<ProjectFeatureRequestSettings />} />
        <Route path="/:feedbackTypePath" element={<ProjectFeedback />} />
        <Route
          path="/:feedbackTypePath/:shareToken"
          element={<ProjectFeedback />}
        />
      </Routes>
    </SidenavContainer>
  );
}

export default inject('projectStore', 'modalStore')(observer(ProjectInbound));
