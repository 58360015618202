import React from 'react';
import classNames from 'classnames';
import './ChangePasswordButton.scss';
import LinkButton from 'components/LinkButton/LinkButton';

interface ChangePasswordButtonProps {
  className?: any;
  label?: string;
  changePassword: () => void;
}

const ChangePasswordButton = ({
  className,
  label,
  changePassword,
}: ChangePasswordButtonProps) => {
  const changePasswordButtonContainerClassName = classNames(
    {
      'field-container': true,
    },
    className,
  );

  return (
    <div className={changePasswordButtonContainerClassName}>
      {label && <div className="input-label">{label}</div>}
      <LinkButton onClick={changePassword} label="Change password" />
    </div>
  );
};

export default ChangePasswordButton;
