import { makeAutoObservable, runInAction } from 'mobx';
import { Outbound } from 'models/Outbound';
import {
  getSkipAndLimitFromPage,
  PaginationDataList,
} from 'models/PaginationDataList';
import { toast } from 'react-toastify';
import {
  askOutboundAIQuestion,
  createOutboundRule,
  exportOutboundResponses,
  getFixedAudience,
  getOutboundRule,
  getOutboundRuleRecipients,
  getOutboundRuleResponses,
  getOutboundRules,
  getOutboundRuleStats,
  markOutboundRuleResponsesAsRead,
  removeOutboundRule,
  updateOutboundRule,
} from 'services/OutboundHttpService';
import { getProjectSurveyUnreadStatus } from 'services/ProjectHttpService';
import WebSocketMessage from 'services/WebSocketMessage';
import { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import Swal from 'sweetalert2';

const itemsInPage = 50;

// eslint-disable-next-line import/prefer-default-export
export class OutboundStore implements WebSocketMessage {
  outboundRules: Outbound[] = [];
  outboundRule?: Outbound = undefined;
  listOfFixedRecievers: any[] = [];
  fixedRecieversCount: number = 0;
  fixedRecieversLimitCount: number = 0;
  loadingFixedRecievers = false;
  fixedLimitReached = false;
  loadingOutboundRules = false;
  loadingOutboundRule = false;
  loadingAiResponse = false;
  aiPlanFailed = false;
  aiResponse: any = {};
  currentProjectSurveyUnreadCount: any = {};
  outboundRuleStats = {
    sent: 0,
  };
  unreadResponses = 0;
  outboundRuleResponses: any[] = [];
  outboundRecipientsDataList: PaginationDataList<any> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };
  loadingOutboundRuleResponses = false;
  loadingOutboundRuleResponsesUnreadUpdate = false;
  stores: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  resetOutboundFixedRecievers = () => {
    this.listOfFixedRecievers = [];
    this.fixedRecieversCount = 0;
    this.fixedRecieversLimitCount = 0;
    this.loadingFixedRecievers = false;
    this.fixedLimitReached = false;
  };

  loadingOutboundRecipients = (loading: boolean) => {
    this.loadingFixedRecievers = loading;
  };

  setOutboundRule = (outboundRule) => {
    this.outboundRule = outboundRule;
    this.resetOutboundFixedRecievers();
  };

  setLoadingOutboundRule = (loadingOutboundRule) => {
    this.loadingOutboundRule = loadingOutboundRule;
  };

  async loadOutboundRule(id: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setOutboundRule(undefined);
    this.setLoadingOutboundRule(true);

    try {
      const response = await getOutboundRule(
        this.stores.projectStore.currentProject.id,
        id,
      );
      this.setOutboundRule(response.data);
    } catch (exp) {
      toast.error('Could not load outbound rule.');
    }

    this.setLoadingOutboundRule(false);
  }

  setLoadingOutboundRuleResponses = (loadingOutboundRuleResponses) => {
    this.loadingOutboundRuleResponses = loadingOutboundRuleResponses;
  };

  setLoadingOutboundRuleResponsesUnreadUpdate = (
    loadingOutboundRuleResponsesUnreadUpdate,
  ) => {
    this.loadingOutboundRuleResponsesUnreadUpdate =
      loadingOutboundRuleResponsesUnreadUpdate;
  };

  setOutboundRuleResponses = (outboundRuleResponses) => {
    this.outboundRuleResponses = outboundRuleResponses;

    var notificationsUnreadCount = 0;
    if (outboundRuleResponses && outboundRuleResponses.length > 0) {
      for (var i = 0; i < outboundRuleResponses.length; i++) {
        if (outboundRuleResponses[i].notificationsUnread) {
          notificationsUnreadCount++;
        }
      }
    }
    this.unreadResponses = notificationsUnreadCount;
  };

  setOutboundRuleStats = (outboundRuleStats) => {
    this.outboundRuleStats = outboundRuleStats;
  };

  async loadOutboundRuleStats(id: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      const response = await getOutboundRuleStats(
        this.stores.projectStore.currentProject.id,
        id,
      );
      this.setOutboundRuleStats(response.data);
    } catch (exp) {
      toast.error('Could not load outbound rule.');
    }
  }

  async markOutboundRuleResponsesAsRead(id: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setLoadingOutboundRuleResponsesUnreadUpdate(true);

    try {
      await markOutboundRuleResponsesAsRead(
        this.stores.projectStore.currentProject.id,
        id,
      );

      runInAction(() => {
        this.unreadResponses = 0;
        if (
          this.currentProjectSurveyUnreadCount &&
          this.currentProjectSurveyUnreadCount[id]
        ) {
          this.currentProjectSurveyUnreadCount[id] = 0;
        }

        for (var i = 0; i < this.outboundRuleResponses.length; i++) {
          this.outboundRuleResponses[i].notificationsUnread = false;
        }
        this.outboundRuleResponses = [...this.outboundRuleResponses];
      });
    } catch (exp) {
      toast.error('Could not load outbound rule.');
    }

    this.setLoadingOutboundRuleResponsesUnreadUpdate(false);
  }

  async queryFixedRecievers(
    outboundId: string,
    conditions: any[],
    targetAudience: string,
  ) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.listOfFixedRecievers = [];
    this.fixedRecieversCount = 0;
    this.fixedRecieversLimitCount = 0;
    this.loadingFixedRecievers = true;
    this.fixedLimitReached = false;

    try {
      const response = await getFixedAudience(
        this.stores.projectStore.currentProject.id,
        outboundId,
        conditions,
        targetAudience,
      );
      runInAction(() => {
        if (response.data) {
          this.listOfFixedRecievers = response.data.sample;
          this.fixedRecieversCount = response.data.count;
          this.fixedRecieversLimitCount = response.data.limit;
          this.fixedLimitReached = response.data.limitReached;
          this.loadingFixedRecievers = false;
        }
      });
    } catch (error) {
      this.loadingFixedRecievers = false;
    }
  }

  async askOutboundAIQuestion(outboundId: string, question: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.aiPlanFailed = false;
    this.loadingAiResponse = true;

    try {
      const response = await askOutboundAIQuestion(
        this.stores.projectStore.currentProject.id,
        outboundId,
        question,
      );
      runInAction(() => {
        this.aiResponse = response.data;
      });
    } catch (error) {
      if ((error as any)?.response.status === 408) {
        runInAction(() => {
          this.aiPlanFailed = true;
        });
      } else {
        if ((error as any)?.response?.data?.errors?.length > 0) {
          Swal.fire({
            text: (error as any)?.response.data.errors[0].message,
            showCancelButton: false,
            confirmButtonText: `Ok`,
          });
        } else {
          toast.error(
            'Could not summarize survey results. Please try again later.',
          );
        }
      }
    }

    runInAction(() => {
      this.loadingAiResponse = false;
    });
  }

  async loadOutboundRuleResponses(id: string) {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.unreadResponses = 0;
    this.setOutboundRuleResponses([]);
    this.setLoadingOutboundRuleResponses(true);

    try {
      const response = await getOutboundRuleResponses(
        this.stores.projectStore.currentProject.id,
        id,
      );
      this.setOutboundRuleResponses(response.data);
    } catch (exp) {
      toast.error('Could not load outbound rule.');
    }

    this.setLoadingOutboundRuleResponses(false);
  }

  /**
   * Sets the outbound rules
   * @param outboundRules
   */
  setOutboundRules = (outboundRules) => {
    this.outboundRules = outboundRules;
  };

  /**
   * Sets the loading outbound rules flag
   * @param loadingOutboundRules
   */
  setLoadingOutboundRules = (loadingOutboundRules) => {
    this.loadingOutboundRules = loadingOutboundRules;
  };

  async loadOutboundRules() {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    this.setLoadingOutboundRules(true);

    try {
      const response = await getOutboundRules(
        this.stores.projectStore.currentProject.id,
      );
      this.setOutboundRules(response.data);
    } catch (exp) {
      toast.error('Could not load outbound rules.');
    }

    this.setLoadingOutboundRules(false);
  }

  onEvent = (event: string, data: any) => {
    if (!data) {
      return;
    }

    if (event === WEBSOCKET_EVENTS.BUG_UPDATED) {
      this.locallyUpdateBug(data.id, data);
    }
  };

  locallyUpdateBug(bugId, data) {
    if (this.outboundRuleResponses && this.outboundRuleResponses.length > 0) {
      const bugIndex = this.outboundRuleResponses.findIndex(
        (x) => x.id === bugId,
      );
      if (
        bugIndex >= 0 &&
        this.outboundRuleResponses[bugIndex] &&
        this.outboundRuleResponses[bugIndex].outbound
      ) {
        runInAction(() => {
          this.outboundRuleResponses[bugIndex] = {
            ...this.outboundRuleResponses[bugIndex],
            ...data,
          };
        });

        // Decrement.
        var outboundUnreadCountCopy = JSON.parse(
          JSON.stringify(this.currentProjectSurveyUnreadCount),
        );
        if (
          outboundUnreadCountCopy &&
          outboundUnreadCountCopy[this.outboundRuleResponses[bugIndex].outbound]
        ) {
          outboundUnreadCountCopy[
            this.outboundRuleResponses[bugIndex].outbound
          ] -= 1;

          runInAction(() => {
            this.currentProjectSurveyUnreadCount = outboundUnreadCountCopy;
          });
        }

        runInAction(() => {
          this.unreadResponses = this.unreadResponses - 1;
          this.outboundRuleResponses = JSON.parse(
            JSON.stringify(this.outboundRuleResponses),
          );
        });
      }
    }
  }

  removeOutboundRule = async (id) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    await removeOutboundRule(this.stores.projectStore.currentProject.id, id);

    this.setOutboundRules([]);
    this.setOutboundRule(undefined);
  };

  getProjectSurveyUnreadStatus = async (id: string) => {
    try {
      const response = await getProjectSurveyUnreadStatus(id);
      if (response.status === 200) {
        runInAction(() => {
          this.currentProjectSurveyUnreadCount = response.data;
        });
      }
    } catch (exp) {}
  };

  createOutboundRule = async (data) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    const outboundRule = await createOutboundRule(
      this.stores.projectStore.currentProject.id,
      data,
    );

    this.setOutboundRule(outboundRule.data);

    this.stores.navigate(
      `/projects/${this.stores.projectStore.currentProject.id}/outbound/${outboundRule.data.id}/edit`,
    );

    this.loadOutboundRules();
  };

  updateOutboundRule = async (id, data) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      const updatedOutboundRule = await updateOutboundRule(
        this.stores.projectStore.currentProject.id,
        id,
        data,
      );
      this.setOutboundRule(updatedOutboundRule.data);
      this.loadOutboundRules();

      if (updatedOutboundRule.data?.sent) {
        toast.success('Sent 📬');
      } else {
        toast.success('Saved 🎉');
      }
    } catch (exp) {
      toast.error('Saving failed 😬');
    }
  };

  exportOutboundResponses = async (id) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      const response = await exportOutboundResponses(
        this.stores.projectStore.currentProject.id,
        id,
      );

      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
        response.data,
      )}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${this.outboundRule?.name
        .replace(/\s/g, '_')
        .toLowerCase()}.csv`;
      hiddenElement.click();
    } catch (exp) {
      toast.error('Could not export outbound responses.');
    }
  };

  getOutboundRecipients = async (args: {
    outboundId: string;
    loadMore?: boolean;
  }) => {
    if (
      this.outboundRecipientsDataList.isLoading ||
      !this.stores.projectStore.currentProject
    ) {
      return;
    }

    try {
      this.outboundRecipientsDataList.isLoading = true;

      if (args.loadMore) {
        this.outboundRecipientsDataList.pageIndex += 1;
      } else {
        this.outboundRecipientsDataList.pageIndex = 0;
        this.outboundRecipientsDataList.data = [];
      }

      const response = await getOutboundRuleRecipients(
        this.stores.projectStore.currentProject.id,
        args.outboundId,
        getSkipAndLimitFromPage({
          pageIndex: this.outboundRecipientsDataList.pageIndex,
          itemsInPage,
        }),
      );

      if (response.status === 200) {
        runInAction(() => {
          this.outboundRecipientsDataList.data = [
            ...this.outboundRecipientsDataList.data,
            ...response.data,
          ];
        });
      }

      this.outboundRecipientsDataList.isLoading = false;
    } catch (_) {
      this.outboundRecipientsDataList.isLoading = false;
    }
  };

  refreshData = () => {
    this.loadOutboundRules();

    /*const id = this.outboundRule?.id;
    if (this.outboundRule && id) {
      this.loadOutboundRule(id);
      this.loadOutboundRuleResponses(id);
    }*/
  };
}
