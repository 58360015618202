import React from 'react';
import moment from 'moment';
import ReactJson from 'react-json-view';
import eventIcon from '../../../assets/icons/np_actions_2010719_000000.svg';
import './EventLogItem.scss';

const EventLogItem = ({ log, index }: any) => {
  const formatDate = (date: string) => {
    const formatedDate = moment(date).format('HH:mm:ss');
    return formatedDate;
  };

  return (
    <div className="event-log-item" key={index}>
      <div className="log-count">
        <img src={eventIcon} className="reversed-img" alt="event log" />
        <div>{formatDate(log.date)}</div>
      </div>
      <div className="log-data">
        <div className="log-url">{log.name ?? '--'}</div>
        <div className="log-extended">
          <div className="log-data-details">
            <ReactJson
              theme={
                localStorage.getItem('theme') === 'dark'
                  ? 'brewer'
                  : 'shapeshifter:inverted'
              }
              src={log.data}
              style={{
                background: 'transparent',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventLogItem;
