import React, { CSSProperties } from 'react';

const MdxLineBreak = (props: any) => {
  const style: CSSProperties = {
    // eslint-disable-next-line react/destructuring-assignment
    height: props.children ? `${props.children}px` : '0px',
  };

  return <div style={style} />;
};

export default MdxLineBreak;
