import React from 'react';
import Confetti from 'react-dom-confetti';

interface ConfettiProps {
  showConfetti?: boolean;
}

const ConfettiCanon = ({ showConfetti = false }: ConfettiProps) => {
  const config = {
    angle: 90,
    spread: 70,
    startVelocity: 16,
    elementCount: 20,
    dragFriction: 0.12,
    duration: 2500,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  return <Confetti active={showConfetti} config={config} />;
};

export default ConfettiCanon;
