import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';

interface PushNotificationSettingsProps {
  projectStore?: ProjectStore;
}

const PushNotificationSettings = ({
  projectStore,
}: PushNotificationSettingsProps) => {
  const currentProject = projectStore?.currentProject;

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);

    await projectStore!.updateProject(currentProject!.id, {
      fcmConfig: currentProject!.fcmConfig,
    });

    setTimeout(() => {
      projectStore!.refreshBugsForCurrentProject();
    }, 500);

    setIsLoading(false);
  };

  return (
    <PageContainer>
      <PageHeadLine title="Push Notifications" />
      <PageContent hasTitle isMediumBoxed>
        <div className="options-group">
          <div className="options-group-header">Configuration</div>
          <TextInput
            className="mb-15"
            type="password"
            label="Server key"
            placeholder="Server key"
            initalValue={getProperty(currentProject, 'fcmConfig.serverKey')}
            error=""
            onChange={(value) => {
              runInAction(() => {
                setProperty(currentProject, 'fcmConfig.serverKey', value);
              });
            }}
            required={false}
          />
          <PrimaryButton
            onClick={onSubmit}
            isLoading={isLoading}
            className="save-button"
            label="Save"
          />
          <InfoBox className='mt-30'>
            Learn how to setup push notifications for your project in <a href="https://docs.gleap.io/javascript/push-notifications">our docs</a>.
          </InfoBox>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(PushNotificationSettings));
