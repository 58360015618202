import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Youtube from '@tiptap/extension-youtube';
import { generateText } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { CustomLink } from 'components/Editors/RichTextEditor/extensions/CustomLink';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  Action,
  ActionFlow,
  ActionFlowNode,
  ActionTypes,
  Button,
  ButtonAction,
  Condition,
  ConditionChild,
} from 'models/Bot';
import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import Iframe from 'services/Iframe';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import BotActionAddOptions, {
  botActionTypes,
} from '../BotActionAddOptions/BotActionAddOptions';
import classNames from 'classnames';
import './ActionEditor.scss';
import { operatorOptions } from 'components/BotConditionTag/BotConditionTag';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';

interface ActionEditorProps {
  data: ActionFlowNode;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const actionIsEndType = (action: Action) => {
  return (
    action.type === ActionTypes.BUTTONS ||
    action.type === ActionTypes.CONDITION ||
    action.type === ActionTypes.CREATE_TICKET ||
    action.type === ActionTypes.ANSWERBOTFLOW ||
    action.type === ActionTypes.BOTFLOW ||
    action.type === ActionTypes.FEEDBACKFLOW ||
    action.type === ActionTypes.ACTIONFLOW ||
    action.type === ActionTypes.LINK
  );
};

const ActionEditor = ({
  data,
  modalStore,
  projectStore,
}: ActionEditorProps) => {
  const reactFlow = useReactFlow();
  const actions = data?.actions ?? [];
  const hasEndAction = actions.find((action) => actionIsEndType(action));
  const hasForwardAction = actions.find(
    (action) => action.type === ActionTypes.BUTTONS,
  );

  const currentLang = projectStore?.currentLanguage ?? 'en';

  const [toggleState, setToggleState] = useState(false);
  const [showAddQuestion, setShowAddQuestion] = useState(false);

  const openActionEditor = (action: Action) => {
    modalStore?.openModal(MODALTYPE.ACTIONEDITOR, {
      action,
    });
  };

  // projectStore!.configuratorLang

  const _buildTypeButtons = (action: Action) => {
    return (
      <div className="button-actions">
        {(action as Button).buttons?.map(
          (button: ButtonAction, index: number) => {
            return (
              <div className="button-action" key={index}>
                <input
                  className="button-input"
                  value={getProperty(button, `text.localized.${currentLang}`)}
                  onChange={(event) => {
                    setProperty(
                      button,
                      `text.localized.${currentLang}`,
                      event.target.value,
                    );
                  }}
                />
                <Handle
                  className="button-handle"
                  type="source"
                  position={Position.Right}
                  id={`${data.id}-${index}`}
                  onClick={() => {
                    const actionFlowNode: ActionFlowNode = {
                      id: `af-${Math.floor(Date.now() * 1000)}`,
                      type: 'action',
                      nodeType: 'defaultAction',
                      actions: [],
                      position: {
                        x: data?.position?.x ? data.position.x + 500 : 500,
                        y: data?.position?.y ?? 0,
                      },
                    };

                    (button.action as ActionFlow) = {
                      type: ActionTypes.ACTIONFLOW,
                      actionFlow: actionFlowNode.id,
                      action: 0,
                    };

                    projectStore?.addActionFlowNode(actionFlowNode);
                  }}
                >
                  {button.action && button.action.type ? (
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                  ) : (
                    <i className="icon fa-sharp fa-solid fa-plus" />
                  )}
                </Handle>
              </div>
            );
          },
        )}
        <div className="add-button-action">{_buildAddButtonAction(action)}</div>
      </div>
    );
  };

  const _getConditionLabel = (condition: ConditionChild) => {
    let label: any[] = [];

    if (
      condition.predicates == null ||
      condition.predicates.predicates?.length === 0
    ) {
      return 'Edit condition';
    }

    condition.predicates!.predicates!.forEach((predicate, index) => {
      if (predicate.predicates == null || predicate.predicates.length === 0) {
        return;
      }

      predicate.predicates.forEach((childPredicate, childIndex) => {
        // Data type
        if (childPredicate.dataType != null) {
          label.push(childPredicate.dataType);
        }

        // Attribute
        if (childPredicate.attribute != null) {
          label.push(` ${childPredicate.attribute}`);
        }

        // Operator
        if (childPredicate.operator != null) {
          const operatorLabel = operatorOptions.find(
            (option) => option.value === childPredicate.operator,
          )?.label;

          if (operatorLabel != null) {
            label.push(
              <span key="operatorLabel" className="operator-label">
                {' '}
                {operatorLabel}{' '}
              </span>,
            );
          }
        }

        // Value
        if (childPredicate.value != null) {
          label.push(` ${childPredicate.value}`);
        }

        // And/Or
        if (childIndex < predicate.predicates!.length - 1 && predicate.type) {
          label.push(` ${predicate.type} `);
        }

        // Wrap the array of elements in a parent span element
        return <span>{label}</span>;
      });

      // And/Or
      if (
        index < condition.predicates!.predicates!.length - 1 &&
        condition.predicates.type
      ) {
        label.push(` ${condition.predicates.type}`);
      }
    });

    if (label.length === 0) {
      return 'Edit condition';
    }

    return label;
  };

  const _buildTypeCondition = (action: Action) => {
    if (!action.conditions) {
      action.conditions = [];
    }
    return (
      <>
        <div className="conditions-header">
          <div className="icon-container">
            <i className="fa-solid fa-option" />
          </div>
          <div className="conditions-header-title ml-5">Conditions</div>
        </div>
        <div className="condition-actions">
          {(action as Condition).conditions.map(
            (condition: ConditionChild, index: number) => {
              return (
                <div key={index} className="condition-item">
                  <div
                    onClick={() => {
                      openActionEditor({
                        ...condition,
                        type: ActionTypes.CONDITION,
                      });
                    }}
                  >
                    {_getConditionLabel(condition)}
                    <div
                      className="condition-hover-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        runInAction(() => {
                          (action as Condition).conditions.splice(index, 1);
                        });
                      }}
                    >
                      <i className="fa-solid fa-trash" />
                    </div>
                  </div>
                  <Handle
                    className="condition-handle"
                    type="source"
                    position={Position.Right}
                    id={`${data.id}-${index}`}
                    onClick={() => {
                      const actionFlowNode: ActionFlowNode = {
                        id: `af-${Math.floor(Date.now() * 1000)}`,
                        type: 'action',
                        nodeType: 'defaultAction',
                        actions: [],
                        position: {
                          x: data?.position?.x ? data.position.x + 500 : 500,
                          y: data?.position?.y ?? 0,
                        },
                      };

                      condition.actionFlow = actionFlowNode.id;
                      condition.action = 0;

                      projectStore?.addActionFlowNode(actionFlowNode);
                    }}
                  >
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                  </Handle>
                </div>
              );
            },
          )}
          {_buildAddConditionAction(action)}
        </div>
        <div className="condition-item-else">
          ELSE
          <div className="else-stroke" />
          <Handle
            className="condition-handle"
            type="source"
            position={Position.Right}
            id={`${data.id}-else`}
            onClick={() => {
              const actionFlowNode: ActionFlowNode = {
                id: `af-${Math.floor(Date.now() * 1000)}-else`,
                type: 'action',
                nodeType: 'defaultAction',
                actions: [],
                position: {
                  x: data?.position?.x ? data.position.x + 500 : 500,
                  y: data?.position?.y ?? 0,
                },
              };

              (action as Condition).fallbackAction = {
                type: ActionTypes.ACTIONFLOW,
                actionFlow: actionFlowNode.id,
                action: 0,
              };

              projectStore?.addActionFlowNode(actionFlowNode);
            }}
          >
            <i className="fa-sharp fa-solid fa-arrow-right" />
          </Handle>
        </div>
      </>
    );
  };

  const getContentForType = (action: Action) => {
    if (action.type === ActionTypes.MESSAGE) {
      try {
        var content = generateText(action?.data?.content, [
          StarterKit.configure({ codeBlock: {}, heading: false }),
          Heading.configure({
            levels: [1, 2, 3],
          }),
          Mention,
          Placeholder,
          CustomLink,
          Image,
          TextStyle,
          Youtube.configure({ controls: true }),
          Iframe,
        ]);

        return content;
      } catch (exp) { }
    }

    if (action.type === ActionTypes.TICKETUPDATE_ASSIGN) {
      const user = projectStore?.currentProjectUsers.find(
        (o) => o.id === action?.processingUser,
      );
      if (user) {
        return (
          <div className="action-ticket-assign">
            Assign ticket to
            <UserAvatar
              small
              email={user.email}
              imageUrl={user.profileImageUrl}
            />
            {user.firstName} {user.lastName}
          </div>
        );
      }
    }

    if (action.type === ActionTypes.TICKETUPDATE_TAG) {
      return (
        <div className="action-ticket-tags">
          Tag ticket with{' '}
          {(action?.tags ?? []).map((tag, index) => {
            return (
              <span className="tag" key={index}>
                {tag}
              </span>
            );
          })}
        </div>
      );
    }

    if (action.type === ActionTypes.ANSWERBOTFLOW) {
      return (
        <div className="action-answerbot">
          Pass to answer bot with question{' '}
          <span>
            {getProperty(action, `question.localization.${currentLang}`)}
          </span>
        </div>
      );
    }

    return null;
  };

  const _buildDefaultAction = (action: Action) => {
    const botAction = botActionTypes.find((type) => type.value === action.type);
    if (!botAction) return <div>{JSON.stringify(action)}</div>;

    return (
      <div
        className="bot-action-option-item"
        onClick={() => {
          openActionEditor(action);
        }}
      >
        <div
          className="bot-action-option-item-icon"
          style={{
            backgroundColor: botAction.color,
          }}
        >
          <i className={`fa-solid fa-${botAction.icon}`}></i>
        </div>
        <div className="bot-action-option-item-title">
          {getContentForType(action) || botAction.label}
        </div>
      </div>
    );
  };

  const _getActionComponent = (action: Action) => {
    switch (action.type) {
      case ActionTypes.BUTTONS:
        return _buildTypeButtons(action);

      case ActionTypes.CONDITION:
        return _buildTypeCondition(action);

      default:
        return _buildDefaultAction(action);
    }
  };
  const _buildAddButtonAction = (action) => {
    return (
      <div className="add-action-container-outer">
        <div
          className="add-action-container mt-10 mb-20"
          onClick={() => {
            runInAction(() => {
              if (!(action as Button).buttons) {
                (action as Button).buttons = [];
              }
              (action as Button).buttons.push({
                text: {
                  localized: { [currentLang]: 'New button option' },
                },
                action: {} as any,
              });
            });
            setToggleState(!toggleState);
          }}
        >
          <div className="line" />
          <i className="icon fa-solid fa-circle-plus" />
          <div className="line" />
        </div>
      </div>
    );
  };

  const _buildAddConditionAction = (action) => {
    return (
      <div className="add-action-container-outer ml-20">
        <div
          className="add-action-container mt-10 mb-20"
          onClick={() => {
            runInAction(() => {
              if (!(action as Condition).conditions) {
                (action as Condition).conditions = [];
              }
              (action as Condition).conditions.push({
                type: ActionTypes.ACTIONFLOW,
                actionFlow: `af-${Math.floor(Date.now() * 1000)}`,
                action: 0,
                predicates: {
                  type: 'and',
                  predicates: [
                    {
                      type: 'and',
                      predicates: [{}],
                    } as any,
                  ],
                },
              });
            });
            setToggleState(!toggleState);
          }}
        >
          <div className="line" />
          <i className="icon fa-solid fa-circle-plus" />
          <div className="line" />
        </div>
      </div>
    );
  };

  const _buildAddAction = () => {
    return (
      <div className="add-action-container-outer">
        <div
          className="add-action-container mt-10 mb-20"
          onClick={() => {
            setShowAddQuestion(!showAddQuestion);
          }}
        >
          <div className="line" />
          <i className="icon fa-solid fa-circle-plus" />
          <div className="line" />
        </div>
        {showAddQuestion && (
          <BotActionAddOptions
            canShowEndOptions={!hasEndAction}
            onBotActionAdded={(type) => {
              var action: any = {
                type,
              };

              // Default buttons.
              if (type === ActionTypes.BUTTONS) {
                action.buttons = [
                  {
                    text: {
                      localized: { [currentLang]: 'New button option' },
                    },
                  },
                ];
              }

              // Default input for rating.
              if (type === ActionTypes.RATECONVERSATION) {
                action.npsType = 'emoji';
                action.title = {
                  localized: {
                    [currentLang]: 'How was your conversation?',
                  },
                };
              }

              // Default input type.
              if (type === ActionTypes.INPUT) {
                action.inputType = 'email';
                action.actionType = 'email';
                action.title = {
                  localized: {
                    [currentLang]: 'What is your email address?',
                  },
                };
              }

              if (type === ActionTypes.ANSWERBOTFLOW) {
                action.question = {
                  localized: {
                    [currentLang]:
                      "Hey 👋! I'm here to assist you. How can I help you?",
                  },
                };

                action.askForAdditionalHelp = {
                  localized: {
                    [currentLang]:
                      'Simply reply below to ask another question.',
                  },
                };

                action.talkToHuman = {
                  localized: {
                    [currentLang]: 'Talk to our support team',
                  },
                };
              }

              // Default input condition.
              if (type === ActionTypes.CONDITION) {
                action.conditions = [
                  {
                    type: ActionTypes.ACTIONFLOW,
                    actionFlow: `af-${Math.floor(Date.now() * 1000)}`,
                    action: 0,
                    predicates: {
                      type: 'and',
                      predicates: [
                        {
                          type: 'and',
                          predicates: [{}],
                        } as any,
                      ],
                    },
                  },
                ];
              }

              // Feedback flow
              if (type === ActionTypes.FEEDBACKFLOW) {
                // Set bug reporting as default flow.
                action.flow = 'bugreporting';
              }

              runInAction(() => {
                if (hasEndAction) {
                  actions.splice(actions.length - 1, 0, action);
                } else {
                  actions.push(action);
                }
              });

              setShowAddQuestion(false);
            }}
            closeAddOptions={() => {
              setShowAddQuestion(false);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="action-editor-container">
      {data.type !== 'start' && (
        <Handle
          className="action-target"
          type="target"
          position={Position.Left}
        >
          <i className="fa-sharp fa-solid fa-arrow-right" />
        </Handle>
      )}
      {actions.map((action, index) => {
        const actionItemClass = classNames(
          {
            'action-item-container--buttons':
              action.type === ActionTypes.BUTTONS,
            'action-item-container--condition':
              action.type === ActionTypes.CONDITION,
          },
          'action-item-container',
        );

        return (
          <div key={index}>
            {actionIsEndType(action) && _buildAddAction()}
            <div key={index} className={actionItemClass}>
              {_getActionComponent(action)}
              <div
                className="hover-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  runInAction(() => {
                    actions.splice(index, 1);
                  });
                }}
              >
                <i className="fa-solid fa-trash" />
              </div>
            </div>
          </div>
        );
      })}
      {!hasEndAction && _buildAddAction()}
      {!hasForwardAction && (
        <div className="action-editor-container-endtag">
          <span>END</span>
        </div>
      )}
      {data.type === 'start' && (
        <div className="action-editor-container-starttag">
          <span>
            START <i className="fa-solid fa-play" />
          </span>
        </div>
      )}
      {data.type !== 'start' && (
        <div
          className="node-delete-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (reactFlow) {
              reactFlow.deleteElements({
                nodes: [
                  {
                    id: data.id,
                  },
                ],
              });
            }
          }}
        >
          <i className="fa-solid fa-trash" />
        </div>
      )}
    </div>
  );
};

export default inject('modalStore', 'projectStore')(observer(ActionEditor));
