import './SharedBoardHeader.scss';
import 'react-virtualized/styles.css';
import { inject, observer } from 'mobx-react';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';

interface SharedBoardHeaderProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  title: string;
  status: string;
  count?: number;
}

const SharedBoardHeader = ({
  sharedBoardStorePublic,
  status,
  title,
  count = 0,
}: SharedBoardHeaderProps) => {
  const featureRequestState = sharedBoardStorePublic
    ?.getFeatureRequestStates(false)
    .find((x) => {
      return x.key === status;
    });

  return (
    <div
      className="shared-column-header"
      style={{
        borderColor: featureRequestState?.color,
      }}
    >
      <div className="shared-column-header-title">{title}</div>
      <div className="shared-column-header-count">{count}</div>
    </div>
  );
};

export default inject('sharedBoardStorePublic')(observer(SharedBoardHeader));
