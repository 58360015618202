import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './PageContainer.scss';
import { inject } from 'mobx-react';
import { MODALTYPE } from 'stores/private/ModalStore';

const PageContainer = ({
  organisationStore,
  modalStore,
  className,
  children,
}: any) => {
  const [isFreePlan, setIsFreePlan] = useState(false);

  useEffect(() => {
    const currentPlan =
      organisationStore?.currentOrganisation?.subscription?.planID ?? 'free';
    const freePlan =
      currentPlan === 'free' &&
      organisationStore?.isLoadingOrganisation === false &&
      organisationStore?.currentOrganisation;
    setIsFreePlan(freePlan);
  }, [
    organisationStore?.currentOrganisation &&
    organisationStore?.isLoadingOrganisation,
  ]);

  const PageContainerClassNmae = classNames(
    {
      'page-container': true,
    },
    className,
  );

  return (
    <div className={PageContainerClassNmae}>
      {children}
    </div>
  );
};

export default inject('organisationStore', 'modalStore')(PageContainer);
