import AITokenProgress from 'components/AITokenProgress/AITokenProgress';
import InvoiceTable from 'components/InvoiceTable/InvoiceTable';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import MAULimitProgress from 'components/MAULimitProgress/MAULimitProgress';
import NoAdminCard from 'components/NoAdminCard/NoAdminCard';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PriceTable, { plans } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import StripeCustomerDetails from 'components/StripeCustomerDetails/StripeCustomerDetails';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { StripeStore } from 'stores/private/StripeStore';
import Swal from 'sweetalert2';
import './Billing.scss';

interface BillingProps {
  organisationStore?: OrganisationStore;
  stripeStore?: StripeStore;
  modalStore?: ModalStore;
}

const Billing = ({ organisationStore, stripeStore, modalStore }: BillingProps) => {
  const [isLoadingStripePortal, setIsLoadingStripePortal] = useState(false);

  useEffect(() => {
    stripeStore!.getSubscription();
    organisationStore?.getCurrentMAU();
  }, []);

  if (!organisationStore?.currentOrganisation) {
    return <Loading />;
  }

  const currentPlan =
    organisationStore?.currentOrganisation?.subscription?.planID ?? 'free';
  const currentSubscriptionMetaData =
    organisationStore?.currentOrganisation?.subscription?.metadata ?? {};
  const isMetered = organisationStore?.currentOrganisation?.isMetered ?? false;

  const getAppSumoDataRetentionDescription = (data) => {
    if (data === '90') {
      return '3 months';
    }
    if (data === '180') {
      return '6 months';
    }
    if (data === '365') {
      return '12 months';
    }
    if (data === '99999') {
      return 'Unlimited';
    }

    return data;
  };

  const getAppSumoTierName = (data) => {
    if (data === '90') {
      return '1';
    }
    if (data === '180') {
      return '2';
    }
    if (data === '365') {
      return '3';
    }
    if (data === '99999') {
      return '4';
    }

    return data;
  };

  const formatAppSumoNumber = (data) => {
    if (data === '99999') {
      return 'Unlimited';
    }
    return data;
  };

  const getPlanName = (planID: string) => {
    if (planID === 'appsumo') {
      return (
        'AppSumo License Tier ' +
        getAppSumoTierName(currentSubscriptionMetaData.dataretention)
      );
    }

    for (let i = 0; i < plans.starter.prices.length; i++) {
      if (plans.starter.prices.includes(currentPlan)) {
        return 'Hobby';
      }
    }
    for (let i = 0; i < plans.growth.prices.length; i++) {
      if (plans.growth.prices.includes(currentPlan)) {
        return 'Pro';
      }
    }
    for (let i = 0; i < plans.pro.prices.length; i++) {
      if (plans.pro.prices.includes(currentPlan)) {
        return 'Enterprise';
      }
    }
    if (planID === 'free' || planID === 'trial') {
      return 'Free';
    }
    return 'Custom plan';
  };

  const moveToYearlyPlan = () => {
    stripeStore!.upgradeToYearlySubscription();
  }

  const currentMainInterval = () => {
    if (!stripeStore?.invoice || stripeStore?.invoice?.total_excluding_tax === undefined) {
      return null;
    }

    const lineData = stripeStore?.invoice?.lines?.data ?? [];
    const interval = lineData.length > 0 ? lineData[0].plan?.interval : "month";

    return interval;
  }

  const renderManageSubscriptionButton = () => {
    if (
      organisationStore?.currentOrganisation?.subscription?.planID === 'free'
    ) {
      return <></>;
    }

    const currentInterval = currentMainInterval();

    const renderRenewText = () => {
      if (!stripeStore?.subscription?.status) {
        return;
      }

      if (stripeStore?.subscription?.cancel_at) {
        return `Your subscription cancels on ${moment.unix(stripeStore?.subscription?.cancel_at).format('LL')}.`;
      }

      if (stripeStore?.subscription?.status === "trialing") {
        return `Your free trial ends on ${moment.unix(stripeStore?.subscription?.current_period_end).format('LL')}.`;
      }

      return `Your subscription renews on ${moment.unix(stripeStore?.subscription?.current_period_end).format('LL')}.`;
    }

    const currentPlanName = getPlanName(currentPlan);

    const renderBaseSubscriptionNextInvoice = () => {
      if (!stripeStore?.invoice || stripeStore?.invoice?.total_excluding_tax === undefined) {
        return null;
      }

      const nextInvoicePrice = stripeStore?.invoice?.total_excluding_tax / 100;
      const lineData = stripeStore?.invoice?.lines?.data ?? [];
      const interval = lineData.length > 0 ? lineData[0].plan?.interval : "month";

      return (<div className='current-plan-price'>
        Subscription ${nextInvoicePrice.toFixed(2)} / {interval}
      </div>);
    }

    const renderUsageBaseSubscriptionNextInvoice = () => {
      if (!stripeStore?.usageBasedInvoice || stripeStore?.usageBasedInvoice?.total_excluding_tax === undefined) {
        return null;
      }

      const nextInvoicePrice = stripeStore?.usageBasedInvoice?.total_excluding_tax / 100;

      return (<div className='current-plan-price'>
        Additional usage ${nextInvoicePrice.toFixed(2)} (running invoice)
      </div>);
    }

    return (
      <>
        <div className="current-plan">
          {currentPlanName} {!isMetered ? <>(Legacy)</> : null}
          {stripeStore?.subscription?.discount?.coupon ? <span className='discount-tag'>{stripeStore?.subscription?.discount?.coupon?.percent_off}% OFF</span> : null}
        </div>
        {renderBaseSubscriptionNextInvoice()}
        {renderUsageBaseSubscriptionNextInvoice()}
        {stripeStore?.subscription?.status && <div className='current-plan-interval'>{renderRenewText()}</div>}
        <div className='manage-invoice-buttons'>
          {stripeStore?.subscription?.cancel_at ? <>
            <PrimaryButton
              isLoading={stripeStore?.updateSubscriptionLoading}
              onClick={() => {
                stripeStore!.updateSubscription(false);
              }}
              label="Reactivate subscription 🚀"
            />
          </> : <>
            <LinkButton onClick={() => {
              modalStore!.openModal(MODALTYPE.CANCEL_PLAN);
            }} label="Cancel" />
            {currentPlanName === "Hobby" &&
              <PrimaryButton
                className="ml-10"
                isLoading={stripeStore?.updateSubscriptionLoading}
                onClick={() => {
                  stripeStore!.upgradeSubscription();
                }}
                label="Upgrade to Pro 🚀"
              />}
            {currentInterval && currentInterval === 'month' && currentPlanName !== "Hobby" &&
              <PrimaryButton
                className="ml-10"
                isLoading={stripeStore?.updateSubscriptionLoading}
                onClick={() => {
                  moveToYearlyPlan();
                }}
                label={`Save 20% by paying yearly 🚀`}
              />}
            { }
          </>}
        </div>
        <div className='text vat-info'>
          VAT may be applied to all prices.
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (currentPlan === 'free' || currentPlan === 'free-trial') {
      return <PriceTable />;
    }

    const currentPlanName = getPlanName(currentPlan);

    return (
      <>
        <div className='billing-header'>
          <div className="options-group">
            <div className="options-group-header">Current subscription</div>
            <div className="current-plan-container">
              {currentPlan === 'appsumo' ? (
                <>
                  <div className="current-plan mb-20">{currentPlanName}</div>
                  <div className="text">
                    Projects:{' '}
                    {formatAppSumoNumber(currentSubscriptionMetaData.projects)}
                    <br />
                    Data retention:{' '}
                    {getAppSumoDataRetentionDescription(
                      currentSubscriptionMetaData.dataretention,
                    )}
                  </div>
                </>
              ) : renderManageSubscriptionButton()}
            </div>
          </div>
          <div className="options-group">
            <div className="options-group-header">Billing address</div>
            <div className="current-plan-container">
              <StripeCustomerDetails />
              <div className='payment-card-update mt-20'>
                <LinkButton isLoading={isLoadingStripePortal} onClick={() => {
                  setIsLoadingStripePortal(true);
                  stripeStore!.startCustomerPortal();
                }} label="Edit billing address" />
                {!isLoadingStripePortal &&
                  <LinkButton isLoading={isLoadingStripePortal} className="ml-10" onClick={() => {
                    setIsLoadingStripePortal(true);
                    stripeStore!.startCustomerPortal();
                  }} label="Edit payment method" />}
              </div>
            </div>
          </div>
        </div>
        {isMetered &&
          <div className="options-group">
            <div className="options-group-header">People reached</div>
            <div className='mb-20'>
              <MAULimitProgress isHobbyPlan={currentPlanName === "Hobby"} />
            </div>
          </div>}
        <div className="options-group">
          <div className="options-group-header">AI services usage</div>
          {isMetered ? <div className='mb-20'>
            <AITokenProgress />
          </div> :
            <div>
              <div className='mb-20 text'>
                Your current plan doesn't support AI assist. Move to our new plans to unlock access to AI assist 🚀.
              </div>
              {currentPlan === 'appsumo' &&
                <PrimaryButton
                  className="ml-10"
                  isLoading={stripeStore?.updateSubscriptionLoading}
                  onClick={() => {
                    Gleap.startFeedbackFlow("contact");
                  }}
                  label="Contact us 👋"
                />}
            </div>}
        </div>
        <div className='pb-30'>
          <div className="options-group">
            <div className="options-group-header">Invoice history</div>
            <InvoiceTable />
          </div>
        </div>
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Billing" />
      <PageContent hasTitle className="billing" isLargeBoxed={currentPlan !== 'free'}>
        {(!organisationStore?.isOrganisationAdmin) ? (
          <NoAdminCard title="Billing is" />
        ) : (
          renderContent()
        )}
      </PageContent>
    </PageContainer>
  );
}

export default inject('organisationStore', 'stripeStore', 'modalStore')(observer(Billing));
