import React from 'react';
import classNames from 'classnames';
import './TextArea.scss';

interface TextAreaProps {
  placeholder: string;
  inputRef?: any;
  rows: number;
  value: any;
  className?: any;
  labelClassName?: any;
  error: string;
  onEnter?: any;
  name?: string;
  label?: string;
  required?: boolean;
  readonly?: boolean;
  onClick?: any;
}

export const TextArea = ({
  placeholder,
  value,
  className,
  labelClassName,
  rows,
  onEnter,
  error,
  inputRef,
  name,
  label,
  required,
  readonly,
  onClick,
}: TextAreaProps) => {
  const onEnterPressed = (event: any) => {
    if (event.key === 'Enter') {
      if (onEnter) {
        onEnter();
      }
    }
  };

  const renderEditableInput = () => {
    const textAreaEditableClassName = classNames({
      'textarea-gray': true,
      'textarea-error': error !== '',
    });

    return (
      <div className="textarea-container">
        <textarea
          {...inputRef}
          rows={rows}
          className={textAreaEditableClassName}
          name={name}
          placeholder={placeholder}
          defaultValue={value}
          readOnly={readonly}
        />
        {onClick && (
          <div onClick={onClick} className="update-button">
            Update
          </div>
        )}
      </div>
    );
  };

  const textAreaClassName = classNames(
    {
      'field-container': true,
    },
    className,
  );

  const combinedLabelClassName = classNames(
    {
      'input-label': true,
    },
    labelClassName,
  );

  return (
    <div
      className={textAreaClassName}
      onClick={() => {}}
      onKeyPress={(event) => {
        onEnterPressed(event);
      }}
    >
      {label && (
        <div className={combinedLabelClassName}>
          {label} {required && <span className="input-required">*</span>}
        </div>
      )}
      {renderEditableInput()}
    </div>
  );
};
