/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import './ProjectStats.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { BugStore } from 'stores/private/BugStore';
import classNames from 'classnames';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import ExtendedStatCard from 'components/ExtendedStatCard/ExtendedStatCard';
import AIFeedbackSummary from 'components/AIFeedbackSummary/AIFeedbackSummary';
import { Title } from 'components/Title/Title';

interface ProjectStatsProps {
  projectStore?: ProjectStore;
  bugStore?: BugStore;
}

function ProjectStats({ projectStore, bugStore }: ProjectStatsProps) {
  const currentProjectStats = projectStore?.currentProjectStats;

  useEffect(() => {
    projectStore!.getCurrentProjecStats(projectStore?.currentProject?.id);
  }, [projectStore?.currentProject?.id]);

  const containerClassName = classNames({
    'stats-container-inner': true,
  });

  const renderStatistics = () => {
    if (!currentProjectStats) {
      return <></>;
    }

    return (
      <div className={containerClassName}>
        <ExtendedStatCard
          isLoading={projectStore?.loadingStats}
          values={currentProjectStats?.itemAnalytics}
          prevValues={currentProjectStats?.prevItemAnalytics}
          projectTypes={projectStore?.currentProject?.projectTypes}
          className="w-50"
          icon="square-plus"
          label="Items created"
          subLabel="Last 30 days"
          valueSubLabel="created items"
          typeKey={'itemsCreated'}
          showNoData={
            !currentProjectStats?.itemAnalytics?.itemsCreated
          }
        />
        <ExtendedStatCard
          isLoading={projectStore?.loadingStats}
          values={currentProjectStats?.itemAnalytics}
          prevValues={currentProjectStats?.prevItemAnalytics}
          projectTypes={projectStore?.currentProject?.projectTypes}
          className="w-50"
          icon="square-check"
          label="Items completed"
          subLabel="Last 30 days"
          valueSubLabel="completed items"
          typeKey='itemsCompleted'
          showNoData={
            !currentProjectStats?.itemAnalytics?.itemsCompleted
          }
        />
        <ExtendedStatCard
          isLoading={projectStore?.loadingStats}
          values={currentProjectStats?.itemAnalytics}
          prevValues={currentProjectStats?.prevItemAnalytics}
          projectTypes={projectStore?.currentProject?.projectTypes}
          className="w-50"
          icon="gauge-simple-max"
          label="Time to completion"
          averageTypeKey='itemsCompleted'
          subLabel="Last 30 days"
          valueSubLabel="Average time to completion"
          type="timespan"
          typeKey='timeToCompletion'
          showNoData={!currentProjectStats?.itemAnalytics?.itemsCompleted}
        />
        <ExtendedStatCard
          isLoading={projectStore?.loadingStats}
          values={currentProjectStats?.itemAnalytics}
          prevValues={currentProjectStats?.prevItemAnalytics}
          projectTypes={projectStore?.currentProject?.projectTypes}
          className="w-50"
          icon="message-dots"
          label="First reply time"
          averageTypeKey='itemsFirstResponded'
          subLabel="Last 30 days"
          valueSubLabel="Average time until first reply"
          type="timespan"
          typeKey='timeToFirstResponse'
          showNoData={!currentProjectStats?.itemAnalytics?.itemsCreated}
        />
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Insights" tag="BETA" />
      <PageContent hasTitle>
        <div className='inner-stats'>
          <AIFeedbackSummary />
          <div className='inner-block'>
            <Title label='Statistics' className="mb-10" />
          </div>
          {renderStatistics()}
          {!projectStore?.currentProject?.sdkInstalled && (
            <div className="project-not-installed">
              <NotInstalledCard />
            </div>
          )}
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject('projectStore', 'bugStore')(observer(ProjectStats));
