import React from 'react';
import classNames from 'classnames';
import './SizedContainer.scss';

export enum ContainerSizes {
  S = 's',
  M = 'm',
  L = 'l',
  XML = 'xml',
  XL = 'xl',
  XXL = 'xxl',
}

interface SizedContainerProps {
  children: any;
  size: ContainerSizes;
  className?: any;
}

const SizedContainer = ({ children, size, className }: SizedContainerProps) => {
  const contentContainerClassName = classNames(
    'sized-container',
    `sized-container--${size}`,
    className,
  );

  return <div className={contentContainerClassName}>{children}</div>;
};

export default SizedContainer;
