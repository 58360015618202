import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { languages as constLanguages } from 'constants/Languages';
import { runInAction } from 'mobx';
import { processTranslations } from 'pages/private/ProjectWidget/TranslationsWidgetSettings/TranslationsWidgetSettings';

interface ProjectLanguageDropdownProps {
  projectStore?: ProjectStore;
}

const ProjectLanguageDropdown = ({
  projectStore,
}: ProjectLanguageDropdownProps) => {
  const [translation, setTranslations] = useState({} as any);
  const [filteredLanguages, setFilteredLanguages] = useState([] as any);
  const langKeys = Object.keys(translation) ?? [];

  const { flowConfig } = projectStore?.currentProject || {};

  useEffect(() => {
    if (!projectStore?.currentProject) {
      return;
    }

    const processedTranslations = processTranslations(
      projectStore?.currentProject,
      flowConfig,
    );

    if (processedTranslations) {
      setTranslations(processedTranslations);
    }
  }, [projectStore?.currentProject, flowConfig]);

  useEffect(() => {
    let preparedLanguages: any[] = [];

    for (let i = 0; i < langKeys.length; i++) {
      const existingLanguage = constLanguages.find(
        (lang) => lang.code === langKeys[i],
      );
      if (existingLanguage) {
        preparedLanguages.push(existingLanguage);
      } else {
        preparedLanguages.push({
          code: langKeys[i],
          name: langKeys[i].toUpperCase(),
        });
      }
    }

    setFilteredLanguages(preparedLanguages);
  }, [translation]);

  return (
    <SelectDropDown
      showBorder
      items={filteredLanguages}
      selectedItem={filteredLanguages.find(
        (lang) => lang.code === projectStore?.currentLanguage,
      )}
      labelPropertyName="name"
      valuePropertyName="code"
      placeholder="Select language"
      onChange={(value) => {
        runInAction(() => {
          projectStore!.currentLanguage = value.code;
        });
      }}
    />
  );
};

export default inject('projectStore')(observer(ProjectLanguageDropdown));
