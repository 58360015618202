import './NotInstalledCard.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useNavigate } from 'react-router';
import 'rc-slider/assets/index.css';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { ModalStore } from 'stores/private/ModalStore';
import InstallSDK from 'assets/icons/integrations.png';

interface NotInstalledCardProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  className?: string;
  title?: string;
  description?: string;
}

function NotInstalledCard({
  projectStore,
  modalStore,
  className,
  title = "Gleap widget not installed",
  description = 'To make the most out of Gleap, please install the Gleap widget.',
}: NotInstalledCardProps) {
  const navigate = useNavigate();

  if (!projectStore?.currentProject) {
    return null;
  }

  return (
    <div className={`not-installed ${className}`}>
      <div className="widget-left">
        <div className='title-tag'>Widget installation required</div>
        <div className="input-label mb-10">{title}</div>
        <div className="text mb-20 center-text">
          {description}
        </div>
        <PrimaryButton
          className="hide-on-mobile"
          label="Install now"
          icon='book-sparkles'
          onClick={() => {
            navigate(
              `/projects/${projectStore?.currentProject?.id}/settings/installation`,
            );
          }}
        />
      </div>
      <img className="widget-image" src={InstallSDK} />
    </div>
  );
}

export default inject('projectStore', 'modalStore')(observer(NotInstalledCard));
