import './FeedbackFlowDropdown.scss';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import LinkButton from 'components/LinkButton/LinkButton';
import { useNavigate } from 'react-router';

interface IconType {
  icon: any;
  label: string;
  value: string;
}

interface FeedbackFlowDropdownProps {
  options: IconType[];
  value?: IconType;
  onValueChanged: (data: IconType) => void;
  projectId?: string;
}

const FeedbackFlowDropdown = ({
  options,
  value,
  onValueChanged,
  projectId,
}: FeedbackFlowDropdownProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Select
        className="feedback-flow-dropdown-selection"
        classNamePrefix="feedback-flow-dropdown-selection"
        value={value}
        placeholder="Type"
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            const icon = option.data.icon;
            return (
              <div className="feedback-flow-type-item">
                <i className={`fa-regular fa-${icon ? icon : 'circle-play'}`} />
                <span>{option.data.label}</span>
                {projectId && (
                  <div className='edit-button-container'>
                    <LinkButton small label='Edit flow' onClick={() => {
                      navigate(`/projects/${projectId}/widget/flows/${option.data.value}`);
                    }} />
                  </div>)}
              </div>
            );
          },
          Option: (option: any) => {
            const icon = option.data.icon;
            return (
              <div
                className="option option-list-item feedback-flow-type-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <i className={`fa-regular fa-${icon ? icon : 'circle-play'}`} />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected) {
            onValueChanged(selected);
          }
        }}
        options={options}
      />
    </>
  );
};

export default inject('projectStore')(observer(FeedbackFlowDropdown));
