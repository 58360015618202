import React from 'react';
import classNames from 'classnames';
import './SidenavContainer.scss';

const SidenavContainer = ({ className, children, sidenavHidden }: any) => {
  const SidenavContainerClassNmae = classNames(
    {
      'sidenav-container': true,
      'sidenav-container--sidenav-hidden': sidenavHidden,
    },
    className,
  );

  return <div className={SidenavContainerClassNmae}>{children}</div>;
};

export default SidenavContainer;
