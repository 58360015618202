import classNames from 'classnames';
import './LinkButton.scss';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

interface LinkButtonProps {
  label?: string;
  onClick: any;
  icon?: any;
  className?: any;
  iconSideRight?: boolean;
  noBorder?: boolean;
  small?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const LinkButton = ({
  label,
  onClick,
  icon,
  className,
  noBorder = false,
  iconSideRight = true,
  small = false,
  isLoading = false,
  disabled = false,
}: LinkButtonProps) => {
  const linkButtonClassName = classNames(
    {
      'link-button': true,
      'link-button--icon-right': iconSideRight,
      'link-button--no-border': noBorder,
      'link-button--small': small,
      'link-button--button-only': label === undefined,
      'link-button--disabled': disabled,
    },
    className,
  );

  if (isLoading) {
    return (
      <div className={linkButtonClassName}>
        <LoadingAnimationSmall />
      </div>
    );
  }
  
  return (
    <button
      type="button"
      className={linkButtonClassName}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {!iconSideRight && icon && <i className={`icon fa-regular fa-${icon}`} />}
      {label}
      {iconSideRight && icon && <i className={`icon fa-regular fa-${icon}`} />}
    </button>
  );
};

export default LinkButton;
