import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import { sha256 } from 'js-sha256';
import './SurveyPreviewModal.scss';

interface SurveyPreviewModalProps {
  modalStore?: ModalStore;
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
}

const SurveyPreviewModal = ({
  modalStore,
  usersStore,
  projectStore,
}: SurveyPreviewModalProps) => {
  const calculatedHash = sha256.hmac(
    projectStore?.currentProject?.secretApiKey ?? '--',
    usersStore?.currentUser?.id ?? '--',
  );
  const previewFrameHTML = `
  <html>
  <head>
  <style>
  * {
    border: none;
    padding: 0px;
    margin: 0px;
  }
  </style>
  </head>
  <body>
  <script>
!function(Gleap,t,i){if(!(Gleap=window.Gleap=window.Gleap||[]).invoked){for(window.GleapActions=[],Gleap.invoked=!0,Gleap.methods=["identify","setEnvironment","clearIdentity","attachCustomData","showFeedbackButton","setCustomData","removeCustomData","clearCustomData","registerCustomAction","trackEvent","log","preFillForm","showSurvey","sendSilentCrashReport","startFeedbackFlow","setAppBuildNumber","setAppVersionCode","setApiUrl","setFrameUrl","isOpened","open","close","on","setLanguage","setOfflineMode","initialize"],Gleap.f=function(e){return function(){var t=Array.prototype.slice.call(arguments);window.GleapActions.push({e:e,a:t})}},t=0;t<Gleap.methods.length;t++)Gleap[i=Gleap.methods[t]]=Gleap.f(i);Gleap.load=function(){var t=document.getElementsByTagName("head")[0],i=document.createElement("script");i.type="text/javascript",i.async=!0,i.src="https://sdk.gleap.io/latest/index.js",t.appendChild(i)},Gleap.load(),
    Gleap.initialize("${projectStore?.currentProject?.apiKey}", true)
}}();
Gleap.identify("${usersStore?.currentUser?.id}", {
  name: "${usersStore?.currentUser?.firstName} ${usersStore?.currentUser?.lastName}",
  email: "${usersStore?.currentUser?.email}"
}, "${calculatedHash}");
Gleap.showFeedbackButton(false);
Gleap.showSurvey("${modalStore?.customData.actionType}", "${modalStore?.customData.format}");
</script>
</body>
</html>
`;

  return (
    <div className="survey-preview-modal">
      <iframe srcDoc={previewFrameHTML} frameBorder="0" className="frame" />
    </div>
  );
};

export default inject(
  'modalStore',
  'usersStore',
  'projectStore',
)(observer(SurveyPreviewModal));
