import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { integrations } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import './IntegrationCallback.scss';

interface IntegrationCallbackProps {
  projectStore?: ProjectStore;
}

const IntegrationCallback = ({ projectStore }: IntegrationCallbackProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  // @ts-ignore
  const { projectId } = useParams();

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    initialize();
  }, [location]);

  const initialize = async () => {
    try {
      const objectKeys = Object.keys(integrations);

      for (let i = 0; i < objectKeys.length; i++) {
        if (location.pathname.indexOf(objectKeys[i]) === -1) {
          continue;
        }

        // Special cases
        if (objectKeys[i] === 'trello') {
          const splittedUrl = location.hash.split('=');
          if (splittedUrl.length > 1 && projectId) {
            await projectStore?.loadProjectById(projectId, true);
            const authResponse = await integrations[objectKeys[i]].initAuth(
              projectId,
              splittedUrl[1],
            );
            if (!authResponse) {
              break;
            }
            const { integrationID } = authResponse.data;

            navigate(
              `/projects/${projectId}/integrations/${objectKeys[i]}/${integrationID}`,
              { replace: true },
            );
          }
          break;
        }

        if (objectKeys[i] === 'zendesk') {
          const params = new URLSearchParams(location.search);
          const code = params.get('code');
          const state = params.get('state');
          const splittedState = state?.split(':');
          if (!splittedState || splittedState.length < 2) {
            break;
          }

          // eslint-disable-next-line no-await-in-loop
          await projectStore?.loadProjectById(splittedState[0]);

          // eslint-disable-next-line no-await-in-loop
          const authResponse = await integrations[objectKeys[i]].initAuth(
            code,
            splittedState[0],
            splittedState[1],
          );
          if (!authResponse) {
            break;
          }
          const { integrationID } = authResponse.data;

          navigate(
            `/projects/${splittedState[0]}/integrations/${objectKeys[i]}/${integrationID}`,
            { replace: true },
          );
          break;
        }

        if (objectKeys[i] === 'slack') {
          const params = new URLSearchParams(location.search);
          const code = params.get('code');
          if (!projectId) {
            break;
          }

          // eslint-disable-next-line no-await-in-loop
          await projectStore?.loadProjectById(projectId);

          // eslint-disable-next-line no-await-in-loop
          const authResponse = await integrations[objectKeys[i]].initAuth(
            code,
            projectId,
          );

          if (!authResponse) {
            break;
          }
          const { integrationID } = authResponse.data;

          navigate(
            `/projects/${projectId}/integrations/${objectKeys[i]}/${integrationID}`,
            { replace: true },
          );
        }

        if (objectKeys[i] === 'clickup') {
          const params = new URLSearchParams(location.search);
          const code = params.get('code');
          if (!projectId) {
            break;
          }

          await projectStore?.loadProjectById(projectId);
          const authResponse = await integrations[objectKeys[i]].initAuth(
            code,
            projectId,
          );
          if (!authResponse) {
            break;
          }

          const { integrationID } = authResponse.data;
          navigate(
            `/projects/${projectId}/integrations/${objectKeys[i]}/${integrationID}`,
            { replace: true },
          );
        }

        // Default behavior
        const params = new URLSearchParams(location.search);

        const code = params.get('code');
        const projectID = params.get('state');

        if (!code || !projectID) {
          break;
        }

        // eslint-disable-next-line no-await-in-loop
        await projectStore?.loadProjectById(projectID);

        // eslint-disable-next-line no-await-in-loop
        const authResponse = await integrations[objectKeys[i]].initAuth(
          code,
          projectID,
        );

        if (!authResponse) {
          break;
        }

        const { integrationID } = authResponse.data;

        navigate(
          `/projects/${projectID}/integrations/${objectKeys[i]}/${integrationID}`,
          { replace: true },
        );
        break;
      }
      setHasError(true);
    } catch (err: any) {
      setHasError(true);
    }
  };

  const buildIntegrationActionContent = () => {
    if (hasError) {
      return <Title label="Error" />;
    }

    return <Loading className="loading-item" />;
  };

  return (
    <>
      <PageContainer>
        <PageHeadLine title="Integrations" />
        <PageContent hasTitle isCentered>
          {buildIntegrationActionContent()}
        </PageContent>
      </PageContainer>
    </>
  );
};

export default inject('projectStore')(observer(IntegrationCallback));
