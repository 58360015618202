import UserAvatar from "components/UserAvatar/UserAvatar";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ProjectStore } from "stores/private/ProjectStore";
import './EmailTemplate.scss';

interface EmailTemplateProps {
    template: string;
    data?: any;
    projectStore?: ProjectStore;
}

function EmailTemplate(props: React.PropsWithChildren<EmailTemplateProps>) {
    const [user, setUser] = useState({} as any);

    useEffect(() => {
        if (props.data?.sender) {
            const user = props.projectStore?.currentProjectUsers.find(
                (o) => o.id === props.data?.sender,
            );
            setUser(user);
        } else {
            setUser(undefined);
        }
    }, [props.data]);

    if (props.template === "personal") {
        return (<div className="email-template-personal">
            <div className="email-template-personal-content">
                {props.children}
            </div>
            {user &&
                <div className="email-template-personal-sender">
                    <UserAvatar email={user.email} imageUrl={user.profileImageUrl} />
                    <span>{user.firstName}</span>
                    {props.data.projectName && <span>from {props.data.projectName}</span>}
                </div>
            }
            <div className="email-unsubscribe-link">Unsubscribe from these emails</div>
        </div>);
    }

    return <div className="email-template-plain">
        {props.children}
        <div className="email-unsubscribe-link">Unsubscribe from these emails</div>
    </div>;
}

export default inject(
    'projectStore',
)(observer(EmailTemplate));