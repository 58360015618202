import InfoBox from 'components/InfoBox/InfoBox';
import Loading from 'components/Loading/Loading';
import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './NoAdminCard.scss';

const NoAdminCard = ({
  title,
  organisationStore,
}: {
  title?: string;
  organisationStore?: OrganisationStore;
}) => {
  if (organisationStore?.isLoadingOrganisation) {
    return <Loading />;
  }

  return (
    <InfoBox>
      <>{title ?? 'This feature is'} only available to organization admins.</>
    </InfoBox>
  );
};

export default inject('organisationStore')(observer(NoAdminCard));
