import { ampli } from 'ampli';
import { HeadLine } from 'components/HeadLine/HeadLine';
import ListTable, { CellText } from 'components/ListTable/ListTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { getDataDescription, truncate } from 'helper/FormDataHelper';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './DuplicateSearchModal.scss';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';

interface DuplicateSearchModalProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  bugStore?: BugStore;
}

const DuplicateSearchModal = ({
  modalStore,
  projectStore,
  bugStore,
}: DuplicateSearchModalProps) => {
  const { currentBug } = bugStore!;
  const ticketsSearchData = projectStore!.ticketSearchData;

  const [fullTextFilter, setFullTextFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (!currentBug) {
    modalStore!.closeModal();
  }

  const debounceSearch = useCallback(
    debounce((nextValue) => {
      projectStore?.duplicateTicketSearch({ searchTerm: nextValue });
    }, 750),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Feedback id',
        accessor: 'bugId',
        Cell: (row) => <CellText text={`#${row.value}`} />,
      },
      {
        Header: 'Title',
        accessor: (row) => ({
          data: row,
        }),
        Cell: (row) => {
          var title = "--"
          if (row.value.data.formText) {
            title = row.value.data.formText
          }
          if (row.value.data.title) {
            title = row.value.data.title
          }

          return (<CellText
            text={truncate(title, 50)}
          />);
        },
      },
      {
        Header: 'Action',
        accessor: (row) => {
          return {
            id: row.id,
            shareToken: row.shareToken,
          };
        },
        Cell: (row) => (
          <PrimaryButton
            label="Merge into"
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await bugStore!.markBugAsDuplicate(currentBug!.id, row.value.id);
              setIsLoading(false);
              modalStore!.closeModal();
              projectStore!.openFeedbackItem({
                shareToken: row.value.shareToken,
              });
              projectStore!.refreshData();

              ampli.markedAsDuplicate({
                bugId: currentBug?.id ?? '',
              });
            }}
          />
        ),
      },
    ],
    [bugStore, currentBug, isLoading, modalStore, projectStore],
  );

  return (
    <SizedContainer size={ContainerSizes.XXL}>
      <HeadLine
        title="Merge with similar"
        subtitle="Duplicates get grouped into one feedback item"
      />
      <div className="dup-search-container">
        <TextInput
          className="fulltext-filter mb-15"
          placeholder="Search"
          type="text"
          error=""
          icon="magnifying-glass"
          initalValue={fullTextFilter}
          onChange={(text) => {
            setFullTextFilter(text);
            debounceSearch(text);
          }}
        />
        {ticketsSearchData.isLoading && fullTextFilter.length > 0 && (
          <div className="dup-search-container-loading">
            <LoadingAnimationSmall />
          </div>
        )}
      </div>
      <div className="list-table-wrapper merge-dups-list">
        <ListTable
          data={ticketsSearchData.data
            .map((feedbackItem: any) => feedbackItem.document)
            .filter((feedbackItem) => {
              if (!feedbackItem) {
                return false;
              }

              if (feedbackItem.type !== currentBug!.type) {
                return false;
              }

              if (
                feedbackItem.id === currentBug!.id ||
                (feedbackItem.duplicateOf && feedbackItem.duplicateOf !== '')
              ) {
                return false;
              }

              return true;
            })
            .slice(0, 50)}
          columns={columns}
          showHeader={false}
        />
      </div>
    </SizedContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'bugStore',
)(observer(DuplicateSearchModal));
