import React, { useEffect } from 'react';
import './OrganisationDropdown.scss';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { useLocation, useNavigate } from 'react-router';

interface OrganisationDropdownProps {
  organisationStore?: OrganisationStore;
  redirect?: boolean;
}

const OrganisationDropdown = ({
  organisationStore,
  redirect = false,
}: OrganisationDropdownProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const organisations = organisationStore?.organisations;

  useEffect(() => {

    const query = new URLSearchParams(location.search);
    const orgaId = query.get('orga');
    if (orgaId && !(organisationStore!.currentOrganisation && organisationStore!.currentOrganisation.id === orgaId)) {
      organisationStore!.getOrganisation(orgaId);
    }
  });

  if (!organisations || organisations.length === 0) {
    return <></>;
  }

  return (
    <div>
      <Select
        className="organisation-selection"
        classNamePrefix="organisation-selection"
        defaultValue={organisationStore?.currentOrganisation}
        value={organisationStore?.currentOrganisation}
        placeholder="Select an organisation"
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            const project = option.data;
            return (
              <div className="project-option">
                {!project.picture && (
                  <div className="image-placeholder mr-15">
                    {project.name && project.name.charAt(0).toLocaleUpperCase()}
                  </div>
                )}
                {project.picture && (
                  <img
                    src={project.picture}
                    className="mr-15"
                    alt="projpicture"
                  />
                )}
                <div className="project-name">{project.name}</div>
              </div>
            );
          },
          Option: (option: any) => {
            const project = option.data;
            return (
              <div
                className="project-option"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                {!project.picture && (
                  <div className="image-placeholder mr-15">
                    {project.name && project.name.charAt(0).toLocaleUpperCase()}
                  </div>
                )}
                {project.picture && (
                  <img
                    src={project.picture}
                    className="mr-15"
                    alt="projpicture"
                  />
                )}
                <div className="project-name">{project.name}</div>
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected) {
            if (redirect) {
              navigate(`/organization/${selected.id}`);
            } else {
              organisationStore!.getOrganisation(selected.id);
            }
          }
        }}
        options={organisations}
      />
    </div>
  );
};

export default inject('organisationStore')(observer(OrganisationDropdown));
