import classNames from 'classnames';
import { Title } from 'components/Title/Title';
import './SubSidenav.scss';

const SubSidenav = ({
  className,
  children,
  title,
  onActionBack,
  buttons,
}: {
  className?: any;
  children: any;
  title?: string;
  hideSidenav?: boolean;
  onActionBack?: any;
  buttons?: any;
}) => {
  const SubSidenavClassNmae = classNames(
    {
      'sub-sidenav': true,
    },
    className,
  );

  return (
    <div className={SubSidenavClassNmae}>
      {onActionBack ? (
        <div className='sub-sidenav--action-back'>
          <div className="action-back-icon" onClick={onActionBack}>
            <i className="fa-solid fa-chevron-left" />
          </div>
          {title && <Title label={title} />}
        </div>
      ) : <>{title && <Title label={title} />}</>}
      {buttons && <div className='sub-sidenav--buttons'>{buttons}</div>}
      {children}
    </div>
  );
};

export default SubSidenav;
