import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import './AnswerBotActionEditor.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';

interface AnswerBotActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const AnswerBotActionEditor = ({
  action,
  modalStore,
  projectStore,
}: AnswerBotActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  return (
    <div className="answerbot-action-editor">
      <PageContainer>
        <PageHeadLine title="Answer bot">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="text">
            This action sends the conversation to the answer bot. The answer bot
            is capable of answering the customers question based on the content
            of your help center and Q&A articles.
          </div>
          <TextInput
            className="mt-20"
            label="Prompt users to ask the answer bot a question"
            value={getProperty(action, `question.localized.${currentLang}`)}
            onChange={(val) => {
              runInAction(() => {
                setProperty(action, `question.localized.${currentLang}`, val);
              });
            }}
            placeholder="Message promting people to ask a question to the answer bot"
            type="text"
            error=""
          />
          <TextInput
            className="mt-20"
            label="Talk to support team button text"
            value={getProperty(action, `talkToHuman.localized.${currentLang}`)}
            onChange={(val) => {
              runInAction(() => {
                setProperty(
                  action,
                  `talkToHuman.localized.${currentLang}`,
                  val,
                );
              });
            }}
            placeholder="Talk to our support team"
            type="text"
            error=""
          />
          <TextInput
            className="mt-20"
            label="Text displayed at the end of the answer bot's answer"
            value={getProperty(
              action,
              `askForAdditionalHelp.localized.${currentLang}`,
            )}
            onChange={(val) => {
              runInAction(() => {
                setProperty(
                  action,
                  `askForAdditionalHelp.localized.${currentLang}`,
                  val,
                );
              });
            }}
            placeholder=""
            type="text"
            error=""
          />
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(AnswerBotActionEditor));
