import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { ClickupIntegrationStore } from 'stores/integrations/ClickupIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface ClickupConfigurationProps {
  integrationID: string;
  index: number;
  clickupIntegrationStore?: ClickupIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const ClickupConfiguration = ({
  integrationID,
  index,
  clickupIntegrationStore,
  projectStore,
  onChange,
}: ClickupConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const teams = clickupIntegrationStore?.teams;
  const spaces = clickupIntegrationStore?.spaces;
  const folders = clickupIntegrationStore?.folders;
  const lists = clickupIntegrationStore?.lists;
  const folderlessLists = clickupIntegrationStore?.folderlessLists;

  const [currentList, setCurrentList] = useState([] as any);
  const [teamID, setTeamID] = useState('');
  const [spaceID, setSpaceID] = useState('');
  const [folderID, setFolderID] = useState('');
  const [listID, setListID] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [currentList, teamID, spaceID, folderID, listID]);

  const initialize = async () => {
    clickupIntegrationStore!.setIntegrationID(integrationID);
    clickupIntegrationStore!.getTeams();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.CLICKUP,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.clickup[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  useEffect(() => {
    if (lists.length === 0) {
      setCurrentList(folderlessLists);
    } else {
      setCurrentList(lists);
    }
  }, [lists, folderlessLists]);

  const chooseTeam = async (teamId) => {
    setTeamID(teamId);
    await clickupIntegrationStore?.getSpaces(teamId);
  };

  const chooseSpace = async (spaceId) => {
    setSpaceID(spaceId);
    await clickupIntegrationStore?.getFolders(spaceId);
    await clickupIntegrationStore?.getFolderlessLists(spaceId);
  };

  const chooseFolder = async (folderId) => {
    setFolderID(folderId);
    await clickupIntegrationStore?.getLists(folderId);
  };

  const chooseList = async (listId) => {
    setListID(listId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      teamID,
      spaceID,
      folderID,
      listID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Team"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={teams.find(
          (element) => element.id === currentIntegrationInfo?.teamID,
        )}
        isLoading={teams.length === 0}
        items={teams}
        onChange={(option) => {
          chooseTeam(option.id);
        }}
      />
      {teamID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Space"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={spaces.find(
            (element) => element.id === currentIntegrationInfo?.spaceID,
          )}
          isLoading={spaces.length === 0}
          items={spaces}
          onChange={(option) => {
            chooseSpace(option.id);
          }}
        />
      )}
      {spaceID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Folder (optional)"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={folders.find(
            (element) => element.id === currentIntegrationInfo?.folderID,
          )}
          items={folders}
          onChange={(option) => {
            chooseFolder(option.id);
          }}
        />
      )}
      {spaceID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="List"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={currentList.find(
            (element) => element.id === currentIntegrationInfo?.listID,
          )}
          isLoading={currentList.length === 0}
          items={currentList}
          onChange={(option) => {
            chooseList(option.id);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'clickupIntegrationStore',
  'projectStore',
)(observer(ClickupConfiguration));
