import React, { useEffect, useState } from 'react';
import './WidgetPreview.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Loading from 'components/Loading/Loading';
import { UsersStore } from 'stores/private/UsersStore';
import { toast } from 'react-toastify';
import { calculateContrast, calculateShadeColor } from 'helper/Color';
import Gleap from 'gleap';

interface WidgetPreviewProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

function WidgetPreview({ projectStore, usersStore }: WidgetPreviewProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId, false, true);
    }
    setIsLoading(false);
  }, [projectId]);

  if (isLoading || !flowConfig) {
    return <Loading />;
  }

  const showDemoNotice = () => {
    toast.success('You clicked your demo widget 🥳.');
  };

  const cornerRadius = flowConfig.borderRadius ?? 20;
  const backgroundColor = flowConfig.backgroundColor ?? '#ffffff';
  const contrastBackgroundColor = calculateContrast(backgroundColor);
  const subTextColor =
    contrastBackgroundColor === '#ffffff'
      ? calculateShadeColor(backgroundColor, 100)
      : calculateShadeColor(backgroundColor, -100);

  const team = (projectStore?.teamPreview ?? []).slice(0, 3);

  return (
    <div className="widget-preview">
      {flowConfig.feedbackButtonPosition &&
        flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
          <div
            className="widget-bugbattle--feedback-button"
            onClick={() => {
              showDemoNotice();
            }}
          >
            <div
              className={`widget-bugbattle--feedback-button-text ${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_LEFT'
                ? 'widget-bugbattle--feedback-button-text--left'
                : ''
                }${flowConfig.feedbackButtonPosition === 'BUTTON_CLASSIC_BOTTOM'
                  ? 'widget-bugbattle--feedback-button-text--bottom'
                  : ''
                }`}
              style={{
                backgroundColor: flowConfig.buttonColor ?? '#485bff',
                color: calculateContrast(flowConfig.buttonColor ?? '#485bff'),
                borderTopLeftRadius: cornerRadius * 0.4,
                borderTopRightRadius: cornerRadius * 0.4,
              }}
            >
              {flowConfig.widgetButtonText}
            </div>
          </div>
        )}
      <div className="widget-preview-browser">
        <div className="widget-preview-browser-head">
          <div className="widget-preview-browser-head-close" />
          <div className="widget-preview-browser-head-minimize" />
          <div className="widget-preview-browser-head-maximize" />
        </div>
        <div className="widget-preview-browser-body">
          <div
            className="feedback-box"
            style={{
              borderRadius: cornerRadius,
              backgroundColor,
            }}
          >
            <div
              className="feedback-box-header"
              style={{
                borderTopLeftRadius: cornerRadius,
                borderTopRightRadius: cornerRadius,
              }}
            >
              <div
                className={`feedback-box-header--shadow ${flowConfig.disableBGGradient && 'feedback-box-header--nogradient'} ${flowConfig.disableBGFade && 'feedback-box-header--nofade'}`}
                style={{
                  backgroundColor: flowConfig.headerColor,
                }}
              >
                {!flowConfig.disableBGFade &&
                  <div
                    className="feedback-box-header--inner"
                    style={{
                      background: `linear-gradient(180deg, transparent 60%, ${backgroundColor} 100%)`,
                    }}
                  ></div>}
              </div>
              {flowConfig.logo.length > 0 ? (
                <img src={flowConfig.logo} />
              ) : (
                <svg
                  width="127"
                  height="129"
                  viewBox="0 0 127 129"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5507 32.306C20.4495 41.0714 17.3437 52.8384 17.3438 64.1091C17.3438 75.3799 20.4497 87.1469 27.5508 95.9123C34.4039 104.372 45.6889 110.937 64.1725 110.937C83.6599 110.937 93.3637 102.356 98.4673 94.1976C102.058 88.4577 103.344 84.2626 103.805 81.4366C104.114 79.5459 105.616 77.9841 107.531 77.9841H117.938C119.853 77.9841 121.429 79.5376 121.265 81.4463C120.835 86.4687 119.175 93.7981 113.171 103.396C105.135 116.242 90.0723 128.281 64.1725 128.281C41.0305 128.281 24.5652 119.779 14.0745 106.83C3.83175 94.1866 0.000111649 78.2036 2.4398e-09 64.1092C-0.000111645 50.0147 3.83155 34.0317 14.0744 21.3884C24.0327 9.09622 39.3744 0.811764 60.7001 0.00243821C62.6145 -0.0702131 64.1725 1.49027 64.1725 3.40601V13.8123C64.1725 15.728 62.6176 17.2712 60.704 17.3608C44.2594 18.1311 33.9643 24.3893 27.5507 32.306Z"
                    fill={calculateContrast(
                      flowConfig.headerColor ?? '#485bff',
                    )}
                  />
                  <path
                    d="M126.609 43.2966C126.609 50.9596 120.397 57.1716 112.734 57.1716C105.071 57.1716 98.8594 50.9596 98.8594 43.2966C98.8594 35.6337 105.071 29.4216 112.734 29.4216C120.397 29.4216 126.609 35.6337 126.609 43.2966Z"
                    fill={calculateContrast(
                      flowConfig.headerColor ?? '#485bff',
                    )}
                  />
                </svg>
              )}
              {!flowConfig.hideTeam && team.length > 2 && (
                <div className='feedback-box-header-team'>
                  {team.map((teamUser, index) => {
                    return (<img key={index} src={teamUser.profileImageUrl} />)
                  })}
                </div>
              )}
              <div
                className="feedback-box-header-title"
              >
                <span style={{
                  color: calculateContrast(flowConfig.headerColor ?? '#485bff'),
                }}>
                  Hey{' '}
                  {flowConfig.hideUsersName
                    ? ''
                    : usersStore?.currentUser?.firstName ?? 'there'}{' '}</span>
                <span className='hand'>{flowConfig.hideWavingHandAfterName ? '' : '👋'}</span>
              </div>
              <div
                className="feedback-box-header-subtitle"
                style={{
                  color: calculateContrast(flowConfig.headerColor ?? '#485bff'),
                }}
              >
                {flowConfig.welcomeText ??
                  'How can we help?'}
              </div>
            </div>
            <div className="feedback-box-body">
              {flowConfig.menuItems &&
                flowConfig.menuItems.map((menuItem, key) => {
                  return (
                    <div
                      className="feedback-box-body-item"
                      key={key}
                      style={{
                        backgroundColor: backgroundColor,
                        borderRadius: cornerRadius * 0.4,
                      }}
                      onClick={() => {
                        showDemoNotice();
                      }}
                    >
                      <div className="feedback-box-body-item-type-text">
                        <div
                          className="feedback-box-body-item-type-title"
                          style={{
                            color: contrastBackgroundColor,
                          }}
                        >
                          {menuItem.title}
                        </div>
                        <div
                          className="feedback-box-body-item-type-description"
                          style={{
                            color: subTextColor,
                          }}
                        >
                          {menuItem.description}
                        </div>
                      </div>
                      <img
                        className="feedback-box-body-item-type-icon"
                        src={menuItem.icon}
                      />
                    </div>
                  );
                })}
            </div>
            <div className="feedback-box-footer">
              {!flowConfig.hideBranding && (
                <>
                  <svg
                    className="bolt"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="#ffd43b"
                      d="M240.5 224H352C365.3 224 377.3 232.3 381.1 244.7C386.6 257.2 383.1 271.3 373.1 280.1L117.1 504.1C105.8 513.9 89.27 514.7 77.19 505.9C65.1 497.1 60.7 481.1 66.59 467.4L143.5 288H31.1C18.67 288 6.733 279.7 2.044 267.3C-2.645 254.8 .8944 240.7 10.93 231.9L266.9 7.918C278.2-1.92 294.7-2.669 306.8 6.114C318.9 14.9 323.3 30.87 317.4 44.61L240.5 224z"
                    />
                  </svg>
                  <span
                    style={{
                      color: subTextColor,
                    }}
                  >
                    Powered by
                  </span>
                  <svg
                    className="logo"
                    width="90px"
                    height="32px"
                    viewBox="0 0 90 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(0.653299, 0.000000)"
                        fill={subTextColor}
                        fillRule="nonzero"
                      >
                        <path d="M16.7989119,8.32577189 L22.1014291,8.32577189 C21.4265888,3.43890649 17.1242771,0 11.5448484,0 C5.02513746,0 0,4.70586291 0,12.7178969 C0,20.5368768 4.69977222,25.3876017 11.6532254,25.3876017 C17.8836188,25.3876017 22.3303551,21.4418423 22.3303551,14.9380999 L22.3303551,11.8250016 L12.0027005,11.8250016 L12.0027005,15.7586204 L17.3052177,15.7586204 C17.2328883,18.823461 15.1479759,20.7661416 11.6773352,20.7661416 C7.76078035,20.7661416 5.29034525,17.8340271 5.29034525,12.6696392 C5.29034525,7.52939191 7.85721955,4.62139446 11.6291156,4.62139446 C14.3165389,4.62139446 16.1362435,6.00903014 16.7989119,8.32577189 Z"></path>
                        <polygon points="30.2692671 0.337857389 25.1355185 0.337857389 25.1355185 25.0496341 30.2692671 25.0496341"></polygon>
                        <path d="M41.7991346,25.4117422 C46.3785919,25.4117422 49.4634758,23.1793283 50.1865357,19.7404968 L45.4385438,19.426787 C44.9203002,20.8385398 43.5947294,21.5745684 41.883636,21.5745684 C39.3167617,21.5745684 37.6897014,19.8732229 37.6897014,17.1100453 L37.6897014,17.097975 L50.2951468,17.097975 L50.2951468,15.6862222 C50.2951468,9.38760404 46.486969,6.27448232 41.5943184,6.27448232 C36.1473765,6.27448232 32.6163443,10.1477732 32.6163443,15.8672059 C32.6163443,21.7435053 36.0991569,25.4117422 41.7991346,25.4117422 Z M37.6897014,13.9124785 C37.7983125,11.8008611 39.4010289,10.1115858 41.6785856,10.1115858 C43.9081568,10.1115858 45.4507158,11.7043223 45.4626536,13.9124785 L37.6897014,13.9124785 Z"></path>
                        <path d="M57.9054165,25.3995548 C60.6410594,25.3995548 62.4125444,24.2049497 63.3163107,22.4795574 L63.4609695,22.4795574 L63.4609695,25.0496341 L68.3295103,25.0496341 L68.3295103,12.5489834 C68.3295103,8.13269445 64.593896,6.27448232 60.4722908,6.27448232 C56.0377263,6.27448232 53.121377,8.39817007 52.410255,11.7767205 L57.1582468,12.162852 C57.5077218,10.9320829 58.6043666,10.0271174 60.448181,10.0271174 C62.1955562,10.0271174 63.1957617,10.9079424 63.1957617,12.4283041 L63.1957617,12.5007023 C63.1957617,13.695284 61.9305825,13.8521272 58.7129777,14.1658604 C55.0494587,14.5037108 51.7595245,15.7344799 51.7595245,19.8732229 C51.7595245,23.5414364 54.3746184,25.3995548 57.9054165,25.3995548 Z M59.375646,21.8521143 C57.7970394,21.8521143 56.664347,21.1160622 56.664347,19.7043095 C56.664347,18.2563459 57.8571969,17.5444343 59.6649636,17.291029 C60.7857181,17.1341858 62.6173606,16.8687102 63.2320434,16.4584616 L63.2320434,18.4252828 C63.2320434,20.3679399 61.629327,21.8521143 59.375646,21.8521143 Z"></path>
                        <path d="M71.2943133,32 L76.4280619,32 L76.4280619,22.0813791 L76.5846586,22.0813791 C77.2957806,23.6258111 78.8502774,25.3512737 81.8389562,25.3512737 C86.0567665,25.3512737 89.3467007,22.0089575 89.3467007,15.806878 C89.3467007,9.43586168 85.9121077,6.27448232 81.850894,6.27448232 C78.7538382,6.27448232 77.2716708,8.12062418 76.5846586,9.62891568 L76.3557325,9.62891568 L76.3557325,6.5158174 L71.2943133,6.5158174 L71.2943133,32 Z M76.3196849,15.7827375 C76.3196849,12.4765852 77.717585,10.3649677 80.2121299,10.3649677 C82.7548944,10.3649677 84.104575,12.5731005 84.104575,15.7827375 C84.104575,19.016515 82.7307846,21.2608586 80.2121299,21.2608586 C77.7416948,21.2608586 76.3196849,19.0889132 76.3196849,15.7827375 Z"></path>
                      </g>
                    </g>
                  </svg>
                </>
              )}
              {!(flowConfig.hideNews && flowConfig.hideKnowledgeBase && flowConfig.hideFeatureRequests && flowConfig.hideAllConversations) && (
                <div
                  className="tab-bar"
                  style={{
                    backgroundColor: backgroundColor,
                    borderBottomLeftRadius: cornerRadius,
                    borderBottomRightRadius: cornerRadius,
                  }}
                >
                  <div className="tab-bar-item active">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path
                        fill={flowConfig.color}
                        d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c.2 35.5-28.5 64.3-64 64.3H128.1c-35.3 0-64-28.7-64-64V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
                      ></path>
                    </svg>
                    <span
                      style={{
                        color: flowConfig.color,
                      }}
                    >
                      Home
                    </span>
                  </div>
                  {!flowConfig.hideAllConversations && (
                    <div className="tab-bar-item false">
                      <div className="icon-container">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                            fill={contrastBackgroundColor}
                            d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.98c0 9.836 11.02 15.55 19.12 9.7l124.9-93.68h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM464 352c0 8.75-7.25 16-16 16h-160l-80 60v-60H64c-8.75 0-16-7.25-16-16V64c0-8.75 7.25-16 16-16h384c8.75 0 16 7.25 16 16V352z" />
                        </svg>
                      </div>
                      <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        Messages
                      </span>
                    </div>
                  )}
                  {!flowConfig.hideNews && (
                    <div className="tab-bar-item false">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill={contrastBackgroundColor} d="M200.7 128C270.7 128 337.9 100.2 387.4 50.68L425.4 12.69C445.5-7.472 480 6.805 480 35.31V179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V444.7C480 473.2 445.5 487.5 425.4 467.3L387.4 429.3C339.6 381.5 275.4 353.1 208 352.1V456C208 486.9 182.9 512 152 512H120C89.07 512 64 486.9 64 456V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128L200.7 128zM432 73.94L421.3 84.62C364.5 141.4 288.1 174 208 175.9V304.1C288.1 305.1 364.5 338.6 421.3 395.4L432 406.1V73.94zM112 456C112 460.4 115.6 464 120 464H152C156.4 464 160 460.4 160 456V352H112V456zM48 192V288C48 296.8 55.16 303.1 64 303.1H160V176H64C55.16 176 48 183.2 48 192V192z" />
                      </svg>
                      <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        News
                      </span>
                    </div>
                  )}
                  {!flowConfig.hideFeatureRequests && (
                    <div className="tab-bar-item false">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path fill={contrastBackgroundColor} d="M375.6 207.8L375.5 207.1C372.4 213.3 369.2 218.7 365.9 224C361.5 231 356.9 237.1 352.4 244.8C352.3 244.1 352.1 245.2 352 245.4C333.9 272.3 315.3 296.4 302.8 311.1C295.1 321.6 280.9 321.6 273.2 311.1C260.7 296.4 242.1 272.3 224 245.4C205.6 218.1 187.7 187.9 177.3 160.9C176.5 158.8 175.7 156.7 174.1 154.6C170.6 142 168 130.3 168 120C168 115.3 168.3 110.7 168.8 106.2C175.6 46.44 226.4 0 288 0C354.3 0 408 53.73 408 120C408 128.6 406.2 138.3 402.1 148.6C397.3 166.1 387.4 187.5 375.6 207.8V207.8zM400 229.5V453.7L528 407.2V154.3L419.3 193.8C421.5 189.1 423.6 184.5 425.6 179.9C431.5 165.8 436.6 150.7 438.8 135.6L543.8 97.44C551.2 94.77 559.4 95.85 565.8 100.3C572.2 104.8 576 112.2 576 119.1V424C576 434.1 569.7 443.1 560.2 446.6L384.2 510.6C378.9 512.5 373.1 512.5 367.8 510.6L200 449.5L32.2 510.6C24.84 513.2 16.64 512.2 10.23 507.7C3.819 503.2 0 495.8 0 488V184C0 173.9 6.314 164.9 15.8 161.4L136 117.7C136 118.5 136 119.2 136 119.1C136 135.1 139.7 150.7 144.9 165.6L48 200.8V453.7L176 407.2V229.5C177.8 232.4 179.5 235.3 181.3 238.2C195.2 260.7 210.5 282.4 224 300.6V407.2L352 453.7V300.6C365.5 282.4 380.8 260.7 394.7 238.2C396.5 235.3 398.2 232.4 400 229.5V229.5z" />
                      </svg>

                      <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        Roadmap
                      </span>
                    </div>
                  )}
                  {!flowConfig.hideKnowledgeBase && (
                    <div className="tab-bar-item false">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path
                        fill={contrastBackgroundColor}
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z" /></svg>
                      <span
                        style={{
                          color: contrastBackgroundColor,
                        }}
                      >
                        Help
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {flowConfig.feedbackButtonPosition &&
            flowConfig.feedbackButtonPosition !== 'BUTTON_NONE' &&
            !flowConfig.feedbackButtonPosition.includes('CLASSIC') && (
              <div
                className={`widget-bugbattle--feedback-new-button ${flowConfig.feedbackButtonPosition === 'BOTTOM_LEFT'
                  ? 'widget-bugbattle--feedback-new-button--left'
                  : ''
                  }`}
              >
                <div
                  className="widget-bugbattle--feedback-new-button-button"
                  style={{
                    backgroundColor: flowConfig.buttonColor ?? '#485bff',
                    color: calculateContrast(
                      flowConfig.buttonColor ?? '#485bff',
                    ),
                  }}
                  onClick={() => {
                    showDemoNotice();
                  }}
                >
                  {flowConfig.buttonLogo && flowConfig.buttonLogo.length > 0 ? (
                    <img src={flowConfig.buttonLogo} />
                  ) : (
                    <svg
                      className="widget-default-icon"
                      width="700px"
                      height="700px"
                      viewBox="0 0 700 700"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          transform="translate(77.000000, 119.000000)"
                          fill={calculateContrast(
                            flowConfig.buttonColor ?? '#485bff',
                          )}
                          fillRule="nonzero"
                        >
                          <path d="M476.856373,0 L68.122339,0 C30.601832,0 0,30.5935323 0,67.1461527 L0,372.655827 C0,410.166158 30.601832,439.80198 68.122339,439.80198 L170.305847,439.80198 L170.305847,528.23059 C170.305847,538.605788 182.280477,544.671288 190.657396,538.552581 L323.602398,438.844269 L476.877661,438.844269 C514.398168,438.844269 545,408.250737 545,371.698116 L545,67.1461527 C544.978712,30.5935323 515.28163,0 476.856373,0 Z"></path>
                        </g>
                      </g>
                    </svg>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default inject('projectStore', 'usersStore')(observer(WidgetPreview));
