import { Editor } from '@tiptap/react';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { getFormFieldValue } from 'helper/FormDataHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useRef, useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './EditFeatureRequestModal.scss';

interface EditFeatureRequestModalProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const EditFeatureRequestModal = ({
  bugStore,
  modalStore,
  projectStore,
}: EditFeatureRequestModalProps) => {
  const bug = bugStore!.currentBug;
  const [toggleState, setToggleState] = useState(false);
  const editorRef: MutableRefObject<Editor | null> = useRef(null);

  if (!bug) {
    return <></>;
  }

  const title = getFormFieldValue(bug, 'title');
  const description = getFormFieldValue(bug, 'description', 'formText');

  return (
    <div className="edit-feature-request-modal">
      <PageContainer>
        <PageHeadLine
          isEditable
          title={title}
          onChangeTitle={(title) => {
            runInAction(() => {
              bug.title = title;
            });
          }}>
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                bugStore!.updateBug(bug.id, {
                  title: bug.title,
                  content: bug.content,
                });

                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="featurerequest-input-container">
            <div className="toolbar-wrapper">
              <Toolbar editor={editorRef?.current} aiStyle="helpcenter" items={[ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.Embedded, ToolbarItems.AI]} />
            </div>
            <div className="editor-wrapper">
              <EmailEditor
                editorRef={editorRef}
                onEditorReady={() => {
                  setToggleState(!toggleState);
                }}
                placeholder="Feature request description..."
                content={bug.content ? bug.content : `<p>${description}</p>`}
                inputContentChanged={(content) => {
                  runInAction(() => {
                    bug.content = content;
                  });
                }}
              />
            </div>
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
)(observer(EditFeatureRequestModal));
