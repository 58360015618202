import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { inject, observer } from 'mobx-react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import { timezones } from 'services/Timezones';
import './OperatingHoursSettings.scss';
import { ampli } from 'ampli';
import { useParams } from 'react-router';
import { isEqual } from 'lodash';

const operatingHoursOptions = [
  {
    label: 'All days',
    value: 'All days',
  },
  {
    label: 'Weekends',
    value: 'Weekends',
  },
  {
    label: 'Weekdays',
    value: 'Weekdays',
  },
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
  {
    label: 'Sunday',
    value: 'Sunday',
  },
];

const operatingHourTimesOption = Array.from(Array(24).keys()).map((item) => {
  return {
    label: `${item.toString().length > 1 ? item.toString() : '0' + item.toString()
      }:00`,
    value: `${item.toString().length > 1 ? item.toString() : '0' + item.toString()
      }:00`,
  };
});

const replyTimeOptions = [
  {
    label: 'In a few minutes',
    value: 'within a few minutes',
  },
  {
    label: 'In a few hours',
    value: 'within a few hours',
  },
  {
    label: 'In a day',
    value: 'within a day',
  },
];

interface OperatingHourSettingsProps {
  projectStore?: ProjectStore;
}

const OperatingHoursSettings = ({
  projectStore,
}: OperatingHourSettingsProps) => {
  const { projectId } = useParams();

  const { flowConfig } = projectStore?.editingProject ?? {};

  const buildTimezoneSelection = () => {
    const selectedTimezone = flowConfig.timezone ?? 'Universal';
    return (
      <SelectDropDown
        className="mb-30 operating-hour-options"
        labelPropertyName="name"
        valuePropertyName="value"
        selectedItem={{
          name: selectedTimezone,
          value: selectedTimezone,
        }}
        items={timezones.map((timezone) => ({
          name: timezone,
          value: timezone,
        }))}
        onChange={(option) => {
          flowConfig.timezone = option.value;

          ampli.openingHoursCustomized({
            projectId,
          });
        }}
      />
    );
  };

  const buildOperatingHourSelectionItem = ({ operatingHourItem, index }) => {
    const openingTimes = flowConfig.openingTimes ?? [];

    return (
      <div
        className="operating-hour-selection-item-container mb-20"
        key={`${operatingHourItem.day}-${index}`}
      >
        <SelectDropDown
          className="mr-5 operating-hour-options"
          labelPropertyName="label"
          valuePropertyName="value"
          selectedItem={operatingHoursOptions.find(
            (element) => element.value === operatingHourItem?.day,
          )}
          items={operatingHoursOptions}
          onChange={(option) => {
            openingTimes[index].day = option.value;

            ampli.openingHoursCustomized({
              projectId,
            });
          }}
        />
        <SelectDropDown
          className="mr-10 time-options"
          labelPropertyName="label"
          valuePropertyName="value"
          selectedItem={operatingHourTimesOption.find(
            (element) => element.value === operatingHourItem.openingTime,
          )}
          items={operatingHourTimesOption}
          onChange={(option) => {
            openingTimes[index].openingTime = option.value;

            ampli.openingHoursCustomized({
              projectId,
            });
          }}
        />
        <div className="text mr-5">to</div>
        <SelectDropDown
          className="mr-10 time-options"
          labelPropertyName="label"
          valuePropertyName="value"
          selectedItem={operatingHourTimesOption.find(
            (element) => element.value === operatingHourItem.closingTime,
          )}
          items={operatingHourTimesOption}
          onChange={(option) => {
            openingTimes[index].closingTime = option.value;

            ampli.openingHoursCustomized({
              projectId,
            });
          }}
        />
        <div
          className="delete-opening-time cursor-focus"
          onClick={() => {
            flowConfig.openingTimes.splice(index, 1);

            ampli.openingHoursCustomized({
              projectId,
            });
          }}
        >
          <i className="fa-solid fa-trash" />
        </div>
      </div>
    );
  };

  const buildOperatingHours = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">
            General
          </div>
          <div className="switch-container mt-30 mb-30">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.showOnlineStatus = !(
                  flowConfig.showOnlineStatus ?? false
                );

                ampli.openingHoursCustomized({
                  projectId,
                });
              }}
              checked={flowConfig.showOnlineStatus ?? false}
            />
            <span>Show online status</span>
          </div>
          {flowConfig.showOnlineStatus && (
            <>
              <div className="text bold mb-5">Workspace timezone</div>
              <div className="text mb-10">Select your workspace timezone.</div>
              {buildTimezoneSelection()}
              <div className="text bold mb-5">Set your office hours</div>
              <div className="text mb-20">
                Let people know when your team will be back online. They'll see
                hours converted to their own time zone.
              </div>
              {flowConfig.openingTimes.map((item, index) =>
                buildOperatingHourSelectionItem({
                  operatingHourItem: item,
                  index,
                }),
              )}
              <LinkButton
                label="Add hours"
                onClick={() => {
                  if (!flowConfig.openingTimes) {
                    flowConfig.openingTimes = [];
                  }
                  flowConfig.openingTimes.push({
                    day: 'Weekdays',
                    openingTime: '09:00',
                    closingTime: '17:00',
                  });

                  ampli.openingHoursCustomized({
                    projectId,
                  });
                }}
              />
            </>
          )}
        </div>
      </>
    );
  };

  const buildReplyTimeOptionItem = ({ option, index }) => {
    return (
      <label
        className="bb-feedback-multiplechoice-container"
        key={`${option.label}-${index}`}
      >
        <div className="text">{option.label}</div>
        <input
          type="radio"
          name="once"
          checked={flowConfig.replyTime === option.value}
          onChange={() => {
            flowConfig.replyTime = option.value;

            ampli.openingHoursCustomized({
              projectId,
            });
          }}
        />
        <span className="bb-feedback-multiplechoice-checkmark" />
      </label>
    );
  };

  const buildReplyTime = () => {
    if (!flowConfig.showOnlineStatus) {
      return null;
    }

    return (
      <>
        <div className="options-group">
          <div className="options-group-header">Reply time</div>
          <div className="text bold mb-5">Share your reply time</div>
          <div className="text mb-30">
            Set the expectations about how quickly your team replies during
            office hours.
          </div>
          {replyTimeOptions.map((option: any, index) =>
            buildReplyTimeOptionItem({ option, index }),
          )}
        </div>
      </>
    );
  };

  const buildAutoResponderBot = () => {
    if (!flowConfig.showOnlineStatus) {
      return null;
    }

    return (
      <>
        <div className="options-group">
          <div className="options-group-header">Auto-responder</div>
          <div className="switch-container mt-30 mb-10">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.enableOpeningTimesBot = !(
                  flowConfig.enableOpeningTimesBot ?? false
                );
              }}
              checked={flowConfig.enableOpeningTimesBot ?? false}
            />
            <span>Auto respond with operating hours when out of office.</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Operating hours">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        {buildOperatingHours()}
        {buildAutoResponderBot()}
        {buildReplyTime()}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(OperatingHoursSettings));
