import BugTypeSelection from 'components/BugTypeSelection/BugTypeSelection';
import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import './CreateTicketActionEditor.scss';

interface CreateTicketActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
}

const CreateTicketActionEditor = ({
  action,
  modalStore,
}: CreateTicketActionEditorProps) => {
  return (
    <div className="createticket-action-editor">
      <PageContainer>
        <PageHeadLine title="Create ticket">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="input-label">Ticket type</div>
          <div className="feedback-type-selection">
            <BugTypeSelection
              all
              value={action.ticketType ?? 'INQUIRY'}
              onValueChanged={(selected) => {
                action.ticketType = selected.value;
              }}
            />
          </div>
          <InfoBox>
            This action creates a ticket based on the current conversation. This
            will also send the ticket to integrations.
          </InfoBox>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore')(observer(CreateTicketActionEditor));
