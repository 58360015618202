import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './EditQAModal.scss';
import TextInput from 'components/TextInput/TextInput';

interface EditQAModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const EditQAModal = ({
  modalStore,
  projectStore,
}: EditQAModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const answerObj = modalStore?.customData?.answer ?? {};
  const [answer, setAnswer] = useState("");
  const [sourceLink, setSourceLink] = useState("");
  const [sourceName, setSourceName] = useState("");

  useEffect(() => {
    if (answerObj && answerObj.answer) {
      setAnswer(answerObj.answer);
    }
    if (answerObj && answerObj.sourceLink) {
      setSourceLink(answerObj.sourceLink);
    }
    if (answerObj && answerObj.sourceName) {
      setSourceName(answerObj.sourceName);
    }
  }, []);

  const sendAnswer = async (data) => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.updateQAAnswer(projectStore.currentProject?.id, answerObj.id, data);
    modalStore!.closeModal();
    setIsLoading(false);
  };

  const deleteAnswer = async () => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.deleteQAAnswer(projectStore.currentProject?.id, answerObj.id);
    modalStore!.closeModal();
    setIsLoading(false);
  };

  return (
    <SizedContainer size={ContainerSizes.L} className="edit-qa-modal">
      <div>
        <div className='input-label mb-5'>Question</div>
        <div className='text mb-20'>{answerObj.question}
        </div>
        <div className="input-label">
          Answer
        </div>
        <textarea
          className="default-textarea mb-10"
          placeholder=""
          value={answer ?? ''}
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
        />
        <TextInput
          name="question"
          placeholder="Provide a name for the resource"
          type="text"
          className="mb-20"
          error=""
          value={sourceName ?? ''}
          label="Read more title"
          onChange={(text) => {
            setSourceName(text);
          }}
        />
        <TextInput
          name="question"
          placeholder="Link that provides more informations on the answer"
          type="text"
          className="mb-20"
          error=""
          value={sourceLink ?? ''}
          label="Read more URL"
          onChange={(text) => {
            setSourceLink(text);
          }}
        />
        <div className='buttons'>
          <LinkButton className="danger-button" icon="trash" iconSideRight={false} isLoading={isLoading} label='Delete' onClick={() => {
            deleteAnswer();
          }} />
          <PrimaryButton isLoading={isLoading} label='Save' icon="check" onClick={() => {
            sendAnswer({
              answer,
              sourceLink,
              sourceName,
            });
          }} />
        </div>
      </div>
    </SizedContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(EditQAModal));
