import { arrayMoveImmutable } from 'array-move';
import { ReactComponent as DragIcon } from 'assets/icons/dragicon.svg';
import NoArticleImage from 'assets/illustrations/no_articels.svg';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LinkButton from 'components/LinkButton/LinkButton';
import { CellGestureDetector } from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import { Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import defaultHelpCenterIcon from 'assets/icons/defaulthelpcentericon.svg';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectHelpcenter.scss';

interface ProjectHelpCenterProps {
  projectStore?: ProjectStore;
  helpcenterStore?: HelpcenterStore;
  modalStore?: ModalStore;
}

function ProjectHelpcenter({
  projectStore,
  helpcenterStore,
  modalStore,
}: ProjectHelpCenterProps) {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const planIsEntitled = projectStore?.isFeatureInPlan(Feature.SURVEYS);
  const currentProject = projectStore?.currentProject;
  const collections = helpcenterStore?.collections;
  const helpcenterConfig = projectStore?.flowConfig.helpcenterConfig;
  const previewLanguageCode = helpcenterConfig?.localization[0].language;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      initialize();
    }
  }, [currentProject]);

  const initialize = async () => {
    setIsLoading(true);
    await helpcenterStore?.getCollections();
    setIsLoading(false);
  };

  const onSortEnd = (event) => {
    runInAction(() => {
      helpcenterStore!.collections = arrayMoveImmutable(
        collections!,
        event.oldIndex,
        event.newIndex,
      );

      let currentCollection = helpcenterStore!.collections[event.newIndex];

      const newLexoRank = helpcenterStore!.calculateNewLexorank(
        collections!,
        event.newIndex,
        currentCollection.id,
      );

      currentCollection.lexorank = newLexoRank.toString();
      helpcenterStore!.updateCollection(currentCollection);
    });
  };

  const DragHandle = SortableHandle(() => (
    <DragIcon className="lanes-drag-icon cursor-focus" />
  ));

  const SortableItem = SortableElement((value) => {
    const collectionItem = value.value;
    return (
      <div className="collection-list-item">
        <div className="collection-list-item-details">
          <DragHandle />
          <img className='collection-list-item-details-icon' src={(collectionItem.iconUrl && collectionItem.iconUrl.length > 0) ? collectionItem.iconUrl : defaultHelpCenterIcon} />
          <CellGestureDetector
            onClick={() => {
              navigate(
                `/projects/${projectId}/helpcenter/collections/${collectionItem.id}`,
              );
            }}
            className="ml-10 r-66-first"
            label={
              (previewLanguageCode &&
                collectionItem.title &&
                Object.keys(collectionItem.title).length > 0 &&
                collectionItem.title[previewLanguageCode] !== '' &&
                collectionItem.title[previewLanguageCode]) ??
              'No title set for default language'
            }
          />
          <div className="text r-33">{collectionItem.articlesCount} articles</div>
          <div className="r-33 collection-list-item-details-date">
            {moment(collectionItem.createdAt).format('MMM Do, h:mm:ss a')}
          </div>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem key={value.id} index={index} value={value} />
        ))}
      </div>
    );
  });

  if (!currentProject) {
    return <></>;
  }

  if (isLoading) {
    return (
      <PageContainer>
        <PageHeadLine title="Collections" />
        <PageContent isCentered hasTitle>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  if (!planIsEntitled) {
    return (
      <PageContainer>
        <PageHeadLine title="Collections" />
        <PageContent isCentered hasTitle>
          <div className="no-surveys-container">
            <img
              src={NoArticleImage}
              alt="No collections created yet"
              className="no-surveys-image"
            />
            <SuggestSubscription
              plan={Plantypes.GROWTH}
              title={'Help center'}
              description={`Happier customers, fewer support questions. Make it easy for users to access information with our help center articles.`}
            />
          </div>
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeadLine title="Collections">
        <div className="header-content-right">
          {collections && collections?.length > 0 && (
            <>
              <LinkButton
                label="Open help center"
                className="mr-10"
                iconSideRight={false}
                icon="arrow-up-right-from-square"
                onClick={() => {
                  const shareURL = projectStore?.currentProject
                    ?.customDomainHelpCenter
                    ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
                    : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;
                  (window as any).open(shareURL, '_blank').focus();
                }}
              />
              <PrimaryButton
                label="Add collection"
                icon="plus"
                iconSideRight={false}
                onClick={() => {
                  modalStore!.openModal(MODALTYPE.CREATE_HELPCENTER_COLLECTION);
                }}
              />
            </>
          )}
        </div>
      </PageHeadLine>
      <PageContent hasTitle isCentered={collections?.length === 0}>
        {collections?.length !== 0 && (
          <div>
            <div className="sortable-table-header">
              <div className="r-66">Name</div>
              <div className="r-33">Articles</div>
              <div className="r-33">Created at</div>
            </div>
            <SortableList
              pressDelay={100}
              itemsList={collections}
              onSortEnd={onSortEnd}
              useDragHandle
              helperClass="sortable-helper"
            />
          </div>
        )}
        {collections?.length === 0 && (
          <div className="no-surveys-container">
            <img
              src={NoArticleImage}
              alt="No articles created yet"
              className="no-surveys-image"
            />
            <>
              <HeadLine
                title="No collections created yet"
                subtitle="Collections are containers for your help articles. Start by creating a collection."
              />
              <PrimaryButton
                icon="plus"
                iconSideRight={false}
                onClick={async () => {
                  modalStore!.openModal(MODALTYPE.CREATE_HELPCENTER_COLLECTION);
                }}
                label={'Add collection'}
              />
            </>
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'helpcenterStore',
  'modalStore',
)(observer(ProjectHelpcenter));
