import moment from 'moment';
import { Bug } from 'models/Bug';
import * as React from 'react';
import { getDuplicatedBugs } from 'services/BugHttpService';
import './BugDuplicates.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import LinkButton from 'components/LinkButton/LinkButton';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { BugStore } from 'stores/private/BugStore';
import { ampli } from 'ampli';
import SidebarFoldable from 'components/SidebarFoldable/SidebarFoldable';

interface BugDuplicatesProps {
  bug?: Bug;
  projectStore?: ProjectStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const BugDuplicates = ({
  bug,
  projectStore,
  bugStore,
  modalStore,
}: BugDuplicatesProps) => {
  const [duplicatedBugs, setDuplicatedBugs] = React.useState([] as any[]);
  const [duplicateOf, setDuplicateOf] = React.useState(null as any);
  const [loading, setLoading] = React.useState(true);

  const loadDuplicates = async () => {
    setLoading(true);
    if (bug && bug.id) {
      try {
        const response = await getDuplicatedBugs(bug.id);
        if (response.status === 200) {
          setDuplicatedBugs(response.data.duplicates);
          setDuplicateOf(response.data.duplicateOf);
        }
        // eslint-disable-next-line no-empty
      } catch (err: any) { }
    }

    setLoading(false);
  };

  React.useEffect(() => {
    loadDuplicates();
  }, []);

  if (loading) {
    return (
      <SidebarFoldable title='Duplicates' infoKey='duplicates'>
        <div className="link-button-loading">
          <LoadingAnimationSmall />
        </div>
      </SidebarFoldable>
    );
  }

  if (
    (!duplicatedBugs || duplicatedBugs.length === 0) &&
    !duplicateOf &&
    !bug?.archived
  ) {
    return (
      <SidebarFoldable title='Duplicates' infoKey='duplicates'>
        <LinkButton
          onClick={() => {
            modalStore!.openModal(MODALTYPE.DUPLICATE_SEARCH, {}, true);

            ampli.duplicateModalOpened();
          }}
          iconSideRight={false}
          icon="copy"
          label="Merge with similar"
          className="bfw"
        />
      </SidebarFoldable>
    );
  }

  if (duplicateOf && duplicateOf !== '') {
    return (
      <SidebarFoldable title='Duplicates' infoKey='duplicates'>
        <div className="duplicated-bugs">
          <div className="duplicated-bugs-title">
            This item is a duplicate of
          </div>
          <div
            className="duplicated-bug-container"
            onClick={() => {
              projectStore!.openFeedbackItem({
                shareToken: duplicateOf.shareToken,
              });

              ampli.duplicateOpened();
            }}
          >
            <div className="duplicated-bug-body">
              #{duplicateOf.bugId},{' '}
              <span className="date">
                {moment(duplicateOf.createdAt).format('MMM Do')}
              </span>
            </div>
          </div>
          <div className="duplicated-bug-footer">
            <LinkButton
              className="bfw"
              icon="link-slash"
              iconSideRight={false}
              label="Unlink duplicate"
              onClick={() => {
                if (bug) {
                  bugStore!.removeBugAsDuplicate(bug.id);
                  modalStore!.closeModal();
                  projectStore!.refreshData();

                  ampli.duplicateUnlinked({
                    bugId: bugStore?.currentBug?.id!,
                  });
                }
              }}
            />
          </div>
        </div>
      </SidebarFoldable>
    );
  }

  const getDuplicateCount = () => {
    if (bug?.duplicatesCount) {
      return bug?.duplicatesCount;
    }

    if (duplicatedBugs) {
      return duplicatedBugs.length;
    }

    return 0;
  };

  const duplicateCount = getDuplicateCount();
  if (duplicatedBugs && duplicatedBugs.length !== 0) {
    return (
      <SidebarFoldable title="Duplicates" infoKey='duplicates' tag={`${duplicateCount}`}>
        <div className="duplicated-bugs">
          <div className="inner-duplicated-bug-container">
            {duplicatedBugs &&
              duplicatedBugs.map((dupBug) => {
                return (
                  <div
                    key={dupBug.id}
                    className="duplicated-bug-container"
                    onClick={() => {
                      projectStore!.openFeedbackItem({
                        shareToken: dupBug.shareToken,
                      });

                      ampli.duplicateOpened();
                    }}
                  >
                    <div className="duplicated-bug-body">
                      #{dupBug.bugId},{' '}
                      <span className="date">
                        {moment(dupBug.createdAt).format('MMM Do')}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {!bug?.archived && (
          <LinkButton
            onClick={() => {
              modalStore!.openModal(MODALTYPE.DUPLICATE_SEARCH, {}, true);

              ampli.duplicateModalOpened();
            }}
            iconSideRight={false}
            icon="copy"
            label="Merge with similar"
            className="bfw"
          />
        )}
      </SidebarFoldable>
    );
  }

  return <></>;
};

export default inject(
  'projectStore',
  'bugStore',
  'modalStore',
)(observer(BugDuplicates));
