import { ReactComponent as GleapLogo } from 'assets/icons/GleapLogo.svg';
import MainNavBar from 'components/MainNavBar/MainNavBar';
import MobileNavigation from 'components/MobileNavigation/MobileNavigation';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHotkey } from 'services/Helper';
import WebSocketHelper from 'services/WebSocketService';
import { AsanaIntegrationStore } from 'stores/integrations/AsanaIntegrationStore';
import { AworkIntegrationStore } from 'stores/integrations/AworkIntegrationStore';
import { AzureDevopsIntegrationStore } from 'stores/integrations/AzureDevopsIntegrationStore';
import { ClickupIntegrationStore } from 'stores/integrations/ClickupIntegrationStore';
import { GithubIntegrationStore } from 'stores/integrations/GithubIntegrationStore';
import { GitlabIntegrationStore } from 'stores/integrations/GitlabIntegrationStore';
import { HelpscoutIntegrationStore } from 'stores/integrations/HelpscoutIntegrationStore';
import { HubspotIntegrationStore } from 'stores/integrations/HubspotIntegrationStore';
import { JiraIntegrationStore } from 'stores/integrations/JiraIntegrationStore';
import { LinearIntegrationStore } from 'stores/integrations/LinearIntegrationStore';
import { NotionIntegrationStore } from 'stores/integrations/NotionIntegrationStore';
import { PlutioIntegrationStore } from 'stores/integrations/PlutioIntegrationStore';
import { ShortcutIntegrationStore } from 'stores/integrations/ShortcutIntegrationStore';
import { SlackIntegrationStore } from 'stores/integrations/SlackIntegrationStore';
import { TrelloIntegrationStore } from 'stores/integrations/TrelloIntegrationsStore';
import { ZendeskIntegrationStore } from 'stores/integrations/ZendeskIntegrationStore';
import { BugStore } from 'stores/private/BugStore';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SessionStore } from 'stores/private/SessionStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import { StripeStore } from 'stores/private/StripeStore';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedSessionStore } from 'stores/public/SharedSessionStore';
import ModalScreen from '../components/Modals/Modal/Modal';
import { UsersStore } from '../stores/private/UsersStore';
import MainRouting from './MainRouting';
import './Routing.scss';

interface RoutingProps {
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
  stripeStore?: StripeStore;
  asanaIntegrationStore?: AsanaIntegrationStore;
  aworkIntegrationStore?: AworkIntegrationStore;
  clickupIntegrationStore?: ClickupIntegrationStore;
  githubIntegrationStore?: GithubIntegrationStore;
  gitlabIntegrationStore?: GitlabIntegrationStore;
  jiraIntegrationStore?: JiraIntegrationStore;
  notionIntegrationStore?: NotionIntegrationStore;
  shortcutIntegrationStore?: ShortcutIntegrationStore;
  trelloIntegrationStore?: TrelloIntegrationStore;
  zendeskIntegrationStore?: ZendeskIntegrationStore;
  hubspotIntegrationStore?: HubspotIntegrationStore;
  plutioIntegrationStore?: PlutioIntegrationStore;
  sessionStore?: SessionStore;
  outboundStore?: OutboundStore;
  sharedSessionStore?: SharedSessionStore;
  helpcenterStore?: HelpcenterStore;
  slackIntegrationStore?: SlackIntegrationStore;
  helpscoutIntegrationStore?: HelpscoutIntegrationStore;
  sidenavStore?: SidenavStore;
  sharedBoardStorePublic?: SharedBoardStorePublic;
  azureDevopsIntegrationStore?: AzureDevopsIntegrationStore;
  linearIntegrationStore?: LinearIntegrationStore;
}

var lastNavigation = '';

const checkIfCurrrentPathIsPrivateRoute = (location) => {
  return (
    location.pathname !== '/' &&
    !location.pathname.includes('register') &&
    !location.pathname.includes('join') &&
    !location.pathname.includes('forgetpassword') &&
    !location.pathname.includes('resetpassword') &&
    !location.pathname.includes('appsumo') &&
    !location.pathname.includes('share')
  );
};

const Routing = ({
  usersStore,
  projectStore,
  bugStore,
  modalStore,
  organisationStore,
  stripeStore,
  asanaIntegrationStore,
  aworkIntegrationStore,
  shortcutIntegrationStore,
  clickupIntegrationStore,
  githubIntegrationStore,
  gitlabIntegrationStore,
  jiraIntegrationStore,
  notionIntegrationStore,
  trelloIntegrationStore,
  zendeskIntegrationStore,
  hubspotIntegrationStore,
  plutioIntegrationStore,
  sessionStore,
  outboundStore,
  sharedSessionStore,
  helpcenterStore,
  slackIntegrationStore,
  helpscoutIntegrationStore,
  sidenavStore,
  sharedBoardStorePublic,
  azureDevopsIntegrationStore,
  linearIntegrationStore,
}: RoutingProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [preLoading, setPreLoading] = useState(true);
  const [sharedView, setSharedView] = useState(true);
  const [initialLoader, setInitialLoader] = useState(true);
  const [canAnimateMainPage, setCanAnimateMainPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname) {
      if (
        location.pathname.includes('/share/') ||
        location.pathname.includes('/unsubscribe') ||
        location.pathname.includes('/sharedboard/')
      ) {
        setSharedView(true);
      } else {
        setSharedView(false);
      }
      setInitialLoader(false);

      if (lastNavigation.length > 0 && lastNavigation !== location.pathname) {
        setCanAnimateMainPage(true);
      }
      lastNavigation = location.pathname;
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setPreLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    try {
      const theme = localStorage.getItem('theme');
      if (theme) {
        const darkModeOn = theme === 'dark';

        if (
          !(
            location &&
            (location.pathname.includes('/share/') ||
              location.pathname.includes('/unsubscribe') ||
              location.pathname.includes('/sharedboard/'))
          )
        ) {
          if (darkModeOn) {
            document.body.classList.add('theme--dark');
            document.body.classList.remove('theme--default');
          } else {
            document.body.classList.remove('theme--dark');
            document.body.classList.add('theme--default');
          }
        }
      } else {
        localStorage.setItem('theme', 'default');
      }
    } catch (exp) { }
  }, []);

  useHotkey(
    75,
    () => {
      if (!usersStore?.currentUser || !projectStore?.currentProject) {
        return null;
      }

      if (modalStore?.currentPath === MODALTYPE.GLOBAL_SEARCH) {
        modalStore?.closeModal();
        return null;
      }

      modalStore?.openModal(MODALTYPE.GLOBAL_SEARCH);
    },
    false,
  );

  const checkUserAuthState = async () => {
    if (!usersStore?.isTokenAvailable()) {
      setLoading(false);
      if (checkIfCurrrentPathIsPrivateRoute(location)) {
        usersStore!.logout();
      }
      return false;
    }

    const user = await usersStore?.checkUserAuthState();
    if (!user && checkIfCurrrentPathIsPrivateRoute(location)) {
      const currentPath = window.location.pathname;
      if (currentPath) {
        navigate(`/login?redirect=${currentPath}`, { replace: true });
      } else {
        navigate('/login', { replace: true });
      }
    }

    setLoading(false);
    return true;
  };

  useEffect(() => {
    projectStore!.setStores({
      navigate,
      bugStore,
      organisationStore,
      usersStore,
      modalStore,
      outboundStore,
      sharedSessionStore,
    });
    sharedSessionStore!.setStores({
      projectStore,
    });
    organisationStore!.setStores({
      usersStore,
      projectStore,
      navigate,
      stripeStore,
      modalStore,
    });
    stripeStore!.setStores({
      organisationStore,
    });
    bugStore!.setStores({
      projectStore,
      navigate,
      modalStore,
      usersStore,
      sharedSessionStore,
    });
    helpcenterStore!.setStores({
      projectStore,
    });
    usersStore!.setStores({ navigate, organisationStore, projectStore });
    modalStore!.setStores({
      bugStore,
    });
    asanaIntegrationStore!.setStores({ projectStore });
    aworkIntegrationStore!.setStores({ projectStore });
    clickupIntegrationStore!.setStores({ projectStore });
    githubIntegrationStore!.setStores({ projectStore });
    gitlabIntegrationStore!.setStores({ projectStore });
    jiraIntegrationStore!.setStores({ projectStore });
    notionIntegrationStore!.setStores({ projectStore });
    trelloIntegrationStore!.setStores({ projectStore });
    zendeskIntegrationStore!.setStores({ projectStore });
    hubspotIntegrationStore!.setStores({ projectStore });
    shortcutIntegrationStore!.setStores({ projectStore });
    plutioIntegrationStore!.setStores({ projectStore });
    slackIntegrationStore!.setStores({ projectStore });
    helpscoutIntegrationStore!.setStores({ projectStore });
    azureDevopsIntegrationStore!.setStores({ projectStore });
    linearIntegrationStore!.setStores({ projectStore });
    outboundStore!.setStores({
      projectStore,
      navigate,
    });
    sessionStore!.setStores({
      projectStore,
    });
    sharedBoardStorePublic!.setStores({
      sharedSessionStore,
    });
    WebSocketHelper.getInstance().setStores({
      bugStore,
      projectStore,
      organisationStore,
      usersStore,
      outboundStore,
    });

    checkUserAuthState();
  }, []);

  if ((loading || preLoading) && !sharedView) {
    return (
      <div className="main-loaader">
        <div className="loading-indicator-bg"></div>
        <div className="loading-indicator">
          <div className="loading-indicator--inner">
            <GleapLogo />
          </div>
        </div>
        <div className="loadung-message">Welcome to Gleap 🎉</div>
      </div>
    );
  }

  if ((loading || preLoading) && initialLoader) {
    return <div className="main-loaader"></div>;
  }

  const userIsComplete =
    usersStore?.currentUser && usersStore.currentUser.confirmed;

  const showConnectionIssue = usersStore?.pusherState && usersStore?.pusherState !== 'connected';

  return (
    <div
      className={`main-page ${(sharedView || !userIsComplete || !canAnimateMainPage) &&
        'main-page--no-animation'
        } ${(sidenavStore?.mainSidenavHidden || sharedView || !userIsComplete) &&
        'main-page--nav-bar-hidden'
        }`}
    >
      {!sharedView && <MainNavBar />}
      {!sharedView && showConnectionIssue && <div className='websocket-connection-state'>
        <i className="fa-solid fa-triangle-exclamation"></i>
        {usersStore?.pusherState}
        <a href='#' onClick={() => {
          window.location.reload();
        }}>(Refresh now)</a>
      </div>}
      {!sharedView && <MobileNavigation />}
      <div className="main-content-page">
        <MainRouting />
      </div>
      <ModalScreen />
    </div>
  );
};

export default inject(
  'usersStore',
  'projectStore',
  'organisationStore',
  'bugStore',
  'stripeStore',
  'modalStore',
  'asanaIntegrationStore',
  'aworkIntegrationStore',
  'clickupIntegrationStore',
  'githubIntegrationStore',
  'gitlabIntegrationStore',
  'jiraIntegrationStore',
  'notionIntegrationStore',
  'shortcutIntegrationStore',
  'trelloIntegrationStore',
  'zendeskIntegrationStore',
  'hubspotIntegrationStore',
  'plutioIntegrationStore',
  'sessionStore',
  'outboundStore',
  'sharedSessionStore',
  'helpcenterStore',
  'slackIntegrationStore',
  'helpscoutIntegrationStore',
  'sidenavStore',
  'sharedBoardStorePublic',
  'azureDevopsIntegrationStore',
  'linearIntegrationStore',
)(observer(Routing));
