import { makeAutoObservable } from 'mobx';
import Gleap from 'gleap';
import { isApp } from 'App';

export enum MODALTYPE {
  INVISIBLE = 'INVISIBLE',
  INVITE_PEOPLE = 'INVITE_PEOPLE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHECKOUT = 'CHECKOUT',
  BILLING_DETAILS = 'BILLING_DETAILS',
  CHANGE_BILLING_CARD = 'CHANGE_BILLING_CARD',
  MANUALLY_ADD_BUG = 'MANUALLY_ADD_BUG',
  SHOW_EVENT_DATA = 'SHOW_EVENT_DATA',
  BUG_DETAIL = 'BUG_DETAIL',
  SDK_INSTALLATION_TUTORIAL = 'SDK_INSTALLATION_TUTORIAL',
  PRICING_TABLE = 'PRICING_TABLE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  SUGGESTSUBSCRIPTION = 'SUGGESTSUBSCRIPTION',
  DARKSIDE = 'DARKSIDE',
  ADDMENUOPTION = 'ADDMENUOPTION',
  ACTIONEDITOR = 'ACTIONEDITOR',
  VOTE = 'VOTE',
  FEEDBACK_TYPE = 'FEEDBACK_TYPE',
  SUBSCRIBE_USER = 'SUBSCRIBE_USER',
  CREATE_FEATURE_REQUEST = 'CREATE_FEATURE_REQUEST',
  FEATURE_BOARD_SHARE = 'FEATURE_BOARD_SHARE',
  INVITE_PEOPLE_ONBOARDING = 'INVITE_PEOPLE_ONBOARDING',
  ONBOARDING = 'ONBOARDING',
  UPSELL_MODAL = 'UPSELL_MODAL',
  DUPLICATE_SEARCH = 'DUPLICATE_SEARCH',
  REPLAYS = 'REPLAYS',
  MANUALLY_SEND_INTEGRATION = 'MANUALLY_SEND_INTEGRATION',
  CREATE_SURVEY = 'CREATE_SURVEY',
  COMPOSE_MESSAGE = 'COMPOSE_MESSAGE',
  WIDGET_APP_SETTINGS = 'WIDGET_APP_SETTINGS',
  CANCEL_PLAN = 'CANCEL_PLAN',
  CREATE_HELPCENTER_COLLECTION = 'CREATE_HELPCENTER_COLLECTION',
  SURVEY_PREVIEW = 'SURVEY_PREVIEW',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  EDIT_FEATURE_REQUEST = 'EDIT_FEATURE_REQUEST',
  CREATE_QA = 'CREATE_QA',
  EDIT_QA = 'EDIT_QA',
  EDIT_INBOX_VIEW = 'EDIT_INBOX_VIEW',
}

export class ModalStore {
  currentPath: MODALTYPE = MODALTYPE.INVISIBLE;
  currentNestedPath: MODALTYPE = MODALTYPE.INVISIBLE;
  customData: any;
  nestedCustomData: any;
  customDataType = {};
  stores: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  getCustomData(path: MODALTYPE) {
    return this.customDataType[path];
  }

  openModalSmart = (path: MODALTYPE, customData?: any) => {
    this.customDataType[path] = customData;
    if (this.currentPath !== MODALTYPE.INVISIBLE) {
      this.currentNestedPath = path;
    } else {
      this.currentPath = path;
    }
  };

  openModal = (path: MODALTYPE, customData?: any, nested = false) => {
    this.customDataType[path] = customData;
    if (!nested) {
      this.currentPath = path as MODALTYPE;
      this.customData = customData;
    } else {
      this.currentNestedPath = path as MODALTYPE;
      this.nestedCustomData = customData;
    }

    this.checkState();
  };

  closeModal = (clean = true) => {
    if (
      this.currentPath === MODALTYPE.BUG_DETAIL &&
      !window.location.pathname.includes('statistics') &&
      this.currentNestedPath === MODALTYPE.INVISIBLE &&
      clean
    ) {
      this.stores.bugStore.clearCurrentBug();
    }

    if (this.currentNestedPath !== MODALTYPE.INVISIBLE) {
      this.currentNestedPath = MODALTYPE.INVISIBLE;
    } else {
      this.currentPath = MODALTYPE.INVISIBLE;
    }

    this.checkState();
  };

  checkState = () => {
    if (window.innerWidth > 950 || isApp) {
      return;
    }

    if (this.currentPath !== MODALTYPE.INVISIBLE) {
      Gleap.showFeedbackButton(false);
    } else {
      Gleap.showFeedbackButton(true);
    }
  };
}
