import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import {
  getOrganizations,
  getProjects,
  getWorkItemTypesForProject,
} from 'services/integrations/AzureDevopsHttpService';

export class AzureDevopsIntegrationStore {
  stores: any = {};

  integrationID: string = '';

  organizations: any = [];
  projects: any = [];
  workItemTypes: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setOrganizations = (organizations) => {
    this.organizations = organizations;
  };

  getOrganizations = async () => {
    try {
      const response = await getOrganizations({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setOrganizations(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setProjects = (projects) => {
    this.projects = projects;
  };

  getProjects = async (organizationID: string) => {
    try {
      const response = await getProjects({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        organizationID: organizationID,
      });
      if (response.status === 200) {
        this.setProjects(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setWorkItemTypes = (workItemTypes) => {
    this.workItemTypes = workItemTypes;
  };

  getWorkItemTypes = async (organizationID: string, projectID: string) => {
    try {
      const response = await getWorkItemTypesForProject({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        organizationID: organizationID,
        devopsProjectID: projectID,
      });
      if (response.status === 200) {
        this.setWorkItemTypes(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
