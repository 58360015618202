import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { PlutioIntegrationStore } from 'stores/integrations/PlutioIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface PlutioConfigurationProps {
  integrationID: string;
  index: number;
  plutioIntegrationStore?: PlutioIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const PlutioConfiguration = ({
  integrationID,
  index,
  plutioIntegrationStore,
  projectStore,
  onChange,
}: PlutioConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [businessID, setBusinessID] = useState('');
  const [businessDomain, setBusinessDomain] = useState('');
  const businesses = plutioIntegrationStore?.businesses;

  const [projectID, setProjectID] = useState('');
  const projects = plutioIntegrationStore?.projects;

  const [taskBoardID, settaskBoardID] = useState('');
  const taskBoards = plutioIntegrationStore?.taskBoards;

  const [taskGroupID, setTaskGroupID] = useState('');
  const taskGroups = plutioIntegrationStore?.taskGroups;

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [businessID, projectID, taskBoardID, taskGroupID]);

  const initialize = async () => {
    plutioIntegrationStore!.setIntegrationID(integrationID);
    await plutioIntegrationStore!.getBusinesses();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.PLUTIO,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.plutio[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseBusiness = (business) => {
    // eslint-disable-next-line no-underscore-dangle
    setBusinessID(business._id);
    setBusinessDomain(business.domain);
    plutioIntegrationStore!.getProjects(business.domain);
  };

  const chooseProject = async (projectId) => {
    setProjectID(projectId);
    plutioIntegrationStore!.getTaskBoards(businessDomain, projectId);
  };

  const chooseTaskBoard = async (taskBoardId) => {
    settaskBoardID(taskBoardId);
    plutioIntegrationStore!.getTaskGroups(
      businessDomain,
      projectID,
      taskBoardId,
    );
  };

  const chooseTaskGroup = async (taskGroupId) => {
    setTaskGroupID(taskGroupId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      businessID,
      businessDomain,
      projectID,
      taskBoardID,
      taskGroupID,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Business"
        labelPropertyName="name"
        valuePropertyName="_id"
        selectedItem={businesses.find(
          // eslint-disable-next-line no-underscore-dangle
          (element) => element._id === currentIntegrationInfo?.businessID,
        )}
        isLoading={businesses.length === 0}
        items={businesses}
        onChange={(option) => {
          chooseBusiness(option);
        }}
      />
      {businessID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Project (optional)"
          labelPropertyName="name"
          valuePropertyName="_id"
          selectedItem={projects.find(
            // eslint-disable-next-line no-underscore-dangle
            (element) => element._id === currentIntegrationInfo?.projectID,
          )}
          isLoading={projects.length === 0}
          items={projects}
          onChange={(option) => {
            // eslint-disable-next-line no-underscore-dangle
            chooseProject(option._id);
          }}
        />
      )}
      {projectID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Task board (optional)"
          labelPropertyName="title"
          valuePropertyName="_id"
          selectedItem={taskBoards.find(
            // eslint-disable-next-line no-underscore-dangle
            (element) => element._id === currentIntegrationInfo?.taskBoardID,
          )}
          items={taskBoards}
          onChange={(option) => {
            // eslint-disable-next-line no-underscore-dangle
            chooseTaskBoard(option._id);
          }}
        />
      )}
      {taskBoardID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Task group (optional)"
          labelPropertyName="title"
          valuePropertyName="_id"
          selectedItem={taskGroups.find(
            // eslint-disable-next-line no-underscore-dangle
            (element) => element._id === currentIntegrationInfo?.taskGroupID,
          )}
          items={taskGroups}
          onChange={(option) => {
            // eslint-disable-next-line no-underscore-dangle
            chooseTaskGroup(option._id);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'plutioIntegrationStore',
  'projectStore',
)(observer(PlutioConfiguration));
