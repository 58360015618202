import InfoBox from 'components/InfoBox/InfoBox';
import Loading from 'components/Loading/Loading';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { SlackIntegrationStore } from 'stores/integrations/SlackIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface SlackConfigurationProps {
  integrationID: string;
  index: number;
  projectStore?: ProjectStore;
  slackIntegrationStore?: SlackIntegrationStore;
  onChange: (data: any) => any;
}

const SlackConfiguration = ({
  integrationID,
  index,
  projectStore,
  slackIntegrationStore,
  onChange,
}: SlackConfigurationProps) => {
  const project = projectStore?.currentProject;

  const channels = slackIntegrationStore?.channels;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [channelID, setChannelID] = useState('');
  const [notifyOnly, setNotifyOnly] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [notifyOnly, channelID]);

  const initialize = async () => {
    slackIntegrationStore!.setIntegrationID(integrationID);
    await slackIntegrationStore!.getChannels();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.SLACK,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.slack[integrationID]?.actions[index]
        ?.integrationInfo;

    setNotifyOnly(integrationInfo.notifyOnly ?? false);
    setCurrentIntegrationInfo(integrationInfo);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      notifyOnly,
      channelID,
    };

    onChange(integrationInfo);
  };

  if (!(channels && channels.length > 0) && !slackIntegrationStore?.loadingChannels) {
    return <div className='text'>This integration is outdated. Please remove the integration and re-connect it again.</div>;
  }

  if (!(channels && channels.length > 0)) {
    return <Loading />;
  }

  return (
    <div className="configuration-container">
      <SelectDropDown
        className="mb-15"
        label="Channel"
        labelPropertyName="name"
        valuePropertyName="id"
        getOptionlabel={(option) => {
          if (option.is_private) {
            return `🔒 ${option.name}`;
          }
          return `# ${option.name}`;
        }}
        selectedItem={channels.find(
          (element) => element.id === currentIntegrationInfo?.channelID,
        )}
        isLoading={channels?.length === 0}
        items={channels}
        onChange={(option) => {
          setChannelID(option.id);
        }}
      />
      <InfoBox className='mb-20'>
        🔒 To <b>connect Gleap to a private channel</b>, you must first add the Gleap app as a member of the channel. Click on the channel name, then select the "Integrations" tab and click on the "Add an app". Now choose "Gleap" and click on "Add". After that, refresh this page to choose your private channel.
      </InfoBox>
      <div className="switch-container">
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            setNotifyOnly(checked);
          }}
          checked={notifyOnly ? true : false}
        />
        <span>Send fewer details only (Email, bug type & form data).</span>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'slackIntegrationStore',
)(observer(SlackConfiguration));
