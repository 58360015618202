import { HeadLine } from 'components/HeadLine/HeadLine';
import ShareableUrl from 'components/ShareableUrl/ShareableUrl';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FeatureBoardShareModal.scss';
import { useNavigate } from 'react-router';

interface FeatureBoardShareModalProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
}

const FeatureBoardShareModal = ({
  modalStore,
  projectStore,
}: FeatureBoardShareModalProps) => {
  const shareURL = `${process.env.REACT_APP_BASEURL}/sharedboard/${modalStore?.customData.apiKey}`;
  const navigate = useNavigate();

  return (
    <div className="share-board-modal">
      <HeadLine
        title="Share options"
        subtitle="Share your feature requests & roadmap with your customers."
      />
      <div className="options-group mt-20">
        <div className="options-group-header">Roadmap portal URL</div>
        <div className="share-url">
          <ShareableUrl url={shareURL} />
        </div>
        <div className='options-text-footer centered text mt-20'>
          <a href="#" className='mr-5' onClick={() => {
            modalStore?.closeModal();
            navigate(`/projects/${projectStore?.currentProject?.id}/featurerequests/settings`);
          }}>Configure a custom domain</a> for your roadmap portal.
        </div>
      </div>
    </div>
  );
};

export default inject('modalStore', 'projectStore')(observer(FeatureBoardShareModal));
