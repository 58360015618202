export const defaultTranslations = {
  cz: {
    Hi: 'Ahoj',
    Submit: 'Předložit',
    Next: 'Příští',
    'Short, descriptive title': 'Krátký popisný název',
    'Let us know how we can do better.': 'Dejte nám vědět, co bychom mohli zlepšit',
    'Report a bug or share your feedback with us.': 'Nahlaste chybu nebo se s námi podělte o svůj názor.',
    Feedback: 'Feedback',
    'We are here to help.': 'Jsme tu, abychom vám pomohli.',
    'What would you like to see next?': 'Kterou funkci byste chtěli vidět dále?',
    'Report an issue': 'Nahlásit problém',
    'Found a bug? Let us know.': 'Našli jste chybu? Dej nám vědět.',
    'Wrong API key': 'Špatný klíč API',
    'Click and drag to mark the bug': 'Kliknutím a přetažením označte chybu',
    'I read and accept the ': 'Přečetl jsem si a přijímám ',
    'privacy policy': 'zásady ochrany osobních údajů',
    'Problem detected': 'Byl zjištěn problém',
    'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.':
      'Oh, oh vypadá to, že se tu něco pokazilo. Odesláním tohoto formuláře nám pomůžete problém vyřešit a vylepšit čas.',
    'Thank you for your feedback.': 'Děkujeme vám za vaši reakci.',
    Email: 'E-mail',
    'Your e-mail': 'Tvůj e-mail',
    'Tell us more about the problem': 'Řekněte nám o problému více',
    'Describe what went wrong': 'Popište, co se stalo',
    'Send report': 'Poslat zprávu',
    'Describe the issue': 'Popište problém',
    'The more information, the better.': 'Čím více informací, tím lépe.',
    'Please tell us more': 'Prosím, řekněte nám víc',
    'The more details, the better.': 'Čím více podrobností, tím lépe.',
    'Send feedback': 'Poslat zpětnou vazbu',
    'Thanks for your message. We will be in touch shortly': 'Díky za vaši zprávu. Brzy budeme v kontaktu.',
    Message: 'Zpráva',
    'Your message': 'Vaše zpráva',
    'Send message': 'Poslat zprávu',
    'Request a feature': 'Požádejte o funkci',
    'What feature would you like to see next?': 'Jakou funkci byste chtěli vidět příště?',
    'Explain your request.': 'Vysvětlete svůj požadavek.',
    Subject: 'Předmět',
    Description: 'Popis',
    'Send request': 'Poslat žádost',
    'Mark the bug': 'Označte problém',
    'The file you chose exceeds the file size limit of 3MB.': 'The file you chose exceeds the file size limit of 3MB.',
    Screenshot: 'Screenshot',
    'Screen recording': 'Záznam obrazovky',
    Retry: 'Zkus to znovu',
    Mute: 'mikrofon vypnutý',
    Unmute: 'mikrofon zapnutý',
    'Browser not supported': 'Prohlížeč není podporován',
    'Stop recording': 'Zastavit nahrávání',
    'Start recording': 'Začít nahrávat',
    'Record screen': 'Video',
    'Pick a color': 'Vyber barvu',
    Undo: 'vrátit',
    'Draw on the screen to mark the bug': 'Nakreslete na obrazovku a označte chybu',
    'Record your screen to showcase the bug': 'Nahrajte video, abyste ukázali chybu',
    'Extremely likely': 'Velmi pravděpodobné',
    'Not likely at all': 'Vůbec ne',
    'Something went wrong, please try again.': 'Something went wrong, please try again.',
    'Choose a file or drag it here.': 'Vyberte soubor nebo jej přetáhněte sem.',
    'We usually reply': 'Obvykle odpovídáme',
    'We are back': 'Jsme zpět',
    You: 'Vy',
    'Write a reply...': 'Odeslat zprávu...',
    'Let us know what you would like to see next.': 'Dejte nám vědět, co byste rádi viděli příště.',
    'Hey 👋, how can we help you?': 'Ahoj 👋, jak vám můžeme pomoci?',
    'within a few minutes': 'během několika minut',
    'within a few hours': 'během několika hodin',
    'within a day': 'během jednoho dne',
    'We are currently offline but will get back to you as soon as possible.': 'Právě jsme offline, ale brzy se vám vrátíme.',
    News: 'News',
    'View all': 'Zobrazit vše',
    'Feature requests': 'Feature requests',
    'How can we help?': 'Jak můžeme pomoci?',
    'Send us a message': 'Pošlete nám zprávu',
    Roadmap: 'Roadmap',
    Messages: 'Zprávy',
    Help: 'Pomoc',
    'Search for help': 'Hledat pomoc',
    'Search results for': 'Výsledky vyhledávání pro',
    'No messages yet': 'Zatím žádné zprávy',
    Home: 'Home',
    "Unfortunately, I'm not able to answer your question at the moment.":
      'Bohužel, v tuto chvíli nemohu odpovědět na vaši otázku.',
    Answer: 'Odpověď',
    'Our bot will reply instantly': 'Naše bot odpoví okamžitě',
    'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.': 'Našli jsme existující požadavky na funkce, které by mohly souhlasit s vaším nápadem. Hlasujte pro ty, které se vám líbí, nebo pokračujte a přidejte svůj vlastní nápad.',
    'Submit my idea': 'Přidejte svůj nápad',
    'Great! Any details you want to add to your feature request?': 'Skvělé! Chcete svému požadavku na funkci přidat nějaké podrobnosti?',
    "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.": 'Vypadá to, že jste našli problém. Byli bychom rádi, kdybyste mohli kliknout na tlačítko níže a nahlásit jej našemu týmu.',
  },
  de: {
    Submit: 'Absenden',
    Hi: 'Hallo',
    'Short, descriptive title': 'Kurzer, aussagekräftiger Titel',
    'Let us know how we can do better.': 'Lass uns wissen, was wir besser machen können',
    'Report a bug or share your feedback with us.': 'Melde einen Fehler oder teile dein Feedback mit uns.',
    Feedback: 'Feedback',
    'We are here to help.': 'Wir helfen gerne weiter.',
    'What would you like to see next?': 'Welches neue Feature möchtest du anfragen?',
    'Report an issue': 'Melde einen Fehler',
    'Found a bug? Let us know.': 'Du hast einen Bug gefunden?',
    'Wrong API key': 'Falscher API Key',
    'Click and drag to mark the bug': 'Klicken & ziehen um den Fehler zu markieren',
    'I read and accept the ': 'Ich akzeptiere die ',
    'privacy policy': 'Datenschutzerklärung',
    'Problem detected': 'Problem erkannt',
    'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.':
      'Oh, oh scheint als wäre etwas schief gelaufen. Indem du dieses Formular abschickst, hilfst du uns das Problem zu beheben und uns zu verbessern.',
    'Thank you for your feedback.': 'Vielen Dank für dein Feedback.',
    Email: 'E-Mail',
    'Your e-mail': 'Deine E-Mail Adresse',
    'Tell us more about the problem': 'Kannst du uns mehr zum Problem sagen?',
    'Describe what went wrong': 'Beschreibe was schief gelaufen ist',
    'Send report': 'Report senden',
    'Describe the issue': 'Beschreibe das Problem',
    'The more information, the better.': 'Je mehr Informationen, desto besser.',
    'Please tell us more': 'Füge noch eine genauere Beschreibung hinzu',
    'The more details, the better.': 'Je mehr Details, desto besser.',
    'Send feedback': 'Feedback senden',
    'Thanks for your message. We will be in touch shortly': 'Danke für deine Nachricht. Wir melden uns in Kürze.',
    Message: 'Nachricht',
    'Your message': 'Deine Nachricht',
    'Send message': 'Nachricht senden',
    'Request a feature': 'Feature anfordern',
    'What feature would you like to see next?': 'Welches Feature würdest du gerne als nächstes sehen?',
    'Explain your request.': 'Beschreibe deinen Feature Request.',
    Subject: 'Betreff',
    Description: 'Beschreibung',
    'Send request': 'Request senden',
    Next: 'Weiter',
    'Mark the bug': 'Markiere den Fehler',
    'The file you chose exceeds the file size limit of 3MB.': 'Die ausgewählte Datei ist zu groß (max. 3MB).',
    Screenshot: 'Screenshot',
    'Screen recording': 'Bildschirmaufnahme',
    Retry: 'Erneut versuchen',
    Mute: 'Mikrofon aus',
    Unmute: 'Mikrofon ein',
    'Browser not supported': 'Browser nicht unterstützt',
    'Stop recording': 'Aufnahme beenden',
    'Start recording': 'Aufnahme starten',
    'Record screen': 'Video',
    'Pick a color': 'Farbe wählen',
    Undo: 'Rückgängig machen',
    'Draw on the screen to mark the bug': 'Markiere den Fehler auf dem Bildschirm',
    'Record your screen to showcase the bug': 'Nimm ein Video auf, um den Fehler zu zeigen',
    'Extremely likely': 'Sehr wahrscheinlich',
    'Not likely at all': 'Nicht wahrscheinlich',
    'Something went wrong, please try again.': 'Etwas ist schief gelaufen. Bitte versuche es später erneut.',
    'Choose a file or drag it here.': 'Wähle eine Datei aus',
    'We usually reply': 'Wir antworten',
    'We are back': 'Wir sind zurück',
    You: 'Du',
    'Write a reply...': 'Antworten...',
    'Let us know what you would like to see next.': 'Lass uns wissen, welches Feature du als nächstes sehen möchtest.',
    'Hey 👋, how can we help you?': 'Hey 👋, wie können wir dir helfen?',
    'within a few minutes': 'in wenigen Minuten',
    'within a few hours': 'in wenigen Stunden',
    'within a day': 'in einem Tag',
    'We are currently offline but will get back to you as soon as possible.':
      'Wir sind derzeit offline, aber werden uns so schnell wie möglich bei Ihnen melden.',
    News: 'News',
    'View all': 'Alle anzeigen',
    'Feature requests': 'Feature requests',
    'How can we help?': 'Wie können wir dir helfen?',
    'Send us a message': 'Schreib uns eine Nachricht',
    Roadmap: 'Roadmap',
    Messages: 'Nachrichten',
    Help: 'Hilfe',
    'Search for help': 'Nach Hilfe suchen',
    'Search results for': 'Suchergebnisse für',
    'No messages yet': 'Noch keine Nachrichten',
    Home: 'Home',
    "Unfortunately, I'm not able to answer your question at the moment.":
      'Leider kann ich deine Frage im Moment nicht beantworten.',
    Answer: 'Antwort',
    'Our bot will reply instantly': 'Unser Bot antwortet sofort',
    'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.': 'Wir haben bereits bestehende Feature Requests gefunden, die vielleicht zu deiner Idee passen. Unterstütze passende Vorschläge oder reiche einfach deinen eigenen ein.',
    'Submit my idea': 'Meine Idee einreichen',
    'Great! Any details you want to add to your feature request?': 'Super! Möchtest du noch Details zu deinem Feature Request hinzufügen?',
    "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.": 'Es sieht so aus, als hättest du einen Fehler gefunden. Wir würden uns freuen, wenn du den Button unten klickst, um ihn an unser Team zu melden.',
  },
  en: {
    Submit: 'Submit',
    Hi: 'Hi',
    'Short, descriptive title': 'Short, descriptive title',
    'Let us know how we can do better.': 'Let us know how we can do better.',
    'Report a bug or share your feedback with us.': 'Report a bug or share your feedback with us.',
    Feedback: 'Feedback',
    'We are here to help.': 'We are here to help.',
    'What would you like to see next?': 'What would you like to see next?',
    'Report an issue': 'Report an issue',
    'Found a bug? Let us know.': 'Found a bug? Let us know.',
    'Wrong API key': 'Wrong API key',
    'Click and drag to mark the bug': 'Click and drag to mark the bug',
    'I read and accept the ': 'I read and accept the ',
    'privacy policy': 'privacy policy',
    'Problem detected': 'Problem detected',
    'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.':
      'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.',
    'Thank you for your feedback.': 'Thank you for your feedback.',
    Email: 'Email',
    'Your e-mail': 'Your e-mail',
    'Tell us more about the problem': 'Tell us more about the problem',
    'Describe what went wrong': 'Describe what went wrong',
    'Send report': 'Send report',
    'Describe the issue': 'Describe the issue',
    'The more information, the better.': 'The more information, the better.',
    'Please tell us more': 'Please tell us more',
    'The more details, the better.': 'The more details, the better.',
    'Send feedback': 'Send feedback',
    'Thanks for your message. We will be in touch shortly': 'Thanks for your message. We will be in touch shortly',
    Message: 'Message',
    'Your message': 'Your message',
    'Send message': 'Send message',
    'Request a feature': 'Request a feature',
    'What feature would you like to see next?': 'What feature would you like to see next?',
    'Explain your request.': 'Explain your request.',
    Subject: 'Subject',
    Description: 'Description',
    'Send request': 'Send request',
    Next: 'Next',
    'Mark the bug': 'Mark the bug',
    'The file you chose exceeds the file size limit of 3MB.': 'The file you chose exceeds the file size limit of 3MB.',
    Screenshot: 'Screenshot',
    'Screen recording': 'Screen recording',
    Retry: 'Retry',
    Mute: 'Mute',
    Unmute: 'Unmute',
    'Browser not supported': 'Browser not supported',
    'Stop recording': 'Stop recording',
    'Start recording': 'Start recording',
    'Record screen': 'Record screen',
    'Pick a color': 'Pick a color',
    Undo: 'Undo',
    'Draw on the screen to mark the bug': 'Draw on the screen to mark the bug',
    'Record your screen to showcase the bug': 'Record your screen to showcase the bug',
    'Extremely likely': 'Extremely likely',
    'Not likely at all': 'Not likely at all',
    'Something went wrong, please try again.': 'Something went wrong, please try again.',
    'Choose a file or drag it here.': 'Choose a file or drag it here.',
    'We usually reply': 'We usually reply',
    'We are back': 'We are back',
    You: 'You',
    'Write a reply...': 'Write a reply...',
    'Let us know what you would like to see next.': 'Let us know what you would like to see next.',
    'Hey 👋, how can we help you?': 'Hey 👋, how can we help you?',
    'within a few minutes': 'within a few minutes',
    'within a few hours': 'within a few hours',
    'within a day': 'within a day',
    'We are currently offline but will get back to you as soon as possible.':
      'We are currently offline but will get back to you as soon as possible.',
    News: 'News',
    'View all': 'View all',
    'Feature requests': 'Feature requests',
    'How can we help?': 'How can we help?',
    'Send us a message': 'Send us a message',
    Roadmap: 'Roadmap',
    Messages: 'Messages',
    Help: 'Help',
    'Search for help': 'Search for help',
    'Search results for': 'Search results for',
    'No messages yet': 'No messages yet',
    Home: 'Home',
    "Unfortunately, I'm not able to answer your question at the moment.":
      "Unfortunately, I'm not able to answer your question at the moment.",
    Answer: 'Answer',
    'Our bot will reply instantly': 'Our bot will reply instantly',
    'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.': 'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.',
    'Submit my idea': 'Submit my idea',
    'Great! Any details you want to add to your feature request?': 'Great! Any details you want to add to your feature request?',
    "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.": "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.",
  },
  es: {
    Submit: 'Enviar',
    Hi: 'Hola',
    'Short, descriptive title': 'Título corto y descriptivo',
    'Let us know how we can do better.': 'Háganos saber cómo podemos mejorar',
    'Report a bug or share your feedback with us.': 'Reportar un bug o comparte tu opinión con nosotros.',
    Feedback: 'Feedback',
    'We are here to help.': 'Estamos aquí para ayudar.',
    'What would you like to see next?': '¿Qué función te gustaría ver a continuación?',
    'Report an issue': 'Reportar un problema',
    'Found a bug? Let us know.': '¿Encontraste un bug? Haznos saber.',
    'Wrong API key': 'Clave de API incorrecta',
    'Click and drag to mark the bug': 'Haga clic y arrastre para marcar el error',
    'I read and accept the ': 'He leído y acepto ',
    'privacy policy': 'las Política de privacidad',
    'Problem detected': 'Problema detectado',
    'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.':
      'Oh, oh parece que algo salió mal aquí. Al enviar este formulario, nos ayudará a solucionar el problema y a mejorar a lo grande.',
    'Thank you for your feedback.': 'Gracias por tus comentarios.',
    Email: 'Correo electrónico',
    'Your e-mail': 'Su dirección de correo electrónico',
    'Tell us more about the problem': 'Cuéntanos más sobre el problema',
    'Describe what went wrong': 'Describe lo que salió mal',
    'Send report': 'Enviar reporte',
    'Describe the issue': 'Describa el problema',
    'The more information, the better.': 'Cuanta más información mejor.',
    'Please tell us more': 'Cuéntanos más',
    'The more details, the better.': 'Cuantos más detalles, mejor.',
    'Send feedback': 'Envíe sus comentarios',
    'Thanks for your message. We will be in touch shortly': 'Gracias por tu mensaje. Estaremos en contacto dentro de poco.',
    Message: 'Mensaje',
    'Your message': 'Tu mensaje',
    'Send message': 'Enviar mensaje',
    'Request a feature': 'Solicita una función',
    'What feature would you like to see next?': '¿Qué característica le gustaría ver a continuación?',
    'Explain your request.': 'Explique su solicitud.',
    Subject: 'Sujeto',
    Description: 'Descripción',
    'Send request': 'Enviar solicitud',
    Next: 'Próximo',
    'Mark the bug': 'Marque el problema',
    'The file you chose exceeds the file size limit of 3MB.': 'The file you chose exceeds the file size limit of 3MB.',
    Screenshot: 'Screenshot',
    'Screen recording': 'Video',
    Retry: 'Intentar otra vez',
    Mute: 'Micrófono apagado',
    Unmute: 'Micrófono encendido',
    'Browser not supported': 'Navegador no compatible',
    'Stop recording': 'Para de grabar',
    'Start recording': 'Iniciar la grabación',
    'Record screen': 'Video',
    'Pick a color': 'Elige un color',
    Undo: 'Deshacer',
    'Draw on the screen to mark the bug': 'Marcar el error en la pantalla',
    'Record your screen to showcase the bug': 'Grabe un video para mostrar el error.',
    'Extremely likely': 'Más probable',
    'Not likely at all': 'No es probable',
    'Something went wrong, please try again.': 'Algo salió mal. Por favor, vuelva a intentarlo.',
    'Choose a file or drag it here.': 'Elija un archivo o arrástrelo aquí.',
    'We usually reply': 'Le responderemos',
    'We are back': 'Nosotros estamos de vuelta',
    You: 'Tú',
    'Write a reply...': 'Enviar un mensaje...',
    'Let us know what you would like to see next.': 'Háganos saber lo que le gustaría ver a continuación.',
    'Hey 👋, how can we help you?': 'Hey 👋, ¿cómo podemos ayudarte?',
    'within a few minutes': 'dentro de unos minutos',
    'within a few hours': 'dentro de unas horas',
    'within a day': 'en un día',
    'We are currently offline but will get back to you as soon as possible.':
      'Actualmente estamos fuera de línea, pero nos pondremos en contacto con usted lo antes posible.',
    News: 'News',
    'View all': 'Ver todo',
    'Feature requests': 'Feature requests',
    'How can we help?': '¿Cómo podemos ayudar?',
    'Send us a message': 'Mandanos un mensaje',
    Roadmap: 'Roadmap',
    Messages: 'Mensajes',
    Help: 'Ayuda',
    'Search for help': 'Buscar ayuda',
    'Search results for': 'Resultados de búsqueda para',
    'No messages yet': 'No hay mensajes todavía',
    Home: 'Home',
    "Unfortunately, I'm not able to answer your question at the moment.":
      'Desafortunadamente, no puedo responder tu pregunta en este momento.',
    Answer: 'Respuesta',
    'Our bot will reply instantly': 'Nuestro bot responderá instantáneamente',
    'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.': 'Encontramos solicitudes de funciones existentes que podrían alinearse con su idea. Vota por los que te gusten o envía tu propia idea.',
    'Submit my idea': 'Enviar mi idea',
    'Great! Any details you want to add to your feature request?': '¡Genial! ¿Algunos detalles que quieras agregar a tu solicitud de función?',
    "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.": 'Parece que encontraste un problema. Agradeceríamos si pudieras hacer clic en el botón de abajo para informarlo a nuestro equipo.',
  },
  fr: {
    Submit: 'Soumettre',
    Hi: 'Salut',
    'Short, descriptive title': 'Titre court et descriptif',
    'Let us know how we can do better.': 'Faites-nous savoir comment nous pouvons mieux faire',
    'Report a bug or share your feedback with us.': 'Signaler un bug ou partagez vos commentaires avec nous.',
    Feedback: 'Feedback',
    'We are here to help.': 'Nous sommes ici pour aider.',
    'What would you like to see next?': 'Quelle fonctionnalité aimeriez-vous voir ensuite?',
    'Report an issue': 'Signaler un problème',
    'Found a bug? Let us know.': 'Vous avez trouvé un bug? Faites le nous savoir.',
    'Wrong API key': 'Clé API incorrecte',
    'Click and drag to mark the bug': 'Cliquez et faites glisser pour marquer le bug',
    'I read and accept the ': 'J’ai lu et j’accepte ',
    'privacy policy': 'politique de confidentialité',
    'Problem detected': 'Problème détecté',
    'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.':
      'Oh, oh quelque chose s’est mal passé ici. En soumettant ce formulaire, vous nous aiderez à résoudre le problème et à améliorer considérablement.',
    'Thank you for your feedback.': 'Merci pour votre avis.',
    Email: 'E-mail',
    'Your e-mail': 'Votre e-mail',
    'Tell us more about the problem': 'Dites-nous en plus sur le problème',
    'Describe what went wrong': 'Décrivez ce qui s’est mal passé',
    'Send report': 'Envoyer un rapport',
    'Describe the issue': 'Décrivez le problème',
    'The more information, the better.': 'Plus il y a d’informations, mieux c’est.',
    'Please tell us more': 'Merci de nous en dire plus',
    'The more details, the better.': 'Plus c’est précis, mieux c’est.',
    'Send feedback': 'Envoyer des commentaires',
    'Thanks for your message. We will be in touch shortly':
      'Merci pour votre message. Nous vous contacterons prochainement.',
    Message: 'Message',
    'Your message': 'Votre message',
    'Send message': 'Envoyer le message',
    'Request a feature': 'Demander une fonctionnalité',
    'What feature would you like to see next?': 'Quelle fonctionnalité aimeriez-vous voir ensuite ?',
    'Explain your request.': 'Expliquez votre demande.',
    Subject: 'Matière',
    Description: 'Description',
    'Send request': 'Envoyer une demande',
    Next: 'Prochain',
    'Mark the bug': 'Marquer le problème',
    'The file you chose exceeds the file size limit of 3MB.': 'The file you chose exceeds the file size limit of 3MB.',
    Screenshot: "Capture d'écran",
    'Screen recording': 'Vidéo',
    Retry: 'Essayer à nouveau',
    Mute: 'Microphone éteint',
    Unmute: 'Microphone allumé',
    'Browser not supported': 'Navigateur non pris en charge',
    'Stop recording': "Arrête d'enregistrer",
    'Start recording': "Commencer l'enregistrement",
    'Record screen': 'Vidéo',
    'Pick a color': 'Choisir une couleur',
    Undo: 'Annuler',
    'Draw on the screen to mark the bug': "Marquez l'erreur sur l'écran",
    'Record your screen to showcase the bug': "Enregistrer une vidéo pour montrer l'erreur",
    'Extremely likely': 'Très probable',
    'Not likely at all': 'Pas probable',
    'Something went wrong, please try again.': "Une erreur s'est produite. Veuillez réessayer.",
    'Choose a file or drag it here.': 'Choisissez un fichier ou faites-le glisser ici.',
    'We usually reply': 'Nous répondons',
    'We are back': 'Nous serons de retour',
    You: 'Tu',
    'Write a reply...': 'Envoyer un message...',
    'Let us know what you would like to see next.': 'Faites-nous savoir ce que vous aimeriez voir ensuite.',
    'Hey 👋, how can we help you?': 'Hey 👋, comment pouvons-nous vous aider?',
    'within a few minutes': 'en quelques minutes',
    'within a few hours': 'en quelques heures',
    'within a day': 'en un jour',
    'We are currently offline but will get back to you as soon as possible.':
      'Nous sommes actuellement hors ligne, mais nous vous répondrons dès que possible.',
    News: 'News',
    'View all': 'Voir tout',
    'Feature requests': 'Feature requests',
    'How can we help?': 'Comment pouvons nous aider?',
    'Send us a message': 'Envoie-nous un message',
    Roadmap: 'Roadmap',
    Messages: 'Messages',
    Help: 'Aide',
    'Search for help': "Rechercher de l'aide",
    'Search results for': 'Résultats de recherche pour',
    'No messages yet': 'Pas encore de messages',
    Home: 'Home',
    "Unfortunately, I'm not able to answer your question at the moment.":
      'Malheureusement, je ne peux pas répondre à votre question pour le moment.',
    Answer: 'Réponse',
    'Our bot will reply instantly': 'Notre bot répondra instantanément',
    'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.': 'Nous avons trouvé des demandes de fonctionnalités existantes qui pourraient correspondre à votre idée. Votez pour celles que vous aimez ou soumettez votre propre idée.',
    'Submit my idea': 'Soumettre mon idée',
    'Great! Any details you want to add to your feature request?': 'Super! Des détails à ajouter à votre demande de fonctionnalité?',
    "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.": "On dirait que vous avez trouvé un problème. Nous vous serions reconnaissants si vous pouviez cliquer sur le bouton ci-dessous pour le signaler à notre équipe.",
  },
  it: {
    Submit: 'Invia',
    Hi: 'Ciao',
    'Short, descriptive title': 'Titolo breve e descrittivo',
    'Let us know how we can do better.': 'Fateci sapere come possiamo fare meglio',
    'Report a bug or share your feedback with us.': 'Segnalare un bug o condividi il tuo feedback con noi.',
    Feedback: 'Feedback',
    'We are here to help.': 'Siamo qui per aiutare.',
    'What would you like to see next?': 'Quale funzione vorresti vedere dopo?',
    'Report an issue': 'Segnala un problema',
    'Found a bug? Let us know.': 'Trovato un bug? Facci sapere.',
    'Wrong API key': 'API key sbagliata',
    'Click and drag to mark the bug': 'Fare clic e trascinare per segnalare il bug',
    'I read and accept the ': 'Ho letto e accetto la ',
    'privacy policy': 'politica sulla riservatezza',
    'Problem detected': 'Problema rilevato',
    'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.':
      'Oh, oh sembra che qualcosa sia andato storto qui. Inviando questo modulo, ci aiuterai a risolvere il problema e ci stai aiutando a migliorare alla grande.',
    'Thank you for your feedback.': 'Grazie per il tuo feedback.',
    Email: 'E-mail',
    'Your e-mail': 'La tua e-mail',
    'Tell us more about the problem': 'Dicci di più sul problema',
    'Describe what went wrong': 'Descrivi cosa è andato storto',
    'Send report': 'Spedisci il rapporto',
    'Describe the issue': 'Descrivi il problema',
    'The more information, the better.': 'Più informazioni, meglio è.',
    'Please tell us more': 'Per favore dicci di più',
    'The more details, the better.': 'Più dettagli, meglio è.',
    'Send feedback': 'Invia feedback',
    'Thanks for your message. We will be in touch shortly': 'Grazie per il tuo messaggio. Ci terremo in contatto.',
    Message: 'Messaggio',
    'Your message': 'Il tuo messaggio',
    'Send message': 'Invia messaggio',
    'Request a feature': 'Richiedi una funzionalità',
    'What feature would you like to see next?': 'Quale caratteristica vorresti vedere dopo?',
    'Explain your request.': 'Spiega la tua richiesta.',
    Subject: 'Soggetto',
    Description: 'Descrizione',
    'Send request': 'Invia richiesta',
    Next: 'Prossimo',
    'Mark the bug': 'Segna il problema',
    'The file you chose exceeds the file size limit of 3MB.': 'The file you chose exceeds the file size limit of 3MB.',
    Screenshot: 'Screenshot',
    'Screen recording': 'Video',
    Retry: 'Riprova',
    Mute: 'Microfono spento',
    Unmute: 'Microfono acceso',
    'Browser not supported': 'Browser non supportato',
    'Stop recording': 'Interrompi la registrazione',
    'Start recording': 'Inizia a registrare',
    'Record screen': 'Video',
    'Pick a color': 'Scegli un colore',
    Undo: 'Annulla',
    'Draw on the screen to mark the bug': "Segna l'errore sullo schermo",
    'Record your screen to showcase the bug': "Registra un video per mostrare l'errore",
    'Extremely likely': 'Molto probabilmente',
    'Not likely at all': 'Non è probabile',
    'Something went wrong, please try again.': 'Qualcosa è andato storto. Per favore riprova.',
    'Choose a file or drag it here.': 'Scegli un file o trascinalo qui.',
    'We usually reply': 'Rispondiamo',
    'We are back': 'torneremo',
    You: 'Voi',
    'Write a reply...': 'Invia un messaggio...',
    'Let us know what you would like to see next.': 'Facci sapere cosa vorresti vedere dopo.',
    'Hey 👋, how can we help you?': 'Ciao 👋, come possiamo aiutarti?',
    'within a few minutes': 'in pochi minuti',
    'within a few hours': 'in poche ore',
    'within a day': 'in un giorno',
    'We are currently offline but will get back to you as soon as possible.':
      'Al momento siamo offline, ma ti risponderemo al più presto.',
    News: 'News',
    'View all': 'Mostra tutto',
    'Feature requests': 'Feature requests',
    'How can we help?': 'Come possiamo aiutare?',
    'Send us a message': 'Mandaci un messaggio',
    Roadmap: 'Roadmap',
    Messages: 'Messaggi',
    Help: 'Aiuto',
    'Search for help': 'Cerca aiuto',
    'Search results for': 'Risultati della ricerca per',
    'No messages yet': 'Ancora nessun messaggio',
    Home: 'Home',
    "Unfortunately, I'm not able to answer your question at the moment.":
      'Sfortunatamente, non sono in grado di rispondere alla tua domanda al momento.',
    Answer: 'Risposta',
    'Our bot will reply instantly': 'Il nostro bot risponderà istantaneamente',
    'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.': 'Abbiamo trovato richieste di funzionalità esistenti che potrebbero essere in linea con la tua idea. Vota quelle che ti piacciono o invia la tua idea.',
    'Submit my idea': 'Invia la mia idea',
    'Great! Any details you want to add to your feature request?': 'Ottimo! Vuoi aggiungere qualche dettaglio alla tua richiesta di funzionalità?',
    "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.": "Sembra che tu abbia trovato un problema. Ti saremmo grati se potessi cliccare sul pulsante qui sotto per segnalarlo al nostro team.",
  },
  nl: {
    Submit: 'Indienen',
    Hi: 'Hoi',
    'Short, descriptive title': 'Korte, beschrijvende titel',
    'Let us know how we can do better.': 'Laat ons weten hoe we het beter kunnen doen',
    'Report a bug or share your feedback with us.': 'Meld een bug of deel uw feedback met ons.',
    Feedback: 'Feedback',
    'We are here to help.': 'We zijn hier om te helpen.',
    'What would you like to see next?': 'Welke functie zou je hierna willen zien?',
    'Report an issue': 'Een probleem melden',
    'Found a bug? Let us know.': 'Een fout gevonden? Laat het ons weten.',
    'Wrong API key': 'Verkeerde API-sleutel',
    'Click and drag to mark the bug': 'Klik en sleep om de bug te markeren',
    'I read and accept the ': 'Ik lees en accepteer het ',
    'privacy policy': 'privacybeleid',
    'Problem detected': 'Probleem gedetecteerd',
    'Oh, oh looks like something went wrong here. By submitting this form, you will help us fix the issue and improve big time.':
      'Oh, oh het lijkt erop dat hier iets mis is gegaan. Door dit formulier in te dienen, helpt u ons het probleem op te lossen en enorm te verbeteren.',
    'Thank you for your feedback.': 'Bedankt voor uw feedback.',
    Email: 'E-mail',
    'Your e-mail': 'Jouw e-mail',
    'Tell us more about the problem': 'Vertel ons meer over het probleem',
    'Describe what went wrong': 'Beschrijf wat er mis ging',
    'Send report': 'Verzend rapport',
    'Describe the issue': 'Beschrijf het probleem',
    'The more information, the better.': 'Hoe meer informatie, hoe beter.',
    'Please tell us more': 'Vertel ons meer',
    'The more details, the better.': 'Hoe meer details, hoe beter.',
    'Thanks for your message. We will be in touch shortly': 'Bedankt voor je bericht. We nemen binnenkort contact op.',
    Message: 'Bericht',
    'Your message': 'Jouw bericht',
    'Send message': 'Bericht versturen',
    'Request a feature': 'Vraag een functie aan',
    'What feature would you like to see next?': 'Welke functie of verbetering zou u graag willen zien?',
    'Explain your request.': 'Licht uw verzoek toe.',
    Subject: 'Onderwerpen',
    Description: 'Omschrijving',
    'Send request': '​​Verzend verzoek',
    Next: 'Volgende',
    'Mark the bug': 'Markeer het probleem',
    'The file you chose exceeds the file size limit of 3MB.': 'The file you chose exceeds the file size limit of 3MB.',
    Screenshot: 'Screenshot',
    'Screen recording': 'Schermopname',
    Retry: 'Probeer opnieuw',
    Mute: 'Microfoon uit',
    Unmute: 'Microfoon aan',
    'Browser not supported': 'Browser niet ondersteund',
    'Stop recording': 'Stop met opnemen',
    'Start recording': 'Start met opnemen',
    'Record screen': 'Video',
    'Pick a color': 'Kies een kleur',
    Undo: 'Annuleren',
    'Draw on the screen to mark the bug': 'Markeer de fout op het scherm',
    'Record your screen to showcase the bug': 'Neem een ​​video op om de fout te laten zien',
    'Extremely likely': 'Waarschijnlijk',
    'Not likely at all': 'Onwaarschijnlijk',
    'Something went wrong, please try again.': 'Er is iets mis gegaan, probeer het alstublieft nogmaals.',
    'Choose a file or drag it here.': 'Kies een bestand of sleep het hierheen.',
    'We usually reply': 'Wij antwoorden',
    'We are back': 'We zijn terug',
    You: 'Jij',
    'Write a reply...': 'Een bericht versturen...',
    'Let us know what you would like to see next.': 'Laat ons weten wat je de volgende keer zou willen zien.',
    'Hey 👋, how can we help you?': 'Hey 👋, hoe kunnen we je helpen?',
    'within a few minutes': 'binnen een paar minuten',
    'within a few hours': 'binnen een paar uur',
    'within a day': 'binnen een dag',
    'We are currently offline but will get back to you as soon as possible.':
      'We zijn momenteel offline, maar we komen zo snel mogelijk bij u terug.',
    News: 'News',
    'View all': 'Bekijk alles',
    'Feature requests': 'Feature requests',
    'How can we help?': 'Hoe kunnen we helpen?',
    'Send us a message': 'Zend ons een bericht',
    Roadmap: 'Roadmap',
    Messages: 'Berichten',
    Help: 'Help',
    'Search for help': 'Zoek hulp',
    'Search results for': 'Zoekresultaten voor',
    'No messages yet': 'Nog geen berichten',
    Home: 'Home',
    "Unfortunately, I'm not able to answer your question at the moment.":
      'Helaas kan ik op dit moment uw vraag niet beantwoorden.',
    Answer: 'Antwoord',
    'Our bot will reply instantly': 'Onze bot zal onmiddellijk antwoorden',
    'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.': 'We hebben bestaande functieverzoeken gevonden die mogelijk overeenkomen met uw idee. Stem op degenen die je leuk vindt of ga verder en dien je eigen idee in.',
    'Submit my idea': 'Mijn idee indienen',
    'Great! Any details you want to add to your feature request?': 'Geweldig! Wil je nog details toevoegen aan je functieverzoek?',
    "Looks like you found an issue. We'd appreciate it if you could click the button below to report it to our team.": "Het lijkt erop dat je een probleem hebt gevonden. We zouden het op prijs stellen als je op de onderstaande knop klikt om het aan ons team te melden.",
  },
};