/* eslint-disable jsx-a11y/label-has-associated-control */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import { plans, Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import CheckoutForm from './CheckoutForm';
import './Subscription.scss';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey!);

interface SubscriptionProps {
  plan: Plantypes;
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
}

const Subscription = ({
  plan,
  organisationStore,
  modalStore,
  usersStore,
  projectStore,
}: SubscriptionProps) => {
  const [planChoosen, setPlanChoosen] = useState(false);
  const currentPlan = plans[plan];
  const [choosenPlan, setchoosenPlan] = useState(currentPlan.annually as any);
  const currentOrganization = organisationStore?.currentOrganisation;
  const trialAlreadyUsed = currentOrganization?.trialUsed;
  const [showDiscountCodeField, setShowDiscountCodeField] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [stripeIsLoading, setStripeIsLoading] = useState(true);
  const clientSecret = organisationStore?.setupIntentClientSecret;
  const darkMode = localStorage.getItem('theme') === 'dark';

  useEffect(() => {
    organisationStore!.createSetupIntent();
  }, []);

  const subscribeToPlan = async () => {
    const success = await organisationStore!.subscribeToPlan(choosenPlan.plan, discountCode);
    setIsLoading(false);

    if (usersStore?.currentUser?.completedOnboarding && success) {
      modalStore!.closeModal();
    }
  };

  const buildCardInput = () => {
    if (!clientSecret) {
      return <></>;
    }

    const options = {
      clientSecret,
      appearance: {
        theme: darkMode ? 'night' : 'stripe',
      },
    } as any;

    return (
      <>
        {stripeIsLoading && (
          <div className="checkout-loading-container">
            <LoadingAnimation />
          </div>
        )}
        <div style={{
          visibility: stripeIsLoading ? 'hidden' : 'visible',
          height: stripeIsLoading ? '0px' : 'auto'
        }}>
          {currentOrganization?.trialUsed && (<div className='title title--small mb-20'>Start {currentPlan.name} subscription</div>)}
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              onSuccess={subscribeToPlan}
              submit={submit}
              onError={() => {
                setIsLoading(false);
              }}
              onReady={() => {
                setTimeout(() => {
                  setStripeIsLoading(false);
                }, 300);
              }}
            />
            {buildPrice()}
            <div className="mt-20 mb-10">
              {showDiscountCodeField ? (
                <TextInput error='' placeholder='Discount code' value={discountCode} onChange={(text) => {
                  setDiscountCode(text)
                }} />
              ) : (
                <a href="#" className="discount-code-link" onClick={(e) => {
                  setShowDiscountCodeField(true);
                }}><i className="fa-regular fa-plus"></i> Apply discount code</a>
              )}
            </div>
            <PrimaryButton
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                setSubmit(false);
                setTimeout(() => {
                  setSubmit(true);
                }, 100);
              }}
              fullWidth
              className="mt-15"
              label={
                currentOrganization?.trialUsed
                  ? `Start subscription 🚀`
                  : 'Start free trial'
              }
            />
          </Elements>
        </div>
      </>
    );
  };

  const buildPrice = () => {
    if (trialAlreadyUsed) {
      return <>
        <div className="price-row-container mt-5">
          <div className="price-to-pay-today">
            Due today
          </div>
          <div className="price-to-pay-today">
            {`$${choosenPlan.type === 'monthly'
              ? choosenPlan.price.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })
              : (choosenPlan.price * 12).toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })
              }`}
          </div>
        </div>
      </>;
    }

    return (
      <>
        <div className="price-row-container mt-15">
          <div className="price-to-pay">
            Due {moment(new Date()).add(14, 'days').format('DD.MM.yyyy')}
          </div>
          <div className="price-to-pay">{`$${choosenPlan.type === 'monthly'
            ? choosenPlan.price.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })
            : (choosenPlan.price * 12).toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })
            }`}</div>
        </div>
        <div className="price-row-container mt-5">
          <div className="price-to-pay-today">
            Due today <div className="trial-length-text">(14 days free)</div>
          </div>
          <div className="price-to-pay-today">$0.00</div>
        </div>
      </>
    );
  };

  const buildPlanInfo = () => {
    return (
      <div className='plan-info'>
        {trialAlreadyUsed && (
          <>
            <HeadLine
              title={`Upgrade to ${currentPlan.name}`}
              className="mb-15"
            />
            <div className="plan-info-row mb-5">
              <i className="fa-solid fa-check checkmark-icon" />
              <div className="plan-info-text">{currentPlan.mainFeature1}</div>
            </div>
            <div className="plan-info-row">
              <i className="fa-solid fa-check checkmark-icon" />
              <div className="plan-info-text">{currentPlan.mainFeature2}</div>
            </div>
          </>
        )}
        {!trialAlreadyUsed && (
          <>
            <HeadLine
              title={`Try ${currentPlan.name} for free`}
              className="mb-15"
            />
            <div className="plan-info-row mb-5">
              <i className="fa-solid fa-check checkmark-icon" />
              <div className="plan-info-text">
                Free 14 days trial, cancel any time
              </div>
            </div>
            <div className="plan-info-row">
              <i className="fa-solid fa-check checkmark-icon" />
              <div className="plan-info-text">
                We&apos;ll remind you before the trial ends
              </div>
            </div>
          </>
        )}
        <label className="bb-feedback-multiplechoice-container mt-30">
          <div>
            <div className="plan-type">
              Annually{' '}
              <div className="best-value-container">
                SAVE{' $'}
                {(
                  currentPlan.monthly.price * 12 -
                  currentPlan.annually.price * 12
                ).toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })}
                /YEAR
              </div>
            </div>
            <div className="plan-price">
              {`$${currentPlan.annually.price.toLocaleString(
                navigator.language,
                {
                  minimumFractionDigits: 2,
                },
              )}`}
              <div className="plan-price-monthly">/month</div>
            </div>
          </div>
          <input
            type="radio"
            name="annually"
            checked={choosenPlan.plan === currentPlan.annually.plan}
            onChange={() => {
              setchoosenPlan({
                ...currentPlan.annually,
                ...{ type: 'annually' },
              });
            }}
          />
          <span className="bb-feedback-multiplechoice-checkmark" />
        </label>
        <label className="bb-feedback-multiplechoice-container mb-30">
          <div>
            <div className="plan-type">Monthly</div>
            <div className="plan-price">
              {`$${currentPlan.monthly.price.toLocaleString(
                navigator.language,
                {
                  minimumFractionDigits: 2,
                },
              )}`}
              <div className="plan-price-monthly">/month</div>
            </div>
          </div>
          <input
            type="radio"
            name="monthly"
            checked={choosenPlan.plan === currentPlan.monthly.plan}
            onChange={() => {
              setchoosenPlan({
                ...currentPlan.monthly,
                ...{ type: 'monthly' },
              });
            }}
          />
          <span className="bb-feedback-multiplechoice-checkmark" />
        </label>
        {buildPrice()}
        <PrimaryButton
          fullWidth
          className="mt-20"
          label="Next"
          onClick={() => {
            setPlanChoosen(true);
          }}
        />
      </div>
    );
  };

  return planChoosen ? buildCardInput() : buildPlanInfo();
};

export default inject(
  'organisationStore',
  'modalStore',
  'modalStore',
  'usersStore',
  'projectStore',
)(observer(Subscription));
