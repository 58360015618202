export const CommentTypes = {
  USER_NOTIFICATION: 'USER_NOTIFICATION',
  FEEDBACK_UPDATED: 'FEEDBACK_UPDATED',
  TEXT: 'TEXT',
  NOTE: 'NOTE',
  USER_TEXT: 'USER_TEXT',
  BOT: 'BOT',
  BOT_REPLY: 'BOT_REPLY',
  BOT_INPUT_REPLY: 'BOT_INPUT_REPLY',
  SHARED_COMMENT: 'SHARED_COMMENT',
  INTEGRATION_STATUS_UPDATED: 'INTEGRATION_STATUS_UPDATED',
};

export const CommentNotificationType = {
  EMAIL: 'EMAIL',
  MESSENGER: 'MESSENGER',
};

export const CommentIntegrationMessageType = {
  CREATED: 'CREATED',
  STATUS_UPDATED: 'STATUS_UPDATED',
  LABELED: 'LABELED',
  DELETED: 'DELETED',
};

export type Comment = {
  id: string;
  fallbackUser: {
    username: string;
    email: string;
    imageUrl: string;
  };
  session?: {
    id: string;
    userId: string;
    userHash: string;
    name: string;
    email: string;
  };
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
  bugId: string;
  userId: string;
  data: any;
  createdAt: string;
  type: string;
  readStatus: any;
  sessionNotificationsUnread: boolean;
  notificationType: string;
  emailStatusHistory: any[];
  emailStatus: any;
  sending: boolean;
};
