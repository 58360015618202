import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './ChatStat.scss';


interface ChatStatProps {
    className?: string;
    icon: string;
    title: string;
    subtitle: string;
    onClick?: () => void;
}

const ChatStat = ({ className, icon, title, subtitle, onClick }: ChatStatProps) => {
    return (
        <div className={`chat-stat ${className}`}>
            <div className='icon'>
                <i className={`fa-solid fa-${icon}`} />
            </div>
            <div className='content'>
                <div className='title'>{title}</div>
                <div className='subtitle'>{onClick ? <a href="#" onClick={onClick}>{subtitle}</a> : subtitle}</div>
            </div>
        </div>
    );
};

export default ChatStat;
