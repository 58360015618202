import Loading from 'components/Loading/Loading';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import './AISummary.scss';

interface AISummaryProps {
    outboundStore?: OutboundStore;
    organisationStore?: OrganisationStore;
    outboundId: string;
}

const AISummary = ({ outboundStore, outboundId, organisationStore }: AISummaryProps) => {
    const navigate = useNavigate();

    const renderAIResponse = () => {
        if (outboundStore?.loadingAiResponse) {
            return <div className="ai-loading-container">
                <Loading />
            </div>;
        }

        if (outboundStore?.aiPlanFailed) {
            return (<div className='ai-list-planerror'>
                <div className='planerror-title'>New plan needed 🪄</div>
                <div className='planerror-description'>AI assist is not supported in your legacy plan. Move to our new plans to unlock AI assist.</div>
                <PrimaryButton onClick={() => {
                    navigate(
                        `/organization/${organisationStore?.currentOrganisation?.id}/billing`,
                    );
                }} label='Update plan' />
            </div>);
        }

        if (outboundStore?.outboundRule?.aiSummary && !outboundStore?.aiResponse?.summary) {
            return (
                <>
                    <div className='question-answer'>
                        {outboundStore?.outboundRule?.aiSummary}
                    </div>
                    <div className='regenerate-info'>
                        {outboundStore?.outboundRule?.aiSummaryOutdated && <span className='regenerate-info-outdated'>This summary is outdated.</span>}
                        <span className='simple-link' onClick={() => {
                            outboundStore!.askOutboundAIQuestion(outboundId, 'summary');
                        }}>
                            <i className="fa-regular fa-rotate-right"></i>
                            Re-summarize?
                        </span>
                    </div>
                </>
            );
        }

        if (!outboundStore?.aiResponse || !outboundStore?.aiResponse?.summary) {
            return <div className='generate-summary'>
                <div className='generate-summary-label'>Ready for the future? Let's summarize your survey results 🚀</div>
                <PrimaryButton label='Summarize results ✍️' onClick={() => {
                    outboundStore!.askOutboundAIQuestion(outboundId, 'summary');
                }} />
            </div>;
        }

        return (
            <div className='question-answer'>
                {outboundStore?.aiResponse?.summary}
            </div>
        );
    };

    return (
        <div className='ai-summary'>
            <div className='ai-summary-headline'>
                <div className='ai-summary-title title'>Summary 🪄 <span className="ai-summary-title-tag">AI assist</span></div>
                <div className='ai-summary-subtitle text'>Our AI assistant summarizes the survey results for you.</div>
            </div>
            {renderAIResponse()}
        </div>
    );
};

export default inject('outboundStore', 'organisationStore')(observer(AISummary));
