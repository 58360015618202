import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './AITokenProgress.scss';

interface AITokenProgressProps {
  organisationStore?: OrganisationStore;
}

export function formatNumber(num) {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  return num.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const AITokenProgress = ({ organisationStore }: AITokenProgressProps) => {
  const mauUsage = organisationStore?.currentOrganisation?.usageData?.tokens ?? 0;
  const includedMAU = organisationStore?.currentOrganisation?.aiTokenLimit ?? 0;
  const maxScale = Math.max(mauUsage, includedMAU) * 1.2;
  const mauUsagePercentage = Math.ceil((mauUsage / maxScale) * 100);
  const mauUsageIncludedPercentage = Math.ceil((includedMAU / maxScale) * 100);
  const limitText = formatNumber(Math.round(includedMAU / 1000 * 0.06));

  return (
    <>
      <div className='mau-limit-cont'>
        <div className='mau-limit-progress-bar'>
          <div className='mau-limit-progress-bar-inner' style={{
            minWidth: `${mauUsagePercentage}%`,
          }}>
          </div>
          <div className='mau-limit-included' style={{
            left: `${mauUsageIncludedPercentage}%`,
          }}>
            <div className='mau-limit-included-label'>${limitText} usage limit</div>
          </div>
        </div>
        <div className='mau-limit-info'>${formatNumber(Math.round((mauUsage / 1000 * 0.06) * 100) / 100)} / ${limitText}</div>
      </div>
      {<div className='text mt-30'>
        We charge a fee of $0.06 for every 1,000 AI tokens utilized. You currently used {mauUsage} AI tokens.<br />
        <a href="#" onClick={() => {
          Gleap.open();
        }}>Contact us</a> to increase your AI token usage limit.</div>}
    </>
  );
};

export default inject('organisationStore')(observer(AITokenProgress));
