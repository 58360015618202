import React from 'react';
import Swal from 'sweetalert2';
import 'styles/swal-theme.scss';
import { UsersStore } from 'stores/private/UsersStore';
import { inject, observer } from 'mobx-react';
import './ProfileDangerZone.scss';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import LinkButton from 'components/LinkButton/LinkButton';

interface ProfileDangerZoneProps {
  usersStore?: UsersStore;
  organisationStore?: OrganisationStore;
}

const ProfileDangerZone = ({
  usersStore,
  organisationStore,
}: ProfileDangerZoneProps) => {
  const user = usersStore!.currentUser;
  if (!user) {
    return <></>;
  }

  const deleteUser = () => {
    Swal.fire({
      text: 'Please send us an email to start the user account deletion process.',
      showCancelButton: true,
      confirmButtonText: `Compose email`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.open('mailto:hello@gleap.io', '_blank');
      }
    });
  };

  return (
    <PageContainer>
      <PageHeadLine title="Danger zone" />
      <PageContent hasTitle isSmallBoxed>
        <>
          <div className="danger-zone">
            <div className="danger-zone-container">
              <div className="danger-zone-item">
                <div className="danger-zone-label">
                  Delete your user account
                </div>
                <LinkButton
                  className="danger-button"
                  label="Delete"
                  onClick={deleteUser}
                />
              </div>
            </div>
          </div>
          <PrimaryButton
            className="mt-20 hide-on-desktop"
            label="Logout"
            onClick={() => {
              usersStore?.logout(true);
            }}
          />
        </>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'usersStore',
  'organisationStore',
)(observer(ProfileDangerZone));
