import TextStyle from '@tiptap/extension-text-style';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { CustomLink } from '../RichTextEditor/extensions/CustomLink';
import Image from '@tiptap/extension-image';
import Youtube from '@tiptap/extension-youtube';
import Heading from '@tiptap/extension-heading';
import './EmailEditor.scss';
import { MutableRefObject, useEffect, useState } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import Iframe from '../../../services/Iframe';

interface EmailEditorProps {
  editorRef?: MutableRefObject<Editor | null>;
  onEditorReady?: () => void;
  content?: any;
  inputContentChanged?: any;
  emptyStateChanged?: any;
  placeholder?: string;
}

const EmailEditor = ({
  editorRef,
  onEditorReady,
  content,
  inputContentChanged,
  emptyStateChanged,
  placeholder,
}: EmailEditorProps) => {
  const [editorContent, setEditorContent] = useState(content as any);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (inputContentChanged) {
      inputContentChanged(editorContent);
    }
  }, [editorContent]);

  const editor = useEditor(
    {
      editable: true,
      extensions: [
        StarterKit.configure({
          heading: false,
          codeBlock: {},
        }),
        Heading.configure({
          levels: [1, 2, 3],
        }),
        Placeholder.configure({
          placeholder: placeholder,
        }),
        CustomLink.configure({
          openOnClick: false,
        }),
        Image,
        TextStyle,
        Youtube.configure({ controls: true }),
        Iframe,
      ],
      content,
      onSelectionUpdate({ editor }) {
        setEditorContent(editor?.getJSON());
      },
      onUpdate({ editor }) {
        if (emptyStateChanged) {
          emptyStateChanged(editor?.isEmpty);
        }
        setEditorContent(editor?.getJSON());
      },
    },
    [],
  );

  if (!editor) return null;

  if (editorRef && !initialized) {
    editorRef.current = editor;
    setInitialized(true);

    if (onEditorReady) {
      onEditorReady();
    }
  }

  return (
    <div className="email-editor">
      <EditorContent editor={editor} />
    </div>
  );
};

export default EmailEditor;
