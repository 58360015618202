import * as React from 'react';
import classNames from 'classnames';
import './IconButton.scss';

interface IconButtonProps {
  onClick: any;
  icon: any;
  className?: any;
}

const IconButton = ({
  onClick,
  icon,
  className,
}: IconButtonProps) => {
  const iconButtonClassName = classNames(
    {
      'icon-button': true,
    },
    className,
  );

  return (
    <button type="button" className={iconButtonClassName} onClick={onClick}>
      <i className={`icon fa-solid fa-${icon}`} />
    </button>
  );
};

export default IconButton;
