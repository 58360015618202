import axios from './Axios';

// PRIVATE
const createHelpcenterCollection = (projectId: string, collection: any) => {
  return axios.post(
    `/projects/${projectId}/helpcenter/collections`,
    collection,
  );
};

const getHelpcenterCollections = (projectId: string) => {
  return axios.get(`/projects/${projectId}/helpcenter/collections`);
};

const getHelpcenterCollection = (projectId: string, collectionId: string) => {
  return axios.get(
    `/projects/${projectId}/helpcenter/collections/${collectionId}`,
  );
};

const updateHelpcenterCollection = (
  projectId: string,
  collectionId: string,
  collection: any,
) => {
  return axios.put(
    `/projects/${projectId}/helpcenter/collections/${collectionId}`,
    collection,
  );
};

const deleteHelpcenterCollection = (
  projectId: string,
  collectionId: string,
) => {
  return axios.delete(
    `/projects/${projectId}/helpcenter/collections/${collectionId}`,
  );
};

const createHelpcenterAricle = (
  projectId: string,
  collectionId: string,
  article: any,
) => {
  return axios.post(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles`,
    article,
  );
};

const getHelpcenterArticles = (projectId: string, collectionId: string) => {
  return axios.get(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles`,
  );
};

const getHelpcenterArticle = (
  projectId: string,
  collectionId: string,
  articleId: string,
) => {
  return axios.get(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleId}`,
  );
};

const updateHelpcenterArticle = (
  projectId: string,
  collectionId: string,
  articleId: string,
  article: any,
) => {
  return axios.put(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleId}`,
    article,
  );
};

const deleteHelpcenterArticle = (
  projectId: string,
  collectionId: string,
  articleId: string,
) => {
  return axios.delete(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleId}`,
  );
};

export {
  createHelpcenterCollection,
  getHelpcenterCollections,
  getHelpcenterCollection,
  updateHelpcenterCollection,
  deleteHelpcenterCollection,
  createHelpcenterAricle,
  getHelpcenterArticles,
  getHelpcenterArticle,
  updateHelpcenterArticle,
  deleteHelpcenterArticle,
};
