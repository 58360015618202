import './AddMenuOptionModal.scss';
import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import { menuOptions, ProjectStore } from 'stores/private/ProjectStore';
import TextHeadline from 'components/TextHeadline/TextHeadline';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { runInAction } from 'mobx';
import { ampli } from 'ampli';

interface AddMenuOptionModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const AddMenuOptionModal = ({
  projectStore,
  modalStore,
}: AddMenuOptionModalProps) => {
  const flowConfig = projectStore?.flowConfig;

  return (
    <SizedContainer size={ContainerSizes.XXL} className="centered">
      <TextHeadline text="Add a new menu option" />
      <div className="addmenuoptions mt-20">
        {menuOptions.map((menuOption, index) => {
          return (
            <div
              key={index}
              className="card addmenuoption-card"
              onClick={() => {
                runInAction(() => {
                  projectStore!.flowConfig.menuItems.push(menuOption);
                  projectStore!.updateFlowConfig();
                  modalStore!.closeModal();
                });

                ampli.menuOptionAdded({
                  name: menuOption.actionType,
                  projectId: projectStore?.currentProject?.id ?? ""
                });
              }}
            >
              <div
                className="icon-container"
                style={{
                  backgroundColor: menuOption.color
                    ? menuOption.color
                    : flowConfig?.color,
                }}
              >
                <img alt="Menu option" src={menuOption.icon} />
              </div>
              <div className="info">
                <div className="title">{menuOption.title}</div>
                <div className="description">{menuOption.infoDescription}</div>
              </div>
            </div>
          );
        })}
      </div>
    </SizedContainer>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(AddMenuOptionModal));
