import React from 'react';
import './CompleteProfile.scss';
import { inject, observer } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';
import { HeadLine } from 'components/HeadLine/HeadLine';
import { ampli } from 'ampli';

interface CompleteProfileProps {
  usersStore?: UsersStore;
}

const onboardingProfiles = [
  {
    name: 'Web developer',
    icon: 'laptop-code',
    role: 'webdeveloper',
    description: "I'm developing websites or web apps.",
  },
  {
    name: 'App developer',
    icon: 'mobile-screen-button',
    role: 'appdeveloper',
    description: "I'm developing apps (iOS, Android, Flutter, React Native).",
  },
  {
    name: 'Project manager',
    icon: 'chart-gantt',
    role: 'projectmanager',
    description: "I'm managing software projects.",
  },
  {
    name: 'Q/A tester',
    icon: 'vials',
    role: 'qatester',
    description: "I'm making sure that our software is stable.",
  },
  {
    name: 'Product owner',
    icon: 'file-chart-pie',
    role: 'productowner',
    description: "I'm responsible for managing my software team.",
  },
  {
    name: 'Other',
    icon: 'house-laptop',
    role: 'other',
    description: "I'm part of a software team.",
  },
];

export const CompleteProfile = ({ usersStore }: CompleteProfileProps) => {
  if (!usersStore) {
    return <></>;
  }

  const user = usersStore!.currentUser;
  if (!user) {
    return <></>;
  }

  const sendProfileUpdate = (role) => {
    const toUpdateUser: any = {
      userRole: role,
    };
    usersStore!.updateUser(user!.id, toUpdateUser, true);

    ampli.userRoleSelected({
      role: role,
    });
  };

  return (
    <>
      <div className="complete-profile">
        <div className="tell-us-more">
          <div className="tell-us-more--header">
            <HeadLine
              title={`Hi ${user.firstName} 👋`}
              subtitle="Tell us more about yourself to help you get started."
            />
          </div>
          <div className="tell-us-more--body">
            {onboardingProfiles.map((profile) => {
              return (
                <div
                  key={profile.role}
                  className="role-card"
                  onClick={() => {
                    sendProfileUpdate(profile.role);
                  }}
                >
                  <div className="role-card-icon">
                    <i className={`fa-regular fa-${profile.icon}`} />
                  </div>
                  <div className="role-card--title">{profile.name}</div>
                  <div className="role-card--description">
                    {profile.description}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default inject('usersStore')(observer(CompleteProfile));
