/* eslint-disable @typescript-eslint/no-unused-expressions */

import React, { useEffect } from 'react';
import './SharedBugReport.scss';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import BugDetail from 'components/BugDetail/BugDetail';
import { useParams } from 'react-router';
import { useLocation } from 'react-router';
import SharedWebSocketHelper from 'services/SharedWebSocketService';

interface SharedBugReportProps {
  bugStore?: BugStore;
}

const SharedBugReport = ({ bugStore }: SharedBugReportProps) => {
  // @ts-ignore
  const { shareToken } = useParams();
  const location = useLocation();
  const [isUserSide, setIsUserSide] = React.useState(false);

  useEffect(() => {
    if (shareToken) {
      const sessionData = new URLSearchParams(location.search);
      const gleapId = sessionData.get('u');
      const gleapHash = sessionData.get('h');
      const token = sessionData.get('token');
      if (gleapId && gleapHash) {
        setIsUserSide(true);
        bugStore?.loadSharedBug(shareToken, true, gleapId, gleapHash, token ?? '');
      } else {
        bugStore?.loadSharedBug(shareToken, true, undefined, undefined, token ?? '');
      }

      // Initialize shared web sockets.
      SharedWebSocketHelper.getInstance().connect(shareToken);
      SharedWebSocketHelper.addEventListener((eventName, data) => {
        if (eventName === "comment.created") {
          bugStore?.clearCurrentlyTyping();
          bugStore?.getSharedComments(shareToken, gleapId ?? '', gleapHash ?? '', token ?? '', false);
        }
        if (eventName === "bug.typing") {
          bugStore?.handleCurrentlyTyping({
            ...data.actionData,
            id: data.actionData.ticketId,
          });
        }
      });
    }

    const style = document.createElement('style');
    style.textContent = `
      .gleap-notification-container {
        display: none !important;
      }
      .bb-feedback-button-shoutout {
        display: none !important;
      }
      .bb-feedback-button {
        display: none !important;
      }`;
    document.head.append(style);
  }, []);
  isUserSide
  return (
    <div className="shared-bugs-container">
      <div className={`shared-bugs ${isUserSide && 'shared-bugs--userside'}`}>
        {!bugStore!.loadedingSharedBugFailed ? (
          <BugDetail shared />
        ) : (
          <div className="bug-report-not-existing">
            <div>The feedback item you are looking for does not exist. 🧐</div>
          </div>
        )}
      </div>
    </div>
  );
};
export default inject('bugStore')(observer(SharedBugReport));
