import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ampli } from 'ampli';
import ConfettiHelper from 'helper/ConfettiHelper';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  getCustomerDetails,
  getInvoices,
  getSubscription,
  startCheckoutFlow,
  startCustomerPortal,
  updateSubscription, upgradeSubscription, upgradeToYearlySubscription
} from 'services/StripeService';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export class StripeStore {
  stripe?: Stripe = undefined;
  stores: any = {};
  invoiceList: any[] = [];
  customerDetails: any = {};
  subscription: any = {};
  invoice: any = {};
  usageBasedInvoice: any = {};
  updateSubscriptionLoading = false;

  setStores(stores) {
    this.stores = stores;
  }

  constructor() {
    makeAutoObservable(this);
    loadStripe(stripeKey ?? '').then((stripe) => {
      if (stripe) {
        this.stripe = stripe;
      }
    });
  }

  getCustomerDetails = async () => {
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const response = await getCustomerDetails(
        this.stores.organisationStore.currentOrganisation.id,
      );
      if (response && response.data) {
        runInAction(() => {
          this.customerDetails = response.data;
        });
      }
    } catch (err: any) {
      toast.error('You are not authorized.');
    }
  };

  getInvoices = async () => {
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const response = await getInvoices(
        this.stores.organisationStore.currentOrganisation.id,
      );
      if (response && response.data) {
        runInAction(() => {
          this.invoiceList = response.data;
        });
      }
    } catch (err: any) {
      toast.error('You are not authorized.');
    }
  };

  getSubscription = async () => {
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const response = await getSubscription(
        this.stores.organisationStore.currentOrganisation.id,
      );
      if (response && response.data) {
        runInAction(() => {
          if (response.data) {
            this.subscription = response.data.subscription;
            this.invoice = response.data.invoice;
            this.usageBasedInvoice = response.data.usageBasedInvoice;
          }
        });
      }
    } catch (err: any) {
      toast.error('You are not authorized.');
    }
  };

  upgradeToYearlySubscription = async () => {
    this.updateSubscriptionLoading = true;
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      var response = await upgradeToYearlySubscription(
        this.stores.organisationStore.currentOrganisation.id,
      );

      if (response && response.data) {
        // Update subscription.
        this.getSubscription();

        runInAction(() => {
          this.subscription = response.data;
          this.updateSubscriptionLoading = false;
          
          ConfettiHelper.start(['😍', '🥰', '🎉'], 130, 35);
        });
      }
      return true;
    } catch (err: any) {
      toast.error('You are not authorized.');
      runInAction(() => {
        this.updateSubscriptionLoading = false;
      });
    }

    return false;
  };

  upgradeSubscription = async () => {
    this.updateSubscriptionLoading = true;
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      var response = await upgradeSubscription(
        this.stores.organisationStore.currentOrganisation.id,
      );

      if (response && response.data) {
        // Update subscription.
        this.getSubscription();

        runInAction(() => {
          this.subscription = response.data;
          this.updateSubscriptionLoading = false;

          this.stores.organisationStore.currentOrganisation.subscription.planID =
            'price_1MXPuoFIu8tJdh4n9B4GdX1w';
          this.stores.organisationStore.currentOrganisation.subscription.metadata.sessionlimit = 1000;
          this.stores.organisationStore.currentOrganisation.isMetered = true;

          ConfettiHelper.start(['😍', '🥰', '🎉'], 130, 35);
        });
      }
      return true;
    } catch (err: any) {
      toast.error('You are not authorized.');
      runInAction(() => {
        this.updateSubscriptionLoading = false;
      });
    }

    return false;
  };

  updateSubscription = async (
    cancel: boolean,
    reason?: string,
    reasonExtended?: string,
  ) => {
    this.updateSubscriptionLoading = true;
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const response = await updateSubscription(
        this.stores.organisationStore.currentOrganisation.id,
        cancel,
        reason,
        reasonExtended,
      );
      if (response && response.data) {
        runInAction(() => {
          this.subscription = response.data;
          this.updateSubscriptionLoading = false;

          if (cancel) {
            ConfettiHelper.start(['😭', '😿', '👋'], 130, 35);
          } else {
            ConfettiHelper.start(['😍', '🥰', '🎉'], 130, 35);
          }
        });
      }
      return true;
    } catch (err: any) {
      toast.error('You are not authorized.');
      runInAction(() => {
        this.updateSubscriptionLoading = false;
      });
    }

    return false;
  };

  startCustomerPortal = async () => {
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const response = await startCustomerPortal(
        this.stores.organisationStore.currentOrganisation.id,
      );
      if (response && response.data && response.data.url) {
        ampli.stripePortalOpened().promise.then(() => {
          window.location.href = response.data.url;
        });
      }
    } catch (err: any) {
      toast.error('You are not authorized.');
    }
  };

  startCheckoutFlow = async (planId: string) => {
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const response = await startCheckoutFlow(
        planId,
        this.stores.organisationStore.currentOrganisation.id,
      );
      if (this.stripe && response.data) {
        ampli.planSelected().promise.then(() => {
          if (this.stripe) {
            this.stripe.redirectToCheckout({
              sessionId: response.data.sessionId,
            });
          }
        });
      }
    } catch (err: any) {
      toast.error('Checkout failed. Please contact our support.');
    }
  };
}
