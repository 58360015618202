import NativeAxios from 'axios';
import { User } from 'models/User';
import axios, { injectHeader } from './Axios';

const getAppSumoActivation = (
  appsumoActivationToken: string,
) => {
  return axios.get(`/appsumo/activation/${appsumoActivationToken}`);
};

const register = (
  token: string,
  email: string,
  password: string,
  name: string,
  onboardingFlow: string,
  appsumoActivationToken?: string,
) => {
  return axios.post('/users', {
    token,
    email,
    password,
    name,
    onboardingFlow,
    appsumoActivationToken,
    utm_source: localStorage.getItem('utm_source'),
    utm_medium: localStorage.getItem('utm_medium'),
    utm_term: localStorage.getItem('utm_term'),
    utm_content: localStorage.getItem('utm_content'),
    utm_campaign: localStorage.getItem('utm_campaign'),
  });
};

const loginWithEmailAndPassword = (
  email: string,
  password: string,
): Promise<any> => {
  const auth = {
    username: email,
    password: encodeURIComponent(password),
  };
  return axios.post('/auth', {}, { auth });
};

const loginWithGithub = (code: string): Promise<any> => {
  return NativeAxios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/github`, {
    access_token: code,
  });
};

const loginWithGoogle = (code: string): Promise<any> => {
  return NativeAxios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, {
    access_token: code,
    utm_source: localStorage.getItem('utm_source'),
    utm_medium: localStorage.getItem('utm_medium'),
    utm_term: localStorage.getItem('utm_term'),
    utm_content: localStorage.getItem('utm_content'),
    utm_campaign: localStorage.getItem('utm_campaign'),
  });
};

const updateUser = (id: string, user: any): Promise<any> => {
  return axios.put(`/users/${id}`, user);
};

const verifyAccount = (code: string, email: string): Promise<any> => {
  return axios.post('/users/verify', {
    email,
    code,
  });
};

const changePassword = (id: string, password: string, oldpassword: string) => {
  return axios.put(`/users/${id}/password`, { password, oldpassword });
};

const resend = (user: User): Promise<any> => {
  if (user) {
    return axios.post('/users/resend', { id: user.id });
  }
  return new Promise((resolve, reject) => {
    reject(new Error('Unauthorized'));
  });
};

const sendEmailNotification = (): Promise<any> => {
  injectHeader();
  return axios.post('/users/me/sendemailnotification', {});
};

const me = (): Promise<any> => {
  injectHeader();
  return axios.get('/users/me');
};

const deleteUser = () => {
  return axios.delete(`users/me`);
};

export {
  loginWithEmailAndPassword,
  loginWithGithub,
  updateUser,
  loginWithGoogle,
  register,
  verifyAccount,
  resend,
  changePassword,
  me,
  deleteUser,
  sendEmailNotification,
  getAppSumoActivation,
};
