import './BotDropdown.scss';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import LinkButton from 'components/LinkButton/LinkButton';
import { useNavigate } from 'react-router';

interface IconType {
  icon: any;
  label: string;
  value: string;
}

interface BotDropdownProps {
  options: any[];
  value?: any;
  onValueChanged: (data: any) => void;
  projectId?: string;
}

const BotDropdown = ({
  options,
  value,
  onValueChanged,
  projectId,
}: BotDropdownProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Select
        className="bot-dropdown-selection"
        classNamePrefix="bot-dropdown-selection"
        value={value}
        placeholder="Optionally select a bot to start"
        isClearable={true}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            return (
              <div className="feedback-flow-type-item">
                <i className={`fa-regular fa-message-bot`} />
                <span>{option.data.name}</span>
                {projectId &&
                  <div className='edit-button-container'>
                    <LinkButton small label='Edit bot' onClick={() => {
                      navigate(`/projects/${projectId}/bots/custombots/${option.data.id}`);
                    }} />
                  </div>}
              </div>
            );
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item feedback-flow-type-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <i className={`fa-regular fa-message-bot`} />
                {option.data.name}
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          onValueChanged(selected);
        }}
        options={options}
      />
    </>
  );
};

export default inject('projectStore')(observer(BotDropdown));
