import './InvitationsOnboarding.scss';
import { inject, observer } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';
import { HeadLine } from 'components/HeadLine/HeadLine';
import InvitationCard from 'components/InvitationCard/InvitationCard';
import LinkButton from 'components/LinkButton/LinkButton';

interface InvitationsOnboardingProps {
    usersStore?: UsersStore;
}

export const InvitationsOnboarding = ({ usersStore }: InvitationsOnboardingProps) => {
    if (!usersStore) {
        return <></>;
    }

    const user = usersStore!.currentUser;
    if (!user) {
        return <></>;
    }

    return (
        <>
            <div className="invitation-onboarding">
                <div className="invitation-onboarding-body">
                    <div className="invitation-onboarding-body--header">
                        <HeadLine
                            title={`Hi ${user.firstName} 👋`}
                            subtitle="You have been invited to join an existing team."
                        />
                        <LinkButton label='Skip' onClick={() => usersStore!.skipInvitations()} />
                    </div>
                    <div className="invitation-onboarding-body--body">
                        {usersStore!.invitations.length > 0 && (
                            <>
                                {usersStore!.invitations.map((invitation, key) => {
                                    return <InvitationCard invitation={invitation} key={key} />;
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default inject('usersStore')(observer(InvitationsOnboarding));
