import './SpecialFeedbackActionQuestionItem.scss';

interface SpecialFeedbackActionQuestionItemProps {
  Icon?: any;
  label?: string;
  fallbackLabel?: string;
  active: boolean;
  onClick?: () => void;
  canDelete?: boolean;
  onDelete?: () => void;
}

const SpecialFeedbackActionQuestionItem = ({
  Icon,
  label,
  fallbackLabel,
  active,
  onClick,
  canDelete,
  onDelete,
}: SpecialFeedbackActionQuestionItemProps) => {
  return (
    <div className="feedback-action-question-item-container">
      <div
        className={`feedback-action-question-item feedback-action-question-item--intro ${
          active && 'feedback-action-question-item--active'
        } ${
          (!label || label.length === 0) &&
          'feedback-action-question-item--disabled'
        }`}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <Icon className="feedback-action-question-item-icon" />
        <div className="feedback-action-question-item-title">
          <div className="feedback-action-question-item-title-inner">
            {label && label.length > 0 ? label : fallbackLabel}
          </div>
        </div>
        {canDelete && (
          <div className="trash-icon" onClick={onDelete}>
            <i className="fa-solid fa-trash" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecialFeedbackActionQuestionItem;
