import axios from '../Axios';

const setAworkAuthToken = (projectID, code) => {
  return axios.post(`/projects/${projectID}/integrations/awork`, {
    code,
  });
};

const getBoards = (accessToken) => {
  return axios.get(`https://api.awork.io/api/v1/projects`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getTypesOfWork = (accessToken) => {
  return axios.get(`https://api.awork.io/api/v1/typeofwork`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getProjectStatuses = (accessToken, projectID) => {
  return axios.get(
    `https://api.awork.io/api/v1/projects/${projectID}/taskstatuses`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

const setAworkActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/awork/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectAworkIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/awork/${integrationID}`,
  );
};

export {
  setAworkAuthToken,
  getBoards,
  disconnectAworkIntegration,
  getProjectStatuses,
  setAworkActions,
  getTypesOfWork,
};
