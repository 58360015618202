import ConditionDisplay from 'components/ConditionDisplay/ConditionDisplay';
import ConditionPicker from 'components/ConditionPicker/ConditionPicker';
import { ConditionTagStatic } from 'components/ConditionTagStatic/ConditionTagStatic';
import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import InfoBox from 'components/InfoBox/InfoBox';
import ListTable, { CellLink, CellSession } from 'components/ListTable/ListTable';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { SelectPageUrl } from 'components/SelectPageUrl/SelectPageUrl';
import { SelectTimeOnPageDelay } from 'components/SelectTimeOnPageDelay/SelectTimeOnPageDelay';
import { SurveyLimitSendingPicker } from 'components/SurveyLimitSendingPicker/SurveyLimitSendingPicker';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import Gleap from 'gleap';
import TimeAgo from 'react-timeago';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useEffect, useMemo } from 'react';
import * as CodeBlock from 'react-code-blocks';
import Collapsible from 'react-collapsible';
import ReactTooltip from 'react-tooltip';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './OutboundTriggerConfiguration.scss';

export const audienceOptions = [
  { name: 'All people', value: 'all' },
  { name: 'Users only', value: 'users' },
  { name: 'Guests only', value: 'guests' },
];

interface OutboundTriggerConfigurationProps {
  outboundRule: Outbound;
  outboundStore?: OutboundStore;
  projectStore?: ProjectStore;
}

const OutboundTriggerConfiguration = ({
  outboundRule,
  projectStore,
  outboundStore,
}: OutboundTriggerConfigurationProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [outboundRule.trigger]);

  const getConditions = () => {
    if (!outboundRule.conditions || outboundRule.conditions.length === 0) {
      if (outboundRule?.eventTrigger && outboundRule?.eventTrigger.length > 0) {
        return [
          {
            event: outboundRule.eventTrigger,
            type: 'firstoccured',
            dateOperator: 'relative',
            matchOperator: 'greaterthan',
            data: outboundRule.eventTriggerDelay || 0,
          },
        ] as any[];
      } else {
        return [];
      }
    }
    return outboundRule.conditions;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        width: '20%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellSession
            session={row.value}
            url={`/projects/${projectStore?.currentProject?.id}/sessions/${row.value.id}`}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '25%',
        Header: 'Email',
        accessor: (row) => row,
        Cell: (row) => (
          <CellLink
            link={`mailto:${row.value?.email}`}
            text={row.value?.email ? row.value?.email : 'No email'}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '20%',
        Header: 'Signed up',
        accessor: (row) => row,
        Cell: (row) => <TimeAgo date={row.value.createdAt} />,
      },
    ],
    [],
  );

  const getTriggers = () => {
    if (!outboundRule.trigger) {
      return [];
    }
    return [outboundRule.trigger];
  };

  const getDelay = () => {
    if (!outboundRule.pageFilterDelay) {
      return 0;
    }

    const number = parseInt(outboundRule.pageFilterDelay as any);
    if (!isNaN(number) && number >= 0) {
      return number;
    }
    return 0;
  }
  const delay = getDelay();
  const conditions = getConditions();

  const getName = () => {
    if (outboundRule.type === 'SURVEY') {
      return 'survey';
    }
    if (outboundRule.type === 'NEWS') {
      return 'news article';
    }
    if (outboundRule.type === 'MESSAGE') {
      return 'chat message';
    }
    if (outboundRule.type === 'EMAIL') {
      return 'outbound email';
    }
    return 'outbound message'
  }

  const renderFrequency = () => {
    if (outboundRule.frequencyType === "fixed") {
      return;
    }

    return (
      <div className="collapsible-options-group">
        <div className="collapsible-options-group-header">Frequency</div>
        <div className="filter-form">
          <div>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">
                Send once, first time the person matches the rules
              </div>
              <input
                type="radio"
                name="once"
                checked={outboundRule.frequency === 'once'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.frequency = 'once';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            <label
              className={`bb-feedback-multiplechoice-container ${outboundRule.type === 'EMAIL' &&
                'bb-feedback-multiplechoice-container--disabled'
                }`}
            >
              <div className="text">
                Send every time the person matches the rules{' '}
                {outboundRule.type === 'EMAIL' && (
                  <span className="bold">(not available for email rules)</span>
                )}
              </div>
              <input
                type="radio"
                name="continuously"
                checked={outboundRule.frequency === 'continuously'}
                onChange={() => {
                  if (outboundRule.type === 'EMAIL') {
                    return;
                  }

                  runInAction(() => {
                    outboundRule.frequencyDays = 14;
                    outboundRule.frequency = 'continuously';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            {outboundRule.frequency === 'continuously' && (
              <>
                <div className='survey-limit-container'>
                  <SurveyLimitSendingPicker value={outboundRule.frequencyDays} onChange={(value) => {
                    if (value && value > 0) {
                      outboundRule.frequencyDays = value;
                    } else {
                      outboundRule.frequencyDays = 0;
                    }
                  }} />
                </div>
                <div className="mt-15">
                  <InfoBox
                    icon="triangle-exclamation"
                    className="mt-20"
                  >
                    <>
                      This outbound message will be sent every time the trigger occurs. {(outboundRule.frequencyDays && outboundRule.frequencyDays > 0) ? `Sending will be limited to once every ${outboundRule.frequencyDays} days.` : ''}
                    </>
                  </InfoBox>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderTrigger = () => {
    if (outboundRule.frequencyType === "fixed") {
      return null;
    }

    return (
      <>
        <ReactTooltip
          id="helpButtonTooltip"
          className="infoTooltip"
          delayHide={500}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip" onClick={() => {
                Gleap.openHelpCenterArticle("52", false);
              }}>
                <span className='tooltip-link'>{content}</span>
              </div>
            );
          }}
        />
        <div className="collapsible-options-group outbound-events">
          <div className="mt-0">
            <div className="input-label">When to send <i className="fa-solid fa-circle-question" data-for="helpButtonTooltip" data-tip={`Learn more about event based triggers`} /></div>
            <ConditionPicker
              conditions={getTriggers()}
              onChange={(triggers) => {
                runInAction(() => {
                  if (triggers && triggers.length > 0) {
                    outboundRule.trigger = triggers[0];
                  } else {
                    outboundRule.trigger = undefined;
                  }
                });
              }}
              streamedEventKeys={projectStore?.streamedEventKeys ?? []}
            />
            {!outboundRule.trigger && (
              <InfoBox simple className="mt-0 mb-30">
                <>
                  Not finding the event you are looking for? Start tracking events
                  with the Gleap SDK. <a
                    target="_blank"
                    href="https://docs.gleap.io/guides/track-events"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </>
              </InfoBox>
            )}
            {outboundRule.type !== 'EMAIL' && (
              <>
                <div className="mt-20">
                  <div className="input-label">Where to send</div>
                  <div className="time-on-page-rule">
                    <SelectPageUrl
                      className="mr-10"
                      value={outboundRule.pageFilter}
                      onChange={(value) => {
                        runInAction(() => {
                          outboundRule.pageFilter = value;
                        });
                      }}
                    />
                    <SelectTimeOnPageDelay
                      value={outboundRule.pageFilterDelay}
                      onChange={(value) => {
                        runInAction(() => {
                          outboundRule.pageFilterDelay = value;
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  const renderAudienceRecievers = () => {
    if (outboundRule.frequencyType !== "fixed") {
      return null;
    }

    if (outboundStore?.loadingFixedRecievers) {
      return (<div className="collapsible-options-group">
        <div className="collapsible-options-group-header">Audience preview</div>
        <div className="loading-audience-preview">
          <LoadingAnimationSmall />
          <div className='loading-preview-text'>Loading the preview might take 1-2 minutes...</div>
        </div>
      </div>);
    }

    return (
      <div className="collapsible-options-group">
        <div className="collapsible-options-group-header">This outbound message will be sent to {outboundStore?.fixedLimitReached ? outboundStore?.fixedRecieversLimitCount : outboundStore?.fixedRecieversCount} people right now</div>
        {outboundStore?.fixedLimitReached && (<div>
          <InfoBox simple className='mb-30'>
            🚨 You reached the limit of <b>{outboundStore?.fixedRecieversLimitCount} people</b> you can contact at once. <b>{outboundStore?.fixedRecieversCount - outboundStore?.fixedRecieversLimitCount} people</b> won't receive this message. <a href="#" onClick={() => {
              Gleap.startFeedbackFlow("contact");
            }}>Contact us</a> to increase this limit.
          </InfoBox>
        </div>)}
        {outboundStore?.listOfFixedRecievers && outboundStore?.listOfFixedRecievers.length > 0 && <div>
          <ListTable data={outboundStore?.listOfFixedRecievers} columns={columns} />
          <div className='text mt-20'>This preview is limited to 10 people.</div>
        </div>}
      </div>);
  }

  const renderAudience = () => {
    return (
      <>
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">Audience</div>
          <div className='mb-20'>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">
                Dynamic (people who match the rules now and in the future)
              </div>
              <input
                type="radio"
                name="dynamic"
                checked={outboundRule.frequencyType === 'dynamic'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.frequencyType = 'dynamic';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            {outboundRule.trigger &&
              <ReactTooltip
                id="triggerFixedInfoTooltip"
                className="infoTooltip"
                delayHide={500}
                type="light"
                effect="solid"
                getContent={(content) => {
                  return (
                    <div className="send-key-tooltip">
                      {content}
                    </div>
                  );
                }}
              />
            }
            <label
              data-for="triggerFixedInfoTooltip"
              data-tip="You can only use a fixed audience if you have no event trigger set up."
              className={`bb-feedback-multiplechoice-container ${outboundRule.trigger &&
                'bb-feedback-multiplechoice-container--disabled'
                }`}>
              <div className="text">
                Fixed (only people who match the rules right now)
              </div>
              <input
                type="radio"
                name="fixed"
                checked={outboundRule.frequencyType === 'fixed'}
                onChange={() => {
                  if (outboundRule.trigger) {
                    Swal.fire({
                      text: 'You can only use a fixed audience if you have no event trigger set up.',
                      showCancelButton: false,
                      confirmButtonText: 'Ok',
                    });
                    return;
                  }

                  runInAction(() => {
                    outboundRule.frequencyType = 'fixed';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
          </div>
          <div className="audience-selection">
            <div className='audience-dropdown'>
              <UserTypeDropDown
                onValueChanged={(value) => {
                  runInAction(() => {
                    outboundRule.targetAudience = value.value;
                  });
                }}
                value={audienceOptions.find(({ value }) => value === outboundRule.targetAudience)}
                options={audienceOptions}
              />
            </div>
            <ConditionPicker
              type='audience'
              conditions={getConditions()}
              onChange={(conditions) => {
                runInAction(() => {
                  if (!conditions || conditions.length <= 0) {
                    outboundRule.eventTrigger = '';
                  }
                  outboundRule.conditions = conditions;
                });
              }}
              streamedEventKeys={projectStore?.streamedEventKeys ?? []}
            />
          </div>
        </div>
        {renderAudienceRecievers()}
      </>
    );
  }

  return (
    <>
      <Collapsible
        className="Collapsible-big"
        trigger={<div className="collapsible-header">
          <div className="collapsible-header-title">Trigger your {getName()} automatically with rules <i className="fa-sharp fa-solid fa-chevron-right"></i></div>
          <div className="collapsible-header-sub">
            {outboundRule.trigger ? <div className='condition-tag-static-header'>
              <ConditionTagStatic
                className='mb-10'
                value={outboundRule.trigger} />
              <div className='condition-tag-static mb-10'>
                <div className='condition-tag-static--inner'>
                  <b>{audienceOptions.find(({ value }) => value === outboundRule.targetAudience)?.name}</b>
                </div>
              </div>
              {conditions.length > 0 &&
                <ConditionDisplay
                  conditions={getConditions()}
                />}
              {outboundRule.pageFilter ?
                <>
                  <div className="condition-tag-static-operator">and</div>
                  <div className='condition-tag-static mb-10'>
                    <div className='condition-tag-static--inner'>
                      Current page contains <b>{truncateString(outboundRule.pageFilter, 10)}</b>
                    </div>
                  </div>
                </>
                : null}
              {outboundRule.pageFilterDelay ?
                <>
                  <div className="condition-tag-static-operator">and</div>
                  <div className='condition-tag-static mb-10'>
                    <div className='condition-tag-static--inner'>
                      Time on page is <b>{delay} second{delay === 1 ? '' : 's'}</b>
                    </div>
                  </div>
                </>
                : null}
            </div> : <div><i className="fa-regular fa-bolt" /> Expand to set up your rules</div>}
          </div>
        </div>}
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible-big Collapsible--opened"
        onClose={() => { }}
      >
        {renderTrigger()}
        {renderAudience()}
        {renderFrequency()}
      </Collapsible>
      {outboundRule && outboundRule.type === 'SURVEY' && (<Collapsible
        className="Collapsible-big"
        trigger={<div className="collapsible-header">
          <div className="collapsible-header-title">Manually share your survey</div>
          <div className="collapsible-header-sub">
            <div>
              <i className="fa-regular fa-code"></i> Share by code<br />
              <i className="fa-regular fa-link-horizontal"></i> Share by link
            </div>
          </div>
        </div>}
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible-big Collapsible--opened"
        onClose={() => { }}
      >
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">Trigger the survey by code</div>
          <div>
            <div className='mb-10 text'>
              You can also trigger the survey by code. This is useful if you want to have full control over it.
            </div>
            <CodeBlock.CodeBlock
              text={`Gleap.showSurvey("${outboundRule.actionType}", "${outboundRule.format === 'survey' ? 'survey' : 'survey_full'}");`}
              language={'js'}
              showLineNumbers={false}
            />
            <div className='mt-10 text'>
              Check out our <a href="https://docs.gleap.io/javascript/surveys/#manually-sending-surveys" target="_blank" rel="noreferrer">full documentation</a> on how to trigger surveys by code.
            </div>
          </div>
        </div>
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">Trigger the survey with URL parameters</div>
          <div>
            <div className='mb-10 text'>
              You can trigger the survey with URL parameters. This allows you to trigger the survey from a link, which you can send to your users.
            </div>
            <CodeBlock.CodeBlock
              text={`https://yourapp.com/?gleap_survey=${outboundRule.actionType}&gleap_survey_format=${outboundRule.format === 'survey' ? 'survey' : 'survey_full'}`}
              language={'js'}
              showLineNumbers={false}
            />
            <div className='mt-10 text'>
              Check out our <a href="https://docs.gleap.io/javascript/surveys#send-surveys-with-url-parameters" target="_blank" rel="noreferrer">full documentation</a> on how to trigger surveys by code.
            </div>
          </div>
        </div>
      </Collapsible>)}
    </>
  );
};

export default inject('projectStore', 'outboundStore')(observer(OutboundTriggerConfiguration));
