import React, { useEffect, useState } from 'react';
import { ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import './CreateableSelectDropdown.scss';

interface CreatableSelectDropDownProps {
  items: any[];
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void;
  isMulti?: boolean;
  selectedItem?: any;
  label?: string;
  disabled?: boolean;
  labelPropertyName?: string;
  valuePropertyName?: string;
  className?: any;
  isLoading?: boolean;
  showBorder?: boolean;
  shared?: boolean;
}

const CreateableSelectDropdown = ({
  items,
  onChange,
  isMulti = true,
  selectedItem,
  label,
  disabled = false,
  labelPropertyName = 'label',
  valuePropertyName = 'value',
  className,
  isLoading = false,
  showBorder = true,
  shared = false,
}: CreatableSelectDropDownProps) => {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    setSelectedValue(selectedItem);
  }, [selectedItem]);

  const selectClass = classNames(
    {
      'creatable-select-container': true,
    },
    className,
  );

  return (
    <div className={selectClass}>
      {label && <div className="side-header">{label}</div>}
      <CreatableSelect
        isClearable
        isLoading={isLoading}
        getOptionLabel={(option) => `${option[labelPropertyName]}`}
        getOptionValue={(option) => `${option[valuePropertyName]}`}
        className="tag-select"
        classNamePrefix="tag-dropdown-selection"
        value={selectedValue ?? selectedItem}
        onChange={(val) => {
          setSelectedValue(val);
          onChange(val);
        }}
        components={{
          MultiValueContainer: (option: any) => {
            return (
              <div
                className="tag-select-tag"
                style={{
                  backgroundColor: `${option.data.color}33`,
                  color: `${option.data.color}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {option.data.label}
                <i
                  className="fa-solid fa-xmark"
                  style={{
                    marginLeft: '4px',
                    marginTop: '2px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (document.getElementById('react-select-6-input')) {
                      document.getElementById('react-select-6-input')!.blur();
                    }

                    const selectedValues = selectedValue ?? selectedItem;
                    const index = selectedValues.findIndex(
                      (selected) => selected.label === option.data.label,
                    );

                    if (index !== -1) {
                      selectedValues.splice(index, 1);
                    }

                    setSelectedValue(selectedValues);
                    onChange(selectedValues);
                  }}
                />
              </div>
            );
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item bug-prio-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <div
                  className="bug-prio-item-dot"
                  style={{
                    backgroundColor: option.data.color,
                  }}
                />
                <span>{option.data.label}</span>
              </div>
            );
          },
        }}
        options={items}
        isMulti={isMulti}
        placeholder={
          shared ? <div className="tag-select-add">
            No tags added
          </div> : <div className="tag-select-add">
            <i className="fa-solid fa-plus" /> Add tag
          </div>
        }
        isDisabled={disabled}
      />
    </div>
  );
};

export default CreateableSelectDropdown;
