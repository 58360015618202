import Container from 'components/Container/Container';
import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './FixedSuggestSubscriptionCard.scss';

interface FixedSuggestSubscriptionCardProps {
  organisationStore?: OrganisationStore;
}

const FixedSuggestSubscriptionCard = ({
  organisationStore,
}: FixedSuggestSubscriptionCardProps) => {
  return (
    <Container className="fixedSuggestSubscriptionContainer">
      <SizedContainer
        className="subscription-card-wrapper"
        size={ContainerSizes.L}
      >
        <HeadLine
          title="Upgrade your plan 🚀"
          subtitle="Please upgrade your plan to use this feature."
        />
        <div className="submit-container mt-15">
          {organisationStore!.currentOrganisation && (
            <>
              <PrimaryButton
                className="plans-button"
                label="Upgrade now ✨"
                onClick={() => {
                  organisationStore!.openBilling();
                }}
              />
            </>
          )}
        </div>
      </SizedContainer>
    </Container>
  );
};

export default inject('organisationStore')(
  observer(FixedSuggestSubscriptionCard),
);
