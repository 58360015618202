import React from 'react';
import classNames from 'classnames';
import './SubHeader.scss';

interface SubHeaderProps {
  label: String;
  className?: any;
}

const SubHeader = ({ label, className }: SubHeaderProps) => {
  const headerClassNames = classNames(
    {
      'input-label': true,
    },
    className,
  );

  return <div className={headerClassNames}>{label}</div>;
};

export default SubHeader;
