import React from 'react';
import './AuthenticationForm.scss';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { HeadLine } from 'components/HeadLine/HeadLine';
import bbLogo from '../../assets/icons/Gleap.svg';

interface AuthenticationFormProps {
  headline: string;
  subtitle?: any;
  form: any;
}

const AuthenticationForm = ({
  headline,
  subtitle,
  form,
}: AuthenticationFormProps) => {
  return (
    <div className="authentication-form">
      <div className="card-container">
        <div className="card">
          <SizedContainer
            className="sized-container-auth centered"
            size={ContainerSizes.XML}
          >
            <img src={bbLogo} className="logo" alt="logo" />
            <HeadLine
              className="mb-30 centered"
              title={headline}
              subtitle={subtitle}
            />
            {form}
          </SizedContainer>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationForm;
