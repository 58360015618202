import { useEffect } from 'react';
import './SDKInstallationModal.scss';
import { inject, observer } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ampli } from 'ampli';
import SDKTutorial from '../SDKTutorial/SDKTutorial';

interface SDKInstallationModalProps {
  modalStore?: ModalStore;
}

const SDKInstallationModal = ({ modalStore }: SDKInstallationModalProps) => {
  const selectedPlatform = modalStore?.getCustomData(MODALTYPE.SDK_INSTALLATION_TUTORIAL);

  useEffect(() => {
    if (selectedPlatform) {
      ampli.installationStarted({
        platform: selectedPlatform.title,
      });
    }
  }, []);

  if (!selectedPlatform) {
    return null;
  }

  return (
    <div className="sdk-install-modal">
      <SDKTutorial
        platform={selectedPlatform.key}
        showOverview={() => {
          modalStore?.closeModal();
        }}
      />
    </div>
  );
};

export default inject('modalStore')(observer(SDKInstallationModal));
