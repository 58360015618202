import { useEffect, useState } from 'react';
import './SidebarFoldable.scss';

interface SidebarFoldableProps {
  children: any;
  className?: string;
  title?: string;
  infoKey: string;
  defaultOpened?: boolean;
  tag?: string;
  canToggle?: boolean;
}

const SidebarFoldable = ({
  children,
  className,
  title = 'Info',
  infoKey,
  defaultOpened = false,
  tag,
  canToggle = true,
}: SidebarFoldableProps) => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    try {
      const openedStatusString = localStorage.getItem(`sidebar-${infoKey}`);
      if (openedStatusString === null) {
        setIsOpened(defaultOpened);
      } else {
        setIsOpened(openedStatusString === "true" ? true : false);
      }
    } catch (exp) { }
  }, []);

  return (
    <div className={`sidebar-foldable ${canToggle && 'sidebar-foldable--enabled'} ${className}`} key={infoKey}>
      <div
        className="sidebar-foldable-title"
        onClick={() => {
          setIsOpened(!isOpened);
          try {
            localStorage.setItem(`sidebar-${infoKey}`, `${!isOpened}`)
          } catch (exp) { }
        }}
      >
        <div className='title-headline'>
          <span>{title}</span>
          {tag && <span className='tag'>{tag}</span>}
        </div>
        {canToggle && <i
          className={`fa-sharp fa-solid fa-${isOpened ? 'chevron-up' : 'chevron-down'
            }`}
        />}
      </div>
      {(isOpened || !canToggle) && <div className="sidebar-foldable-content">{children}</div>}
    </div>
  );
};

export default SidebarFoldable;
