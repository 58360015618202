import React from 'react';
import './UserTypeDropDown.scss';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';

interface UserType {
  name: string;
  value: string;
}

interface UserTypeDropDownProps {
  options: UserType[];
  value?: UserType;
  onValueChanged: (data: UserType) => void;
}

export const iconList = {
  'all': 'fa-user-group',
  'users': 'fa-user-group',
  'guests': 'fa-user-group',
  'on-conversation-started': 'fa-message',
  'on-conversation-closed': 'fa-message-check',
  'none': 'fa-circle-stop',
}

const UserTypeDropDown = ({
  options,
  value,
  onValueChanged,
}: UserTypeDropDownProps) => {
  return (
    <>
      <Select
        className="user-type-dropdown-selection"
        classNamePrefix="user-type-dropdown-selection"
        value={value}
        placeholder="Choose option"
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            return (
              <div className="user-type-item">
                <i className={`fa-solid ${iconList[option.data.value]}`} />
                {option.data.name}
              </div>
            );
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item user-type-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data);
                  }
                }}
              >
                <i className={`fa-solid ${iconList[option.data.value]}`} />
                {option.data.name}
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected) {
            onValueChanged(selected);
          }
        }}
        options={options}
      />
    </>
  );
};

export default inject('projectStore')(observer(UserTypeDropDown));
