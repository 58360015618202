import axios from './Axios';

const createSession = (projectId: string, email: string) => {
  return axios.post(`/projects/${projectId}/sessions`, { email });
};

const unsubscribeSession = (gleapId: string, gleapHash: string, apiToken: string, unsubscribe: boolean) => {
  return axios.post(`/sessions/unsubscribe`, { unsubscribed: unsubscribe }, {
    headers: {
      'api-token': apiToken,
      'gleap-id': gleapId,
      'gleap-hash': gleapHash,
    }
  });
};

const removeSession = (projectId: string, sessionId: string) => {
  return axios.delete(`/projects/${projectId}/sessions/${sessionId}`);
};

const getSessions = (
  projectId: string,
  type: string,
  withFeedback: boolean,
  { skip, limit },
) => {
  return axios.get(
    `/projects/${projectId}/sessions?type=${type}&withFeedback=${withFeedback}&skip=${skip}&limit=${limit}`,
  );
};

const searchForSessions = (
  projectId: string,
  type: string,
  withFeedback: boolean,
  searchTerm: string,
) => {
  return axios.get(
    `/projects/${projectId}/sessions/search?type=${type}&withFeedback=${withFeedback}&searchTerm=${searchTerm}`,
  );
};

const getSession = (projectId: string, id: string) => {
  return axios.get(`/projects/${projectId}/sessions/${id}`);
};

const getEventsOfSession = (
  projectId: string,
  sessionId: string,
  limit: number,
) => {
  return axios.get(
    `/projects/${projectId}/sessions/${sessionId}/events?limit=${limit}`,
  );
};

const getSessionActivities = (projectId: string, id: string) => {
  return axios.get(`/projects/${projectId}/sessions/${id}/activities`);
};

export {
  createSession,
  getSessions,
  searchForSessions,
  getSession,
  getSessionActivities,
  getEventsOfSession,
  removeSession,
  unsubscribeSession,
};
