import classNames from 'classnames';
import './TextHref.scss';

interface TextHrefProps {
  label: string;
  link?: string;
  onClick?: any;
  className?: any;
}

export const TextHref = ({
  label,
  link,
  onClick,
  className,
}: TextHrefProps) => {
  const textHrefClassName = classNames(
    {
      'text-href': true,
    },
    className,
  );

  if (onClick) {
    return (
      <div
        className={textHrefClassName}
        onClick={() => {
          onClick();
        }}
      >
        {label}
      </div>
    );
  }
  return (
    <a href={link} className={textHrefClassName}>
      {label}
    </a>
  );
};
