import React, { useEffect, useState } from 'react';
import './BugDetail.scss';

import { inject, observer } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { useLocation } from 'react-router';
import { BugStore } from 'stores/private/BugStore';
import { Feature } from 'models/Enums';
import Details from './Details/Details';
import CustomData from './CustomData/CustomData';
import Replay from './Replay/Replay';
import ActivityTimeline from './ActivityTimeline/ActivityTimeline';
import SessionData from './SessionData/SessionData';
import SubscriberList from './SubscriberList/SubscriberList';
import { ampli } from 'ampli';
import Gleap from 'gleap';

interface BugDetailProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  shared?: boolean;
}

const BugDetail = ({
  modalStore,
  bugStore,
  shared
}: BugDetailProps) => {
  const [path, setpath] = useState('details');
  const location = useLocation();
  const currentBug = bugStore?.currentBug;
  const replaysEntitled = bugStore?.isFeatureInPlan(Feature.REPLAYS);
  const customDataEntitled = bugStore?.isFeatureInPlan(Feature.CUSTOM_DATA);
  const consoleLogEntitled = bugStore?.isFeatureInPlan(Feature.CONSOLE_LOG);
  const hideTabs = (!currentBug || currentBug.hideDetailTabsOnSharedBugs) && shared;
  const isInbox = currentBug && currentBug.type === "INQUIRY";

  const changeRoute = (route: string) => {
    setpath(route);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get('tab');
    if (tab) {
      changeRoute(tab);
    }

    ampli.bugDetailsOpened({
      bugId: currentBug?.id ?? '',
    });
    Gleap.trackEvent('Bug Details Opened');
  }, []);

  const showSubscriberList = () => {
    if (!currentBug) {
      return false;
    }

    if (currentBug?.type === 'FEATURE_REQUEST') {
      return true;
    }

    return false;
  };

  const showTechnicalInfoDataTabs = () => {
    if (!currentBug) {
      return false;
    }
    
    return true;
  };

  const showTechnicalDataTabs = () => {
    if (!currentBug) {
      return false;
    }

    if (
      currentBug?.type === 'INQUIRY' ||
      currentBug?.type === 'FEATURE_REQUEST'
    ) {
      return false;
    }

    return true;
  };

  const buildNavigation = () => {
    if (hideTabs) {
      return null;
    }

    return (
      <div className={`nav-container ${shared ? 'shared' : ''}`}>
        <div className="nav-items">
          <div
            className={`nav-item ${path === 'details' ? 'nav-item--active' : ''
              }`}
            onClick={() => changeRoute('details')}
          >
            Conversation
          </div>
          <div
            className={`hide-on-desktop nav-item ${path === 'mobile-actions' ? 'nav-item--active' : ''
              }`}
            onClick={() => changeRoute('mobile-actions')}
          >
            Details
          </div>
          {showSubscriberList() && !shared && (
            <div
              className={`nav-item ${path === 'subscriberlist' ? 'nav-item--active' : ''
                }`}
              onClick={() => {
                ampli.subscriberListOpened({
                  bugId: currentBug?.id ?? '--',
                });
                changeRoute('subscriberlist');
              }}
            >
              Subscribers ({currentBug?.upvotes?.length ?? 0})
            </div>
          )}
          {showTechnicalInfoDataTabs() &&
            ((shared && consoleLogEntitled) || !shared) && (
              <>
                <div
                  className={`nav-item ${path === 'sessiondata' ? 'nav-item--active' : ''
                    }`}
                  onClick={() => {
                    ampli.environmentalDataOpened({
                      bugId: currentBug?.id ?? '--',
                    });
                    changeRoute('sessiondata');
                  }}
                >
                  Environment data
                </div>
                <div
                  className={`nav-item ${path === 'activity' ? 'nav-item--active' : ''
                    }`}
                  onClick={() => {
                    ampli.activityLogOpened({
                      bugId: currentBug?.id ?? '--',
                    });
                    changeRoute('activity');
                  }}
                >
                  Activity log
                </div>
                {((shared && customDataEntitled) || !shared) && (
                  <div
                    className={`nav-item ${path === 'custom' ? 'nav-item--active' : ''
                      }`}
                    onClick={() => {
                      ampli.customDataOpened({
                        bugId: currentBug?.id ?? '--',
                      });
                      changeRoute('custom');
                    }}
                  >
                    Custom data
                  </div>
                )}
              </>
            )}
          {showTechnicalDataTabs() && ((shared && replaysEntitled) || !shared) && (
            <div
              className={`nav-item ${path === 'replay' ? 'nav-item--active' : ''
                }`}
              onClick={() => {
                ampli.replaysOpened({
                  bugId: currentBug?.id ?? '--',
                });
                modalStore!.openModal(MODALTYPE.REPLAYS, {}, true);
              }}
            >
              Replay
              <span className="beta-tag">BETA</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`bug-detail-container ${hideTabs && "bug-detail-container-tabs-hidden"}`}>
      {buildNavigation()}
      {path === 'details' && <Details shared={shared} isInbox={isInbox} />}
      {path === 'mobile-actions' && <Details showComments={false} shared={shared} isInbox={isInbox} />}
      {path === 'sessiondata' && <SessionData />}
      {path === 'subscriberlist' && <SubscriberList />}
      {path === 'activity' && <ActivityTimeline />}
      {path === 'custom' && <CustomData />}
      {path === 'replay' && <Replay shared={shared} />}
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
  'bugStore',
)(observer(BugDetail));
