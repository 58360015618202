import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { AzureDevopsIntegrationStore } from 'stores/integrations/AzureDevopsIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface AzureDevopsConfigurationProps {
  integrationID: string;
  index: number;
  azureDevopsIntegrationStore?: AzureDevopsIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const AzureDevopsConfiguration = ({
  integrationID,
  index,
  azureDevopsIntegrationStore,
  projectStore,
  onChange,
}: AzureDevopsConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const organizations = azureDevopsIntegrationStore?.organizations;
  const projects = azureDevopsIntegrationStore?.projects;
  const workItemTypes = azureDevopsIntegrationStore?.workItemTypes;

  const [organizationID, setOrganizationID] = useState('');
  const [projectID, setProjectID] = useState('');
  const [workItemType, setWorkItemType] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [organizationID, projectID, workItemType]);

  const initialize = async () => {
    azureDevopsIntegrationStore!.setIntegrationID(integrationID);
    azureDevopsIntegrationStore!.getOrganizations();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.AZURE_DEVOPS,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.azureDevops[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseOrganization = async (organizationId) => {
    setOrganizationID(organizationId);
    await azureDevopsIntegrationStore?.getProjects(organizationId);
  };

  const chooseProject = async (projectId) => {
    setProjectID(projectId);
    await azureDevopsIntegrationStore?.getWorkItemTypes(
      organizationID,
      projectId,
    );
  };

  const chooseWorkItemType = (workItemTypeId) => {
    setWorkItemType(workItemTypeId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      organizationID,
      projectID,
      workItemType,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Organizations"
        labelPropertyName="accountName"
        valuePropertyName="accountName"
        selectedItem={organizations.find(
          (element) =>
            element.accountName === currentIntegrationInfo?.organizationID,
        )}
        isLoading={organizations.length === 0}
        items={organizations}
        onChange={(option) => {
          chooseOrganization(option.accountName);
        }}
      />
      {organizationID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Projects"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={projects.find(
            (element) => element.id === currentIntegrationInfo?.projectID,
          )}
          isLoading={projects.length === 0}
          items={projects}
          onChange={(option) => {
            chooseProject(option.id);
          }}
        />
      )}
      {projectID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Work Item Types"
          labelPropertyName="name"
          valuePropertyName="name"
          selectedItem={workItemTypes.find(
            (element) => element.name === currentIntegrationInfo?.workItemType,
          )}
          isLoading={workItemTypes.length === 0}
          items={workItemTypes}
          onChange={(option) => {
            chooseWorkItemType(option.name);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'azureDevopsIntegrationStore',
  'projectStore',
)(observer(AzureDevopsConfiguration));
