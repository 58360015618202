import React, { useEffect, useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import classNames from 'classnames';
import './SelectDropDown.scss';
import Loading from 'components/Loading/Loading';

interface SelectDropDownProps {
  items: any[];
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void;
  selectedItem?: any;
  isMulti?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  labelPropertyName?: string;
  valuePropertyName?: string;
  className?: any;
  isLoading?: boolean;
  showBorder?: boolean;
  getOptionlabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
}

const SelectDropDown = ({
  items,
  onChange,
  selectedItem,
  isMulti = false,
  label,
  placeholder = 'Select',
  disabled = false,
  labelPropertyName = 'label',
  valuePropertyName = 'value',
  className,
  isLoading = false,
  showBorder = true,
  getOptionlabel = (option) => option[labelPropertyName],
  getOptionValue = (option) => option[valuePropertyName],
}: SelectDropDownProps) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const selectClass = classNames(
    {
      'select-container': true,
      'select-container--border': showBorder,
    },
    className,
  );
  
  useEffect(() => {
    if (!items) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if ('options' in item) {
        delete item.options;
      }
    }
  }, [items]);

  useEffect(() => {
    if (
      selectedItem &&
      selectedItem !== null &&
      selectedItem !== '' &&
      !initialized
    ) {
      setInitialized(true);
      onChange(selectedItem);
    }
  }, [selectedItem]);

  if (isLoading) {
    return (
      <div className="loading-container">
        {label && <div className="select-label mb-5">{label}</div>}
        <Loading className="loading-align mb-15" />
      </div>
    );
  }

  return (
    <div className={selectClass}>
      {label && <div className="select-label">{label}</div>}
      <Select
        isLoading={isLoading}
        getOptionLabel={getOptionlabel}
        getOptionValue={getOptionValue}
        className="select"
        classNamePrefix="dropdown-selection"
        value={selectedValue ?? selectedItem}
        onChange={(val) => {
          setSelectedValue(val);
          onChange(val);
        }}
        options={items}
        isMulti={isMulti}
        placeholder={placeholder}
        isDisabled={disabled}
      />
    </div>
  );
};

export default SelectDropDown;
