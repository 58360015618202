import React from 'react';
import classNames from 'classnames';
import './Card.scss';

export const Card = ({ className, children, onClick }: any) => {
  const cardClassName = classNames(
    {
      card: true,
    },
    className,
  );

  return (
    <div
      className={cardClassName}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};
