import { Editor } from '@tiptap/react';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import EmailTemplate from 'components/EmailTemplate/EmailTemplate';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useRef, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './EmailConfiguration.scss';

interface EmailConfigurationProps {
  outboundRule: Outbound;
  content?: any;
  inputContentChanged?: (content: any) => void;
  projectStore?: ProjectStore;
}

const EmailConfiguration = ({
  outboundRule,
  content,
  inputContentChanged,
  projectStore,
}: EmailConfigurationProps) => {
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);

  const [toggleState, setToggleState] = useState(false);

  return (
    <div className="email-container">
      <div className="email-composer">
        <div className="widget-preview">
          <div className="widget-preview-browser">
            <div className="widget-preview-browser-head">
              <div className="widget-preview-browser-head-close" />
              <div className="widget-preview-browser-head-minimize" />
              <div className="widget-preview-browser-head-maximize" />
            </div>
            <div className="widget-preview-browser-body">
              <div className="widget-preview-browser-body-header">
                <TextInput
                  name="email-subject"
                  value={outboundRule.subject}
                  placeholder="Email subject"
                  type="text"
                  error=""
                  onChange={(val) => {
                    runInAction(() => {
                      outboundRule.subject = val;
                      outboundRule.name = val;
                    });
                  }}
                />
              </div>
              {editorRef?.current && (
                <>
                  <div className="toolbar-wrapper">
                    <Toolbar editor={editorRef?.current} aiStyle="email" items={[ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.AI]} />
                  </div>
                  <Toolbar editor={editorRef?.current} aiStyle="email" items={[ToolbarItems.Basic, ToolbarItems.Advanced, ToolbarItems.AI]} floating />
                </>
              )}
              <EmailTemplate
                template={outboundRule.format ?? 'plain'}
                data={{
                  sender: outboundRule.sender,
                  projectName: projectStore?.currentProject?.name,
                }}
              >
                <EmailEditor
                  editorRef={editorRef}
                  onEditorReady={() => {
                    setToggleState(!toggleState);
                  }}
                  content={content}
                  inputContentChanged={inputContentChanged}
                />
              </EmailTemplate>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-group">
        <div className="input-label mt-20">Sender</div>
        <FeedbackUserFilter
          hasBorder
          value={outboundRule.sender}
          truncatePreview={50}
          truncate={50}
          placeholder="Select user"
          onValueChanged={(value) => {
            runInAction(() => {
              if (value) {
                outboundRule.sender = value;
              }
            });
          }}
        />
        <div className="filter-form mt-30">
          <div className="input-label">Template</div>
          <div className="mt-10">
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Personal</div>
              <input
                type="radio"
                name="once"
                checked={outboundRule.format === 'personal'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.format = 'personal';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Plain</div>
              <input
                type="radio"
                name="continuously"
                checked={outboundRule.format === 'plain'}
                onChange={() => {
                  runInAction(() => {
                    outboundRule.format = 'plain';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
          </div>
        </div>
        <div className="mt-30">
          <div className="input-label">Unsubscribe</div>
          <div className="switch-container mt-10">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                Swal.fire(
                  'Spam prevention',
                  'You can not remove the unsubscribe link from outbound emails.',
                  'info',
                );
              }}
              checked={true}
            />
            <span>Show unsubscribe link</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(EmailConfiguration));
