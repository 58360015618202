import { makeAutoObservable } from 'mobx';
import { User } from 'models/User';
import { SocialButtonType } from 'models/Enums';
import {
  loginWithEmailAndPassword,
  loginWithGithub,
  updateUser,
  loginWithGoogle,
  register,
  verifyAccount,
  resend,
  me,
  changePassword,
  deleteUser,
} from 'services/UserHttpService';
import { toast } from 'react-toastify';
import { resetPassword } from 'services/ResetPasswordHttpService';
import { Invitation } from 'models/Invitations';
import {
  answerInvitation,
  getMyInvitations,
} from 'services/OrganisationHttpService';
import WebSocketHelper, { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import { injectHeader } from '../../services/Axios';
import {
  FCM_TOPIC,
  PostMessageAction,
  PostMessageData,
} from 'models/PostMessageData';
import Gleap from 'gleap';
import { ampli } from '../../ampli';
import { reset } from '@amplitude/analytics-browser';

export const getRandomStep = () => {
  const items = ['flow1', 'flow2'];
  return items[Math.floor(Math.random() * items.length)];
};

export class UsersStore {
  currentUser: User | undefined;
  isUpdatingCurrentUser = false;
  isLoadingCurrentUser = false;
  pusherState?: string = undefined;
  loading = true;
  provider: SocialButtonType | undefined = undefined;
  stores: any = {};
  skippedInvitations = false;
  invitations: Invitation[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  onEvent = (event: string, data: any) => {
    if (event === WEBSOCKET_EVENTS.USER_INVITATION) {
      this.findInvitations();
    }
    if (event === WEBSOCKET_EVENTS.USER_ONBOARDING) {
      this.refreshUser();
      this.stores.navigate('/dashboard');
      window.location.reload();
    }
  };

  skipInvitations() {
    this.skippedInvitations = true;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setInvitations = (invitations) => {
    this.invitations = invitations;
  };

  findInvitations = async () => {
    const response = await getMyInvitations();
    this.setInvitations(response.data as Invitation[]);
  };

  acceptInvitation = async (id: string, accpet: boolean) => {
    const response = await answerInvitation(id, accpet);
    if (response.status === 200) {
      this.stores.projectStore.getProjects();
      this.stores.organisationStore.getMyOrganisations();
      this.findInvitations();

      // Refresh user if he hasn't completed the onboarding yet.
      if (!this.currentUser?.completedOnboarding) {
        this.refreshUser();
      }

      toast.success(
        accpet ? 'Invitation accepted 🎉' : 'Invitation declined 🎉',
      );
      this.stores.navigate('/dashboard');
    } else {
      toast.error('Something went wrong 🤕');
    }
  };

  isTokenAvailable = () => {
    try {
      const token = localStorage.getItem('@bbtoken');
      if (!token || token === '') {
        return false;
      }
      return true;
    } catch (exp) {}
  };

  initializePusher = (user: any) => {
    try {
      if (
        !(
          window.location.href &&
          (window.location.href.includes('sharedboard') ||
            window.location.href.includes('share'))
        )
      ) {
        // Initialize websockets
        const token = localStorage.getItem('@bbtoken');
        WebSocketHelper.getInstance().setOnStatusChange((state) => {
          this.pusherState = state;
        });
        WebSocketHelper.getInstance().connect(token ?? '', user?.id ?? '');
      }
    } catch (exp) {}
  };

  checkUserAuthState = async () => {
    if (!this.isTokenAvailable()) {
      return null;
    }

    injectHeader();

    const user = await this.refreshUser();
    if (user) {
      this.initializePusher(user);

      const token = localStorage.getItem('@bbtoken');
      if (!token || token === '') {
        this.logout();
        return null;
      }
    }

    return user;
  };

  setIsLoadingCurrentUser = (isLoadingCurrentUser: boolean) => {
    this.isLoadingCurrentUser = isLoadingCurrentUser;
  };

  refreshUser = async () => {
    this.setIsLoadingCurrentUser(true);
    try {
      const response = await me();
      if (response.status === 200) {
        const user = response.data as User;
        this.setCurrentUser(user);
        this.stores.organisationStore.getMyOrganisations();
        this.stores.projectStore.getProjects();
        this.setIsLoadingCurrentUser(false);
        return user;
      }
    } catch (err: any) {
      if (
        err &&
        err.response &&
        err.response.status &&
        err.response.status === 401
      ) {
        Gleap.clearIdentity();
        localStorage.clear();
        reset();
        window.location.reload();
      }
    }
    this.setIsLoadingCurrentUser(false);
    return null;
  };

  login = async (email: string, password: string) => {
    this.setLoading(true);
    try {
      const response = await loginWithEmailAndPassword(email, password);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        const user = data.user as User;
        this.setCurrentUser(user);

        this.afterLoginAction(data.token);

        this.setLoading(false);
      }
    } catch (err: any) {
      if (err?.response?.status === 401) {
        toast.error('E-Mail or password incorrect. Please try again.');
      } else {
        toast.error('Something went wrong. Please try it again.');
      }
      this.setLoading(false);
    }
  };

  register = async (
    token: string,
    email: string,
    password: string,
    name: string,
    appsumocode?: string,
  ) => {
    this.setLoading(true);
    try {
      const response: any = await register(
        token,
        email,
        password,
        name,
        getRandomStep(),
        appsumocode,
      );
      if (response.status === 201) {
        const { data } = response;

        this.setCurrentUser(data.user);
        this.setLoading(false);

        ampli.userRegistered({
          type: 'password',
        });

        (window as any).uetq = (window as any).uetq || [];
        (window as any).uetq.push('event', 'signup', {});

        await this.afterLoginAction(data.token, '/dashboard');
      }
    } catch (err: any) {
      if (err && err.response && err.response.status === 409) {
        this.setLoading(false);

        if (
          err.response.data &&
          err.response.data.param &&
          err.response.data.param === 'code'
        ) {
          toast.error('Code already used 😳');
        } else if (
          err.response.data &&
          err.response.data.name &&
          err.response.data.name === 'ValidationError'
        ) {
          toast.error('Email or password invalid 😳');
        } else if (
          err.response.data &&
          err.response.data.param &&
          err.response.data.param === 'user'
        ) {
          toast.error('Email or password invalid 😳');
        } else {
          toast.error('User already exists!');
        }
      } else {
        toast.error('Registration failed');
      }
      this.setLoading(false);
    }
  };

  loginGithub = async (code: string) => {
    this.setLoading(true);
    try {
      const response = await loginWithGithub(code);
      const { data } = response;
      await this.afterLoginAction(data.token);
      this.setLoading(false);
      this.setLoading(false);
    } catch (err: any) {
      this.stores.navigate('/login');
      this.setLoading(false);
    }
  };

  loginGoogle = async (code: string) => {
    this.setLoading(true);
    try {
      const response = await loginWithGoogle(code);
      const { data } = response;

      await this.afterLoginAction(data.token);
      this.setLoading(false);
    } catch (err: any) {
      this.stores.navigate('/login');
      this.setLoading(false);
    }
  };

  setCurrentUser = (currentUser: any) => {
    this.currentUser = currentUser;

    if (currentUser) {
      (window as any).messageHandler?.postMessage(
        JSON.stringify({
          action: PostMessageAction.SUBSCRIBE_TO_TOPIC,
          data: {
            topic: `${FCM_TOPIC.USER}-${currentUser.id}`,
          },
        } as PostMessageData),
      );

      const name = `${currentUser.firstName} ${currentUser.lastName}`;

      Gleap.identify(currentUser.id, {
        name,
        email: currentUser.email,
      });

      var userData = {
        name,
        email: currentUser.email,
      };

      const utmSource = localStorage.getItem('utm_source');
      if (utmSource) {
        userData['utm_source'] = utmSource;
      }

      const utmMedium = localStorage.getItem('utm_medium');
      if (utmMedium) {
        userData['utm_medium'] = utmMedium;
      }

      const utmTerm = localStorage.getItem('utm_term');
      if (utmTerm) {
        userData['utm_term'] = utmTerm;
      }

      const utmContent = localStorage.getItem('utm_content');
      if (utmContent) {
        userData['utm_content'] = utmContent;
      }

      const utmCampaign = localStorage.getItem('utm_campaign');
      if (utmCampaign) {
        userData['utm_campaign'] = utmCampaign;
      }

      ampli.identify(currentUser.id, userData);

      if ((window as any).profitwell) {
        (window as any).profitwell('start', { user_email: currentUser.email });
      }
    }
  };

  setIsUpdatingCurrentUser = (isUpdatingCurrentUser: boolean) => {
    this.isUpdatingCurrentUser = isUpdatingCurrentUser;
  };

  updateUser = async (id: string, data: any, hideToast?: boolean) => {
    if (this.currentUser) {
      this.setIsUpdatingCurrentUser(true);
      try {
        const response = await updateUser(id, data);
        if (response && response.status === 200) {
          this.setCurrentUser(response.data as User);
          if (!hideToast) {
            toast.success('Profile updated ✓');
          }
        }
      } catch (exp) {
        if (!hideToast) {
          toast.error('Upps. Something went wrong.');
        }
      }
      this.setIsUpdatingCurrentUser(false);
    }
  };

  deleteUser = async () => {
    try {
      await deleteUser();
      this.logout();
    } catch (err: any) {
      if (err.response.status === 404) {
        toast.error('User not found in organisation.');
      } else if (err.response.status === 403) {
        toast.error(
          'User could not be deleted. Please delete your company first.',
        );
      } else {
        toast.error('Could not delete user. Please try it again later.');
      }
    }
  };

  changeUserPassword = async (
    id: string,
    oldPassword: string,
    newPassword: string,
  ) => {
    await changePassword(id, newPassword, oldPassword);
  };

  oauthLogin = (type: SocialButtonType) => {
    this.provider = type;
  };

  resend = async () => {
    if (this.currentUser) {
      try {
        await resend(this.currentUser);
        toast.success('Verification code sent 📬.');
      } catch (exp) {
        toast.error('Too many attemps. Please try again in 5 minutes.');
      }
    }
  };

  verifyCode = async (code: string) => {
    this.setLoading(true);
    try {
      const response = await verifyAccount(code, this.currentUser!.email);
      if (!response) {
        return false;
      }

      if (response.status === 200) {
        await this.refreshUser();

        ampli.passwordVerified();

        this.setLoading(false);
        return true;
      }
    } catch (err: any) {
      if (err.response.status === 304) {
        toast.error('Your code expired.');
      } else {
        toast.error('Your code is invalid. Please try again.');
      }
      this.setLoading(false);
    }

    return false;
  };

  logout = (ignoreRedirect: boolean = false, redirect = true) => {
    (window as any).messageHandler?.postMessage(
      JSON.stringify({
        action: PostMessageAction.UNSUBSCRIBE_FROM_TOPIC,
        data: {
          topic: `${FCM_TOPIC.USER}-${this.currentUser?.id}`,
        },
      } as PostMessageData),
    );

    localStorage.removeItem('@bbtoken');
    localStorage.removeItem('@verifyTime');
    this.setCurrentUser(undefined);
    Gleap.clearIdentity();
    reset();

    if (redirect) {
      const currentPath = window.location.pathname;
      if (currentPath && !ignoreRedirect) {
        this.stores.navigate(`/?redirect=${currentPath}`, { replace: true });
      } else {
        this.stores.navigate('/', { replace: true });
      }
    }

    window.location.reload();
  };

  afterLoginAction = async (token, route?: string) => {
    if (token) {
      localStorage.setItem('@bbtoken', token);
      injectHeader();
      const user = await this.refreshUser();
      if (user) {
        this.initializePusher(user);
      }

      let defaultRoute = '/dashboard';
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get('redirect');

      if (
        redirectUrl &&
        redirectUrl.length > 0 &&
        redirectUrl.startsWith('/')
      ) {
        defaultRoute = redirectUrl;
      }

      this.stores.navigate(route ?? defaultRoute);
    }
  };

  resetPasswordWithToken = async (token: string, password: string) => {
    try {
      await resetPassword(token, password);
      toast.success('Successfully reseted password');
      return true;
    } catch (err: any) {
      toast.error('Token is already used.');
      return false;
    }
  };

  refreshData = () => {
    // this.refreshUser();
  };
}
