import React from 'react';
import classNames from 'classnames';
import './TextHeadline.scss';

interface TextHeadlineProps {
  text: string;
  className?: any;
}

export default ({ text, className }: TextHeadlineProps) => {
  const textHeadlineClassName = classNames('text-headline', className);

  return <div className={textHeadlineClassName}>{text}</div>;
};
