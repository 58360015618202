import { arrayMoveImmutable } from 'array-move';
import { ReactComponent as DragIcon } from 'assets/icons/dragicon.svg';
import NoArticleImage from 'assets/illustrations/HelpArticle.svg';
import { HeadLine } from 'components/HeadLine/HeadLine';
import LinkButton from 'components/LinkButton/LinkButton';
import { CellGestureDetector } from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as CodeBlock from 'react-code-blocks';
import Collapsible from 'react-collapsible';
import { useNavigate, useParams } from 'react-router';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import makeUrlFriendly from 'services/UrlHelper';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';

export const calculateTotalArticleVotes = (votes: any) => {
  const upvotes = (votes ?? {}).count ?? {};

  var total = {
    bad: 0,
    good: 0,
    neutral: 0,
  };

  const keys = Object.keys(upvotes);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = upvotes[key];
    if (value) {
      const moodKeys = Object.keys(value);
      for (let j = 0; j < moodKeys.length; j++) {
        const moodKey = moodKeys[j];
        const moodValue = value[moodKey];
        total[moodKey] += moodValue;
      }
    }
  }

  return total;
}

interface HelpcenterCollectionDetailProps {
  projectStore?: ProjectStore;
  helpcenterStore?: HelpcenterStore;
  modalStore?: ModalStore;
}

const HelpcenterCollectionDetail = ({
  projectStore,
  helpcenterStore,
  modalStore,
}: HelpcenterCollectionDetailProps) => {
  const { projectId, collectionId } = useParams();
  const navigate = useNavigate();

  const projectUsers = projectStore?.currentProjectUsers;
  const project = projectStore?.currentProject;
  const collection = helpcenterStore?.currentCollection;
  const collectionArticles = helpcenterStore?.currentArticels;
  const helpcenterConfig = projectStore?.flowConfig.helpcenterConfig;
  const previewLanguageCode = helpcenterConfig?.localization[0].language;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    initialize();
  }, [collectionId, project]);

  const initialize = async () => {
    if (collectionId && project) {
      setIsLoading(true);
      await helpcenterStore?.getAndSetCollection(collectionId);
      setIsLoading(false);
    }
  };

  const onSortEnd = (event) => {
    runInAction(() => {
      helpcenterStore!.currentArticels = arrayMoveImmutable(
        collectionArticles!,
        event.oldIndex,
        event.newIndex,
      );

      let currentArticle = helpcenterStore!.currentArticels[event.newIndex];

      const newLexoRank = helpcenterStore!.calculateNewLexorank(
        collectionArticles!,
        event.newIndex,
        currentArticle.id,
      );

      currentArticle.lexorank = newLexoRank.toString();

      helpcenterStore!.updateArticle(currentArticle, collectionId!);
    });
  };

  const DragHandle = SortableHandle(() => (
    <DragIcon className="lanes-drag-icon cursor-focus" />
  ));

  const SortableItem = SortableElement((value) => {
    const articleItem = value.value;

    const title =
      (previewLanguageCode &&
        articleItem.title &&
        Object.keys(articleItem.title).length > 0 &&
        articleItem.title[previewLanguageCode] !== '' &&
        articleItem.title[previewLanguageCode]) ??
      'No title set for default language';

    var user: any = null;
    if (articleItem.author && projectUsers) {
      user = projectUsers.find((o) => o.id === articleItem.author);
    }

    const upvotes = calculateTotalArticleVotes(articleItem.upvotes);
    const score = Math.round((upvotes.good / (upvotes.bad + upvotes.neutral + upvotes.good)) * 100);

    return (
      <div className="collection-list-item">
        <div className="collection-list-item-details">
          <DragHandle />
          <CellGestureDetector
            onClick={() => {
              navigate(
                `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleItem.id}`,
              );
            }}
            className="ml-10 r-66-first"
            label={title}
          />
          <div className="collection-list-item-details-author r-25">
            {user ? (
              <>
                <UserAvatar
                  small
                  email={user.email}
                  imageUrl={user.profileImageUrl}
                />
                <div className="collection-list-item-details-author-name">{`${user.firstName} ${user.lastName}`}</div>
              </>
            ) : <>--</>}
          </div>
          <div className="r-25 collection-list-item-details-date">
            {moment(articleItem.createdAt).format('MMM Do, h:mm:ss a')}
          </div>
          <div className='article-ratings'>
            <div className='article-ratings--inner'>
              <div className='article-ratings-item'><span>😞</span> {upvotes.bad}</div>
              <div className='article-ratings-item'><span>😐</span> {upvotes.neutral}</div>
              <div className='article-ratings-item'><span>😁</span> {upvotes.good}</div>
            </div>
            <div className={`article-ratings-score ${score > 75 && 'article-ratings-score--good'} ${score < 25 && 'article-ratings-score--bad'}`}>
              {isNaN(score) ? `--%` : `${score}%`}
            </div>
          </div>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem key={value.id} index={index} value={value} />
        ))}
      </div>
    );
  });

  if (isLoading) {
    return (
      <PageContainer>
        <PageHeadLine
          onActionBack={() => navigate(-1)}
          title={
            (previewLanguageCode &&
              collection?.title &&
              Object.keys(collection.title).length > 0 &&
              collection.title[previewLanguageCode] !== '' &&
              collection.title[previewLanguageCode]) ??
            '...'
          }
        />
        <PageContent isCentered hasTitle>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const helpCenterTitle =
    (previewLanguageCode &&
      collection?.title &&
      Object.keys(collection?.title).length > 0 &&
      collection.title[previewLanguageCode] !== '' &&
      collection.title[previewLanguageCode]) ??
    'No title set for default language';

  const shareBaseURL = projectStore?.currentProject
    ?.customDomainHelpCenter
    ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
    : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;
  const collectionURL = `${shareBaseURL}/${previewLanguageCode}/collections/${collection?.docId
    }-${makeUrlFriendly(helpCenterTitle)}`;

  return (
    <PageContainer>
      <PageHeadLine onActionBack={() => navigate(-1)} title={helpCenterTitle}>
        <div className="header-content-right">
          <LinkButton
            label="Open collection"
            className="mr-10"
            iconSideRight={false}
            icon="arrow-up-right-from-square"
            onClick={() => {
              (window as any)
                .open(collectionURL, '_blank')
                .focus();
            }}
          />
          <LinkButton
            label="Edit collection"
            iconSideRight={false}
            icon="pen"
            className="mr-10"
            onClick={() => {
              modalStore!.openModal(
                MODALTYPE.CREATE_HELPCENTER_COLLECTION,
                collection,
              );
            }}
          />
          <PrimaryButton
            label="Add article"
            icon="plus"
            iconSideRight={false}
            onClick={() => {
              navigate(
                `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/new`,
              );
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle isCentered={collectionArticles?.length === 0}>
        {collectionArticles && collectionArticles?.length > 0 ? (
          <>
            <div className="sortable-table-header">
              <div className="r-66">Name</div>
              <div className="r-25">Author</div>
              <div className="r-25">Created at</div>
              <div className="article-ratings-title">Rating</div>
            </div>
            <SortableList
              pressDelay={100}
              itemsList={collectionArticles}
              onSortEnd={onSortEnd}
              useDragHandle
              helperClass="sortable-helper"
            />
            <div className='share-article-container'>
              <Collapsible
                className="Collapsible-big"
                trigger={<div className="collapsible-header">
                  <div className="collapsible-header-title">Share this collection</div>
                  <div className="collapsible-header-sub">
                    <div>
                      <i className="fa-regular fa-code"></i> Share by code<br />
                      <i className="fa-regular fa-link-horizontal"></i> Share by link
                    </div>
                  </div>
                </div>}
                transitionTime={200}
                overflowWhenOpen="initial"
                openedClassName="Collapsible-big Collapsible--opened"
                onClose={() => { }}
              >
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">Share the collection by code</div>
                  <div>
                    Offer <b>contextual help</b> to your customers by opening this
                    collection directly within the Gleap widget.
                    <div className="mt-10 mb-10 code-container">
                      <CodeBlock.CodeBlock
                        text={`Gleap.openHelpCenterCollection("${collection?.docId}", false);`}
                        language={'js'}
                        showLineNumbers={false}
                      />
                    </div>
                    <a
                      href="https://docs.gleap.io/javascript/helpcenter"
                      target={'_blank'}
                      className="mt-10"
                      rel="noreferrer"
                    >
                      Open documentation
                    </a>
                  </div>
                </div>
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">Share the collection by link</div>
                  <div>
                    <CodeBlock.CodeBlock
                      text={collectionURL}
                      language={'js'}
                      showLineNumbers={false}
                    />
                  </div>
                </div>
              </Collapsible>
            </div>
          </>
        ) : (
          <div className="no-surveys-container">
            <img
              src={NoArticleImage}
              alt="No articles created yet"
              className="no-surveys-image"
            />
            <>
              <HeadLine
                title="No articles created yet"
                subtitle="Provide your users with the help they need with articles."
              />
              <PrimaryButton
                icon="plus"
                iconSideRight={false}
                onClick={async () => {
                  navigate(
                    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/new`,
                  );
                }}
                label={'Create article'}
              />
            </>
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'helpcenterStore',
  'projectStore',
  'modalStore',
)(observer(HelpcenterCollectionDetail));
