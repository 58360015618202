import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface SingleSidenavElementProps {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  IconActive?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  projectId: string;
  name: string;
  projectPath: string;
  extraProjectPaths?: string[];
  readOnly?: boolean;
  visible?: boolean;
  className?: string;
  notificationCount?: number;
}
const SingleSidenavElement = ({
  Icon,
  IconActive,
  projectId,
  projectPath,
  extraProjectPaths = [],
  name,
  readOnly = false,
  visible = true,
  className = '',
  notificationCount,
}: SingleSidenavElementProps) => {
  // @ts-ignore
  const location = useLocation();
  const navigate = useNavigate();

  if (!visible) {
    return <></>;
  }

  const isActive = () => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 3) {
        if (parts[3] === projectPath) {
          return true;
        }

        for (let i = 0; i < extraProjectPaths.length; i++) {
          if (parts[3] === extraProjectPaths[i]) {
            return true;
          }
        }
      }

      if (
        projectPath === '' &&
        parts.length === 3 &&
        parts[1] === 'projects' &&
        parts[2] !== 'create'
      ) {
        return true;
      }
    }

    return false;
  };

  let notificationBadge;
  if (notificationCount && notificationCount > 0) {
    notificationBadge = (
      <div className="notifications-badge">{notificationCount > 9 ? '9+' : notificationCount}</div>
    );
  }

  const active = isActive();

  return (
    <div
      className={`sidenav-element ${className} ${active ? 'active' : ''}`}
      onClick={() => {
        if (!readOnly) {
          navigate(`/projects/${projectId}/${projectPath}`);
        }
      }}
    >
      {/*Icon && (!active || !IconActive) && <Icon />}
      {IconActive && active && <IconActive />*/}
      {IconActive && <IconActive />}
      {notificationBadge}
      <span className="sidenav-tooltip">{name}</span>
    </div>
  );
};

export default SingleSidenavElement;
