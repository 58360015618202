import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { isMacintosh, useClickedOutside, useEscape } from 'services/Helper';
import { ProjectStore } from 'stores/private/ProjectStore';
import { ReactComponent as SearchIcon } from 'assets/icons/noun-search-1157129-FFFFFF.svg';
import './BoardSearchFilter.scss';
import { ampli } from 'ampli';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import ReactTooltip from 'react-tooltip';

interface BoardSearchFilterProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  iconOnly?: boolean;
}

const BoardSearchFilter = ({
  projectStore,
  modalStore,
  iconOnly = false,
}: BoardSearchFilterProps) => {
  const [fullTextFilter, setFullTextFilter] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowFilter(false);
  });
  useEscape(() => {
    setShowFilter(false);
  });

  useEffect(() => {
    projectStore?.searchForTicketsForAllLanes({ searchTerm: fullTextFilter });
    if (fullTextFilter && fullTextFilter.length > 0) {
      ampli.boardSearched();
    }
  }, [fullTextFilter]);

  const isActive = fullTextFilter && fullTextFilter.length > 0;

  return (
    <div
      className={`board-search-filter hide-on-mobile ${iconOnly && 'board-search-filter--icononly'
        }`}
      ref={wrapperRef}
    >
      <>
        <ReactTooltip
          id="openSearchTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={500}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
                <div className="hotkey-help">
                  {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                  <div>K</div>
                </div>
              </div>
            );
          }}
        />
        <div data-for="openSearchTooltip" data-tip={`Open search`}>
          <div
            className="board-search-filter-button"
            onClick={() => {
              modalStore?.openModal(MODALTYPE.GLOBAL_SEARCH);
            }}
          >
            <i className="fa-solid fa-magnifying-glass" />
            {!iconOnly && <span>Search</span>}
            {isActive && (
              <div className="board-search-filter-active-indicator" />
            )}
          </div>
        </div>
      </>
      {showFilter && (
        <div className="board-search-filter-selection">
          <TextInput
            inputRef={{
              ref: inputRef,
            }}
            className="fulltext-filter"
            placeholder="Search"
            type="text"
            error=""
            icon="magnifying-glass"
            initalValue={fullTextFilter}
            onChange={(text) => {
              setFullTextFilter(text);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(BoardSearchFilter));
