import Loading from 'components/Loading/Loading';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import Switch from 'react-switch';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { INTEGRATION, integrations, localActions } from 'models/Integration';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ProjectStore } from 'stores/private/ProjectStore';
import ARROW from '../../../../assets/icons/np_arrow_3542562_000000.svg';
import GLEAP_LOGO from '../../../../assets/icons/Gleap.svg';
import AsanaConfiguration from './ConfigurationComponents/AsanaConfiguration/AsanaConfiguration';
import AworkConfiguration from './ConfigurationComponents/AworkConfiguration/AworkConfiguration';
import AzureConfiguration from './ConfigurationComponents/AzureConfiguration/AzureConfiguration';
import ClickupConfiguration from './ConfigurationComponents/ClickupConfiguration/ClickupConfiguration';
import GithubConfiguration from './ConfigurationComponents/GithubConfiguration/GithubConfiguration';
import JiraConfiguration from './ConfigurationComponents/JiraConfiguration/JiraConfiguration';
import NotionConfiguration from './ConfigurationComponents/NotionConfiguration/NotionConfiguration';
import SlackConfiguration from './ConfigurationComponents/SlackConfiguration/SlackConfiguration';
import TrelloConfiguration from './ConfigurationComponents/TrelloConfiguration/TrelloConfiguration';
import WebhookConfiguration from './ConfigurationComponents/WebhookConfiguration/WebhookConfiguration';
import ZapierConfiguration from './ConfigurationComponents/ZapierConfiguration/ZapierConfiguration';
import ShortcutConfiguration from './ConfigurationComponents/ShortcutConfiguration/ShortcutConfiguration';
import PlutioConfiguration from './ConfigurationComponents/PlutioConfiguration/PlutioConfiguration';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import { runInAction } from 'mobx';
import './ConfigureIntegration.scss';
import TextInput from 'components/TextInput/TextInput';
import React from 'react';
import ZendeskConfiguration from './ConfigurationComponents/ZendeskConfiguration/ZendeskConfiguration';
import { ampli } from 'ampli';
import HubSpotConfiguration from './ConfigurationComponents/HubSpotConfiguration/HubSpotConfiguration';
import LinkButton from 'components/LinkButton/LinkButton';
import HelpscoutConfiguration from './ConfigurationComponents/HelpscoutConfiguration/HelpscoutConfiguration';
import GitlabConfiguration from './ConfigurationComponents/GitlabConfiguration/GitlabConfiguration';
import AzureDevopsConfiguration from './ConfigurationComponents/AzureDevopsConfiguration/AzureDevopsConfiguration';
import LinearConfiguration from './ConfigurationComponents/LinearConfiguration/LinearConfiguration';
import EmailConfiguration from './ConfigurationComponents/EmailConfiguration/EmailConfiguration';

interface ConfigureIntegrationProps {
  projectStore?: ProjectStore;
}

const ConfigureIntegration = ({ projectStore }: ConfigureIntegrationProps) => {
  const navigate = useNavigate();
  const { projectId, integration, integrationID }: any = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState({} as any);
  const [integrationIsValid, setIntegrationIsValid] = useState(false);
  const [actions, setActions] = useState([
    { local: null, integration: null, actionTypes: [], integrationInfo: {} },
  ]);
  const [toggle, setToggle] = useState(false);
  const currentProject = projectStore?.currentProject;
  const projectTypes = currentProject?.projectTypes
    ? [
        ...JSON.parse(JSON.stringify(currentProject?.projectTypes)),
        ...[{ name: 'Survey', type: 'SURVEY' }],
      ]
    : [];

  useEffect(() => {
    projectStore!.loadProjectById(projectId, true);

    const generalActions: any = getPreparedGeneralActions();
    setActions(generalActions);
    validate();
  }, []);

  useEffect(() => {
    initialize();
  }, [currentProject]);

  // @ts-ignore
  const initialize = async () => {
    setIsLoading(true);

    if (!integration) {
      toast.error('Something went wrong!');
      navigate(`/projects/${projectId}/integrations`, { replace: true });
      return;
    }
    const integrationsData = integrations[integration];
    if (!integrationsData) {
      toast.error('Something went wrong!');
      navigate(`/projects/${projectId}/integrations`, { replace: true });
      return;
    }

    setCurrentIntegration(integrationsData);

    if (
      !currentProject?.integrations ||
      !currentProject?.integrations[integration]
    ) {
      return;
    }

    const integrationData = {
      ...currentProject?.integrations[integration][integrationID],
    };

    if (integrationData?.actions) {
      setActions(JSON.parse(JSON.stringify(integrationData.actions)));
    }

    setIsLoading(false);
    validate();
  };

  const getPreparedGeneralActions = () => {
    switch (integration) {
      case INTEGRATION.ASANA:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];

      case INTEGRATION.AWORK:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];

      case INTEGRATION.AZURE:
        return [
          {
            local: 'CREATED',
            integration: 'SEND_MESSAGE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];

      case INTEGRATION.CLICKUP:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];

      case INTEGRATION.GITHUB:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.GITLAB:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.HUBSPOT:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TICKET',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TICKET',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TICKET',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.HELPSCOUT:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_CONVERSATION',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.JIRA:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.NOTION:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.PLUTIO:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.SHORTCUT:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_STORY',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_STORY',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_STORY',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_STORY',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.SLACK:
        return [
          {
            local: 'CREATED',
            integration: 'SEND_MESSAGE_TO_CHANNEL',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_THREAD',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'PROCESSING_USER_CHANGED',
            integration: 'PROCESSING_USER_CHANGED',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.TRELLO:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TASK',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.WEBHOOK:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'PROCESSING_USER_CHANGED',
            integration: 'UPDATE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.EMAIL:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.ZENDESK:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_TICKET',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_TICKET',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_TICKET',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.ZAPIER:
        return [
          {
            local: 'CREATED',
            integration: 'SEND',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'SEND',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'SEND',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.AZURE_DEVOPS:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_WORK_ITEM',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_WORK_ITEM',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_WORK_ITEM',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_WORK_ITEM',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      case INTEGRATION.LINEAR:
        return [
          {
            local: 'CREATED',
            integration: 'CREATE_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'UPDATED',
            integration: 'UPDATE_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'DELETED',
            integration: 'DELETE_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
          {
            local: 'MESSAGE_SENT',
            integration: 'SEND_MESSAGE_TO_ISSUE',
            actionTypes: [
              'BUG',
              'CRASH',
              'RATING',
              'FEATURE_REQUEST',
              'INQUIRY',
            ],
            integrationInfo: {},
          },
        ];
      default:
        return [
          {
            local: null,
            integration: null,
            actionTypes: [],
            integrationInfo: {},
          },
        ];
    }
  };

  const handleGeneralIntegrationInfo = (integrationInfo) => {
    for (let i = 0; i < actions.length; i++) {
      actions[i].integrationInfo = integrationInfo;
    }
  };

  const handleGeneralLocalAction = (actionTypes: any) => {
    for (let i = 0; i < actions.length; i++) {
      actions[i].actionTypes = actionTypes;
    }
  };

  const addAction = () => {
    setActions((oldArray) => [
      ...oldArray,
      { local: null, integration: null, actionTypes: [], integrationInfo: {} },
    ]);
    validate();
  };

  const deleteAction = (index) => {
    const updatedArray = actions;

    updatedArray.splice(index, 1);

    setActions(updatedArray);
    setToggle(!toggle);
    validate();
  };

  const validate = () => {
    let currentIntegrationIsValid = true;
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];

      if (
        !action.local ||
        action.local === '' ||
        !action.integration ||
        action.integration === ''
      ) {
        currentIntegrationIsValid = false;
        break;
      }

      if (!currentIntegration.validate(action.integrationInfo)) {
        currentIntegrationIsValid = false;
        break;
      }
    }

    setIntegrationIsValid(currentIntegrationIsValid);
  };

  const saveIntegration = async () => {
    setIsSaving(true);

    try {
      await integrations[integration].save(
        projectId,
        integrationID,
        actions,
        false,
        currentProject?.integrations[integration][integrationID]
          .autoChangeStatus,
        currentProject?.integrations[integration][integrationID].sendDuplicates,
      );
      await projectStore?.loadProjectById(projectId, true);
      toast.success('Sucessfully saved integration');
      navigate(`/projects/${projectId}/integrations`, { replace: true });

      ampli.integrationSetupCompleted({
        projectId: projectId ?? '',
        name: integration,
      });
    } catch (err: any) {
      toast.error('Could not save integration');
    }

    setIsSaving(false);
  };

  const getIntegrationConfiguarionComponent = (integrationName, index) => {
    switch (integrationName) {
      case INTEGRATION.ASANA:
        return (
          <AsanaConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.AWORK:
        return (
          <AworkConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.AZURE:
        return (
          <AzureConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.CLICKUP:
        return (
          <ClickupConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.GITHUB:
        return (
          <GithubConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.GITLAB:
        return (
          <GitlabConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.HUBSPOT:
        return (
          <HubSpotConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.HELPSCOUT:
        return (
          <HelpscoutConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.JIRA:
        return (
          <JiraConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.NOTION:
        return (
          <NotionConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.PLUTIO:
        return (
          <PlutioConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.SHORTCUT:
        return (
          <ShortcutConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.SLACK:
        return (
          <SlackConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.TRELLO:
        return (
          <TrelloConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.WEBHOOK:
        return (
          <WebhookConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.ZAPIER:
        return (
          <ZapierConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.ZENDESK:
        return (
          <ZendeskConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              if (integrationInfo.label) {
                integrationInfo['label'] =
                  actions[index].integrationInfo['label'];
              }
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.AZURE_DEVOPS:
        return (
          <AzureDevopsConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.LINEAR:
        return (
          <LinearConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      case INTEGRATION.EMAIL:
        return (
          <EmailConfiguration
            integrationID={integrationID}
            index={index}
            onChange={(integrationInfo) => {
              integrationInfo['label'] =
                actions[index].integrationInfo['label'];
              actions[index].integrationInfo = integrationInfo;
              handleGeneralIntegrationInfo(integrationInfo);
              validate();
            }}
          />
        );

      default:
        return <></>;
    }
  };

  const buildIntegrationActionItem = (action, index) => {
    return (
      <div className="integration-action-row">
        <div className="action-item-wrapper">
          <Title className="mb-5" label="When this happens" />
          <div className="action-item">
            <SelectDropDown
              label="Type"
              labelPropertyName="name"
              valuePropertyName="type"
              selectedItem={projectTypes.filter((element) => {
                const actionIndex = action.actionTypes.findIndex(
                  (item) => item === element.type,
                );

                if (actionIndex <= -1) {
                  return false;
                }

                return true;
              })}
              items={projectTypes}
              onChange={(selectedItems: any[]) => {
                const values: any[] = [];
                if (!selectedItems) {
                  action.actionTypes = [];
                  validate();
                  setToggle(!toggle);
                  return;
                }

                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < selectedItems.length; i++) {
                  values.push(selectedItems[i].type);
                }
                action.actionTypes = values;
                validate();
                setToggle(!toggle);
              }}
              isMulti
            />
            <div className="mr-15" />
            <SelectDropDown
              label="Action"
              selectedItem={localActions.find(
                (element) => element.value === action.local,
              )}
              items={localActions}
              onChange={(selectedItem) => {
                action.local = selectedItem.value;
                validate();
                setToggle(!toggle);
              }}
            />
          </div>
        </div>
        <div className="dotted-line" />
        <div className="action-item-wrapper">
          <Title className="mb-5" label="Then do this" />
          <div className="action-item">
            <SelectDropDown
              label="Action"
              selectedItem={
                currentIntegration?.actions
                  ? currentIntegration.actions.find(
                      (element) => element.value === action.integration,
                    )
                  : null
              }
              items={currentIntegration.actions}
              onChange={(selectedItem) => {
                action.integration = selectedItem.value;
                validate();
                setToggle(!toggle);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const buildGeneralIntegrationActionContent = () => {
    if (getIntegrationConfiguarionComponent(integration, 0) == null) {
      return <></>;
    }

    return (
      <div className="options-group" style={{ width: '100%' }}>
        <div className="options-group-header">
          {currentIntegration.title} configuration
        </div>
        {getIntegrationConfiguarionComponent(integration, 0)}
      </div>
    );
  };

  const buildCooseIntegrationActionContent = () => {
    return (
      <PageContainer>
        <PageHeadLine
          title={`${currentIntegration.title} Integration`}
          onActionBack={() => {
            navigate(`/projects/${projectId}/integrations`);
          }}
        />
        <PageContent hasTitle isMediumBoxed>
          <div className="choose-integation-action-container">
            <div className="integration-info-content-header">
              <img
                className="integration-preview-image"
                src={GLEAP_LOGO}
                alt="Gleap logo"
              />
              <img className="arrow-icon" src={ARROW} alt="Integrations" />
              <img
                className="integration-preview-image"
                src={currentIntegration.icon}
                alt="Integration logo"
              />
            </div>
            {buildGeneralIntegrationActionContent()}
            <div className="options-group" style={{ width: '100%' }}>
              <div className="options-group-header">Advanced</div>
              <>
                <TextInput
                  className="mb-30"
                  error=""
                  placeholder="Name your integration"
                  label="Name"
                  initalValue={
                    actions &&
                    Array.isArray(actions) &&
                    actions.length > 0 &&
                    actions[0]?.integrationInfo
                      ? actions[0]?.integrationInfo['label']
                      : ''
                  }
                  onChange={(val) => {
                    actions[0].integrationInfo['label'] = val;
                    handleGeneralIntegrationInfo(actions[0].integrationInfo);
                    validate();
                  }}
                />
                {integration !== INTEGRATION.ZAPIER && (
                  <div className="switch-container">
                    <Switch
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(checked) => {
                        runInAction(() => {
                          if (!checked) {
                            handleGeneralLocalAction([]);
                          } else {
                            handleGeneralLocalAction(
                              projectTypes.map((item) => item.type),
                            );
                          }

                          validate();
                          setToggle(!toggle);
                        });
                      }}
                      checked={actions && actions[0]?.actionTypes.length > 0}
                    />
                    <span>
                      Automatically send feedback to {currentIntegration.title}
                    </span>
                  </div>
                )}
                {actions &&
                  actions.length > 0 &&
                  actions[0]?.actionTypes.length > 0 && (
                    <SelectDropDown
                      labelPropertyName="name"
                      valuePropertyName="type"
                      className="mt-20"
                      label={`Send following types to ${currentIntegration.title} Integration`}
                      selectedItem={projectTypes.filter((element) => {
                        const actionIndex = actions[0]?.actionTypes.findIndex(
                          (item) => item === element.type,
                        );
                        if (actionIndex <= -1) {
                          return false;
                        }
                        return true;
                      })}
                      items={projectTypes}
                      onChange={(selectedItems: any[]) => {
                        runInAction(() => {
                          const values: any[] = [];
                          if (!selectedItems) {
                            handleGeneralLocalAction([]);
                            validate();
                            setToggle(!toggle);
                            return;
                          }
                          // eslint-disable-next-line no-plusplus
                          for (let i = 0; i < selectedItems.length; i++) {
                            values.push(selectedItems[i].type);
                          }
                          handleGeneralLocalAction(values);
                          validate();
                          setToggle(!toggle);
                        });
                      }}
                      isMulti
                    />
                  )}
                {((actions && actions[0]?.actionTypes.length > 0) ||
                  integration === INTEGRATION.ZAPIER) && (
                  <>
                    <div className="switch-container mb-20 mt-40">
                      <Switch
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={() => {
                          runInAction(() => {
                            currentProject!.integrations[integration][
                              integrationID
                            ].autoChangeStatus =
                              !currentProject?.integrations[integration][
                                integrationID
                              ].autoChangeStatus;
                          });
                        }}
                        checked={
                          currentProject?.integrations &&
                          currentProject?.integrations[integration] &&
                          currentProject?.integrations[integration][
                            integrationID
                          ] &&
                          currentProject?.integrations[integration][
                            integrationID
                          ].autoChangeStatus
                            ? true
                            : false
                        }
                      />
                      <span>
                        Change feedback status to done when sent to{' '}
                        {currentIntegration.title}.
                      </span>
                    </div>
                    <div className="switch-container">
                      <Switch
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={() => {
                          runInAction(() => {
                            currentProject!.integrations[integration][
                              integrationID
                            ].sendDuplicates =
                              !currentProject?.integrations[integration][
                                integrationID
                              ].sendDuplicates;
                          });
                        }}
                        checked={
                          currentProject?.integrations &&
                          currentProject?.integrations[integration] &&
                          currentProject?.integrations[integration][
                            integrationID
                          ] &&
                          currentProject?.integrations[integration][
                            integrationID
                          ].sendDuplicates
                            ? true
                            : false
                        }
                      />
                      <span>Send duplicates to this integration as well.</span>
                    </div>
                  </>
                )}
              </>
            </div>

            <div className="integration-buttons mt-20">
              <PrimaryButton
                className="mb-30"
                label="Save integration"
                onClick={saveIntegration}
                isLoading={isSaving}
                disabled={!integrationIsValid || isSaving}
              />
              <LinkButton
                className="danger-button mb-30"
                label="Delete"
                onClick={() => {
                  Swal.fire({
                    text: 'Do you really want to delete this integration?',
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                    denyButtonText: `No`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await currentIntegration.disconnect(
                        projectId,
                        integrationID,
                      );
                      await projectStore?.loadProjectById(projectId, true);
                      toast.success('Sucessfully deleted integration');
                      navigate(`/projects/${projectId}/integrations`, {
                        replace: true,
                      });
                    }
                  });
                }}
              />
            </div>
          </div>
        </PageContent>
      </PageContainer>
    );
  };

  if (isLoading) {
    return <Loading className="loading-item" />;
  }

  return buildCooseIntegrationActionContent();
};

export default inject('projectStore')(observer(ConfigureIntegration));
