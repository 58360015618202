import React from 'react';
import { Md5 } from 'md5-typescript';
import './UserAvatar.scss';
import AvatarImage from 'components/AvatarImage/AvatarImage';
import { ReactComponent as GleapBot } from '../../assets/icons/gleapbot.svg';

interface UserAvatarProps {
  email?: string;
  small?: Boolean;
  big?: Boolean;
  imageUrl?: string;
  editable?: boolean;
  afterImageUpdload?: (imageUrl: string) => void;
  isOnline?: boolean;
}

const UserAvatar = ({
  email = '--',
  small = false,
  big = false,
  imageUrl,
  editable = false,
  afterImageUpdload,
  isOnline = false,
}: UserAvatarProps) => {
  const hash = Md5.init(email);
  const gravatar = `https://www.gravatar.com/avatar/${hash}?d=https://api.gleap.io/identicon/${hash}`;
  const className = `user-avatar editable-gravatar ${small ? 'user-avatar--small' : ''
    } ${big ? 'user-avatar--big' : ''}`;

  if (editable) {
    return (
      <AvatarImage
        image={imageUrl && imageUrl !== '' ? imageUrl : gravatar}
        className={className}
        editable
        afterImageUpload={afterImageUpdload}
      />
    );
  }

  return (
    <div className={className}>
      {email === "bot" ? <GleapBot /> :
        <img
          alt={`${email}'s avatar'`}
          src={imageUrl && imageUrl !== '' ? imageUrl : gravatar}
        />}
      {isOnline && <div className="user-avatar-user-online" />}
    </div>
  );
};

export default UserAvatar;
