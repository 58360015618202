import React from 'react';
import CreatableSelect from 'react-select/creatable';
import './EditableDropdown.scss';

interface EditableDropdownProps {
  options: { label: string; value: string }[];
  onChange: (val: { label: string; value: string }) => void;
  value: any;
}

export const EditableDropdown = ({
  options,
  value,
  onChange,
}: EditableDropdownProps) => {
  const handleChange = (newValue: any, actionMeta: any) => {
    onChange(newValue);
  };

  const getItemFromOptions = () => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return options[i];
      }
    }

    return value;
  };

  return (
    <CreatableSelect
      className="creatableselect"
      value={getItemFromOptions()}
      onChange={handleChange}
      options={options}
    />
  );
};
