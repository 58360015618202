import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { languages as constLanguages } from 'constants/Languages';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { isEqual } from 'lodash';
import './HelpcenterSettings.scss';
import { useNavigate, useParams } from 'react-router-dom';
import ShareableUrl from 'components/ShareableUrl/ShareableUrl';
import InfoBox from 'components/InfoBox/InfoBox';
import 'prismjs/components/prism-clike';
import 'prismjs/themes/prism.css';
import ListTable, {
  CellText,
  CellTextCopy,
} from 'components/ListTable/ListTable';
import LinkButton from 'components/LinkButton/LinkButton';

interface HelpcenterSettingsProps {
  projectStore?: ProjectStore;
}

const HelpcenterSettings = ({ projectStore }: HelpcenterSettingsProps) => {
  const { flowConfig } = projectStore?.editingProject || {};
  const { projectId } = useParams();
  const navigate = useNavigate();
  const helpcenterConfig = flowConfig?.helpcenterConfig;
  const [customDomain, setCustomDomain] = useState(
    projectStore?.currentProject?.customDomainHelpCenter || '',
  );

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const columns = useMemo(
    () => [
      {
        Header: 'Hostname',
        accessor: 'hostname',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '50px',
        Cell: (row) => <CellText text={row.value} />,
      },
      {
        Header: 'Add this value',
        accessor: 'value',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
    ],
    [],
  );

  const _buildHelpcenterLocalizationForm = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Localization</div>
        {helpcenterConfig?.localization.map((item, index) => (
          <div className="helpcenter-localize-item mb-10" key={item.language}>
            <SelectDropDown
              showBorder
              items={constLanguages}
              selectedItem={constLanguages.find(
                (lang) => lang.code === item.language,
              )}
              labelPropertyName="name"
              valuePropertyName="code"
              placeholder="Select language"
              onChange={(value) => {
                runInAction(() => {
                  item.language = value.code;
                });
              }}
            />
            <TextInput
              className="ml-10 mr-10"
              placeholder="Page title"
              error=""
              value={item.title}
              onChange={(val) => {
                runInAction(() => {
                  item.title = val;
                });
              }}
            />
            <TextInput
              placeholder="Header text"
              error=""
              value={item.description}
              onChange={(val) => {
                runInAction(() => {
                  item.description = val;
                });
              }}
            />
            {helpcenterConfig?.localization.length > 1 && (
              <div
                className="delete-item cursor-focus ml-15"
                onClick={() => {
                  runInAction(() => {
                    helpcenterConfig?.localization.splice(index, 1);
                  });
                }}
              >
                <i className="fa-solid fa-trash" />
              </div>
            )}
          </div>
        ))}
        <PrimaryButton
          icon="plus"
          label="Add language"
          className="mr-10 mt-10"
          onClick={() => {
            runInAction(() => {
              helpcenterConfig?.localization.push({
                language: '',
                title: '',
                description: '',
              });
            });
          }}
        />
      </div>
    );
  };

  const renderCustomDomainSettings = () => {
    if (projectStore?.currentProject?.customDomainHelpCenter) {
      return (
        <>
          <InfoBox className="mt-10 mb-20">
            In order to use your custom domain, you need to add the following
            CNAME record to your DNS settings.
          </InfoBox>
          <ListTable
            data={[
              {
                id: 'CNAME',
                label: 'CNAME',
                hostname: projectStore?.currentProject?.customDomainHelpCenter,
                type: 'CNAME',
                value: 'help.gleap.io',
              },
            ]}
            columns={columns}
          />
          <LinkButton
            className="mt-20"
            label="Remove custom domain"
            onClick={() => {
              projectStore!.deleteCustomDomainHelpCenterSettings();
              setCustomDomain('');
            }}
          />
        </>
      );
    }

    return (
      <>
        <div className="fullwidth mt-30">
          <InfoBox className="mt-10 mb-10">
            Use your own custom domain for your Gleap help center.
          </InfoBox>
          <div className="custom-domain">
            <span>https://</span>
            <TextInput
              placeholder="help.yourdomain.com"
              type="text"
              className="mb-10"
              error=""
              initalValue={customDomain}
              onChange={(text) => {
                setCustomDomain(text);
              }}
            />
            <PrimaryButton
              label="Add domain"
              icon="arrow-right"
              iconSideRight={true}
              className="ml-10"
              disabled={customDomain.length === 0}
              onClick={() => {
                projectStore?.updateCustomDomainHelpCenterSettings(
                  customDomain,
                );
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const _buildHelpcenterCustomDomainForm = () => {
    const shareURL = projectStore?.currentProject?.customDomainHelpCenter
      ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
      : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;

    return (
      <div className="options-group">
        <div className="options-group-header">Domain settings</div>
        <div className="share-url share-url-fullwidth">
          <ShareableUrl url={shareURL} />
        </div>
        {renderCustomDomainSettings()}
      </div>
    );
  };

  const _buildHelpcenterCacheSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Cache</div>
        <div>
          <div className="text mb-20">
            Gleap automatically caches your help center to ensure great
            performance. Changes invalidate the cache automatically. If you want
            to manually clear the cache, press the button below.
          </div>
          <PrimaryButton
            label="Clear cache"
            onClick={() => {
              projectStore?.clearHelpCenterCache();
            }}
          />
        </div>
      </div>
    );
  };

  const _buildHelpcenterAppWidgetSettings = () => {
    return (
      <div className="options-group">
        <div className="options-group-header">Widget app</div>
        <div>
          <div className="text mb-20">
            To answer questions even faster, you can include the help center
            directly within the Gleap widget.
          </div>
          <PrimaryButton
            label="Show widget app settings"
            onClick={() => {
              navigate(`/projects/${projectId}/widget/apps`);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Settings">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <SizedContainer size={ContainerSizes.XXL}>
          {_buildHelpcenterLocalizationForm()}
          {_buildHelpcenterCustomDomainForm()}
          {_buildHelpcenterCacheSettings()}
          {_buildHelpcenterAppWidgetSettings()}
        </SizedContainer>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(HelpcenterSettings));
