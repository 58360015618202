import * as React from 'react';
import './EventTimeline.scss';
import { SessionStore } from 'stores/private/SessionStore';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import RecentEvents from 'components/RecentEvents/RecentEvents';

interface EventTimelineProps {
  sessionStore?: SessionStore;
  projectStore?: ProjectStore;
}

const EventTimeline = ({ sessionStore, projectStore }: EventTimelineProps) => {
  // @ts-ignore
  const { contactId } = useParams();

  if (!contactId) {
    return null;
  }

  return (
    <div className="event-timeline card">
      <div className="event-timeline-title">Recent events</div>
      <div className="event-timeline-body">
        <RecentEvents contactId={contactId ?? ""} />
      </div>
    </div>
  );
};

export default inject('sessionStore', 'projectStore')(observer(EventTimeline));
