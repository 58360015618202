import { ReactComponent as HelpCenterIcon } from 'assets/icons/circle-question-solid.svg';
import { ReactComponent as InboxIcon } from 'assets/icons/inbox-regular_new.svg';
import { ReactComponent as OutboundIcon } from 'assets/icons/paper-plane-regular.svg';
import { ReactComponent as ContactsIcon } from 'assets/icons/user-group-regular_new.svg';
import { ReactComponent as WidgetIcon } from 'assets/icons/widget-config.svg';
import Filter from 'components/Filter/Filter';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { getFormFieldValue, truncate } from 'helper/FormDataHelper';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ticketStatusToColor,
  useClickedOutside,
  useEscape
} from 'services/Helper';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './GlobalSearchModal.scss';

const getCommentContent = (comment, highlights) => {
  if (highlights && highlights.length > 0 && highlights[0].snippet) {
    return <span dangerouslySetInnerHTML={{
      __html: highlights[0].snippet
    }} />;
  }

  var prefix = '';
  if (comment?.type === 'NOTE' || comment?.type === 'TEXT') {
    prefix = 'Team: ';
  }

  if (comment?.comment) {
    const text = prefix + comment?.comment;
    return truncate(text, 50);
  }

  return 'No description';
};

interface GlobalSearchModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const GlobalSearchModal = ({
  projectStore,
  modalStore,
}: GlobalSearchModalProps) => {
  const navigate = useNavigate();

  const [resultFilter, setResultFilter] = useState('ALL');
  const [searchText, setSearchText] = useState('');
  const globalSearchData = projectStore?.globalSearchData ?? {};
  const globalSearchRef = useRef(null);

  const debounceSearch = useCallback(
    debounce((nextValue) => projectStore?.globalSearch(nextValue), 750),
    [],
  );

  useClickedOutside(globalSearchRef, () => {
    modalStore?.closeModal();
  });

  useEscape(() => {
    modalStore?.closeModal();
  });

  const _buildSearchGroupedResults = () => {
    const searchDataKeys = Object.keys(globalSearchData);

    var hasItems = false;
    searchDataKeys.forEach((key) => {
      if (
        globalSearchData[key] &&
        globalSearchData[key].data &&
        globalSearchData[key].data.length > 0
      ) {
        hasItems = true;
      }
    });

    if (!hasItems) {
      return null;
    }

    const sortKeys = searchDataKeys.sort((a, b) => globalSearchData[a].sort - globalSearchData[b].sort);

    var filterOptions: any[] = [{ name: 'All', value: 'ALL' }];
    for (var i = 0; i < sortKeys.length; i++) {
      if (globalSearchData[sortKeys[i]].data?.length > 0) {
        filterOptions.push({
          name: globalSearchData[sortKeys[i]].label,
          value: sortKeys[i],
        });
      }
    }

    return (
      <>
        <div>
          <Filter
            onValueChange={(value) => {
              setResultFilter(value);
            }}
            value={resultFilter}
            options={filterOptions}
          />
        </div>
        <div className={`grouped-search-result-list-wrapper`}>
          {
            sortKeys.filter((key) => {
              if (resultFilter === 'ALL') return true;
              return key === resultFilter;
            }).map((key) => {
              return _buildSearchResultList(globalSearchData[key], key);
            })}
        </div>
      </>
    );
  };

  const _buildSearchResultList = (dataItem, key) => {
    if (!dataItem.data || dataItem.data.length === 0) return <></>;

    return (
      <div
        className="grouped-search-result-list"
        id={`list-group-${dataItem.type}`}
      >
        <div className="text mb-5">{dataItem.label}</div>
        {(resultFilter === "ALL" ? dataItem.data.slice(0, 5) : dataItem.data).map((item) => {
          return _buildSearchResultListItem(item, dataItem.type);
        })}
        {resultFilter === "ALL" && dataItem.data.length > 5 && (
          <div className='search-results-showmore' onClick={() => {
            setResultFilter(key);
          }}>Show more</div>
        )}
      </div>
    );
  };

  const _buildSearchResultListItem = (item, type) => {
    return (
      <div
        className="grouped-search-result-list-item"
        key={`list-item-${item.id}`}
        onClick={() => {
          handleOnClickForType(type, item);
        }}
      >
        <div className="type-icon">{getIconForType(type)}</div>
        <div className="item-label">{getLabelForType(type, item.document ? item.document : item, item.highlights ? item.highlights : [])}</div>
      </div>
    );
  };

  const getIconForType = (type) => {
    switch (type) {
      case 'OUTBOUND':
        return <OutboundIcon />;
      case 'TICKET':
        return <InboxIcon />;
      case 'SESSION':
        return <ContactsIcon />;
      case 'HELPCENTER_ARTICLE':
        return <HelpCenterIcon />;
      case 'COMMENT':
        return <WidgetIcon />;
      default:
        return <></>;
    }
  };

  const getLabelForType = (type, data, highlights = []) => {
    switch (type) {
      case 'OUTBOUND':
        return data.name ?? 'No title set';
      case 'TICKET':
        return (
          <div className="item-label-bug">
            <div className="item-label-bug-title">
              #{data.bugId} - {getFormFieldValue(data, 'title', 'formText')}
            </div>
            <div
              className="item-label-bug-status"
              style={{
                backgroundColor: `${ticketStatusToColor(data.status)}22`,
                color: ticketStatusToColor(data.status),
              }}
            >
              {data.status}
            </div>
            {data.archived && (
              <div className="item-label-bug-archieved">ARCHIVED</div>
            )}
          </div>
        );
      case 'SESSION':
        return <div className='item-label-session'>
          <span className='item-label-session-name'>{data.name}</span>
          <span className='item-label-session-info'><i className={`fa-solid ${data.userId ? "fa-shield-check" : "fa-shield"}`}></i> {`${data.email ?? 'No email set'}${data.userId ? ` · #${data.userId}` : ''}`}</span>
        </div>;
      case 'HELPCENTER_ARTICLE':
        return data.title ?? 'No title set';
      case 'COMMENT':
        return getCommentContent(data, highlights);
      default:
        return '';
    }
  };

  const handleOnClickForType = (type, searchData) => {
    const data = searchData.document ? searchData.document : searchData;

    switch (type) {
      case 'OUTBOUND':
        navigate(
          `/projects/${projectStore!.currentProject!.id}/outbound/${data.id}`,
        );
        modalStore?.closeModal();
        break;

      case 'TICKET':
        projectStore?.openFeedbackItem({
          shareToken: data.shareToken,
        });
        break;

      case 'SESSION':
        navigate(
          `/projects/${projectStore!.currentProject!.id}/sessions/${data.id}`,
        );
        modalStore?.closeModal();
        break;

      case 'HELPCENTER_ARTICLE':
        navigate(
          `/projects/${projectStore!.currentProject!.id
          }/helpcenter/collections/${data.helpcenterCollection.id}/articles/${data.id
          }`,
        );
        modalStore?.closeModal();
        break;

      case 'COMMENT':
        projectStore?.openFeedbackItem({
          shareToken: data.bug?.shareToken,
        });
        break;

      default:
        break;
    }
  };

  var hasItems = false;
  var isLoading = false;
  Object.keys(globalSearchData).forEach((key) => {
    if (
      globalSearchData[key] &&
      globalSearchData[key].data &&
      globalSearchData[key].data.length > 0
    ) {
      hasItems = true;
    }
    if (globalSearchData[key] && globalSearchData[key].isLoading === true) {
      isLoading = true;
    }
  });

  return (
    <div className="global-search-modal-background-container">
      <div className="global-search-modal-background-container-blur">
        <div className="global-search-container" ref={globalSearchRef}>
          <div className="global-search-container-input-cont">
            <input
              className="global-search-container-input"
              type="text"
              autoFocus
              placeholder="Search"
              onChange={(text) => {
                setResultFilter("ALL");
                setSearchText(text.target.value);
                debounceSearch(text.target.value);
              }}
            />
            {isLoading && searchText.length > 0 && (
              <div className="global-search-container-input-loading">
                <LoadingAnimationSmall />
              </div>
            )}
          </div>
          {!hasItems && !isLoading && searchText.length > 0 && (
            <div className="global-search-container-no-results">
              No results found for your search query 😭
            </div>
          )}
          {_buildSearchGroupedResults()}
        </div>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(GlobalSearchModal));
