import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import ARROW from 'assets/icons/np_arrow_3542562_000000.svg';
import GLEAP_LOGO from 'assets/icons/Gleap.svg';
import AWORK_LOGO from 'assets/icons/awork-logo.svg';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getBoards } from 'services/integrations/AworkHttpService';
import { AworkIntegrationStore } from 'stores/integrations/AworkIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';

interface AworkConnectConfigurationProps {
  aworkIntegrationStore?: AworkIntegrationStore;
  projectStore?: ProjectStore;
}

const AworkConnectConfiguration = ({
  aworkIntegrationStore,
  projectStore,
}: AworkConnectConfigurationProps) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [accessToken, setAccessToken] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, []);

  useEffect(() => {
    if (isValid) {
      navigate(
        `/projects/${projectId}/integrations/awork/${aworkIntegrationStore?.integrationID}`,
        { replace: true },
      );
    }
  }, [aworkIntegrationStore?.integrationID]);

  const connectAwork = async () => {
    try {
      await getBoards(accessToken);
    } catch (err: any) {
      setIsValid(false);
      toast.error('Wrong access token!');
      return;
    }

    setIsValid(true);
    aworkIntegrationStore!.setAuth(accessToken);
  };

  return (
    <PageContainer>
      <PageHeadLine
        title="awork Integration"
        onActionBack={() => {
          navigate(`/projects/${projectId}/integrations`);
        }}
      />
      <PageContent hasTitle isMediumBoxed>
        <div className="choose-integation-action-container">
          <div className="integration-info-content-header">
            <img
              className="integration-preview-image"
              src={GLEAP_LOGO}
              alt="Gleap logo"
            />
            <img className="arrow-icon" src={ARROW} alt="Integrations" />
            <img
              className="integration-preview-image"
              src={AWORK_LOGO}
              alt="awork logo"
            />
          </div>
          <TextInput
            className="mb-30"
            error=""
            placeholder=""
            label="Access token"
            initalValue={accessToken}
            onChange={(val) => {
              setAccessToken(val);
            }}
          />
          <a
            className="mb-15"
            rel="noreferrer"
            target="_blank"
            href="https://support.awork.io/en/articles/5415664-client-applications-and-api-keys#h_9a69b4ff90"
          >
            Where do I find the awork access token?
          </a>
          <SizedContainer size={ContainerSizes.M}>
            <PrimaryButton label="Connect" onClick={connectAwork} />
          </SizedContainer>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'aworkIntegrationStore',
  'projectStore',
)(observer(AworkConnectConfiguration));
