import { ampli } from 'ampli';
import { Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './SuggestSubscription.scss';

interface SuggestSubscriptionProps {
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
  icon?: any;
  title: string;
  description: string;
  plan?: Plantypes;
}

const SuggestSubscription = ({
  modalStore,
  organisationStore,
  icon,
  title,
  description,
  plan = Plantypes.GROWTH,
}: SuggestSubscriptionProps) => {
  return (
    <SizedContainer size={ContainerSizes.L}>
      <div className="suggest-subscription-container">
        {icon && <img className="icon" src={icon} />}
        <Title className="mt-10" label={title} />
        <div className="description mb-20 mt-10">{description}</div>
        <div className="action-row">
          <PrimaryButton
            label={organisationStore?.currentOrganisation?.trialUsed ? "Upgrade now ✨" : "Start 14-day free trial"}
            onClick={() => {
              ampli.suggestedUpsellClicked({
                planId: plan,
                name: title,
              });
              modalStore!.closeModal();
              modalStore!.closeModal();

              setTimeout(() => {
                modalStore!.openModal(MODALTYPE.UPSELL_MODAL, {
                  plan,
                });
              }, 100);
            }}
          />
        </div>
      </div>
    </SizedContainer>
  );
};

export default inject('modalStore', 'organisationStore')(observer(SuggestSubscription));
