import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';

interface AzureConfigurationProps {
  integrationID: string;
  index: number;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const AzureConfiguration = ({
  integrationID,
  index,
  projectStore,
  onChange,
}: AzureConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [notifyOnly, setNotifyOnly] = useState(false);

  const [webhookUrl, setWebhookUrl] = useState('');

  useEffect(() => {
    if (project) {
      initialize();
    }
  }, [project]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [webhookUrl, notifyOnly]);

  const initialize = async () => {
    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.AZURE,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.azure[integrationID]?.actions[index]
        ?.integrationInfo;

    setNotifyOnly(integrationInfo.notifyOnly ?? false);

    setCurrentIntegrationInfo(integrationInfo);

    if (integrationInfo.webhookUrl) {
      setWebhookUrl(integrationInfo.webhookUrl);
    }
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      webhookUrl,
      notifyOnly,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <TextInput
        error=""
        className="mb-15"
        placeholder="https://example.com/webhooks"
        label="Webhook url"
        initalValue={currentIntegrationInfo?.webhookUrl}
        onChange={(val) => {
          setWebhookUrl(val);
        }}
      />
      <div className="switch-container mt-10 mb--10">
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            setNotifyOnly(checked);
          }}
          checked={notifyOnly ? true : false}
        />
        <span>Send fewer details only (Email, bug type & form data).</span>
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(AzureConfiguration));
