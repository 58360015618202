import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { StripeStore } from 'stores/private/StripeStore';
import './StripeCustomerDetails.scss';

interface StripeCustomerDetailsProps {
  organisationStore?: OrganisationStore;
  stripeStore?: StripeStore;
}

const StripeCustomerDetails = ({ organisationStore, stripeStore }: StripeCustomerDetailsProps) => {
  useEffect(() => {
    stripeStore!.getCustomerDetails();
  }, []);

  if (!organisationStore?.currentOrganisation || !stripeStore?.customerDetails) {
    return null;
  }

  return (
    <div className='customer-details-container'>
      <div className='text bold'>{stripeStore?.customerDetails.name}</div>
      {stripeStore?.customerDetails.address && (
        <>
          <div className='text'>{stripeStore?.customerDetails.address.line1}</div>
          <div className='text'>{stripeStore?.customerDetails.address.postal_code} {stripeStore?.customerDetails.address.city}</div>
          <div className='text'>{stripeStore?.customerDetails.address.country}</div>
        </>
      )}
      <div className='text mt-10'>{stripeStore?.customerDetails.email}</div>
    </div>
  );
};

export default inject('organisationStore', 'stripeStore')(observer(StripeCustomerDetails));
