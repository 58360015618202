import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import './ListTableVirtualized.scss';
import { useEffect, useRef, useState } from "react";

interface ListTableVirtualizedProps {
  data: any[];
  height?: string;
  renderCell: (row: any, index: Number) => JSX.Element;
}

function ListTableVirtualized({ data, height, renderCell }: ListTableVirtualizedProps) {
  const listRef = useRef({} as any);
  const rowHeights = useRef({} as any);
  const [scrolled, setScrolled] = useState(false);

  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 82;
  }

  function Row({ index, style }) {
    const rowRef = useRef({} as any);

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
      // eslint-disable-next-line
    }, [rowRef]);

    return (
      <div style={style}>
        <div ref={rowRef}>

          <span>{renderCell(data[index], index)}</span>
        </div>
      </div>
    );
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // Render the UI for your table
  return (
    <div className={`virtual-list-table ${scrolled && 'virtual-list-table--scrolled'}`}>
      <AutoSizer style={{
        width: '100%',
        height: height ? height : '50vh',
      }}>
        {({ height, width }) => (
          <List
            className="List"
            height={height}
            itemCount={data.length}
            itemSize={getRowHeight}
            ref={listRef}
            width={width}
            onScroll={({
              scrollDirection,
              scrollOffset,
              scrollUpdateWasRequested
            }) => {
              if (scrollOffset > 0) {
                setScrolled(true);
              } else {
                setScrolled(false);
              }
            }}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  );
}

export default ListTableVirtualized;
