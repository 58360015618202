/* eslint-disable jsx-a11y/accessible-emoji */
import { useEffect } from 'react';
import './GeneralWidgetSettings.scss';
import Switch from 'react-switch';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router';
import Loading from 'components/Loading/Loading';
import { TwitterPicker } from 'react-color';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import LinkButton from 'components/LinkButton/LinkButton';
import TextInput from 'components/TextInput/TextInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { Feature } from 'models/Enums';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import UpgradeButton from 'components/UpgradeButton/UpgradeButton';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.css';
import InfoBox from 'components/InfoBox/InfoBox';
import { ampli } from 'ampli';
import { isEqual } from 'lodash';

interface GeneralWidgetSettingsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function GeneralWidgetSettings({
  projectStore,
  modalStore,
}: GeneralWidgetSettingsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const { flowConfig } = projectStore?.editingProject || {};

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  if (!projectStore?.currentProject) {
    return <Loading />;
  }

  if (!flowConfig || Object.keys(flowConfig).length === 0) {
    return <Loading />;
  }

  const renderGeneralSettings = () => {
    return (
      <>
        {!projectStore?.editingProject?.sdkInstalled && (
          <div className="mb-30 gleap-widget-not-installed-card">
            <NotInstalledCard className="not-installed--full-width" />
          </div>
        )}
        <div className="options-group">
          <div className="options-group-header">Home</div>
          <div className="switch-container mt-20">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.hideTeam = !flowConfig.hideTeam;
              }}
              checked={!flowConfig.hideTeam}
            />
            <span>Introduce team (min. 3 team members required)</span>
          </div>
          <div className="fullwidth mt-30">
            <TextInput
              language="English"
              name="Welcome message"
              placeholder=""
              type="text"
              error=""
              value={flowConfig.welcomeText}
              label="Welcome message"
              onChange={(text) => {
                flowConfig.welcomeText = text;
              }}
            />
          </div>
          <div className="switch-container mt-20">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.hideWavingHandAfterName =
                  !flowConfig.hideWavingHandAfterName;

                ampli.widgetCustomized({
                  projectId,
                  name: 'welcomeIcon',
                });
              }}
              checked={flowConfig.hideWavingHandAfterName}
            />
            <span>Hide 👋 after greeting</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.hideUsersName = !flowConfig.hideUsersName;

                ampli.widgetCustomized({
                  projectId,
                  name: 'hideUsersName',
                });
              }}
              checked={flowConfig.hideUsersName}
            />
            <span>Hide name</span>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Branding</div>
          <div className="switch-container">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                if (projectStore!.isFeatureInPlan(Feature.REMOVE_BRANDING)) {
                  flowConfig.hideBranding = !flowConfig.hideBranding;

                  ampli.widgetCustomized({
                    projectId,
                    name: 'hideBranding',
                  });
                } else {
                  modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
                    projectId,
                    type: 'branding',
                  });
                }
              }}
              checked={flowConfig.hideBranding}
            />
            <span>Remove the Gleap branding</span>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Look & feel</div>
          <div className="widget-color-picker">
            <div className="input-label mb-10">Header background color</div>
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={flowConfig.headerColor}
                onChangeComplete={(color) => {
                  flowConfig.headerColor = color.hex;

                  ampli.widgetCustomized({
                    projectId,
                    name: 'headerColor',
                  });
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: flowConfig.headerColor,
                }}
              />
            </div>
          </div>
          <div className="switch-container mt-20">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.disableBGGradient = flowConfig.disableBGGradient
                  ? false
                  : true;
              }}
              checked={!(flowConfig.disableBGGradient ?? false)}
            />
            <span>Background gradient</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.disableBGFade = flowConfig.disableBGFade
                  ? false
                  : true;
              }}
              checked={!(flowConfig.disableBGFade ?? false)}
            />
            <span>Fade background to white</span>
          </div>
          <div className="widget-color-picker mt-20">
            <div className="input-label mb-10">UI color</div>
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={flowConfig.color}
                onChangeComplete={(color) => {
                  flowConfig.color = color.hex;

                  ampli.widgetCustomized({
                    projectId,
                    name: 'color',
                  });
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: flowConfig.color,
                }}
              />
            </div>
          </div>
          <div className="widget-color-picker mt-20">
            <div className="input-label mb-10">Background color</div>
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={flowConfig.backgroundColor}
                onChangeComplete={(color) => {
                  flowConfig.backgroundColor = color.hex;

                  ampli.widgetCustomized({
                    projectId,
                    name: 'backgroundColor',
                  });
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: flowConfig.backgroundColor,
                }}
              />
            </div>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Other</div>
          <div className="widget-logo-upload-main mt-20">
            <div className="half-form custom-logo">
              <div className="input-label mb-10">Header logo</div>
              <ImageUpload
                image={flowConfig.logo}
                label="Logo"
                editable
                customBgColor={flowConfig.headerColor}
                uploadPath="feedback_widgets"
                afterImageUpload={(imageURL) => {
                  flowConfig.logo = imageURL;

                  ampli.widgetCustomized({
                    projectId,
                    name: 'customLogo',
                  });
                }}
              />
            </div>
            <div className="mb-20">
              <div className="input-label mt-20">Corner radius</div>
              <div className="corner-radius">
                <Slider
                  marks={{
                    0: 'Sharp',
                    30: 'Rounded',
                  }}
                  min={0}
                  max={30}
                  value={flowConfig.borderRadius ?? 20}
                  onChange={(value) => {
                    flowConfig.borderRadius = value;
                  }}
                />
              </div>
            </div>
            <div className="mb-20">
              <div className="input-label mt-20">
                Thank you message duration
              </div>
              <div className="corner-radius">
                <Slider
                  marks={{
                    1000: '1s',
                    2000: '2s',
                    3000: '3s',
                    4000: '4s',
                    5000: '5s',
                    6000: '6s',
                    7000: '7s',
                    8000: '8s',
                  }}
                  min={1000}
                  max={8000}
                  value={flowConfig.thankYouDuration ?? 3000}
                  onChange={(value) => {
                    flowConfig.thankYouDuration = value;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Bot</div>
          <div className="fullwidth">
            <div className="widget-avatar-upload">
              <ImageUpload
                customBgColor={flowConfig.operatorAvatarImage}
                image={flowConfig.operatorAvatarImage}
                label="Avatar"
                editable
                uploadPath="feedback_widgets"
                afterImageUpload={(imageURL) => {
                  flowConfig.operatorAvatarImage = imageURL;

                  ampli.widgetCustomized({
                    projectId,
                    name: 'operatorAvatarImage',
                  });
                }}
              />
            </div>
            <InfoBox className="mt-20">
              <>
                The bot avatar image will be used as default avatar image
                for all avatar images not directly related to a user.
              </>
            </InfoBox>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Custom CSS</div>
          <div className="fullwidth">
            <Editor
              className="code-editor"
              value={
                flowConfig.customCSS && flowConfig.customCSS.length > 0
                  ? flowConfig.customCSS
                  : '/**\nCUSTOM CSS WILL BE APPLIED\nTO THE LIVE WIDGET ONLY\n\nGET STARTED BELOW:**/'
              }
              onValueChange={(code) => {
                flowConfig.customCSS = code;
              }}
              highlight={(code) => highlight(code, languages.css)}
              padding={10}
            />
          </div>
          <InfoBox className="mt-20">
            <>
              The custom CSS will be applied to your feedback widget. It will
              not be applied to the feedback button or any other elements
              outside the core feedback widget.
            </>
          </InfoBox>
        </div>
      </>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Feedback widget">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject();
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent className="page-content" hasTitle>
        <div className="widget-general-settings">
          <div className="widget-general-settings-content">
            {renderGeneralSettings()}
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'modalStore',
)(observer(GeneralWidgetSettings));
