import './FinishProjectSetup.scss';
import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { useNavigate } from 'react-router';
import Lottie from "lottie-react";
import doneAnimation from "assets/animations/33886-check-okey-done.json";
import { useEffect, useRef } from 'react';

interface FinishProjectSetupProps {
  projectStore?: ProjectStore;
  playAnimation?: Boolean;
  redirectAfter?: string;
}

const FinishProjectSetup = ({
  projectStore,
  playAnimation = false,
  redirectAfter = 'widget',
}: FinishProjectSetupProps) => {
  const navigate = useNavigate();
  const lottieRef = useRef(null as any);

  useEffect(() => {
    if (lottieRef.current && playAnimation) {
      lottieRef.current.play();
    }
  }, [playAnimation]);

  return (
    <div className="finish-project">
      <SizedContainer
        size={ContainerSizes.XML}
        className="centered">
        <Lottie style={{
          width: '110px',
        }}
          lottieRef={lottieRef}
          autoPlay={false}
          loop={false}
          animationData={doneAnimation} />
        <HeadLine
          title="Project created"
          subtitle="Now it's time to integrate the Gleap SDK. It usually takes under 60 seconds to get started."
          className="mb-20"
        />
        <PrimaryButton
          icon="arrow-right"
          iconSideRight
          label="Show me how"
          className="btn"
          onClick={async () => {
            navigate(
              `/projects/${projectStore?.currentProject?.id}/${redirectAfter}`,
            );
          }}
        />
      </SizedContainer>
    </div>
  );
};

export default inject('projectStore')(FinishProjectSetup);
