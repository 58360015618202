import { ampli } from 'ampli';
import { ReactComponent as SaveIcon } from 'assets/icons/np_save_2209758_4C5AF6.svg';
import Comment, { canDeleteComment } from 'components/Comment/Comment';
import InboxBugStatusSelection from 'components/InboxBugStatusSelection/InboxBugStatusSelection';
import InboxSnoozeButton from 'components/InboxSnoozeButton/InboxSnoozeButton';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SendInputEditor from 'components/SendInputEditor/SendInputEditor';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import UnreadItemButton from 'components/UnreadItemButton/UnreadItemButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { getDataDescription } from 'helper/FormDataHelper';
import { debounce } from 'lodash';
import { inject, observer } from 'mobx-react';
import { CommentTypes } from 'models/Comment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { uploadFileToServer } from 'services/FileUpload';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import BugScreenContent from './BugScreenContent';
import './Comments.scss';
import './Details.scss';
import FeatureRequestFormData from './FeatureRequestFormData';
import FormData from './FormData';

interface CommentsProps {
  shared?: boolean;
  showHistory?: boolean;
  bugStore?: BugStore;
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
  isInbox?: boolean;
}

export const getMainCommentContent = (bug) => {
  if (bug.type === 'FEATURE_REQUEST') {
    const reporter = getReporterInfo(bug);
    return `${reporter.name} submited this feature request.`;
  }

  return getDataDescription({ data: bug });
};

export const hasSameCreator = (comment, otherComment) => {
  if (
    comment.session &&
    otherComment.session &&
    comment.session.id &&
    otherComment.session.id &&
    comment.session.id === otherComment.session.id
  ) {
    return true;
  }
  if (
    comment.user &&
    otherComment.user &&
    comment.user.email &&
    otherComment.user.email &&
    comment.user.email === otherComment.user.email
  ) {
    return true;
  }
  return false;
};

export const getReporterInfo = (bug) => {
  if (bug && bug.session) {
    return {
      name: bug.session.name,
      email: bug.session.email,
      isOnline: bug.session?.lastActivity
        ? (Date.now() - Date.parse(bug.session?.lastActivity)) / 60000 < 2
        : false,
    };
  }

  return {
    name: 'Not set',
    email: '--',
    isOnline: false,
  };
};

const Comments = ({
  shared = false,
  showHistory = false,
  bugStore,
  usersStore,
  projectStore,
  organisationStore,
  modalStore,
  isInbox,
}: CommentsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newTicketTitle, setNewTicketTitle] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isNote, setIsNote] = useState(false);
  const textareaRef = useRef(undefined as any);
  const [userSession, setUserSession] = useState(undefined as any);
  const [textfieldHeight, setTextfieldHeight] = useState(80);
  const [laneKeys, setLaneKeys] = useState([] as any[]);
  const attachmentsArrayRef = useRef([] as File[]);
  const [attachments, setAttachments] = useState([] as File[]);
  attachmentsArrayRef.current = attachments;
  const [feedbackTypes, setFeedbackTypes] = useState([] as any[]);
  const [notSpamLoading, setNotSpamLoading] = useState(false);
  const commentsRef = useRef(null as any);
  const bug = bugStore!.currentBug;
  const comments = bugStore!.currentComments;
  const loadingComments = bugStore!.loadingComments;
  const attachmentsRef = useRef();
  const textComposerRef = useRef();
  const isFeatureRequest = bug?.type === 'FEATURE_REQUEST';

  const sessionData = new URLSearchParams(location.search);
  const gleapId = sessionData.get('u');
  const gleapHash = sessionData.get('h');

  const updateEditorContent = (content) => {
    if (textComposerRef.current) {
      (textComposerRef.current as any).updateContent(content);
    }
  };

  const userTypingPing = async (typing: boolean) => {
    if (!bug) {
      return;
    }

    if (!usersStore?.currentUser?.id) {
      /*bugStore!.addSharedCommentWithSession(
        bug.shareToken,
        userSession.gleapId,
        userSession.gleapHash,
      );*/
    } else {
      bugStore!.userIsTypingInTicket(bug.id, typing);
    }
  };

  const debouncedUserTypingPing = useCallback(
    debounce(
      (json: any) => {
        var isTyping = false;

        if (json && json.content && json.content.length > 0) {
          if (!(json.content.length === 1 && !json.content[0].content)) {
            isTyping = true;
          }
        }

        return userTypingPing(isTyping);
      },
      800,
      {
        leading: true,
        trailing: false,
      },
    ),
    [],
  );

  useEffect(() => {
    const currentFeedbackType = projectStore!.findFeedbackTypeForType(
      bug?.type,
    );

    if (
      currentFeedbackType &&
      currentFeedbackType.options &&
      currentFeedbackType.options.possibleLanes
    ) {
      setLaneKeys(currentFeedbackType.options.possibleLanes);
    }

    if (projectStore?.currentProject?.projectTypes) {
      setFeedbackTypes(projectStore?.currentProject?.projectTypes);
    }
  }, [bugStore?.currentBug, projectStore?.currentProject]);

  const scrollCommentsViewToBottom = () => {
    setTimeout(() => {
      if (
        comments &&
        comments.length > 0 &&
        commentsRef &&
        commentsRef.current
      ) {
        if ((commentsRef.current.scrollTop ?? 0) === 0) {
          commentsRef.current!.scroll({
            top: commentsRef.current!.scrollHeight * 0.75,
            left: 0,
            behavior: 'auto',
          });
        }
        commentsRef.current!.scroll({
          top: commentsRef.current!.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    scrollCommentsViewToBottom();
  }, [textfieldHeight]);

  useEffect(() => {
    ReactTooltip.rebuild();
    scrollCommentsViewToBottom();
  }, [comments?.length]);

  const updateTextFieldHeight = () => {
    if (!textareaRef || !textareaRef.current) {
      return;
    }

    textareaRef.current.style.height = 'inherit';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    let height = textareaRef.current.scrollHeight;
    if (textareaRef.current.scrollHeight > 250) {
      height = 250;
    }
    if (textareaRef.current.scrollHeight < 80) {
      height = 80;
    }
    setTextfieldHeight(height);
  };

  useEffect(() => {
    if (gleapId && gleapHash) {
      setUserSession({
        gleapId,
        gleapHash,
      });
    }
  }, [gleapId, gleapHash]);

  useEffect(() => {
    if (bug) {
      // Get last decision.
      try {
        const isNote = localStorage.getItem(`isnote_n${bug.id}`);
        if (isNote && isNote === 'true') {
          setIsNote(true);
        } else {
          setIsNote(false);
        }
      } catch (exp) { }
    }
  }, [bug]);

  const setNoteDraft = (commentValue: any) => {
    try {
      // Update in local storage.
      const stringifiedJson = JSON.stringify(commentValue);
      localStorage.setItem(`note_draft_n${bug?.id}`, stringifiedJson);
    } catch (exp) { }
  };

  const sendComment = async (json: any) => {
    if (!bug) {
      return;
    }

    const comment = json;
    if (!comment || comment.length === 0) {
      return;
    }

    setIsSending(true);

    if (!usersStore?.currentUser?.id) {
      bugStore!.addSharedCommentWithSession(
        bug.shareToken,
        userSession.gleapId,
        userSession.gleapHash,
        comment,
      );
    } else {
      // Upload attachments.
      var uploadedAttachments = [] as any[];
      if (attachmentsArrayRef && attachmentsArrayRef.current) {
        for (let i = 0; i < attachmentsArrayRef.current.length; i++) {
          const file = attachmentsArrayRef.current[i];
          const uploadedAttachment = await uploadFileToServer(
            file,
            `${bug.id}/attachments`,
          );
          if (uploadedAttachment) {
            uploadedAttachments.push({
              url: uploadedAttachment,
              name: file.name,
              type: file.type,
            });
          }
        }
      }

      bugStore!.addCommentToBug(bug.id, comment, isNote, uploadedAttachments);
    }

    ampli.sendMessage({
      messageType: isNote ? 'note' : 'message',
    });

    if (attachmentsRef && attachmentsRef.current) {
      (attachmentsRef.current as any).clearFiles();
    }

    updateEditorContent({});
    setNoteDraft('');
    if (textareaRef && textareaRef.current) {
      setTextfieldHeight(80);
      textareaRef.current.style.height = `80px`;
    }

    setAttachments([]);
    userTypingPing(false);
    setIsSending(false);

    setTimeout(() => {
      updateEditorContent({});
      setNoteDraft('');
    }, 250);
  };

  const renderCommentComposer = () => {
    const getTextPlaceholder = () => {
      if (userSession) {
        return 'Reply directly to our team...';
      }

      if (isNote) {
        return "Type @ to mention a teammate and they'll be notified.";
      }

      if (bug?.type === 'FEATURE_REQUEST') {
        return `Send a message to all subscribers of this feature request...`;
      }

      if (bug?.session?.email) {
        return 'Reply to the user...';
      }

      return 'Reply to the user...';
    };

    if (shared && usersStore?.currentUser?.id) {
      return (
        <div className="not-logged-in">
          <span>Hi there 👋. Please click the button below to reply.</span>
          <LinkButton
            label="Reply to the user"
            onClick={() => {
              navigate(`/projects/${bug?.project}/bugs/${bug?.shareToken}`);
            }}
          />
        </div>
      );
    }

    if (shared && !userSession) {
      return (
        <div className="not-logged-in">
          <span>Hi there 👋. Please log in to reply.</span>
          <LinkButton
            label="Log in"
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      );
    }

    const getReplyTypes = () => {
      if (userSession) {
        return [];
      }

      if (bug?.type === 'FEATURE_REQUEST') {
        return [`Send message to subscribers`, 'Note'];
      }

      return ['Reply', 'Note'];
    };

    const replyTypes = getReplyTypes();

    if (!bug || loadingComments) {
      return null;
    }

    return (
      <SendInputEditor
        draftId={`note_draft_n${bug.id}`}
        ref={textComposerRef}
        isSending={isSending}
        inputTypes={replyTypes}
        allowSend={bug !== undefined}
        currentInputType={isNote ? 'Note' : replyTypes[0]}
        inputTypeChanged={(inputType) => {
          setIsNote(inputType === 'Note');

          try {
            localStorage.setItem(
              `isnote_n${bug?.id}`,
              inputType === 'Note' ? 'true' : 'false',
            );
          } catch (exp) { }
        }}
        onFilesSelected={(files) => {
          setAttachments([...files, ...attachments]);
        }}
        attachmentsUpdated={(files) => {
          setAttachments([...files]);
        }}
        currentlyTyping={bugStore?.currentlyTyping}
        attachmentsRef={attachmentsRef}
        attachments={attachments}
        onClickSend={sendComment}
        shared={shared}
        showAttachments={usersStore?.currentUser?.id !== undefined}
        inputContentChanged={(json) => {
          setNoteDraft(json);
          debouncedUserTypingPing(json);
        }}
        inputPlaceholder={getTextPlaceholder()}
        mentions={
          projectStore?.currentProjectUsers
            ? projectStore?.currentProjectUsers.map((userItem) => {
              return {
                label: `${userItem.firstName} ${userItem.lastName}`,
                id: userItem.id,
                email: userItem.email,
                profileImageUrl: userItem.profileImageUrl,
              };
            })
            : []
        }
      />
    );
  };

  const renderAttachments = () => {
    if (!bug?.attachments) {
      return <></>;
    }

    return (
      <div className="attachments attachments--standalone">
        {bug.attachments.map((attachment, index) => {
          return (
            <div className="attachment" key={index}>
              <a href={attachment.url} target="_blank" rel="noreferrer">
                <SaveIcon />
                {attachment.name}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  const renderStaticComment = () => {
    const reporterInfo = getReporterInfo(bug);

    const canShowStaticComment =
      bug && !loadingComments && !bugStore?.currentBug?.hideContent;
    if (!canShowStaticComment) {
      return null;
    }

    if (isFeatureRequest) {
      return (
        <>
          <FeatureRequestFormData shared={shared} />
          <BugScreenContent />
          {renderAttachments()}
        </>
      );
    }

    return (
      <div className="static-comments">
        {!(
          projectStore?.currentProject?.domainVerification?.postmarkData
            ?.SPFVerified &&
          projectStore?.currentProject?.domainVerification?.postmarkData
            ?.DKIMVerified
        ) &&
          organisationStore?.isOrganisationAdmin &&
          !shared && (
            <InfoBox className="verify-domain-info mb-20">
              <>
                Improve your email deliverability by verifying your email domain.
                <PrimaryButton
                  className="ml-10"
                  label="Start verification"
                  onClick={() => {
                    modalStore!.closeModal();
                    navigate(
                      `/projects/${projectStore?.currentProject?.id}/settings/domainverification`,
                    );
                  }}
                />
              </>
            </InfoBox>
          )}
        <div className="main-feedback-container">
          <div className="main-feedback-avatar" data-for="commentTooltip">
            <UserAvatar
              isOnline={reporterInfo.isOnline}
              email={reporterInfo.email}
              small
            />
          </div>
          <div className="main-feedback-content">
            <FormData shared={shared} />
            {!shared && bug?.isSpam && (
              <div className="static-comment-spaminfo">
                <div className="static-comment-spaminfo-inner">
                  <i className="fa-solid fa-user-robot-xmarks"></i> This
                  feedback item has been marked as spam.
                  <LinkButton
                    className="ml-10 unmark-spam-button"
                    label="Not spam"
                    isLoading={notSpamLoading}
                    onClick={async () => {
                      setNotSpamLoading(true);
                      bugStore?.unarchiveBug(bugStore?.currentBug?.id!);
                      setNotSpamLoading(false);
                    }}
                  />
                </div>
              </div>
            )}
            <BugScreenContent />
            {renderAttachments()}
          </div>
        </div>
      </div>
    );
  };

  const renderComments = () => {
    const filteredComments = (comments ?? []).filter((comment) => {
      if (showHistory || comment.type !== CommentTypes.FEEDBACK_UPDATED) {
        return comment;
      }

      return null;
    });

    return (
      <div
        className={`comments-container ${isFeatureRequest && 'comments-container--feature-request'
          }`}
        ref={commentsRef}
        style={{
          height: `calc(100% - ${textfieldHeight + (shared ? 39 : 68) + 50}px)`,
        }}
      >
        {renderStaticComment()}
        {bug &&
          filteredComments.map((comment, index) => {
            var sameCreator = false;
            if (index - 1 >= 0) {
              sameCreator = hasSameCreator(
                comment,
                filteredComments[index - 1],
              );
            }

            return (
              <Comment
                hideCreator={sameCreator}
                associatedBug={bugStore?.currentBug}
                shared={shared}
                comment={comment}
                onDeleteComment={canDeleteComment(
                  comment,
                  usersStore?.currentUser?.id,
                  () => {
                    bugStore!.deleteCommentFromBug(bug!.id, comment.id);
                  },
                )}
                key={index}
                possibleStatusList={laneKeys}
                feedbackTypes={feedbackTypes}
              />
            );
          })}
        {(!bug || loadingComments) && (
          <div className="loading-comments">
            <div className="loading-comment">
              <PublicSkeleton
                width={34}
                height={34}
                count={1}
                style={{
                  borderRadius: '100%',
                  marginRight: '10px',
                }}
              />
              <PublicSkeleton
                height={55}
                width={'65vw'}
                count={1}
                style={{
                  borderRadius: '12px',
                  width: '65vw',
                  maxWidth: '320px',
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const unsavedTitle = newTicketTitle && newTicketTitle !== bug?.title;

  const renderTitleEditor = () => {
    if (!bug) {
      return null;
    }

    if (bug?.type === 'INQUIRY') {
      return null;
    }

    return (
      <>
        <div
          contentEditable={!shared}
          className={`ticket-headline-input ${unsavedTitle && 'ticket-headline-input--unsaved'
            }`}
          onInput={(e: any) => {
            if (e && e.target && e.target.innerText) {
              setNewTicketTitle(e.target.innerText);
            } else {
              setNewTicketTitle('');
            }
          }}
        >
          {bug?.title}
        </div>
        {unsavedTitle && (
          <LinkButton
            className="ticket-headline-input-save"
            small
            onClick={() => {
              bugStore?.updateBug(bug!.id, {
                title: newTicketTitle,
              });
              setNewTicketTitle('');
            }}
            icon="check"
          />
        )}
      </>
    );
  };

  return (
    <div className="notes-container">
      <div className="feedback-header">
        <span>
          {bug ? (
            `#${bug.bugId}`
          ) : (
            <PublicSkeleton
              width={100}
              height={13}
              count={1}
              style={{
                borderRadius: '5px',
              }}
            />
          )}
        </span>
        {renderTitleEditor()}
        {bug?.archived && (
          <div className="archived-tag">
            <i className="fa-solid fa-box-archive" />
            Archived
          </div>
        )}
        {bug?.duplicateOf && bug?.duplicateOf.length > 0 && (
          <div className="archived-tag">
            <i className="fa-solid fa-clone" />
            Duplicate
          </div>
        )}
        {bug && !shared && (
          <div className="feedback-header-action-items">
            <InboxSnoozeButton isInbox={isInbox} />
            <UnreadItemButton isInbox={isInbox} />
            <InboxBugStatusSelection isInbox={isInbox} />
          </div>
        )}
      </div>
      {renderComments()}
      {renderCommentComposer()}
      <ReactTooltip
        id="commentTooltip"
        className="infoTooltip"
        delayHide={500}
        type="light"
        effect="solid"
        html
      />
    </div>
  );
};

export default inject(
  'bugStore',
  'usersStore',
  'projectStore',
  'organisationStore',
  'modalStore',
)(observer(Comments));
