import classNames from 'classnames';
import { gleapUserProperties } from 'components/ConditionPicker/ConditionPicker';
import { OutboundCondition } from 'models/OutboundCondition';
import moment from 'moment';
import './ConditionTagStatic.scss';

export const conditionIsValid = (condition: OutboundCondition) => {
  if (condition.data != null && condition.type && condition.matchOperator) {
    return true;
  }
  return false;
}

export const ConditionTagStatic = ({ className, value }: {
  className?: string;
  value: OutboundCondition;
}) => {
  const isValid = conditionIsValid(value);
  const cardClassName = classNames(
    {
      'condition-tag-static': true,
      'condition-tag-static--invalid': (!isValid),
    },
    className,
  );

  const renderConditionName = () => {
    var eventName = value.event;
    var eventIcon = "bullseye-arrow";

    if (gleapUserProperties[eventName]) {
      eventName = gleapUserProperties[eventName].label;
      eventIcon = "user";
    }

    return (
      <div className="condition-name">
        <i className={`fa-solid fa-${eventIcon}`} />
        {eventName}
      </div>
    );
  }

  const calculateDays = (seconds) => {
    const number = parseInt(seconds);
    if (!isNaN(number)) {
      const days = Math.floor(value.data / (3600 * 24));
      return days;
    }
    return "";
  }

  const getConditionDescription = () => {
    const resolveCountMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        'is': 'is',
        'isnot': 'is not',
        'greaterthan': 'is greater than',
        'lessthan': 'is less than',
      }
      return matchOperatorMap[matchOperator];
    }

    const resolveDateMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        'after': 'after',
        'before': 'before',
        'on': 'on',
        'greaterthan': 'more than',
        'lessthan': 'less than',
        'exactly': 'exactly',
      }
      return matchOperatorMap[matchOperator];
    }

    if (value.matchOperator && value.data != null) {
      if (value.type === 'count') {
        return `count ${resolveCountMatchOperator(value.matchOperator)} ${value.data}`;
      }

      if (value.dateOperator === 'relative') {
        const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
        const days = calculateDays(value.data);
        return `${prefix}${resolveDateMatchOperator(value.matchOperator)} ${days} day${days === 1 ? '' : 's'} ago`;
      }

      if (value.dateOperator === 'absolute') {
        const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
        return `${prefix}${resolveDateMatchOperator(value.matchOperator)} ${moment(value.data).format('LL')}`;
      }
    }

    return '';
  }

  return <>
    <div
      className={cardClassName}>
      <div
        className='condition-tag-static--inner'
      >
        {renderConditionName()}
        <div className='condition-description'>{getConditionDescription()}</div>
      </div>
    </div>
  </>;
};
