export enum SocialButtonType {
  GOOGLE,
  GITHUB,
}

export enum Feature {
  REMOVE_BRANDING = 'removebranding',
  RATINGS = 'ratings',
  INQUIRIES = 'inquiries',
  FEATURE_REQUESTS = 'featureRequests',
  CUSTOM_FEEDBACK_TYPE = 'customFeedbackType',
  CONSOLE_LOG = 'consolelogs',
  SESSION_DATA = 'sessiondata',
  CUSTOM_DATA = 'customdata',
  REPLAYS = 'replays',
  SURVEYS = 'surveys',
  INTEGRATIONS = 'integrations',
}
