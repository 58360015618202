import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Gleap from 'gleap';
import { ampli } from './ampli';

ampli.load({ environment: 'production' });

// Load Gleap only when not shared.
if (!(window.location.href.includes("/sharedboard") || window.location.href.includes("/share"))) {
  Gleap.initialize("ogWhNhuiZcGWrva5nlDS8l7a78OfaLlV");
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
