import bugmapping from '../assets/mappings/bugmapping.json';
import bugiconmapping from '../assets/mappings/bugiconmapping.json';
import iOSDeviceModels from '../assets/mappings/iOSDeviceModels.json';

export interface MetaDataItem {
  key: string;
  title: string;
  value: string;
  icon: string;
}

const toHHMMSS = (secs) => {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds].map((v) => (v < 10 ? `0${v}` : v)).join(':');
};

const enrichMetaData = (metaData: object) => {
  const metaDataArray: MetaDataItem[] = [];
  if (metaData) {
    const metaDataKeys = Object.keys(metaData);
    for (let i = 0; i < metaDataKeys.length; i++) {
      const key = metaDataKeys[i];
      let value = metaData[key];

      if (key === 'sessionDuration') {
        value = toHHMMSS(value);
      }
      if (key === 'networkStatus') {
        value = metaData[key];
      }

      if (key === 'deviceModel') {
        if (iOSDeviceModels[value]) {
          value = iOSDeviceModels[value];
        }
      }

      metaDataArray.push({
        key,
        value,
        title: bugmapping[key] ? bugmapping[key] : key,
        icon: bugiconmapping[key] ? bugiconmapping[key] : 'objects-column',
      });
    }
  }

  return metaDataArray;
};

export { enrichMetaData };
