import ListTable, { CellGestureDetector } from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import TimeAgo from 'react-timeago';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AnswerBot.scss';
import InfoBox from 'components/InfoBox/InfoBox';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import { HeadLine } from 'components/HeadLine/HeadLine';
import GleapBotAI from 'assets/GleapBotAi.png';
import ChatStat from 'components/ChatStat/ChatStat';

interface AnswerBotProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const AnswerBot = ({ projectStore, modalStore }: AnswerBotProps) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const currentProject = projectStore?.currentProject;

  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        width: 'calc(100% - 290px)',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value,
              });
            }}
            label={row.value.question}
          />
        ),
      },
      {
        Header: 'Asked',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        width: 140,
        Header: 'Action',
        accessor: (row) => row,
        Cell: (row) => (<div>
          <PrimaryButton
            small
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value,
              });
            }}
            icon='edit'
            label="Edit"
          />
        </div>)
      }
    ],
    [],
  );

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getQAAnswers(currentProject.id, false, 0, 100);
      projectStore!.getProjectAnswerStats(currentProject.id);
    }
  }, [currentProject]);

  if (!currentProject) {
    return <></>;
  }

  const answers = projectStore?.currentQAAnswers.filter((answer) => {
    if (answer.skipped || !answer.acknowledged) {
      return false;
    }

    return true;
  }) || [];

  const renderAnswers = () => {
    if (projectStore.loadingQAAnswers) {
      return <div className='loading mt-100'>
        <LoadingAnimationMedium />
      </div>;
    }

    if (answers.length === 0) {
      return (
        <div className="no-surveys-container">
          <img
            src={GleapBotAI}
            alt="No surveys created yet"
            className="no-surveys-image"
          />
          <>
            <HeadLine
              className="mt-20"
              title="GPT-4 based answer bot"
              subtitle="Our answer bot is a brand new GPT-4 based bot that answers customer questions based on your FAQ and help center articles. Start by adding your first answer."
            />
            <PrimaryButton label='Add answer' onClick={() => {
              modalStore!.openModal(MODALTYPE.CREATE_QA);
            }} />
          </>
        </div>
      );
    }

    var successRate = 0;
    if (projectStore?.currentAnswerStats?.successful >= 0 && projectStore?.currentAnswerStats?.total > 0) {
      successRate = Math.round(projectStore?.currentAnswerStats?.successful / projectStore?.currentAnswerStats?.total * 100);
    }

    return (<>
      <InfoBox className='mb-30'>
        Our answer bot is a brand new GPT-4 based bot that answers customer questions based on your FAQ and help center articles.
      </InfoBox>
      <div className='answer-stats'>
        <ChatStat icon='messages' title={projectStore?.currentAnswerStats?.total} subtitle='Total questions asked' />
        <ChatStat className='chat-stat--success' icon='message-check' title={projectStore?.currentAnswerStats?.successful} subtitle='Answered' />
        <ChatStat onClick={() => {
          navigate(`/projects/${projectId}/bots/unansweredquestions`);
        }} className='chat-stat--info' icon='message-captions' title={projectStore?.currentAnswerStats?.failed} subtitle='Context missing' />
        <ChatStat className='chat-stat--success' icon='face-smile' title={`${successRate}%`} subtitle='Success rate' />
      </div>
      <ListTable
        data={answers}
        columns={columns}
      />
    </>);
  }

  return (
    <PageContainer className="answer-bot">
      <PageHeadLine title="Answer bot" tag='BETA'>
        <PrimaryButton
          onClick={() => {
            modalStore!.openModal(MODALTYPE.CREATE_QA);
          }}
          className="save-button"
          label="Add answer"
          icon='message-plus'
        />
      </PageHeadLine>
      <PageContent hasTitle isLargeBoxed>
        {renderAnswers()}
      </PageContent>
    </PageContainer >
  );
};

export default inject('projectStore', 'modalStore')(observer(AnswerBot));
