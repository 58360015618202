import axios from '../Axios';

const setWebhookAuthToken = (projectID, code) => {
  return axios.post(`/projects/${projectID}/integrations/webhook`, {
    code,
  });
};

const setWebhookActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
  sendDuplicates,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/webhook/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
      sendDuplicates,
    },
  );
};

const disconnectWebhooksIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/webhook/${integrationID}`,
  );
};

export {
  setWebhookAuthToken,
  setWebhookActions,
  disconnectWebhooksIntegration,
};
