import { inject, observer } from 'mobx-react';
import { ModalStore } from 'stores/private/ModalStore';
import './ActionEditorModal.scss';
import AnswerBotActionEditor from './AnswerBotActionEditor';
import AssignUserActionEditor from './AssignUserActionEditor';
import BotActionEditor from './BotActionEditor';
import ConditionActionEditor from './ConditionActionEditor';
import CreateTicketActionEditor from './CreateTicketActionEditor';
import FeedbackflowActionEditor from './FeedbackflowActionEditor';
import InputActionEditor from './InputActionEditor';
import LinkActionEditor from './LinkActionEditor';
import MessageActionEditor from './MessageActionEditor';
import RatingActionEditor from './RatingActionEditor';
import TagActionEditor from './TagActionEditor';
import APIActionEditor from './APIActionEditor';
import ExpectedReplyTimeModal from './ExpectedReplyTimeModal';

interface ActionEditorModalProps {
  modalStore?: ModalStore;
}

const ActionEditorModal = ({ modalStore }: ActionEditorModalProps) => {
  const action = modalStore?.customData?.action;
  if (!action) {
    return null;
  }

  const renderActionEditor = () => {
    if (action.type === 'message') {
      return <MessageActionEditor action={action} />;
    }
    if (action.type === 'ticketupdate-tag') {
      return <TagActionEditor action={action} />;
    }
    if (action.type === 'ticketupdate-assign') {
      return <AssignUserActionEditor action={action} />;
    }
    if (action.type === 'createticket') {
      return <CreateTicketActionEditor action={action} />;
    }
    if (action.type === 'input') {
      return <InputActionEditor action={action} />;
    }
    if (action.type === 'api') {
      return <APIActionEditor action={action} />;
    }
    if (action.type === 'link') {
      return <LinkActionEditor action={action} />;
    }
    if (action.type === 'rateconversation') {
      return <RatingActionEditor action={action} />;
    }
    if (action.type === 'botflow') {
      return <BotActionEditor action={action} />;
    }
    if (action.type === 'answerbotflow') {
      return <AnswerBotActionEditor action={action} />;
    }
    if (action.type === 'feedbackflow') {
      return <FeedbackflowActionEditor action={action} />;
    }
    if (action.type === 'condition') {
      return <ConditionActionEditor condition={action} />;
    }
    if (action.type === 'replytimes') {
      return <ExpectedReplyTimeModal />;
    }
    return <>{JSON.stringify(action)}</>;
  };

  return <>{renderActionEditor()}</>;
};

export default inject('modalStore')(observer(ActionEditorModal));
