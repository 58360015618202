import { useState } from 'react';
import './CreateOrganisation.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { observer, inject } from 'mobx-react';
import { HeadLine } from 'components/HeadLine/HeadLine';
import AvatarImage from 'components/AvatarImage/AvatarImage';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { useNavigate } from 'react-router';
import camIcon from '../../../assets/icons/cam.svg';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';

interface CreateOrganisationProps {
  organisationStore?: OrganisationStore;
}

export const CreateOrganisation = ({
  organisationStore,
}: CreateOrganisationProps) => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');
  const { register, handleSubmit, formState } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: any) => {
    if (data.organame !== '') {
      setIsLoading(true);
      organisationStore!
        .createOrganisation(data.organame, imageUrl)
        .then((createdOrganisation: any) => {
          navigate(`/organization/${createdOrganisation.id}`);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  if (!organisationStore) {
    return <></>;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Create an organization" />
      <PageContent hasTitle className="create-organisation">
        <form onSubmit={handleSubmit(onSubmit)}>
          <HeadLine
            title="Create an organization"
            subtitle="Gleap was built for teams."
          />
          <AvatarImage
            image={imageUrl !== '' ? imageUrl : camIcon}
            className={`${!imageUrl && 'placeholder-img'}`}
            editable
            afterImageUpload={(finalImageUrl) => {
              setImageUrl(finalImageUrl);
            }}
          />
          <SizedContainer size={ContainerSizes.M}>
            <TextInput
              label="Organization name"
              required
              inputRef={register('organame', { required: true })}
              placeholder="Organization name"
              type="text"
              name="organame"
              className="mt-20"
              error={
                formState.errors.organame ? 'Please enter an organization name' : ''
              }
              initalValue=""
            />
          </SizedContainer>
          <SizedContainer size={ContainerSizes.S}>
            <PrimaryButton
              className="mt-20"
              icon='plus'
              iconSideRight={true}
              label="Create organization"
              isLoading={isLoading}
              submit
            />
          </SizedContainer>
        </form>
      </PageContent>
    </PageContainer>
  );
};

export default inject('organisationStore')(observer(CreateOrganisation));
