import { useEffect, useState } from 'react';
import './ProjectFeatureRequests.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import LinkButton from 'components/LinkButton/LinkButton';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { useParams } from 'react-router';
import { Feature } from 'models/Enums';
import FixedSuggestSubscriptionCard from 'components/FixedSuggestSubscriptionCard/FixedSuggestSubscriptionCard';
import NotInstalledCard from 'components/NotInstalledCard/NotInstalledCard';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import Loading from 'components/Loading/Loading';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import BugBoard from 'components/BugBoard/BugBoard';
import BoardSortFilter from 'components/BoardSortFilter/BoardSortFilter';
import BoardFilter from 'components/BoardFilter/BoardFilter';
import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import { ampli } from 'ampli';

interface ProjectFeatureRequestsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function ProjectFeatureRequests({
  projectStore,
  modalStore,
}: ProjectFeatureRequestsProps) {
  const { shareToken } = useParams();
  const planIsEntitled = projectStore?.isFeatureInPlan(
    Feature.FEATURE_REQUESTS,
  );

  useEffect(() => {
    if (shareToken) {
      projectStore!.openFeedbackItem({ shareToken });
    }
  }, []);

  if (projectStore?.loadingCurrentProject) {
    return (
      <PageContainer>
        <PageHeadLine title="Feature requests" />
        <PageContent hasTitle isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <>
      {!planIsEntitled && <FixedSuggestSubscriptionCard />}
      <PageContainer>
        <PageHeadLine title="Feature requests">
          {planIsEntitled && (
            <div className="feature-board-share-container">
              <BoardSortFilter
                filterOptions={[
                  {
                    title: 'Sort manually',
                    key: 'lexorank',
                  },
                  {
                    title: 'By date',
                    key: 'createdAt',
                  },
                  {
                    title: 'By read status',
                    key: 'notificationsUnread',
                  },
                  {
                    title: 'By due date',
                    key: 'dueDate',
                  },
                  {
                    title: 'By priority',
                    key: 'priority',
                  },
                  {
                    title: 'By upvotes',
                    key: 'upvotesCount',
                  },
                ]}
              />
              <BoardFilter />
              <BoardSearchFilter />
              <div className="button-seperator hide-on-mobile"></div>
              <PrimaryButton
                label="Share board"
                icon="share-from-square"
                className="mr-10 hide-on-mobile"
                iconSideRight={false}
                small
                onClick={() => {
                  ampli.featureRequestShareModalOpened();
                  modalStore!.openModal(MODALTYPE.FEATURE_BOARD_SHARE, {
                    apiKey: projectStore?.currentProject?.apiKey,
                  });
                }}
              />
              {projectStore?.isProjectAdmin && (
                <LinkButton
                  className="hide-on-mobile"
                  iconSideRight={false}
                  icon="gear"
                  small
                  onClick={() => {
                    modalStore!.openModal(MODALTYPE.FEEDBACK_TYPE, {
                      feedbackType: projectStore?.currentFeedbackType?.type,
                    });
                  }}
                />
              )}
            </div>
          )}
        </PageHeadLine>
        <PageContent hasTitle hasPadding={false}>
          <div className="board-container">
            <BugBoard />
          </div>
        </PageContent>
        {!projectStore?.currentProject?.sdkInstalled && (
          <div className="project-not-installed">
            <NotInstalledCard />
          </div>
        )}
      </PageContainer>
    </>
  );
}

export default inject(
  'projectStore',
  'modalStore',
)(observer(ProjectFeatureRequests));
