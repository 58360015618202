import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CreateQAModal.scss';

interface CreateQAModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const CreateQAModal = ({
  modalStore,
  projectStore,
}: CreateQAModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const create = async () => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.createQAAnswer(projectStore.currentProject?.id, question, answer);
    modalStore!.closeModal();
    setIsLoading(false);
  };

  return (
    <SizedContainer size={ContainerSizes.L}>
      <div>
        <TextInput
          name="question"
          placeholder=""
          type="text"
          className="mb-20"
          error=""
          initalValue={question ?? ''}
          label="Question"
          onChange={(text) => {
            setQuestion(text);
          }}
        />
        <div className="input-label">
          Answer
        </div>
        <textarea
          className="default-textarea mb-20"
          placeholder=""
          value={answer ?? ''}
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
        />
        <PrimaryButton isLoading={isLoading} label='Add' onClick={() => {
          create();
        }} />
      </div>
    </SizedContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(CreateQAModal));
