import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { getAccessibleDatabases } from 'services/integrations/NotionHttpService';

export class NotionIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  accessibleDatabases: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setAccessibleDatabases = (accessibleDatabases) => {
    this.accessibleDatabases = accessibleDatabases;
  };

  getAccessibleDatabases = async () => {
    try {
      var response = await getAccessibleDatabases({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        for (let i = 0; i < response.data.results.length; i++) {
          response.data.results[i].name =
            response.data.results[i].title[0].text.content;
        }
        this.setAccessibleDatabases(response.data.results);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
