import { ReactComponent as GleapLogo } from 'assets/icons/GleapGray.svg';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router';
import { sendPostMessage } from 'services/Helper';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import './FeatureRequestsHeadLine.scss';

interface FeatureRequestsHeadLineProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  headerConfig: any;
  page: string;
  isAppWidget?: boolean;
}

const FeatureRequestsHeadLine = ({
  sharedBoardStorePublic,
  headerConfig,
  page,
  isAppWidget = false,
}: FeatureRequestsHeadLineProps) => {
  const { boardShareToken } = useParams();
  const navigate = useNavigate();

  const navigateTo = (type) => {
    sendPostMessage(
      JSON.stringify({
        type: 'app',
        name: 'data',
        data: {
          tab: type,
        },
      }),
    );

    var route = '';
    if (type === 'featurerequests') {
      route = '';
    } else if (type === 'roadmap') {
      route = 'roadmap';
    } else if (type === 'news') {
      route = 'news';
    }

    navigate(`/sharedboard/${boardShareToken}/${route}`);
  };

  return (
    <div className="feature-requests-headline">
      <div className="feature-requests-headline--inner">
        <a href={headerConfig.link ? headerConfig.link : '#'} rel="noreferrer">
          {headerConfig?.logo && headerConfig?.logo !== '' ? (
            <img className="shared-logo" src={headerConfig?.logo} alt="Logo" />
          ) : (
            <GleapLogo className="shared-logo shared-logo--default" />
          )}
        </a>
        <div className="links">
          <div
            className={page === 'featurerequests' ? 'active' : ''}
            onClick={() => {
              navigateTo(`featurerequests`);
            }}
          >
            <i className="fa-regular fa-lightbulb"></i>{' '}
            {sharedBoardStorePublic?.translateText('Feature requests')}
          </div>
          <div
            className={page === 'roadmap' ? 'active' : ''}
            onClick={() => {
              navigateTo(`roadmap`);
            }}
          >
            <i className="fa-regular fa-map-location"></i>{' '}
            {sharedBoardStorePublic?.translateText('Roadmap')}
          </div>
          {!isAppWidget && !sharedBoardStorePublic?.currentProject?.flowConfig?.hideNews && (
            <div
              className={page === 'news' ? 'active' : ''}
              onClick={() => {
                navigateTo(`news`);
              }}
            >
              <i className="fa-regular fa-megaphone"></i>{' '}
              {sharedBoardStorePublic?.translateText('News')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject('sharedBoardStorePublic')(
  observer(FeatureRequestsHeadLine),
);
