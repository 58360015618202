import Linkify from 'linkify-react';
import './NewLineText.scss';
import { toJS } from 'mobx';

interface NewLineTextProps {
  text?: string;
}

const NewLineText = ({ text }: NewLineTextProps) => {
  return (
    <Linkify
      tagName="span"
      options={{
        target: {
          url: '_blank',
        },
      }}
    >
      <div className="newlinetext">
        {text &&
          typeof text.split !== 'undefined' &&
          text.split('\n').map((str, key) => <p key={key}>{str}</p>)}
      </div>
    </Linkify>
  );
};

export default NewLineText;
