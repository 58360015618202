/* eslint-disable jsx-a11y/label-has-associated-control */
import TextInput from 'components/TextInput/TextInput';
import { useState } from 'react';
import './FeedbackActionQuestionEditorProps.scss';
import LinkButton from 'components/LinkButton/LinkButton';
import Switch from 'react-switch';
import { runInAction } from 'mobx';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import { actionTypes } from 'components/FeedbackActionQuestionListItem/FeedbackActionQuestionListItem';
import { ReactComponent as NPSIcon } from '../../assets/icons/npsicon.svg';
import { ReactComponent as SmileyIcon } from '../../assets/icons/smileyRating.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/onetofiveItem.svg';

export const npsTypes = [
  {
    label: 'Classic (0 to 10)',
    value: 'classic',
    icon: NPSIcon,
  },
  {
    label: 'Simplified (0 to 5)',
    value: 'modern',
    icon: QuestionIcon,
  },
  {
    label: 'Emoji',
    value: 'emoji',
    icon: SmileyIcon,
  },
];

interface FeedbackActionQuestionEditorPropsProps {
  formItem: any;
  isSurvey?: boolean;
  chatStyle?: boolean;
  formItemUpdated: (formItem: any) => void;
}

const FeedbackActionQuestionEditorProps = ({
  formItem,
  isSurvey = false,
  chatStyle = false,
  formItemUpdated,
}: FeedbackActionQuestionEditorPropsProps) => {
  const [toggle, setToggle] = useState(false);

  if (!formItem) {
    return null;
  }

  const updated = () => {
    formItemUpdated(formItem);
    setToggle(!toggle);
  };

  const buildNPSAdvancedContent = () => {
    return (
      <div className="feedback-content-column mt-30">
        <div className="switch-container">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.hideInfoLabel = !checked;
                updated();
              });
            }}
            checked={formItem.hideInfoLabel ? false : true}
          />
          <span>Show info label</span>
        </div>
        {formItem.hideInfoLabel ? null : (
          <>
            <TextInput
              className="mr-10 mt-30"
              name="lowest-value"
              label="Lowest value label"
              language="English"
              initalValue={formItem.lowestValueLabel}
              placeholder=""
              type="text"
              error=""
              onChange={(val) => {
                runInAction(() => {
                  formItem.lowestValueLabel = val;
                  updated();
                });
              }}
            />
            <TextInput
              className="mt-30"
              name="highest-value"
              label="Highest value label"
              language="English"
              initalValue={formItem.highestValueLabel}
              placeholder=""
              type="text"
              error=""
              onChange={(val) => {
                runInAction(() => {
                  formItem.highestValueLabel = val;
                  updated();
                });
              }}
            />
          </>
        )}
      </div>
    );
  };

  const buildOneToFiveScaleAdvancedContent = () => {
    return (
      <div className="feedback-content-column mt-30">
        <TextInput
          className="mr-10"
          name="lowest-value"
          label="Lowest value label"
          language="English"
          initalValue={formItem.lowestValueLabel}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              formItem.lowestValueLabel = val;
              updated();
            });
          }}
        />
        <TextInput
          className="mt-30"
          name="highest-value"
          label="Highest value label"
          language="English"
          initalValue={formItem.highestValueLabel}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              formItem.highestValueLabel = val;
              updated();
            });
          }}
        />
      </div>
    );
  };

  const buildNPSScaleContent = () => {
    return (
      <div className="feedback-content-column">
        <div className="input-label">NPS style</div>
        <div className="icon-dropdown-container">
          <IconDropdown
            value={npsTypes.find(
              (element) => element.value === (formItem.npsType || 'classic'),
            )}
            options={npsTypes}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                formItem.npsType = selectedItem.value;
                updated();
              });
            }}
          />
        </div>
        <TextInput
          language="English"
          name="survey-question"
          label={'Question'}
          value={formItem.title}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              formItem.title = val;
              updated();
            });
          }}
        />
        <div className="switch-container mt-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
      </div>
    );
  };

  const buildOneToFiveScaleContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          language="English"
          className="mb-30"
          name="survey-question"
          label={'Question'}
          value={formItem.title}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              formItem.title = val;
              updated();
            });
          }}
        />
        <div className="switch-container mt-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <div className="field-container mt-30">
          <div className="input-label">From</div>
          <div className="input-wrapper">
            <input
              className="textinput-gray"
              value={formItem.fromNumber ?? 1}
              type="number"
              onChange={(val) => {
                runInAction(() => {
                  const upToNumber = formItem.upToNumber ?? 5;
                  const number = parseInt(val.target.value);
                  if (!isNaN(number) && number >= 0 && number < upToNumber) {
                    formItem.fromNumber = number;
                    updated();
                  }
                });
              }}
            />
          </div>
        </div>
        <div className="field-container mt-30">
          <div className="input-label">To</div>
          <div className="input-wrapper">
            <input
              className="textinput-gray"
              value={formItem.upToNumber ?? 0}
              type="number"
              onChange={(val) => {
                runInAction(() => {
                  const number = parseInt(val.target.value);
                  if (!isNaN(number) && number > 0 && number <= 99) {
                    formItem.upToNumber = number;
                    updated();
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const buildMultipleChoiceAdvancedSelectContent = () => {
    return (
      <div className="switch-container mt-20">
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            runInAction(() => {
              formItem.randomizeChoices = checked;
              updated();
            });
          }}
          checked={formItem.randomizeChoices ? true : false}
        />
        <span>Randomize choices</span>
      </div>
    );
  };

  const buildMultipleChoiceSelectContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          className="mb-30"
          name="survey-question"
          language="English"
          label={'Question'}
          value={formItem.title}
          onChange={(val) => {
            runInAction(() => {
              formItem.title = val;
              updated();
            });
          }}
          placeholder=""
          type="text"
          error=""
        />
        <div className="switch-container">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <div className="input-label mt-30">Options</div>
        {formItem.choices &&
          formItem.choices.map((element, i) => (
            <div
              className="feedback-content-row-choices mb-5"
              key={`${formItem.name}-${i}`}
            >
              <TextInput
                name="choice"
                initalValue={element}
                onChange={(val) => {
                  runInAction(() => {
                    formItem.choices[i] = val;
                    updated();
                  });
                }}
                placeholder="Choice text"
                type="text"
                error=""
              />
              <i
                className="delete-choice-item fa-solid fa-trash"
                onClick={() => {
                  runInAction(() => {
                    formItem.choices.splice(i, 1);
                    updated();
                  });
                }}
              />
            </div>
          ))}
        <div className="add-choice-item-container">
          <LinkButton
            label="Add option"
            onClick={() => {
              runInAction(() => {
                if (formItem.choices) {
                  formItem.choices.push('New option');
                } else {
                  formItem.choices = ['New option'];
                }
                updated();
              });
            }}
          />
        </div>
      </div>
    );
  };

  const buildRatingContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          name="survey-question"
          language="English"
          label={'Question'}
          value={formItem.title}
          onChange={(val) => {
            runInAction(() => {
              formItem.title = val;
              updated();
            });
          }}
          placeholder="Optional"
          type="text"
          error=""
        />
        <div className="switch-container mt-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
      </div>
    );
  };

  const buildQuestionContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          name="survey-question"
          language="English"
          label={'Question'}
          value={formItem.title}
          onChange={(val) => {
            runInAction(() => {
              formItem.title = val;
              updated();
            });
          }}
          className="mb-30"
          placeholder=""
          type="text"
          error=""
        />
        <div className="switch-container mt-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <TextInput
          name="placeholder"
          label="Placeholder"
          language="English"
          initalValue={formItem.placeholder}
          onChange={(val) => {
            runInAction(() => {
              formItem.placeholder = val;
              updated();
            });
          }}
          className="mt-30"
          placeholder=""
          type="text"
          error=""
        />
      </div>
    );
  };

  const buildFileUploadContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          name="survey-question"
          language="English"
          label={'Question'}
          value={formItem.title}
          onChange={(val) => {
            runInAction(() => {
              formItem.title = val;
              updated();
            });
          }}
          placeholder=""
          type="text"
          error=""
        />
        <div className="switch-container mt-20">
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <TextInput
          name="restrictions"
          label="File type restrictions (optional)"
          initalValue={formItem.restrictions}
          onChange={(val) => {
            runInAction(() => {
              formItem.restrictions = val;
              updated();
            });
          }}
          className="mt-30"
          placeholder=".png,.jpg"
          type="text"
          error=""
        />
      </div>
    );
  };

  const buildActionContent = () => {
    switch (formItem.type) {
      case 'netpromoterscore':
        return buildNPSScaleContent();
      case 'onetofive':
        return buildOneToFiveScaleContent();
      case 'multiplechoice':
        return buildMultipleChoiceSelectContent();
      case 'multiple-choice-multi-select':
        return buildMultipleChoiceSelectContent();
      case 'rating':
        return buildRatingContent();
      case 'text':
        return buildQuestionContent();
      case 'textarea':
        return buildQuestionContent();
      case 'upload':
        return buildFileUploadContent();
      default:
        return (
          <div className="text p-20">
            This form item is not supported anymore.
          </div>
        );
    }
  };

  const buildAdvancedActionContent = () => {
    switch (formItem.type) {
      case 'netpromoterscore':
        return buildNPSAdvancedContent();
      case 'onetofive':
        return buildOneToFiveScaleAdvancedContent();
      case 'multiplechoice':
        return buildMultipleChoiceAdvancedSelectContent();
      case 'multiple-choice-multi-select':
        return buildMultipleChoiceAdvancedSelectContent();
      case 'rating':
        return null;
      case 'text':
        return null;
      case 'textarea':
        return null;
      case 'upload':
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="feedback-action-form-item-editor">
      <div className="icon-dropdown-container">
        <IconDropdown
          value={actionTypes.find((element) => element.value === formItem.type)}
          options={actionTypes}
          onValueChanged={(selectedItem) => {
            runInAction(() => {
              formItem.type = selectedItem.value;
              updated();
            });
          }}
        />
      </div>
      {buildActionContent()}
      <div className="advanced-settings-container">
        <div className="advanced-settings-container-content">
          {!chatStyle && (
            <TextInput
              name="description"
              label="Subtitle"
              initalValue={formItem.description}
              onChange={(val) => {
                runInAction(() => {
                  formItem.description = val;
                  updated();
                });
              }}
              className="mt-30"
              language="English"
              placeholder="Optional"
              type="text"
              error=""
            />
          )}
          {buildAdvancedActionContent()}
          <TextInput
            name="Identifier"
            label="Identifier"
            className="mt-30"
            value={formItem.name}
            onChange={(val) => {
              runInAction(() => {
                formItem.name = val;
                updated();
              });
            }}
            placeholder=""
            description={
              <span>
                The identifier is used to identify a question internally. Please
                make sure that it is unique.{' '}
                <a
                  href="https://docs.gleap.io/guides/Feedback%20flows/question-identifier"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="ml-2 fa-regular fa-book-open" /> Learn more
                </a>
              </span>
            }
            type="text"
            error=""
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackActionQuestionEditorProps;
