/* eslint-disable prefer-destructuring */
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import './ProjectContactDetails.scss';
import { SessionStore } from 'stores/private/SessionStore';
import EventTimeline from 'components/EventTimeline/EventTimeline';
import FeedbackItem from 'components/FeedbackItem/FeedbackItem';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Swal from 'sweetalert2';
import SessionDetails from '../../../components/SessionDetails/SessionDetails';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import Loading from 'components/Loading/Loading';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ampli } from 'ampli';

interface ProjectContactDetailsProps {
  projectStore?: ProjectStore;
  sessionStore?: SessionStore;
  modalStore?: ModalStore;
}

const ProjectContactDetails = ({
  projectStore,
  sessionStore,
  modalStore,
}: ProjectContactDetailsProps) => {
  // @ts-ignore
  const { projectId, contactId } = useParams();
  const session = sessionStore?.session;
  const navigate = useNavigate();

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);

    if (projectStore?.currentProject) {
      ampli.contactDetailsViewed({
        projectId: projectStore?.currentProject!.id,
      });
    }
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject) {
      sessionStore!.loadSession(contactId);
    }
  }, [projectStore?.currentProject]);

  if (!session || sessionStore?.loadingSession) {
    return (
      <PageContainer>
        <PageHeadLine title="Contact details" onActionBack={() => {}} />
        <PageContent hasTitle isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <PageHeadLine
        title="Contact details"
        onActionBack={() => {
          navigate(`/projects/${projectStore?.currentProject?.id}/sessions`);
        }}
      >
        <div className="header-content-right hide-on-mobile">
          <LinkButton
            onClick={() => {
              Swal.fire({
                text: 'Do you really want to delete this contact and all associated tickets?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await sessionStore!.removeSession(sessionStore!.session!.id);

                  navigate(
                    `/projects/${projectStore?.currentProject!.id}/sessions`,
                  );

                  ampli.contactDeleted({
                    projectId: projectStore?.currentProject!.id,
                  });
                }
              });
            }}
            icon="trash"
            label="Delete"
            iconSideRight={false}
            className="mr-10"
          />
          <LinkButton
            icon="file-export"
            iconSideRight={false}
            className="mr-10"
            onClick={() => {
              sessionStore!.downloadBugsAsJSON();

              ampli.contactExported({
                projectId: projectStore?.currentProject!.id,
              });
            }}
            label="Export data"
          />
          <PrimaryButton
            icon="pen-to-square"
            onClick={() => {
              modalStore!.openModal(MODALTYPE.COMPOSE_MESSAGE, {
                sessionId: sessionStore!.session!.id,
              });
            }}
            label="New conversation"
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle hasPadding={false}>
        <div className="contact-details-container-inner">
          <SessionDetails title="Details" session={sessionStore?.session} />
          <div className='feedback-item-activity'>
            {sessionStore?.sessionFeedbackActivities.map((feedbackItem) => {
              return (
                <FeedbackItem
                  key={feedbackItem.id}
                  feedbackItem={feedbackItem}
                />
              );
            })}
            {(!sessionStore?.sessionFeedbackActivities ||
              sessionStore?.sessionFeedbackActivities.length === 0) && (
                <div className="no-activities-yet">
                  No conversations yet 😲
                </div>
              )}
          </div>
          <EventTimeline />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'sessionStore',
  'modalStore',
)(observer(ProjectContactDetails));
