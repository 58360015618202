import {
  calculateContrast,
  calculateShadeColor,
  hashToColor,
} from 'helper/Color';
import { makeAutoObservable, runInAction } from 'mobx';
import { Bug } from 'models/Bug';
import {
  getSkipAndLimitFromPage,
  PaginationDataList,
} from 'models/PaginationDataList';
import { FeedbackType, Project } from 'models/Project';
import { ticketStatusToColor } from 'services/Helper';
import {
  getSharedProject,
  getSharedTicketsForProject,
} from 'services/ProjectHttpService';

export class SharedBoardStorePublic {
  currentProject: Project | undefined = undefined;
  loadingCurrentProject = false;
  overrideLanguage = '';
  currentFeedbackType?: FeedbackType = undefined;
  currentTicketsData: any = {}; // { [laneKey: string]: PaginationDataList<Bug> } = {};
  currentTicketDataFilter: any = {};
  currentTicketDataSort: any = {};

  featureRequestList: PaginationDataList<Bug> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };

  // Identification
  projectSharedToken: string | undefined = undefined;
  gleapId: string | undefined = undefined;
  gleapHash: string | undefined = undefined;

  stores: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  getCurrentLanguage(short = false) {
    const overrideLanguage = this.overrideLanguage;
    var language = 'en';
    if (typeof navigator.language !== 'undefined') {
      language = navigator.language.substring(0, 2).toLowerCase();
    }
    if (overrideLanguage && overrideLanguage !== '') {
      language = overrideLanguage.toLowerCase();
    }
    if (short) {
      return language.substring(0, 2);
    }
    return language;
  }

  translateText(key: string) {
    if (!key) {
      return '';
    }

    const customTranslations =
      this.currentProject?.flowConfig?.customTranslations;
    const language = this.getCurrentLanguage();

    if (typeof customTranslations === 'undefined') {
      return key;
    }

    const searchForTranslationTable = (langKey: string) => {
      var customTranslation = null;
      const translationKeys = Object.keys(customTranslations);
      for (var i = 0; i < translationKeys.length; i++) {
        const translationKey = translationKeys[i];
        if (
          langKey &&
          translationKey &&
          langKey === translationKey.toLowerCase()
        ) {
          if (customTranslations[translationKey]) {
            customTranslation = customTranslations[translationKey];
          }
        }
      }

      return customTranslation;
    };

    var customTranslation = searchForTranslationTable(language);

    // Extended search for language match only.
    if (!customTranslation && language) {
      const langKeys = language.split('-');
      if (langKeys && langKeys.length > 1) {
        customTranslation = searchForTranslationTable(langKeys[0]);
      }
    }

    if (customTranslation && customTranslation[key]) {
      return customTranslation[key];
    }

    return key;
  }

  setFeedbackTypeForPath(path) {
    if (!this.currentProject?.projectTypes) {
      return null;
    }

    for (let i = 0; i < this.currentProject?.projectTypes?.length; i++) {
      const currentProjectType = this.currentProject?.projectTypes[i];
      if (currentProjectType.path === path) {
        this.currentFeedbackType = currentProjectType;
        this.currentTicketDataFilter = {};
        this.currentTicketDataSort = {};

        this.prepareCurrentTicketsData();
        return currentProjectType;
      }
    }

    return null;
  }

  prepareCurrentTicketsData = () => {
    this.currentTicketsData = {};

    // Check if feedback type in currentTicketsData havn't changed
    if (
      this.currentTicketsData &&
      Object.keys(this.currentTicketsData).length > 0
    ) {
      const laneKeys = Object.keys(this.currentTicketsData);

      if (laneKeys.length > 0) {
        const currentLaneKey = laneKeys[0];
        const currentLane = this.currentTicketsData[currentLaneKey];

        if (
          currentLane.metaData.currentFeedbackType ===
          this.currentFeedbackType?.type
        ) {
          return;
        }
      }
    }

    if (
      this.currentFeedbackType &&
      this.currentFeedbackType.options &&
      this.currentFeedbackType.options.possibleLanes
    ) {
      this.currentFeedbackType.options.possibleLanes.forEach((possibleLane) => {
        if (possibleLane.key !== 'OPEN') {
          runInAction(() => {
            this.currentTicketsData[possibleLane.key] = {
              data: [],
              pageIndex: 0,
              itemsInPage: 50,
              isLoading: false,
              metaData: {
                currentFeedbackType: this.currentFeedbackType?.type,
                appliedQuery: null,
              },
            };
          });
        }
      });
    }

    this.setInitalSorting();

    for (const laneKey in this.currentTicketsData) {
      this.fetchAndSetTicketsDataForLane({
        laneKey,
        loadMore: false,
      });
    }
  };

  fetchAndSetTicketsDataForLane = async (args: {
    laneKey: string;
    loadMore: boolean;
  }) => {
    const { laneKey, loadMore } = args;
    const currentTicketsData = this.currentTicketsData[laneKey];

    if (
      !this.projectSharedToken ||
      !currentTicketsData ||
      currentTicketsData.isLoading ||
      !this.currentProject
    ) {
      return;
    }

    let { data, pageIndex, itemsInPage } = currentTicketsData;

    if (loadMore) {
      // Check if already fetched all data
      if (data.length < itemsInPage * pageIndex) {
        return;
      }

      pageIndex++;
    } else {
      pageIndex = 0;
      data = [];

      runInAction(() => {
        this.currentTicketsData[laneKey].data = data;
      });
    }

    let query = {
      type: this.currentFeedbackType?.type,
      status: laneKey,
      ...getSkipAndLimitFromPage({
        pageIndex,
        itemsInPage,
      }),
    };

    const filterQuery = this.getTicketFilterQuery();

    query = { ...query, ...filterQuery };

    if (
      JSON.stringify(this.currentTicketsData[laneKey].metaData.appliedQuery) ===
      JSON.stringify(query)
    ) {
      return;
    }

    runInAction(() => {
      this.currentTicketsData[laneKey].isLoading = true;
      this.currentTicketsData = { ...this.currentTicketsData };
    });

    const response = await getSharedTicketsForProject({
      apiKey: this.projectSharedToken,
      gleapId: this.gleapId,
      gleapHash: this.gleapHash,
      query,
    });

    runInAction(() => {
      if (
        this.currentTicketsData[laneKey] &&
        response.data &&
        response.data.tickets
      ) {
        this.currentTicketsData[laneKey].metaData.appliedQuery = query;
        this.currentTicketsData[laneKey].data = data.concat(
          response.data.tickets,
        );
        this.currentTicketsData[laneKey].pageIndex = pageIndex;
        this.currentTicketsData[laneKey].count = response.data.count;
        this.currentTicketsData[laneKey].unread = response.data.unread;
        this.currentTicketsData[laneKey].isLoading = false;
      }
    });
  };

  setInitalSorting = () => {
    switch (this.currentFeedbackType?.type) {
      case 'INQUIRY':
        this.currentTicketDataSort.sortKey = 'lastNotification';
        this.currentTicketDataSort.sortDirection = 'desc';
        break;

      case 'FEATURE_REQUEST':
        this.currentTicketDataSort.sortKey = 'upvotesCount';
        this.currentTicketDataSort.sortDirection = 'desc';
        break;

      default:
        this.currentTicketDataSort.sortKey = 'lexorank';
        this.currentTicketDataSort.sortDirection = 'asc';
        break;
    }
  };

  fetchAndSetFeatureRequestList = async (args: { loadMore?: boolean }) => {
    const { loadMore } = args;

    if (
      !this.projectSharedToken ||
      !this.featureRequestList ||
      this.featureRequestList.isLoading ||
      !this.currentProject
    ) {
      return;
    }

    let { data, pageIndex, itemsInPage } = this.featureRequestList;

    if (loadMore) {
      // Check if already fetched all data
      if (data.length < itemsInPage * pageIndex) {
        return;
      }

      pageIndex++;
    } else {
      pageIndex = 0;
      data = [];

      runInAction(() => {
        this.featureRequestList.data = data;
      });
    }

    let query = {
      type: this.currentFeedbackType?.type,
      ...getSkipAndLimitFromPage({
        pageIndex,
        itemsInPage,
      }),
    };

    const filterQuery = this.getTicketFilterQuery();

    query = { ...query, ...filterQuery };

    this.featureRequestList.isLoading = true;

    const response = await getSharedTicketsForProject({
      apiKey: this.projectSharedToken,
      gleapId: this.gleapId,
      gleapHash: this.gleapHash,
      query,
    });

    runInAction(() => {
      if (response.data && response.data.tickets) {
        this.featureRequestList.data = data.concat(response.data.tickets);
        this.featureRequestList.pageIndex = pageIndex;
        this.featureRequestList.isLoading = false;
      }
    });
  };

  filterTicketsData = () => {
    for (const laneKey in this.currentTicketsData) {
      this.fetchAndSetTicketsDataForLane({
        laneKey,
        loadMore: false,
      });
    }
  };

  filterFeatureRequestList = () => {
    this.fetchAndSetFeatureRequestList({
      loadMore: false,
    });
  };

  getTicketFilterQuery = () => {
    let query: any = {};

    for (const key in this.currentTicketDataFilter) {
      switch (key) {
        case 'country':
          query['session.location.country'] =
            this.currentTicketDataFilter[key].code;
          break;

        default:
          query[key] = this.currentTicketDataFilter[key];
          break;
      }
    }

    // Sort
    if (
      this.currentTicketDataSort &&
      this.currentTicketDataSort.sortKey &&
      this.currentTicketDataSort.sortDirection
    ) {
      query['sort'] = `${
        this.currentTicketDataSort.sortDirection === 'asc' ? '' : '-'
      }${this.currentTicketDataSort.sortKey}`;
    }

    return query;
  };

  setCurrentProject = (currentProject) => {
    this.currentProject = currentProject;

    const color = this.currentProject?.flowConfig?.color ?? '#00bcd4';
    const contrastColor = calculateContrast(color);
    const headerColor =
      this.currentProject?.flowConfig?.headerColor ?? '#00bcd4';
    const contrastHeaderColor = calculateContrast(headerColor);

    const backgroundColor =
      this.currentProject?.flowConfig?.backgroundColor || '#ffffff';
    const primaryTextColor = calculateContrast(backgroundColor);

    const isDarkMode = primaryTextColor === '#ffffff';
    const headerDarkColor = calculateShadeColor(
      headerColor,
      contrastHeaderColor === '#ffffff' ? -35 : -15,
    );
    const secondaryTextColor = isDarkMode
      ? calculateShadeColor(backgroundColor, 120)
      : calculateShadeColor(backgroundColor, -160);
    const backgroundColorHover = isDarkMode
      ? calculateShadeColor(backgroundColor, 20)
      : calculateShadeColor(backgroundColor, -10);
    const hoverHoverColor = isDarkMode
      ? calculateShadeColor(backgroundColor, 40)
      : calculateShadeColor(backgroundColor, -20);
    const borderColor = isDarkMode
      ? calculateShadeColor(backgroundColor, 70)
      : calculateShadeColor(backgroundColor, -70);

    const existingStyle = document.getElementById('gleap-shared-project-color');
    if (existingStyle) {
      existingStyle.remove();
    }

    const style = document.createElement('style');
    style.textContent = `
      :root {
        --shared-color: ${color};
        --shared-color-22: ${color}22;
        --shared-contrast-color: ${contrastColor};
        --shared-header-color: ${headerColor};
        --shared-header-dark-color: ${headerDarkColor};
        --shared-background-color: ${backgroundColor};
        --shared-header-contrast-color: ${contrastHeaderColor};
        --shared-header-contrast-color-33: ${contrastHeaderColor}33;
        --shared-title-color: ${primaryTextColor};
        --shared-text-color: ${secondaryTextColor};
        --shared-border-color: ${borderColor};
        --shared-gray-color: ${backgroundColorHover};
        --shared-light-gray-color: ${hoverHoverColor};
      }
      #root {
        background-color: ${backgroundColor} !important;
      }
      .small-title-text {
        color: ${primaryTextColor} !important;
      }
      .running-text {
        color: ${secondaryTextColor} !important;
      }
      `;
    style.id = 'gleap-shared-project-color';
    document.head.append(style);
  };

  setLoadingCurrentProject = (loadingCurrentProject) => {
    this.loadingCurrentProject = loadingCurrentProject;
  };

  getSharedProject = async (
    projectSharedToken: string,
    gleapId?,
    gleapHash?,
  ) => {
    if (
      this.currentProject?.apiKey === projectSharedToken ||
      this.currentProject
    ) {
      return;
    }

    this.projectSharedToken = projectSharedToken;
    this.gleapId = gleapId;
    this.gleapHash = gleapHash;

    this.setCurrentProject(undefined);
    this.setLoadingCurrentProject(true);

    const response = await getSharedProject(
      projectSharedToken,
      gleapId,
      gleapHash,
    );

    if (response && response.status === 200 && response.data) {
      this.setCurrentProject(response.data.project as Project);
      this.setLoadingCurrentProject(false);
      this.setFeedbackTypeForPath('featurerequests');
      this.stores.sharedSessionStore.setSession(response.data.session);
      this.stores.sharedSessionStore.setUpvotes(response.data.upvotes);

      if (response.data.session) {
        try {
          gleapId = localStorage.setItem(
            `${projectSharedToken}_gleapId`,
            response.data.session.gleapId,
          );
          gleapHash = localStorage.setItem(
            `${projectSharedToken}_gleapHash`,
            response.data.session.gleapHash,
          );
        } catch (exp) {}
      }
    }
  };

  getLanesForFeedbackType = () => {
    if (
      this.currentFeedbackType &&
      this.currentFeedbackType.options &&
      this.currentFeedbackType.options.possibleLanes
    ) {
      return this.currentFeedbackType.options.possibleLanes;
    }

    return [];
  };

  getFeatureRequestStates = (excludeOpenDone = true) => {
    const featureRequestStates = this.currentProject?.projectTypes
      .find((value) => value.type === 'FEATURE_REQUEST')
      ?.options.possibleLanes.filter((featureRequestState) => {
        if (
          excludeOpenDone &&
          (featureRequestState.key === 'OPEN' ||
            featureRequestState.key === 'DONE')
        ) {
          return false;
        }
        return true;
      })
      .map((item) => {
        return { ...item, color: ticketStatusToColor(item.key) };
      });

    return featureRequestStates;
  };
}
