import { useEffect, useState } from 'react';
import './SharedFeatureRequestsListContainer.scss';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import FeatureStatusRequestFilter from 'components/FeatureStatusRequestFilter/FeatureStatusRequestFilter';
import SharedFeatureRequestsList from 'components/SharedFeatureRequestsList/SharedFeatureRequestsList';
import FeatureRequestsOrder from 'components/FeatureRequestsOrder/FeatureRequestsOrder';
import { useLocation } from 'react-router';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { SharedBoardStorePublic } from 'stores/public/SharedBoardStorePublic';
import { SharedPoweredBy } from '../SharedPoweredBy/SharedPoweredBy';

interface SharedFeatureRequestsListContainerProps {
  sharedBoardStorePublic?: SharedBoardStorePublic;
  widgetMode?: boolean;
}

const SharedFeatureRequestsListContainer = ({
  sharedBoardStorePublic,
  widgetMode,
}: SharedFeatureRequestsListContainerProps) => {
  const sharedFeatureRequestStates =
    sharedBoardStorePublic?.getFeatureRequestStates(false);
  const filtersNotOpen = sharedBoardStorePublic
    ?.getFeatureRequestStates(true)
    .map((state) => {
      return state.key;
    });
  const featureRequests = sharedBoardStorePublic?.featureRequestList.data;
  const { boardShareToken } = useParams();
  const currentTicketDataFilter =
    sharedBoardStorePublic?.currentTicketDataFilter;
  const [filters, setFilters] = useState(filtersNotOpen);
  const [order, setOrder] = useState('VOTES');
  const [tags, setTags] = useState([] as String[]);
  const location = useLocation();

  useEffect(() => {
    sharedBoardStorePublic?.fetchAndSetFeatureRequestList({});
  }, []);

  useEffect(() => {
    if (featureRequests) {
      var newTags: any = {};
      featureRequests.forEach((featureRequest) => {
        featureRequest.tags?.forEach((tag) => {
          if (!newTags[tag]) {
            newTags[tag] = 0;
          }
          newTags[tag]++;
        });
      });
      setTags(newTags);
    }
  }, [featureRequests]);

  useEffect(() => {
    sharedBoardStorePublic?.filterFeatureRequestList();
  }, [currentTicketDataFilter]);

  useEffect(() => {
    try {
      const orderFilter = localStorage.getItem(
        `feature_request_board_order_filter${sharedBoardStorePublic?.currentProject?.id}`,
      );
      if (orderFilter) {
        setOrder(orderFilter);
      }

      const statusFilter = localStorage.getItem(
        `feature_request_board_status_filter${sharedBoardStorePublic?.currentProject?.id}`,
      );
      if (statusFilter) {
        setFilters([statusFilter]);
      }

      const params = new URLSearchParams(location.search);
      if (params.get('filter')) {
        setFilters([params.get('filter')]);
      }
    } catch (exp) {}
  }, []);

  if (!sharedBoardStorePublic?.currentProject) {
    return null;
  }

  const renderStatusFilter = () => {
    return (
      <div className="board-filter-status">
        <div className="filter-title">{sharedBoardStorePublic?.translateText('Status')}</div>
        <FeatureStatusRequestFilter
          featureRequestStates={sharedFeatureRequestStates}
          value={filters.length > 1 ? 'NOTDONE' : filters[0]}
          onValueChanged={(newValue) => {
            if (newValue === 'NOTDONE') {
              setFilters(filtersNotOpen);
              try {
                localStorage.removeItem(
                  `feature_request_board_status_filter${sharedBoardStorePublic?.currentProject?.id}`,
                );
              } catch (exp) {}
            } else {
              setFilters([newValue!]);
              try {
                localStorage.setItem(
                  `feature_request_board_status_filter${sharedBoardStorePublic?.currentProject?.id}`,
                  newValue!,
                );
              } catch (exp) {}
            }
          }}
        />
      </div>
    );
  };

  const renderTagFilter = () => {
    if (tags.length === 0) {
      return null;
    }

    return (
      <div className="tag-filters">
        <div className="filter-title mt-30">{sharedBoardStorePublic?.translateText('Tags')}</div>
        <div className="tags mt-5">
          {Object.keys(tags).map((key) => {
            return (
              <div
                className="tag"
                key={key}
                onClick={() => {
                  if (
                    currentTicketDataFilter.tags &&
                    currentTicketDataFilter.tags.includes(key)
                  ) {
                    currentTicketDataFilter.tags =
                      currentTicketDataFilter.tags.filter((tag) => tag !== key);
                  } else {
                    if (!currentTicketDataFilter.tags) {
                      currentTicketDataFilter.tags = [];
                    }

                    currentTicketDataFilter.tags = [
                      ...currentTicketDataFilter.tags,
                      key,
                    ];
                  }

                  sharedBoardStorePublic!.currentTicketDataFilter = {
                    ...currentTicketDataFilter,
                  };
                }}
              >
                <div className="tag-name">
                  <span>#</span>
                  {key}
                </div>
                {currentTicketDataFilter.tags &&
                currentTicketDataFilter.tags.includes(key) ? (
                  <div className="tag-selected">
                    <i className="fa-sharp fa-solid fa-check"></i>
                  </div>
                ) : (
                  <div className="tag-count">{tags[key]}</div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="shared-feature-requests-board-main-container">
      {!widgetMode && (
        <div className="shared-feature-requests-board-filters">
          {renderStatusFilter()}
          {renderTagFilter()}
          {!sharedBoardStorePublic?.currentProject?.flowConfig
            ?.hideBranding && (
            <SharedPoweredBy />
          )}
        </div>
      )}
      <div className="shared-feature-requests-board">
        <div className="board-filter-header">
          <div
            className={`board-filter-sort ${
              !widgetMode && 'board-filter-sort--webmode'
            }`}
          >
            <div className="board-filter-header-title">{sharedBoardStorePublic?.translateText('Show')}</div>
            <FeatureRequestsOrder
              value={order}
              onValueChanged={(newValue: any) => {
                setOrder(newValue!);
                try {
                  localStorage.setItem(
                    `feature_request_board_order_filter${sharedBoardStorePublic?.currentProject?.id}`,
                    newValue.key,
                  );

                  sharedBoardStorePublic.currentTicketDataSort = newValue!;
                  sharedBoardStorePublic?.filterFeatureRequestList();
                } catch (exp) {}
              }}
            />
          </div>
          {widgetMode ? (
            renderStatusFilter()
          ) : (
            <PrimaryButton
              onClick={() => {
                (window as any).Gleap.startFeedbackFlow('featurerequests');
              }}
              icon="plus"
              iconSideRight={false}
              label={sharedBoardStorePublic?.translateText('Request a feature')}
            />
          )}
        </div>
        <SharedFeatureRequestsList
          isShared
          widgetMode={widgetMode}
          boardShareToken={boardShareToken}
          filter={filters}
          showUnreadOnly={false}
        />
      </div>
    </div>
  );
};

export default inject('sharedBoardStorePublic')(
  observer(SharedFeatureRequestsListContainer),
);
