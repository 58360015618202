import './SubscriberList.scss';
import { BugStore } from 'stores/private/BugStore';
import { inject } from 'mobx-react';
import ListTable, {
  CellIconAction,
  CellInvitedUser,
} from 'components/ListTable/ListTable';
import { useMemo } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import InfoBox from 'components/InfoBox/InfoBox';
import { getFormFieldValue } from 'helper/FormDataHelper';
import LinkButton from 'components/LinkButton/LinkButton';

interface SubscriberListProps {
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const SubscriberList = ({ bugStore, modalStore }: SubscriberListProps) => {
  const bug = bugStore!.currentBug;
  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: (row) => row,
        Cell: (row) => <CellInvitedUser email={row.value.email} />,
      },
      {
        id: 'remove',
        width: '50px',
        Header: '',
        accessor: (row) => row,
        Cell: (row) => {
          return (
            <CellIconAction
              Icon={DeleteIcon}
              action={async () => {
                if (bug) {
                  await bugStore!.subscribeUser(
                    bug,
                    row.value.email,
                    false,
                  );
                }
              }}
            />
          );
        },
      },
    ],
    [bug],
  );

  if (!bug) {
    return null;
  }

  return (
    <div className="subscriber-list-container">
      <div className="subscriber-list-header">
        <span>Subscribers ({bug.upvotes?.length ?? 0})</span>
        <div className="subscriber-list-action-items">
          <LinkButton
            label='Export subscribers'
            icon="file-export"
            iconSideRight={false}
            onClick={() => {
              try {
                var csvContent = "";
                if (bug.upvotes) {
                  for (var i = 0; i < bug.upvotes.length; i++) {
                    if (bug.upvotes[i]) {
                      csvContent += bug.upvotes[i].email + "\n";
                    }
                  }
                }

                const hiddenElement = document.createElement('a');
                hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvContent)}`;
                hiddenElement.target = '_blank';
                hiddenElement.download = `${bug.bugId}-subscribers.csv`;
                hiddenElement.click();
              } catch (exp) { }
            }}
          />
          <PrimaryButton
            label="Add subscriber"
            className="ml-10"
            icon="plus"
            onClick={() => {
              modalStore!.openModalSmart(MODALTYPE.SUBSCRIBE_USER, {
                bug,
                title: getFormFieldValue(bug, 'title'),
              });
            }}
          />
        </div>
      </div>
      <InfoBox className="mt-20 mb-20">
        <>
          All subscribed users will receive the updates you post within a
          feature request.
        </>
      </InfoBox>
      <ListTable data={bug.upvotes} columns={columns} />
    </div>
  );
};

export default inject('bugStore', 'modalStore')(SubscriberList);
