import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './ShareableUrl.scss';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import copy from '../../assets/icons/copy.svg';

interface ApikeyProps {
  url: String;
}

const ShareableUrl = ({ url }: ApikeyProps) => {
  return (
    <div className="shareable-url">
      <div className="key">{url}</div>
      <CopyToClipboard
        text={url}
        onCopy={() => {
          toast.success('Successfully copied ✓');
        }}
      >
        <img className="copy-button" src={copy} alt="copy" />
      </CopyToClipboard>
    </div>
  );
};

export default inject('projectStore')(observer(ShareableUrl));
