export enum ROLES {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type OrgaUser = {
  id?: string;
  userId?: string;
  role: ROLES;
};

export type Organisation = {
  id?: string;
  name: string;
  description?: string;
  country?: string;
  isMetered?: boolean;
  aiTokenLimit?: number;
  onboardingFlow?: string;
  selectedOnboardingPlan?: boolean;
  completedOnboarding?: boolean;
  homepage?: string;
  usageData?: {
    tokens: number;
  };
  address?: string;
  trialUsed?: boolean;
  email?: string;
  mauUsage?: number;
  mauLastUpdated?: Date;
  phoneNumber?: string;
  picture?: string;
  users?: OrgaUser[];
  payment?: {
    customerID: string;
    paymentMethods: [];
  };
  onboarding?: {
    projectcreated: boolean;
    addedintegration: boolean;
    reportedbug: boolean;
    joinedcommunity: boolean;
  };
  createdAt?: string;
  invoices?: [
    {
      invoiceID: string;
      createdAt: string;
      price: string;
      invoicePdf: string;
    },
  ];
  subscription?: {
    subscriptionID: string;
    planID: string;
    trialExpiresAt: string;
    metadata: any;
  };
};
