import axios from './Axios';

const uploadFile = (file: File, path?: string): any => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(`/uploads?path=${path}`, formData, config);
};

export { uploadFile };
