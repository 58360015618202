import { makeAutoObservable } from 'mobx';
import { Plan } from 'models/Plan';
import { getPlans } from 'services/PlanHttpService';

// eslint-disable-next-line import/prefer-default-export
export class PlanStore {
  plans: Plan[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  getPlans = async () => {
    try {
      const response = await getPlans();
      if (response && response.status === 200) {
        this.plans = response.data;
      }
      // eslint-disable-next-line no-empty
    } catch (err: any) { }
  };
}