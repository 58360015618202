import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  getBusinesses,
  getProjects,
  getTaskBoards,
  getTaskGroups,
} from 'services/integrations/PlutioHttpService';

export class PlutioIntegrationStore {
  stores: any = {};
  integrationID: string = '';
  businesses: any = [];
  projects: any = [];
  taskBoards: any = [];
  taskGroups: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setIntegrationID = (integrationID) => {
    this.integrationID = integrationID;
  };

  setBusinesses = (businesses) => {
    this.businesses = businesses;
  };

  getBusinesses = async () => {
    try {
      const response = await getBusinesses({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
      });
      if (response.status === 200) {
        this.setBusinesses(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setProjects = (projects) => {
    this.projects = projects;
  };

  getProjects = async (businessDomain) => {
    try {
      const response = await getProjects({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        businessDomain,
      });

      if (response.status === 200) {
        this.setProjects(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setTaskBoards = (taskBoards) => {
    this.taskBoards = taskBoards;
  };

  getTaskBoards = async (businessDomain, plutioProjectID) => {
    try {
      const response = await getTaskBoards({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        businessDomain,
        plutioProjectID,
      });
      if (response.status === 200) {
        this.setTaskBoards(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };

  setTaskGroups = (taskGroups) => {
    this.taskGroups = taskGroups;
  };

  getTaskGroups = async (businessDomain, plutioProjectID, taskBoardID) => {
    try {
      const response = await getTaskGroups({
        projectID: this.stores.projectStore.currentProject.id,
        integrationID: this.integrationID,
        businessDomain,
        plutioProjectID,
        taskBoardID,
      });
      if (response.status === 200) {
        this.setTaskGroups(response.data);
      }
    } catch (err: any) {
      toast.error('Something went wrong!');
    }
  };
}
