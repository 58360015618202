import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ModalStore } from 'stores/private/ModalStore';

interface ExpectedReplyTimeModalProps {
  modalStore?: ModalStore;
}

const ExpectedReplyTimeModal = ({
  modalStore,
}: ExpectedReplyTimeModalProps) => {
  return (
    <div className="rating-action-editor">
      <PageContainer>
        <PageHeadLine title="Expected reply times">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="text mb-20">
            This action will display the expected reply time to the customer.
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject('modalStore')(observer(ExpectedReplyTimeModal));
