import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ZendeskIntegrationStore } from 'stores/integrations/ZendeskIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import ARROW from 'assets/icons/np_arrow_3542562_000000.svg';
import GLEAP_LOGO from 'assets/icons/Gleap.svg';
import ZENDESK_LOGO from 'assets/icons/zendesklogo.png';
import './ZendeskConfiguration.scss';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContainer from 'components/PageContainer/PageContainer';

interface ZendeskConnectConfigurationProps {
  zendeskIntegrationStore?: ZendeskIntegrationStore;
  projectStore?: ProjectStore;
}

const ZendeskConnectConfiguration = ({
  zendeskIntegrationStore,
  projectStore,
}: ZendeskConnectConfigurationProps) => {
  // @ts-ignore
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, []);

  const connectZendesk = async () => {
    zendeskIntegrationStore!.getAuthToken({ baseUrl });
  };

  return (
    <PageContainer>
      <PageHeadLine
        title="Zendesk Integration"
        onActionBack={() => {
          navigate(`/projects/${projectId}/integrations`);
        }}
      />
      <PageContent hasTitle isMediumBoxed>
        <div className="choose-integation-action-container">
          <div className="integration-info-content-header">
            <img
              className="integration-preview-image"
              src={GLEAP_LOGO}
              alt="Gleap logo"
            />
            <img className="arrow-icon" src={ARROW} alt="Integrations" />
            <img
              className="integration-preview-image"
              src={ZENDESK_LOGO}
              alt="Zendesk logo"
            />
          </div>
          <div className="row-container mb-15">
            <TextInput
              error=""
              placeholder="your-organisation"
              label="Zendesk url"
              initalValue={baseUrl}
              onChange={(val) => {
                setBaseUrl(val);
              }}
            />
            <div className="domain-preview">.zendesk.com</div>
          </div>
          <SizedContainer size={ContainerSizes.M}>
            <PrimaryButton label="Connect" onClick={connectZendesk} />
          </SizedContainer>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'zendeskIntegrationStore',
  'projectStore',
)(observer(ZendeskConnectConfiguration));
