import { ampli } from 'ampli';
import ApiKey from 'components/ApiKey/ApiKey';
import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Switch from 'react-switch';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './UpdateProjectNotifications.scss';

interface UpdateProjectNotificationsProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const UpdateProjectNotifications = ({
  projectStore,
  organisationStore,
}: UpdateProjectNotificationsProps) => {
  const project = projectStore!.currentProject;
  const isAdmin = organisationStore?.isOrganisationAdmin;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [
    sendFeedbackReceivedNotifications,
    setSendFeedbackReceivedNotifications,
  ] = useState(false);
  const [sendBugAssignmentNotifications, setSendBugAssignmentNotifications] =
    useState(false);
  const [sendBugNotifications, setSendBugNotifications] = useState(false);
  const [bugNotificationsReciever, setBugNotificationsReciever] = useState('');

  useEffect(() => {
    if (project) {
      setSendBugAssignmentNotifications(
        project!.sendBugAssignmentNotifications,
      );
      setSendFeedbackReceivedNotifications(
        project!.sendFeedbackReceivedNotifications,
      );
      setSendBugNotifications(project!.sendBugNotifications);
      setBugNotificationsReciever(project!.bugNotificationsReciever);
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Email settings" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        <div className="options-group">
          <div className="options-group-header">Gleap email inbox</div>
          <div className="text mt-10">
            Gleap allows the creation of inquiries by email. As soon as a new
            email arrives in your Gleap email inbox, a new inquiry will be
            created.
            <br />
            <br />
            <b>The email address of your Gleap email inbox is:</b>
          </div>
          <div className="mt-10 mb-10">
            <ApiKey
              icon="mailbox"
              apiKey={`project+id${project.apiKey}@gleapmail.com`}
            />
          </div>
          <InfoBox>
            You might want to forward your support email inbox to the Gleap
            inbound email inbox above, which allows you to handle all inquiries
            right in one place.
          </InfoBox>
        </div>
        <div className="options-group">
          <div className="options-group-header">Email notifications</div>
          <div className="text mt-10">
            Enforce identity verification on all platforms to prevent third
            parties from impersonating logged-in users.
            <br />
            Once enabled, Gleap will require you to pass the correct user hash
            in order to identify users.
          </div>
          <div className="checkbox-settings-content mt-20">
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setSendBugAssignmentNotifications(checked);
                  }}
                  checked={sendBugAssignmentNotifications}
                />
                <span>
                  Send email notifications to people who got assigned to a bug
                  report.
                </span>
              </div>
            </div>
            <div className="checkbox-settings-container mt-10">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setSendFeedbackReceivedNotifications(checked);
                  }}
                  checked={sendFeedbackReceivedNotifications}
                />
                <span>
                  Send feedback received email confirmations to the reporter of
                  a new feedback item.
                </span>
              </div>
            </div>
            <div className="checkbox-settings-container mt-10">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setSendBugNotifications(checked);
                  }}
                  checked={sendBugNotifications}
                />
                <span>
                  Send email notifications to a predefined email for newly
                  reported feedback items.
                </span>
              </div>
              {sendBugNotifications && (
                <div className="fullwidth">
                  <TextInput
                    name="Receiver"
                    placeholder="someone@awesomecompany.com"
                    type="text"
                    className="mb-20"
                    error=""
                    initalValue={bugNotificationsReciever}
                    label="Receiver email address"
                    onChange={(text) => {
                      setBugNotificationsReciever(text);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              if (sendBugAssignmentNotifications) {
                ampli.sendEmailToAssignedUsersEnabled({
                  projectId: project.id,
                });
              }
              if (sendFeedbackReceivedNotifications) {
                ampli.sendEmailOnFeedbackEnabled({
                  projectId: project.id,
                });
              }
              if (sendBugNotifications) {
                ampli.sendEmailUpdateToTeamEnabled({
                  projectId: project.id,
                });
              }

              try {
                await projectStore!.updateProject(project!.id, {
                  sendBugAssignmentNotifications,
                  sendFeedbackReceivedNotifications,
                  sendBugNotifications,
                  bugNotificationsReciever,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            className="mt-20"
            label="Save"
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(UpdateProjectNotifications));
