import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { OutboundCondition } from 'models/OutboundCondition';
import './ConditionTag.scss';
import DatePicker from 'react-date-picker-gleap/dist/entry';
import { useClickedOutside, useEscape } from 'services/Helper';
import LinkButton from 'components/LinkButton/LinkButton';
import moment from 'moment';
import { gleapUserProperties } from 'components/ConditionPicker/ConditionPicker';

export const conditionIsValid = (condition: OutboundCondition) => {
  if (condition.data != null && condition.type && condition.matchOperator) {
    return true;
  }
  return false;
};

export const ConditionTag = ({
  className,
  value,
  onChange,
  onRemove,
  type = 'audience',
}: {
  className?: string;
  value: OutboundCondition;
  type?: 'audience' | 'trigger';
  onChange: (value: OutboundCondition) => void;
  onRemove: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  useEscape(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (value.initiallyOpened) {
      var newData = {
        ...value,
      };
      delete newData.initiallyOpened;
      onChange(newData);
      setIsOpen(true);
    }
  }, [value]);

  const calculateDays = (seconds) => {
    const number = parseInt(seconds);
    if (!isNaN(number)) {
      const days = Math.floor(value.data / (3600 * 24));
      return days;
    }
    return '';
  };

  const calculateSeconds = (days) => {
    const number = parseInt(days);
    if (!isNaN(number)) {
      return days * (3600 * 24);
    }
    return 0;
  };

  const getConditionDescription = () => {
    const resolveCountMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        is: 'is',
        isnot: 'is not',
        greaterthan: 'is greater than',
        lessthan: 'is less than',
      };
      return matchOperatorMap[matchOperator];
    };

    const resolveDateMatchOperator = (matchOperator) => {
      const matchOperatorMap = {
        after: 'after',
        before: 'before',
        on: 'on',
        greaterthan: 'more than',
        lessthan: 'less than',
        exactly: 'exactly',
      };
      return matchOperatorMap[matchOperator];
    };

    const isUserValue = value.event && value.event.includes('gleap-up-');

    if (value.matchOperator && value.data != null) {
      if (value.type === 'count') {
        return `${isUserValue ? 'value' : 'count'} ${resolveCountMatchOperator(
          value.matchOperator,
        )} ${value.data}`;
      }

      if (value.dateOperator === 'relative') {
        const days = calculateDays(value.data);

        if (type === 'trigger' && !isUserValue) {
          if (days === 0) {
            return `as the event occurs`;
          } else {
            return `${days} day${days === 1 ? '' : 's'} after the event occurs`;
          }
        } else if (type === 'trigger' && isUserValue && days === 0) {
          return `as the event occurs`;
        } else {
          const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
          return `${prefix}${resolveDateMatchOperator(
            value.matchOperator,
          )} ${days} day${days === 1 ? '' : 's'} ago`;
        }
      }

      if (value.dateOperator === 'absolute') {
        const prefix = value.type === 'firstoccured' ? '' : 'last occured ';
        return `${prefix}${resolveDateMatchOperator(
          value.matchOperator,
        )} ${moment(value.data).format('LL')}`;
      }
    }

    return '';
  };

  const getCurrentEventData = () => {
    var eventName = value.event;
    var eventIcon = 'bullseye-arrow';

    if (gleapUserProperties[eventName]) {
      eventName = gleapUserProperties[eventName].label;
      eventIcon = 'user';
    }

    return {
      eventName,
      eventIcon,
    };
  };

  const renderConditionName = () => {
    const { eventName, eventIcon } = getCurrentEventData();

    return (
      <div className="condition-name">
        <i className={`fa-solid fa-${eventIcon}`} />
        {eventName}
      </div>
    );
  };

  const renderRelativeDateContent = () => {
    const options = [
      {
        label: 'more than',
        value: 'greaterthan',
      },
      {
        label: 'less than',
        value: 'lessthan',
      },
      {
        label: 'exactly',
        value: 'exactly',
      },
    ];

    return (
      <div className="count-editor-container">
        <div className="count-editor-container-title">Relative</div>
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      var newVal = value.data;
                      if (!isNaN(parseInt(newVal)) || newVal instanceof Date) {
                        newVal = 0;
                      }
                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                        dateOperator: 'relative',
                        data: newVal,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value && (
                  <>
                    <div className="field-container">
                      <div className="input-wrapper">
                        <input
                          className="textinput-gray"
                          value={calculateDays(value.data)}
                          type="number"
                          onChange={(inputVal) => {
                            const number = parseInt(inputVal.target.value);
                            if (!isNaN(number) && number >= 0) {
                              onChange({
                                ...value,
                                data: calculateSeconds(number),
                              });
                            } else {
                              onChange({
                                ...value,
                                data: 0,
                              });
                            }
                          }}
                        />
                      </div>
                      <span>day{value.data === 1 ? '' : 's'} ago</span>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderRelativeTriggerDateContent = () => {
    const isUserValue = value.event && value.event.includes('gleap-up-');

    const options = isUserValue
      ? [
          {
            label: 'more than',
            value: 'greaterthan',
          },
          {
            label: 'less than',
            value: 'lessthan',
          },
          {
            label: 'on the date',
            value: 'exactly',
          },
        ]
      : [
          {
            label: 'after the event occurs',
            value: 'greaterthan',
          },
          {
            label: 'as the event occurs',
            value: 'exactly',
          },
        ];

    return (
      <div className="count-editor-container">
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      var newVal = value.data;
                      if (!isNaN(parseInt(newVal)) || newVal instanceof Date) {
                        newVal = 0;
                      }
                      if (option.value === 'exactly') {
                        newVal = 0;
                      }

                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                        dateOperator: 'relative',
                        data: newVal,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value &&
                  option.value !== 'exactly' && (
                    <>
                      <div className="field-container">
                        <div className="input-wrapper">
                          <input
                            className="textinput-gray"
                            value={calculateDays(value.data)}
                            type="number"
                            onChange={(inputVal) => {
                              const number = parseInt(inputVal.target.value);
                              if (!isNaN(number) && number >= 0) {
                                onChange({
                                  ...value,
                                  data: calculateSeconds(number),
                                });
                              } else {
                                onChange({
                                  ...value,
                                  data: 0,
                                });
                              }
                            }}
                          />
                        </div>
                        <span>
                          day{value.data === 1 ? '' : 's'}
                          {isUserValue ? ' ago' : ''}
                        </span>
                      </div>
                    </>
                  )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderAbsoluteDateContent = () => {
    const options = [
      {
        label: 'after',
        value: 'after',
      },
      {
        label: 'on',
        value: 'on',
      },
      {
        label: 'before',
        value: 'before',
      },
    ];

    return (
      <div className="count-editor-container mt-30">
        <div className="count-editor-container-title">Absolute</div>
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      var newVal = value.data;
                      if (!(newVal instanceof Date)) {
                        newVal = new Date();
                      }
                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                        dateOperator: 'absolute',
                        data: newVal,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value && (
                  <>
                    <DatePicker
                      placeholderText="Select date"
                      onChange={(selectedDate) => {
                        if (selectedDate) {
                          onChange({
                            ...value,
                            data: selectedDate,
                          });
                        }
                      }}
                      value={value.data ?? new Date()}
                      showLeadingZeros={true}
                      calendarIcon={<i className="fa-solid fa-calendar" />}
                    />
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDateEditorContent = () => {
    if (type === 'trigger') {
      return <>{renderRelativeTriggerDateContent()}</>;
    }

    return (
      <>
        {renderRelativeDateContent()}
        {renderAbsoluteDateContent()}
      </>
    );
  };

  const renderCountEditorContent = () => {
    const options = [
      {
        label: 'is',
        value: 'is',
      },
      {
        label: 'is not',
        value: 'isnot',
      },
      {
        label: 'greater than',
        value: 'greaterthan',
      },
      {
        label: 'less than',
        value: 'lessthan',
      },
    ];

    const isUserValue = value.event && value.event.includes('gleap-up-');

    return (
      <div className="count-editor-container">
        <div className="count-editor-container-title">
          {isUserValue ? 'Value' : 'Event count'}
        </div>
        <div className="count-editor-container-options">
          {options.map((option, index) => {
            return (
              <>
                <label
                  className="bb-feedback-multiplechoice-container"
                  key={`${option.value}-${index}`}
                >
                  <div className="text">{option.label}</div>
                  <input
                    type="radio"
                    name={`condition-${option.value}`}
                    checked={value.matchOperator === option.value}
                    onChange={() => {
                      onChange({
                        ...value,
                        matchOperator: option.value as any,
                      });
                    }}
                  />
                  <span className="bb-feedback-multiplechoice-checkmark" />
                </label>
                {value.matchOperator === option.value && (
                  <div className="field-container">
                    <div className="input-wrapper">
                      <input
                        className="textinput-gray"
                        value={value.data}
                        type="number"
                        onChange={(inputVal) => {
                          const number = parseInt(inputVal.target.value);
                          if (!isNaN(number)) {
                            onChange({
                              ...value,
                              data: number,
                            });
                          } else {
                            onChange({
                              ...value,
                              data: 0,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderTypeEditorContent = () => {
    const types = [
      {
        label: 'Count',
        value: 'count',
      },
      {
        label: 'First occured',
        value: 'firstoccured',
      },
      {
        label: 'Last occured',
        value: 'lastoccured',
      },
    ];
    return (
      <div className="type-editor-container">
        <div className="type-editor-container-title">Choose the event type</div>
        <div className="type-editor-container-buttons">
          {types.map((eventType, index) => {
            return (
              <LinkButton
                key={index}
                label={eventType.label}
                small
                onClick={() => {
                  onChange({
                    ...value,
                    type: eventType.value as any,
                  });
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderEditorModalContent = () => {
    const renderContent = () => {
      if (!value.type) {
        return renderTypeEditorContent();
      }
      if (value.type === 'count') {
        return renderCountEditorContent();
      }
      if (value.type === 'firstoccured' || value.type === 'lastoccured') {
        return renderDateEditorContent();
      }
    };

    return <div className="condition-editor">{renderContent()}</div>;
  };

  const isValid = conditionIsValid(value);
  const cardClassName = classNames(
    {
      'condition-tag': true,
      'condition-tag--trigger': type === 'trigger',
      'condition-tag--invalid': !isOpen && !isValid,
    },
    className,
  );

  return (
    <div className={cardClassName} ref={wrapperRef}>
      <div
        className="condition-tag--inner"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {renderConditionName()}
        <div className="condition-description">{getConditionDescription()}</div>
        <div className="condition-remove">
          <i
            className="fa-sharp fa-solid fa-xmark"
            onClick={() => {
              onRemove();
            }}
          ></i>
        </div>
      </div>
      <div
        className={`condition-tag-overlay ${
          isOpen && 'condition-tag-overlay--open'
        }`}
      >
        {renderEditorModalContent()}
      </div>
    </div>
  );
};
