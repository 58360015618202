import React, { useState } from 'react';
import TextInput from 'components/TextInput/TextInput';
import AvatarImage from 'components/AvatarImage/AvatarImage';
import { useForm } from 'react-hook-form';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { Organisation } from 'models/Organisation';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Swal from 'sweetalert2';
import 'styles/swal-theme.scss';
import { inject, observer } from 'mobx-react';
import './UpdateOrganisation.scss';
import camIcon from '../../../../assets/icons/cam.svg';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContainer from 'components/PageContainer/PageContainer';
import Loading from 'components/Loading/Loading';
import { runInAction } from 'mobx';
import LinkButton from 'components/LinkButton/LinkButton';

interface UpdateOrganisationProps {
  organisationStore?: OrganisationStore;
}
const UpdateOrganisation = ({ organisationStore }: UpdateOrganisationProps) => {
  const { register, handleSubmit, formState } = useForm();
  const [loading, setLoading] = useState(false);
  const organisation = organisationStore?.currentOrganisation;

  if (!organisation) {
    return null;
  }

  const saveCompany = async () => {
    await organisationStore?.updateOrganisation(organisation as Organisation);
  };

  const onSubmit = async () => {
    setLoading(true);
    await saveCompany();
    setLoading(false);
  };

  const generateErrorText = (type: string) => {
    if (type === 'maxLength') {
      return 'The maximal length of the input is reached.';
    }
    if (type === 'required') {
      return 'Input is required';
    }
    if (type === 'pattern') {
      return 'Wrong input';
    }
    return '';
  };

  const renderContent = () => {
    if (organisationStore.isLoadingOrgaUsers) {
      return <Loading />;
    }

    if (!organisationStore?.isOrganisationAdmin) {
      return (
        <div className="options-group">
          <div className="options-group-header">General settings</div>
          <div className="orga-page">
            {!organisation.picture && (
              <div className="image-placeholder">
                {organisation.name &&
                  organisation.name.charAt(0).toLocaleUpperCase()}
              </div>
            )}
            {organisation.picture && (
              <img src={organisation.picture} alt="projpicture" />
            )}
            <div className="danger-zone">
              <div className="danger-zone-container">
                <div className="danger-zone-item">
                  <div className="danger-zone-label">Leave this organization</div>
                  <LinkButton
                    className="danger-button"
                    label="Leave"
                    onClick={() => {
                      Swal.fire({
                        text: 'Do you really want to leave this organization?',
                        showCancelButton: true,
                        confirmButtonText: `Yes`,
                        denyButtonText: `No`,
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          await organisationStore!.leaveOrganisation(
                            organisation.id!,
                          );
                        }
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="options-group" key={organisation.id}>
        <div className="options-group-header">General settings</div>
        <AvatarImage
          image={
            organisation.picture && organisation.picture !== ''
              ? organisation.picture
              : camIcon
          }
          className={`${(!organisation.picture || organisation.picture === '') &&
            'placeholder-img'
            }`}
          editable
          afterImageUpload={(imageUrl) => {
            runInAction(() => {
              organisation.picture = imageUrl;
            });
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <TextInput
            placeholder="Company*"
            type="text"
            name="name"
            label="Company"
            className="mt-20 mb-20 text-input"
            required
            onChange={(text) => {
              runInAction(() => {
                organisation.name = text;
              });
            }}
            initalValue={organisation.name ?? ''}
            error={organisation.name !== '' ? '' : 'Name is required'}
          />
          <TextInput
            placeholder="E-Mail*"
            type="text"
            name="email"
            label="E-Mail"
            className="mt-20 text-input"
            required
            onChange={(text) => {
              runInAction(() => {
                organisation.email = text;
              });
            }}
            initalValue={organisation ? organisation.email : ''}
            inputRef={register('email', {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            error={
              formState.errors.email
                ? generateErrorText(formState.errors.email.type)
                : ''
            }
          />
          <PrimaryButton
            submit
            isLoading={loading}
            className="save-button mt-20"
            label="Save"
          />
        </form>
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="General" />
      <PageContent hasTitle isMediumBoxed>
        {renderContent()}
      </PageContent>
    </PageContainer>
  );
};

export default inject('organisationStore')(observer(UpdateOrganisation));
