import { ConditionTag } from 'components/ConditionTag/ConditionTag';
import { OutboundCondition } from 'models/OutboundCondition';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import './ConditionPicker.scss';

interface ConditionPickerProps {
    streamedEventKeys: string[];
    conditions: OutboundCondition[];
    onChange?: any;
    type?: 'audience' | 'trigger';
}

export const gleapUserProperties = {
    "gleap-up-createdAt": {
        label: "Signed up",
        eventType: "firstoccured",
        type: "calendar",
    },
    "gleap-up-lastActivity": {
        label: "Last seen",
        eventType: "lastoccured",
        type: "calendar",
    },
    "gleap-up-lastHeardFrom": {
        label: "Last heared from",
        eventType: "lastoccured",
        type: "calendar",
    },
    "gleap-up-value": {
        label: "Customer value",
        eventType: "count",
        type: "user",
    }
};

const ConditionPicker = ({ streamedEventKeys, conditions, onChange, type = 'trigger' }: ConditionPickerProps) => {
    const [internalConditions, setInternalConditions] = useState([] as any[]);
    const MAX_CONDITIONS = type === 'trigger' ? 1 : 3;

    useEffect(() => {
        setInternalConditions(conditions);
    }, [conditions]);

    const updateCondition = (index, condition: any) => {
        const newConditions = [...internalConditions];
        newConditions[index] = condition;
        setInternalConditions(newConditions);
        if (onChange) {
            onChange(newConditions);
        }
    }

    const addCondition = (eventName) => {
        var eventData = {};

        if (type === 'trigger') {
            eventData = {
                "type": "lastoccured",
            };
        }

        if (gleapUserProperties[eventName]) {
            eventData = {
                "type": gleapUserProperties[eventName].eventType
            };
        }

        const newConditions = [...internalConditions, {
            event: eventName,
            initiallyOpened: true,
            ...eventData,
        }];
        setInternalConditions(newConditions);
        if (onChange) {
            onChange(newConditions);
        }
    }

    const removeCondition = (index) => {
        var newArray = [...internalConditions];
        newArray.splice(index, 1);
        setInternalConditions(newArray);
        if (onChange) {
            onChange(newArray);
        }
    }

    const renderCreateCondition = () => {
        if (internalConditions.length > (MAX_CONDITIONS - 1)) {
            return null;
        }

        return (
            <Select
                className="dropdown-selection"
                classNamePrefix="dropdown-selection"
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                placeholder={internalConditions.length > 0 ? "+" : type === 'trigger' ? "+ Add event" : "+ Add audience filter"}
                components={{
                    SingleValue: (option: any) => {
                        if (!option.data.value) {
                            return (
                                <div className="outbound-select-event">
                                    + Select event
                                </div>
                            );
                        }
                        return <>{option.data.label}</>;
                    },
                    Option: (option: any) => {
                        const getIcon = () => {
                            if (option.data.type === "user") {
                                return "user";
                            } else if (option.data.type === "calendar") {
                                return "calendar";
                            } else {
                                return "bullseye-arrow";
                            }
                        }
                        return (
                            <div
                                className="option option-list-item"
                                onClick={() => {
                                    if (option.selectOption) {
                                        option.selectOption(option.data.value);
                                    }
                                }}
                            >
                                <i className={`fa-solid fa-${getIcon()}`} />
                                {option.data.label}
                            </div>
                        );
                    },
                }}
                onChange={(data: any) => {
                    if (data) {
                        addCondition(data);
                    }
                }}
                options={[...Object.keys(gleapUserProperties).map((key) => {
                    return {
                        ...gleapUserProperties[key],
                        value: key,
                    } as any;
                }), ...streamedEventKeys.map((data) => {
                    return {
                        label: data,
                        value: data,
                        type: "event",
                    };
                })]}
            />
        );
    }

    return (
        <div className='condition-picker'>
            <div
                className="condition-picker-conditions"
            >
                {internalConditions.map((condition, index) => {
                    const hasNext = internalConditions.length > index + 1;
                    return (
                        <>
                            <ConditionTag
                                type={type}
                                key={`condition-${condition.event}-${index}`}
                                className='mb-10'
                                value={condition}
                                onChange={(newValue) => {
                                    updateCondition(index, newValue);
                                }}
                                onRemove={() => {
                                    removeCondition(index);
                                }} />
                            {hasNext && <div className="condition-picker-conditions-operator">and</div>}
                        </>
                    );
                })}
                {renderCreateCondition()}
            </div>
        </div>
    );
};

export default ConditionPicker;
