/* eslint-disable prefer-destructuring */
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useParams } from 'react-router-dom';
import './ProjectFeedback.scss';
import Loading from 'components/Loading/Loading';
import ProjectFeedbackBoard from '../ProjectFeedbackBoard/ProjectFeedbackBoard';
import ProjectFeatureRequests from '../ProjectFeatureRequests/ProjectFeatureRequests';
import ProjectInbox from '../ProjectInbox/ProjectInbox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { ampli } from 'ampli';

interface ProjectFeedbackProps {
  projectStore?: ProjectStore;
  forceTypePath?: string;
}

const feedbackTypeComponents = {
  BOARD: ProjectFeedbackBoard,
  RATING: ProjectFeedbackBoard,
  CRASHREPORTSBOARD: ProjectFeedbackBoard,
  FEATUREREQUESTBOARD: ProjectFeatureRequests,
  INBOX: ProjectInbox,
};

const ProjectFeedback = ({ projectStore, forceTypePath }: ProjectFeedbackProps) => {
  const { feedbackTypePath } = useParams();

  useEffect(() => {
    if (projectStore?.currentProject) {
      if (forceTypePath) {
        projectStore!.setFeedbackTypeForPath(forceTypePath);
      } else if (feedbackTypePath) {
        projectStore!.setFeedbackTypeForPath(feedbackTypePath);
      } else {
        projectStore!.setFeedbackTypeForPath('bugs');
      }
    }
  }, [projectStore?.currentProject, feedbackTypePath, forceTypePath]);

  const viewType = projectStore?.currentFeedbackType?.renderType ?? 'BOARD';
  const RenderComponent = feedbackTypeComponents[viewType];

  useEffect(() => {
    if (viewType) {
      ampli.boardViewed({
        boardType: viewType
      });
    }
  }, [viewType]);

  if (!projectStore?.currentFeedbackType || !RenderComponent) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  return <RenderComponent key={viewType} />;
};

export default inject('projectStore')(observer(ProjectFeedback));
