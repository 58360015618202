import FeedbackPriorityFilter from 'components/FeedbackStatusFilter/FeedbackPriorityFilter';
import FeedbackTagFilter from 'components/FeedbackTagFilter/FeedbackTagFilter';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BoardFilter.scss';

interface BoardFilterProps {
  filtersToShow?: string[];
  projectStore?: ProjectStore;
}

const BoardFilter = ({
  filtersToShow = [],
  projectStore,
}: BoardFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const feedbackTypePath = projectStore?.currentFeedbackType?.path ?? "inquiries";

  const currentTicketDataFilter = projectStore?.currentTicketDataFilter;

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowFilter(false);
  });
  useEscape(() => {
    setShowFilter(false);
  });

  useEffect(() => {
    try {
      if (feedbackTypePath && projectStore?.currentProject?.id) {
        const prioFilter = localStorage.getItem(
          `priorityFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
        );
        if (prioFilter) {
          currentTicketDataFilter.priority = prioFilter;
        }

        const assiFilter = localStorage.getItem(
          `assigneeFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
        );
        if (assiFilter) {
          currentTicketDataFilter.processingUser = assiFilter;
        }

        const feedbackTagFilter = localStorage.getItem(
          `tagFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
        );
        if (feedbackTagFilter) {
          currentTicketDataFilter.tags = JSON.parse(feedbackTagFilter);
        }

        const countryFilterStored = localStorage.getItem(
          `countryFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
        );
        if (countryFilterStored) {
          currentTicketDataFilter.country = countryFilterStored;
        }
      }

      runInAction(() => {
        projectStore!.currentTicketDataFilter = { ...currentTicketDataFilter };
      });
      // eslint-disable-next-line no-empty
    } catch (exp) { }
  }, [
    feedbackTypePath,
    projectStore?.currentProject,
    projectStore?.currentFeedbackType,
  ]);

  useEffect(() => {
    projectStore?.filterTicketsData();
  }, [currentTicketDataFilter]);

  const isActive = Object.keys(currentTicketDataFilter).length > 0;

  return (
    <div className="board-filter" ref={wrapperRef}>
      <div
        className="board-filter-button"
        onClick={() => {
          setShowFilter(!showFilter);
        }}
      >
        <i className="fa-solid fa-filter" />
        <span>Filter</span>
        {isActive && <div className="board-filter-active-indicator" />}
      </div>
      {showFilter && (
        <div className="board-filter-selection">
          <FeedbackPriorityFilter
            value={currentTicketDataFilter.priority}
            onValueChanged={(value) => {
              try {
                if (value && value.length > 0) {
                  currentTicketDataFilter.priority = value;

                  localStorage.setItem(
                    `priorityFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                    value,
                  );
                } else {
                  delete currentTicketDataFilter.priority;

                  localStorage.removeItem(
                    `priorityFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                  );
                }
                // eslint-disable-next-line no-empty
              } catch (exp) { }

              runInAction(() => {
                projectStore!.currentTicketDataFilter = {
                  ...currentTicketDataFilter,
                };
              });
            }}
          />
          <div className='mt-5'>
            <FeedbackTagFilter
              value={currentTicketDataFilter.tags}
              onValueChanged={(value: any) => {
                try {
                  // eslint-disable-next-line no-underscore-dangle
                  if (value && value._id && value._id.length > 0) {
                    currentTicketDataFilter.tags = value;

                    localStorage.setItem(
                      `tagFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                      // eslint-disable-next-line no-underscore-dangle
                      JSON.stringify(value),
                    );
                  } else {
                    delete currentTicketDataFilter.tags;

                    localStorage.removeItem(
                      `tagFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                    );
                  }
                  // eslint-disable-next-line no-empty
                } catch (exp) { }

                runInAction(() => {
                  projectStore!.currentTicketDataFilter = {
                    ...currentTicketDataFilter,
                  };
                });
              }}
            />
          </div>
          <div className='mt-5'>
            <FeedbackUserFilter
              value={currentTicketDataFilter.processingUser}
              onValueChanged={(value) => {
                try {
                  if (value && value.length > 0) {
                    currentTicketDataFilter.processingUser = value;

                    localStorage.setItem(
                      `assigneeFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                      value,
                    );
                  } else {
                    delete currentTicketDataFilter.processingUser;

                    localStorage.removeItem(
                      `assigneeFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                    );
                  }
                  // eslint-disable-next-line no-empty
                } catch (exp) { }

                runInAction(() => {
                  projectStore!.currentTicketDataFilter = {
                    ...currentTicketDataFilter,
                  };
                });
              }}
            />
          </div>
          {/* <FeedbackCountryFilter
            value={currentTicketDataFilter.country}
            onValueChanged={(value) => {
              try {
                if (value) {
                  currentTicketDataFilter.country = value;

                  localStorage.setItem(
                    `countryFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                    value,
                  );
                } else {
                  delete currentTicketDataFilter.country;

                  localStorage.removeItem(
                    `countryFilter_${projectStore?.currentProject?.id}_${feedbackTypePath}`,
                  );
                }
              } catch (_) {}

              projectStore!.currentTicketDataFilter = {
                ...currentTicketDataFilter,
              };
            }}
          /> */}
        </div>
      )}
    </div>
  );
};

export default inject('projectStore')(observer(BoardFilter));
