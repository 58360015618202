import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import RichTextEditor, {
  RichTextEditorRefProps,
} from 'components/Editors/RichTextEditor/RichTextEditor';
import SendInputAttachmentUpload from 'components/SendInputAttachmentUpload/SendInputAttachmentUpload';
import TabButton from 'components/TabButton/TabButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { getSessionName } from 'services/GuestNameHelper';
import { isMacintosh } from 'services/Helper';
import './SendInputEditor.scss';
import SendInputMacros from 'components/SendInputMacros/SendInputMacros';
import PerformAIAction from 'components/PerformAIAction/PerformAIAction';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import PerformHelpcenterAction from 'components/PerformHelpcenterAction/PerformHelpcenterAction';

interface SendInputEditorProps {
  inputTypes?: string[];
  isSending?: boolean;
  shared?: boolean;
  currentInputType?: string;
  inputTypeChanged?: any;
  inputContentChanged?: any;
  inputPlaceholder?: string;
  onClickSend?: any;
  allowSend?: boolean;
  onFilesSelected?: (any) => void;
  attachmentsRef?: any;
  mentions?: {
    id: string;
    label: string;
    email?: string;
    profileImageUrl?: string;
  }[];
  hideTabButtons?: boolean;
  showAttachments: boolean;
  attachments?: any;
  attachmentsUpdated?: (any) => void;
  currentlyTyping?: any;
  draftId?: string;
}

const SendInputEditor = forwardRef(
  (
    {
      inputTypes = [],
      isSending,
      shared = false,
      currentInputType,
      inputTypeChanged,
      inputContentChanged,
      inputPlaceholder,
      onClickSend,
      allowSend = true,
      mentions = [],
      attachmentsRef,
      onFilesSelected,
      showAttachments = true,
      hideTabButtons = false,
      attachments = [],
      attachmentsUpdated,
      currentlyTyping,
      draftId,
    }: SendInputEditorProps,
    ref,
  ) => {
    const editorRef = useRef<RichTextEditorRefProps>(null);
    const [canSend, setCanSend] = useState(false);

    // Load draft.
    var initialContentFromDraft = {} as any;
    try {
      if (draftId) {
        const draft = localStorage.getItem(draftId);
        if (draft) {
          const jsonDraft = JSON.parse(draft);
          if (jsonDraft) {
            initialContentFromDraft = jsonDraft;
          }
        }
      }
    } catch (exp) {}

    const [currentContent, setCurrentContent] = useState(
      initialContentFromDraft,
    );

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [canSend]);

    useImperativeHandle(ref, () => ({
      updateContent(content: any) {
        setCurrentContent(content);
        const editor: any = editorRef.current;
        try {
          if (editor) {
            editor.updateContent(content);
          }
        } catch (exp) {}
      },
    }));

    const buildTabButtons = () => {
      if (shared) {
        return null;
      }

      if (inputTypes.length === 0 || hideTabButtons) {
        return <></>;
      }
      return (
        <div className="tab-buttons">
          {inputTypes.map((inputType: string) => {
            return (
              <TabButton
                key={inputType}
                active={inputType === currentInputType}
                label={inputType}
                onClick={() => {
                  inputTypeChanged(inputType);
                }}
              />
            );
          })}
        </div>
      );
    };

    const insertHelpCenterArticle = (article: any) => {
      const editor: any = editorRef.current;

      if (editor) {
        editor.insertContent({
          type: 'helpCenterArticle',
          attrs: {
            articleId: article.id,
            articleTitle: article.title,
            articleDescription: article.description,
            articleUrl: article.url,
          },
        });
      }
    };

    const buildAttachmentOptions = () => {
      if (!showAttachments || shared) {
        return <div />;
      }

      return (
        <div className="attachment-row">
          <SendInputAttachmentUpload
            ref={attachmentsRef}
            onFilesSelected={onFilesSelected}
            attachments={attachments}
            attachmentsUpdated={attachmentsUpdated!}
          />
          <SendInputMacros
            onTextPaste={(content) => {
              if (editorRef.current) {
                editorRef.current!.insertText(content);
              }
            }}
          />
          <PerformHelpcenterAction
            onArticleSelected={insertHelpCenterArticle}
          />
          <PerformAIAction
            currentText={currentContent}
            onTextPaste={(content) => {
              if (editorRef.current) {
                editorRef.current!.insertText(content);
              }
            }}
            onTextReplace={(content) => {
              if (editorRef.current) {
                editorRef.current!.clearContent();
                editorRef.current!.insertText(content);
              }
            }}
          />
        </div>
      );
    };

    const sendMessage = () => {
      if (
        !canSend &&
        !allowSend &&
        (!attachments || attachments.length === 0)
      ) {
        return;
      }

      const json = editorRef.current!.getJSON();
      onClickSend(json);
      if (editorRef.current) {
        editorRef.current!.clearContent();
      }
      setCanSend(false);
    };

    const buildSendOptions = () => {
      return (
        <div
          data-for="sendButtonTooltip"
          data-tip={currentInputType === 'Note' ? 'Add note' : 'Send'}
        >
          <PrimaryButton
            label={currentInputType === 'Note' ? 'Add note' : 'Send'}
            className="send-button"
            disabled={
              (!canSend || !allowSend) &&
              (!attachments || attachments.length === 0)
            }
            onClick={() => {
              if (!onClickSend) {
                return;
              }

              sendMessage();
            }}
          />
        </div>
      );
    };

    const buildActionRow = () => {
      return (
        <div className="action-row">
          {buildAttachmentOptions()}
          {buildSendOptions()}
        </div>
      );
    };

    const renderCurrentlyTyping = () => {
      if (!currentlyTyping) {
        return <></>;
      }

      if (currentlyTyping?.session) {
        return (
          <div className="currently-typing">
            <UserAvatar small email={currentlyTyping?.session?.email} />
            <div className="currently-typing-text">
              <span>{getSessionName(currentlyTyping?.session)}</span> is
              typing...
            </div>
          </div>
        );
      }

      return (
        <div className="currently-typing">
          <UserAvatar small imageUrl={currentlyTyping?.user?.profileImageUrl} />
          <div className="currently-typing-text">
            <span>{currentlyTyping?.user?.firstName}</span> is typing...
          </div>
        </div>
      );
    };

    return (
      <div
        className={`send-input-editor-container send-input-editor-container--${currentInputType?.toLocaleLowerCase()}`}
      >
        {renderCurrentlyTyping()}
        {buildTabButtons()}
        <div className="editor-container">
          <RichTextEditor
            ref={editorRef}
            showToolbar={!shared}
            mentions={mentions}
            content={currentContent}
            onFilesSelected={onFilesSelected}
            emptyStateChanged={(isEmpty) => {
              if (isEmpty) {
                setCanSend(false);
              } else {
                setCanSend(true);
              }
            }}
            sendMessage={() => {
              sendMessage();
            }}
            inputContentChanged={(content) => {
              setCurrentContent(content);
              inputContentChanged(content);
            }}
            placeholder={inputPlaceholder}
          />
        </div>
        {buildActionRow()}
        {isSending && (
          <div className="send-input-editor-container--sending">
            <LoadingAnimationSmall />
          </div>
        )}
        <ReactTooltip
          id="sendButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={500}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div className="send-key-tooltip">
                <span>{content}</span>
                <div className="hotkey-help">
                  {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                  <div>Enter</div>
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  },
);

export default SendInputEditor;
