import { ampli } from 'ampli';
import classNames from 'classnames';
import BugAssignUser from 'components/BugAssignUser/BugAssignUser';
import BugDataSelection from 'components/BugDataSelection/BugDataSelection';
import BugDuplicates from 'components/BugDuplicates/BugDuplicates';
import BugStatusSelection from 'components/BugStatusSelection/BugStatusSelection';
import BugTypeSelection from 'components/BugTypeSelection/BugTypeSelection';
import CreateableSelectDropdown from 'components/CreateableSelectDropdown/CreateableSelectDropdown';
import DueToDataSelection from 'components/DueToDataSelection/DueToDataSelection';
import FeatureRequestStatusSelection from 'components/FeatureRequestStatusSelection/FeatureRequestStatusSelection';
import LinkButton from 'components/LinkButton/LinkButton';
import RecentEvents from 'components/RecentEvents/RecentEvents';
import SessionDetailsShort from 'components/SessionDetailsShort/SessionDetailsShort';
import SidebarFoldable from 'components/SidebarFoldable/SidebarFoldable';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Gleap from 'gleap';
import { getValidIntegrations } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { isMacintosh, useHotkey } from 'services/Helper';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import 'styles/swal-theme.scss';
import Swal from 'sweetalert2';
import Comments from './Comments';
import './Details.scss';

interface DetailsProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  shared?: boolean;
  isInbox?: boolean;
  showComments?: boolean;
}

const Details = ({
  bugStore,
  modalStore,
  projectStore,
  shared,
  isInbox = false,
  showComments = true,
}: DetailsProps) => {
  const bug = bugStore!.currentBug;
  const isFeatureRequest = bug?.type === 'FEATURE_REQUEST';
  const navigate = useNavigate();
  const [actionIsLoading, setActionIsLoading] = useState(false);
  const [restoreIsLoading, setRestoreIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    try {
      const showTicketHistory = localStorage.getItem('show-ticket-history');
      if (showTicketHistory === 'true') {
        setShowHistory(true);
      } else {
        setShowHistory(false);
      }
    } catch (exp) { }
  });

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    };
  });

  const archiveBug = async () => {
    setActionIsLoading(true);
    await bugStore?.archiveBug(bugStore?.currentBug?.id!);
    setActionIsLoading(false);

    ampli.ticketArchived({
      bugId: bugStore?.currentBug?.id!,
    });
  };

  useHotkey(79, () => {
    archiveBug();
  });

  const renderStatusSelection = () => {
    if (isInbox || bug?.type === 'INQUIRY') {
      return <></>;
    }

    if (isFeatureRequest) {
      return (
        <div className="feedback-selection-row">
          <div className="feedback-selection-row-label">Status</div>
          <div className="feedback-selection-row-checkbox">
            <FeatureRequestStatusSelection shared={shared} />
          </div>
        </div>
      );
    }

    return (
      <div className="feedback-selection-row">
        <div className="feedback-selection-row-label">Status</div>
        <div className="feedback-selection-row-checkbox">
          <BugStatusSelection />
        </div>
      </div>
    );
  };

  const renderIntegrationLinks = () => {
    if (!bug?.integrations) {
      return <></>;
    }

    const integrationList = getValidIntegrations({
      feedbackItem: bug,
      project: projectStore?.currentProject,
    });
    if (!integrationList || integrationList.length === 0) {
      return <></>;
    }

    return (
      <div className="integrations">
        {integrationList.map((integration, index) => (
          <div
            className="integration"
            onClick={async () => {
              window.open(integration.url);
            }}
          >
            <span className="integration-title">{integration.label}</span>
            {integration.name && integration.name.length > 0 && (
              <span className="integration-description">
                {integration.name}
              </span>
            )}
            <i className="fa-solid fa-square-up-right"></i>
          </div>
        ))}
      </div>
    );
  };

  const commentsClassName = classNames(
    {
      'hide-on-mobile': !showComments,
    },
    'row left',
  );

  const detailsClassName = classNames(
    {
      'force-display': !showComments,
    },
    'row right',
  );

  const renderSidebar = () => {
    if (!bug) {
      return (
        <div className={detailsClassName}>
          <div className="sidebar-loading">
            {[
              [65, 100],
              [55, 110],
              [45, 90],
              [40, 105],
              [55, 120],
              [40, 95],
            ].map((w, index) => {
              return (
                <div className="sidebar-loading-item" key={index}>
                  <PublicSkeleton
                    width={w[0]}
                    height={12}
                    count={1}
                    style={{
                      borderRadius: '5px',
                      marginRight: `${65 - w[0] + 30}px`,
                    }}
                  />
                  <PublicSkeleton
                    width={13}
                    height={13}
                    count={1}
                    style={{
                      borderRadius: '100%',
                      marginRight: '10px',
                    }}
                  />
                  <PublicSkeleton
                    width={w[1]}
                    height={13}
                    count={1}
                    style={{
                      borderRadius: '5px',
                    }}
                  />
                </div>
              );
            })}
          </div>
          {[120, 110, 80, 120, 110].map((w, index) => {
            return (
              <div className="sidebar-loading-item-foldable" key={index}>
                <PublicSkeleton
                  width={w}
                  height={13}
                  count={1}
                  style={{
                    borderRadius: '5px',
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    }

    const shareURL = `${window.location.origin}/share/${bug?.shareToken
      }?token=${bug?.secretShareToken ?? ''}`;

    return (
      <div className={detailsClassName}>
        {shared ? (
          <div className="feedback-actions-box">
            {bug.session && (
              <div className="static-assigned-user">
                <UserAvatar
                  small
                  email={bug.processingUser?.email}
                  imageUrl={bug.processingUser?.profileImageUrl}
                />
                {bug.processingUser
                  ? `${bug.processingUser?.firstName} ${bug.processingUser?.lastName}`
                  : 'Not assigned'}
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="feedback-actions-box">
              <div className="feedback-selection-row">
                <div className="feedback-selection-row-label">Assignee</div>
                <div className="feedback-selection-row-checkbox">
                  <BugAssignUser />
                </div>
              </div>
              <div className="feedback-selection-row">
                <div className="feedback-selection-row-label">Priority</div>
                <div className="feedback-selection-row-checkbox">
                  <BugDataSelection />
                </div>
              </div>
              {renderStatusSelection()}
              <div className="feedback-selection-row">
                <div className="feedback-selection-row-label">Type</div>
                <div className="feedback-selection-row-checkbox">
                  <BugTypeSelection
                    value={bug.type}
                    onValueChanged={(selected) => {
                      let newStatus = 'OPEN';
                      if (selected.options && selected.options.possibleLanes) {
                        newStatus = selected.options.possibleLanes[0].key;
                      }
                      bugStore!.updateBug(bug.id, {
                        type: selected.value,
                        status: newStatus,
                      });

                      ampli.ticketTypeChanged({
                        bugId: bug.id,
                        name: newStatus,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="feedback-selection-row">
                <div className="feedback-selection-row-label">{isFeatureRequest ? 'ETA' : 'Due on'}</div>
                <div className="feedback-selection-row-checkbox">
                  <DueToDataSelection placeholder={isFeatureRequest ? 'Select ETA' : 'Select due date'} />
                </div>
              </div>
              {!shared &&
                bug &&
                bug.metaData &&
                bug.metaData.lastScreenName && (
                  <div className="feedback-selection-row mt-10">
                    <div className="feedback-selection-row-label">View</div>
                    <div className="feedback-selection-row-content">
                      {bug.metaData.lastScreenName}
                    </div>
                  </div>
                )}
              {!shared && bug && bug.metaData && bug.metaData.currentUrl && (
                <div className="feedback-selection-row mt-10">
                  <div className="feedback-selection-row-label">URL</div>
                  <div className="feedback-selection-row-content">
                    <a
                      href={bug.metaData.currentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {bug.metaData.currentUrl}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {!shared && (
          <SidebarFoldable title="Integrations" infoKey="integrations">
            <LinkButton
              className="bfw"
              icon="puzzle"
              label="Send to integration"
              iconSideRight={false}
              onClick={() => {
                const planIsEntitled = projectStore?.isFeatureInPlan(
                  Feature.INTEGRATIONS,
                  ['some', 'all'],
                );
                if (planIsEntitled) {
                  modalStore!.openModal(
                    MODALTYPE.MANUALLY_SEND_INTEGRATION,
                    {},
                    true,
                  );
                } else {
                  modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
                    projectId: projectStore?.currentProject?.id,
                    type: 'forwardtointegrations',
                  });

                  ampli.manuallyForwardToIntegrationModalOpened({
                    bugId: bugStore?.currentBug?.id!,
                  });
                }
              }}
            />
            {renderIntegrationLinks()}
          </SidebarFoldable>
        )}
        {bug.session && (
          <SidebarFoldable
            canToggle={!shared}
            className={`${shared && 'no-border-top'}`}
            title={isFeatureRequest ? "Requested by" : "Contact data"}
            infoKey="userdata"
            defaultOpened={true}
          >
            <SessionDetailsShort
              shared={shared}
              session={bug.session}
              onHeaderClicked={() => {
                if (!shared) {
                  navigate(
                    `/projects/${bug.project}/sessions/${bug.session?.id}`,
                  );
                  modalStore!.closeModal(false);
                }
              }}
            />
          </SidebarFoldable>
        )}
        {bug.session && !shared && (
          <SidebarFoldable
            canToggle={!shared}
            title="Recent events"
            infoKey="recentevents"
          >
            <RecentEvents contactId={bug.session.id} />
          </SidebarFoldable>
        )}
        {!shared && (
          <SidebarFoldable
            title="Tags"
            infoKey="tags"
            tag={(bug?.tags?.length ?? 0) > 0 ? `${bug?.tags?.length}` : ''}
          >
            <CreateableSelectDropdown
              disabled={shared}
              showBorder={false}
              shared={shared}
              selectedItem={
                bug?.tags
                  ? bug?.tags!.map((tagItem: string) => {
                    return {
                      label: tagItem,
                      value: tagItem,
                      color:
                        projectStore!.currentProject?.feedbackTags.find(
                          (item) => item.label === tagItem,
                        )?.color ?? '#060d25',
                    };
                  })
                  : []
              }
              items={
                projectStore?.currentProject?.feedbackTags
                  ? projectStore?.currentProject?.feedbackTags!.map(
                    (tagItem) => {
                      return {
                        label: tagItem.label,
                        value: tagItem.label,
                        color: tagItem.color ?? '#060d25',
                      };
                    },
                  )
                  : []
              }
              onChange={(selectedItems: any[]) => {
                const tags = selectedItems
                  ? selectedItems.map((item) => item.value)
                  : [];
                bugStore!.handleTags(tags);

                ampli.tagChanged({
                  tags,
                });
              }}
            />
          </SidebarFoldable>
        )}
        {!shared && bug && <BugDuplicates bug={bug} />}
        {!shared && bug && (
          <SidebarFoldable title="Actions" infoKey="actions">
            <div className="mb-10">
              <div className="button-group">
                {!bug.archived &&
                  (!bug.duplicateOf || bug.duplicateOf === '') && (
                    <>
                      <ReactTooltip
                        id="archiveButtonTooltip"
                        className="infoTooltip infoTooltipButton"
                        delayHide={500}
                        type="light"
                        effect="solid"
                        getContent={(content) => {
                          return (
                            <div className="send-key-tooltip">
                              <span>{content}</span>
                              <div className="hotkey-help">
                                {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                                <div>Shift</div>
                                <div>O</div>
                              </div>
                            </div>
                          );
                        }}
                      />
                      <div
                        style={{
                          width: '100%',
                        }}
                        data-for="archiveButtonTooltip"
                        data-tip={'Archive'}
                      >
                        <LinkButton
                          className="bfw"
                          icon="box-archive"
                          label="Archive"
                          isLoading={actionIsLoading}
                          iconSideRight={false}
                          onClick={async () => {
                            archiveBug();
                          }}
                        />
                      </div>
                    </>
                  )}
                {bug.archived &&
                  (!bug.duplicateOf || bug.duplicateOf === '') && (
                    <LinkButton
                      className="bfw"
                      icon="rotate-left"
                      label="Restore"
                      isLoading={restoreIsLoading}
                      iconSideRight={false}
                      onClick={async () => {
                        setRestoreIsLoading(true);
                        await bugStore?.unarchiveBug(bugStore?.currentBug?.id!);
                        setRestoreIsLoading(false);
                        modalStore!.closeModal();

                        ampli.ticketRestored({
                          bugId: bugStore?.currentBug?.id ?? '',
                        });
                      }}
                    />
                  )}
              </div>
            </div>
            <CopyToClipboard
              text={shareURL}
              onCopy={() => {
                ampli.shareLinkCopied({
                  bugId: bug?.id,
                });
                toast.success('Successfully copied ✓');
              }}
            >
              <LinkButton
                label="Copy share link"
                className="bfw mb-10"
                noBorder
                iconSideRight={false}
                icon="share-from-square"
                onClick={() => { }}
              />
            </CopyToClipboard>
            <LinkButton
              label={showHistory ? 'Hide history' : 'Show history'}
              className="bfw"
              noBorder
              iconSideRight={false}
              icon={showHistory ? 'eye-slash' : 'eye'}
              onClick={() => {
                try {
                  localStorage.setItem(
                    'show-ticket-history',
                    showHistory ? 'false' : 'true',
                  );
                } catch (e) { }
                setShowHistory(!showHistory);
              }}
            />
            <div className="mt-10 mb-20">
              <LinkButton
                className="bfw"
                icon="trash"
                label="Delete"
                isLoading={deleteIsLoading}
                iconSideRight={false}
                onClick={async () => {
                  Swal.fire({
                    text: 'Do you really want to delete this ticket?',
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                    denyButtonText: `No`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      setDeleteIsLoading(true);
                      await bugStore?.deleteBug(bugStore?.currentBug?.id!);
                      setDeleteIsLoading(false);
                      modalStore!.closeModal();

                      ampli.ticketDeleted({
                        bugId: bugStore?.currentBug?.id!,
                      });
                    }
                  });
                }}
              />
            </div>
          </SidebarFoldable>
        )}
      </div>
    );
  };

  return (
    <div className="details-container">
      <div className={commentsClassName}>
        <Comments
          key={bugStore?.currentBug?.id}
          shared={shared}
          showHistory={showHistory}
          isInbox={isInbox}
        />
      </div>
      {renderSidebar()}
    </div>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
)(observer(Details));
