import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { ModalStore } from 'stores/private/ModalStore';
import './RatingActionEditor.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';

interface RatingActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const RatingActionEditor = ({
  action,
  modalStore,
  projectStore,
}: RatingActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  return (
    <div className="rating-action-editor">
      <PageContainer>
        <PageHeadLine title="Rate conversation">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="text mb-20">
            The rate conversation action sends a CSAT (emoji) rating to the
            customer. The rating will then be applied to the conversation rating
            for this conversation.
          </div>
          <TextInput
            className="mr-10 mt-30"
            name="question-value"
            label="Question"
            initalValue={getProperty(
              action,
              `title.localized.${currentLang}`,
            )}
            placeholder=""
            type="text"
            error=""
            onChange={(val) => {
              runInAction(() => {
                setProperty(action, `title.localized.${currentLang}`, val);
              });
            }}
          />
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(RatingActionEditor));
