import { makeAutoObservable } from 'mobx';

// eslint-disable-next-line import/prefer-default-export
export class OnBoardingStore {
  selected = 0;
  increseSlide = (): void => {
    this.selected += 1;
  };

  decreseSlide = (): void => {
    this.selected -= 1;
  };

  constructor() {
    makeAutoObservable(this)
  }
}