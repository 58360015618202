import React from 'react';
import './InvitePeopleModal.scss';
import InvitationBlock from 'components/InvitationBlock/InvitationBlock';

const InvitePeopleModal = () => {
  return (
    <div>
      <InvitationBlock />
    </div>
  );
};

export default InvitePeopleModal;
