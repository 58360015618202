import React from 'react';
import './DashboardButton.scss';
import { useNavigate } from 'react-router';
import dashboardIcon from '../../assets/icons/np_back-arrow_938966_FFFFFF.svg';

interface DashboardButtonProps {
  showLabel?: boolean;
  isHeader?: boolean;
}

const DashboardButton = ({
  showLabel = false,
  isHeader = false,
}: DashboardButtonProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={`dashboard-button ${showLabel && 'dashboard-button--label'} ${
        isHeader && 'dashboard-button--header'
      }`}
      onClick={() => {
        navigate('/dashboard');
      }}
    >
      <div className="actionbutton">
        <img src={dashboardIcon} alt="Show dashboard" />
      </div>
      {showLabel && <div className="title">Dashboard</div>}
    </div>
  );
};

export default DashboardButton;
