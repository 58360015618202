import './LoadingAnimationSmall.scss';

const LoadingAnimationSmall = () => {
  return (
    <div className="spring-spinner-small">
      <div className="spring-spinner-small-part top">
        <div className="spring-spinner-small-rotator" />
      </div>
      <div className="spring-spinner-small-part bottom">
        <div className="spring-spinner-small-rotator" />
      </div>
    </div>
  );
};

export default LoadingAnimationSmall;
