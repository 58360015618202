import { ampli } from 'ampli';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Switch from 'react-switch';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AIAssistProjectSettings.scss';

interface AIAssistProjectSettingsProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const AIAssistProjectSettings = ({
  projectStore,
  organisationStore,
}: AIAssistProjectSettingsProps) => {
  const project = projectStore!.currentProject;

  const [loading, setLoading] = useState(false);
  const [generateAiTitle, setGenerateAiTitle] = useState(false);
  const [generateBotAiSummary, setGenerateBotAiSummary] = useState(false);

  useEffect(() => {
    if (project) {
      setGenerateAiTitle(
        project!.generateAiTitle,
      );
      setGenerateBotAiSummary(
        project!.generateBotAiSummary,
      )
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="AI assist settings" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        <div className="options-group">
          <div className="options-group-header">Automatically generate titles</div>
          <div className="text mt-10">
            Use AI assist to automatically generate titles for incoming bug reports. Please note that generating one title will consume approximately 200 AI tokens.
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setGenerateAiTitle(checked);
                  }}
                  checked={generateAiTitle}
                />
                <span>
                  Enable
                </span>
              </div>
            </div>
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  generateAiTitle,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) { }

              setLoading(false);
            }}
            label="Save"
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">Automatically summarize bot conversations</div>
          <div className="text mt-10">
            Use AI assist to automatically summarize bot conversations before they get assigned to a support agent.
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setGenerateBotAiSummary(checked);
                  }}
                  checked={generateBotAiSummary}
                />
                <span>
                  Enable
                </span>
              </div>
            </div>
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  generateBotAiSummary,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) { }

              setLoading(false);
            }}
            label="Save"
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(AIAssistProjectSettings));
