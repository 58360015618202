import * as React from 'react';
import './SessionDetails.scss';
import { Session } from 'models/Session';
import TimeAgo from 'react-timeago';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import currency from 'currency.js';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';
import { getCountryByCode } from 'services/CountryHelper';
import moment from 'moment';

interface SessionDetailsProps {
  title: string;
  session?: Session;
}

const SessionDetails = ({ title, session }: SessionDetailsProps) => {
  if (!session) {
    return <></>;
  }

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const isOnline = (Date.now() - Date.parse(session.lastActivity)) / 60000 < 2;

  const renderCustomData = () => {
    if (!session.customData) {
      return null;
    }

    return Object.keys(session.customData).map((key) => {
      const data = session.customData[key] ?? 'Unknown';
      return (
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">{key}</div>
          <div className="contact-details-card-value-row-value">
            {`${data}`}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="contact-details-card card contact-details-container">
      <div className="contact-details-header-avatar">
        <UserAvatar isOnline={isOnline} email={session.email ? session.email : session.gleapId} />
        <div className="data">
          <div className="title">
            {cleanupName(getSessionName(session), 20)}
          </div>
          <div className="subtitle">
            <TimeAgo date={session.lastActivity} />
          </div>
        </div>
      </div>
      <div className="contact-details-card-rows">
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">Type</div>
          <div className="contact-details-card-value-row-value">
            {session.userId ? (
              <div className="type-tag">
                <i className="fa-solid fa-shield-check"></i>
              </div>
            ) : (
              <div className="type-tag type-tag--guest">
                <i className="fa-regular fa-shield"></i>
              </div>
            )}
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">User id</div>
          <div className="contact-details-card-value-row-value">
            {session.userId ?? 'Not set'}
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">Name</div>
          <div className="contact-details-card-value-row-value capitalize">
            {getSessionName(session) ?? 'Unknown'}
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">Email</div>
          <div className="contact-details-card-value-row-value">
            {session.email ?? 'Unknown'}
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">Language</div>
          <div className="contact-details-card-value-row-value">
            {session.lang ?? 'Unknown'}
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">Phone</div>
          <div className="contact-details-card-value-row-value">
            {session.phone ?? 'Unknown'}
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">Value</div>
          <div className="contact-details-card-value-row-value">
            <span data-for="infoTooltip">
              {session.value ? currency(session.value).format() : "Not set"}
            </span>
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">
            Signed up
          </div>
          <div className="contact-details-card-value-row-value">
            <TimeAgo date={session.createdAt} />
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">
            Last activity
          </div>
          <div className="contact-details-card-value-row-value">
            <TimeAgo date={session.lastActivity} />
          </div>
        </div>
        {session.unsubscribed && session.unsubscribedAt && (
          <div className="contact-details-card-value-row">
            <div className="contact-details-card-value-row-key">Unsubscribed</div>
            <div className="contact-details-card-value-row-value">
              {moment(session.unsubscribedAt).format('LLL')}
            </div>
          </div>
        )}
        {session.lastPageView && (
          <div className="contact-details-card-value-row">
            <div className="contact-details-card-value-row-key">
              Last pageview
            </div>
            <div className="contact-details-card-value-row-value">
              {isValidHttpUrl(session.lastPageView.page) ? (
                <a href={session.lastPageView.page} target="_blank" rel="noreferrer">
                  {session.lastPageView.page}
                </a>
              ) : (
                session.lastPageView.page
              )}
            </div>
          </div>
        )}
        {session.lastEvent && (
          <div className="contact-details-card-value-row">
            <div className="contact-details-card-value-row-key">Last event</div>
            <div className="contact-details-card-value-row-value">
              {session.lastEvent.name}
            </div>
          </div>
        )}
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">
            Last feedback
          </div>
          <div className="contact-details-card-value-row-value">
            {session.lastFeedbackReport ? (
              <TimeAgo date={session.lastFeedbackReport} />
            ) : (
              '--'
            )}
          </div>
        </div>
        <div className="contact-details-card-value-row">
          <div className="contact-details-card-value-row-key">Location</div>
          <div className="contact-details-card-value-row-value">
            {session.location
              ? `${session.location.region && session.location.region.length > 0
                ? `${session.location.region}, ${getCountryByCode(session.location.country)}`
                : getCountryByCode(session.location.country)
              }`
              : 'Unknown'}
          </div>
        </div>
        {renderCustomData()}
      </div>
    </div>
  );
};

export default SessionDetails;
