import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { checkIntegrationData } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { INTEGRATION } from 'models/Integration';
import React, { useEffect, useState } from 'react';
import { JiraIntegrationStore } from 'stores/integrations/JiraIntegrationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './JiraConfiguration.scss';

interface JiraConfigurationProps {
  integrationID: string;
  index: number;
  jiraIntegrationStore?: JiraIntegrationStore;
  projectStore?: ProjectStore;
  onChange: (data: any) => any;
}

const JiraConfiguration = ({
  integrationID,
  index,
  jiraIntegrationStore,
  projectStore,
  onChange,
}: JiraConfigurationProps) => {
  const project = projectStore?.currentProject;

  const [currentIntegrationInfo, setCurrentIntegrationInfo] = useState(
    {} as any,
  );

  const [issueTypes, setIssueTypes] = useState([] as any[]);
  const accessibleOrganisations = jiraIntegrationStore?.accessibleOrganisations;
  const boards = jiraIntegrationStore?.boards;

  const [organisationID, setOrganisationID] = useState('');
  const [boardID, setBoardID] = useState('');
  const [issueType, setIssueType] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (project && isMounted) {
      initialize();
    }
  }, [project, isMounted]);

  useEffect(() => {
    saveIntegrationInfo();
  }, [organisationID, boardID, issueType]);

  const initialize = async () => {
    jiraIntegrationStore!.setIntegrationID(integrationID);
    await jiraIntegrationStore!.getAccessibleOrganisations();

    if (
      !checkIntegrationData({
        project,
        integrationName: INTEGRATION.JIRA,
        integrationID,
        actionIndex: index,
      })
    ) {
      return;
    }

    const integrationInfo =
      project?.integrations?.jira[integrationID]?.actions[index]
        ?.integrationInfo;

    setCurrentIntegrationInfo(integrationInfo);
  };

  const chooseOrganisation = async (organisationId) => {
    setOrganisationID(organisationId);
    await jiraIntegrationStore?.getBoards({ organisationID: organisationId });
  };

  const chooseBoard = async (boardId) => {
    setBoardID(boardId);
    for (let i = 0; i < boards.length; i++) {
      const board = boards[i];
      if (board.id === boardId) {
        setIssueTypes(
          board.issueTypes.filter((issueItem: any) => !issueItem.subtask),
        );
      }
    }
  };

  const chooseIssueType = async (issueTypeId) => {
    setIssueType(issueTypeId);
  };

  const saveIntegrationInfo = () => {
    const integrationInfo = {
      organisationID,
      boardID,
      issueType,
    };

    onChange(integrationInfo);
  };

  return (
    <div className="configuration-container mt-30">
      <SelectDropDown
        className="mb-15"
        label="Organization"
        labelPropertyName="name"
        valuePropertyName="id"
        selectedItem={accessibleOrganisations.find(
          (element) => element.id === currentIntegrationInfo?.organisationID,
        )}
        isLoading={accessibleOrganisations.length === 0}
        items={accessibleOrganisations}
        onChange={(option) => {
          chooseOrganisation(option.id);
        }}
      />
      {organisationID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Project"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={boards.find(
            (element) => element.id === currentIntegrationInfo?.boardID,
          )}
          isLoading={boards.length === 0}
          items={boards}
          onChange={(option) => {
            chooseBoard(option.id);
          }}
        />
      )}
      {boardID !== '' && (
        <SelectDropDown
          className="mb-15"
          label="Issue type"
          labelPropertyName="name"
          valuePropertyName="id"
          selectedItem={issueTypes.find(
            (element) => element.id === currentIntegrationInfo?.issueType,
          )}
          isLoading={issueTypes.length === 0}
          items={issueTypes}
          onChange={(option) => {
            chooseIssueType(option.id);
          }}
        />
      )}
    </div>
  );
};

export default inject(
  'jiraIntegrationStore',
  'projectStore',
)(observer(JiraConfiguration));
