import React, { useState } from 'react';
import './CreateProject.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import InvitationCard from 'components/InvitationCard/InvitationCard';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { Title } from 'components/Title/Title';
import SetupProject from './SetupProject/SetupProject';
import FinishProjectSetup from './FinishProjectSetup/FinishProjectSetup';

interface CreateProjectProps {
  className?: any;
  isOnboarding?: boolean;
  redirectAfter?: string;
  invitations?: any[];
  autocreateProject?: boolean;
}

const CreateProject = ({
  className,
  isOnboarding,
  redirectAfter,
  invitations,
  autocreateProject,
}: CreateProjectProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const createProjectContainerClassName = classNames(
    {
      'create-project': true,
    },
    className,
  );

  if (invitations && invitations.length > 0) {
    return (
      <div className={createProjectContainerClassName}>
        <SizedContainer size={ContainerSizes.XXL}>
          <div className="invitations--header">
            <Title label="Join your team 🤝" />
            <div className="text mb-15">Gleap was built for teams.</div>
          </div>
          <div className="invitations-onboarding">
            {invitations.map((invitation, key) => {
              return <InvitationCard invitation={invitation} key={key} />;
            })}
          </div>
        </SizedContainer>
      </div>
    );
  }

  return (
    <div className={createProjectContainerClassName}>
      {currentIndex === 0 && (
        <SetupProject
          autocreateProject={autocreateProject}
          isOnboarding={isOnboarding}
          next={next}
        />
      )}
      {currentIndex === 1 && (
        <FinishProjectSetup
          playAnimation={currentIndex === 1}
          redirectAfter={redirectAfter}
        />
      )}
    </div>
  );
};

export default CreateProject;
