import LinkButton from 'components/LinkButton/LinkButton';
import { inject, observer } from 'mobx-react';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import ProductMarketFit from '../../../../assets/surveys/ProductMarketFit.png';
import FeatureSatisfication from '../../../../assets/surveys/ProductQuality.png';
import NewCustomers from '../../../../assets/surveys/NewCustomers.png';
import Newsletter from '../../../../assets/surveys/Newsletter.png';
import Message1 from '../../../../assets/surveys/Message1.png';
import Message2 from '../../../../assets/surveys/Message2.png';
import Message3 from '../../../../assets/surveys/Message3.png';
import Churn from '../../../../assets/surveys/Churn.png';
import OnboardingEmail from '../../../../assets/surveys/OnboardingEmail.png';
import EmailFeedback from '../../../../assets/surveys/EmailFeedback.png';
import EmailCheckin from '../../../../assets/surveys/EmailCheckIn.png';
import NPS from '../../../../assets/surveys/NPS.png';
import ReleaseNotes from '../../../../assets/surveys/ReleaseNotes.png';
import './ProjectOutboundTemplates.scss';
import { toJS } from 'mobx';
import { ModalStore } from 'stores/private/ModalStore';
import { useEffect, useState } from 'react';
import { UsersStore } from 'stores/private/UsersStore';
import { ampli } from 'ampli';

interface ProjectOutboundTemplatesProps {
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  modalStore?: ModalStore;
}

const ProjectOutboundTemplates = ({
  projectStore,
  outboundStore,
  modalStore,
  usersStore,
}: ProjectOutboundTemplatesProps) => {
  const [type, setType] = useState('all');

  useEffect(() => {
    const type = modalStore?.customData?.type ?? 'all';
    setType(type);
  });

  const templates = [
    {
      type: 'SURVEY',
      title: 'Net Promoter Score® (NPS)',
      image: NPS,
      format: 'survey',
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 604800,
      },
      pageFilterDelay: 10,
      form: [
        {
          title:
            'How likely are you to recommend us to a friend or colleague? ',
          type: 'netpromoterscore',
          lowestValueLabel: 'Not likely at all',
          highestValueLabel: 'Very likely',
          name: Math.random().toString(36).substring(7),
          required: true,
        },
        {
          title:
            'What’s the most important thing we could do to improve your experience in the future?',
          type: 'textarea',
          placeholder: 'Type your suggestions here',
          name: Math.random().toString(36).substring(7),
          required: false,
        },
      ],
    },
    {
      type: 'SURVEY',
      title: 'Product market fit',
      image: ProductMarketFit,
      format: 'survey_full',
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 604800 * 2,
      },
      pageFilterDelay: 10,
      form: [
        {
          title:
            'How disappointed would you be if you could no longer use [PRODUCTNAME]?',
          description: 'Please be honest. We can take it.',
          type: 'onetofive',
          fromNumber: 0,
          upToNumber: 10,
          lowestValueLabel: 'Not disappointed at all',
          highestValueLabel: 'Super disappointed',
          name: Math.random().toString(36).substring(7),
          required: true,
        },
        {
          title:
            'What is the main benefit you get from our product or service?',
          placeholder: 'Type your suggestions here',
          type: 'text',
          name: Math.random().toString(36).substring(7),
          required: false,
        },
      ],
    },
    {
      type: 'SURVEY',
      title: 'Understand why customers leave',
      format: 'survey_full',
      image: Churn,
      form: [
        {
          title: 'What’s the main reason for cancelling your subscription?',
          type: 'multiplechoice',
          name: Math.random().toString(36).substring(7),
          required: true,
          choices: [
            'Don’t need it right now',
            'Wasn’t useful to me',
            'Too expensive',
            'It lacked essential features',
            'Found an alternative',
            'Other',
          ],
        },
        {
          title: 'What can we do to improve?',
          placeholder: 'Type your suggestions here',
          type: 'textarea',
          name: Math.random().toString(36).substring(7),
          required: true,
        },
      ],
    },
    {
      type: 'SURVEY',
      title: 'Product satisfaction survey',
      format: 'survey',
      description: 'Improve the usability of a product.',
      image: FeatureSatisfication,
      form: [
        {
          title: 'How would you rate the quality of [PRODUCTNAME]?',
          type: 'rating',
          name: Math.random().toString(36).substring(7),
          required: true,
        },
        {
          title: 'How would you rate the value for money of [PRODUCTNAME]?',
          type: 'rating',
          name: Math.random().toString(36).substring(7),
          required: true,
        },
        {
          title:
            'Is there anything particular you have in mind to make [PRODUCTNAME] better?',
          placeholder: 'Type your suggestions here',
          type: 'textarea',
          name: Math.random().toString(36).substring(7),
          required: false,
        },
      ],
    },
    {
      type: 'SURVEY',
      title: 'Learn about new users',
      format: 'survey_full',
      pageFilterDelay: 10,
      image: NewCustomers,
      introTitle: 'Hi {{name}}',
      introMessage:
        "For a more tailored onboarding experience just answer the following questions and we'll do the rest 🙌",
      form: [
        {
          title: 'Which of the following best describes your role?',
          type: 'multiplechoice',
          name: Math.random().toString(36).substring(7),
          required: true,
          choices: ['Sales', 'Support', 'Marketing', 'Product', 'Other'],
        },
        {
          title:
            'How familiar are you with our product (or products like ours)?',
          type: 'onetofive',
          fromNumber: 1,
          upToNumber: 5,
          name: Math.random().toString(36).substring(7),
          required: true,
          lowestValueLabel: 'Not familiar',
          highestValueLabel: 'Very familiar',
        },
        {
          title: 'Which of these best describes your role?',
          type: 'multiplechoice',
          name: Math.random().toString(36).substring(7),
          required: true,
          choices: [
            'I just joined a company which uses your product',
            "I'm just testing it out",
            'I want to get set up as soon as possible',
            'Other',
          ],
        },
      ],
    },
    {
      type: 'SURVEY',
      title: 'Generate more leads',
      format: 'survey_full',
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 0,
      },
      pageFilterDelay: 20,
      image: Newsletter,
      form: [
        {
          title: 'Want to sign up for our free weekly newsletter?',
          placeholder: 'Email address',
          type: 'text',
          name: Math.random().toString(36).substring(7),
          required: true,
        },
        {
          title: 'What are you most interested in?',
          type: 'multiplechoice',
          choices: ['Option A', 'Option B', 'Option C'],
          name: Math.random().toString(36).substring(7),
          required: true,
        },
      ],
    },
    {
      type: 'MESSAGE',
      sender: usersStore?.currentUser?.id,
      actionType: 'message',
      sound: true,
      title: 'Increase interactions with your feedback widget',
      message:
        'Hey {{name}} 👋 Found a bug or want to chat with us? Send your feedback here.',
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 0,
      },
      pageFilterDelay: 0,
      image: Message3,
    },
    {
      type: 'NEWS',
      sender: usersStore?.currentUser?.id,
      actionType: 'notification',
      sound: true,
      title: 'Release note',
      subject: 'Release update: MONTH',
      message: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'We are excited to share with you the features we added most recently!',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: '- Feature 1',
              },
            ],
          },
        ],
      },
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 0,
      },
      pageFilterDelay: 0,
      image: ReleaseNotes,
    },
    {
      type: 'MESSAGE',
      sender: usersStore?.currentUser?.id,
      actionType: 'message',
      sound: true,
      title: 'Keep new users engaged',
      message:
        'Time flies when you’re having fun. You’ve been with us for two weeks already. 🙌 I wanted to quickly check in to see if there’s something we can help you with.',
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 0,
      },
      pageFilterDelay: 0,
      image: Message2,
    },
    {
      type: 'MESSAGE',
      sender: usersStore?.currentUser?.id,
      actionType: 'message',
      sound: true,
      title: 'Offer help upon checkout',
      message: 'Ready for checkout? Let me know if you need any help.',
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 0,
      },
      pageFilterDelay: 0,
      image: Message1,
    },
    {
      type: 'EMAIL',
      sender: usersStore?.currentUser?.id,
      actionType: 'email',
      sound: true,
      format: 'personal',
      title: 'Welcome new users',
      subject: 'Welcome to [product name]',
      message: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Hi {{name}},',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Thanks for signing up for [product name]. We are super excited to have you! ',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Here are some useful links to help you get started:',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: '[insert useful information / tips here]',
              },
              { type: 'hardBreak' },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  {
                    type: 'link',
                    attrs: {
                      href: '#',
                      target: '_blank',
                      linktype: 'button',
                      bgcolor: '#485bff',
                    },
                  },
                  { type: 'textStyle', attrs: { color: '#ffffff' } },
                ],
                text: 'Get started now',
              },
              {
                type: 'hardBreak',
                marks: [
                  {
                    type: 'link',
                    attrs: {
                      href: '#',
                      target: '_blank',
                      linktype: 'button',
                      bgcolor: '#485bff',
                    },
                  },
                  { type: 'textStyle', attrs: { color: '#ffffff' } },
                ],
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'In the meantime, let us know if you have any questions or feedback. We would love to hear it all.',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Best,',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: usersStore?.currentUser?.firstName ?? '',
              },
            ],
          },
        ],
      },
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 0,
      },
      pageFilterDelay: 0,
      image: OnboardingEmail,
    },
    {
      type: 'EMAIL',
      sender: usersStore?.currentUser?.id,
      actionType: 'email',
      sound: true,
      format: 'personal',
      title: 'Checking in',
      subject: 'Checking in from team [product name]',
      message: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [{ type: 'text', text: 'Hi {{name}},' }],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: "I hope you're doing well 🎉!",
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'You have been part of [product name] for some time now. I wanted to ask how your journey’s been so far.',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Is there anything we can clarify or do you have feedback for us?',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Best,',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: usersStore?.currentUser?.firstName ?? '',
              },
            ],
          },
        ],
      },
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 86400 * 14,
      },
      pageFilterDelay: 0,
      image: EmailCheckin,
    },
    {
      type: 'EMAIL',
      sender: usersStore?.currentUser?.id,
      actionType: 'email',
      sound: true,
      format: 'personal',
      title: 'Help us get better',
      subject: 'Help us get better',
      message: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [{ type: 'text', text: 'Hi {{name}},' }],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'We’re super happy that you’ve joined [product name] a couple of weeks ago. We love learning from our customers, which is why we wanted to reach out to see if there are particular things you’d like to share with us. ',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Whether you have general feedback, suggestions for improvement or anything else, we’d love to hear it all.',
              },
            ],
          },
          { type: 'paragraph' },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  {
                    type: 'link',
                    attrs: {
                      href: 'http://all.Talk',
                      target: '_blank',
                      linktype: 'link',
                      bgcolor: '#485bff',
                    },
                  },
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: 'Talk',
              },
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: ' soon,',
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                marks: [
                  { type: 'textStyle', attrs: { color: 'rgb(0, 0, 0)' } },
                ],
                text: usersStore?.currentUser?.firstName ?? '',
              },
            ],
          },
        ],
      },
      trigger: {
        event: 'gleap-up-createdAt',
        type: 'firstoccured',
        dateOperator: 'relative',
        matchOperator: 'greaterthan',
        data: 86400 * 21,
      },
      pageFilterDelay: 0,
      image: EmailFeedback,
    },
  ];

  const createMessage = async (templateItem: any) => {
    if (projectStore?.currentProject) {
      await outboundStore?.createOutboundRule({
        type: 'MESSAGE',
        sender: usersStore?.currentUser?.id,
        actionType: templateItem.actionType ?? 'message',
        sound: true,
        message: templateItem.message ?? '',
        name: templateItem.title,
        format: templateItem.format ?? 'surveys',
        trigger: templateItem.trigger,
        pageFilterDelay: templateItem.pageFilterDelay ?? 0,
      });

      modalStore!.closeModal();

      ampli.messageCreated({
        name: templateItem.title,
        projectId: projectStore?.currentProject?.id,
      });
    }
  };

  const createEmail = async (templateItem: any) => {
    if (projectStore?.currentProject) {
      await outboundStore?.createOutboundRule({
        type: 'EMAIL',
        sender: usersStore?.currentUser?.id,
        actionType: templateItem.actionType ?? 'email',
        sound: true,
        subject: templateItem.subject ?? '',
        message: templateItem.message ?? '',
        name: templateItem.title,
        format: templateItem.format ?? 'personal',
        trigger: templateItem.trigger,
        pageFilterDelay: templateItem.pageFilterDelay ?? 0,
      });

      modalStore!.closeModal();

      ampli.messageCreated({
        name: templateItem.title,
        projectId: projectStore?.currentProject?.id,
      });
    }
  };

  const createSurvey = async (templateItem: any) => {
    // Replace the placeholder with the actual project name
    let formTemplateString = JSON.stringify(templateItem.form);
    formTemplateString = formTemplateString
      .split('[PRODUCTNAME]')
      .join(projectStore!.currentProject?.name);
    let formData = JSON.parse(formTemplateString);

    if (projectStore?.currentProject) {
      var projectCopy = toJS(projectStore!.currentProject);

      const actionKey = Math.random().toString(36).substring(7);
      projectCopy.projectActions[actionKey] = {
        title: templateItem.title,
        description: '',
        thanksMessage: 'Thanks for your feedback.',
        feedbackType: 'surveys',
        disableUserScreenshot: true,
        enableUserScreenRecording: false,
        introTitle: templateItem.introTitle,
        introMessage: templateItem.introMessage,
        form: formData,
        excludeData: {
          customData: false,
          metaData: false,
          consoleLog: true,
          networkLogs: true,
          customEventLog: true,
          screenshot: true,
          replays: true,
        },
        default: false,
      };

      await projectStore?.updateProject(projectCopy.id, projectCopy, false);

      await outboundStore?.createOutboundRule({
        actionType: actionKey,
        type: 'SURVEY',
        name: templateItem.title,
        format: templateItem.format ?? 'survey',
        trigger: templateItem.trigger,
        pageFilterDelay: templateItem.pageFilterDelay ?? 0,
      });

      modalStore!.closeModal();

      ampli.surveyCreated({
        name: templateItem.title,
        projectId: projectStore?.currentProject?.id,
      });
    }
  };

  const createNewsItem = async (templateItem: any) => {
    if (projectStore?.currentProject) {
      await outboundStore?.createOutboundRule({
        type: 'NEWS',
        sender: usersStore?.currentUser?.id,
        actionType: templateItem.actionType ?? 'notification',
        sound: true,
        subject: templateItem.subject ?? '',
        message: templateItem.message ?? '',
        name: templateItem.title,
        format: templateItem.format ?? 'personal',
        trigger: templateItem.trigger,
        pageFilterDelay: templateItem.pageFilterDelay ?? 0,
      });

      modalStore!.closeModal();

      ampli.messageCreated({
        name: templateItem.title,
        projectId: projectStore?.currentProject?.id,
      });
    }
  };

  const buildTemplateItem = (templateItem) => {
    return (
      <div
        className="template-card"
        key={templateItem.title}
        onClick={() => {
          if (templateItem.type === 'MESSAGE') {
            createMessage(templateItem);
          } else if (templateItem.type === 'EMAIL') {
            createEmail(templateItem);
          } else if (templateItem.type === 'NEWS') {
            createNewsItem(templateItem);
          } else {
            createSurvey(templateItem);
          }
        }}
      >
        <img src={templateItem.image} />
        <div className="card-body">
          <div className="card-body-title">{templateItem.title}</div>
        </div>
      </div>
    );
  };

  const renderSurveyButton = () => {
    if (!(type === 'all' || type === 'surveys')) {
      return null;
    }

    return (
      <LinkButton
        icon="square-poll-vertical"
        iconSideRight={false}
        label="New survey"
        onClick={() => {
          createSurvey({
            title: 'Custom survey',
            image: FeatureSatisfication,
            form: [
              {
                title: '[PRODUCTNAME] offers all the features I need.',
                type: 'onetofive',
                fromNumber: 1,
                upToNumber: 5,
                name: Math.random().toString(36).substring(7),
                required: true,
                lowestValueLabel: 'Strongly agree',
                highestValueLabel: 'Strongly disagree',
              },
            ],
          });
        }}
      />
    );
  };

  const renderMessageButton = () => {
    if (!(type === 'all' || type === 'messages')) {
      return null;
    }

    return (
      <LinkButton
        icon="comment-lines"
        iconSideRight={false}
        label="New message"
        onClick={() => {
          createMessage({
            title: 'New chat message',
            image: FeatureSatisfication,
            trigger: {
              event: 'gleap-up-createdAt',
              type: 'firstoccured',
              dateOperator: 'relative',
              matchOperator: 'greaterthan',
              data: 0,
            },
            pageFilterDelay: 0,
          });
        }}
      />
    );
  };

  const renderEmailButton = () => {
    if (!(type === 'all' || type === 'emails')) {
      return null;
    }

    return (
      <LinkButton
        icon="envelope"
        iconSideRight={false}
        label="New email"
        onClick={() => {
          createEmail({
            title: 'New email',
            image: FeatureSatisfication,
            trigger: {
              event: 'gleap-up-createdAt',
              type: 'firstoccured',
              dateOperator: 'relative',
              matchOperator: 'greaterthan',
              data: 0,
            },
            pageFilterDelay: 0,
            message: {
              type: 'doc',
              content: [
                {
                  type: 'paragraph',
                  content: [{ type: 'text', text: 'Hey {{name}},' }],
                },
                { type: 'paragraph' },
                {
                  type: 'paragraph',
                  content: [
                    { type: 'text', text: 'Your message goes here...' },
                  ],
                },
                { type: 'paragraph' },
                {
                  type: 'paragraph',
                  content: [
                    {
                      type: 'text',
                      text: `Best,\n${usersStore?.currentUser?.firstName ?? ''
                        }`,
                    },
                  ],
                },
              ],
            },
          });
        }}
      />
    );
  };

  const renderNewsButton = () => {
    if (!(type === 'all' || type === 'news')) {
      return null;
    }

    return (
      <LinkButton
        icon="bullhorn"
        iconSideRight={false}
        label="New article"
        onClick={() => {
          createNewsItem({
            title: 'New article',
            trigger: {
              event: 'gleap-up-createdAt',
              type: 'firstoccured',
              dateOperator: 'relative',
              matchOperator: 'greaterthan',
              data: 0,
            },
            pageFilterDelay: 0,
          });
        }}
      />
    );
  };

  return (
    <div className="survey-template-modal">
      <div className="survey-template-modal-inner">
        <div className="input-label">Start from scratch</div>
        <div className="survey-create-buttons">
          {renderSurveyButton()}
          {renderMessageButton()}
          {renderEmailButton()}
          {renderNewsButton()}
        </div>
        <div className="input-label mt-30">Or choose a template</div>
        <div className="outbound-wrap-grid-container">
          {templates
            .filter((template) => {
              if (type === 'all') {
                return true;
              }
              return type.includes(template.type.toLocaleLowerCase());
            })
            .map((element: any) => {
              return buildTemplateItem(element);
            })}
        </div>
        {(type === 'all' || type === 'surveys') && (
          <div className="mt-30 text text--small">
            Net Promoter®, NPS®, NPS Prism®, and the NPS-related emoticons are
            registered trademarks of Bain & Company, Inc., Satmetrix Systems,
            Inc., and Fred Reichheld. Net Promoter Score℠ and Net Promoter
            System℠ are service marks of Bain & Company, Inc., Satmetrix
            Systems, Inc., and Fred Reichheld.
          </div>
        )}
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'outboundStore',
  'modalStore',
  'usersStore',
)(observer(ProjectOutboundTemplates));
