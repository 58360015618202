import { HeadLine } from 'components/HeadLine/HeadLine';
import { inject, observer } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';
import './QAInfo.scss';

interface QAInfoProps {
  usersStore?: UsersStore;
}

const qaAnwers = [
  {
    name: 'Referral',
    icon: 'users',
    value: 'referral',
    description: '',
  },
  {
    name: 'Internet-Search',
    icon: 'magnifying-glass',
    value: 'internet-search',
    description: '',
  },
  {
    name: 'Advertisment',
    icon: 'rectangle-ad',
    value: 'advertisment',
    description: '',
  },
  {
    name: 'Other',
    icon: 'comment-dots',
    value: 'other',
    description: '',
  },
];

const QAInfo = ({ usersStore }: QAInfoProps) => {
  if (!usersStore) {
    return <></>;
  }

  const user = usersStore!.currentUser;
  if (!user) {
    return <></>;
  }

  const sendProfileUpdate = (data) => {
    const toUpdateUser: any = {
      knewGleapFrom: data,
    };
    
    usersStore!.updateUser(user!.id, toUpdateUser, true);
  };

  return (
    <>
      <div className="qa-info-container">
        <div className="tell-us-more">
          <div className="tell-us-more--header">
            <HeadLine title="How did you hear about us?" />
          </div>
          <div className="tell-us-more--body">
            {qaAnwers.map((profile) => {
              return (
                <div
                  key={profile.value}
                  className="role-card"
                  onClick={() => {
                    sendProfileUpdate(profile.value);
                  }}
                >
                  <div className="role-card-icon">
                    <i className={`fa-regular fa-${profile.icon}`} />
                  </div>
                  <div className="role-card--title">{profile.name}</div>
                  <div className="role-card--description">
                    {profile.description}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default inject('usersStore')(observer(QAInfo));
