import React, { useState } from 'react';
import menuIcon from 'assets/icons/np_option_3079768_FFFFFF.svg';
import './ExtendedMenu.scss';

interface ExtendedMenuProps {
  onClick;
  items;
  component?: JSX.Element;
}

const ExtendedMenu = ({ onClick, items, component }: ExtendedMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = (event) => {
    event.preventDefault();
    setShowMenu(true);
    document.addEventListener('click', closeMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
    document.removeEventListener('click', closeMenu);
  };

  const buildMenuItem = (item, key) => {
    return (
      <div
        className="extended-menu-item"
        onClick={() => onClick(item)}
        key={key}
      >
        {item.label}
      </div>
    );
  };

  const getComponent = () => {
    if (component) {
      return <div onClick={openMenu}>{component}</div>;
    } else {
      return (
        <div onClick={openMenu} className="menu-header">
          <img src={menuIcon} alt="Menu icon" />
        </div>
      );
    }
  };

  return (
    <div
      className="extended-menu-container"
      onClick={(e) => e.stopPropagation()}
    >
      {getComponent()}
      {showMenu ? (
        <div className="extended-menu-items">
          {items.map((item, key) => {
            return buildMenuItem(item, key);
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ExtendedMenu;
