import { HeadLine } from 'components/HeadLine/HeadLine';
import { inject, observer } from 'mobx-react';
import { UsersStore } from 'stores/private/UsersStore';
import InstallGleapImage from 'assets/illustrations/InstallGleap.png';
import './CompleteOnboarding.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { useNavigate } from 'react-router';
import { ampli } from 'ampli';
import { OrganisationStore } from 'stores/private/OrganisationStore';

interface CompleteOnboardingProps {
  usersStore?: UsersStore;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const CompleteOnboarding = ({
  usersStore,
  modalStore,
  projectStore,
  organisationStore,
}: CompleteOnboardingProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="complete-onboarding">
        <div className="complete-onboarding-left">
          <HeadLine title={`Install the Gleap SDK 🎉`} />
          <div className="text mb-20 mt-10">
            Only one step to go, until you can fully experience the Gleap magic: <b>install
            our SDK</b>. Feel free to reach out to us, if you need any help with the installation.
          </div>
          <PrimaryButton
            fullWidth
            icon="chevron-right"
            iconSideRight={true}
            label={`Let's go`}
            onClick={() => {
              usersStore!.updateUser(
                usersStore?.currentUser?.id!,
                {
                  completedOnboarding: true,
                },
                true,
              );

              organisationStore!.getMyOrganisations();
              modalStore!.closeModal();

              ampli.onboardingCompleted();

              if (projectStore?.projects && projectStore?.projects.length > 0) {
                navigate(
                  `/projects/${projectStore?.projects[0].id}/settings/installation`,
                );
              }
            }}
          />
        </div>
        <div className="complete-onboarding-right">
          <img src={InstallGleapImage} alt={`Install Gleap to get started.`} />
        </div>
      </div>
    </>
  );
};

export default inject(
  'usersStore',
  'modalStore',
  'projectStore',
  'organisationStore',
)(observer(CompleteOnboarding));
