import React from 'react';
import Swal from 'sweetalert2';
import 'styles/swal-theme.scss';
import './UpdateProjectDangerZone.scss';

import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContainer from 'components/PageContainer/PageContainer';
import LinkButton from 'components/LinkButton/LinkButton';

interface UpdateProjectDangerZoneProps {
  projectStore?: ProjectStore;
}

const UpdateProjectDangerZone = ({
  projectStore,
}: UpdateProjectDangerZoneProps) => {
  const project = projectStore!.currentProject;

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Danger zone" />
      <PageContent hasTitle isMediumBoxed>
        <div className="options-group">
          <div className="options-group-header">Delete project</div>
          <div className="danger-zone">
            <div className="danger-zone-container">
              <div className="danger-zone-item">
                <div className="danger-zone-label">Delete this project</div>
                <LinkButton
                  className="danger-button"
                  label="Delete"
                  onClick={() => {
                    Swal.fire({
                      text: 'Do you really want to delete the project ' + project.name + '?',
                      showCancelButton: true,
                      confirmButtonText: `Yes`,
                      denyButtonText: `No`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        await projectStore!.deleteProject(project.id);
                      }
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(UpdateProjectDangerZone));
