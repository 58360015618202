import axios from '../Axios';

const getAuthCodeFromPlutio = (projectID) => {
  const redirectUri = `${process.env.REACT_APP_BASEURL}/integrations/plutio`;

  return window.open(
    `https://app.plutio.com/signin/authorize?app_id=${process.env.REACT_APP_PLUTIO_APP_ID}&redirect_uri=${redirectUri}&state=${projectID}`,
    '_self',
  );
};

const setPlutioAuthToken = (code, projectID) => {
  return axios.post(`/projects/${projectID}/integrations/plutio`, {
    code,
  });
};

const setPlutioActions = ({
  projectID,
  integrationID,
  actions,
  isAdvanced,
  autoChangeStatus,
}) => {
  return axios.put(
    `/projects/${projectID}/integrations/plutio/${integrationID}`,
    {
      actions,
      isAdvanced,
      autoChangeStatus,
    },
  );
};

const disconnectPlutioIntegration = ({ projectID, integrationID }) => {
  return axios.delete(
    `/projects/${projectID}/integrations/plutio/${integrationID}`,
  );
};

const getBusinesses = ({ projectID, integrationID }) => {
  return axios.get(
    `/projects/${projectID}/integrations/plutio/${integrationID}/businesses`,
  );
};

const getProjects = ({ projectID, integrationID, businessDomain }) => {
  return axios.get(
    `/projects/${projectID}/integrations/plutio/${integrationID}/projects?businessDomain=${businessDomain}`,
  );
};

const getTaskBoards = ({
  projectID,
  integrationID,
  businessDomain,
  plutioProjectID,
}) => {
  return axios.get(
    `/projects/${projectID}/integrations/plutio/${integrationID}/task-boards?businessDomain=${businessDomain}&projectID=${plutioProjectID}`,
  );
};

const getTaskGroups = ({
  projectID,
  integrationID,
  businessDomain,
  plutioProjectID,
  taskBoardID,
}) => {
  return axios.get(
    `/projects/${projectID}/integrations/plutio/${integrationID}/task-groups?businessDomain=${businessDomain}&projectID=${plutioProjectID}&taskBoardID=${taskBoardID}`,
  );
};

export {
  disconnectPlutioIntegration,
  getAuthCodeFromPlutio,
  setPlutioAuthToken,
  setPlutioActions,
  getBusinesses,
  getProjects,
  getTaskBoards,
  getTaskGroups,
};
