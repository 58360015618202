import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { useState } from 'react';
import './CancelPlanModal.scss';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { StripeStore } from 'stores/private/StripeStore';
import TextInput from 'components/TextInput/TextInput';

interface CancelPlanModalProps {
  bugStore?: BugStore;
  modalStore?: ModalStore;
  stripeStore?: StripeStore;
}

const CancelPlanModal = ({
  bugStore,
  modalStore,
  stripeStore,
}: CancelPlanModalProps) => {
  const [selectedReason, setSelectedReason] = useState({
    title: '',
  } as any);
  const [additionalInfo, setAdditionalInfo] = useState('');

  const cancelationReasons = [{
    title: "Found an alternative tool",
    specify: "Which tool did you find?",
  }, {
    title: "My project is over and I don’t need it anymore",
  }, {
    title: "Too expensive",
  }, {
    title: "Wasn’t achieving the desired outcome with Gleap",
  }, {
    title: "Wasn’t happy with the customer service",
  }, {
    title: "Other",
    specify: "Please specify",
  }];

  const canCancel = () => {
    if (selectedReason && selectedReason.specify) {
      if (additionalInfo && additionalInfo.length > 2) {
        return true;
      } else {
        return false;
      }
    }

    if (selectedReason && selectedReason.title && selectedReason.title.length > 0) {
      return true;
    }

    return false;
  }

  return (
    <SizedContainer size={ContainerSizes.L}>
      <HeadLine title="Cancel subscription" />
      <div className='mt-20 mb-20'>
        {cancelationReasons.map((reason, index) => {
          return (
            <label className="bb-feedback-multiplechoice-container" key={`${index}`}>
              <div className="text">
                {reason.title}
              </div>
              <input
                type="radio"
                name={`condition-${reason.title}`}
                checked={reason.title === selectedReason.title}
                onChange={() => {
                  setSelectedReason(reason);
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
          );
        })}
      </div>
      {selectedReason.specify && (
        <div className='specify-details'>
          <TextInput
            placeholder=""
            type="text"
            error=""
            value={additionalInfo}
            onChange={(text) => {
              setAdditionalInfo(text);
            }}
            label={selectedReason.specify}
          />
        </div>
      )}
      <PrimaryButton
        disabled={!canCancel()}
        label="Cancel"
        isLoading={stripeStore?.updateSubscriptionLoading}
        onClick={async () => {
          const success = await stripeStore?.updateSubscription(true, selectedReason.title, additionalInfo);
          if (success) {
            modalStore?.closeModal();
          }
        }}
      />
    </SizedContainer>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'stripeStore',
)(observer(CancelPlanModal));
