import { useCallback, useEffect, useState } from 'react';
import './FeedbackTypeEditor.scss';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import TextInput from 'components/TextInput/TextInput';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TypeIconSelection from 'components/TypeIconSelection/TypeIconSelection';
import { createReactEditorJS } from 'react-editor-js';
import { debounce } from 'lodash';
import FeedbackPossibleLanes from './FeedbackPossibleLanes';
import { runInAction } from 'mobx';
import { ModalStore } from 'stores/private/ModalStore';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import LinkButton from 'components/LinkButton/LinkButton';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { ampli } from 'ampli';

const ReactEditorJS = createReactEditorJS();

interface FeedbackTypeEditorProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const FeedbackTypeEditor = ({
  projectStore,
  modalStore,
}: FeedbackTypeEditorProps) => {
  const navigate = useNavigate();
  const selectedFeedbackType = modalStore?.customData?.feedbackType;
  const [currentFeedbackTypeIndex, setCurrentFeedbackTypeIndex] = useState(
    null as any,
  );
  const feedbackTypes = projectStore?.currentProject?.projectTypes;
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    if (selectedFeedbackType && feedbackTypes && feedbackTypes.length > 0) {
      const index = feedbackTypes.findIndex(
        (x) => x.type === selectedFeedbackType,
      );
      if (index >= 0) {
        setCurrentFeedbackTypeIndex(index);

        ampli.feedbackTypeEditorOpened({
          name: projectStore?.currentFeedbackType?.type ?? '',
          projectId: projectStore?.currentProject?.id ?? '',
        });
      }
    }
  }, [selectedFeedbackType, feedbackTypes]);

  const closeAndSave = async () => {
    setIsLoadingSave(true);
    await updateProjectTypes();
    setIsLoadingSave(false);
    setCurrentFeedbackTypeIndex(null);
    modalStore!.closeModal();
  };

  const updateProjectTypes = async () => {
    await projectStore!.updateProject(
      projectStore!.currentProject!.id,
      {
        projectTypes: projectStore!.currentProject!.projectTypes,
      },
      false,
      false,
    );
  };

  const debouncedSave = useCallback(
    debounce(() => updateProjectTypes(), 1000),
    [],
  );

  if (!(feedbackTypes && currentFeedbackTypeIndex != null)) {
    return null;
  }

  const feedbackType =
    feedbackTypes[Object.keys(feedbackTypes)[currentFeedbackTypeIndex]];

  if (!feedbackType) {
    return null;
  }

  return (
    <div className="feedback-type-editor-container">
      <PageHeadLine title="Feedback type editor">
        <div className="filter-bar">
          {!feedbackTypes![
            Object.keys(feedbackTypes!)[currentFeedbackTypeIndex]
          ].default && (
            <LinkButton
              className="danger mr-10"
              label="Delete"
              small
              onClick={() => {
                Swal.fire({
                  text: "Do you really want to delete this feedback type and all of it's feedback items?",
                  showCancelButton: true,
                  confirmButtonText: `Yes`,
                  denyButtonText: `No`,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    runInAction(() => {
                      projectStore!.currentProject!.projectTypes.splice(
                        currentFeedbackTypeIndex,
                        1,
                      );
                      updateProjectTypes();
                      modalStore!.closeModal();
                      navigate(
                        `/projects/${projectStore?.currentProject?.id}/bugs`,
                      );
                    });
                  }
                });
              }}
            />
          )}
          <LinkButton
            label="Cancel"
            small
            onClick={() => {
              modalStore!.closeModal();
            }}
          />
          <PrimaryButton
            className="ml-10"
            small
            isLoading={isLoadingSave}
            label="Save"
            onClick={() => {
              closeAndSave();
            }}
          />
        </div>
      </PageHeadLine>
      <div className="feedback-type-content-page">
        <div className="options-group">
          <div className="options-group-header">General</div>
          <TextInput
            name="Name"
            placeholder=""
            type="text"
            error=""
            initalValue={feedbackType.name}
            label="Name"
            readOnly={feedbackType.default}
            onChange={(text) => {
              runInAction(() => {
                const baseName = text.toLowerCase().replace(/[^a-z]/g, '');
                feedbackType.name = text;
                feedbackType.menuName = `${text}s`;
                feedbackType.path = `${baseName}s`;
                debouncedSave();
              });
            }}
          />
          <div className="mt-20">
            <div className="input-label">Icon</div>
            <TypeIconSelection
              hasBorder
              value={feedbackType.icon}
              onValueChange={(icon) => {
                runInAction(() => {
                  feedbackType.icon = icon;
                  updateProjectTypes();
                });
              }}
            />
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Auto-assign</div>
          <div className="text mb-10">
            Automatically assign all incoming tasks of this type to the
            following user:
          </div>
          <FeedbackUserFilter
            value={feedbackType.autoAssignUser}
            truncatePreview={50}
            truncate={50}
            hasBorder
            placeholder="Select user"
            onValueChanged={(value) => {
              runInAction(() => {
                if (!value) {
                  delete feedbackType.autoAssignUser;
                } else {
                  feedbackType.autoAssignUser = value;
                }
                debouncedSave();
              });
            }}
          />
        </div>
        {feedbackType.options && feedbackType.options.possibleLanes && (
          <div className="options-group">
            <div className="options-group-header">Columns</div>
            <div className="mt-20">
              <FeedbackPossibleLanes
                possibleLanes={feedbackType.options.possibleLanes}
                onChange={(possibleLanes) => {
                  runInAction(() => {
                    feedbackType.options.possibleLanes = possibleLanes;
                    debouncedSave();
                  });
                }}
              />
            </div>
          </div>
        )}
        <div className="options-group">
          <div className="options-group-header">Email template</div>
          <div className="mt-20">
            <div className="input-label">Feedback received email template</div>
            <div className="text">
              This email will be sent to reporters of feedback items with the
              type <i>{feedbackType.type}</i>.
            </div>
            <div className="mt-20">
              <TextInput
                name="Subject"
                placeholder=""
                type="text"
                className="mb-20"
                error=""
                initalValue={feedbackType.receievedEmailTitle}
                label="Subject"
                onChange={(text) => {
                  runInAction(() => {
                    feedbackType.receievedEmailTitle = text;
                    debouncedSave();
                  });
                }}
              />
            </div>
            <div className="input-label">Content</div>
            <div className="react-editor-js-container">
              <ReactEditorJS
                defaultValue={feedbackType.receievedEmailDescription}
                minHeight={30}
                onChange={(data) => {
                  data.saver
                    .save()
                    .then((outputData) => {
                      let stringifiedOutputData = JSON.stringify(outputData);
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'http://{{{',
                        '{{{',
                      );
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'https://{{{',
                        '{{{',
                      );
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'http://{{',
                        '{{',
                      );
                      stringifiedOutputData = stringifiedOutputData.replaceAll(
                        'https://{{',
                        '{{',
                      );
                      outputData = JSON.parse(stringifiedOutputData);
                      runInAction(() => {
                        projectStore!.currentProject!.projectTypes[
                          Object.keys(
                            projectStore!.currentProject!.projectTypes,
                          )[currentFeedbackTypeIndex]
                        ].receievedEmailDescription = outputData;
                        feedbackType.receievedEmailDescription = outputData;
                        debouncedSave();
                      });
                    })
                    .catch((error) => {});
                }}
                hideToolbar={true}
              />
            </div>
            <div className="text mt-10">
              You might want to use the following variables within your email
              templates: <i>{`{{feedbackId}}`}</i>, <i>{`{{{formData}}}`}</i>,{' '}
              <i>{`{{{screenshot}}}`}</i>, <i>{`{{feedbackUrl}}`}</i>,{' '}
              <i>{`{{receiverName}}`}</i>, <i>{`{{companyName}}`}</i>.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(FeedbackTypeEditor));
